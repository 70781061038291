import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { SbxFormModalService } from '../sbx-form-modal/sbx-form-modal.service';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { SbxSimilarEntitiesModalComponent } from '@/shared/sbx-form/sbx-stakeholder-base/sbx-similar-entities-modal.component';
import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';

/**
 * Component to allow editing of uninitialized stakeholders
 *
 * Example:
 *   <sbx-edit-stakeholder
 *     [stakeholderId]="sh.id"
 *     [canInvite]="sh.canInvite"
 *     [canEdit]="sh.canEdit"
 *     [stakeholderInvited]="sh.invited"
 *     [statusCallback]="someCallback"
 *   </sbx-edit-stakeholder>
 *
 *  stakeholderId: the ID of the stakeholder to edit
 *  canInvite: whether the user has the permissions to invite the sh
 *  canEdit: whether the user has the permissions to edit modify stakeholder information
 *  stakeholderInvited: whether the stakeholder is invited. Determines whether
 *    to prompt to send an invitation
 *  buttonType: 'dropdown' or 'icon'
 *  reload: whether to reload after a successful edit
 *  statusCallback: a function that gets called after an edit
 *    { success: true if no error,
 *      message: UI-appropriate message,
 *      changes: true if there were changes,
 *      model: the new stakeholder object
 *    }
 */
const SELECTOR = 'sbx-edit-stakeholder';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-edit-stakeholder.component.html',
  styleUrls: ['sbx-edit-stakeholder.component.scss'],
})
export class SbxEditStakeholderComponent {
  @Input() stakeholderId: string;
  @Input() stakeholderInvited: boolean;
  @Input() canInvite = true;
  @Input() canEdit = true;
  @Input() buttonType = 'dropdown';
  @Input() reload = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() statusCallback: Function = null;
  @Input() modalOpen = false;
  @Output() modalOpened = new EventEmitter();

  @ViewChild('warningfooter') footerTemplate: TemplateRef<any>;

  model: any = {};
  templateData: any = {};

  constructor(
    private sbxHttp: SbxHttpClient,
    public sbxFormModalService: SbxFormModalService,
    public sbxModalService: SbxModalService,
    @Inject(SbxConfirmationModalService)
    private sbxConfirmationService: SbxConfirmationModalService,
  ) {}

  onStatusUpdate(success: boolean, message: string, changes: boolean) {
    if (this.statusCallback) {
      this.statusCallback({
        success: success,
        message: message,
        changes: changes,
        model: this.model,
      });
    }
    if (this.reload) {
      location.reload();
    }
  }

  sendInvite() {
    this.sbxHttp
      .entity('2')
      .get(`stakeholder/${this.stakeholderId}/invite`)
      .subscribe(
        (res: any) => {
          this.onStatusUpdate(res.email_sent, res.status, true);
        },
        (errors) => {
          this.onStatusUpdate(false, 'Invitation failed to send.', true);
        },
      );
  }

  onRejectSendInvite(err, message): void {
    // Do not send invite, but stakeholder has changed
    this.onStatusUpdate(true, message, true);
  }

  sendUpdatedInvitation(res: any): void {
    if (
      !res.result.error &&
      this.model.email &&
      this.canInvite &&
      (!this.stakeholderInvited ||
        this.model.email !== this.templateData.initialModel.email ||
        this.model.sh_type !== this.templateData.initialModel.sh_type)
    ) {
      this.sbxConfirmationService
        .open({
          data: {
            title: 'Send Fidelity Private Shares Invitation?',
            body: res.result.updated
              ? `We noticed you changed this user's information.
              Would you like to send them an updated invitation?`
              : `Please confirm that you want to send the invitation to
              the same email: ${this.model.email}`,
            cancelButtonTitle: "Don't Send",
            okButtonTitle: 'Send Invitation',
          },
        })
        .result.then(
          () => this.sendInvite(),
          (error) => this.onRejectSendInvite(error, res.result.status),
        );
    } else {
      this.onStatusUpdate(
        !res.result.error,
        res.result.updated ? res.result.status : null,
        res.result.updated,
      );
    }
  }

  populateForm(data = null) {
    this.modalOpened.emit();
    if (data) {
      this.stakeholderId = data.stakeholderId;
      this.stakeholderInvited = data.stakeholderInvited;
      this.canInvite = data.canInvite;
      this.canEdit = data.canEdit;
      this.statusCallback = data.statusCallback;
      this.modalOpen = data.modalOpen;
    }
    const editURl = `stakeholder/${this.stakeholderId}/edit-stakeholder`;
    const config: ISbxFormModalConfig = {
      data: {
        bottomMessageTemplate: this.footerTemplate,
        model: this.model,
        templateData: this.templateData,
        url: editURl,
      },
    };
    this.sbxFormModalService.open(config).result.then(
      (res: any) => {
        // if we have a similar entity
        if (res.result.similar_data) {
          const modal = this.sbxModalService.open(SbxSimilarEntitiesModalComponent, {
            data: {
              similarEntities: res.result.similar_data.result,
              handleAffiliates: res.result.similar_data.query.includeAffiliates,
              entityTitle: res.result.similar_data.query.entityTitle,
              affiliateTitle: res.result.similar_data.query.affiliateTitle,
            },
          });
          modal.result.then((pickedRes) => {
            const r = res;
            const { similarityWhitelist, selectedEntity, selectedAffiliate } =
              pickedRes.result;

            // if user wants to create a new profile
            if (!selectedEntity.id && !selectedAffiliate) {
              res.result.similar_data.query.check_similar = false; // eslint-disable-line camelcase
              this.sbxHttp
                .entity('2')
                .post(editURl, {
                  params: {
                    ...res.result.similar_data.query,
                  },
                })
                .subscribe(
                  (r2) => {
                    res.result = r2;
                    this.sendUpdatedInvitation(res);
                  },
                  (err) => {
                    this.onStatusUpdate(false, 'Edit Failed.', true);
                  },
                );
            } else {
              const selected = selectedAffiliate ? selectedAffiliate : selectedEntity;
              res.result.similar_data.query.merge_to = selected.shId; // eslint-disable-line camelcase
              res.result.similar_data.query.foreignEntityId = selected.id;
              this.sbxHttp
                .entity('2')
                .post(editURl, {
                  params: {
                    ...res.result.similar_data.query,
                  },
                })
                .subscribe(
                  (r2) => {
                    res.result = r2;
                    this.sendUpdatedInvitation(res);
                  },
                  (err) => {
                    this.onStatusUpdate(false, 'Edit Failed.', true);
                  },
                );
            }
          });
        } else {
          this.sendUpdatedInvitation(res);
        }
      },
      (err: any) => {
        // We don't need to show this error.
      },
    );
  }
}
