<sbx-tree-hover
  [defaultToRoot]="true"
  [ids]="profileId ? [profileId] : []"
></sbx-tree-hover>
<sbx-icon
  class="sbx-share-icon"
  *ngIf="!isDefault"
  type="share"
  (click)="gotoProfileCallback(profileId)"
></sbx-icon>
