import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Downgrade } from '@/shared/downgrade';
import { ISbxWsAdapter } from './sbx-ws.interface';
import { WS_ADAPTER_TOKEN } from './ws-adapter.token';

@Downgrade.Injectable('ngShoobx', 'SbxWs')
@Injectable({
  providedIn: 'root',
})
export class SbxWsService {
  public constructor(
    @Inject(WS_ADAPTER_TOKEN) private readonly autobahnAdapterService: ISbxWsAdapter,
  ) {}

  // This is a hot observable, hence unsubscribing will not close a topic connection.
  public listenTopic$<TopicData = string>(topic: string): Observable<TopicData> {
    return this.autobahnAdapterService.listenTopic$(topic);
  }

  // Closing a topic connection
  public unregisterTopic(topic: string): void {
    this.autobahnAdapterService.unregisterTopic(topic);
  }
}
