import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxUploadMissingDocumentComponent } from './sbx-upload-missing-document.component';
import { SbxActionModule } from '../sbx-action-menu/sbx-action-menu.module';

@NgModule({
  imports: [CommonModule, SbxButtonModule, SbxActionModule],
  declarations: [SbxUploadMissingDocumentComponent],
  exports: [SbxUploadMissingDocumentComponent],
})
export class SbxUploadMissingDocumentModule {
  static entryComponents = [SbxUploadMissingDocumentComponent];

  static forRoot(): ModuleWithProviders<SbxUploadMissingDocumentModule> {
    return {
      ngModule: SbxUploadMissingDocumentModule,
    };
  }
}
