import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import Modal from '../modal/modal';

import { $createSignatureModal, CreateSignatureModalCtrl } from './create-modal';
import { sbSignature } from './signature';
import { sbSignatureCapture } from './capture';

/**
 * @ngdoc overview
 * @name sb.lib.signature
 *
 * @description
 * This is the module for the signature UI.
 */
export default angular
  .module('sb.lib.signature', [ngSanitize, Modal])

  .controller('CreateSignatureModalCtrl', CreateSignatureModalCtrl)
  .directive('sbSignature', sbSignature)
  .component('sbSignatureCapture', sbSignatureCapture)
  .factory('$createSignatureModal', $createSignatureModal).name;
