import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SbxStockCertificateLegendApiService } from './sbx-stock-certificate-legend.api.service';
import { Legend1933, LegendGroups } from '@shoobx/types/webapi-v2';

export const legendGroupsResolver: ResolveFn<LegendGroups> = () => {
  return inject(SbxStockCertificateLegendApiService).getLegendGroups$();
};

export const legend1933Resolver: ResolveFn<Legend1933> = () => {
  return inject(SbxStockCertificateLegendApiService).getLegend1933$();
};
