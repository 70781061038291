import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-enum-radios',
  templateUrl: './sbx-enum-radios.component.html',
  styleUrls: ['./sbx-enum-radios.component.scss'],
})
export class SbxEnumRadiosComponent extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }

  handleChange(value) {
    this.formControl.setValue(value);
  }
}
