import { Component, Output, Input, EventEmitter } from '@angular/core';

/**
 *  Shoobx Themed slide Toggle Component
 *
 *  Example:
 *    <sbx-slide-toggle>
 *      Emit a toggled event here when click
 *    </sbx-slide-toggle>
 */
@Component({
  selector: 'sbx-slide-toggle',
  templateUrl: './sbx-slide-toggle.component.html',
  styleUrls: ['sbx-slide-toggle.component.scss'],
})
export class SbxSlideToggleComponent {
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();
  @Input() sliderOnColor = false;
  @Input() checked = false;
  @Input() disabled = false;

  onToggleChange(): void {
    this.toggled.emit(!this.checked);
  }
}
