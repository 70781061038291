import { SbxHttpClient } from '@/core/http';
import { Injectable } from '@angular/core';
import {
  GenericDeleteResponse,
  GuestsToRemoveRequest,
  LandingPageResponse,
  StakesSearchResponse,
} from '@shoobx/types/webapi-v2';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SbxLandingPageApiService {
  public constructor(private readonly http: SbxHttpClient) {}

  public searchResults$(phrase: string): Observable<StakesSearchResponse> {
    return this.http.root('2').get('spa/landingpage/search', {
      params: {
        searchStr: phrase,
      },
    });
  }

  public removeGuestStake$(
    params: GuestsToRemoveRequest,
  ): Observable<GenericDeleteResponse> {
    return this.http.root('2').delete('spa/landingpage', {
      params,
    });
  }

  public loadEntities$(
    start: number,
    count: number,
    inactives: boolean,
  ): Observable<LandingPageResponse> {
    return this.http.root('2').get('spa/landingpage', {
      params: {
        start,
        count,
        inactives,
      },
    });
  }
}
