<ng-template #defaultRt let-r="result" let-t="term">
  <div *ngIf="!r">No Result Matched "{{ t }}"</div>
  <ngb-highlight *ngIf="r" [result]="r.label" [term]="t" [attr.data-value]="r.value">
  </ngb-highlight>
</ng-template>

<div class="input-container" (keydown)="handleKeyDown($event)" tabindex="0">
  <input
    #input
    #instance="ngbTypeahead"
    id="typeahead-template"
    [ngClass]="{
      'sbx-enum-pointer': selectOptions && !instance.isPopupOpen() && !disabled
    }"
    [name]="name"
    type="text"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    [resultTemplate]="resultTemplate || defaultRt"
    [inputFormatter]="formatter"
    [placeholder]="placeholderText"
    [autocomplete]="disableAutoComplete ? 'off' : 'on'"
    [value]="initialValue"
    (focus)="focus$.next($event)"
    (click)="click$.next($event)"
    (selectItem)="selectItemHandler($event)"
    (blur)="instance.dismissPopup(); blur.emit($event)"
    autocomplete="off"
    [disabled]="disabled"
    tabindex="-1"
    [container]="container"
    [popperOptions]="popperOptions"
    popupClass="sbx-select-typeahead-window {{ customTypeaheadWindowClass }}"
  />
  <span
    [ngClass]="{
      caret: selectOptions && !disabled,
      'caret-up': selectOptions && instance.isPopupOpen()
    }"
  ></span>
</div>
