import angular from 'angular';
import Promise from 'lib/promise';
import Messages from 'lib/messages/messages';

import { sbChargeCreditCardWorkitem, ChargeCreditCardModel } from './payment';

export default angular
  .module('sb.workitem.chargeCreditCard', [Messages, Promise])

  .component('sbChargeCreditCardWorkitem', sbChargeCreditCardWorkitem)
  .factory('ChargeCreditCardModel', ChargeCreditCardModel).name;
