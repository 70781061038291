/**
 * @ngdoc directive
 * @name sb.billing.order.sbOrderHistory
 *
 * This is a directive that creates a Billing Tickets Page
 *
 * @description
 * A button that brings up a modal that allows to complete a task.
 * Parameters to initialize the pagelist model.
 **/
export const sbOrderHistory = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/order-history.html'),
      scope: {
        title: '@sbTitle',
        totalSize: '<sbTotalSize',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        sortData: '<sbSortData',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbItems',
        renewals: '<sbRenewals',
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.model = ListPageModel;
          $scope.redirect = '/';
          $scope.isNaN = isNaN;
          $scope.total = (order) => {
            const total =
              order.items.reduce(
                (item1, item2) => {
                  return { total: item1.total + item2.total };
                },
                { total: 0 },
              ).total / 100;

            const refunds = order.refunds || [];
            const refundTotal =
              refunds.reduce((total, refund) => {
                // eslint-disable-next-line camelcase
                return total + refund.amount;
              }, 0) / 100;

            if (refundTotal === total) {
              return 'Refunded';
            } else if (refundTotal > 0) {
              return `Partially refunded ($${refundTotal})`;
            }

            return total;
          };
          $scope.model.initialize(
            undefined,
            undefined,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            undefined,
            { url: BackendLocation.entity(1) + '/orders' },
            ListLocation.updateUrl,
          );
          ListLocation.updateModelOnLocationChange($scope);

          $scope.setStatus = (msg) => {
            $scope.status = msg;
          };
        },
      ],
    };
  },
];

export const BillingService = [
  'BackendLocation',
  'SimpleHTTPWrapper',
  function (BackendLocation, SimpleHTTPWrapper) {
    const ENTITY_TICKETS_URL = BackendLocation.entity('1') + 'tickets';
    const ENTITY_BILLING_TICKETS_URL = BackendLocation.entity('1') + 'billing';
    const COMPLETE_ERROR = 'There was an error creating invoice.';
    const TICKET_DETAIL_ERROR = 'Failed to find ticket.';

    return {
      charge(data) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_BILLING_TICKETS_URL}/charge`,
            data: data,
          },
          { chargeError: COMPLETE_ERROR },
        );
      },
      updateOrder(data) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_BILLING_TICKETS_URL}/update_order`,
            data: data,
          },
          { updateError: COMPLETE_ERROR },
        );
      },
      details(ticketId) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${ENTITY_TICKETS_URL}/${ticketId}`,
          },
          { ticketError: TICKET_DETAIL_ERROR },
        );
      },
      findProduct(productName) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${ENTITY_BILLING_TICKETS_URL}/product_info`,
            params: { name: productName },
          },
          { productError: COMPLETE_ERROR },
        );
      },
    };
  },
];
// end ProductService
