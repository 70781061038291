/**
 * @ngdoc object
 * @name sb.lib.form.OverrideControl
 *
 * @description
 * Utility class for allowing field-override checkbox behavior:
 *
 *  * A field can specify a to.override = true to display a checkbox
 *    next to the field to represent enabling override/configuration for the
 *    field.
 *  * If a field changes to a non initial/default value and the override is not checked,
 *    the override checkbox is checked automatically
 *  * If the override checkbox is unchecked, the field value is set back to the
 *    initial/default value.
 *
 *  Usage:
 *  if (field.templateOptions.override) {
 *    // for angular-formly provided fields:
 *    field.templateOptions.override = OverrideControl(field, model);
 *  }
 */
export class OverrideControl {
  constructor(field, model) {
    this.name = field.key;
    if (!model.$$overrides) {
      model.$$overrides = [];
    }
    this.model = model;
    this.field = field;
  }

  get val() {
    return this.model.$$overrides.includes(this.name);
  }

  set val(check) {
    const ovr = this.model.$$overrides;
    const includes = ovr.includes(this.name);
    if (check && !includes) {
      ovr.push(this.name);
    }
    if (!check && includes) {
      // remove name from overrides
      this.model.$$overrides = ovr.filter((elem) => elem !== this.name);
    }
  }
}
