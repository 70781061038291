import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SbxGestureConfig } from './sbx-gesture.config';
import 'hammerjs';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: SbxGestureConfig,
    },
  ],
})
export class SbxGestureModule {}
