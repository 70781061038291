import { $processStatusModal } from '../../../js/process/modal';

export { $processStatusModal };

export function processStatusModalFactory(ajsInjector: any) {
  return ajsInjector.get('$processStatusModal');
}

export const processStatusModalProvider = {
  provide: $processStatusModal,
  useFactory: processStatusModalFactory,
  deps: ['$injector'],
};
