import { Component, Input } from '@angular/core';
import { Downgrade } from '../downgrade';

export type ISbxTitleType =
  | 'page'
  | 'main'
  | 'step'
  | 'section'
  | 'sub'
  | 'super'
  | 'form-label'
  | 'listing-table-main'
  | 'listing-table-main-black';

@Downgrade.Component('ngShoobx', 'sbx-title')
@Component({
  selector: 'sbx-title',
  templateUrl: './sbx-title.component.html',
  styleUrls: ['sbx-title.component.scss'],
})
export class SbxTitleComponent {
  /*
   * Enum values for type of title/heading text.
   */
  @Input() type: ISbxTitleType;
}
