import { InjectionToken, Injector } from '@angular/core';

export interface ISbxProcessButtonModel {
  $clear(doNotClearSubmitConditions?: boolean): void;
  disable(buttonName?: string): void;
  requestEnable(buttonName?: string): void;
  $isDisabled(buttonName: string): boolean;
  $requestSubmit(buttonName: string): Promise<void>;
  $removeSubmitCondition(id: number): void;
  $addSubmitCondition(
    buttonName: string | RegExp,
    promiseMaker: () => Promise<void>,
    priority?: number,
  ): number;
}

export const SbxProcessButtonModel = new InjectionToken<ISbxProcessButtonModel>(
  'SbxProcessButtonModel',
);

export function ProcessButtonModelServiceFactory(i: Injector) {
  return i.get('ProcessButtonModel');
}

export const ProcessButtonModelServiceProvider = {
  provide: SbxProcessButtonModel,
  useFactory: ProcessButtonModelServiceFactory,
  deps: ['$injector'],
};
