import { SbxUrlService } from '@/core/url/url.service';
import { SbxLandingPageApiService } from '@/sbx-landing-page/sbx-landing-page-api.service';
import { ISearchGroup } from '@/shared/sbx-search-bar/sbx-search-bar.component';
import { Component, Input } from '@angular/core';
import { UserBareStake, UserStake } from '@shoobx/types/webapi-v2';
import { Observable, map } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@/core/config/app.config';

@Component({
  selector: 'sbx-search-company',
  templateUrl: './sbx-search-company.component.html',
  styleUrls: ['./sbx-search-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxSearchCompanyComponent {
  @Input({ required: false }) public showFooter = true;

  public constructor(
    private readonly sbxLandingPageApiService: SbxLandingPageApiService,
    private readonly sbxUrlService: SbxUrlService,
    private readonly appConfig: AppConfig,
    private readonly router: Router,
  ) {}

  public readonly searchResults = (text: string): Observable<ISearchGroup[] | []> => {
    return this.sbxLandingPageApiService.searchResults$(text).pipe(
      map((data: ISearchGroup) => {
        const results = this.filterOutCurrentEntity(data.results);
        let searchResults = [
          {
            header: null,
            headerIcon: null,
            noResultsText: 'No companies match your current filters',
            results: results,
            footer: null,
            footerLink: null,
          },
        ];

        if (this.showFooter && results.length) {
          searchResults = [
            {
              ...searchResults[0],
              footer: 'View all my companies',
              footerLink: this.router.createUrlTree(['spa', 'entities']).toString(),
            },
          ];
        }

        return searchResults;
      }),
    );
  };

  async navigateToDashboard(event: UserStake | Event) {
    // XXX: Get rid of alternative Event type from event parameter
    if (event instanceof Event) {
      return;
    }

    await this.appConfig.entityInit(event.entity.name);
    this.router.navigateByUrl(this.sbxUrlService.dashboardUrl());
  }

  private filterOutCurrentEntity(entities: UserBareStake[]): UserBareStake[] {
    return entities.filter(
      (entity: UserBareStake) => entity.entity.id !== this.appConfig.currentEntity?.id,
    );
  }
}
