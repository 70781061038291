import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[sbxScrollToElementRef]',
  standalone: true,
})
export class SbxScrollToElementRefDirective implements OnInit {
  @Input('sbxScrollToElementRefIsActive') public isActive = true;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  public ngOnInit(): void {
    if (!this.isActive) {
      return;
    }

    this.scrollToElementRef();
  }

  private scrollToElementRef(): void {
    const headerHeight =
      this.document.getElementsByTagName('sbx-header')[0]?.clientHeight;
    const elementRefOffsetTop = this.elementRef.nativeElement.offsetTop;

    window.scrollTo({ top: elementRefOffsetTop - headerHeight, behavior: 'smooth' });
  }
}
