import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbxDocumentsListModule } from '../sbx-documents-list/sbx-documents-list.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxDocumentsListModalComponent } from './sbx-documents-list-modal.component';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';

@NgModule({
  imports: [
    CommonModule,
    SbxDocumentsListModule,
    SbxModalModule,
    SbxAlertBannerModule,
    SbxLoaderModule,
  ],
  declarations: [SbxDocumentsListModalComponent],
  exports: [SbxDocumentsListModalComponent],
})
export class SbxDocumentsListModalModule {}
