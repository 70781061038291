import { NgModule } from '@angular/core';
import { SbxRadioGroupComponent } from './sbx-radio-group.component';
import { CommonModule } from '@angular/common';
import { SbxRadioModule } from '../sbx-radio/sbx-radio.module';

@NgModule({
  imports: [CommonModule, SbxRadioModule],
  declarations: [SbxRadioGroupComponent],
  exports: [SbxRadioGroupComponent],
})
export class SbxRadioGroupModule {}
