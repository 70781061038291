import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { SbxNavigationService } from './sbx-navigation.service';
import { isHrefValid } from '../utils/url-validator.util';

/**
 * The goal is to unify the navigation process between the pages:
 * - in code by using
 *    - this directive in templates
 *    - {@link SbxNavigationService.navigate | navigate()} in .ts
 * - improving performance by using "routerLink" in navigation between SPA pages even though full url has been passed
 *
 * Inspired by Angular RouterLink:
 * github.com/angular/angular/blob/main/packages/router/src/directives/router_link.ts
 */
@Directive({
  selector: '[sbxRouterLink]',
})
export class SbxRouterLinkDirective {
  @Input() public set sbxRouterLink(href: string) {
    if (!isHrefValid(href)) {
      throw new Error(`'${href}' is not a valid href`);
    }

    this.renderer.setAttribute(this.el.nativeElement, 'href', href);

    this.href = href;
  }

  /**
   * Represents the `target` attribute on a host element.
   * This is only used when the host element is an `<a>` tag.
   */
  @HostBinding('attr.target') @Input() public target?: string;

  @HostListener('click', [
    '$event.button',
    '$event.ctrlKey',
    '$event.shiftKey',
    '$event.altKey',
    '$event.metaKey',
  ])
  public onClick(
    button: number,
    ctrlKey: boolean,
    shiftKey: boolean,
    altKey: boolean,
    metaKey: boolean,
  ): boolean {
    if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    this.navigationService.navigate(this.href);

    return false;
  }

  public constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly navigationService: SbxNavigationService,
  ) {
    const isAnchor = this.el.nativeElement.tagName === 'A';

    if (!isAnchor) {
      throw new Error(`SbxRouterLink is available only for 'a' HTML Tag`);
    }
  }

  private href: string;
}
