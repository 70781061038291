import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

function createBaseFields(userVocab, isSensitive, required) {
  const keys = [
    {
      key: 'title',
      data: {},
      type: 'string-textline',
      templateOptions: { required: true, label: 'Field Name' },
      controller: [
        '$scope',
        '$observable',
        function ($scope, $observable) {
          const invalid$ = $observable.fromWatcher($scope, 'form.$invalid').pipe(
            map((field) => field?.newValue),
            startWith($scope.model.invalid),
          );
          const invalidParam$ = $observable
            .fromWatcher($scope, 'model.invalidParams')
            .pipe(
              map((field) => field?.newValue),
              startWith($scope.model.invalidParams),
            );
          combineLatest(invalid$, invalidParam$, (invalid, invalidParams) => {
            return invalid || invalidParams;
          }).$applySubscribe($scope, (val) => {
            $scope.model.invalid = val;
          });
        },
      ],
    },
    {
      key: 'actor',
      data: {},
      type: 'enum-dropdown',
      templateOptions: { required: true, label: 'User', enumVocab: userVocab },
    },
  ];

  if (isSensitive !== undefined) {
    keys.push({
      key: 'isSensitive',
      data: {},
      type: 'bool-radios',
      templateOptions: {
        required: isSensitive,
        label: 'Is this sensitive information?',
        help: `
          Will this field contain sensitive information (e.g. Social Security Number)
          which should limit access to authorized users only?
        `,
      },
    });
  }

  if (required !== undefined) {
    keys.push({
      key: 'required',
      data: {},
      type: 'bool-radios',
      templateOptions: { required: required, label: 'Is this field required?' },
    });
  }

  return Object.freeze(keys);
}

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.fieldConfiguration:isGroupFieldConfigurationType
 *
 * @description
 * Some user configurable fields (IE radios/checkboxes) are a "group" field in that
 * they allow the user to specify "options." This just captures this reusable property
 * to properly show UI if the field is a group or just a normal field.
 *
 * @param {string} type Type of the group
 *
 * @returns {boolean} Returns if the type is a group field that has options/params.
 */
export const isGroupFieldConfigurationType = [
  function () {
    return (type) => type === 'enum-radios' || type === 'checklist';
  },
]; // end isGroupFieldConfigurationType

/**
 * @ngdoc component
 * @name sb.lib.fieldConfiguration.component:sbFieldConfiguration
 *
 * @description
 * This draws a form for configuration of a non-group field. Think of a UI for a user to configure ".ini".
 * For instance, there are `name`, `description`, `actor`, and `isSensitive` properties. The implication
 * is that this field will show up in a process for another user (the actor) to fill out.
 *
 * @param {expression} ngModel Model expression. It is the model of the internal form.
 * @param {array<Object>} actors Array of objects with `value` and `label` actor vocab.
 * @param {expression} [onNameChange=undefined] This is evaled on field name change.
 */
export const sbFieldConfiguration = {
  template: require('./templates/field-configuration.html'),
  controllerAs: 'vm',
  bindings: {
    model: '=ngModel',
    actors: '<',
    onNameChange: '&?',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        let isSensitive;
        let required;
        if (this.model) {
          isSensitive = this.model.isSensitive;
          required = this.model.required;
        } else {
          isSensitive = undefined;
          required = undefined;
        }
        this.fields = createBaseFields(this.actors, isSensitive, required);
        this.model = this.model || {};
        this.model.invalid = false;
        this.model.invalidParams = false;
        const { onNameChange } = this;
        if (onNameChange) {
          $scope.$watchGroup(['vm.model.title', 'vm.model.required'], (nv, ov) => {
            if (ov !== nv) {
              onNameChange();
            }
          });
        }
      };
    },
  ],
}; // end sbFieldConfiguration

/**
 * @ngdoc component
 * @name sb.lib.fieldConfiguration.component:sbGroupFieldConfiguration
 *
 * @description
 * This is the group version of `sbFieldConfiguration`. It allows the user to configure standard field properties
 * but also configure the options for the field. The options are referred to as "params" below:
 *
 * @param {expression} ngModel Model expression. It is the model of the internal form.
 * @param {array<Object>} actors Array of objects with `value` and `label` actor vocab.
 * @param {immutable.List<immutable.Map>} params Array of params representing current options of field:
 *   @property {string} id Unique identifier of the param.
 *   @property {object} formModel Param configuration model object.
 * @param {expression} addParam Evaluated when user adds a param.
 * @param {expression} deleteParam Evaluated when user removes a param. `$param` will be in the
 *   namespace.
 * @param {expression} moveParam Evaluated when user moves a param. `$param` and `$direction` will
 *   be in the namespace.
 */
export const sbGroupFieldConfiguration = {
  template: require('./templates/group.html'),
  controllerAs: 'vm',
  bindings: {
    model: '=ngModel',
    actors: '<',
    params: '<',
    drawingEnabledId: '<',
    onNameChange: '&',
    addParam: '&',
    deleteParam: '&',
    moveParam: '&',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        this.model = this.model || {};
        this.model.invalid = false;
        this.model.invalidParams = true;
        this.paramConfigureFields = Object.freeze([
          {
            key: 'title',
            type: 'string-textline',
            data: {},
            templateOptions: { required: true },
            controller: [
              '$scope',
              '$observable',
              function ($scope, $observable) {
                const invalid$ = $observable
                  .fromWatcher($scope, 'form.$invalid')
                  .pipe(map((field) => field?.newValue));
                const model$ = $observable
                  .fromWatcher($scope, 'model', true)
                  .pipe(map((field) => field?.newValue));
                combineLatest(invalid$, model$, (invalid, model) => ({
                  invalid,
                  model,
                })).$applySubscribe($scope, (val) => {
                  $scope.$emit(
                    'sbGroupFieldConfigurationParamsValidityChange',
                    val.invalid,
                  );
                });
              },
            ],
          },
        ]);
        $scope.$on('sbGroupFieldConfigurationParamsValidityChange', (_, val) => {
          this.model.invalidParams = val;
        });
        const { onNameChange } = this;
        if (onNameChange) {
          $scope.$watchGroup(['vm.model.title', 'vm.model.required'], (nv, ov) => {
            if (ov !== nv) {
              onNameChange();
            }
          });
        }
      };
    },
  ],
}; // end sbGroupFieldConfiguration
