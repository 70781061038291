import {
  Component,
  Input,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Downgrade } from '../downgrade';

@Downgrade.Component('ngShoobx', '[sbxCollapsableTr]', true)
@Component({
  // <table>'s don't like handling random component tags
  // Therefore, just hi-jacking a <tr> element directive style
  selector: 'tr [sbxCollapsableTr]',
  templateUrl: './sbx-collapsable-tr.component.html',
  styleUrls: ['./sbx-collapsable-tr.component.scss'],
})
export class SbxCollapsableTrComponent implements OnInit {
  @ViewChild('collapsableHeader', { static: true }) header: ElementRef;
  @Input() headerColumnValues = [];
  collapsed = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const parent = this.el.nativeElement.parentNode;
    const nativeEl = this.el.nativeElement;
    this.renderer.insertBefore(parent, this.header.nativeElement, nativeEl);
  }
}
