import angular from 'angular';
import ReviewAndEditDocuments from './review-and-edit-documents';
import EditBoard from './edit-board';
import ShowPage from './show-page';
import ManageSubprocesses from './manage-subprocesses/manage-subprocesses';
import CounselSettings from './counsel-settings';
import RequestSupport from './request-support';
import SetOwnershiptAndControl from './set-ownership-and-control';
import SignDocuments from './sign-documents';
import DataItems from './data-items';
import EditOfficers from './edit-officers';
import ChargeCreditCard from './charge-credit-card';
import WaitPages from './wait-pages';
import Resolution from './resolution';
import PreviewDocuments from './preview-documents';
import CollectRepresentations from './collect-representations';
import GrantConstraints from './grant-constraints';
import WetSignature from './wet-signature';
import FillAcroForm from './fill-acroform';

/**
 * @ngdoc overview
 * @name sb.workitem
 *
 * @description
 * This is the main module for Shoobx workitem code.
 */
export default angular.module('sb.workitem', [
  ReviewAndEditDocuments,
  EditBoard,
  ShowPage,
  ManageSubprocesses,
  CounselSettings,
  RequestSupport,
  SetOwnershiptAndControl,
  SignDocuments,
  DataItems,
  EditOfficers,
  ChargeCreditCard,
  WaitPages,
  Resolution,
  PreviewDocuments,
  CollectRepresentations,
  GrantConstraints,
  WetSignature,
  FillAcroForm,
]).name;
