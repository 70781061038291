import { Injectable } from '@angular/core';
import { SbxModalService, SbxNgbModalRef } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxTaxCustomEntryModalDataModel } from './sbx-tax-custom-entry-modal-data.model';
import { SbxTaxCustomEntryModalComponent } from './sbx-tax-custom-entry-modal.component';
import { ISbxModalConfig } from '@/shared/sbx-modal/interfaces';

@Injectable()
export class SbxTaxCustomEntryModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(config: ISbxModalConfig<SbxTaxCustomEntryModalDataModel>): SbxNgbModalRef {
    const modal = this.sbxModalService.open(SbxTaxCustomEntryModalComponent, config);
    return modal;
  }
}
