/**
 * @ngdoc object
 * @name sb.lib.tickets:Tickets
 *
 * @description
 * A model for an entities tickets
 */
export const TicketsService = [
  'BackendLocation',
  'SimpleHTTPWrapper',
  function (BackendLocation, SimpleHTTPWrapper) {
    const ENTITY_TICKETS_URL = BackendLocation.entity('1') + 'tickets';
    const ENTITY_TICKETS_V2_URL = BackendLocation.entity('2') + 'tickets';
    const COMPLETE_ERROR = 'Failed to complete the task.';

    return {
      complete(ticketId) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_TICKETS_URL}/${ticketId}/complete`,
          },
          COMPLETE_ERROR,
        );
      },

      remind(ticketId) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${ENTITY_TICKETS_URL}/${ticketId}/remind`,
          },
          COMPLETE_ERROR,
        );
      },

      remove(ticketId) {
        return SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: `${ENTITY_TICKETS_V2_URL}/${ticketId}`,
          },
          COMPLETE_ERROR,
        );
      },
    };
  },
];
