/**
 * @ngdoc component
 * @name sb.lib.mobile.component:sbSlideMenu
 *
 * @description
 * Creates a slide-menu for mobile views
 *
 * @param {string} [title="Page Options"] text to display as the slide menu title
 **/
export const sbSlideMenu = {
  template: require('./templates/slidemenu.html'),
  transclude: true,
  controllerAs: 'vm',
  bindings: {
    title: '<?',
  },
  controller: [
    function () {
      this.open = false;

      function openMenu() {
        this.open = true;
      }
      function closeMenu() {
        this.open = false;
      }
      function toggleMenu() {
        this.open = !this.open;
      }

      this.openMenu = openMenu.bind(this);
      this.closeMenu = closeMenu.bind(this);
      this.toggleMenu = toggleMenu.bind(this);
    },
  ],
}; // end sbListMobileMenu
