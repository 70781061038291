import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxRecaptchaComponent } from './sbx-recaptcha.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxRecaptchaComponent],
})
export class SbxRecaptchaModule {
  static entryComponents = [SbxRecaptchaComponent];

  static forRoot(): ModuleWithProviders<SbxRecaptchaModule> {
    return {
      ngModule: SbxRecaptchaModule,
    };
  }
}
