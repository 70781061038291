import {
  Component,
  ViewChild,
  Input,
  ViewContainerRef,
  Inject,
  OnInit,
} from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxTaxService } from '../sbx-tax.service';
import { TaxAdjustment, TaxAdjustmentOptions } from '@shoobx/types/webapi-v2';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

type IAdjustmentReasonType = 'address' | 'rule' | 'error' | 'other';

const ADJUSTMENT_REASON_ADDRESS = 'Employee address is incorrect';
const ADJUSTMENT_REASON_TAX_RULE = 'Change tax rule';
const ADJUSTMENT_REASON_ERROR = 'Clerical error';
const ADJUSTMENT_REASON_OTHER = 'Other';

interface IAdjustmentReasonFormModel {
  reason: IAdjustmentReasonType;
  description: string;
}

@Component({
  selector: 'sbx-tax-adjust-event-modal',
  templateUrl: './sbx-tax-adjust-event-modal.component.html',
  styleUrls: ['sbx-tax-adjust-event-modal.component.scss'],
})
export class SbxTaxAdjustEventModalComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) containerRef;

  @Input() title: string = 'Adjust Taxes';
  @Input() url: string;

  loading = false;
  globalError: string = null;

  adjustments: TaxAdjustmentOptions[];
  adjustTaxesFormValid: boolean = true;
  taxesCollected: boolean = false;

  form = new FormGroup({});
  formData = <IAdjustmentReasonFormModel>{ reason: null, description: '' };
  formFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'enum-dropdown',
      templateOptions: {
        label: 'Provide a reason for adjustment',
        required: true,
        enumVocab: [
          { value: 'address', label: ADJUSTMENT_REASON_ADDRESS },
          { value: 'rule', label: ADJUSTMENT_REASON_TAX_RULE },
          { value: 'error', label: ADJUSTMENT_REASON_ERROR },
          { value: 'other', label: ADJUSTMENT_REASON_OTHER },
        ],
      },
      defaultValue: null,
    },
    {
      key: 'description',
      type: 'text',
      hideExpression: (model: IAdjustmentReasonFormModel) => model.reason === null,
      templateOptions: {
        subfield: 1,
        required: false,
        label: 'Description',
      },
      defaultValue: '',
    },
  ];

  constructor(
    @Inject(SbxTaxService)
    private sbxTaxService: SbxTaxService,
    @Inject(SbxModalService)
    public sbxModalService: SbxModalService,
  ) {}

  ngOnInit() {
    this.load();
  }

  reset() {
    this.globalError = null;
    this.adjustments = null;
  }

  async load() {
    this.reset();
    if (!this.url) {
      return;
    }
    this.loading = true;
    try {
      this.adjustments = await this.sbxTaxService
        .getEventAdjustmentOptions(this.url)
        .toPromise();
    } catch {
      this.globalError = 'Could not load tax data.';
    } finally {
      this.loading = false;
    }
  }

  async saveAndClose() {
    let reason: string = this.formData.description.trim();
    switch (this.formData.reason) {
      case 'address':
        reason = (ADJUSTMENT_REASON_ADDRESS + '\n' + reason).trim();
        break;
      case 'rule':
        reason = (ADJUSTMENT_REASON_TAX_RULE + '\n' + reason).trim();
        break;
      case 'error':
        reason = (ADJUSTMENT_REASON_ERROR + '\n' + reason).trim();
        break;
      case 'other':
        reason = (ADJUSTMENT_REASON_OTHER + '\n' + reason).trim();
        break;
    }

    this.loading = true;
    try {
      const adjustments = this.adjustments.map(
        (adjustment) =>
          <TaxAdjustment>{
            tax: adjustment.override,
            taxCode: adjustment.taxCode,
            taxDescription: adjustment.taxDescription,
            taxType: adjustment.taxType,
          },
      );
      await this.sbxTaxService
        .postEventAdjustments(this.url, adjustments, reason, this.taxesCollected)
        .toPromise();
      this.sbxModalService.close(this.adjustments);
    } catch {
      this.globalError = 'Could not save tax data.';
    } finally {
      this.loading = false;
    }
  }
}
