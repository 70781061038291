import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxProcessStatusButtonDirective } from './sbx-process-status-button.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxProcessStatusButtonDirective],
  exports: [SbxProcessStatusButtonDirective],
})
export class SbxProcessStatusButtonModule {}
