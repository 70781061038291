import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { SbxHttpClient } from '@/core/http';
import { Downgrade } from '@/shared/downgrade';
import { SbxPageComponent } from '@/shared/sbx-page/sbx-page.component';
import { FormGroup } from '@angular/forms';

const SELECTOR = 'sbx-pie-settings';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-pie-settings.component.html',
  styleUrls: ['./sbx-pie-settings.component.scss'],
})
export class SbxPieSettingsComponent implements OnInit {
  @Input() settingsname: string;

  @ViewChild('pageRef', { static: true }) pageRef: SbxPageComponent;

  API_VERSION = 'pie_1';
  form = new FormGroup({});
  formErrors = {};
  state = null;
  header = '';

  constructor(@Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient) {}

  async ngOnInit() {
    // Set page title
    if (this.settingsname === 'termsheetDefaults') {
      this.header = 'Termsheet Defaults';
    } else {
      this.header = 'Investment Entity Management Configuration';
    }

    this.pageRef.clearErrorFlag('global');

    try {
      this.pageRef.setLoadingFlag('global');
      this.state = await this.sbxHttpClient
        .entity(this.API_VERSION)
        .get('settings', {
          params: {
            settingsName: this.settingsname,
          },
        })
        .toPromise();
    } catch (e) {
      this.pageRef.setErrorFlag('global', e.error.message);
    } finally {
      this.pageRef.clearLoadingFlag('global');
    }
  }

  async handleSave() {
    this.pageRef.clearErrorFlag('global');

    try {
      this.pageRef.setLoadingFlag('global');
      await this.sbxHttpClient
        .entity(this.API_VERSION)
        .post('settings', {
          params: {
            settingsName: this.settingsname,
            data: this.state.model,
          },
        })
        .toPromise();
      this.pageRef.showSuccessBanner('Saved successfully!');
    } catch (e) {
      if (typeof e.error.message === 'string') {
        this.pageRef.setErrorFlag('global', e.error.message);
      } else {
        this.formErrors = e.error.message;
      }
    } finally {
      this.pageRef.clearLoadingFlag('global');
    }
  }
}
