import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxSaveFooterComponent } from './sbx-save-footer.component';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [CommonModule, SbxGridModule, SbxButtonModule],
  declarations: [SbxSaveFooterComponent],
  exports: [SbxSaveFooterComponent],
})
export class SbxSaveFooterModule {}
