import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-string',
  templateUrl: './sbx-string.component.html',
  styleUrls: ['./sbx-string.component.scss'],
})
export class SbxStringComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  @ViewChild('textarea') textarea;
  maxedHeight = false;

  autosize() {
    const currentHeight = parseInt(this.textarea.nativeElement.style.height, 10) || 0;
    const scrollHeight = this.textarea.nativeElement.scrollHeight;
    const maxHeight = parseInt(
      window.getComputedStyle(this.textarea.nativeElement).maxHeight,
      10,
    );

    if (currentHeight < scrollHeight) {
      this.textarea.nativeElement.style.height = scrollHeight + 'px';
    }

    if (scrollHeight > maxHeight) {
      this.maxedHeight = true;
    } else {
      this.maxedHeight = false;
    }
  }

  ngAfterViewInit() {
    this.autosize();
  }
}
