import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxListingSortComponent } from './sbx-listing-sort.component';
import { SbxSelectModule } from '@/shared/sbx-select/sbx-select.module';

@NgModule({
  declarations: [SbxListingSortComponent],
  imports: [CommonModule, SbxSelectModule],
  exports: [SbxListingSortComponent],
})
export class SbxListingSortModule {}
