<sbx-date-base
  #dateBaseRef
  [id]="$any(field.key)"
  [model]="formControl.value"
  [min]="field.templateOptions.minDate"
  [showError]="showError"
  [readOnly]="field.templateOptions.readOnly"
  (modelChange)="handleChange($event)"
  (focus)="handleFocus($event)"
  (blur)="handleBlur()"
></sbx-date-base>
