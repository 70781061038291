import { Options, beforeWrite } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';

export interface NgbContainerConfig {
  container: null | 'body';
  popperOptions?: (options: Partial<Options>) => Partial<Options>;
}

/**
 * Body attached container config for Ngb directives. Used for modal windows
 */
export const NGB_BODY_ATTACHED_CONTAINER_CONFIG: NgbContainerConfig = {
  container: 'body',
  popperOptions: (options: Partial<Options>) => {
    const applyMaxSize = {
      name: 'applyMaxSize',
      enabled: true,
      phase: beforeWrite,
      requires: ['maxSize'],
      fn({ state }) {
        // The `maxSize` modifier provides this data
        const { width, height } = state.modifiersData.maxSize;

        state.styles.popper = {
          ...state.styles.popper,
          maxWidth: `${width}px`,
          maxHeight: `${height}px`,
        };
      },
    };

    options.modifiers = [maxSize, applyMaxSize];

    return options;
  },
};
