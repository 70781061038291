import angular from 'angular';
/**
 * @ngdoc service
 * @name sb.lib.administration.SettingsService
 *
 * @description
 * Service for settings manipulation, like process/counsel settings.
 */
export const SettingsService = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    return {
      /**
       * @ngdoc property
       * @name settings
       * @propertyOf sb.lib.administration.SettingsService
       *
       * @description
       * Process settings model as an object in the form of `{ <row>-<col>:
       * <boolean> }`.
       */
      settings: undefined,

      /**
       * @ngdoc property
       * @name presentation
       * @propertyOf sb.lib.administration.SettingsService
       *
       * @description
       * Presentation object for process settings.
       */
      presentation: undefined,

      /**
       * @ngdoc property
       * @name defaults
       * @propertyOf sb.lib.administration.SettingsService
       *
       * @description
       * Defaults settings object. Same form as `SettingsService.settings`.
       */
      defaults: undefined,

      /**
       * @ngdoc method
       * @name getCounselSettings
       * @methodOf sb.lib.administration.SettingsService
       *
       * @description
       * Fetch counsel settings and store it in the model.
       *
       * @returns {promise} This promise resolves with settings or rejects with
       *    a reason.
       */
      getCounselSettings: function () {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.context(1) + 'counsel/currentInfo',
          },
          'Failed to fetch settings.',
        ).then((result) => {
          this.settings = result.data;
          this.defaults = angular.copy(this.settings);
          this.presentation = result.presentation;
          this.requiresChange = result.changeRequired;
          return this.settings;
        }, $q.reject);
      },

      /**
       * @ngdoc method
       * @name setCounselSettings
       * @methodOf sb.lib.administration.SettingsService
       *
       * @description
       * Save the counsel settings currently in the object.
       *
       * @returns {promise} This promise resolves on sucess and rejects with a
       *    reason.
       */
      setCounselSettings: function () {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: BackendLocation.context(1) + 'counsel/update',
            data: this.settings,
          },
          'Failed to save settings.',
        );
      },

      /**
       * @ngdoc method
       * @name resetToDefaults
       * @methodOf sb.lib.administration.SettingsService
       *
       * @description
       * Reset the model to default settings. This will fetch the defaults if
       * need be.
       *
       * @returns {promise} This promise resolves on sucess with currently reset
       *    settings model and rejects with reason.
       */
      resetToDefaults: function () {
        if (angular.isDefined(this.defaults)) {
          this.settings = angular.copy(this.defaults);
        }
      },
    };
  },
]; // end SettingsService
