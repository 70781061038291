import { Inject, Injectable } from '@angular/core';

@Injectable()
export class SbxZendeskWidget {
  constructor(@Inject(Window) private window: Window) {
    this.hide();
  }

  show() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.window as any).zE?.('webWidget', 'show');
  }

  hide() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.window as any).zE?.('webWidget', 'hide');
  }
}
