import angular from 'angular';
/**
 * @ngdoc object
 * @name sb.workitem.setOwnershipAndControl.SetOwnershipAndControlModel
 * @requires lib/sb.promise.SimpleHTTPWrapper
 * @requires lib/sb.url.BackendLocation
 *
 * @description
 * This service is in charge of model data for the show page workitem.
 */
export const SetOwnershipAndControlModel = [
  'SimpleHTTPWrapper',
  '$q',
  '$window',
  'ProcessUrlInfo',
  function (SimpleHTTPWrapper, $q, $window, ProcessUrlInfo) {
    let savePromise = null;

    return {
      /**
       * @ngdoc method
       * @name loadDealParameters
       * @methodOf sb.workitem.setOwnershipAndControl.SetOwnershipAndControlModel
       *
       * @description
       * Load deal parameters.
       *
       * @returns {promise} Resolves with data or rejects with reason.
       */
      loadDealParameters: function () {
        return SimpleHTTPWrapper(
          {
            url: ProcessUrlInfo.currentWiUrl() + '/index.html/json',
            method: 'GET',
          },
          'There was an error loading deal parameters.',
        );
      },

      /**
       * @ngdoc method
       * @name saveDealParameters
       * @methodOf sb.workitem.setOwnershipAndControl.SetOwnershipAndControlModel
       *
       * @description
       * Save deal parameters.
       *
       * @param {object} dealParam Data to save (likely dealParams and investors).
       *
       * @returns {promise} Resolves with data or rejects with reason.
       */
      saveDealParameters: function (dealParam) {
        if (savePromise) {
          savePromise.resolve('canceled');
        }

        savePromise = $q.defer();
        dealParam = dealParam || {};
        return SimpleHTTPWrapper(
          {
            url: ProcessUrlInfo.currentWiUrl() + '/index.html/json',
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            transformRequest: angular.identity,
            data: Object.keys(dealParam)
              .reduce((accum, key) => {
                let value = dealParam[key];
                if (angular.isObject(value)) {
                  value = angular.toJson(value);
                }
                return accum + '&form.widgets.' + key + '=' + encodeURIComponent(value);
              }, '')
              .slice(1),
            timeout: savePromise,
          },
          'There was an error saving deal parameters.',
        ).finally(() => {
          savePromise = null;
        });
      },
    };
  },
]; // end SetOwnershipAndControlModel

/* Equity Computation Machine */
export function EquityManager() {
  const badValue = function (value) {
      return !angular.isNumber(value) || value < 0;
    },
    computeEmployeePoolExpansion = function (dealParam, totalShares, openPool) {
      const investment = dealParam.totalSize,
        valuation = dealParam.preMoneyValuation,
        targetPercent = dealParam.targetPostMoneyOption;
      if (
        badValue(investment) ||
        badValue(valuation) ||
        badValue(openPool) ||
        badValue(totalShares) ||
        badValue(targetPercent)
      ) {
        return 0;
      }

      const recipPer = 1 / targetPercent,
        investPer = investment / valuation,
        value =
          ((1 + investPer) * totalShares - recipPer * openPool) /
          (recipPer - 1 - investPer);
      if (value < 0) {
        return 0;
      }
      return Math.round(value);
    },
    baseArray = function (
      dealParam,
      totalShares,
      keyHolderEquity,
      openPool,
      otherCommon,
      expansion,
    ) {
      const values = [];
      if (keyHolderEquity > 0) {
        values.push(['keyHolderEquity', keyHolderEquity]);
      }
      if (openPool > 0) {
        values.push(['currentOpenPool', openPool]);
      }
      if (expansion > 0) {
        values.push(['employeePoolExpansion', expansion]);
      }
      if (otherCommon > 0) {
        values.push(['otherGrantedCommon', otherCommon]);
      }
      return values;
    };

  return {
    /**
     * Compute post money data for COW.
     */
    computePostMoneyData: function (
      dealParam,
      totalShares,
      keyHolderEquity,
      openPool,
      otherCommon,
    ) {
      if (!dealParam.totalSize || !dealParam.preMoneyValuation) {
        return undefined;
      }
      const expansion = computeEmployeePoolExpansion(dealParam, totalShares, openPool);
      const values = baseArray(
        dealParam,
        totalShares,
        keyHolderEquity,
        openPool,
        otherCommon,
        expansion,
      );
      totalShares += expansion;
      const investors = dealParam.investors || [],
        self = this,
        investorLength = investors.length,
        investorConversion = function (i) {
          if (i <= 0) {
            return false;
          }
          return self.shares(i, totalShares, dealParam.preMoneyValuation);
        };
      for (let i = 0; i < investorLength; i++) {
        const conversion = investorConversion(investors[i].investment);
        if (conversion) {
          values.push([investors[i].id, conversion]);
        }
      }
      return values;
    },

    /**
     * Compute pre-money data for COW.
     */
    computePreMoneyData: function (
      dealParam,
      totalShares,
      keyHolderEquity,
      openPool,
      otherCommon,
    ) {
      const expansion = computeEmployeePoolExpansion(dealParam, totalShares, openPool);
      return baseArray(
        dealParam,
        totalShares,
        keyHolderEquity,
        openPool,
        otherCommon,
        expansion,
      );
    },

    /**
     * Calculate percentage of an investment.
     */
    investmentPercentage: function (investment, total) {
      return (investment / total) * 100;
    },

    /**
     * Calculate percentage of total shares.
     */
    ownershipPercentage: function (investment, total, valuation) {
      return this.ownershipPercentageFromShares(
        this.shares(investment, total, valuation),
        total,
      );
    },

    /**
     * Calculate percentage of total shares from shares.
     */
    ownershipPercentageFromShares: function (shares, total) {
      return (shares / total) * 100;
    },

    /**
     * Calculate conversion price of shares.
     */
    conversionPrice: function (total, valuation) {
      if (total === 0) {
        return undefined;
      }
      return valuation / total;
    },

    /**
     * Calculate shares from valuation and total.
     */
    shares: function (investment, total, valuation) {
      return Math.round(investment / this.conversionPrice(total, valuation));
    },

    /**
     * Calculate money from shares.
     */
    money: function (shares, total, valuation) {
      return shares * this.conversionPrice(total, valuation);
    },
  };
} // end EquityManager

export function InvestorManager() {
  /* Private */
  let converters = [];
  let investorCollection = [];
  const COLORS = {
      otherInvestor: '#A985E3',
      otherShareholders: '#CFD9D9',
      0: '#7AB031',
      1: '#F5D600',
      2: '#BFCF0B',
      3: '#A5795B',
      4: '#FFB642',
    },
    CONVERTER_COLORS = {
      0: '#130BA7',
      1: '#006D5F',
      2: '#B0FCFB',
      3: '#B5975A',
      4: '#246BFF',
    },
    COLORS_MOD = 5,
    slides = {
      ppSlides: [],
      shVotingSlides: [],
    },
    investorNames = {
      otherInvestor: 'Other Investments',
      otherShareholders: 'Other Shareholders',
    },
    updateSlide = function (arr, id, newName, newInvestment, newPercentage) {
      const length = arr.length;
      for (let i = 0; i < length; i++) {
        if (arr[i].id === id) {
          arr[i].investment = newInvestment;
          arr[i].percentage = newPercentage;
          arr[i].name = newName;
          return;
        }
      }
    },
    computeSlides = function (arr, totalInvestments, grandTotal) {
      const iLength = investorCollection.length;
      const seenInvestors = {};
      let i,
        sum = 0,
        slidesLength = arr.length;

      if (!grandTotal) {
        grandTotal = totalInvestments;
      }

      const diff = grandTotal - totalInvestments;

      for (i = 0; i < slidesLength; i++) {
        seenInvestors[arr[i].id] = true;
      }

      for (i = 0; i < iLength; i++) {
        const investor = investorCollection[i];
        sum += investor.investment || 0;
        if (seenInvestors[investor.id]) {
          updateSlide(
            arr,
            investor.id,
            investor.name,
            investor.investment,
            investor.investment / grandTotal,
          );
        } else {
          arr.push({
            id: investor.id,
            name: investor.name,
            color: investor.color,
            investment: investor.investment,
            percentage: investor.investment / grandTotal,
          });
        }
      }

      const otherInvestments = totalInvestments - sum;
      if (seenInvestors.otherInvestor) {
        updateSlide(
          arr,
          'otherInvestor',
          investorNames.otherInvestor,
          otherInvestments,
          otherInvestments / grandTotal,
        );
      } else {
        arr.push({
          id: 'otherInvestor',
          name: investorNames.otherInvestor,
          color: COLORS.otherInvestor,
          percentage: otherInvestments / grandTotal,
          investment: otherInvestments,
        });
      }

      if (diff > 0) {
        if (seenInvestors.otherShareholders) {
          updateSlide(
            arr,
            'otherShareholders',
            investorNames.otherShareholders,
            diff,
            diff / grandTotal,
          );
        } else {
          arr.push({
            id: 'otherShareholders',
            name: investorNames.otherShareholders,
            color: COLORS.otherShareholders,
            percentage: diff / grandTotal,
            investment: diff,
          });
        }
      }

      slidesLength = arr.length;
      for (i = 0; i < slidesLength; i++) {
        if (!angular.isNumber(arr[i].investment) || arr[i].investment <= 0) {
          arr.splice(i, 1);
          slidesLength--;
        }
      }
      // Do a copy to trigger $watch events and keep data private.
      return arr.slice();
    }, // end computeSlides
    getNewId = function () {
      return (investorCollection || []).reduce((accum, investor) => {
        if (investor.id !== 'otherInvestor' && !investor.conversion) {
          accum += 1;
        }
        return accum;
      }, 0);
    };

  return {
    /**
     * Return a mapping from investor id to investor names.
     */
    names: function () {
      return angular.extend({}, investorNames); // Return copy.
    },

    /**
     * Compute stockholder voting slides/bars.
     */
    computeShSlides: function (totalInvestmentSize, totalSharesValue) {
      return computeSlides(
        slides.shVotingSlides,
        totalInvestmentSize,
        totalSharesValue,
      );
    },

    /**
     * Compute protective provsions slides/bars.
     */
    computePpSlides: function (totalInvestmentSize) {
      return computeSlides(slides.ppSlides, totalInvestmentSize);
    },

    setConverters: function (cvrs) {
      converters = [];
      angular.forEach(cvrs, (converter, x) => {
        converter.id = 'converter' + x.toString();
        converter.color = CONVERTER_COLORS[(x % COLORS_MOD).toString()];
        converter.editableInvestment = false;
        converters.push(converter);
      });
    },

    convert: function (investors, dealParams) {
      investors = this.removeConversions(investors);
      return investors.concat(this.computeConvertedInvestors(dealParams));
    },

    removeConversions: function (investors) {
      return (investors || []).filter((investor) => !investor.conversion);
    },

    /**
     * Compute conversion investors from deal parameters
     */
    computeConvertedInvestors: function (dealParams) {
      let totalSize = dealParams.totalSize;
      if (!angular.isNumber(totalSize) || totalSize < 0) {
        totalSize = 0;
      }
      const convertDict = {}, // keyed on stakeholder id
        investors = [],
        pmv = dealParams.preMoneyValuation,
        cLength = converters.length;

      for (let i = 0; i < cLength; i++) {
        const c = converters[i];
        let conversionRatio = 100.0 / (100.0 - c.discount);
        if (totalSize < c.qualFin) {
          continue;
        }
        if (c.valCap >= pmv) {
          // TODO: jvance use issued and outstanding shares
          conversionRatio = 1.0;
        }
        if (c.shId in convertDict) {
          convertDict[c.shId].investment += c.investment * conversionRatio;
        } else {
          convertDict[c.shId] = {
            investment: c.investment * conversionRatio,
            shId: c.shId,
            name: c.name,
            id: c.id,
            color: c.color,
          };
        }
      }

      angular.forEach(convertDict, (value, key) => {
        const ni = {};
        ni.investment = convertDict[key].investment;
        ni.shId = convertDict[key].shId;
        ni.name = convertDict[key].name;
        ni.conversion = true;
        ni.id = convertDict[key].id;
        ni.color = convertDict[key].color;
        ni.editableInvestment = false;
        investors.push(ni);
      });
      return investors;
    },

    /**
     * Compute conversion amounts based on investment total
     */
    computeConversionAmt: function (dealParams) {
      const totalSize = dealParams.totalSize;
      const cLength = converters.length;
      let pmv = dealParams.preMoneyValuation;
      let amt = 0;
      if (!angular.isNumber(totalSize) || totalSize < 0) {
        return amt;
      }
      if (!angular.isNumber(pmv) || pmv < 0) {
        pmv = 0;
      }
      for (let i = 0; i < cLength; i++) {
        const c = converters[i];
        let conversionRatio = 100.0 / (100.0 - c.discount);
        if (totalSize < c.qualFin) {
          continue;
        }
        if (c.valCap >= pmv) {
          // TODO: jvance use issued and outstanding shares
          conversionRatio = 1.0;
        }
        amt += c.investment * conversionRatio;
      }
      return amt;
    },

    /**
     * Return freshly computed investors collection.
     */
    computeInvestors: function (oldInvestors, dealParams) {
      if (!angular.isArray(oldInvestors)) {
        return undefined;
      }
      let totalInvestmentSize = dealParams.totalSize;
      if (!angular.isNumber(totalInvestmentSize) || totalInvestmentSize < 0) {
        totalInvestmentSize = 0;
      }

      const newInvestors = [];
      const iLength = oldInvestors.length;
      let sum = 0;

      for (let i = 0; i < iLength; i++) {
        const oi = oldInvestors[i],
          ni = {};
        if (!angular.isObject(oi) || oi.id === 'otherInvestor') {
          continue;
        }
        if (oi.conversion) {
          continue;
        }

        ni.id = oi.id || 'investor' + getNewId().toString();
        ni.shId = oi.shId;
        ni.investment = oi.investment;
        ni.name = oi.name;
        ni.editableInvestment = !oi.conversion;
        ni.editableName = !oi.shId;
        ni.conversion = false;
        if (oi.color) {
          ni.color = oi.color;
        } else {
          const curId = parseInt(ni.id.slice(8));
          ni.color = COLORS[(curId % COLORS_MOD).toString()];
        }
        newInvestors.push(ni);
        investorNames[ni.id] = oi.name || '(Unknown)';
        sum += ni.investment || 0;
      }

      investorCollection = this.convert(newInvestors, dealParams);

      const computed = investorCollection.slice(); // Return copy.
      angular.forEach(computed, (c) => {
        if (c.conversion) {
          investorNames[c.id] = c.name;
        }
      });
      const otherInvestments = totalInvestmentSize - sum;
      if (otherInvestments > 0) {
        computed.push({
          id: 'otherInvestor',
          editableName: false,
          editableInvestment: false,
          color: COLORS.otherInvestor,
          name: investorNames.otherInvestor,
          investment: otherInvestments,
        });
      }
      return computed;
    },

    /**
     * Add a new empty investor to the front.
     */
    addInvestor: function (newInvestor, oldInvestors, dealParams) {
      const newId = getNewId();
      oldInvestors.unshift({
        id: 'investor' + newId.toString(),
        color: COLORS[newId % COLORS_MOD],
        name: newInvestor.name || newInvestor.fullName,
        investment: newInvestor.investment,
        editableName: !newInvestor.shId,
        shId: newInvestor.id,
        conversion: false,
      });
      return this.computeInvestors(oldInvestors, dealParams);
    },

    /**
     * Remove an investor by ID.
     */
    removeInvestor: function (id, oldInvestors, dealParams) {
      const iLength = oldInvestors.length;
      for (let i = 0; i < iLength; i++) {
        if (oldInvestors[i].id === id) {
          oldInvestors.splice(i, 1);
          return this.computeInvestors(oldInvestors, dealParams);
        }
      }
      return oldInvestors;
    },

    /**
     * Ask for an investor's color.
     */
    color: function (investor) {
      let investorId = '';
      if (angular.isObject(investor) && investor.id) {
        investorId = investor.id;
      } else if (angular.isString(investor)) {
        investorId = investor;
      }

      if (investorId === 'otherInvestor') {
        return COLORS.otherInvestor;
      }

      let colorsLookup = {};
      if (investorId.indexOf('investor') === 0) {
        colorsLookup = COLORS;
      } else if (investorId.indexOf('converter') === 0) {
        colorsLookup = CONVERTER_COLORS;
      }
      investorId = investorId.replace(/\D/g, '');
      return colorsLookup[(investorId % COLORS_MOD).toString()];
    },
  };
} // end InvestorManager
