import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { SbxWebcamModule } from './sbx-webcam/sbx-webcam.module';
import { SbxImageUploadModalComponent } from './sbx-image-upload-modal.component';
import { SbxImageUploadModalService } from './sbx-image-upload-modal.service';

@NgModule({
  declarations: [SbxImageUploadModalComponent],
  imports: [
    CommonModule,
    SbxButtonModule,
    SbxIconModule,
    SbxModalModule,
    ImageCropperComponent,
    SbxWebcamModule,
  ],
  exports: [SbxImageUploadModalComponent],
  providers: [SbxImageUploadModalService],
})
export class SbxImageUploadModalModule {}
