import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxPrincipalChooserComponent } from './sbx-principal-chooser.component';
import { PrincipalStakeholderSearchService } from './principal-stakeholder-search.service';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxStakeholderCardModule } from '../sbx-stakeholder-card/sbx-stakeholder-card.module';
import { SbxSearchBarModule } from '../sbx-search-bar/sbx-search-bar.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxStakeholderCardModule,
    SbxSearchBarModule,
    SbxButtonModule,
  ],
  declarations: [SbxPrincipalChooserComponent],
  exports: [SbxPrincipalChooserComponent],
  providers: [PrincipalStakeholderSearchService],
})
export class SbxPrincipalChooserModule {
  static entryComponents = [SbxPrincipalChooserComponent];

  static forRoot(): ModuleWithProviders<SbxPrincipalChooserModule> {
    return {
      ngModule: SbxPrincipalChooserModule,
    };
  }
}
