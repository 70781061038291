import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxActivityLogModalComponent } from './sbx-activity-log-modal.component';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxTableModule } from '../sbx-table/sbx-table.module';
import { SbxPagingModule } from '../sbx-paging/sbx-paging.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';

@NgModule({
  imports: [
    CommonModule,
    SbxModalModule,
    SbxLoaderModule,
    SbxTableModule,
    SbxPagingModule,
    SbxIconModule,
    SbxAlertBannerModule,
  ],
  declarations: [SbxActivityLogModalComponent],
  exports: [SbxActivityLogModalComponent],
})
export class SbxActivityLogModalModule {
  static entryComponents = [SbxActivityLogModalComponent];

  static forRoot(): ModuleWithProviders<SbxActivityLogModalModule> {
    return {
      ngModule: SbxActivityLogModalModule,
    };
  }
}
