/**
 * @ngdoc component
 * @name sb.lib.form.component:sbTypeahead
 *
 * @description
 * This component is for displaying a typeahead that is supported by options
 * of objects. This is **depreacated** in favor of `sbTypeahead`.
 *
 * @param {expression} sbTypeaheadModel Model expression.
 * @param {template} name Name of underlying `<input>` (use data-name).
 * @param {array} sbTypeaheadOptions List of objects representing options.
 * @param {boolean} [sbTypeaheadRequired=false] If truthy, the field will be
 *    required.
 * @param {string} [sbTypeaheadPlaceholder=undefined] string value of the place-
 *    holder text.
 *
 * @example
   <sb-typeahead-object name="aName"
     data-sb-typeahead-model="model[id]"
     data-sb-typeahead-required="true"
     data-sb-typeahead-placeholder="'a placeholder!'"
     data-sb-typeahead-options="[{ title: 'one', value: '1'}]">
   </sb-typeahead-object>
 */
export const sbTypeaheadObject = {
  template: require('./templates/widgets/typeahead.html'),
  controllerAs: 'vm',
  bindings: {
    model: '=sbTypeaheadModel',
    name: '@?',
    keyOptions: '<sbTypeaheadOptions',
    required: '<?sbTypeaheadRequired',
    placeholder: '<?sbTypeaheadPlaceholder',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        $scope.$watch('vm.keyOptions', (newValue) => {
          const iterKeys = newValue || [];
          this.options = iterKeys.map((key) => key.title);
          this.$updateModel(iterKeys, (this.model || {}).title);
        });

        this.$updateModel = (keyOptions, val) => {
          if (!this.required) {
            val = val || '';
          }
          this.model = keyOptions.find((item) => item.title === val) || { title: val };
        };
        this.$addControl = (ctrl) => {
          // $addControl shoudl only be called once...
          if (this.required) {
            ctrl.$validators.required = (val) => !ctrl.$isEmpty(val);
          }
          ctrl.$parsers.push((val) => {
            this.$updateModel(this.keyOptions || [], val);
            return val;
          });
        };
      };
    },
  ],
}; // end sbTypeahead

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbTypeaheadInput
 * @restrict A
 *
 * @description
 * This directive is for passing internal typeahead model to parent `sbTypeahead`.
 */
export function sbTypeaheadInput() {
  return {
    restrict: 'A',
    require: ['ngModel', '^sbTypeaheadObject'],
    link(scope, element, attrs, [ngModelCtrl, typeaheadCtrl]) {
      typeaheadCtrl.$addControl(ngModelCtrl);
    },
  };
} // end sbTypeaheadInput
