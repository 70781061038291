import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxCollapsableComponent } from './sbx-collapsable.component';
import { SbxCollapsableTrComponent } from './sbx-collapsable-tr.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxButtonModule],
  declarations: [SbxCollapsableComponent, SbxCollapsableTrComponent],
  exports: [SbxCollapsableComponent, SbxCollapsableTrComponent],
})
export class SbxCollapsableModule {
  static entryComponents = [SbxCollapsableTrComponent];

  static forRoot(): ModuleWithProviders<SbxCollapsableModule> {
    return {
      ngModule: SbxCollapsableModule,
    };
  }
}
