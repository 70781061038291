import { Downgrade } from '@/shared/downgrade';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagesService } from '@/shared/sbx-message/messages.service';
import { MessageSeverity } from '@/shared/sbx-message/message-severity';
import { MessageModel } from '@/shared/sbx-message/message.model';

const SELECTOR = 'sbx-messages';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-messages.component.html',
  styleUrls: ['sbx-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxMessagesComponent implements OnInit, OnDestroy {
  public readonly MESSAGE_SEVERITY = MessageSeverity;
  public messages$!: Observable<MessageModel[]>;

  public constructor(private readonly messagesService: MessagesService) {}

  public ngOnInit(): void {
    this.messages$ = this.messagesService.getMessages$();
  }

  public ngOnDestroy(): void {
    this.messagesService.clearMessages();
  }

  public handleRemove(message: MessageModel): void {
    this.messagesService.removeMessage(message);
  }
}
