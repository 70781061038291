import angular from 'angular';
import Modal from '../modal/modal';
import RX from 'lib/rx';
import Form from 'lib/form/form';

import {
  $addCreditCardModal,
  sbBillingCardForm,
  sbNewCardForm,
  sbSavedCardForm,
} from './card';
import { $stripe, sbStripeElement } from './stripe';

/**
 * @ngdoc overview
 * @name sb.lib.billing
 *
 * @description
 * This module houses billing libary.
 */
export default angular
  .module('sb.lib.billing', [RX, Modal, Form])

  .component('sbBillingCardForm', sbBillingCardForm)
  .component('sbNewCardForm', sbNewCardForm)
  .component('sbSavedCardForm', sbSavedCardForm)
  .component('sbStripeElement', sbStripeElement)
  .factory('$addCreditCardModal', $addCreditCardModal)
  .factory('$stripe', $stripe).name;
