import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { ValidatorError } from '@/shared/sbx-form/fields/validators/validator-error';
import { INTERNAL_FORMAT } from '@/shared/sbx-form/fields/sbx-date-base.component.formatters';

export function dateAfterValidator(
  dateToCompare: Date,
  allowEqual = true,
): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const momentDate = moment(control.value, INTERNAL_FORMAT, true);

    if (!momentDate.isValid()) {
      return {
        [ValidatorError.INVALID_DATE]: true,
      };
    }

    const date = momentDate.toDate();

    if (allowEqual) {
      if (!moment(date).isSameOrAfter(dateToCompare)) {
        return {
          [ValidatorError.DATE_TOO_EARLY]: true,
        };
      }
    } else if (!moment(date).isAfter(dateToCompare)) {
      return {
        [ValidatorError.DATE_TOO_EARLY]: true,
      };
    }

    return null;
  };
}
