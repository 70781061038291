import { SbxUrlService } from '@/core/url/url.service';
import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sbx-error-page',
  templateUrl: './sbx-error-page.component.html',
  styleUrls: ['./sbx-error-page.component.scss'],
})
export class SbxErrorPageComponent implements OnInit {
  constructor(
    @Inject(SbxUrlService) private sbxUrlService: SbxUrlService,
    @Inject(DomSanitizer) private sanitized: DomSanitizer,
  ) {}

  ngOnInit() {
    const url = new URL(location.href);
    const withoutRedirect = url.searchParams.get('withoutRedirect') === 'true';
    if (withoutRedirect) {
      return;
    }

    const urlContainsInitial = Boolean(url.searchParams.get('initial'));
    if (urlContainsInitial) {
      url.searchParams.delete('initial');
      window.history.replaceState(null, null, url.toString());
    } else {
      const camefrom = this.sanitized.sanitize(
        SecurityContext.URL,
        this.sbxUrlService.parseCamefromUrl(
          decodeURIComponent(url.searchParams.get('camefrom')),
        ),
      );
      // nosemgrep
      window.location.replace(camefrom);
    }
  }
}
