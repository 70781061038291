<ng-template #headerTemplate let-model="model">
  <div *ngIf="model.editingMessage">
    <div [innerHtml]="model.editingMessage"></div>
  </div>
  <div *ngIf="!model.editingMessage">
    <p>
      Please compose the email message that will later be sent to
      {{ model.recipientsTitle }} as part of this workflow.
    </p>
    <p>
      <strong>This email will not be sent now.</strong> It will be sent after the
      workflow checkpoint, as soon as any necessary legal or internal reviews are
      completed.
    </p>
  </div>
</ng-template>

<ng-template #popoverContext let-model>
  <div
    *ngFor="let recipient of model.recipients | slice : 0 : maxRecipients"
    class="sbx-stakeholder-card-wrapper"
  >
    <sbx-stakeholder-card
      format="medium"
      [stakeholderId]="$any(recipient)"
    ></sbx-stakeholder-card>
  </div>
  <div
    *ngIf="model.recipients.length > maxRecipients"
    class="sbx-stakeholder-card-wrapper"
  >
    <p>
      and <strong>{{ model.recipients.length - maxRecipients }}</strong> more.
    </p>
  </div>
</ng-template>

<ng-template #recipientTemplate let-model>
  <strong>{{ model.recipientsTitle }}</strong>
  <sbx-popover
    *ngIf="model.recipients?.length"
    [sbxPopoverTitle]="popoverTitle"
    [sbxPopoverContent]="popoverContext"
    [sbxPopoverTemplateContext]="model"
  >
    <sbx-icon class="sbx-info-icon" type="infoCircle"></sbx-icon>
  </sbx-popover>
</ng-template>

<sbx-wi-panel-group
  [items]="emailItems"
  (buttonClick)="handleButtonClick($event)"
></sbx-wi-panel-group>
