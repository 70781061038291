import { Component, Input } from '@angular/core';
import { ISbxIconType } from '@/core/constants/icons';
import { Downgrade } from '@/shared/downgrade';

/**
 *  Shoobx Themed Button Component
 *
 *  Example:
 *    <sbx-button
 *      title="Export doc"
 *      icon="document"
 *    ></sbx-button>
 */
const SELECTOR = 'sbx-button';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-button.component.html',
  styleUrls: ['sbx-button.component.scss'],
})
export class SbxButtonComponent {
  @Input() theme: 'default' | 'primary' | 'danger' | 'secondary' = 'default';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() title: string = null;
  @Input() icon: ISbxIconType = null;
  @Input() iconRight: ISbxIconType = null;
  @Input() href: string = null;
  @Input() target: '_self' | '_blank' = '_self';

  // disable on-hover effects
  @Input() staticButton = false;
}
