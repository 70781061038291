import {
  Component,
  Input,
  Output,
  EventEmitter,
  NgZone,
  Inject,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Downgrade } from '../downgrade';
import { Observable } from 'rxjs';

const RENDER_CALLBACK = 'renderRecaptcha';
const SELECTOR = 'sbx-recaptcha';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-recaptcha.component.html',
  styleUrls: ['sbx-recaptcha.component.scss'],
})
export class SbxRecaptchaComponent implements OnInit {
  @Input() recaptchaApiKey: Observable<string>;
  @Output() response = new EventEmitter<string>();
  @Output() expired = new EventEmitter();
  @Output() loaded = new EventEmitter<boolean>();
  @ViewChild('elem') elem: ElementRef;
  private alreadyLoaded = false;
  private zone: NgZone;

  constructor(@Inject(NgZone) zone: NgZone) {
    this.zone = zone;
  }

  ngOnInit() {
    if (!this.alreadyLoaded) {
      this.alreadyLoaded = true;
      const script = document.createElement('script');
      window[RENDER_CALLBACK] = () =>
        this.zone.runOutsideAngular(this.renderRecaptcha.bind(this));
      script.src = `https://google.com/recaptcha/api.js?onload=${RENDER_CALLBACK}&render=explicit`;
      script.async = true;
      script.defer = true;
      document.body.append(script);
    }
  }

  public renderRecaptcha() {
    // grecaptcha is loaded after the script in onInit() executes
    const win = <any>window;
    win.grecaptcha.render(this.elem.nativeElement, {
      sitekey: this.recaptchaApiKey,
      badge: 'bottomright',
      theme: 'light',
      type: 'image',
      size: 'normal',
      tabindex: 0,
      callback: (response) =>
        this.zone.run(this.handleCaptchaResponse.bind(this, response)),
      'expired-callback': () => this.zone.run(this.handleCaptchaExpired.bind(this)),
    });

    this.handleCaptchaLoaded();
  }

  public handleCaptchaLoaded() {
    this.loaded.emit(true);
  }

  public handleCaptchaExpired() {
    this.expired.emit();
  }

  public handleCaptchaResponse(event) {
    this.response.emit(event);
  }
}
