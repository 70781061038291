<sbx-row
  class="sbx-tresholds-container"
  *ngFor="let threshold of thresholds | keyvalue"
>
  <sbx-col [lg]="1">
    <strong>{{ threshold.key }}</strong>
  </sbx-col>
  <sbx-col [lg]="11">
    <sbx-progress-bar
      [completed]="countSelectedShares(threshold.key)"
      [total]="countTotalShares(threshold.key)"
      [threshold]="threshold.value"
    ></sbx-progress-bar>
  </sbx-col>
</sbx-row>

<sbx-table [columns]="columns" [data]="shareholders"></sbx-table>

<ng-template #checkboxTpl let-item let-row="row">
  <sbx-checkbox
    [name]="row.id"
    [model]="selectedShareholders.indexOf(row.id) > -1"
    (change)="handleChange(row.id, $event)"
  ></sbx-checkbox>
</ng-template>

<ng-template #sharesTpl let-column="column" let-row="row">
  {{ row.data[column.key].valueStr }}
</ng-template>

<ng-template #checkboxHeaderTpl>
  <sbx-checkbox
    name="select-all"
    [model]="isSelectAllChecked"
    [indeterminate]="isSelectAllIndeterminate"
    (change)="handleSelectAllChange($event)"
  ></sbx-checkbox>
</ng-template>
