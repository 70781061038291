const UNSAVED_CHANGES_WARNING =
  'You have unsaved changes. Are you sure you want to continue?';

export const sbCollectRepresentations = {
  controllerAs: 'vm',
  template: require('./templates/collect-representations.html'),
  bindings: {
    data: '<',
  },
  controller: [
    '$q',
    '$confirm',
    '$sbModal',
    'SimpleHTTPWrapper',
    'BackendLocation',
    'PromiseErrorCatcher',
    'ProcessButtonModel',
    class {
      constructor(
        $q,
        $confirm,
        $sbModal,
        SimpleHTTPWrapper,
        BackendLocation,
        PromiseErrorCatcher,
        ProcessButtonModel,
      ) {
        this.$sbModal = $sbModal;
        this.SimpleHTTPWrapper = SimpleHTTPWrapper;
        this.BackendLocation = BackendLocation;
        this.PromiseErrorCatcher = PromiseErrorCatcher;

        this.hasUnsavedChanges = false;
        this.ProcessButtonModel = ProcessButtonModel;
        this.ProcessButtonModel.$addSubmitCondition(
          'continue',
          () => {
            if (this.hasUnsavedChanges) {
              return $confirm({
                body: UNSAVED_CHANGES_WARNING,
                alertType: 'warning',
                confirmButtonText: 'Continue',
              });
            }
            return $q.resolve();
          },
          1,
        );

        this.saveInfo = {};
        this.isSaving = false;
        this.deleteErrorMsg = '';

        this.collectRepresentationsUrl = this.BackendLocation.context(1) + 'forms/';
        this.editorFeatures = {
          enableParaStyle: true,
          enableSpanStyle: true,
          enableFontStyle: true,
          enableListStyle: true,
          customSubElement: true,
        };
      }

      viewNotes($event, html) {
        $event.preventDefault();
        $event.stopPropagation();

        this.$sbModal
          .open({
            size: 'md',
            keyboard: false,
            backdrop: 'static',
            template: require('./templates/notes-modal.html'),
            controllerAs: 'vm',
            bindToController: true,
            resolve: {
              html: () => html,
            },
            controller: [
              'html',
              class {
                constructor(html) {
                  this.html = html;
                }
              },
            ],
          })
          .result.catch(this.PromiseErrorCatcher);
        this.hasUnsavedChanges = false;
      }

      onDocumentAdd(data, section) {
        this.data.forEach((group) => {
          const foundSection = group.sections.find((s) => s.name === section);

          if (foundSection) {
            foundSection.disclosures.push(data);
          }
        });
      }

      onDocumentUpdate(data, section, index) {
        this.data.forEach((group) => {
          const foundSection = group.sections.find((s) => s.name === section);

          if (foundSection) {
            foundSection.disclosures[index] = data;
          }
        });
      }

      removeDocument(section, disclosureId, documentId) {
        this.deleteErrorMsg = '';

        this.SimpleHTTPWrapper({
          method: 'DELETE',
          url: `${this.collectRepresentationsUrl}`,
          data: {
            section,
            id: disclosureId,
            documentId: documentId,
          },
        })
          .then(() => {
            this.data.forEach((group) => {
              const foundSection = group.sections.find((s) => s.name === section);

              if (foundSection) {
                const disclosures = group.sections.find(
                  (s) => s.name === section,
                ).disclosures;
                const index = disclosures.findIndex((d) => d.id === disclosureId);

                disclosures.splice(index, 1);
              }
            });
          })
          .catch(() => {
            this.deleteErrorMsg = 'Error while trying to remove disclosure';
          });
      }

      saveNote(exceptions, section) {
        this.saveInfo[section] = '';
        this.isSaving = true;

        this.SimpleHTTPWrapper({
          method: 'POST',
          url: `${this.collectRepresentationsUrl}save_exceptions`,
          data: {
            section,
            exceptions,
          },
        })
          .then(() => {
            const now = new Date();
            this.saveInfo[section] = `Saved ${('0' + now.getHours()).slice(-2)}:${(
              '0' + now.getMinutes()
            ).slice(-2)}`;
            this.isSaving = false;
          })
          .catch(() => {
            this.saveInfo[section] = 'Error while saving note';
            this.isSaving = false;
          });
        this.hasUnsavedChanges = false;
      }

      getSaveInfo(section) {
        return this.saveInfo[section];
      }

      updateSectionExceptions(data, section) {
        section.exceptions = data;
        this.hasUnsavedChanges = true;
      }
    },
  ],
};
