import angular from 'angular';
import { List, Set, Map, fromJS } from 'immutable';

const BOARD_MINUTES_TEMPLATE_NAME = 'board-minutes';

const ACCEPT_LEGAL_CHANGES_FORM = Object.freeze([
  {
    type: 'text',
    key: 'description',
    data: {},
    templateOptions: {
      required: true,
      label: 'Description of Changes',
    },
  },
]);

const SELECT_DOCUMENTS_FIELDS = (documents) => [
  {
    type: 'checklist',
    key: 'selectedDocuments',
    data: {},
    defaultValue: documents.map((doc) => doc.id),
    templateOptions: {
      label: 'Which documents do you wish to share?',
      valueOptions: documents.map((doc) => ({
        label: doc.title,
        value: doc.id,
      })),
    },
  },
];

const SHARE_DOCUMENTS_VIEW_ONLY_TEXT = `<p>
              Share a temporary draft of the documents created so far.
              The reviewer will be able to view these documents; however,
              they won't be asked to edit or approve them. This review will not
              stop you from moving forward with the workflow.
            </p>
            <p>
              NOTE: Access to these drafts will expire when you continue to
              the next step in the workflow, so make sure you've gotten the
              feedback you need before continuing past the checkpoint.
            </p>`;

const SHARE_DOCUMENTS_REVIEW_TYPE_FIELDS = () => [
  {
    type: 'enum-radios',
    key: 'review_type',
    data: {},
    defaultValue: 'view_only',
    templateOptions: {
      required: true,
      label: 'What kind of review is this?',
      enumVocab: [
        {
          label: 'Share - view only',
          value: 'view_only',
          descriptionText: SHARE_DOCUMENTS_VIEW_ONLY_TEXT,
        },
        {
          label: 'Share for review and edit',
          value: 'review_and_edit',
          descriptionText: `<p>
            Invite a third party to review a draft of the
            documents created so far. The reviewer will be able to make
            changes to the documents. You will have the opportunity to review
            these changes before the workflow continues.
       </p>`,
        },
      ],
    },
  },
];

const SHARE_DOCUMENTS_EMAIL_FIELDS = (entityTitle, reviewerTitle) => [
  {
    type: 'list',
    defaultValue: [],
    templateOptions: {
      subfield: 0,
      required: true,
      valueType: {
        type: 'stakeholder',
        templateOptions: {
          stakeholderButtonTitle: 'Select Recipient',
          stakeholderOptions: {
            creationModalTitleText: null,
            creationModalIndividualLabel: null,
            allowCreation: true,
            withUsersOnly: false,
            includeStates: null,
            requireEmail: true,
            format: 'large',
            allowStakes: false,
            allowPerson: true,
            affiliatePlaceholder: '',
            allowEntity: true,
            affiliateCheckboxLabel: '',
            creationModalEntityNameLabel: null,
            placeholderText: null,
            onlyActive: false,
            creationModalTeamLabel: null,
            entityOptions: {
              allowSelfTeam: false,
              type: 'any',
              affiliates: false,
            },
            affiliateLabel: '',
            creatorIsContact: false,
            allowExisting: true,
            alwaysDisplayAffiliate: false,
            creationModalEntityNamePlaceholder: null,
          },
          subfield: 0,
          required: false,
        },
        data: {},
        required: true,
        key: 'recipients_valueListKey',
      },
      label: 'Recipients',
    },
    data: {},
    key: 'recipients',
  },
  {
    type: 'string-textline',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'Email Subject',
    },
    data: {},
    key: 'emailSubject',
    defaultValue: `${reviewerTitle} from ${entityTitle} has shared documents for your review`,
  },
  {
    type: 'text',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'Email Body',
    },
    data: {},
    key: 'emailBody',
    defaultValue:
      `${reviewerTitle} from ${entityTitle} is requesting your review` +
      ' of the draft document(s) listed below. Click below to download' +
      ` and view the document(s). Please reach out directly to ${reviewerTitle}` +
      ' if you have any comments or questions.' +
      '\n' +
      '\nNote: Access to the document(s) is temporary. Your access will automatically' +
      ` expire as soon as ${entityTitle} representative takes any action related to` +
      ' these documents.' +
      '\n' +
      '\nThank you.',
  },
  {
    type: 'bool-radios',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'Would you like to be copied on the email?',
    },
    data: {},
    key: 'ccMe',
    defaultValue: false,
  },
];

/**
 * @ngdoc object
 * @name sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
 * @requires lib/sb.lib.promise.SimpleHTTPWrapper
 *
 * @description
 * This service is in charge of model data for the Review and Edit Documents
 * workitem.
 *
 * @param {string} baseUrlString the base of review and edit api resource
 */
export const ReviewAndEditDocumentsModel = [
  '$q',
  'SimpleHTTPWrapper',
  'EntityDocumentsService',
  function ($q, SimpleHTTPWrapper, EntityDocumentsService) {
    const DOC_RENDER_FAILURE = 'Failed to render document.';
    class Model {
      constructor(baseUrlString) {
        /**
         * @ngdoc property
         * @name $loading
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Amount of outstanding async operations that should block
         * continuation of the process.
         */
        this.$loading = 0;

        /**
         * @ngdoc property
         * @name $documents
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Immutable List of documents currently in state.
         */
        this.$documents = List();

        /**
         * @ngdoc property
         * @name $hasSuggestions
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Boolean indicating that there is at least one suggestion outstanding.
         */
        this.$hasSuggestions = false;

        /**
         * @ngdoc property
         * @name $hasPersistedChanges
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Boolean describing if the workitem has persited changes to terms. Inits
         * as `true`.
         */
        this.$hasPersistedChanges = true;

        /**
         * @ngdoc property
         * @name $workitemData
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Immutable map of "extra" data returned by the workitem for view.
         */
        this.$workitemData = Map();

        /**
         * @ngdoc property
         * @name $emails
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Immutable list of "emails" data returned by the workitem for view.
         */
        this.$emails = [];

        /**
         * @ngdoc property
         * @name $sharedDocuments
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * List of shared document entries.
         */
        this.$sharedDocuments = [];

        /**
         * @ngdoc property
         * @name $granterData
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Data about party that shares the documnets.
         */
        this.$granterData = {};

        /**
         * @ngdoc property
         * @name $rejectionForm
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Rejection form description.
         */
        this.$rejectionForm = {};

        /**
         * @ngdoc property
         * @name $rejectionInfoModel
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Mutable data model for rejection form data.
         */
        this.$rejectionInfoModel = {};

        /**
         * @ngdoc property
         * @name $thirdPartyReviewFrom
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Third party review form description.
         */
        this.$thirdPartyReviewForm = {};

        /**
         * @ngdoc property
         * @name $thirdPartyReviewInfoModel
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Mutable data model for third party review form data.
         */
        this.$thirdPartyReviewInfoModel = {};

        /**
         * @ngdoc property
         * @name $openPanels
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * Mutable data model of open panels for accordion state.
         */
        this.$openPanels = {};

        /**
         * @ngdoc property
         * @name _baseUrlString
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * The full api url string that will be hit.
         */
        this._baseUrlString = baseUrlString;

        this.$accessRevokedMessage = null;

        this.$latestRevisionNumber = 0;
      } // end constructor

      $$createField(name, field, fieldData, revisions, indent = 0) {
        let hasChanges = false,
          warningIconText = null,
          lastRevision = null;
        // When there is only one revision, then it must be the initial revision, thus no changes.
        if (revisions && revisions.length > 1) {
          lastRevision = revisions[0].toJS();
          // determine if this field has any changes
          if (lastRevision.myChange) {
            hasChanges = 'current';
          } else if (lastRevision.revisionNumber === this.$latestRevisionNumber) {
            hasChanges = 'last';
          }

          // determine which revision is the change in
          if (hasChanges === 'current') {
            warningIconText = 'You have modified this term value.';
          } else if (hasChanges === 'last') {
            warningIconText = `${lastRevision.creatorName} edited this term on ${lastRevision.createdDate}`;
          }
        }

        return Map({
          name,
          indent,
          // only has a value if field has a change in this revision.
          revisions: fromJS(revisions),
          // currentValue: displayed value
          currentValue: field.get('fmtValue'),
          title: fieldData.get('title'),
          rating: fieldData.get('rating'),
          hasChanges: hasChanges,
          warningIconText: warningIconText,
        });
      }

      $$viewFields(set, setName, viewData, subfields, fieldRevisions) {
        const viewFieldData = viewData.get('fields');
        return set
          .filter(
            (field, fieldName) =>
              viewFieldData.has(`${setName}.${fieldName}`) &&
              !subfields.has(`${setName}.${fieldName}`),
          )
          .map((field, fieldName) => {
            const lookup = `${setName}.${fieldName}`;
            const fieldData = viewFieldData.get(lookup);
            const parentField = this.$$createField(
              lookup,
              field,
              fieldData,
              fieldRevisions[lookup],
            );
            return fieldData
              .get('subfields', Map())
              .map((sfData, sfName) => {
                const lookup = `${setName}.${sfName}`;
                return this.$$createField(
                  lookup,
                  set.get(sfName),
                  sfData,
                  fieldRevisions[lookup],
                  1,
                );
              })
              .toList()
              .unshift(parentField);
          })
          .toList()
          .reduce((accum, fields) => accum.concat(fields), List());
      }

      $$processDoc(doc) {
        if (angular.isUndefined(doc.get('$metadata'))) {
          return doc.set('$docLoaded', false);
        }

        // metadata is retrieved, process it
        const viewData = doc.get('$metadata').get('view');
        const excludeSets = doc.get('$setFilters');
        const revList = doc.get('$revisions', List());
        const fieldRevisions = {};

        // find latest revision number
        revList.map((rev) => {
          this.$latestRevisionNumber = Math.max(
            this.$latestRevisionNumber,
            rev.get('revision_no'),
          );
        });

        const revIndex = Map(
          revList.map((rev) => [rev.get('revision_no'), this.$$formatRevision(rev)]),
        );

        // reverse because we want the latest revision first.
        revList.reverse().map((rev) => {
          const changes = rev.get('changes');
          if (changes) {
            changes.reduce((fieldRevisions, mdChange) => {
              const key = `${mdChange.get('schema')}.${mdChange.get('field')}`;
              if (!fieldRevisions[key]) {
                fieldRevisions[key] = [];
              }
              fieldRevisions[key].push(
                this.$$formatRevision(rev, mdChange.get('fmt_new_value')),
              );
              return fieldRevisions;
            }, fieldRevisions);
          }
        });

        const subfields = viewData
          .get('fields')
          .toList()
          .map((field) =>
            field
              .get('subfields', Map())
              .keySeq()
              .map((subfldName) => field.get('setName') + '.' + subfldName),
          )
          .reduce((accum, subflds) => accum.union(subflds), Set());

        const viewMd = doc
          .get('$metadata')
          .get('data')
          .filter((set, setName) => !excludeSets.has(setName))
          .map((set, setName) =>
            Map({
              name: setName,
              title: viewData.get('sets').get(setName, Map()).get('title'),
              fields: this.$$viewFields(
                set,
                setName,
                viewData,
                subfields,
                fieldRevisions,
              ),
            }),
          )
          .filter((set) => set.get('fields').size)
          .toList();

        // set updated metadata, doc is fully loaded now
        doc = doc.merge({
          $viewMetadata: viewMd || List(),
          $docLoaded: true,
        });

        // we have the template, update the metadata
        const template = doc.get('$template');

        // if doc doesn't have template (uploaded doc), we return early.
        if (!template) {
          return doc;
        }

        // populate sections' fields for display in metadata summary
        const sectionToFields = template.get('sections').map((sec) => {
          const form = sec.get('form');
          const formFields = form && form.get('fields');
          let fields = List();
          if (formFields && formFields.size) {
            fields = formFields
              .map((formField) => {
                const segs = formField.get('key').split('-'),
                  schema = segs[0],
                  name = segs[1],
                  key = `${schema}.${name}`;
                const set = viewMd.find((set) => set.get('name') === schema);
                return set
                  ? set.get('fields').find((field) => field.get('name') === key)
                  : null;
              })
              .filter((field) => field)
              .toList();
          }
          let secMd = sec.set('fields', fields).set('revisions', []);
          revList.map((rev) => {
            const textChange = rev.get('text_changes').get(sec.get('name'));
            if (textChange) {
              secMd
                .get('revisions')
                .push(
                  this.$$formatRevision(
                    rev,
                    textChange.get('diff_html'),
                    textChange.get('html'),
                  ),
                );
            }
          });
          secMd = secMd.set('revisions', fromJS(secMd.get('revisions').reverse()));
          if (secMd.get('changedSinceLastReview')) {
            secMd = secMd.set(
              'lastRevision',
              revIndex.get(secMd.get('changedInRevisionSinceLastReview')),
            );
          }

          return secMd;
        });

        // if section has text change or term change
        const changedSections = sectionToFields.filter((sec) => sec.get('hasChanges'));

        doc = doc
          .set('$sectionToFields', sectionToFields)
          .set('$changedSections', changedSections)
          .set('latestRevisionNumber', this.$latestRevisionNumber)
          .set('$viewMetadata', viewMd);
        return doc;
      }

      $$formatRevision(rev, value, currentValue) {
        return Map({
          value,
          currentValue,
          revisionNumber: rev.get('revision_no'),
          creatorName: rev.get('creator_name'),
          createdDate: rev.get('created_date'),
          myChange: rev.get('my_change'),
        });
      }

      $$mutateDoc(docId, mutator) {
        this.$documents = this.$documents.map((v) => {
          return v.get('id') === docId ? mutator(v) : v;
        });
      }

      $$load(http, defaultError, success) {
        this.$loading += 1;
        return SimpleHTTPWrapper(http, defaultError)
          .then(success, (err) => $q.reject(err))
          .finally(() => {
            this.$loading -= 1;
          });
      }

      $$loadTemplates(docId) {
        return this.$$load(
          {
            method: 'GET',
            url: this._baseUrlString + 'templates/' + docId + '/document',
            params: {
              customTextAlerts: this.$workitemData.get('customTextAlerts') || null,
            },
          },
          'Could not load document details.',
          (doc) => {
            const newDoc = this.$$processDoc(fromJS(doc));
            this.$$mutateDoc(docId, (oldDoc) =>
              newDoc
                .set('$recentChangesCount', oldDoc.get('$recentChangesCount'))
                .set('$templateEdits', oldDoc.get('$templateEdits')),
            );
            return newDoc;
          },
        );
      }

      /**
       * @ngdoc method
       * @name $saveDocs
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * save documents data to the model.
       *
       * @param {Array} docData documents data from review and edit api
       *
       * @returns {List} Documents
       */
      $saveDocs(docData) {
        const { documents } = docData;
        this.$documents = fromJS(documents).map((doc) => this.$$processDoc(doc));
        // if you there are changes by current user
        this.$hasPersistedChanges = Boolean(docData.hasPersistedChanges);
        // it has suggestions only NONE of the doc revisions are virtual (changes made by you).
        this.$hasSuggestions =
          this.$documents.every((doc) => doc.get('$lastRevisionNumber') !== null) &&
          this.$documents.some((doc) => doc.get('$recentChangesCount') > 0);
        return this.$documents;
      }

      /**
       * @ngdoc method
       * @name $initDocuments
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Load documents and worktiem data.
       *
       * @returns {promise} Returns a promise that either resolves when the load
       *    is complete (returns documents) or rejects with a reason.
       */
      $initDocuments() {
        return this.$$load(
          {
            method: 'GET',
            url: this._baseUrlString + 'index',
          },
          'Error loading documents.',
          (data) => {
            const {
              documentsData,
              workitemData,
              emailsData,
              rejectionForm,
              thirdPartyReviewForm,
              sharedDocuments,
              granterData,
            } = data;
            this.$rejectionForm = rejectionForm;
            this.$thirdPartyReviewForm = thirdPartyReviewForm;
            this.$workitemData = this.$workitemData.merge(workitemData);
            this.$emails = emailsData;
            this.$sharedDocuments = sharedDocuments;
            this.$granterData = granterData;
            return this.$saveDocs(documentsData);
          },
        );
      }

      /**
       * @ngdoc method
       * @name $loadDocumentFully
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Reload a document, its metadata and the template.
       *
       * @param {string} docId The ID of the document to update.
       *
       * @returns {promise} Returns a promise that either resolves when the load
       *    is complete (returns document) or rejects with a reason.
       */
      $loadDocumentFully(docId) {
        const doc = this.$documents.find((doc) => doc.get('id') === docId);
        const outstanding = doc.get('$$loadingPromise');
        if (outstanding) {
          return outstanding;
        }
        const templatePromise = EntityDocumentsService.$waitForDocumentToRender(
          doc.toJS(),
        )
          .$toPromise()
          .then(
            () => this.$$loadTemplates(docId),
            () => $q.reject(DOC_RENDER_FAILURE),
          );
        this.$$mutateDoc(docId, (doc) => doc.set('$$loadingPromise', templatePromise));
        return templatePromise;
      }

      /**
       * @ngdoc method
       * @name $saveTerms
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Save term data to the document.
       *
       * @param {string} docId The ID of the document to update.
       * @param {object} termData Object of `{ <setName>.<fieldName>: <value>... }`.
       * @param {object} customSections Object of `{ <sectionName>: {
       *     'text': <textOrNull>, ['title': <string>,] ['anchor': <string>],
       *     ['removed': <bool>,]}, ... }`.
       * @param {string} formattedTitle The new formatted title of the document if applicable.
       *
       * @returns {promise} Returns a promise that either resolves when the update
       *    is complete (returns newly updated documents) or rejects with a reason.
       */
      $saveTerms(docId, termData, customSections, formattedTitle) {
        const payload = { terms: termData, customSections };
        if (formattedTitle) {
          payload.formattedTitle = formattedTitle;
        }
        return this.$$load(
          {
            method: 'POST',
            url: this._baseUrlString + 'documents/' + docId,
            data: payload,
          },
          'Could not save document terms.',
          (data) => this.$saveDocs(data),
        );
      }

      /**
       * @ngdoc method
       * @name $saveAcceptanceInfo
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Save the accpetance data.
       *
       * @param {string} description The description of the change.
       *
       * @returns {promise} Returns a promise that either resolves when the update
       *   is complete or rejects with a reason.
       */
      $saveAcceptanceInfo(description) {
        return this.$$load(
          {
            method: 'POST',
            url: this._baseUrlString + 'index/acceptance',
            data: { description },
          },
          'Could not save accetance description.',
        );
      }

      /**
       * @ngdoc method
       * @name $saveRejectionInfo
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Save the rejection form data at `$rejectionInfoModel`.
       *
       * @returns {promise} Returns a promise that either resolves when the update
       *    is complete or rejects with a reason.
       */
      $saveRejectionInfo() {
        return this.$$load(
          {
            method: 'POST',
            url: this._baseUrlString + 'index/rejection',
            data: this.$rejectionInfoModel,
          },
          'Could not save rejection reason.',
        );
      }

      /**
       * @ngdoc method
       * @name $saveThirdPartyReviewInfo
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Save the third party review form data at `$thirdPartyReviewInfoModel`.
       *
       * @param {documents} list of document ids to review.
       *
       * @returns {promise} Returns a promise that either resolves when the update
       *    is complete or rejects with a reason.
       */
      $saveThirdPartyReviewInfo(documents) {
        const data = angular.copy(this.$thirdPartyReviewInfoModel);
        data.documents = documents;
        return this.$$load(
          {
            method: 'POST',
            url: this._baseUrlString + 'index/thirdPartyReview',
            data: data,
          },
          'Could not save third party review data.',
        );
      }

      /**
       * @ngdoc method
       * @name $rejectSuggestions
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Remove all suggestion changes on this workitem.
       *
       * @returns {promise} Returns a promise that either resolves when the revert
       *    is complete (returns newly updated documents) or rejects with a reason.
       */
      $rejectSuggestions() {
        return Promise.all(
          this.$documents
            .filter((doc) => doc.get('$lastRevisionNumber') > 0)
            .map((doc) => {
              return this.$$load(
                {
                  method: 'POST',
                  data: { revision: doc.get('$lastRevisionNumber') },
                  url:
                    this._baseUrlString +
                    'documents/' +
                    doc.get('id') +
                    '/restore-revision',
                },
                'Could not revert changes.',
              );
            }),
        ).then(() =>
          this.$initDocuments().then(() => {
            this.$changesUndone = true;
          }),
        );
      }

      /**
       * @ngdoc method
       * @name $revertChanges
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Revert all outstanding changes to the documents.
       *
       * @returns {promise} Returns a promise that either resolves when the revert
       *    is complete (returns newly updated documents) or rejects with a reason.
       */
      $revertChanges() {
        return this.$$load(
          {
            method: 'DELETE',
            url: this._baseUrlString + 'documents/changes',
          },
          'Could not revert changes.',
        ).then(() => this.$initDocuments());
      }

      /**
       * @ngdoc method
       * @name $shareDocuments
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description
       * Share documents with recipients.
       *
       * @param {object} emailData Description of share data:
       *   @property {string} emailSubject Subject line text
       *   @property {string} emailBody Body text of the share email.
       *   @property {array<stakeholder>} recipients List of stakeholders to share with
       * @param {array<document_id>} documents to share
       *
       * @returns {promise} Returns a promise that either resolves when the revert
       *    is complete (returns newly updated documents) or rejects with a reason.
       */
      $shareDocuments(emailData, documents) {
        const data = angular.copy(emailData);
        data.documents = documents;
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'index/share-docs',
            data,
          },
          'Failed to share documents.',
        ).then((sharedDocuments) => {
          this.$sharedDocuments = sharedDocuments;
        });
      }

      /**
       * @ngdoc method
       * @name $revokeAccess
       * @methodOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
       *
       * @description Revoke shared documents access for a list of stakeholders
       *
       * @param {object} stakeholders a list of stakeholder ids
       *
       */
      $revokeAccess(stakeholderId, title) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'index/revoke-access',
            data: { stakeholderIds: [stakeholderId] },
          },
          'Failed to revoke document access.',
        ).then((sharedDocuments) => {
          this.$sharedDocuments = sharedDocuments;
          this.$accessRevokedMessage = `Access for ${title} has been revoked`;
        });
      }
    }

    return (baseUrlString) => new Model(baseUrlString);
  },
]; // end ReviewAndEditDocumentsModel

/**
 * @ngdoc object
 * @name sb.workitem.reviewAndEditDocuments.ShareDocumentsModalCtrl
 *
 * @description
 *
 * This is the controller for the multi-step share documents modal.
 *
 */
export const ShareDocumentsModalCtrl = [
  '$scope',
  '$q',
  'FormSubmitResolve',
  'SerializeAndSubmitProcessForm',
  'Title',
  'Persist',
  '$confirm',
  function (
    $scope,
    $q,
    FormSubmitResolve,
    SerializeAndSubmitProcessForm,
    Title,
    Persist,
    $confirm,
  ) {
    function submitModal() {
      const resolver = FormSubmitResolve($scope, $scope.formFeedback);
      $scope.$error = null;

      let form = null;
      if ($scope.reviewTypeData.review_type === 'view_only') {
        form = $scope.formFeedback.$$form.formly_shareEmailForm;
      } else if ($scope.reviewTypeData.review_type === 'review_and_edit') {
        form = $scope.formFeedback.$$form.formly_thirdPartyReviewForm;
      }
      form.$setSubmitted();
      if (!form.$valid) {
        return;
      }

      resolver
        .resolve()
        .then(() => {
          const selectedDocuments = $scope.selectDocumentsData.selectedDocuments;

          if ($scope.reviewTypeData.review_type === 'view_only') {
            return Persist.$shareDocuments(
              $scope.shareEmailFormData,
              selectedDocuments,
            ).then(() => {
              $scope.$close();
              $confirm({
                title: 'The documents have been shared',
                body: `Once you proceed past this checkpoint,
                     the draft documents shared will no longer be accessible.</p>
                     <p>You may navigate away from this page and resume
                    this workflow after you have gathered feedback.</p>`,
                alertType: 'info',
                confirmButtonText: false,
                dismissButtonText: 'Close',
              }).then(angular.noop, angular.noop);
            });
          } else if ($scope.reviewTypeData.review_type === 'review_and_edit') {
            let warnIfRewieversLooseAccess = $q.resolve();
            if (Persist.$sharedDocuments.length) {
              const names = Persist.$sharedDocuments
                .map((share) => share.stakeholder.title)
                .join(', ');
              warnIfRewieversLooseAccess = $confirm({
                title: 'Review and Edit',
                body: `<p>You are about to start a document review.
                 This will revoke temporary document access you provided
                 to following users: ${names}.</p>
                 <p>Please make sure you've received the feedback you need.</p>`,
                alertType: 'warning',
                confirmButtonText: 'Continue',
                dismissButtonText: 'Cancel',
              });
            }

            return warnIfRewieversLooseAccess.then(
              () =>
                Persist.$saveThirdPartyReviewInfo(selectedDocuments)
                  .then(() => SerializeAndSubmitProcessForm('third_party_review'))
                  .then(() => {
                    $scope.$close();
                  }),
              () => {
                // Canceled, keep the dialog open.
              },
            );
          }
          return null;
        })
        .catch((error) => {
          $scope.$error = error || '';
        });
    }

    $scope.title = Title;
    $scope.errors = {};
    $scope.persist = Persist;
    $scope.documents = Persist.$documents;

    $scope.allowThirdPartyReview = Persist.$workitemData.get('allowThirdPartyReview');
    if ($scope.allowThirdPartyReview) {
      $scope.reviewTypeFields = SHARE_DOCUMENTS_REVIEW_TYPE_FIELDS();
      $scope.reviewTypeErrors = {};
      $scope.reviewTypeData = {};
    } else {
      $scope.reviewTypeData = {
        /* eslint-disable camelcase */
        review_type: 'view_only',
        descriptionText: SHARE_DOCUMENTS_VIEW_ONLY_TEXT,
      };
    }

    $scope.noDocumentsSelected = () =>
      !$scope.selectDocumentsData ||
      !$scope.selectDocumentsData.selectedDocuments ||
      !$scope.selectDocumentsData.selectedDocuments.length;

    $scope.thirdPartyReviewErrors = {};

    $scope.selectDocumentsFields = SELECT_DOCUMENTS_FIELDS($scope.documents.toJSON());
    $scope.selectDocumentsErrors = {};
    $scope.selectDocumentsData = {};

    $scope.shareEmailFormFields = SHARE_DOCUMENTS_EMAIL_FIELDS(
      Persist.$granterData.entity_title,
      Persist.$granterData.granter_title,
    );
    $scope.shareEmailFormErrors = {};
    $scope.shareEmailFormData = {};

    $scope.submitModal = submitModal;
  },
]; // end ShareDocumentsModalCtrl

/**
 * @ngdoc directive
 * @name sb.workitem.reviewAndEditDocuments.directive:sbReviewAndEditDocuments
 * @restrict E
 *
 * @description
 * The controller/element for a review_and_edit_documents WI.
 *
 * @param {boolean} [legalReview=false] If truthy, the workitem will be in legal
 *   mode.
 */
export function sbReviewAndEditDocuments() {
  return {
    restrict: 'E',
    template: require('./templates/workitem.html'),
    scope: {
      legalReview: '<?',
    },
    controller: [
      '$scope',
      '$compile',
      '$q',
      '$confirm',
      '$formModal',
      '$sbModal',
      'ProcessStatus',
      'ProcessButtonModel',
      'ReviewAndEditDocumentsModel',
      '$editTermsModal',
      'BackendLocation',
      'PromiseErrorCatcher',
      '$selectEditResolutionModal',
      function (
        $scope,
        $compile,
        $q,
        $confirm,
        $formModal,
        $sbModal,
        ProcessStatus,
        ProcessButtonModel,
        ReviewAndEditDocumentsModel,
        $editTermsModal,
        BackendLocation,
        PromiseErrorCatcher,
        $selectEditResolutionModal,
      ) {
        const BASE_URL = BackendLocation.context(1);
        const persist = ReviewAndEditDocumentsModel(BASE_URL);

        function catchError(promise) {
          return promise.catch((err) => {
            ProcessStatus.$setStatus(err, 'danger');
            return $q.reject(err);
          });
        }

        function modalButtonText(doc) {
          const isEditable = doc.get('$editable');
          if (isEditable) {
            // Ideally we would also check if doc really has some editable
            // fields, but we can't do that without loading metadata,
            // and we only load it on demand as an optimization for
            // large sets of reviewable docs (100+)
            return 'Edit Document';
          }
          return 'Review Document';
        }

        function openLegalAcceptModal() {
          if (!persist.$hasPersistedChanges) {
            return $q.resolve();
          }
          return $formModal({
            title: 'Describe Changes',
            primaryButtonText: 'Accept',
            forms: {
              acceptLegalChangesForm: {
                fields: angular.copy(ACCEPT_LEGAL_CHANGES_FORM),
              },
            },
            formData: { acceptLegalChangesForm: {} },
            htmlContent: require('./templates/accept-legal-changes-modal.html'),
            onConfirmPromise({ $formData: { acceptLegalChangesForm } }) {
              return persist.$saveAcceptanceInfo(acceptLegalChangesForm.description);
            },
          });
        }

        function warnIfReviewersLooseAccess() {
          if (!persist.$sharedDocuments.length) {
            return $q.resolve();
          }
          return $confirm({
            title: 'Continue',
            body: `<p>You have granted users temporary access to some or all
                 of these documents.  When you continue past this checkpoint
                 that access will expire, so you should only continue once
                 you've gotten the feedback you need.</p>

                 <p>If you need to grant access to any of the final documents,
                 you can do so from your Data Room after the workflow has
                 completed.</p>`,
            alertType: 'warning',
            confirmButtonText: 'Continue',
            dismissButtonText: 'Cancel',
          });
        }

        function openRejectModal() {
          const modal = $sbModal.open({
            template: require('./templates/rejection-info-modal.html'),
            controller: [
              '$scope',
              function ($scope) {
                function save() {
                  $scope.mainError = null;
                  $scope.mainForm.formly_rejectionForm.$setSubmitted(true);
                  $scope.rejectionFeedback
                    .submittingForm()
                    .then(() => persist.$saveRejectionInfo())
                    .then(
                      () => {
                        $scope.$close();
                      },
                      (err) => {
                        $scope.mainError = err;
                      },
                    );
                }

                $scope.persist = persist;
                $scope.formFields = angular.copy(persist.$rejectionForm.fields);
                $scope.save = save;
                $scope.rejectionErrors = {};
              },
            ],
          });
          return modal.result.catch(() => $q.reject());
        }

        function openShareDocumentsModal() {
          $sbModal
            .open({
              windowClass: 'share-documents-modal wide-modal',
              template: require('./templates/share-documents-modal.html'),
              controller: 'ShareDocumentsModalCtrl',
              resolve: {
                Title: () => 'Circulate Documents',
                Persist: () => persist,
              },
            })
            .result.catch(PromiseErrorCatcher);
        }

        function stopAccordion(evt) {
          // So accordion doesn't toggle, we stop the event from bubbling.
          evt.stopPropagation();
          evt.preventDefault();
        }

        function openEditModal(evt, doc, openSection) {
          if (!persist.$loading) {
            catchError(persist.$loadDocumentFully(doc.get('id')))
              .then((doc) => {
                $editTermsModal(doc, persist, BASE_URL, openSection).catch(
                  PromiseErrorCatcher,
                );
              })
              .catch(PromiseErrorCatcher);
          }
          stopAccordion(evt);
        }

        function showDocEditButton(doc) {
          return (
            doc.get('$viewable') &&
            !doc.get('isFinalized') &&
            (!persist.$workitemData || !persist.$workitemData.get('reviewOnly'))
          );
        }

        function isDocResolution(doc) {
          return doc.get('$editableAsResolution');
        }

        function openEditResolutionModal(evt, doc) {
          if (!persist.$loading) {
            catchError(persist.$loadDocumentFully(doc.get('id')))
              .then((doc) => {
                const hasVoting =
                  doc.get('templateBaseName') === BOARD_MINUTES_TEMPLATE_NAME;
                $selectEditResolutionModal({ doc, isWorkitem: false, hasVoting }).catch(
                  PromiseErrorCatcher,
                );
              })
              .catch(PromiseErrorCatcher);
          }
          stopAccordion(evt);
        }

        function undoChanges() {
          persist.$revertChanges();
        }
        function rejectSuggestions() {
          persist.$rejectSuggestions();
        }

        function revokeAccess(stakeholder) {
          persist.$revokeAccess(stakeholder.id, stakeholder.title);
        }

        $scope.persist = persist;
        $scope.undoChanges = undoChanges;
        $scope.rejectSuggestions = rejectSuggestions;
        $scope.openEditModal = openEditModal;
        $scope.isDocResolution = isDocResolution;
        $scope.showDocEditButton = showDocEditButton;
        $scope.openEditResolutionModal = openEditResolutionModal;
        $scope.openShareDocumentsModal = openShareDocumentsModal;
        $scope.revokeAccess = revokeAccess;
        $scope.modalButtonText = modalButtonText;
        $scope.stopAccordion = stopAccordion;

        let continueCondition = warnIfReviewersLooseAccess;

        if ($scope.legalReview) {
          const prevCondition = continueCondition;
          continueCondition = () => prevCondition().then(openLegalAcceptModal);
        }

        ProcessButtonModel.$addSubmitCondition('continue', continueCondition, 1);
        ProcessButtonModel.$addSubmitCondition('reject', openRejectModal, 1);

        $scope.$watch(
          () => persist.$loading > 0,
          ProcessButtonModel.$disableWatch('continue'),
        );

        catchError(persist.$initDocuments()).catch(PromiseErrorCatcher);
      },
    ], // end controller
  };
} // end sbReviewAndEditDocuments

/**
 * @ngdoc component
 * @name sb.workitem.reviewAndEditDocuments.component:sbReviewDocumentMetadata
 *
 * @description
 * Small component for review and edit documents to render the metadata inside
 * the accordion.
 */
export const sbReviewDocumentMetadata = {
  controllerAs: 'vm',
  template: require('./templates/document-metadata.html'),
  bindings: {
    document: '<',
    model: '<',
    openEditModal: '<',
  },
  controller: [
    '$scope',
    '$sbModal',
    'PromiseErrorCatcher',
    'viewTextDiff',
    function ($scope, $sbModal, PromiseErrorCatcher, viewTextDiff) {
      $scope.viewTextDiff = viewTextDiff;

      function templateReviewAcceptanceText(doc) {
        const md = doc.get('$lastTemplateAcceptance');
        if (!md) {
          return undefined;
        }
        const docTempRev = doc.get('templateRevision');
        const revisionText =
          docTempRev === md.get('revision') ? '' : 'a different version of ';
        return (
          `You last accepted ${revisionText}this template for ` +
          `${md.get('entityName')} on ${md.get('timestamp')}.`
        );
      }

      function isEditableSection(doc, sec) {
        if (doc.get('$editable')) {
          // If we've disabled Document Customization, only show edit icon
          // for items that have associated term fields
          const wiData = $scope.vm.model.$workitemData;
          if (wiData && wiData.get('isDocumentCustomizationDisabled')) {
            return Boolean(sec.get('fields').size);
          }

          return true;
        }
        return false;
      }

      function openTemplateReviewModal(doc) {
        $sbModal
          .open({
            template: require('./templates/review-template-modal.html'),
            controller: [
              '$scope',
              function ($scope) {
                $scope.template = {
                  title: doc.get('templateTitle'),
                  baseName: doc.get('templateBaseName'),
                  initRevision: doc.get('templateRevision', null),
                };
              },
            ],
            windowClass: 'review-template-modal',
            size: 'lg',
          })
          .result.catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        $scope.templateReviewAcceptanceText = templateReviewAcceptanceText;
        $scope.openTemplateReviewModal = openTemplateReviewModal;
        $scope.isEditableSection = isEditableSection;
        $scope.$watch(
          () => {
            return (
              this.model.$openPanels[this.document.get('id')] &&
              !this.document.get('$docLoaded')
            );
          },
          (nv) => {
            if (nv) {
              this.model
                .$loadDocumentFully(this.document.get('id'))
                .catch(PromiseErrorCatcher);
            }
          },
        );
      };
    },
  ], // end controller
}; // end sbReviewDocumentMetadata

export function sbRefPopovers() {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const references$ = scope.$eval(attrs.sbRefPopovers);
      let refs = null;

      references$.subscribe(({ references }) => {
        refs = references;
      });

      element.popover({
        content: function () {
          const id = this.getAttribute('data-reference-id');
          const ref = refs.find((ref) => ref.value === id);
          return ref ? ref.title : '';
        },
        selector: '.richtext-reference-pill',
        html: true,
        placement: 'auto',
        trigger: 'hover',
        container: 'body',
      });
    },
  };
} // end sbRefPopovers
