import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SbxTopNavComponent } from './sbx-top-nav.component';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxClickOutsideModule } from '../sbx-click-outside/sbx-click-outside.module';
import { SbxConfirmationModalModule } from '../sbx-confirmation-modal/sbx-confirmation-modal.module';

@NgModule({
  imports: [
    RouterModule.forChild([]),
    CommonModule,
    SbxGridModule,
    SbxLoaderModule,
    SbxIconModule,
    SbxClickOutsideModule,
    SbxConfirmationModalModule,
  ],
  declarations: [SbxTopNavComponent],
  exports: [SbxTopNavComponent],
})
export class SbxTopNavModule {}
