<sbx-search-bar
  [rowTemplate]="resultTemplate"
  [searchResults]="searchStakeholderList"
  [placeholderText]="placeholderText"
  [disableAutoComplete]="true"
  (select)="selectItem($event)"
>
</sbx-search-bar>
<ng-template #resultTemplate let-item let-i="index">
  <li
    [ngClass]="{
      'sbx-bordered-stakeholder':
        item.type === 'person' ||
        (item.type === 'team' && !item.parentTitle) ||
        item.type === 'stake',
      'sbx-child-stakeholder': item.type === 'team' && item.parentTitle,
      'sbx-error': item.id === 'searchError',
      'sbx-unselectable': item.$unselectable,
      'sbx-selected-item': i === keySelectedIndex
    }"
    [attr.typeahead-suggestion-id]="item[trackBy || 'id']"
  >
    <span class="sbx-typeahead-item">
      <div class="sbx-typeahead-padding" *ngIf="!item.$displayName && !item.icon">
        <sbx-stakeholder-card
          [stakeholderObject]="item"
          format="medium"
          [highlightTerm]="queryText"
        >
        </sbx-stakeholder-card>
      </div>
      <span *ngIf="item.$displayName" [innerHTML]="item.$displayName"></span>
      <div *ngIf="item.icon && !item.$displayName" class="sbx-typeahead-padding">
        <span class="sbx-circle-icon">
          <i class="fas {{ item.icon }}"></i>
        </span>
        <b>{{ item.label }}</b>
      </div>
    </span>
  </li>
</ng-template>
