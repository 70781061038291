<sbx-row *ngIf="data">
  <sbx-col [lg]="1" [sm]="4"
    ><sbx-icon class="sbx-document-icon" [type]="getIcon(data.displayType)"></sbx-icon
  ></sbx-col>
  <sbx-col [lg]="11" [sm]="8" class="sbx-document-details">
    <sbx-row>
      <sbx-col [lg]="10" [sm]="8" class="sbx-document-title">
        {{ data.title }}
      </sbx-col>
      <sbx-col [lg]="2" [sm]="4" class="sbx-document-back">
        <ng-container *ngTemplateOutlet="documentBack"></ng-container>
      </sbx-col>
    </sbx-row>

    <sbx-row>
      <sbx-col [lg]="12" [sm]="12">
        <ul class="sbx-document-details-list">
          <li *ngFor="let item of items" [class]="'document-header-' + item.key">
            <ng-container [ngSwitch]="item.key">
              <ng-container *ngSwitchCase="'displayType'">
                <ng-container
                  *ngTemplateOutlet="typeTemplate; context: { $implicit: item }"
                >
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'folders'">
                <ng-container
                  *ngTemplateOutlet="foldersTemplate; context: { $implicit: item }"
                >
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="default; context: { $implicit: item }">
                </ng-container>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </sbx-col>
    </sbx-row>
    <sbx-row>
      <sbx-col [lg]="12" [sm]="12">
        <ul class="sbx-document-buttons-list">
          <li *ngIf="!data.isDeferred">
            <sbx-document-download-dropdown
              [docId]="documentId"
              [canExport]="data.canExport"
              [canDownload]="data.canDownload"
              [canDownloadRevisionHistory]="data.canDownloadRevisionHistory"
              [canDownloadWatermarked]="data.canDownloadWatermarked"
              [canDownloadCustomization]="data.canDownloadCustomization"
            >
            </sbx-document-download-dropdown>
          </li>
          <li *ngIf="data.isDeferred && data.uploadAllowed">
            <sbx-upload-missing-document
              [saveUrl]="data.uploadUrl"
              [apiResource]="data.uploadApiResource"
              [urlVersion]="data.urlVersion"
              id="upload"
              class="upload-doc"
            >
            </sbx-upload-missing-document>
          </li>
          <li *ngIf="!data.isDeferred && data.reuploadAllowed">
            <sbx-upload-missing-document
              title="Re-upload Document"
              header="Edit Document"
              text="Re-uploading the document will not change any of its associated data. If you need to
              correct any of the information about this document, contact Fidelity Private Shares
              support."
              [required]="true"
              [saveUrl]="data.reUploadUrl"
              [apiResource]="data.reUploadApiResource"
              [urlVersion]="data.urlVersion"
              id="reupload"
              class="upload-doc"
            >
            </sbx-upload-missing-document>
          </li>
          <li *ngIf="data.canEditTitle">
            <sbx-button
              title="Edit Title"
              class="document-header-edit-title"
              [icon]="'edit'"
              (click)="open(documentId)"
            >
            </sbx-button>
          </li>
        </ul>
      </sbx-col>
    </sbx-row>
  </sbx-col>
</sbx-row>

<ng-template #documentBack>
  <ng-container
    *ngIf="isSpaPage && sbxUrlService.spaUrl(this.backUrl); else regularLink"
  >
    <a
      [routerLink]="sbxUrlService.spaUrl(this.backUrl).url"
      [queryParams]="sbxUrlService.spaUrl(this.backUrl).queryParams"
    >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-container>
  <ng-template #regularLink>
    <a [href]="this.backUrl" class="sbx-back-url">
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>
</ng-template>

<ng-template #linkContent>
  <sbx-icon type="chevronLeft"></sbx-icon>
  <span>{{ backText }}</span>
</ng-template>

<ng-template #typeTemplate let-item>
  <span *ngIf="item.value === 'generated'">
    <sbx-icon [type]="getIcon(data.displayType)"></sbx-icon> Created in Fidelity Private
    Shares
  </span>
  <span *ngIf="item.value === 'uploaded'">
    <sbx-icon [type]="getIcon(data.displayType)"></sbx-icon> Uploaded
  </span>
  <span *ngIf="item.value === 'deferred'">
    <sbx-icon [type]="getIcon(data.displayType)"></sbx-icon> Missing Document
  </span>
  <span *ngIf="item.value === 'executed'">
    <sbx-icon [type]="getIcon(data.displayType)"></sbx-icon> Uploaded and Executed
  </span>
</ng-template>

<ng-template #foldersTemplate let-item>
  <sbx-icon [type]="item.icon"></sbx-icon>
  <sbx-popover
    [sbxPopoverContent]="popoverContent"
    [sbxPopoverTemplateContext]="item.popup"
    [sbxPopoverAutoWidth]="true"
  >
    {{ item.value }}
  </sbx-popover>
</ng-template>

<ng-template #popoverContent let-folders>
  <div class="sbx-folder-popup">
    <span *ngIf="folders.length > 1"
      >This document can be found in multiple folders.</span
    >
    <ul>
      <li *ngFor="let folder of folders">
        <sbx-icon type="folder"></sbx-icon>
        <span>{{ folder }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #default let-item>
  <sbx-icon [type]="item.icon"></sbx-icon>
  <span *ngIf="item.popup">
    <sbx-popover
      [sbxPopoverContent]="popoverContent"
      [sbxPopoverTemplateContext]="item.popup"
      [sbxPopoverAutoWidth]="true"
    >
      {{ item.value }}
    </sbx-popover>
  </span>
  <span *ngIf="!item.popup">
    {{ item.value }}
  </span>
</ng-template>
