import angular from 'angular';
import ngAnimate from 'angular-animate';
import Modal from '../modal/modal';
import FormCreation from './form-creation';
import { checkForSuspiciousTypes } from '../../../app/shared/utils/file.util';

/**
 * @ngdoc object
 * @name sb.lib.pdf.controller:UploadCtrl
 * @requires https://docs.angularjs.org/api/ng/type/$rootScope.Scope
 * @requires https://docs.angularjs.org/api/ng/service/$http
 * @requires https://docs.angularjs.org/api/ng/service/$timeout
 * @requires lib/sb.process.ProcessButtonModel
 * @requires lib/sb.url.BackendLocation
 *
 * @description
 * This is the controller for working with uploadstorage APIs.
 * It is used with `sbPdfUpload`, but can also be used on its own.
 */
const UploadCtrl = [
  '$scope',
  '$http',
  '$timeout',
  '$sbModal',
  'PromiseErrorCatcher',
  'ProcessButtonModel',
  'BackendLocation',
  function (
    $scope,
    $http,
    $timeout,
    $sbModal,
    PromiseErrorCatcher,
    ProcessButtonModel,
    BackendLocation,
  ) {
    const TICK_RATE = 1000;
    const UPLOAD_SIZE_LIMIT = 100 * 1024 * 1024;

    let reloadTimeout;

    $scope.fileInit = {
      ready: true,
      pdfURL: '',
      name: '',
    };

    function cancelReloadTimeout() {
      if (reloadTimeout) {
        $timeout.cancel(reloadTimeout);
      }
      reloadTimeout = null;
    }

    function setError(data) {
      $scope.uploadError = data.error || 'There was an error.';
      $scope.loading = false;
    }

    function processResult(res) {
      $scope.uploadError = null;
      const file = ($scope.$parent.file = res || $scope.fileInit);
      $scope.$parent.uploadId = res.id || '';
      $scope.$parent.uploadIdChange({ uploadId: res.id, file: res });
      $scope.$parent.fileChange(res);
      if (file.infoMessage) {
        $scope.infoMessages = file.infoMessage.split('\n');
      } else {
        $scope.infoMessages = null;
      }
      if (file.failed) {
        setError(res);
      }
      if (file.ready === false) {
        $scope.loading = true;
        cancelReloadTimeout();
        reloadTimeout = $timeout(() => doFetch(), TICK_RATE);
      } else {
        // nothing uploaded or conversion complete
        $scope.loading = false;
      }

      $scope.downloadSourceURL = null;
      $scope.downloadURL = null;
      $scope.previewURL = null;
      $scope.enablePreview =
        typeof $scope.$parent.enablePreview === 'undefined'
          ? true
          : $scope.$parent.enablePreview;
      if (file.ready && file.id) {
        $scope.downloadSourceURL = $scope.backendURL + '/download?id=' + file.id;
        $scope.downloadURL = $scope.backendURL + '/pdf?id=' + file.id;
        $scope.previewURL = $scope.backendURL + '/preview?id=' + file.id;
      }
      // assign pdfURL to file be passed along to sbDocumentReference
      $scope.$parent.file.pdfURL = $scope.previewURL;

      $scope.reviewable =
        file.ready && !file.failed && file.id && !$scope.$parent.disableConversion;
      if (file.id && !file.readonly) {
        $scope.deleteFile = doDelete;
      } else {
        $scope.deleteFile = null;
      }
    }

    $scope.$watch('loading', (nv) => {
      if (nv) {
        ProcessButtonModel.disable('continue');
      } else {
        ProcessButtonModel.requestEnable('continue');
      }
    });

    function doRequest(method, endpoint, data) {
      let params;
      if (method === 'GET') {
        params = data;
        data = undefined;
      }

      $scope.loading = true;
      $scope.uploadError = null;
      cancelReloadTimeout();
      $http({
        method: method,
        transformRequest: angular.identity,
        url: $scope.backendURL + '/' + endpoint,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: params,
        data: data,
      }).then(
        (response) => {
          processResult(response.data);
        },
        (response) => {
          setError(response.data || {});
        },
      );
    }

    function doFetch(uploadId) {
      if (angular.isUndefined(uploadId)) {
        uploadId = $scope.$parent.uploadId || '';
      }
      doRequest('GET', 'fetch', {
        id: uploadId,
        deletePrev: $scope.$parent.deletePreviousUpload,
      });
    }

    function doDelete() {
      doRequest('POST', 'delete', 'id=' + ($scope.$parent.uploadId || ''));
    }

    $scope.$parent.filename = function () {
      if ($scope.uploadError) {
        return $scope.uploadError;
      }
      return $scope.file.id ? $scope.file.name : 'Choose a file...';
    };
    $scope.loading = true;
    $scope.$parent.file = $scope.fileInit;
    $scope.reload = doFetch;

    $scope.$on('UploadCtrl::reload', (e, uploadId) => {
      doFetch(uploadId);
    });

    // We need to wait for directive setup and a valid URL.
    const watchAPIResource = $scope.$parent.$watch(
      () => $scope.$parent.apiResource(),
      (res) => {
        if (res) {
          const backend = BackendLocation.context(1);
          $scope.backendURL = backend + res;
          doFetch();
          $scope.deleteFile = null;
          $scope.finished = function () {
            return !$scope.loading && !$scope.uploading && $scope.file.id;
          };

          watchAPIResource();
        }
      },
    );

    $scope.isFileValid = function (file) {
      const typeCompatibility = checkForSuspiciousTypes(file);
      if (typeCompatibility?.error) {
        setError({ error: typeCompatibility.errorMsg });
        return false;
      }
      if (file.size > UPLOAD_SIZE_LIMIT) {
        setError({ error: 'File size exceeds 100MB' });
        return false;
      }

      return true;
    };

    $scope.uploadFile = async function (event) {
      const name = event.target.name;
      const file = event.target.files[0];

      if (!$scope.isFileValid(file)) {
        return;
      }

      $scope.uploadError = null;
      $scope.loading = true;
      $scope.uploading = true;
      $scope.progress = 0;

      try {
        const data = new FormData();
        data.append(name, file);

        const response = await $http({
          method: 'POST',
          url: $scope.backendURL,
          data,
          headers: { 'Content-Type': undefined },
          uploadEventHandlers: {
            progress: (e) => {
              if (e.lengthComputable) {
                $scope.progress = (e.loaded / e.total) * 100;
              }
            },
          },
        });
        processResult(response.data);
      } catch (e) {
        setError(e.data || {});
        $scope.$parent.file = $scope.fileInit;
        ProcessButtonModel.requestEnable('continue');
      } finally {
        $scope.uploading = false;
        $scope.progress = 0;
      }
    };

    $scope.previewModal = function (pdfURL, reject) {
      $sbModal
        .open({
          template: require('./templates/pdf-review-modal.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.pdfURL = pdfURL;
              if (reject) {
                $scope.reject = function () {
                  reject();
                  $scope.$dismiss();
                };
              }
            },
          ],
        })
        .result.catch(PromiseErrorCatcher);
    };

    $scope.$on('$destroy', () => {
      cancelReloadTimeout();
      if ($scope.loading) {
        ProcessButtonModel.requestEnable('continue');
      }
    });
  },
]; // end UploadCtrl

/**
 * @ngdoc directive
 * @name sb.lib.pdf.directive:sbPdfUpload
 * @restrict EA
 *
 * @description
 * This creates a PDF upload widget. It is intended for use with an upload
 * storage.
 *
 * @element ANY
 * @param {expression} sbPdfUpload The model of the uploaded file.
 * @param {expression} sbPdfUploadId Uploaded file storage folder ID.
 * @param {expression} sbPdfUploadApiResource WebAPI resource path for
 *                                            backend locatoin.
 * @param {expression} sbPdfBackendLocation Optional, provide different
 *                                          backend location than current one.
 *
 * @example
   <div
      sb-pdf-upload="model.file"
      sb-pdf-upload-id="model.uploadId"
      sb-pdf-upload-api-resource="'upload/pdf'"
   ></div>
 */
function sbPdfUpload() {
  return {
    restrict: 'EA',
    template: require('./templates/pdf-upload.html'),
    scope: {
      file: '=?sbPdfUpload',
      apiResource: '&?sbPdfUploadApiResource',
      uploadId: '=?sbPdfUploadId',
      uploadIdChange: '&',
      fileChange: '&',
      deletePreviousUpload: '=?sbPdfDeletePreviousUpload',
      enablePreview: '=?sbEnablePreview',
    },
    link: function ($scope, el) {
      // At the time of writing this event is used for functional tests
      // to inject the uploadId that was created directly in the backend.
      el.on('reload.sbPdfUpload', (e, uploadId) => {
        $scope.$broadcast('UploadCtrl::reload', uploadId);
        e.stopPropagation();
      });
    },
  };
} // end sbPdfUpload

/**
 * @ngdoc directive
 * @name sb.lib.pdf.directive:sbDomRemovableScope
 * @restrict EA
 *
 * @description
 * This creates a local scope that will be destroyed when the DOM element
 * is removed manually. It is intended for use with our old modal dialogs,
 * where html is replaced manually -- which caused scope bindings to leak.
 *
 * @element ANY
 *
 * @example
   <div class="remove-me-with-direct-dom-manipulation">
     <sb-dom-removable-scope>
       ... something that binds listeners to local scope ...
     </sb-dom-removable-scope>
   </div>
 */
function sbDomRemovableScope() {
  return {
    restrict: 'EA',
    scope: true,
    link(scope, element) {
      element.on('$destroy', () => scope.$destroy());
    },
  };
} // end sbDomRemovableScope

function sbOnChange() {
  return {
    scope: {
      sbOnChange: '&',
    },
    link(scope, element) {
      element.on('change', (event) => {
        scope.$apply(() => {
          scope.sbOnChange({ $event: event });
        });
      });
      scope.$on('$destroy', () => {
        element.off();
      });
    },
  };
}

/**
 * @ngdoc overview
 * @name sb.lib.pdf
 * @requires https://docs.angularjs.org/api/ngAnimate
 *
 * @description
 * The sbPdf module has helpers for PDF widgets/functions.
 */
export default angular
  .module('sb.lib.pdf', [ngAnimate, Modal, FormCreation])
  .controller('UploadCtrl', UploadCtrl)
  .directive('sbPdfUpload', sbPdfUpload)
  .directive('sbDomRemovableScope', sbDomRemovableScope)
  .directive('sbOnChange', sbOnChange).name;
