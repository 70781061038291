import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-checklist',
  templateUrl: './sbx-checklist.component.html',
  styleUrls: ['./sbx-checklist.component.scss'],
})
export class SbxChecklistComponent extends FieldType<FieldTypeConfig> {
  isChecked(key: string) {
    return this.field.formControl.value.includes(key);
  }

  handleChange(key: string) {
    if (this.isChecked(key)) {
      this.formControl.setValue(this.field.formControl.value.filter((v) => v !== key));
      return;
    }

    this.formControl.setValue([...this.field.formControl.value, key]);
  }
}
