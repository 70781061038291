import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import {
  sbStakeholderEmailSelector,
  sbStakeholderEmailPage,
  StakeholderProfileEmailModel,
} from './email';
import { sbStakeholderAccountRecovery } from './recovery';

/**
 * @ngdoc overview
 * @name sb.stakeholderProfile
 *
 * @description
 * This is the module for stakeholder profile.
 */
export default angular
  .module('sb.stakeholderProfile', [ngSanitize])

  .component('sbStakeholderEmailSelector', sbStakeholderEmailSelector)
  .component('sbStakeholderEmailPage', sbStakeholderEmailPage)
  .component('sbStakeholderAccountRecovery', sbStakeholderAccountRecovery)
  .factory('StakeholderProfileEmailModel', StakeholderProfileEmailModel).name;
