<div class="sbx-wi-panel">
  <span class="sbx-wi-panel-title">
    {{ item.title }}
  </span>

  <span class="sbx-button-container">
    <sbx-button-group>
      <ng-container *ngFor="let button of item.buttons">
        <sbx-button
          [title]="button.title"
          [icon]="button.icon"
          (click)="emitButtonClick(button.id)"
        >
        </sbx-button>
      </ng-container>
    </sbx-button-group>
  </span>
</div>
