/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFpvConfirm
 * @restrict A
 *
 * @description
 * This validator makes sure a field is identical value a list of other
 * field names in the same form. List is considered one time bound
 *
 * @element ANY
 */
export const sbFpvConfirm = [
  '$parse',
  function ($parse) {
    return {
      require: ['ngModel', '^?form'],
      restrict: 'A',
      link: function (scope, element, attrs, ctrls) {
        const [modelCtrl, formCtrl] = ctrls;
        const valueOf = (name) => formCtrl[name] && formCtrl[name].$modelValue;
        const otherFields = $parse(attrs.sbFpvConfirm)(scope) || [];

        if (!formCtrl) {
          return;
        }

        modelCtrl.$validators.confirm = (val) =>
          otherFields.reduce((ac, name) => ac && valueOf(name) === val, true);
        scope.$watchGroup(
          otherFields.map((name) => () => valueOf(name)),
          () => {
            modelCtrl.$validate();
          },
        );
      },
    };
  },
]; // end sbFpvConfirm

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFpvEmptyEmail
 * @restrict A
 *
 * @description
 * This code nerfs the built in email validator from Angular.
 *
 * @element ANY
 */
export function sbFpvEmptyEmail() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.email = () => true;
    },
  };
} // end sbFpvEmptyEmail

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFpvEmail
 * @restrict A
 *
 * @description
 * This adds a (better) $validator to a `ngModelController` for models of that
 * must be an email.
 *
 * @element ANY
 */
export function sbFpvEmail() {
  // Regex taken from http://stackoverflow.com/questions/23671733/angularjs-v1-3-x-email-validation-issue
  const SB_EMAIL_REGEX =
    /^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.email = function (value) {
        return ngModelCtrl.$isEmpty(value) || SB_EMAIL_REGEX.test(value);
      };
    },
  };
} // end sbFpvEmail
