<div class="document-card standalone-document-card">
  <div class="document">
    <ng-container *ngIf="document.documentCard.canView; else undisclosedDocument">
      <div class="row">
        <div class="col-lg-12">
          <sbx-document-card
            [documentCard]="document.documentCard"
            format="full"
          ></sbx-document-card>
        </div>
      </div>

      <sbx-button-group>
        <sbx-button
          *ngIf="document.canDownload"
          [href]="downloadHref"
          title="Download"
          icon="download"
        ></sbx-button>
        <sbx-button
          *ngIf="document.canManage"
          title="Remove Reference"
          icon="linkSlash"
          class="sbx-view-document-button sbx-remove-user-reference"
          (click)="this.removeReference.emit()"
        ></sbx-button>
      </sbx-button-group>
      <sbx-upload-missing-document
        *ngIf="document.canUpload"
        [apiResource]="uploadMissingDocumentApiResource"
        [saveUrl]="uploadMissingDocumentSaveUrl"
        urlVersion="1"
        id="upload"
        class="upload-doc"
      ></sbx-upload-missing-document>
    </ng-container>
  </div>
</div>

<ng-template #undisclosedDocument>
  <div class="sbx-undisclosed-document">
    <h4 class="not-allowed">Undisclosed document</h4>
    <div class="basic-info">You don't have permission to view this document.</div>
  </div>
</ng-template>
