import { Component, Input, Inject, OnInit, DoCheck, forwardRef } from '@angular/core';
import { Downgrade } from '../downgrade';
import { IDraggableListItem } from '../sbx-draggable-list/sbx-draggable-list.component';
import { of } from 'rxjs';
import { SbxFormModalService } from '../sbx-form-modal/sbx-form-modal.service';
import { FormGroup } from '@angular/forms';
import { SbxDocumentUploadFile } from '../sbx-document-reference/sbx-document-upload.component';
import { BackendLocation } from '@/core/http';

export class ExhibitListItem implements IDraggableListItem {
  referenceName: string;
  referenceTitle: string;
  referenceId: string;
  referenceDoc: any;

  itemTitle?: string;
  constructor(ref: string, doc: any, name: string, title: string) {
    this.referenceId = ref;
    this.referenceDoc = doc;
    this.referenceName = name;
    this.referenceTitle = title;
  }
}

/**
 *  Shoobx Exhibit List
 *
 *  Draggable, Editable
 *
 *  @ngdoc component
 *  @name sbx-exhibit-list/component:SbxExhibitListComponent
 *  @description
 *  component for managing explicit exhibits
 *
 *  @param exhibits - list of exhibits, example item
 *   {
 *   'referenceName': exh.name,
 *   'referenceTitle': exh.title,
 *     'referenceId': exh.ref,
 *     'referenceDoc': {
 *       'type': 'pickFrom',
 *       'documentId': 'id'}
 *     }
 *  @param uploadFolder - folder id for uploads
 *  @param sbmodel - model from wi, the out put is mirrored to wi-form
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-exhibit-list
 *          [exhibits] exhibits
 *          [uploadFolder]="upload_folder_id"
 *          [sbmodel]="model">
 *        </sbx-exhibit-list>
 *      `,
 *    })
 */
const SELECTOR = 'sbx-exhibit-list';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-exhibit-list.component.html',
  styleUrls: ['./sbx-exhibit-list.component.scss'],
})
export class SbxExhibitListComponent implements OnInit, DoCheck {
  // Exhibit list from the document
  @Input() exhibits: ExhibitListItem[];
  @Input() uploadFolder: any;
  @Input() sbmodel: any;

  // Form to pass on the data
  formName = 'exhibit_management';
  formField = 'exhibits';

  constructor(
    @Inject(SbxFormModalService)
    private sbxExhibitFormModalService: SbxFormModalService,
    @Inject(forwardRef(() => BackendLocation)) private backendLocation: BackendLocation,
  ) {}

  // update the list as user changes
  ngOnInit(): void {
    this.mapToForm();
  }
  ngDoCheck() {
    this.mapToForm();
  }
  mapToForm() {
    // modified list will go to wi with the form
    this.sbmodel.$formData[this.formName][this.formField] = this.exhibits;
  }

  populateForm(item: ExhibitListItem = null) {
    // included docs should be ISbxDocumentReferenceModel-like
    // doc reference model
    const exhibitModel: ExhibitListItem = {
      referenceId: '',
      referenceTitle: '',
      referenceName: '',
      referenceDoc: {
        type: '',
        uploadId: '',
        documentId: '',
        documentTitle: '',
        pdfURL: '',
        processId: '',
        file: new SbxDocumentUploadFile(),
      },
    };

    if (item) {
      exhibitModel.referenceId = item.referenceId;
      exhibitModel.referenceTitle = item.referenceTitle;
      exhibitModel.referenceName = item.referenceName;
      // TODO: multi-document support in an exhibit
      exhibitModel.referenceDoc = {
        documentTitle: item.referenceTitle,
        documentId: item.referenceDoc.documentId,
        file: { id: item.referenceDoc.documentId },
        type: item.referenceDoc.type,
        pdfURL: `
          ${this.backendLocation.entity('1')}documents/${
            item.referenceDoc.documentId
          }/preview_content`,
      };
    }

    // Add/Edit form
    const form = new FormGroup({});
    this.sbxExhibitFormModalService
      .open({
        data: {
          title: item ? 'Edit Exhibit' : 'Add Exhibit',
          formFields: this.getFormFields(),
          model: exhibitModel,
          form: form,
        },
      })
      .result.then(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          this.modifyExhibitList(exhibitModel, item);
        },
      )
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  modifyExhibitList(res, oldItem): any {
    function _setDownloadLink(refDoc) {
      if (refDoc.type === 'pickFrom') {
        refDoc.downloadURL = res.referenceDoc.pdfURL.replace('/preview_', '/');
      } else {
        refDoc.downloadURL = res.referenceDoc.pdfURL.replace('/preview?', '/pdf?');
      }
    }
    // eslint-disable-next-line no-negated-condition
    if (!oldItem) {
      _setDownloadLink(res.referenceDoc);
      this.exhibits.push(
        new ExhibitListItem(
          res.referenceId,
          res.referenceDoc,
          res.referenceDoc.documentId || res.referenceDoc.uploadId,
          res.referenceTitle,
        ),
      );
    } else {
      oldItem.referenceTitle = res.referenceTitle;
      oldItem.referenceId = res.referenceId;
      //  if the document changed
      if (res.referenceDoc.type) {
        oldItem.referenceDoc = res.referenceDoc;
        _setDownloadLink(oldItem.referenceDoc);
      }
    }
  }

  addExhibit() {
    this.populateForm();
  }

  editExhibit(item: ExhibitListItem) {
    this.populateForm(item);
  }

  deleteExhibit(item: ExhibitListItem) {
    const index = this.exhibits.indexOf(item, 0);
    if (index > -1) {
      this.exhibits.splice(index, 1);
    }
  }

  navigateToUrl(url) {
    window.location.href = url;
  }

  // edit and add form fields
  getFormFields() {
    return of([
      {
        key: 'referenceId',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'Exhibit ID',
          help: 'e.g. “C,” “3,” “2.1, ”A2,” etc.',
        },
      },
      {
        key: 'referenceTitle',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'Exhibit Title',
        },
      },
      {
        key: 'referenceDoc',
        type: 'document-reference',
        templateOptions: {
          required: true,
          label: 'Document',
          pickFromModalTitle: 'Select Document from Data Room',
          apiResource:
            'select_and_edit_resolutions/resolutionWorkitemExhibitDocEditResolution',
          documentOptions: {
            title: 'Exhibit',
            allowUpload: true,
            allowPickFrom: true,
            allowAllFromProc: true,
            rootFolderId: this.uploadFolder,
            collectTitleInUpload: true,
          },
        },
      },
    ]);
  }
}
