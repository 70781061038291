import angular from 'angular';
/**
 * @ngdoc filter
 * @name sb.workitem.setOwnershipAndControl.filter:investmentPercentage
 * @kind function
 *
 * @description
 * Given investment and total shares, yield a formatted percentage with one
 * decimal precison.
 *
 * @param {number} investment Individual investment amount.
 * @param {number} total Total investment amount.
 *
 * @returns {string} Formatted percentage. Will return 'N/A' for invalid
 *    params.
 */
export const investmentPercentage = [
  '$filter',
  'EquityManager',
  function ($filter, EquityManager) {
    return function (investment, total) {
      for (let i = 0; i < arguments.length; i++) {
        if (!angular.isNumber(arguments[i]) || arguments[i] <= 0) {
          return 'N/A';
        }
      }
      const per = EquityManager.investmentPercentage(investment, total);
      return $filter('number')(per, 1) + '%';
    };
  },
]; // end investmentPercentage

/**
 * @ngdoc filter
 * @name sb.workitem.setOwnershipAndControl.filter:ownershipPercentage
 * @kind function
 *
 * @description
 * Given investment, total shares, and valuation yield a formatted percentage
 * with one decimal precison.
 *
 * @param {number} investment Individual investment amount.
 * @param {number} total Total investment amount.
 * @param {number} valuation The valuation of the investment.
 *
 * @returns {string} Formatted percentage. Will return 'N/A' for invalid
 *    params.
 */
export const ownershipPercentage = [
  '$filter',
  'EquityManager',
  function ($filter, EquityManager) {
    return function (investment, total, valuation) {
      for (let i = 0; i < arguments.length; i++) {
        if (!angular.isNumber(arguments[i]) || arguments[i] <= 0) {
          return 'N/A';
        }
      }
      const per = EquityManager.ownershipPercentage(investment, total, valuation);
      return $filter('number')(per, 1) + '%';
    };
  },
]; // end ownershipPercentage

/**
 * @ngdoc filter
 * @name sb.workitem.setOwnershipAndControl.filter:ownershipPercentageFromShares
 * @kind function
 *
 * @description
 * Given shares and total shares yield a formatted percentage with one decimal
 * precison. Similar to `ownershipPercentage`, but is done with shares instead
 * of money.
 *
 * @param {number} investment Shares of investment.
 * @param {number} total Total number of shares.
 *
 * @returns {string} Formatted percentage. Will return 'N/A' for invalid
 *    params.
 */
export const ownershipPercentageFromShares = [
  '$filter',
  'EquityManager',
  function ($filter, EquityManager) {
    return function (shares, total) {
      for (let i = 0; i < arguments.length; i++) {
        if (!angular.isNumber(arguments[i]) || arguments[i] <= 0) {
          return 'N/A';
        }
      }
      const per = EquityManager.ownershipPercentageFromShares(shares, total);
      return $filter('number')(per, 1) + '%';
    };
  },
]; // end ownershipPercentageFromShares
