import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxExhibitListComponent } from './sbx-exhibit-list.component';
import { CommonModule } from '@angular/common';
import { UpgradedModule } from '../upgraded/upgraded.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SbxDraggableListModule } from '../sbx-draggable-list/sbx-draggable-list.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxFormModule } from '../sbx-form/sbx-form.module';

@NgModule({
  imports: [
    CommonModule,
    UpgradedModule,
    SbxIconModule,
    DragDropModule,
    SbxDraggableListModule,
    SbxButtonModule,
    SbxModalModule,
    SbxFormModule,
  ],
  declarations: [SbxExhibitListComponent],
  exports: [SbxExhibitListComponent],
})
export class SbxExhibitListModule {
  static entryComponents = [SbxExhibitListComponent];

  static forRoot(): ModuleWithProviders<SbxExhibitListModule> {
    return {
      ngModule: SbxExhibitListModule,
    };
  }
}
