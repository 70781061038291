/**
 * @ngdoc component
 * @name sb.payroll.sbPaylocityStageEmployees
 *
 * @description
 * This element will display the stage new employees table for
 * Paylocity payroll integration.
 *
 */

export const sbPaylocityConflicts = {
  controllerAs: 'vm',
  template: require('./templates/paylocity-conflicts.html'),
  controller: [
    'BackendLocation',
    'SimpleHTTPWrapper',
    'EmployeeChanges',
    class {
      constructor(BackendLocation, SimpleHTTPWrapper, EmployeeChanges) {
        this.BackendLocation = BackendLocation;
        this.SimpleHTTPWrapper = SimpleHTTPWrapper;
        this.EmployeeChanges = EmployeeChanges;

        this.apiUrl = BackendLocation.entity('1') + 'paylocity';
        this.stakeholdersWithConflicts = [];
        this.sboobxTerminations = [];
        this.sboobxRehires = [];
        this.paylocityTerminations = [];
        this.paylocityRehires = [];

        this.status = null;

        this.hasConflicts = this.hasConflicts.bind(this);
        this.canResolveConflicts = this.canResolveConflicts.bind(this);
        this.resolveConflicts = this.resolveConflicts.bind(this);
        this.showStatusMessage = this.showStatusMessage.bind(this);
        this.refresh = this.refresh.bind(this);

        EmployeeChanges.subscribe(() => {
          this.refresh();
        });
      }

      $postLink() {
        this.refresh();
      }

      refresh() {
        this.SimpleHTTPWrapper({
          method: 'GET',
          url: `${this.apiUrl}/terminations`,
        })
          .then((response) => {
            this.sboobxTerminations = response.shoobxTerminations;
            this.sboobxRehires = response.shoobxRehires;
            this.paylocityTerminations = response.paylocityTerminations;
            this.paylocityRehires = response.paylocityRehires;
          })
          .catch((error) => {
            this.showStatusMessage(error || 'There was a response error.', 'danger');
          });

        this.SimpleHTTPWrapper({
          method: 'GET',
          url: `${this.apiUrl}/conflicts`,
        }).then((stakeholdersWithConflicts) => {
          this.stakeholdersWithConflicts = stakeholdersWithConflicts.map((sh) => {
            sh.isLoading = false;

            return sh;
          });
        });
      }

      hasConflicts() {
        return (
          this.stakeholdersWithConflicts.filter(
            (stakeholder) => stakeholder.conflicts.length > 0,
          ).length === 0
        );
      }

      hasConflictDetails(conflict) {
        return (
          conflict.shoobx_origin_process_title ||
          conflict.shoobx_origin_stakeholder_title
        );
      }

      formatConflictDetails(conflict) {
        const details = ['Changed'];
        if (conflict.shoobx_origin_stakeholder_title) {
          details.push(`by ${conflict.shoobx_origin_stakeholder_title}`);
        }
        if (conflict.shoobx_origin_process_title) {
          details.push(`during "${conflict.shoobx_origin_process_title}"`);
        }
        if (conflict.shoobx_origin_document_title) {
          details.push(`according to "${conflict.shoobx_origin_document_title}"`);
        }

        return details.join(' ');
      }

      canResolveConflicts(stakeholder) {
        const hasConf =
          stakeholder.conflicts.filter(
            (conflict) => conflict.selectedValue !== undefined,
          ).length === 0;
        const isLoading = stakeholder.isLoading;

        return hasConf || isLoading;
      }

      resolveConflicts(stakeholder) {
        const resolutions = stakeholder.conflicts
          .map((conflict) => [conflict.id, conflict.selectedValue])
          .filter((conflict) => conflict[1] !== undefined);

        if (resolutions.length === 0) {
          return;
        }

        const data = {
          stakeholderId: stakeholder.sh.id,
          resolutions,
        };

        this.stakeholdersWithConflicts = this.stakeholdersWithConflicts.map((sh) => {
          if (sh.sh.id === stakeholder.sh.id) {
            sh.isLoading = true;
          }

          return sh;
        });

        this.SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${this.apiUrl}/resolve`,
            data: data,
          },
          `Error occured while updating ${stakeholder.sh.fullName}.`,
        )
          .then(() => {
            const updatedIds = resolutions.map((resolution) => resolution[0]);

            this.stakeholdersWithConflicts = this.stakeholdersWithConflicts.map(
              (sh) => {
                if (sh.sh.id === stakeholder.sh.id) {
                  sh.isLoading = false;
                  sh.conflicts = sh.conflicts.filter(
                    (conflict) => !updatedIds.includes(conflict.id),
                  );
                }

                return sh;
              },
            );

            this.EmployeeChanges.emit(stakeholder.sh.id);

            this.showStatusMessage(
              `${stakeholder.sh.fullName} was successfully updated.`,
              'success',
            );
          })
          .catch((error) => {
            this.stakeholdersWithConflicts = this.stakeholdersWithConflicts.map(
              (sh) => {
                if (sh.sh.id === stakeholder.sh.id) {
                  sh.isLoading = false;
                }

                return sh;
              },
            );

            this.showStatusMessage(error, 'danger');
          });
      }

      showStatusMessage(msg, type) {
        if (!msg) {
          this.status = null;
        }

        this.status = {
          type,
          message: msg,
        };
      }
    },
  ],
};
