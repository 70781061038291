/* eslint-disable dot-notation */
import { Component, Input } from '@angular/core';
import { SbxModalService } from '../../sbx-modal/sbx-modal.service';

const CREATE_NEW_KEY = '_CREATE_';
const DONT_USE_AFFILIATE_KEY = '_NO_AFFILIATE_';

interface IEntity {
  id?: string;
  shId?: string;
  title?: string;
}

@Component({
  selector: 'sbx-similar-entities-modal',
  templateUrl: './sbx-similar-entities-modal.component.html',
  styleUrls: ['./sbx-similar-entities-modal.component.scss'],
})
export class SbxSimilarEntitiesModalComponent {
  @Input() similarEntities;
  @Input() entityTitle;
  @Input() affiliateTitle;
  @Input() handleAffiliates;

  selectedEntity: IEntity = {};
  selectedEntityKey = null;
  selectedAffiliate = {};
  selectedAffiliateKey = null;
  similarAffiliates = [];
  CREATE_NEW_KEY = CREATE_NEW_KEY;
  DONT_USE_AFFILIATE_KEY = DONT_USE_AFFILIATE_KEY;

  constructor(public sbxModalService: SbxModalService) {}

  makeKey(entry) {
    if (entry) {
      // If the entry is a local team, it will have no entity .id.
      // If the entry is a similarily named foreign entity,
      // it may have no stakeholder (.shId) in the current entity.
      // Or it could be a team stakeholder with both .shId and foreign entity .id.
      if (entry.shId) {
        return `sh-${entry.shId}`;
      }
      if (entry.id) {
        return `ent-${entry.id}`;
      }
    }
    return CREATE_NEW_KEY;
  }

  setSelectedEntity(entity) {
    this.selectedEntity = entity || { id: null, shId: null, title: this.entityTitle };
    this.selectedEntityKey = this.makeKey(this.selectedEntity);
    this.similarAffiliates = entity ? entity.similarAffiliates : [];
    // unselect affiliate option
    this.selectedAffiliate = {};
    this.selectedAffiliateKey = null;
  }

  setSelectedAffiliate(affiliate) {
    this.selectedAffiliate = affiliate || {
      id: null,
      shId: null,
      title: this.affiliateTitle,
    };
    this.selectedAffiliateKey = this.makeKey(this.selectedAffiliate);
  }

  setDontUseAffiliate() {
    this.selectedAffiliate = {};
    this.selectedAffiliateKey = DONT_USE_AFFILIATE_KEY;
  }

  save() {
    const similarityWhitelist = [];
    // whitelist similar entities
    similarityWhitelist.push(
      ...this.similarEntities
        .map((entity) => entity.id)
        .filter((entityId) => entityId && entityId !== this.selectedEntity['id']),
    );

    if (this.hasAffiliateOption()) {
      // whitelist similar affiliate entities
      similarityWhitelist.push(
        ...this.similarAffiliates
          .map((affiliate) => affiliate.id)
          .filter(
            (entityId) =>
              entityId &&
              (!this.selectedAffiliateKey || entityId !== this.selectedAffiliate['id']),
          ),
      );
    }
    let selectedEntity = null;
    let selectedAffiliate = null;
    if (this.selectedEntityKey === CREATE_NEW_KEY) {
      selectedEntity = { id: null, shId: null, title: this.entityTitle };
      if (this.handleAffiliates) {
        selectedAffiliate = { id: null, shId: null, title: this.affiliateTitle };
      }
    } else {
      selectedEntity = this.selectedEntity;

      if (this.hasAffiliateOption()) {
        if (this.selectedAffiliateKey === DONT_USE_AFFILIATE_KEY) {
          selectedAffiliate = null;
        } else if (this.selectedAffiliateKey === CREATE_NEW_KEY) {
          selectedAffiliate = { id: null, shId: null, title: this.affiliateTitle };
        } else {
          selectedAffiliate = this.selectedAffiliate;
        }
      } else if (this.handleAffiliates) {
        // There are no similar affiliates for selected entity and option to
        // select similar affiliates was not displayed.
        // Since user had requested an affiliate, create a new one.
        selectedAffiliate = { id: null, shId: null, title: this.affiliateTitle };
      }
    }
    this.sbxModalService.close({
      similarityWhitelist,
      selectedEntity: selectedEntity,
      selectedAffiliate: selectedAffiliate,
    });
  }

  hasAffiliateOption() {
    return (
      this.handleAffiliates && this.similarAffiliates && this.similarAffiliates.length
    );
  }

  hasErrors() {
    return (
      !this.selectedEntity || (this.hasAffiliateOption() && !this.selectedAffiliateKey)
    );
  }
}
