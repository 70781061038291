import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SelectionState } from '@/shared/sbx-table/sbx-bulk-description/selection-state.enum';

@Component({
  selector: 'sbx-bulk-description',
  templateUrl: './sbx-bulk-description.component.html',
  styleUrls: ['./sbx-bulk-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxBulkDescriptionComponent implements OnChanges {
  @Input() public selected!: number;
  @Input() public total!: number;
  @Input() public selectedOnPage!: number;
  @Input() public pageSize!: number;

  @Output() public readonly selectAll = new EventEmitter<void>();
  @Output() public readonly clearAll = new EventEmitter<void>();

  public readonly SELECTION_STATE = SelectionState;

  public selectionState!: SelectionState;

  public ngOnChanges(): void {
    this.updateState();
  }

  private updateState(): void {
    if (this.isNotPaginated()) {
      this.selectionState = SelectionState.NOT_PAGINATED;
      return;
    }

    if (this.isSomeOnPageSelected()) {
      this.selectionState = SelectionState.SOME_ON_PAGE_WHEN_MULTIPLE_PAGES;
      return;
    }

    if (this.isAllOnPageSelected()) {
      this.selectionState = SelectionState.ALL_ON_PAGE_WHEN_MULTIPLE_PAGES;
      return;
    }

    if (this.isAllSelected()) {
      this.selectionState = SelectionState.ALL_WHEN_MULTIPLE_PAGES;
      return;
    }

    throw new Error('state not found');
  }

  private isNotPaginated(): boolean {
    return this.total <= this.pageSize;
  }

  private isSomeOnPageSelected(): boolean {
    return this.selectedOnPage < this.pageSize;
  }

  private isAllOnPageSelected(): boolean {
    return this.selectedOnPage === this.pageSize && !this.isAllSelected();
  }

  private isAllSelected(): boolean {
    return this.selected === this.total;
  }
}
