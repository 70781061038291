<ng-container [ngSwitch]="selectionState">
  <ng-container *ngSwitchCase="SELECTION_STATE.NOT_PAGINATED">
    <span>
      <strong>{{ selected }}</strong> of {{ total }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SELECTION_STATE.SOME_ON_PAGE_WHEN_MULTIPLE_PAGES">
    <span>
      <strong>{{ selected }}</strong> of {{ total }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SELECTION_STATE.ALL_ON_PAGE_WHEN_MULTIPLE_PAGES">
    <span>
      <strong>{{ selected }}</strong> of {{ total }}. All options on this page are
      selected.
      <a role="button" class="sbx-action-button" (click)="selectAll.emit()">
        Select all {{ total }}
      </a>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SELECTION_STATE.ALL_WHEN_MULTIPLE_PAGES">
    <span>
      <strong>{{ selected }}</strong> of {{ total }}. All options selected.
      <a role="button" class="sbx-action-button" (click)="clearAll.emit()">
        Clear Selection
      </a>
    </span>
  </ng-container>
</ng-container>
