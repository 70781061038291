<div #alertBanner class="sbx-alert-banner" [ngClass]="{ 'sbx-hidden': hidden }">
  <span
    class="sbx-icon-container"
    [ngClass]="{ 'sbx-error': error, 'sbx-warning': warning }"
  >
    <sbx-icon [type]="error || warning ? 'error' : 'check'"></sbx-icon>
  </span>
  <span
    class="sbx-text"
    [innerHtml]="
      customText
        ? customText
        : error
        ? 'There was an issue saving your changes.'
        : 'Changes saved.'
    "
  >
  </span>
  <sbx-popover *ngIf="details" [sbxPopoverContent]="details">
    <sbx-icon class="sbx-tooltip-icon" type="questionCircle"></sbx-icon>
  </sbx-popover>
  <sbx-icon
    *ngIf="!disableClose"
    class="sbx-close"
    type="delete"
    (click)="hide()"
  ></sbx-icon>
</div>
