<ng-container *ngIf="messages$ | async as messages">
  <ng-container *ngFor="let message of messages; index as index">
    <div [ngSwitch]="message.severity" class="sbx-message-panel">
      <sbx-alert-banner
        *ngSwitchCase="MESSAGE_SEVERITY.ERROR"
        [error]="true"
        [customText]="message.text"
        (close)="handleRemove(message)"
      ></sbx-alert-banner>
      <sbx-alert-banner
        *ngSwitchCase="MESSAGE_SEVERITY.SUCCESS"
        [error]="false"
        [fadeTime]="undefined"
        [customText]="message.text"
        (close)="handleRemove(message)"
      ></sbx-alert-banner>
    </div>
  </ng-container>
</ng-container>
