import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Enum with possible navigation scenarios
 */
export enum NavigationState {
  FROM_SPA_TO_SPA,
  FROM_SPA_TO_NON_SPA,
  FROM_NON_SPA_TO_SPA,
  FROM_NON_SPA_TO_NON_SPA,
  FROM_SHOOBX_TO_EXTERNAL,
}

/**
 * This service selects a "NavigationState" by target and current URL
 */
@Injectable({ providedIn: 'root' })
export class SbxNavigationStateService {
  public constructor(
    @Inject(APP_BASE_HREF) private readonly baseHref: string,
    @Inject('Window') private readonly window: Window,
  ) {}

  /**
   * Get navigation state by passed URL
   */
  public getState(url: URL): NavigationState {
    const baseHref = this.baseHref;
    const location = this.window.location;
    const isTargetPageExternal = this.isTargetPageExternal(url.origin, location.origin);

    if (isTargetPageExternal) {
      return NavigationState.FROM_SHOOBX_TO_EXTERNAL;
    }

    const isCurrentPageSpa = this.isCurrentPageSpa(baseHref, location.pathname);
    const isTargetPageSpa = this.isTargetPageSpa(baseHref, url.pathname);

    if (isCurrentPageSpa && isTargetPageSpa) {
      return NavigationState.FROM_SPA_TO_SPA;
    } else if (isCurrentPageSpa && !isTargetPageSpa) {
      return NavigationState.FROM_SPA_TO_NON_SPA;
    } else if (!isCurrentPageSpa && isTargetPageSpa) {
      return NavigationState.FROM_NON_SPA_TO_SPA;
    } else if (!isCurrentPageSpa && !isTargetPageSpa) {
      return NavigationState.FROM_NON_SPA_TO_NON_SPA;
    }
  }

  private isTargetPageExternal(currentOrigin: string, targetOrigin: string): boolean {
    return currentOrigin !== targetOrigin;
  }

  private isCurrentPageSpa(baseHref: string, currentPathName: string): boolean {
    return currentPathName.startsWith(baseHref);
  }

  private isTargetPageSpa(baseHref: string, targetPathName: string): boolean {
    return targetPathName.startsWith(baseHref);
  }
}
