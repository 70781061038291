import angular from 'angular';
import { List, Map } from 'immutable';
import { combineLatest, zip } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

/**
 * @ngdoc component
 * @name sb.workitem.grantConstraints.component:sbConstraintToken
 *
 * @description
 * A simple component to display a constraint expression
 *
 * @param {object} model with the shape of a Token record
 *  @property {string} title of the token value
 *  @property {any} value of the token
 *  @property {string} op if defined, token represents an operator
 *  @property {string} info with details describing the token
 *  @property {string} format string describing formatting type
 * @param {boolean} [showHelp=true] displays help text if true
 *
 */
export const sbConstraintToken = {
  controllerAs: 'vm',
  template: require('./templates/token.html'),
  bindings: {
    model: '<',
    showHelp: '<?',
  },
  controller: [
    function () {
      function $onInit() {
        this.showHelp = angular.isDefined(this.showHelp) ? this.showHelp : true;
      }

      function roundCent(val) {
        return val < 0.01 && val > 0 ? 0.01 : val;
      }

      this.isNumber = angular.isNumber;
      this.$onInit = $onInit.bind(this);
      this.roundCent = roundCent.bind(this);
    },
  ],
}; // end sbConstraintToken

/**
 * @ngdoc directive
 * @name sb.workitem.grantConstraints.component:sbCombinedRule701Constraint
 * @description A wrapper component providing a grouped view on Rule701 constraints
 *
 * @param {array<object>} constraints a list of GrantISOVestLimit constraints:
 *  @property {string} description of the constraint
 *  @property {boolean} satisfies is true if the contraint is satisfied
 *  @property {object} messages for different constraint-satisfying cases
 *    @property {string} [error=undefined] for error messaging
 *  @property {object} variables a map of each variable name and value
 *  @property {array<object>} formula represented by an array of Token-shaped objects
 *  @property {string} type id for the type of constraint
 *
 */
export const sbCombinedRule701Constraint = {
  controllerAs: 'vm',
  template: require('./templates/combined-rule701.html'),
  bindings: {
    constraints: '<',
  },
  controller: [
    function () {
      function $onInit() {
        this.model = { type: 'CombinedRule701Constraint' };
        const { Rule701SecurityLimit, Rule701PercentOutstanding } = Map(
          this.constraints.map((c) => [c.type, c]),
        ).toJS();

        // Determine which constraint is relevant
        const limit = Rule701SecurityLimit.variables.limit;
        const outstanding = Rule701PercentOutstanding.variables.pct_of_outstanding;
        const measure = Math.max(limit, outstanding);
        Rule701SecurityLimit.disable = limit < measure;
        Rule701PercentOutstanding.disable = outstanding < measure;

        this.constraints = [Rule701SecurityLimit, Rule701PercentOutstanding];
        this.model.messages = Rule701SecurityLimit.messages;
        this.model.satisfies = this.constraints.every((c) => c.satisfies);
        this.model.notifies =
          this.model.satisfies &&
          this.constraints.map((c) => c.notifies).includes(true);
      }

      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbCombinedIsoVestConstraint

/**
 * @ngdoc directive
 * @name sb.workitem.grantConstraints.component:sbCombinedIsoVestConstraint
 * @description A wrapper component providing a grouped view on GrantISOVestLimit constraints
 *
 * @param {array<object>} constraints a list of GrantISOVestLimit constraints:
 *  @property {string} description of the constraint
 *  @property {boolean} satisfies is true if the contraint is satisfied
 *  @property {object} messages for different constraint-satisfying cases
 *    @property {string} [error=undefined] for error messaging
 *  @property {object} variables a map of each variable name and value
 *  @property {array<object>} formula represented by an array of Token-shaped objects
 *  @property {string} type id for the type of constraint
 *
 */
export const sbCombinedIsoVestConstraint = {
  controllerAs: 'vm',
  template: require('./templates/combined-iso-vests.html'),
  bindings: {
    constraints: '<',
  },
  controller: [
    function () {
      function $onInit() {
        this.model = { type: 'CombinedIsoVestConstraint' };
        const { description, messages } = this.constraints[0];
        this.model.description = description;
        this.model.messages = messages;
        this.model.satisfies = this.constraints.every((c) => c.satisfies);
        this.model.notifies =
          this.model.satisfies &&
          this.constraints.map((c) => c.notifies).includes(true);
      }

      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbCombinedIsoVestConstraint

/**
 * @ngdoc directive
 * @name sb.workitem.grantConstraints.component:sbConstraint
 * @description A simple component to display a constraint expression
 *
 * @param {object} model with the shape of a Constraint record
 *  @property {string} description of the constraint
 *  @property {boolean} satisfies is true if the contraint is satisfied
 *  @property {object} messages for different constraint-satisfying cases
 *    @property {string} [error=undefined] for error messaging
 *  @property {object} variables a map of each variable name and value
 *  @property {array<object>} formula represented by an array of Token-shaped objects
 *  @property {string} type id for the type of constraint
 *
 *
 */
export const sbConstraint = {
  controllerAs: 'vm',
  template: require('./templates/constraint.html'),
  bindings: {
    model: '<',
  },
  controller: [
    class {
      constructor() {}
    },
  ],
}; // end sbConstraint

/**
 * @ngdoc component
 * @name sb.workitem.grantConstraints.component:sbConstraintSet
 *
 * @description
 * This component displays legal constraint information of a grant
 *
 * @param {object|Map} info the constraints info object
 *  @property {string} title of this constraint set
 *  @property {boolean} satisfies is true all constraints are satisfied
 *  @property {array<object>} constraints
 *
 */
export const sbConstraintSet = {
  controllerAs: 'vm',
  template: require('./templates/constraint-set.html'),
  bindings: {
    info: '<',
  },
  controller: [
    function () {
      const type701 = /Rule701/;
      const customType = (constraint) => {
        const { type } = constraint;
        if (type === 'GrantISOYearlyVestLimit') {
          return 'vest';
        } else if (type701.test(type)) {
          return 'rule701';
        }
        return 'rest';
      };
      // Group ISO Vesting constraints into a different view
      function $onInit() {
        if (this.info.toJS) {
          this.info = this.info.toJS();
        }
        const { rest, vest, rule701 } = new List(this.info.constraints)
          .groupBy(customType)
          .toJS();
        this.info.constraints = rest;
        this.custom = { vest, rule701 };
      }

      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbConstraintSet

/**
 * @ngdoc component
 * @name sb.workitem.grantConstraints.component:sbConstraintsOverview
 *
 * @description
 * This component is the body of the grant constraints workitem page
 *
 * TODO: decouple process integration from overview component
 *
 * @param {array<string>} grantIds List of grant document ids.
 * @param {template} [processId=undefined] with grant workflow data.
 *
 */
export const sbConstraintsOverview = {
  controllerAs: 'vm',
  template: require('./templates/overview.html'),
  bindings: {
    grantIds: '<',
    processId: '@?',
    grantsByGrantee: '<?',
  },
  controller: [
    'ConstraintService',
    'ProcessButtonModel',
    '$confirm',
    '$observable',
    '$scope',
    function (ConstraintService, ProcessButtonModel, $confirm, $observable, $scope) {
      const UNFINISHED_CONSTRAINTS_WARNING = `
    Shoobx checks for possible conflicts regarding available shares, \
    IRS limits, and 701 compliance. We are still checking constraints \
    for this set of grants. Do you want to wait for the constraints check \
    to finish?
    `;
      const UNSATISFIED_CONSTRAINTS_WARNING = `
    We highly recommend seeking legal counsel when leaving \
    constraints unsatisfied to avoid unintended legal ramifications.
    `;

      function $onInit() {
        const { processId } = this;
        this.info = null;
        this.error = null;
        this.unfinishedCondId = null;
        this.unsatisfiedCondId = null;
        this.grantsByGrantee = this.grantsByGrantee || {};

        // Initialize component as loading
        this.loading = true;
        this.setUnfinishedModal();

        const onError = () => {
          this.error = 'There was an error loading data...';
        };
        // XXX only handles loading -> !loading state transitions
        // assumes component never goes back to loading.
        const onNext = ({ entity, grants }) => {
          const loading = entity.loading || grants.some((g) => g.toJS().loading);
          this.info = { entity, grants };
          if (!loading) {
            ProcessButtonModel.$removeSubmitCondition(this.unfinishedCondId);
            this.satisfies =
              entity.satisfies && !grants.some((g) => !g.toJS().satisfies);
            if (!this.satisfies) {
              this.setUnsatisfiedModal();
            }
          }

          this.loading = loading;
        };

        const processId$ = $observable.fromWatcher($scope, 'vm.processId').pipe(
          map((id) => id?.newValue),
          filter(Boolean),
        );

        const grantIds$ = $observable.fromWatcher($scope, 'vm.grantIds').pipe(
          map((id) => id?.newValue),
          filter(Boolean),
        );

        const grantsByGrantee$ = $observable
          .fromWatcher($scope, 'vm.grantsByGrantee')
          .pipe(
            map((id) => id?.newValue),
            filter(Boolean),
          );

        if (processId) {
          zip(
            processId$,
            grantIds$,
            grantsByGrantee$,
            (processId, grantIds, grantsByGrantee) => ({
              processId,
              grantIds,
              grantsByGrantee,
            }),
          )
            .pipe(switchMap(_fetchInfo))
            .$applySubscribe($scope, onNext, onError);
        }
      }

      function _fetchInfo({ processId, grantIds, grantsByGrantee }) {
        const entity$ = ConstraintService.$getEntityReport({ processId }).pipe(
          startWith({
            loading: true,
            title: 'Company Capitalization Constraint',
          }),
        );
        let grants$;
        if (grantsByGrantee.size > 0) {
          grants$ = ConstraintService.$getGrantByGranteeReport(grantsByGrantee);
        } else {
          grants$ = ConstraintService.$getGrantReport(grantIds);
        }
        return combineLatest(entity$, grants$, (entity, grants) => ({
          entity,
          grants,
        }));
      }

      function setUnfinishedModal() {
        this.unfinishedCondId = ProcessButtonModel.$addSubmitCondition(
          'continue',
          () =>
            $confirm({
              body: UNFINISHED_CONSTRAINTS_WARNING,
              alertType: 'danger',
              confirmButtonText: 'Proceed with Grants',
              dismissButtonText: 'Wait for Constraints Check',
            }),
          1,
        );
      }

      function setUnsatisfiedModal() {
        this.unsatisfiedCondId = ProcessButtonModel.$addSubmitCondition(
          'continue',
          () =>
            $confirm({
              body: UNSATISFIED_CONSTRAINTS_WARNING,
              alertType: 'danger',
              confirmButtonText: 'Understood',
            }),
          1,
        );
      }

      this.$onInit = $onInit.bind(this);
      this.setUnsatisfiedModal = setUnsatisfiedModal.bind(this);
      this.setUnfinishedModal = setUnfinishedModal.bind(this);
    },
  ],
}; // end sbConstraintsOverview
