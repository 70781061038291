import { Component, Input, Inject, OnInit } from '@angular/core';
import { Downgrade } from '../downgrade';
import { StakeholderCard } from '@shoobx/types/webapi-v2';
import { Stakeholders } from '../../../js/lib/stakeholder/service';

@Downgrade.Component('ngShoobx', 'sbx-stakeholder-card-list')
@Component({
  selector: 'sbx-stakeholder-card-list',
  templateUrl: './sbx-stakeholder-card-list.component.html',
  styleUrls: ['./sbx-stakeholder-card-list.component.scss'],
})
export class SbxStakeholderCardListComponent implements OnInit {
  @Input() stakeholderObjects: Array<StakeholderCard>;
  @Input() stakeholderIds: Array<string>;

  @Input() showApproval: boolean = true;
  @Input() format: string = 'medium';

  constructor(@Inject(Stakeholders) private stakeholderService) {}

  async ngOnInit() {
    if (this.stakeholderIds && !this.stakeholderObjects) {
      // only allow numbers, letters, and underscore
      const ids = this.stakeholderIds.map((x) =>
        x.toString().replace(/[^0-9a-z_]/gi, ''),
      );
      this.stakeholderObjects = await this.stakeholderService.getStakeholders(
        ids,
        false,
      );
    }
  }
}
