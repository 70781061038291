/**
 * @ngdoc service
 * @name sb.workitem.manageSubprocesses.SubProcessModel
 *
 * @description
 * This service interacts with the process API on a subprocess WI.
 *
 * @TODO Since we expect many contexts to use a Process API Model, make this
 * a new-able class so that we can make many of them.
 */
export const SubProcessModel = [
  '$q',
  '$timeout',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, $timeout, BackendLocation, SimpleHTTPWrapper) {
    class Model {
      constructor(wiId) {
        /**
         * @ngdoc property
         * @name $processes
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Array of process objects as described by the API.
         */
        this.$processes = [];

        /**
         * @ngdoc property
         * @name $autoStartsSubprocesses
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Boolean indicating if this workitem can start subprocesses automatically.
         * If true, the model polls backend until all processes have started.
         */
        this.$autoStartsSubprocesses = false;

        /**
         * @ngdoc property
         * @name $extraData
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Object of addtional data retrived from the API with a call to `$load`.
         */
        this.$extraData = {};

        /**
         * @ngdoc property
         * @name $tableConfig
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Object of table configuration data.
         */
        this.$tableConfig = {};

        /**
         * @ngdoc property
         * @name $loading
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Boolean indicating loading that blocks UI
         */
        this.$loading = false;
        this.$loadingText = 'Loading...';

        /**
         * @ngdoc property
         * @name $loading
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Boolean indicating loading in background
         */
        this.$refreshing = false;
        this.$$updatedWhileRefreshing = false;
        this.$$refreshPromise = null;

        /**
         * @ngdoc property
         * @name $processesStarting
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * Boolean indicating that some of the processes are scheduled, but not started yet
         */
        this.$processesStarting = false;

        /**
         * @ngdoc property
         * @name $wiUrl
         * @propertyOf sb.workitem.manageSubprocesses.SubProcessModel
         *
         * @description
         * String of backend workitem url
         */
        this.$wiUrl = wiId
          ? `${BackendLocation.entity(1)}workitems/${wiId}/`
          : BackendLocation.context(1);
      }

      httpAndUpdate(config, defaultError, updateFunc) {
        this.$loading = true;
        return SimpleHTTPWrapper(config, defaultError)
          .then(updateFunc, (reason) => $q.reject(reason))
          .finally(() => {
            this.$loading = false;
          });
      }

      /**
       * @description
       * Find the first process not found in the old process list.
       *
       * @param {array} newProcs New process list
       *
       * @return {object|undefined} New process object
       */
      $$findNewProcess(newProcs) {
        const oldProcIds = (this.$processes || []).reduce((accum, proc) => {
          accum[proc.id] = true;
          return accum;
        }, {});
        return (newProcs || []).find((proc) => !oldProcIds[proc.id]);
      }

      /**
       * @ngdoc method
       * @name $remove
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Remove a process from the model.
       *
       * @param {string} id Process ID to remove.
       *
       * @returns {promise} Resolves on successful remove and rejects with reason.
       */
      $remove(id) {
        return this.httpAndUpdate(
          {
            method: 'DELETE',
            url: this.$wiUrl + 'processes/' + id,
          },
          'Failed to remove process.',
          (data) => {
            this.$$setProcesses(data.processes);
          },
        );
      }

      /**
       * @ngdoc method
       * @name $removeIfNoInteraction
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Remove a process from the model if no interactive WI was completed.
       *
       * @param {string} id Process ID to remove.
       *
       * @returns {promise} Resolves on successful remove and rejects with reason.
       */
      $removeIfNoInteraction(id) {
        return this.httpAndUpdate(
          {
            method: 'DELETE',
            url: this.$wiUrl + 'processes/' + id + '/no-interaction',
          },
          'Failed to remove process.',
          (data) => {
            this.$$setProcesses(data.processes);
          },
        );
      }

      /**
       * @ngdoc method
       * @name $edit
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Allows a user to edit process after its been all ready completed.
       *
       * @param {string} id Process ID to edit.
       *
       * @returns {promise} Resolves on successful remove and rejects with reason.
       */
      $edit(id) {
        return this.httpAndUpdate(
          {
            method: 'POST',
            data: { fn: 'edit' },
            url: this.$wiUrl + 'processes/' + id,
          },
          'Failed to edit process.',
          (data) => {
            this.$$setProcesses(data.processes);
            return this.$processes;
          },
        );
      }

      /**
       * @ngdoc method
       * @name $restart
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Reset a process from the model (basically remove and create a new process
       * with the same initiator).
       *
       * @param {string} id Process ID to reset.
       *
       * @returns {promise} Resolves on successful remove with the "new" process
       *    object and rejects with reason.
       */
      $restart(id) {
        return this.httpAndUpdate(
          {
            method: 'POST',
            url: this.$wiUrl + 'processes/' + id,
          },
          'Failed to remove process.',
          (data) => {
            const newProc = this.$$findNewProcess(data.processes);
            this.$$setProcesses(data.processes);
            if (newProc) {
              return newProc;
            }
            // Edit mode is in-place replacement
            return this.$processes.find((proc) => proc.id === id);
          },
        );
      }

      /**
       * @ngdoc method
       * @name $manuallyFinish
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Allow someone to manually finish a process (via upload) by removing
       * existing processes and starting a new one.
       *
       * @param {string} id Process ID to reset.
       *
       * @returns {promise} Resolves on successful remove with the "new" process
       *    object and rejects with reason.
       */
      $manuallyFinish(id) {
        return this.httpAndUpdate(
          {
            method: 'POST',
            data: { fn: 'manuallyFinish' },
            url: this.$wiUrl + 'processes/' + id,
          },
          'Failed to take over process.',
          (data) => {
            const newProc = this.$$findNewProcess(data.processes);
            this.$$setProcesses(data.processes);
            return newProc;
          },
        );
      }

      /**
       * @ngdoc method
       * @name $override
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Allow another pathway in the process
       *
       * @param {string} id Process ID to reset.
       * @param {string} key Key of override parameters for the workitem.
       *
       * @returns {promise} Resolves on successful remove with the "new" process
       *    object and rejects with reason.
       */
      $override(id, key) {
        return this.httpAndUpdate(
          {
            method: 'POST',
            data: { fn: 'override', overrideKey: key },
            url: this.$wiUrl + 'processes/' + id,
          },
          'Failed to take over process.',
          (data) => {
            const newProc = this.$$findNewProcess(data.processes);
            this.$$setProcesses(data.processes);
            return newProc;
          },
        );
      }

      /**
       * @ngdoc method
       * @name $new
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Request a new process from the API.
       *
       * @param {object} [data=undefined] Any addtional data to send to the server.
       *
       * @returns {promise} Resolves on successful addition with the newest process
       *    object and rejects with reason.
       */
      $new(data) {
        return this.httpAndUpdate(
          {
            method: 'PUT',
            url: this.$wiUrl + 'processes',
            data: data || {},
          },
          'Failed to start process.',
          (data) => {
            const newProc = this.$$findNewProcess(data.processes);
            this.$$setProcesses(data.processes);
            return newProc;
          },
        );
      }

      /**
       * @ngdoc method
       * @name $load
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Request a fresh load of the model.
       *
       * @returns {promise} This promise resolves on successful load and rejects on
       *    failure with reason.
       */
      $load() {
        this.$loadingText = 'Loading...';
        return this.httpAndUpdate(
          {
            method: 'GET',
            url: this.$wiUrl + 'processes',
          },
          'Failed to fetch processes.',
          (data) => {
            this.$extraData = data.extraData;
            this.$tableConfig = data.tableConfig;
            this.$autoStartsSubprocesses = data.autoStartsSubprocesses;
            this.$$setProcesses(data.processes);
          },
        );
      }

      $$setProcesses(processes) {
        this.$$clearPendingRefresh();
        this.$processes = processes;

        if (this.$autoStartsSubprocesses) {
          // Some processes may not have started yet,
          // schedule automatic refresh if needed.
          this.$processesStarting = Boolean(
            processes.filter((proc) => proc.isStarting).length,
          );
          if (this.$refreshing) {
            // we're currently refreshing, let the refresh loader know
            // it may retrieve outdated process info
            this.$$updatedWhileRefreshing = true;
          } else {
            this.$$scheduleRefreshIfNeeded();
          }
        }
      }

      $$scheduleRefreshIfNeeded() {
        const retryInterval = 1000;
        if (this.$processesStarting) {
          // some processes are still starting, schedule a refresh
          this.$$refreshPromise = $timeout(this.$$refresh.bind(this), retryInterval);
        }
      }

      $$clearPendingRefresh() {
        if (this.$$refreshPromise) {
          // cancel any pending refresh requests
          $timeout.cancel(this.$$refreshPromise);
          this.$$refreshPromise = null;
        }
      }

      $$refresh() {
        this.$$clearPendingRefresh();
        this.$refreshing = true;
        SimpleHTTPWrapper(
          { method: 'GET', url: this.$wiUrl + 'processes' },
          'Failed to fetch processes.',
        ).then(
          (data) => {
            this.$refreshing = false;
            if (this.$$updatedWhileRefreshing) {
              // process info we received may be already outdated, ditch it and refresh
              this.$$updatedWhileRefreshing = false;
              this.$$scheduleRefreshIfNeeded();
            } else {
              this.$$setProcesses(data.processes);
            }
          },
          (reason) => {
            this.$refreshing = false;
            return $q.reject(reason);
          },
        );
      }

      /**
       * @ngdoc method
       * @name $init
       * @methodOf sb.workitem.manageSubprocesses.SubProcessModel
       *
       * @description
       * Init and `$load()` this model.
       *
       * @returns {promise} This promise resolves on successful load and rejects on
       *    failure with reason.
       */
      $init() {
        this.$processes = [];
        this.$extraData = {};
        this.$tableConfig = {};
        return this.$load();
      }
    }

    return Model;
  },
]; // end SubProcessModel
