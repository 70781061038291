import { NgModule } from '@angular/core';
import { SbxTableComponent } from './sbx-table.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { CommonModule } from '@angular/common';
import { ProfileGroupsComponent } from './column-templates/profile-groups.component';
import { BoolCheckComponent } from './column-templates/bool-check.component';
import { SbxTreeModule } from '../sbx-tree/sbx-tree.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxBulkDescriptionComponent } from '@/shared/sbx-table/sbx-bulk-description/sbx-bulk-description.component';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxTreeModule, SbxPopoverModule],
  declarations: [
    SbxTableComponent,
    ProfileGroupsComponent,
    BoolCheckComponent,
    SbxBulkDescriptionComponent,
  ],
  exports: [
    SbxTableComponent,
    ProfileGroupsComponent,
    BoolCheckComponent,
    SbxBulkDescriptionComponent,
  ],
})
export class SbxTableModule {}
