import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'sbx-liquidation-stack',
  templateUrl: './sbx-liquidation-stack.component.html',
  styleUrls: ['sbx-liquidation-stack.component.scss'],
})
export class SbxLiquidationStackComponent implements OnInit {
  @Input('stack') initialStack = [];
  @Input() canEdit = true;
  @Output() change = new EventEmitter<any>();
  stack = null;
  common = null;

  ngOnInit() {
    const stack = this.initialStack.map((classes) => ({
      type: 'group',
      classes,
    }));

    this.common = [stack.pop()];
    this.stack = stack;
  }

  onDrop(event: DndDropEvent, stack: any[], type: 'group' | 'class') {
    if (!stack || event.dropEffect !== 'move') {
      return;
    }

    // If we drag class to group
    if (stack && stack.length && type === 'group' && event.type === 'class') {
      event.data = { type: 'group', classes: [event.data] };
    }

    stack.splice(event.index, 0, event.data);
  }

  onDragged(event, item: any, stack: any[]) {
    const index = stack.indexOf(item);
    stack.splice(index, 1);

    const emptyGroupIndex = this.stack.findIndex((group) => {
      return !group.classes || !group.classes.length;
    });

    if (emptyGroupIndex > -1) {
      this.stack.splice(emptyGroupIndex, 1);
    }

    const normalizedStack = this.stack.concat(this.common).reduce((acc, classes) => {
      return [...acc, classes.classes];
    }, []);

    this.change.emit(normalizedStack);
  }
}
