import angular from 'angular';
import Modal from '../modal/modal';
import sbBootstrap from './../bootstrap/bootstrap';
import Tools from '../../lib/tools';
import Form from './../form/form';
import URL from '../url';
import sbBulkSharingTable from './../sharing';

import {
  RelationshipPermissionsModalCtrl,
  PermissionsBehavior,
  sbTargetRelationshipPermissions,
  sbSourceRelationshipPermissionsTable,
  sbTargetRelationshipSourceTeam,
  sbRelationshipTeamTable,
  sbRelationshipFundTable,
  sbRelationshipAccessPage,
} from './permissions';
import { sbFundsManagement, sbFundManagement } from './funds';

import { sbTargetDiligenceMode, sbSourceDiligenceMode } from './diligence';
import { Relationship, TargetRelationship, SourceRelationship } from './relationships';

/**
 * @ngdoc overview
 * @name sb.lib.relationships
 *
 * @description
 * This module houses code for relationships.
 */
export default angular
  .module('sb.lib.relationships', [
    Modal,
    sbBootstrap,
    Form,
    Tools,
    URL,
    sbBulkSharingTable,
  ])

  .controller('RelationshipPermissionsModalCtrl', RelationshipPermissionsModalCtrl)
  .directive('sbTargetRelationshipPermissions', sbTargetRelationshipPermissions)
  .directive(
    'sbSourceRelationshipPermissionsTable',
    sbSourceRelationshipPermissionsTable
  )
  .directive('sbTargetRelationshipSourceTeam', sbTargetRelationshipSourceTeam)
  .directive('sbRelationshipTeamTable', sbRelationshipTeamTable)
  .directive('sbRelationshipFundTable', sbRelationshipFundTable)
  .directive('sbRelationshipAccessPage', sbRelationshipAccessPage)
  .directive('sbTargetDiligenceMode', sbTargetDiligenceMode)
  .directive('sbSourceDiligenceMode', sbSourceDiligenceMode)
  .directive('sbFundsManagement', sbFundsManagement)
  .directive('sbFundManagement', sbFundManagement)
  .factory('PermissionsBehavior', PermissionsBehavior)
  .factory('Relationship', Relationship)
  .factory('TargetRelationship', TargetRelationship)
  .factory('SourceRelationship', SourceRelationship).name;
