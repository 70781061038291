import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxCarouselComponent } from './sbx-carousel.component';
import { SbxPipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [CommonModule, NgbModule, SbxButtonModule, SbxIconModule, SbxPipesModule],
  declarations: [SbxCarouselComponent],
  exports: [SbxCarouselComponent],
})
export class SbxCarouselModule {}
