import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Downgrade } from '../downgrade';
import { ISbxDocumentPickFromModalConfig } from './sbx-document-pick-from.component';

export enum SbxDocumentReferenceModelType {
  Waive = 'waive',
  Upload = 'upload',
  PickFrom = 'pickFrom',
  Defer = 'defer',
}

export interface ISbxDocumentReferenceModel {
  type: SbxDocumentReferenceModelType;
  documentTitle: string;
  documentId?: string;
  pdfURL?: string;
  uploadId?: string;
  processId?: string;
}

export class SbxDocumentReferenceComponentConfig
  implements ISbxDocumentPickFromModalConfig
{
  allowDefer: boolean;
  allowPickFrom: boolean;
  allowUpload: boolean;
  allowWaive: boolean;
  rootFolderId: string;

  // optional configs
  collectTitleInUpload?: boolean;
  defaultTitle?: string;
  allowAllFromProc?: boolean;
  allowPickManaged?: boolean;
  allowUnfinalized?: boolean;
  allowPending?: boolean;
  managedTagFilter?: string[];
}

@Downgrade.Component('ngShoobx', 'sbx-document-reference')
@Component({
  selector: 'sbx-document-reference',
  templateUrl: './sbx-document-reference.component.html',
  styleUrls: ['./sbx-document-reference.component.scss'],
})
export class SbxDocumentReferenceComponent implements OnInit {
  @Input() config: SbxDocumentReferenceComponentConfig;
  @Input() api: string;
  @Input() pickFromModalTitle?: string;
  @Input() excludeFn?: () => true;
  @Output() modelChange = new EventEmitter();

  @Input()
  get model() {
    return this.docRefModel;
  }

  set model(val) {
    this.docRefModel = val;
    this.modelChange.emit(this.docRefModel);
  }

  private docRefModel: ISbxDocumentReferenceModel;

  modelTypes = SbxDocumentReferenceModelType;
  apiResource: () => string;

  ngOnInit(): void {
    this.apiResource = () => this.api;
    if (!this.model?.documentTitle) {
      this.clearModel();
    }
  }

  clearModel() {
    this.model = {
      type: null,
      documentTitle: null,
    };
  }
}
