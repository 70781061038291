import { Component, Input } from '@angular/core';
import { SbxModalService } from '../../sbx-modal/sbx-modal.service';

const CREATE_NEW_KEY = '_CREATE_';
const DONT_USE_AFFILIATE_KEY = '_NO_AFFILIATE_';

@Component({
  selector: 'sbx-existing-stakeholder-modal',
  templateUrl: './sbx-existing-stakeholder-modal.component.html',
  styleUrls: ['./sbx-existing-stakeholder-modal.component.scss'],
})
export class SbxExistingStakeholderModalComponent {
  @Input() shTitle = 'a';

  constructor(private sbxModalService: SbxModalService) {}

  save() {
    this.sbxModalService.close();
  }

  edit() {
    this.sbxModalService.dismiss();
  }
}
