import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'sbx-number-textline',
  templateUrl: './sbx-number-textline.component.html',
  styleUrls: ['./sbx-number-textline.component.scss'],
})
export class SbxNumberTextlineComponent extends FieldType<FieldTypeConfig> {
  handleChange(value) {
    this.formControl.setValue(value);
  }

  handleFocus(event) {
    if (this.to.focus) {
      this.to.focus(this.field, event);
    }
  }

  handleBlur() {
    this.formControl.markAsTouched();
  }
}
