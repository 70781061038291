/**
 * @ngdoc component
 * @name sb.lib.metadata.component:sbMetdataChange
 *
 * @description
 * This generically displays a historical change to a metadata.
 *
 * @param {string} [data=undefined] UTC time of change made.
 * @param {object} [document=undefined] Object with `title` and `url` properties.
 * @param {string} [stakeholder=undefined] Stakeholder name who made the change.
 */
export const sbMetdataChange = {
  template: require('./templates/change.html'),
  bindings: {
    date: '<?',
    document: '<?',
    stakeholder: '<?',
  },
  controllerAs: 'vm',
}; // end sbMetdataChange
