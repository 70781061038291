import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxProgressBarComponent } from './sbx-progress-bar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxProgressBarComponent],
  exports: [SbxProgressBarComponent],
})
export class SbxProgressBarModule {
  static entryComponents = [SbxProgressBarComponent];

  static forRoot(): ModuleWithProviders<SbxProgressBarModule> {
    return {
      ngModule: SbxProgressBarModule,
    };
  }
}
