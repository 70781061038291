import { Component, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxStakeholderBaseMode } from '../sbx-stakeholder-base/sbx-stakeholder-base.component';

@Component({
  selector: 'sbx-stakeholder-list',
  templateUrl: './sbx-stakeholder-list.component.html',
  styleUrls: ['./sbx-stakeholder-list.component.scss'],
})
export class SbxStakeholderListComponent extends FieldType<FieldTypeConfig> {

  stakeholderBaseMode = SbxStakeholderBaseMode;

  updateModel() {
    if (this.field) {
      this.formControl.setValue(this.field.model[String(this.field.key)]);
    }
  }

  getItemModel(item) {
    return { [String(this.field.key)]: item };
  }

  addItem(stakeholderObject) {
    if (
      stakeholderObject.sh.id &&
      !this.field.model[String(this.field.key)]
        .map((item) => {
          if (item.sh) {
            return item.sh.id;
          }

          return '';
        })
        .includes(stakeholderObject.sh.id)
    ) {
      this.field.model[String(this.field.key)].push(stakeholderObject);
      this.updateModel();
    }
  }

  removeItem(stakeholderObject) {
    for (let i = 0; i < this.field.model[String(this.field.key)].length; ++i) {
      if (
        this.field.model[String(this.field.key)][i].sh.id === stakeholderObject.sh.id
      ) {
        this.field.model[String(this.field.key)].splice(i, 1);
        break;
      }
    }
    this.updateModel();
    this.handleFocus(stakeholderObject);
  }

  handleFocus(event) {
    if (this.to.focus) {
      this.to.focus(this.field, event);
    }
  }

  handleBlur() {
    this.formControl.markAsTouched();
  }
}
