<input
  [type]="to.secure ? 'password' : 'input'"
  [placeholder]="to.placeholder"
  [name]="field.key"
  [formControl]="formControl"
  [ngClass]="{ 'has-errors': showError }"
  (focus)="to.focus ? to.focus(field, $event) : null"
  (blur)="to.blur ? to.blur(field, $event) : null"
  (keyup)="handleKeyup()"
/>
