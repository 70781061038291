import { Component, ViewChild, ViewContainerRef, Inject } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SbxInvestmentService } from '@/sbx-investment/sbx-investment.service';

@Component({
  selector: 'sbx-liquidation-stack-modal',
  templateUrl: './sbx-liquidation-stack-modal.component.html',
  styleUrls: ['sbx-liquidation-stack-modal.component.scss'],
})
export class SbxLiquidationStackModalComponent {
  @ViewChild('container', { read: ViewContainerRef }) containerRef;

  stack = [];
  canEdit = true;

  constructor(
    @Inject(SbxInvestmentService) public sbxInvestmentService: SbxInvestmentService,
    public sbxModalService: SbxModalService,
    public activeModal: NgbActiveModal,
  ) {}

  change(stack) {
    this.stack = stack;
  }

  handleSave() {
    this.sbxModalService.close(this.stack);
  }
}
