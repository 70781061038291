import angular from 'angular';
/**
 * @ngdoc component
 * @name sb.lib.navigation.component:sbBreadcrumb
 *
 * @description
 * Breadcrumb widget to display a path with ablity to click on each element.
 *
 * @param {Array<Object>} path The elements to display in breadcrumb.
 *   @property {string} id Unique item ID.
 *   @property {string} title Human readable title.
 * @param {expression} crumbClick This expression will be evaluated when user selects
 *   an element in the path. `$crumb`, the object clicked, will be in the namespace.
 * @example
   <sb-breadcrumb
     path="[{ id: 1, title: 'Data Room' }, { id: 2, title: 'HR' }]"
     crumb-click="vm.pathClick($crumb)">
   </sb-breadcrumb>
 */
export const sbBreadCrumb = {
  controllerAs: 'vm',
  template: require('./templates/breadcrumb.html'),
  bindings: {
    path: '<',
    crumbClick: '&',
  },
  controller: class BreadcrumbCtrl {
    $onChanges() {
      let crumbs = this.path;
      if (!Array.isArray(this.path)) {
        crumbs = this.path.toArray();
      }
      let head = null;
      let tail = [null];
      if (crumbs.length > 0) {
        head = crumbs[0];
      }
      if (crumbs.length > 1) {
        tail = crumbs.slice(1);
      }
      this.first = head;
      this.last = tail.pop();
      this.middle = tail;
      this.fullPath = crumbs.map((i) => i.title).join(' > ');
    }

    handleClick(evt, $crumb) {
      if ($crumb.permitted || angular.isUndefined($crumb.permitted)) {
        evt.preventDefault();

        this.crumbClick({ $crumb });
      }
    }
  },
};
