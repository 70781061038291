import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SbxSideNavComponent } from './sbx-side-nav.component';
import { SbxSideNavListComponent } from './sbx-side-nav-list.component';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxClickOutsideModule } from '../sbx-click-outside/sbx-click-outside.module';

@NgModule({
  imports: [
    RouterModule.forChild([]),
    CommonModule,
    SbxClickOutsideModule,
    SbxGridModule,
    SbxIconModule,
  ],
  declarations: [SbxSideNavComponent, SbxSideNavListComponent],
  exports: [SbxSideNavComponent, SbxSideNavListComponent],
})
export class SbxSideNavModule {}
