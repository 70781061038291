import { Injectable, Inject, forwardRef } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { BackendLocation } from './backend-location.service';
import { IContextDependent, ISbxRequest } from './interfaces';
import { Observable, catchError, throwError } from 'rxjs';
import { SbxNextLocationTrackerService } from '../next-location-tracker';
import { AppConfig } from '../config/app.config';

/**
 *  Low-level Observable API for making ajax requests.
 *
 *  get, post, put, and delete methods return observables of
 *  the JSON response body.
 *
 * Examples:
 *
 *  sbxHttp.entity(1)
 *    .get<IStakeholder>('stakeholders')
 *    .subscribe(...)
 *
 *  sbxHttp.context(1)
 *    .put('upload')
 *    .subscribe(...)
 *
 *  sbxHttp.context(1)
 *    .get<Item[]>({
 *      endpoint: 'items',
 *      params: {
 *        limit: 20,
 *        sort: 'asc',
 *      }
 *    })
 *    .subscribe(...)
 *
 *  sbxHttp.entity(1)
 *    .delete('users/123', {
 *      params: {
 *        limit: 20,
 *        sort: 'asc',
 *      }
 *    })
 *    .subscribe(...)
 */
@Injectable({
  providedIn: 'root',
})
export class SbxHttpClient implements IContextDependent<SbxHttpClient> {
  private version: string;
  private ctx: string;
  private useFullUrl: boolean;

  constructor(
    @Inject(forwardRef(() => HttpClient))
    private http: HttpClient,
    @Inject(forwardRef(() => BackendLocation))
    private backendLocation: BackendLocation,
    @Inject(forwardRef(() => SbxNextLocationTrackerService))
    private sbxNextLocationTrackerService: SbxNextLocationTrackerService,
    @Inject(forwardRef(() => AppConfig))
    private appConfig: AppConfig,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  setVersion(version: string): SbxHttpClient {
    this.version = version;
    return this;
  }

  setContext(ctx: string): SbxHttpClient {
    this.ctx = ctx;
    return this;
  }

  setUseFullUrl(useFullUrl: boolean): SbxHttpClient {
    this.useFullUrl = useFullUrl;
    return this;
  }

  /**
   * Set the baseUrl context to entity.
   *
   * @return this for method chaining.
   */
  entity(version: string): SbxHttpClient {
    const ctx = 'entity';
    return new SbxHttpClient(
      this.http,
      this.backendLocation,
      this.sbxNextLocationTrackerService,
      this.appConfig,
    )
      .setVersion(version)
      .setContext(ctx);
  }

  /**
   * Set the baseUrl context to root.
   *
   * @return this for method chaining.
   */
  root(version: string): SbxHttpClient {
    const ctx = 'root';
    return new SbxHttpClient(
      this.http,
      this.backendLocation,
      this.sbxNextLocationTrackerService,
      this.appConfig,
    )
      .setVersion(version)
      .setContext(ctx);
  }

  /**
   * Set the baseUrl to the current page context.
   *
   * @return this for method chaining.
   */
  context(version: string): SbxHttpClient {
    const ctx = 'context';
    return new SbxHttpClient(
      this.http,
      this.backendLocation,
      this.sbxNextLocationTrackerService,
      this.appConfig,
    )
      .setVersion(version)
      .setContext(ctx);
  }

  /**
   * Set the useFullUrl.
   *
   * @return this for method chaining.
   */
  fullUrl(): SbxHttpClient {
    return new SbxHttpClient(
      this.http,
      this.backendLocation,
      this.sbxNextLocationTrackerService,
      this.appConfig,
    ).setUseFullUrl(true);
  }

  /**
   * Base endpoint url.
   *
   * Throws an error if the client has not been configured with a context or is not switched to useFullUrl.
   *
   * @return a url string.
   */
  private get baseUrl() {
    const { ctx, version, backendLocation, useFullUrl } = this;
    if (ctx && version) {
      return backendLocation[ctx](version);
    } else if (useFullUrl) {
      return '';
    }
    throw new Error('No context set for SbxHttpClient');
  }

  /**
   * Add request headers required by WebAPI.
   *
   * WebAPI v1 keeps user's entity stakeholder in URL,
   * but v2 expects it to be set in request headers.
   */
  private addOnbehalfHeaders(options) {
    const { version, backendLocation } = this;
    if (version !== '2') {
      return options;
    }
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    const onbehalf = backendLocation.onBehalf(version);
    if (onbehalf) {
      options.headers = options.headers.set('X-SB-Onbehalf', onbehalf);
    }
    return options;
  }

  /**
   *  Make a plain HttpClient request.
   *
   *  Should not be needed except for very specific use cases, such as getting a
   *  responseType other than a JSON, or observing individual HttpEvents.
   *
   *  @return an `Observable` of the body that matches `http.request`.
   */
  request(method: string, endpoint: string, options) {
    const { http, baseUrl } = this;

    options = this.addOnbehalfHeaders(options);

    return http
      .request(method, baseUrl + endpoint, options)
      .pipe(catchError(this.handleError));
  }

  /**
   *  Make a GET request given a endpoint path url and optional `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  get<R>(endpoint: string, options?: ISbxRequest): Observable<R>;

  /**
   *  Make a GET request given an `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  get<R>(request: ISbxRequest): Observable<R>;

  /**
   *  Make a GET request.
   *
   *  Overloaded with options to call with a url, `ISbxRequest`, or both.
   *
   * @return an `Observable` of the json body type R.
   */
  get<R>(...request): Observable<R> {
    const { url, options } = this.format(...request);
    return this.http.get<R>(url, options).pipe(catchError(this.handleError));
  }

  /**
   *  Make a POST request given a endpoint path url and optional `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  post<R>(endpoint: string, options?: ISbxRequest): Observable<R>;

  /**
   *  Make a POST request given an `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  post<R>(request: ISbxRequest): Observable<R>;

  /**
   *  Make a POST request.
   *
   *  Overloaded with options to call with a url, `ISbxRequest`, or both.
   *
   * @return an `Observable` of the json body type R.
   */
  post<R>(...request): Observable<R> {
    const { url, options, postBody } = this.format(...request);

    if (options && options.params) {
      delete options.params;
    }

    // If body is not provided, we still passing the empty object to force
    // Angular HttpRequest to set the content-type header. This header is
    // required by Authentication Monitor for all POST requests.
    return this.http
      .post<R>(url, postBody || {}, options)
      .pipe(catchError(this.handleError));
  }

  /**
   *  Make a PUT request given a endpoint path url and optional `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  put<R>(endpoint: string, options?: ISbxRequest): Observable<R>;

  /**
   *  Make a PUT request given an `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  put<R>(request: ISbxRequest): Observable<R>;

  /**
   *  Make a PUT request.
   *
   *  Overloaded with options to call with a url, `ISbxRequest`, or both.
   *
   * @return an `Observable` of the json body type R.
   */
  put<R>(...request): Observable<R> {
    const { url, options, postBody } = this.format(...request);
    return this.http.put<R>(url, postBody, options).pipe(catchError(this.handleError));
  }

  /**
   *  Make a DELETE request given a endpoint path url and optional `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  delete<R>(endpoint: string, options?: ISbxRequest): Observable<R>;

  /**
   *  Make a DELETE request given an `ISbxRequest`.
   *
   * @return an `Observable` of the json body type R.
   */
  delete<R>(request: ISbxRequest): Observable<R>;

  /**
   *  Make a DELETE request.
   *
   *  Overloaded with options to call with a url, `ISbxRequest`, or both.
   *
   * @return an `Observable` of the json body type R.
   */
  delete<R>(...request): Observable<R> {
    const { url, options } = this.format(...request);
    return this.http.delete<R>(url, options).pipe(catchError(this.handleError));
  }

  /**
   * Transforms SbxHttpClient parameters into HttpClient parameters.
   *
   * @return an object with the full url and additional HttpRequest options.
   */
  private format(
    endpointOrRequest: string | ISbxRequest = '',
    options: ISbxRequest = {},
  ): { url: string; options: ISbxRequest; postBody: HttpParams } {
    let url, request;
    if (typeof endpointOrRequest === 'string') {
      request = options || {};
      url = this.baseUrl + endpointOrRequest;
    } else {
      request = endpointOrRequest;
      url = this.baseUrl + endpointOrRequest.endpoint;
    }
    request.responseType = 'json';
    request.observe = 'body';
    let postBody;
    if (!request.params) {
      request.params = new HttpParams();
    }
    if (!(request.params instanceof HttpParams)) {
      postBody = request.params;

      const stringifyVal = (v) => {
        if (typeof v !== 'string') {
          return JSON.stringify(v);
        }
        return v;
      };

      request.params = Object.keys(request.params).reduce((params, param) => {
        const val = request.params[param];
        if (Array.isArray(val)) {
          val.forEach((v) => {
            params = params.append(param, stringifyVal(v));
          });
          return params;
        }
        if (val !== null && val !== undefined) {
          return params.set(param, stringifyVal(val));
        }
        return params;
      }, new HttpParams());
    }
    // XXX might be better implemented in an interceptor
    if (!request.headers) {
      request.headers = new HttpHeaders();
    }
    request.headers = request.headers.set('X-Requested-With', 'XMLHttpRequest');

    request = this.addOnbehalfHeaders(request);

    return { url, options: request, postBody };
  }

  /**
   * Http request error handler.
   *
   * Throws a generic message for javascript errors and
   * body, status, and message for server-side errors.
   */
  handleError(response: HttpErrorResponse) {
    if (response.error instanceof ErrorEvent) {
      return throwError({
        error: response.error,
        message: 'An error occurred and has been reported. Please try again later.',
      });
    }
    const { error, status, message } = response;
    if (
      handleRedirect(status, {
        redirectUrl: error?.redirectUrl,
        nextUrl: this.sbxNextLocationTrackerService.nextUrl,
        entityName: this.appConfig.currentEntity?.name,
      })
    ) {
      return throwError({ error });
    }
    return throwError({ error, status, message });
  }
}

export function handleRedirect(status, data) {
  const { hash, pathname, search } = location;
  const camefrom = data.nextUrl
    ? `/spa${data.nextUrl}`
    : encodeURIComponent(`${pathname}${search || ''}${hash || ''}`);

  if (status === 403) {
    window.location.replace(`/spa/${data.entityName || ''}/forbidden`);
  } else if (status === 401) {
    // XXX: This should use angular router if available
    window.location.replace(`/spa/auth/login?camefrom=${camefrom}`);
    return true;
  } else if (status === 478) {
    const { redirectUrl } = data;
    if (!redirectUrl || window.location.href === redirectUrl) {
      // If we are currently at the same location as the redirect, just reload
      // so that the workitem load handler will not fire.
      window.location.reload();
      return true;
    }
    window.location.replace(redirectUrl);
    return true;
  } else if (status === 503) {
    if (window.location.pathname !== '/spa/error') {
      window.location.replace(`/spa/error?camefrom=${camefrom}&initial=true`);
      return true;
    }

    return false;
  }
}
