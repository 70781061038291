export function sbStakeholderApprovalItem() {
  return {
    restrict: 'EA',
    template: require('./templates/approval-item.html'),
    scope: {
      stakeholder: '=',
    },
    controller: [
      '$scope',
      'Stakeholders',
      'ProcessStatus',
      'ProcessButtonModel',
      function ($scope, Stakeholders, ProcessStatus, ProcessButtonModel) {
        this.$onInit = () => {
          ProcessButtonModel.disable('continue');
        };

        $scope.actionsVisible = true;
        $scope.rejectionDialogVisible = false;
        $scope.wasRejected = false;
        $scope.wasApproved = false;
        $scope.reason = '';
        $scope.doing = null;

        $scope.approve = function () {
          $scope.actionsVisible = false;
          $scope.doing = 'Approving...';
          ProcessButtonModel.requestEnable('continue');

          Stakeholders.approve($scope.stakeholder.id)
            .then(() => {
              $scope.wasApproved = true;
              $scope.doing = null;
            })
            .catch((e) => {
              $scope.actionsVisible = true;
              $scope.doing = null;
              ProcessStatus.$setStatus(e, 'danger');
            });
        };

        $scope.reject = function () {
          $scope.actionsVisible = false;
          $scope.rejectionDialogVisible = true;
        };
        $scope.cancel = function () {
          $scope.rejectionDialogVisible = false;
          $scope.actionsVisible = true;
        };

        $scope.confirmRejection = function () {
          $scope.actionsVisible = false;
          $scope.rejectionDialogVisible = false;
          $scope.doing = 'Rejecting...';
          ProcessButtonModel.requestEnable('continue');

          Stakeholders.reject($scope.stakeholder.id, $scope.reason)
            .then(() => {
              $scope.doing = null;
              $scope.wasRejected = true;
            })
            .catch((e) => {
              $scope.actionsVisible = true;
              $scope.doing = null;
              ProcessStatus.$setStatus(e, 'danger');
            });
        };
      },
    ], // end controller
  };
} // end sbStakeholderApprovalItem

export function sbStakeholderApprovalUI() {
  return {
    restrict: 'EA',
    template: require('./templates/approval-ui.html'),
    scope: {},
    controller: [
      '$scope',
      'Stakeholders',
      function ($scope, Stakeholders) {
        Stakeholders.getPendingApprovals().then((resp) => {
          $scope.stakeholders = resp.stakeholders;
        });
      },
    ],
  };
} // end sbStakeholderApprovalUI
