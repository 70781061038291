/**
 * @ngdoc compoent
 * @name sb.stakeholder.sbStakeholdersEvents
 *
 **/
export const sbStakeholderPrivilegePage = {
  controllerAs: 'vm',
  template: require('./templates/stakeholder-privilege-page.html'),
  bindings: {
    stakeholderId: '@ ',
  },
  controller: [
    '$scope',
    'Stakeholders',
    function ($scope, Stakeholders) {
      function load() {
        this.loading = true;
        Stakeholders.findPrivileges(this.stakeholderId).then((data) => {
          this.folderGrants = data.folderGrants;
          this.documentGrants = data.documentGrants;
          this.roles = data.roles;
          this.access = data.access;
          this.accessURL = data.accessURL;
          this.canEditAccess = data.canEditAccess && !data.accessSuppressed;
          this.canEditFolderAccess = data.canEditFolderAccess && !data.accessSuppressed;
          this.accessSuppressed = data.accessSuppressed;
          this.haveCustomEmployeeGroups = data.haveCustomEmployeeGroups;
          this.managedEmployeeGroups = data.managedEmployeeGroups;
          this.employeeManagement = data.employeeManagement;
          this.loading = false;
        });
      }
      this.$onInit = () => {
        this.load = load.bind(this);
        this.load();
      };
    },
  ],
};
