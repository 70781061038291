<div
  class="sbx-progress-bar-container"
  [ngClass]="{ 'sbx-full-bar': isFullSize() }"
  [style.height.px]="height"
>
  <div
    class="sbx-progress-bar sbx-completed-bar"
    *ngIf="completed"
    [ngClass]="{ 'sbx-flat-right': pending, 'sbx-not-met': threshold > completed }"
    [style.width.%]="(completed / total) * 100"
  ></div>
  <div
    class="sbx-progress-bar sbx-striped-bar"
    *ngIf="pending"
    [ngClass]="{ 'sbx-flat-left': completed }"
    [style.width.%]="(pending / total) * 100"
  ></div>
  <div
    class="sbx-progress-bar sbx-remaining-bar"
    *ngIf="remainingPercent()"
    [style.width.%]="(remainingPercent() / total) * 100"
  ></div>
</div>
