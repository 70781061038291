<div [ngClass]="{ locked: locked }">
  <a [href]="url" *ngIf="!locked">{{ title }}</a>
  <span *ngIf="locked">{{ title }}</span>
  <sbx-popover
    *ngIf="!locked && description"
    [sbxPopoverContent]="description"
    sbxPopoverPlacement="top"
    sbxPopoverContainer="body"
  >
    <sbx-icon type="help"></sbx-icon>
  </sbx-popover>
  <sbx-popover
    *ngIf="locked && lockDescription"
    [sbxPopoverContent]="lockDescription"
    sbxPopoverPlacement="top"
    sbxPopoverContainer="body"
  >
    <sbx-icon type="lock"></sbx-icon>
  </sbx-popover>
</div>
