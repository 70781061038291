import { SbxHttpClient } from '@/core/http';
import { AppConfig } from '@/core/config/app.config';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SbxLogoutRedirectService } from './sbx-logout-redirect.service';

@Injectable({ providedIn: 'root' })
export class SbxLogoutRedirectGuard implements CanActivate {
  constructor(
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(Router) private router: Router,
    @Inject(AppConfig) private appConfig: AppConfig,
    private logoutRedirectService: SbxLogoutRedirectService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot) {
    const redirected = this.logoutRedirectService.handlePossibleLogoutRedirect();
    const state = this.router.getCurrentNavigation().extras.state;

    if (!redirected) {
      const loginRoute = ['auth', 'login'];

      if (next.params.entity) {
        loginRoute.unshift(next.params.entity);
      }
      this.router.navigate(loginRoute, {
        queryParams: next.queryParams,
        state,
      });
    }
    return true;
  }
}
