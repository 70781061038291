import angular from 'angular';
import Promise from 'lib/promise';
import RX from '../../lib/rx';
import Modal from '../../lib/modal/modal';

import {
  FullDocumentModalCtrl,
  SignDocumentsWiCtrl,
  SignDocumentsWiModel,
} from './sign';

/**
 * @ngdoc overview
 * @name sb.workitem.signDocuments
 *
 * @description
 * This is the module for the sign documents WI.
 */
export default angular
  .module('sb.workitem.signDocuments', [Modal, Promise, RX])

  .controller('FullDocumentModalCtrl', FullDocumentModalCtrl)
  .controller('SignDocumentsWiCtrl', SignDocumentsWiCtrl)
  .factory('SignDocumentsWiModel', SignDocumentsWiModel).name;
