import angular from 'angular';
import { fromEvent, merge } from 'rxjs';
import {
  map,
  filter,
  switchMap,
  share,
  debounceTime as rxjsDebounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';

/**
 * @ngdoc directive
 * @name sb.lib.typeahead.directive:sbTypeaheadItem
 *
 * @description
 * An attribute used to add common behavior to sbTypeahead with select functionality. If you have a typeahead
 * with a dropdown list where users can select items from such list, you may attach this attribute to the list
 * items, and each list items will be selectable from a 'mousedown' event.
 *
 * @example
   <sb-typeahead ...>
     {{ ::$item.title }}
     <ul>
       <li
         ng-repeat="subItem in ::$item.subItems track by subItem.id"
         sb-typeahead-item="subItem">
         {{ ::subItem.title }}
         Click this to select this subitem.
       </li>
     </ul>
   </sb-typeahead>
 */
export function sbTypeaheadItem() {
  return {
    require: {
      sbTypeaheadCtrl: '^^sbTypeahead',
    },
    link: function (scope, element, attrs, { sbTypeaheadCtrl }) {
      element.on('mousedown', (event) => {
        event.preventDefault();
        event.stopPropagation();
        scope.$apply(() => {
          const item = scope.$eval(attrs.sbTypeaheadItem);
          sbTypeaheadCtrl.selectItem(item);
        });
      });
    },
  };
} // end sbTypeaheadItem

/**
 * @ngdoc component
 * @name sb.lib.typeahead.component:sbTypeahead
 *
 * @description
 * Standard widget for typeahead input box; this will show an input box to user
 * then at strategic points, show the user "suggestions" for them to select from.
 * The template for each item (will be inside a repeated `<li>`) is provided by the
 * raw html of this elements content (see the example). In addition to all the
 * standard `ngRepeat` scope augmentations, `$item` (the repeated item) and `$query`
 * (the currently searched query string) are available in the template namespace.
 *
 * @param {expression} search This expression will be evaluated when results are to
 *    be updated. `$query` is available in the namespace. The result of this
 *    evaluations is expected to be an array of objects or a promise that resolves
 *    to be an array of objects. Items may have a special property `$unselectable`,
 *    meaning that they are not to be clickable or selectable with the keyboard.
 *    First item that is not `$unselectable` will be pre-selected.
 * @param {expression} [itemSelect=] This expression will be evaluated when user
 *    selects an item in the results set either by clicking the item or using the
 *    keyboard. `$query` and `$item` are available in the namespace. The result of
 *    this evaluation is then what the inputs value is set to. The default, if one
 *    does not specify this expression, the input box will be set to the track by key
 *    of the item.
 * @param {number} [debounceTime=] Milliseconds to wait before calling `search()`
 *    This defaults to standard text field debounce timing.
 * @param {number} [minLength=1] Minimum number of characters a query must have
 *    before a search is preformed.
 * @param {boolean} [selectOnBlur=false] If truthy, active selections will be
 *    selected on blur.
 * @param {template} [placeholder=undefined] Placeholder text of the input box
 * @param {template} [trackBy='id'] This can override the trackBy key for the result
 *    set (track by expression in the ng-repeat).
 * @param {template} [itemNgClass=undefined] One can add this attribute to give the
 *    raw template string of the `ngClass` attribute of each element `<li>`.
 * @param {expression} [ngModel=undefined] A model expression
 * @param {expression} [onKeyUp=undefined] An expression to evaluate on keyup events.
 *    This will have `$query` and `$event` in the namespace.
 * @param {object} [extraContext=undefined] An object that hold extra information you
 *    may need/want in the type-ahead. It will be available as
 *   `$extraContext` in the template.
 *
 * @example
   <sb-typeahead
     search="search($query)"
     placeholder="Type your search..."
     item-select="select($item, $query)"
     item-ng-class="::{
       'alt': $item.type === 'alternate',
     }">

     <i class="fa fa-{{ ::$item.icon }}"></i>
     {{ ::$item.value }}

   </sb-typeahead>
 */
export const sbTypeahead = {
  controllerAs: 'vm',
  require: {
    ngModelCtrl: '?ngModel',
  },
  bindings: {
    search: '&',
    itemSelect: '&?',
    debounceTime: '<?',
    minLength: '<?',
    trackBy: '@?',
    placeholder: '@?',
    onKeyUp: '&?',
    selectOnBlur: '<?',
    passedExtraContext: '<?extraContext',
  },
  template: [
    '$element',
    '$attrs',
    function ($element, $attrs) {
      const $template = angular.element(
        `<div>
         <div class="loading angular-noanimate" ng-show="vm.loading">
           <sbx-icon type="spinner"></sbx-icon>
         </div>
         <input type="text" autocomplete="off" class="form-control"
           placeholder="{{ ::vm.placeholder }}"
           ng-focus="vm.onFocus()"
           ng-blur="vm.onBlur()">
         <ul class="list-unstyled"
           ng-class="{ 'invalid-results': vm.loading }"
           ng-show="vm.choicesVisible()">
           <li class="{{ $index === vm.keySelectedIndex ? 'selected' : '' }}"
             typeahead-suggestion-id="{{ ::$item[vm.trackBy || 'id'] }}"
             ng-mousedown="vm.selectItem($item)"
             ng-repeat="$item in vm.items track by $item[vm.trackBy || 'id']"></li>
         </ul>
       </div>`,
      );
      const $item = $template.find('li');
      if ($attrs.itemNgClass) {
        $item.attr('ng-class', $attrs.itemNgClass);
      }
      const $itemTemplate = $element.contents();
      $item.append($itemTemplate);
      return $template.html();
    },
  ],
  controller: [
    '$q',
    '$scope',
    '$element',
    '$observable',
    '$window',
    'TextModelOptions',
    function ($q, $scope, $element, $observable, $window, TextModelOptions) {
      function wireSearch(keyEvents) {
        const { debounceTime, minLength } = this;
        const waitTime =
          angular.isNumber(debounceTime) && debounceTime >= 0
            ? debounceTime
            : TextModelOptions.debounce.default;
        const minQLength =
          angular.isNumber(minLength) && minLength >= 0 ? minLength : 1;
        if (this.ngModelCtrl) {
          keyEvents.subscribe((evt) => {
            const { value } = evt.target;
            this.ngModelCtrl.$setViewValue(value, 'keyup');
          });
        }
        if (this.onKeyUp) {
          keyEvents.$applySubscribe($scope, ($event) => {
            const $query = $event.target.value;
            this.onKeyUp({ $query, $event });
          });
        }
        const slowDistinctSearchTerms = keyEvents.pipe(
          map((evt) => evt.target.value),
          rxjsDebounceTime(waitTime),
          distinctUntilChanged(),
          share(),
        );
        const searchResults = slowDistinctSearchTerms.pipe(
          switchMap(($query) => {
            // We ignore errors (either by promise rejection or by raw exception)
            // so that our streams remain open for later searches.
            let results;
            try {
              results = $query.length >= minQLength ? this.search({ $query }) : [];
            } catch (err) {
              results = [];
            }
            return $q.when(results).catch(() => []);
          }),
          share(),
        );
        slowDistinctSearchTerms
          .pipe(filter((query) => query.length >= minQLength))
          .$applySubscribe($scope, (query) => {
            $scope.$query = query;
            this.loading = true;
          });
        searchResults.$applySubscribe($scope, (results) => {
          this.loading = false;
          // We've got the results, now pre-activate first item that is not
          // $unselectable. We rely on this to make widget UX a bit better: when
          // person navigates out of the typeahead, we automatically select the
          // active item. This works well when the first item in the returned set
          // is the "most relevant" for entered query.
          this.keySelectedIndex = results.findIndex((item) => !item.$unselectable);
          this.items = results;
        });
      }
      function wireArrowSelection(keyupEvents, keydownEvents) {
        // Here we combine with the keypress event for enter so that we can properly
        // prevent the form submission in all browsers. We also use regular subscribe()
        // so that our preventDefault call is not delayed.
        const keypressEvents = fromEvent(this.$input[0], 'keypress');
        const enterEvents = merge(keypressEvents, keyupEvents).pipe(
          filter(({ keyCode, which }) => keyCode === 13 || which === 13),
        );

        const enterSub = enterEvents.subscribe((evt) => {
          evt.preventDefault();
          if (evt.type !== 'keyup') {
            // Only do keyups so we don't double duty the select routine.
            return;
          }
          $scope.$applyAsync(() => this.selectActiveItem());
        });
        $scope.$on('$destroy', () => {
          enterSub.unsubscribe();
        });

        const findNewIndex = (op, untilPredicate, defaultIndex, index) => {
          const newSelectedIndex = op(index || 0);
          if (untilPredicate(newSelectedIndex)) {
            return defaultIndex;
          } else if (!this.items[newSelectedIndex].$unselectable) {
            return newSelectedIndex;
          }
          return findNewIndex(op, untilPredicate, defaultIndex, newSelectedIndex);
        };
        const setNewIndex = (op, untilPredicate, defaultIndex) => {
          const newIndex = findNewIndex(
            op,
            untilPredicate,
            defaultIndex,
            this.keySelectedIndex,
          );
          // We can skip an $apply if we know the index didn't update.
          if (angular.isDefined(newIndex)) {
            $scope.$applyAsync(() => {
              this.keySelectedIndex = newIndex;
            });
          }
        };

        // We use keydown events to handle arrows to support key autorepeat
        // naturally. Long pressing an arrow will scroll many items, like user
        // would expect.
        keydownEvents
          .pipe(filter(({ keyCode }) => keyCode === 38)) // up
          .$applySubscribe($scope, () => {
            setNewIndex(
              (i) => i - 1,
              (i) => i < 0,
              0,
            );
          });

        keydownEvents
          .pipe(filter(({ keyCode }) => keyCode === 40)) // down
          .$applySubscribe($scope, () => {
            const itemsLength = this.items ? this.items.length : 0;
            setNewIndex(
              (i) => i + 1,
              (i) => i >= itemsLength,
              itemsLength - 1,
            );
          });
      }

      function wireEscape(keyupEvents, keydownEvents) {
        keydownEvents.subscribe((evt) => {
          // Pressing escape hides the choices, but pressing any key shows them
          // again.
          const esc = evt.key === 'Escape';
          if (esc && this.choicesVisible()) {
            // Hide choices, but do not close any opened modal. When no choices
            // are visible, escape keypress is passed through, allowing other
            // page elements (like modals) to handle it.
            evt.preventDefault();
          }

          $scope.$applyAsync(() => {
            this.selectable = !esc;
          });
        });
      }

      this.$onInit = () => {
        this.focused = false;
        this.selectable = false;
        $scope.$extraContext = this.passedExtraContext;
      };

      this.$postLink = () => {
        this.items = [];
        const $input = (this.$input = $element.find('input').eq(0));
        const keyupEvents = fromEvent(this.$input[0], 'keyup').pipe(share());
        const keydownEvents = fromEvent(this.$input[0], 'keydown').pipe(share());
        wireArrowSelection.call(this, keyupEvents, keydownEvents);
        wireEscape.call(this, keyupEvents, keydownEvents);
        wireSearch.call(this, keyupEvents);
        const { ngModelCtrl } = this;
        if (ngModelCtrl) {
          ngModelCtrl.$render = () => {
            const val = ngModelCtrl.$viewValue || '';
            if ($input.val() !== val) {
              $input.val(val);
            }
          };
        }
      };

      this.selectActiveItem = () => {
        if (!this.choicesVisible()) {
          return;
        }
        const item = this.items[this.keySelectedIndex];
        if (!item) {
          // Nothing is selected, ignore
          return;
        }
        this.selectItem(item);
      };

      this.onFocus = () => {
        this.focused = true;
      };

      this.onBlur = () => {
        // Only blur if we're not in testing mode:
        // An element may have lost focus because browser window lost focus.
        // This happens when working with multiple Firefox browsers
        // on Selenium grid node, and randomly disappearing typahead
        // suggestions make testing difficult.
        if (!$window.shoobxTesting) {
          // When user tabs out of the input, select the active choice. Makes
          // completion more natural.
          if (this.selectOnBlur) {
            this.selectActiveItem();
          }
          this.focused = false;
        }
      };

      this.choicesVisible = () => {
        // We only show choices when typeahead input is focused, when selection
        // is allowed (esc isn't pressed) and we have options for the value.
        return this.focused && this.selectable && this.items.length;
      };

      this.selectItem = ($item) => {
        if ($item.$unselectable) {
          return;
        }
        const { $input, trackBy, itemSelect } = this;
        const $query = $input.val();
        let newValue = $item[trackBy || 'id'];
        if (itemSelect) {
          newValue = itemSelect({ $query, $item });
        }
        $input.val(newValue);
        if (this.ngModelCtrl) {
          this.ngModelCtrl.$setViewValue(newValue);
        }
        this.selectable = false;
      };
    },
  ],
}; // end sbTypeahead
