import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyAttributeEvent } from '@ngx-formly/core/lib/models';

export function handleRTValidationFocus(
  field: FormlyFieldConfig,
  event?,
  callback?: FormlyAttributeEvent,
): FormlyAttributeEvent {
  field?.formControl?.markAsUntouched();
  if (callback) {
    callback(field, event);
  }
  return null;
}

export function handleRTValidationBlur(
  field: FormlyFieldConfig,
  event?: any,
  callback?: FormlyAttributeEvent,
): FormlyAttributeEvent {
  const condition: boolean =
    field?.formControl?.hasError('required') && !field.formControl.value;
  if (condition) {
    field.formControl.markAsUntouched();
  }
  if (callback) {
    callback(field, event);
  }
  return null;
}
