import { ICallbackLink } from '@/shared/models/link.model';
import { Pipe, PipeTransform } from '@angular/core';
import { LightweightProfileTab } from '@shoobx/types/webapi-v2';
import { TabViewModel } from './sbx-responsive-nav.component';

@Pipe({ name: 'tabsToSideNavListLinks' })
export class TabsToSideNavListLinksPipe implements PipeTransform {
  public transform(value: TabViewModel[]): ICallbackLink[] {
    return value.map((tab: LightweightProfileTab, index: number) => ({
      path: tab.name,
      linkText: tab.title,
      index,
    }));
  }
}
