import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxCheckboxModule } from '@/shared/sbx-checkbox/sbx-checkbox.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxRadioModule } from '@/shared/sbx-radio/sbx-radio.module';
import { UpgradedModule } from '@/shared/upgraded/upgraded.module';
import { SbxProcessIntroComponent } from './sbx-process-intro.component';

@NgModule({
  imports: [
    CommonModule,
    SbxCheckboxModule,
    SbxPopoverModule,
    SbxIconModule,
    SbxRadioModule,
    UpgradedModule,
  ],
  declarations: [SbxProcessIntroComponent],
  exports: [SbxProcessIntroComponent],
})
export class SbxProcessIntroModule {
  static entryComponents = [SbxProcessIntroComponent];

  static forRoot(): ModuleWithProviders<SbxProcessIntroModule> {
    return {
      ngModule: SbxProcessIntroModule,
    };
  }
}
