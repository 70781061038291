import { Component, Input } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

const SELECTOR = 'sbx-process-action';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-process-action.component.html',
  styleUrls: ['./sbx-process-action.component.scss'],
})
export class SbxProcessActionComponent {
  // The URL to use as the href of this link
  @Input() url: string;
  // To be displayed as the primary text of this link
  @Input() title: string;
  // Whether the relevant action is locked or not
  @Input() locked: boolean;
  // Text to display as a tooltip
  @Input() description: string;
  // Text to display as a tooltip if the action is locked
  @Input() lockDescription: string;
}

/**
 * Simple mock of the process action component for testing
 * This should maintiain the same input interface as the real thing
 */
@Component({
  selector: SELECTOR,
  template: '<span>Mocked Process Action</span>',
})
export class MockSbxProcessActionComponent {
  @Input() url: string;
  @Input() title: string;
  @Input() locked: boolean;
  @Input() description: string;
  @Input() lockDescription: string;
}
