<div class="sbx-all-employees" *ngIf="multiSelect">
  <div class="sbx-checkbox-group">
    <sbx-checkbox
      [model]="!root.getSelectedNodes().length"
      [readOnly]="!root.parent && root.leaves.length === 0"
      (change)="selectAllEmployees($event)"
    ></sbx-checkbox>
    <label (click)="selectAllEmployees(!!root.getSelectedNodes().length)"
      >All Employees</label
    >
  </div>

  <i *ngIf="!root.parent && root.leaves.length === 0">
    Did you know that you can create employee groups with customized HR settings?
  </i>
</div>

<div
  class="sbx-tree-select"
  [ngClass]="{ disabled: !root.getSelectedNodes().length && multiSelect }"
  *ngIf="root.parent || root.leaves.length !== 0"
>
  <div *ngIf="includeRoot && root.selectable">
    <sbx-tree-node-select
      [root]="root"
      [renderChildren]="false"
      [multiSelect]="multiSelect"
      (change)="emitChange($event)"
    >
    </sbx-tree-node-select>
  </div>

  <div *ngFor="let node of root.getNodes()" class="sbx-tree-select-children">
    <sbx-tree-node-select
      *ngIf="node.selectable && node !== root"
      [id]="node.data['id']"
      [root]="node"
      [renderChildren]="false"
      [rootChain]="multiSelect ? null : node.getAncestors()"
      [multiSelect]="multiSelect"
      (change)="emitChange($event)"
    >
    </sbx-tree-node-select>
  </div>
</div>
