import angular from 'angular';
import Navigation from 'lib/navigation';
import Bootstrap from '../lib/bootstrap/bootstrap';
import Promise from 'lib/promise';
import Messages from '../lib/messages/messages';

import {
  sbDocumentDetailsSummary,
  sbDocumentStatusType,
  sbDataroomListing,
} from './listing';
import { sbTrashListing, TrashModel, $trashModal } from './trash';
import { sbTransformDocumentHtmlBody } from './transform-document';

export default angular
  .module('sb.dataroom', [Bootstrap, Messages, Navigation, Promise])

  .component('sbDocumentStatusType', sbDocumentStatusType)
  .component('sbDocumentDetailsSummary', sbDocumentDetailsSummary)
  .component('sbDataroomListing', sbDataroomListing)
  .component('sbTrashListing', sbTrashListing)
  .directive('sbTransformDocumentHtmlBody', sbTransformDocumentHtmlBody)
  .factory('TrashModel', TrashModel)
  .factory('$trashModal', $trashModal).name;
