import angular from 'angular';

/**
 * @ngdoc service
 * @name sb.lib.url.URLAvailability
 *
 * @description
 * Use this service to lookup url availability and whois information
 */
export const URLAvailability = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  function (SimpleHTTPWrapper, BackendLocation) {
    const checkUrl = BackendLocation.root(1) + 'whois/urlcheck';
    return {
      /**
       * @ngdoc method
       * @name urlformat
       * @methodOf sb.lib.url.URLAvailability
       *
       * @description
       * Format a URL into a "safe" format.
       *
       * @param {string} url URL to format.
       *
       * @returns {string} Safe format of URL.
       */
      urlformat: function (url) {
        return url.replace(/[^A-Za-z0-9-]+/g, '').toLowerCase();
      },

      /**
       * @ngdoc method
       * @name urlcheck
       * @methodOf sb.lib.url.URLAvailability
       *
       * @description
       * Check the whois information about a url.
       *
       * @param {object} data Data to send to the server.
       *
       * @returns {promise} Promise which resolves with positive response and
       *    rejects with negative response.
       */
      urlcheck: function (data) {
        return SimpleHTTPWrapper({ method: 'POST', url: checkUrl, data });
      },
    };
  },
]; // end URLAvailability

/**
 * @ngdoc service
 * @name sb.lib.url.BackendLocation
 *
 * @description
 * `BackendLocation` yields URL information about backend services.
 */
export const BackendLocation = [
  'AppConfig',
  function (AppConfig) {
    function getUrlsForVersion(version) {
      const info = AppConfig.endpointInfos[version];
      if (angular.isUndefined(info)) {
        throw new Error('Unknown backend version: ' + version);
      }
      return info;
    }

    const obj = {};
    let contextUrlOverridden = false;

    /**
     * @ngdoc method
     * @name sb.lib.url.BackendLocation#entity
     * @methodOf sb.lib.url.BackendLocation
     *
     * @param {string} version Version of the api to return.
     *
     * @returns {string} Return entity API endpoint url.
     */
    obj.entity = function (version) {
      return getUrlsForVersion(version).entity;
    };

    /**
     * @ngdoc method
     * @name sb.lib.url.BackendLocation#root
     * @methodOf sb.lib.url.BackendLocation
     *
     * @param {string} version Version of the api to return.
     *
     * @returns {string} Returns root API endpoint url.
     */
    obj.root = function (version) {
      return getUrlsForVersion(version).root;
    };

    /**
     * @ngdoc method
     * @name sb.lib.url.BackendLocation#context
     * @methodOf sb.lib.url.BackendLocation
     *
     * @param {string} version Version of the api to return.
     *
     * @returns {string} Return context API endpoint url.
     */
    obj.context = function (version) {
      if (contextUrlOverridden) {
        return getUrlsForVersion(version).context;
      }

      const urlParts = location.pathname
        .split('/')
        .filter((p) => p)
        .filter((p) => !p.startsWith('onbehalf-'));

      // Ensure that the HTML file is not considered part of the context.
      if (urlParts.length > 0 && urlParts[urlParts.length - 1].endsWith('.html')) {
        urlParts.pop();
      }

      const onbehalfMatch = location.pathname.match(/onbehalf-(\w*)?/);
      let rootUrl = getUrlsForVersion(version).root;
      if (onbehalfMatch) {
        rootUrl = rootUrl.replace(/onbehalf-(\w*)?/, onbehalfMatch[0]);
      }

      const processIndex = urlParts.findIndex((p) =>
        ['process', 'activation-process', 'bare-process', 'workitems'].includes(p),
      );
      if (processIndex !== -1) {
        urlParts.splice(processIndex, 1, 'workitems');
        return `${rootUrl}${urlParts.join('/')}/`;
      }

      const relationshipsIndex = urlParts.indexOf('relationships');
      if (relationshipsIndex !== -1) {
        urlParts.splice(4, 1);
        return `${rootUrl}${urlParts.join('/')}/`;
      }

      const stakeholdersIndex = urlParts.indexOf('stakeholders');
      if (stakeholdersIndex !== -1) {
        urlParts.splice(3, 1);
        return `${rootUrl}${urlParts.join('/')}/`;
      }

      const documentsIndex = urlParts.indexOf('documents');
      if (documentsIndex !== -1) {
        return `${rootUrl}${urlParts.join('/')}/`;
      }
      return getUrlsForVersion(version).entity;
    };

    /**
     * Return stakeholder for current user in this entity
     */
    obj.onBehalf = function (version) {
      return AppConfig.endpointInfos[version].onbehalf;
    };

    /**
     * @ngdoc method
     * @name sb.lib.url.BackendLocation#updateEndpoint
     * @methodOf sb.lib.url.BackendLocation
     *
     * @description
     * Update api url for given version and type
     *
     * This is particularily useful when context url is not really an object
     * we are dealing with (for example in workitem views our context is
     * entity, but we are dealing with a particular workitem there.
     *
     * @param {string} version Version of the api to update.
     * @param {string} type Type of the api to update (eg, root).
     * @param {string} url New url location.
     *
     * @returns {string} Return context API endpoint url.
     */
    obj.updateEndpoint = function (version, type, url) {
      if (type === 'context') {
        contextUrlOverridden = true;
      }

      AppConfig.endpointInfos[version][type] = url;
    };

    return obj;
  },
]; // end BackendLocation
