import { Map, fromJS } from 'immutable';

/**
 * @ngdoc directive
 * @name sb.workitem.editBoard.directive:sbBoardCircle
 * @restrict EA
 *
 * @description
 * Displays Board Seats in a fancy circle with Edit, Add, and Delete options
 *
 * @element ANY
 * @param {List} sbBoardCircleData Board data in the form of an array
 *    of objects that have at least the properties fullName, rowID, and
 *    optionally a pictureUrl.
 * @param {template} [sbBoardCircleEntityLogo='fa-users'] Url to entity logo.
 * @param {boolean} [sbBoardCircleDisable=false] Boolean to disable buttons.
 * @param {expression} [sbBoardCircleOnAdd=undefined] Expression to evaluate
 *    on add.
 * @param {expression} [sbBoardCircleOnEdit=undefined] Expression to evaluate
 *    on edit. Clicked board member data is available as `$member`. If none
 *    provided, popover will be displayed.
 * @param {expression} [sbBoardCircleOnRemove=undefined] Expression to evaluate
 *    on remove. Clicked board member data is available as `$member`.
 * @param {expression} [sbBoardCircleConvert=undefined] Expression is evaluated
 *    on init and will provide a `$fromJs` function to hydrate a litteral to the
 *    required immutable DS.
 *
 * @example
   <div
     data-sb-board-circle
     data-sb-board-circle-data="[{fullName: 'John Smith', rowID: 0}]"
     data-sb-board-circle-disable="loading"
     data-sb-board-circle-on-add="addBoardSeat()"
     data-sb-board-circle-on-edit="editBoardSeat($member)"
     data-sb-board-circle-on-remove="removeBoardSeat($member)"
     ></div>
 */
export function sbBoardCircle() {
  return {
    restrict: 'EA',
    template: require('./templates/board-circle.html'),
    scope: {
      data: '<sbBoardCircleData',
      onAdd: '&sbBoardCircleOnAdd',
      onEdit: '&sbBoardCircleOnEdit',
      onRemove: '&sbBoardCircleOnRemove',
      disable: '<?sbBoardCircleDisable',
      convert: '&?sbBoardCircleConvert',
      editOnly: '<?sbBoardCircleEditOnly',
    },
    controller: [
      '$scope',
      '$attrs',
      'AppConfig',
      function ($scope, $attrs, AppConfig) {
        if ($scope.convert) {
          $scope.convert({ $fromJS: fromJS });
        }

        $scope.attrs = $attrs;
        $scope.entityInfo = AppConfig.currentEntity;
        $scope.isRemovable = $attrs.sbBoardCircleOnRemove;
        $scope.isEmptyShown = true;

        $scope.toggleEmpty = function () {
          $scope.isEmptyShown = !$scope.isEmptyShown;
        };
        $scope.emptyFilter = function (seat) {
          return $scope.isEmptyShown || seat.get('data').get('seatType') === 'occupied';
        };
        $scope.seatRotation = function (index) {
          const seatLen = $scope.data.filter($scope.emptyFilter).size;
          const degree = Math.round(360 / seatLen) * index;
          const transform =
            'rotate(' +
            degree +
            'deg) ' +
            'translate(0, -160px) ' +
            'rotate(-' +
            degree +
            'deg)';
          return {
            transform: transform,
            '-webkit-transform': transform,
          };
        };
        $scope.internalEdit = function (seat) {
          if ($scope.disable) {
            return;
          }
          $scope.onEdit({ $member: seat });
        };
        $scope.popoverContent = function (seat) {
          const data = seat.get('data');
          const content = [];
          if (data.get('seatType') === 'empty') {
            content.push('<em>Empty Seat</em>');
          } else {
            content.push(`<strong>${data.get('$fullName').get('fmtValue')}</strong>`);
          }
          const resClass = data.get('$reservation_class', Map()).get('fmtValue');
          if (resClass) {
            content.push(resClass);
          }
          return `
          <ul class="list-unstyled" style="margin-bottom: 0px">
            <li>${content.join('</li><li>')}</li>
          </ul>
        `;
        };
      },
    ],
  };
} // end sbBoardCircle
