<div *ngIf="mode === videoMode.video" class="sbx-proportion">
  <div [class]="videoProportion">
    <iframe
      [src]="videoSrc | resourceUrl"
      allowfullscreen=""
      allow="encrypted-media"
      frameborder="0"
    ></iframe>
  </div>
</div>
<div *ngIf="mode === videoMode.modal" class="sbx-video-cover" (click)="open()">
  <img [src]="urlService.assetUrl('img/logo-mobile.svg')" />
  <div class="sbx-cover-play"></div>
  <div class="sbx-cover-title">{{ coverTitle }}</div>
</div>
