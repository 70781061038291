<ng-container *ngIf="root">
  <ng-container *ngFor="let id of filterIds(); last as isLast">
    <ng-template #popoverContent>
      <sbx-tree-node-select
        [root]="idNodeMap.get(id)"
        [renderChildren]="false"
        [rootChain]="idNodeMap.get(id).getAncestors()"
      ></sbx-tree-node-select>
    </ng-template>
    <sbx-popover [sbxPopoverContent]="popoverContent">
      <span>{{ idNodeMap.get(id).name }}</span>
      <span *ngIf="!isLast">,&nbsp;</span>
    </sbx-popover>
  </ng-container>
  <div *ngIf="!filterIds().length">
    <span>All Employees</span>
  </div>
</ng-container>
