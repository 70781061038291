/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.tools:PromiseErrorCatcher
 *
 * @description
 * This is a standard promise rejection handler. Angular complains when we have unhandled promise
 * rejections, but in shoobx code (and 3rd party libraries we depend on), rejections are used for some
 * what non-exceptional cases; the classic case of this is $dismissing a modal will reject the `.result` promise.
 * To handle these promise rejections that are "non-exceptional", we essentially (usually) want a no-op, but if
 * we do `.catch(PromiseErrorCatcher)` then we will ignore exceptional rejections (like bugs in promise handlers).
 *
 * This injectable function will handle Errors/exceptions by passing them along to $exceptionHandler but will
 * ignore other harmless values.
 *
 * @param {any} rejectedValue The value rejected from the promise chain.
 *
 * @example
   const modal = $sbModal.open();
   modal.result.catch(PromiseErrorCatcher);
 */
export const PromiseErrorCatcher = [
  '$exceptionHandler',
  function ($exceptionHandler) {
    return (rejectedValue) => {
      if (rejectedValue instanceof Error) {
        $exceptionHandler(rejectedValue);
      }
    };
  },
]; // end PromiseErrorCatcher
