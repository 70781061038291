import { Component, Input, HostBinding } from '@angular/core';
import { Downgrade } from '../downgrade';

/**
 *  Shoobx Themed Column Container
 */
@Downgrade.Component('ngShoobx', 'sbx-col')
@Component({
  selector: 'sbx-col',
  templateUrl: './sbx-col.component.html',
  styleUrls: ['sbx-col.component.scss'],
})
export class SbxColComponent {
  @Input() lg: number;
  @Input() md: number;
  @Input() sm: number;
  @Input() xs: number;

  @HostBinding('attr.lg')
  get lgCol() {
    return this.lg;
  }

  @HostBinding('attr.md')
  get mdCol() {
    return this.md;
  }

  @HostBinding('attr.sm')
  get smCol() {
    return this.sm;
  }

  @HostBinding('attr.xs')
  get xsCol() {
    return this.xs;
  }
}
