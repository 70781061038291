import {
  WebViewerInstance,
  type Core as WebViewerInstanceCore,
} from '@pdftron/webviewer';

interface ICustomRectangleAnnotation {
  X: number;
  Y: number;
  Width: number;
  Height: number;
  PageNumber: number;
}

export function initializeCustomRectangleAnnotation(instance: WebViewerInstance) {
  const TOOL_NAME = 'AnnotationCreateTriangle';

  const { Core } = instance;

  class CustomRectangleAnnotation extends Core.Annotations.RectangleAnnotation {
    constructor(initializer: ICustomRectangleAnnotation) {
      super(initializer);
    }

    draw(ctx: CanvasRenderingContext2D) {
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#000000';
      ctx.strokeRect(this.X, this.Y, this.Width, this.Height);

      const title = this.getCustomData('title');
      if (title) {
        const paddingX = 8;
        const paddingY = 4;
        const fontSize = 12;
        const font = `${fontSize}px Source Sans Pro`;
        const width = ctx.measureText(title).width;

        ctx.fillStyle = '#000000';
        ctx.fillRect(this.X, this.Y, width + paddingX, fontSize + paddingY);

        ctx.font = font;
        ctx.textBaseline = 'top';
        ctx.fillStyle = '#ffffff';
        ctx.fillText(title, this.X + paddingX / 2, this.Y + paddingY / 2);
      }
    }
  }

  CustomRectangleAnnotation.prototype.elementName = 'customRectangle';

  Core.annotationManager.registerAnnotationType(
    CustomRectangleAnnotation.prototype.elementName,
    CustomRectangleAnnotation,
  );

  instance.UI.registerTool({
    toolName: TOOL_NAME,
    toolObject: new Core.Tools.GenericAnnotationCreateTool(
      Core.documentViewer,
      CustomRectangleAnnotation as unknown as WebViewerInstanceCore.Annotations.Annotation,
    ),
    buttonImage: '',
  });

  const create = (initializer: ICustomRectangleAnnotation) => {
    return new CustomRectangleAnnotation(initializer);
  };

  return {
    toolName: TOOL_NAME,
    create,
  };
}
