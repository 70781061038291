<label
  [id]="name"
  [attr.name]="name"
  [class]="'sbx-radio-group ' + labelClass"
  (click)="disabled ? null : handleClick($event)"
  (focus)="disabled ? null : handleFocus($event)"
  tabindex="-1"
  [sbxDisabled]="disabled"
>
  <input
    type="radio"
    [checked]="model === value"
    [name]="name"
    [value]="value"
    tabindex="-1"
    [sbxDisabled]="disabled"
  />
  <span
    class="sbx-radio"
    tabindex="0"
    (keydown)="disabled ? null : handleKeydown($event)"
    [sbxDisabled]="disabled"
  ></span>
  <span [class]="disabled ? labelClass + ' sbx-text-muted' : labelClass">
    <ng-container *ngIf="labelTemplate">
      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="label">
      <span>{{ label }}</span>
    </ng-container>
  </span>
</label>
