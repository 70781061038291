/**
 * @ngdoc directive
 * @name sb.lib.bootstrap.directive:sbCompiledContent
 * @restrict E
 *
 * @description
 * Fill content of element with compiled html string.
 *
 * @param $compile
 */
export const sbCompiledContent = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'E',
      terminal: true,
      link: {
        pre(scope, element, attrs) {
          scope.$watch(attrs.sbCompiledContent, (nv) => {
            if (nv) {
              if (scope.vm) {
                scope = Object.assign(scope, scope.vm.extraContext);
              }
              // Here we are certain to attach the contents to the DOM before
              // $compile so that any parent controllers are correctly found.
              element.html(`<div>${nv}</div>`);
              $compile(element.contents())(scope);
            } else {
              element.html('');
            }
          });
        },
      },
    };
  },
]; // end sbCompiledContent

export const sbCompiledContentComponent = {
  template: `<sb-compiled-content sb-compiled-content="vm.content.res" extra-context="vm.extraContext">
  </sb-compiled-content>`,
  controllerAs: 'vm',
  bindings: {
    content: '<',
    extraContext: '<?',
  },
};
