import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'resourceUrl' })
export class ResourceUrlPipe implements PipeTransform {
  public constructor(@Inject(DomSanitizer) private sanitized: DomSanitizer) {}

  public transform(value: string): string {
    return this.sanitized.bypassSecurityTrustResourceUrl(value) as string;
  }
}
