import { Input, Component, Inject, OnInit } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { Observable, tap, map } from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import { HttpParams } from '@angular/common/http';
import { SbxProcessButtonModel } from '@/shared/upgraded/process-button-model.service';

/**
 *  Shoobx Upload Icon with custom text
 *
 *
 *  @ngdoc component
 *  @name sbx-upload-icon/component:SbxUploadIconComponent
 *  @description
 *  component for displaying upload icon with custom text
 *
 *  @param text - string text to place next to icon
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-upload-icon
 *         [text]="Upload Company Logo">
 *        </sbx-upload-icon>
 *      `,
 *    })
 */
const SELECTOR = 'sbx-process-blocked-list';

interface IProcess {
  id: string;
  entId: string;
  procId: string;
  title: string;
  isFinished: boolean;
}

interface IStakeholder {
  id: string;
  title: string;
}

interface IProcessItem {
  process: IProcess;
  owner: IStakeholder;
  canViewStatus: boolean;
  driver: IStakeholder;
  participants: Array<IStakeholder>;
}

interface IProcessList {
  data: Array<IProcessItem>;
}

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-process-blocked-list.component.html',
  styleUrls: ['./sbx-process-blocked-list.component.scss'],
})
export class SbxProcessBlockedListComponent implements OnInit {
  @Input() processids: string[];
  @Input() showMessages = true;
  @Input() additionalinfo = [];

  constructor(
    @Inject(SbxHttpClient) private http: SbxHttpClient,
    @Inject(SbxProcessButtonModel) private procButtonModel,
  ) {}

  data$: Observable<IProcessItem[]>;

  ngOnInit() {
    if (this.processids && typeof this.processids === 'string') {
      this.processids = JSON.parse(this.processids);
    }
    if (this.additionalinfo && typeof this.additionalinfo === 'string') {
      this.additionalinfo = JSON.parse(this.additionalinfo);
    }
    const params = new HttpParams().set('processIds', this.processids.join());

    this.data$ = this.http
      .entity('2')
      .get<IProcessList>('processes/processesById', { params: params })
      .pipe(
        map((response: IProcessList) => response.data),
        tap((val: IProcessItem[]) => this.reEnableContinue(val)),
      );
    this.procButtonModel.disable('continue');
  }

  allFinished(procList: IProcessItem[]): boolean {
    const isFinishedReduce = (accum, procRow) => accum && procRow.process.isFinished;
    return procList.reduce(isFinishedReduce, true);
  }

  reEnableContinue(procList: IProcessItem[]): void {
    if (this.allFinished(procList)) {
      this.procButtonModel.requestEnable('continue');
    }
  }
}
