import { Injectable } from '@angular/core';
import { SbxExitScenarioPlannerService } from '../sbx-exit-scenario-planner/sbx-exit-scenario-planner.service';
import { EmployeeOption, NextRoundStateResponse } from '@shoobx/types/plannerapi-v1';
import { GenericDeleteResponse } from '@shoobx/types/pieapi-v1';
import { map } from 'rxjs';

interface IEmployeeOption extends EmployeeOption {
  title: string;
  color: string;
}

export interface INextRoundStateResponse extends NextRoundStateResponse {
  employeeOptions?: IEmployeeOption[];
}

export function getEmployeeOptionColor(name: string) {
  switch (name) {
    case 'Utilized Pool': {
      return '#1A5276';
    }
    case 'Current Available Pool': {
      return '#5f7787';
    }
    case 'Additional Shares (Post-Money)': {
      return '#AED6F1';
    }
    default: {
      return null;
    }
  }
}

@Injectable()
export class SbxNextRoundPlannerService extends SbxExitScenarioPlannerService {
  getState() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<NextRoundStateResponse>(`${this.BASE_URL}/getNextRoundState`)
      .pipe(
        map((data) => {
          data.employeeOptions = data.employeeOptions.map((o: IEmployeeOption) => {
            o.title = o.name;
            o.color = getEmployeeOptionColor(o.name);
            return o;
          });

          return data as INextRoundStateResponse;
        }),
      );
  }

  addStakeholderFormUrl() {
    return `${this.BASE_URL}/addDilutionImpactStakeholder`;
  }

  removeStakeholder(stakeholderId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(
        `${this.BASE_URL}/dilutionImpactStakeholder/${stakeholderId}`,
      )
      .toPromise();
  }

  changeSipSettingsFormUrl() {
    return `${this.BASE_URL}/sipSettings`;
  }
}
