import { Downgrade } from '@/shared/downgrade';
import { SbxDocumentUploadModel } from '@/shared/sbx-document-reference/sbx-document-upload.component';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { ISbxActionsMenu } from '../sbx-action-menu/sbx-action-menu.component';

const SELECTOR = 'sbx-upload-missing-document';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-upload-missing-document.component.html',
  styleUrls: ['./sbx-upload-missing-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxUploadMissingDocumentComponent implements OnDestroy {
  @Input() public saveUrl!: string;
  @Input() public apiResource!: string;
  @Input() public urlVersion = '2';
  @Input() public type: 'icon' | 'button' = 'button';
  @Input() public title = 'UPLOAD MISSING DOCUMENT';
  @Input() public header = 'UPLOAD MISSING DOCUMENT';
  @Input() public text;
  @Input() public required = false;

  public readonly actions: ISbxActionsMenu = [
    {
      title: this.title,
      icon: 'upload',
      collapsed: false,
      click: () => this.openModal(),
    },
  ];

  private readonly unsubscribe$ = new Subject();

  public constructor(private readonly sbxFormModalService: SbxFormModalService) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public openModal(): void {
    const uploadModel: SbxDocumentUploadModel = new SbxDocumentUploadModel();

    this.sbxFormModalService
      .open({
        data: {
          title: this.header,
          topMessageText: this.text,
          formFields: [
            {
              key: 'uploadId',
              type: 'pdf-file',
              templateOptions: <FormlyTemplateOptions>{
                apiResource: this.apiResource,
                file: uploadModel.file,
                enablePreview: true,
                label: 'Document',
                persistUpload: false,
                required: this.required,
              },
            },
          ],
          saveUrl: this.saveUrl,
          urlVersion: this.urlVersion,
        },
        windowClass: 'sbx-document-upload',
      })
      .closed.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.urlVersion === '1') {
          window.location.reload();
        }
      });
  }
}
