<div class="sbx-container">
  <span class="sbx-icon-container">
    <sbx-icon type="error"></sbx-icon>
  </span>
  <span class="sbx-main-content">
    <div>We found the following similar profiles. Did you mean?</div>
    <div class="sbx-similar-options">
      <sbx-radio
        *ngFor="let entity of similarEntities"
        name="similarEntity"
        [value]="makeKey(entity)"
        [label]="entity.title"
        [model]="selectedEntityKey"
        (modelChange)="setSelectedEntity(entity)"
      ></sbx-radio>
      <sbx-radio
        name="similarEntity"
        [value]="CREATE_NEW_KEY"
        [model]="selectedEntityKey"
        (modelChange)="setSelectedEntity(null)"
        [labelTemplate]="similarEntityTpl"
      ></sbx-radio>
      <ng-template #similarEntityTpl>
        None of the above; I want to create a new profile for <b>{{ entityTitle }}</b>
      </ng-template>
    </div>

    <div class="sbx-affiliate-option" *ngIf="hasAffiliateOption()">
      <div>
        {{ selectedEntity.title }} already has the following funds similar to the one
        you specified. Did you mean?
      </div>
      <div class="sbx-similar-options">
        <sbx-radio
          *ngFor="let affiliate of similarAffiliates"
          name="similarAffiliate"
          [value]="makeKey(affiliate)"
          [label]="affiliate.title"
          [model]="selectedAffiliateKey"
          (modelChange)="setSelectedAffiliate(affiliate)"
        ></sbx-radio>
        <sbx-radio
          *ngIf="affiliateTitle"
          name="similarAffiliate"
          [value]="CREATE_NEW_KEY"
          [model]="selectedAffiliateKey"
          (modelChange)="setSelectedAffiliate(null)"
          [labelTemplate]="similarAffiliateCreateTpl"
        ></sbx-radio>
        <sbx-radio
          name="similarAffiliate"
          [value]="DONT_USE_AFFILIATE_KEY"
          label="None of the above; I don't want to use a fund."
          [model]="selectedAffiliateKey"
          (modelChange)="setDontUseAffiliate()"
        ></sbx-radio>
      </div>
      <ng-template #similarAffiliateCreateTpl>
        I want to create a new fund named <b>{{ affiliateTitle }}</b>
      </ng-template>
    </div>
    <sbx-modal-footer>
      <sbx-button
        [disabled]="hasErrors()"
        title="Confirm"
        theme="primary"
        (click)="save()"
      ></sbx-button>
      <sbx-button
        title="Cancel"
        theme="secondary"
        (click)="sbxModalService.dismiss()"
      ></sbx-button>
    </sbx-modal-footer>
  </span>
</div>
