import {
  Component,
  Inject,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxInvestorModalDataModel } from '@/sbx-investment/sbx-investor-modal/sbx-investor-modal-data.model';
import { FormGroup } from '@angular/forms';
import { SbxFormComponent } from 'frontend/app/shared/sbx-form/sbx-form.component';
import { ISbxTableColumns } from 'frontend/app/shared/sbx-table/sbx-table.component';
import { SbxExitScenarioPlannerService } from '../sbx-exit-scenario-planner/sbx-exit-scenario-planner.service';
import { StockClass, StockClassAndShares } from '@shoobx/types/plannerapi-v1';

interface IStockClassAndShares extends StockClassAndShares {
  title?: string;
}

@Component({
  selector: 'sbx-investor-modal',
  templateUrl: './sbx-investor-modal.component.html',
  styleUrls: ['./sbx-investor-modal.component.scss'],
})
export class SbxInvestorModalComponent implements SbxInvestorModalDataModel, OnInit {
  @ViewChild('container', { read: ViewContainerRef }) containerRef;
  @ViewChild('formRef') formRef: ComponentRef<SbxFormComponent>;
  @ViewChild('boldTpl', { static: true }) boldTpl: TemplateRef<any>;

  investorId: string = null;
  stockClasses: StockClass[] = null;
  apiVersion: string = null;
  baseUrl: string = null;

  currentHoldingsColumns: ISbxTableColumns = [];
  currentHoldingsData = [];
  currentInvestmentAmountStr = null;
  preMoneyOwnership = null;

  form = new FormGroup({});
  formErrors = {};
  newInvestmentForm = null;

  constructor(
    @Inject(SbxModalService) public sbxModalService: SbxModalService,
    @Inject(SbxExitScenarioPlannerService)
    public sbxExitScenarioPlannerService: SbxExitScenarioPlannerService,
  ) {}

  async ngOnInit() {
    this.sbxExitScenarioPlannerService.initialize(this.apiVersion, this.baseUrl);

    const {
      currentInvestmentAmountStr,
      currentHoldings,
      preMoneyOwnership,
      newInvestmentForm,
    } = await this.sbxExitScenarioPlannerService
      .getInvestor(this.investorId)
      .toPromise();
    this.currentHoldingsData =
      this.mapStockClassTitleToCurrentHoldings(currentHoldings);
    this.currentInvestmentAmountStr = currentInvestmentAmountStr;
    this.preMoneyOwnership = preMoneyOwnership;
    this.newInvestmentForm = newInvestmentForm;
    this.setCurrentHoldingsColumns();
  }

  mapStockClassTitleToCurrentHoldings(currentHoldings: StockClassAndShares[]) {
    return currentHoldings.map((h) => {
      const data: IStockClassAndShares = h;
      data.title = this.stockClasses.find(({ name }) => name === h.classId).title;

      return data;
    });
  }

  setCurrentHoldingsColumns() {
    this.currentHoldingsColumns = [
      {
        key: 'title',
        title: 'Stock class',
      },
      {
        key: 'sharesStr',
        title: 'Shares',
        template: this.boldTpl,
      },
    ];
  }

  async save() {
    try {
      await this.sbxExitScenarioPlannerService
        .saveInvestor(this.investorId, this.newInvestmentForm.model)
        .toPromise();
      this.sbxModalService.close();
    } catch (e) {
      this.formErrors = e.error.message;
    }
  }
}
