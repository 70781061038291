<ng-template #searchRow let-item>
  {{ item.title }}
</ng-template>

<ng-container *ngIf="!formControl.value?.title">
  <sbx-search-bar
    [searchResults]="getTicker"
    [rowTemplate]="searchRow"
    [disableAutoComplete]="true"
    (select)="selectTicker($event)"
    (focus)="to.focus ? to.focus(field, $event) : null"
    (blur)="field ? formControl.markAsTouched() : null"
  >
  </sbx-search-bar>
</ng-container>
<ng-container *ngIf="formControl.value?.title">
  <div class="sbx-search-result">
    <ng-container
      *ngTemplateOutlet="searchRow; context: { $implicit: formControl.value }"
    ></ng-container>
    <sbx-icon type="close" (click)="removeTicker()"></sbx-icon>
  </div>
</ng-container>
