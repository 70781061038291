import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../config/app.config';
import { SbxHttpClient } from '../http/client.service';

@Injectable({
  providedIn: 'root',
})
export class AppcuesService {
  constructor(
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
  ) {}

  identify() {
    const userId = this.appConfig.userProfile?.id;

    if (!userId) {
      return;
    }

    return this.sbxHttpClient
      .entity('2')
      .get('appcues_info')
      .toPromise()
      .then((data) => {
        // eslint-disable-next-line dot-notation
        data['lens'] = this.appConfig.currentLens.name;
        // eslint-disable-next-line no-extra-parens
        (window as any).Appcues?.identify(userId, data);
      });
  }

  page() {
    // eslint-disable-next-line no-extra-parens
    (window as any).Appcues?.page();
  }

  reset() {
    // eslint-disable-next-line no-extra-parens
    (window as any).Appcues?.reset();
  }
}
