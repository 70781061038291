interface ModelChangeKeys {
  changeKey: string;
  changeKeys: string[];
}

const empty: ModelChangeKeys = { changeKey: null, changeKeys: [] };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getModelChangeKeys(
  prevModel: any,
  nextModel: any,
  path: string = '',
): ModelChangeKeys {
  prevModel = prevModel === undefined ? null : prevModel;
  nextModel = nextModel === undefined ? null : nextModel;
  const changeKey: string = path ? path : '$full';

  if (typeof prevModel !== typeof nextModel) {
    return { changeKey, changeKeys: [changeKey] };
  } else if (prevModel === null && nextModel === null) {
    return empty;
  }

  if (typeof prevModel === 'object') {
    return getObjectChangeKeys(prevModel, nextModel, path);
  } else if (prevModel !== nextModel) {
    return { changeKey, changeKeys: [changeKey] };
  }

  return empty;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getObjectChangeKeys(
  prevModel: any,
  nextModel: any,
  path: string = '',
): ModelChangeKeys {
  prevModel = prevModel === null ? {} : prevModel;
  nextModel = nextModel === null ? {} : nextModel;

  const change: ModelChangeKeys = { changeKey: null, changeKeys: [] };
  const prevModelKeys: string[] = Object.keys(prevModel);
  const nextModeKeys: string[] = Object.keys(nextModel);
  const keys: string[] = [...new Set([...nextModeKeys, ...prevModelKeys])];

  for (const key of keys) {
    const newPath: string = path ? `${path}.${key}` : key;
    const deepChange: ModelChangeKeys = getModelChangeKeys(
      prevModel[key],
      nextModel[key],
      newPath,
    );
    if (deepChange.changeKeys.length) {
      change.changeKeys.push(...deepChange.changeKeys);
      if (!change.changeKey) {
        change.changeKey = deepChange.changeKey;
      }
    }
  }

  if (change.changeKeys.length && path) {
    change.changeKeys.unshift(path);
  }

  return change;
}
