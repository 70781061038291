import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-profile',
  templateUrl: './sbx-profile.component.html',
  styleUrls: ['./sbx-profile.component.scss'],
})
export class SbxProfileComponent extends FieldType<FieldTypeConfig> {
  handleChange(event) {
    this.formControl.setValue(event.selectedNodes[0].data.id);
  }
}
