import {} from '../sbx-action-menu/sbx-action-menu.component';

/**
 * Represents a node in the tree.
 *
 * @param name: The name displayed for the tree node.
 * @param data: Arbitrary data associated with the tree node.
 * @param selected: Indicates whether the item is selected.
 * @param leaves: Child nodes for which this node is parent.s
 */
export class SbxTreeNode<T = any> {
  name: string;
  data: T;
  selected = false;
  expanded = true;
  selectable = true;
  removable = true;
  leaves: SbxTreeNode<T>[] = [];
  parent: SbxTreeNode<T> = null;

  constructor(name = '<root>', data: T = null) {
    this.name = name;
    this.data = data;
  }

  /**
   * Get a list of nodes below this node that are selected.
   */
  public getSelectedNodes(): SbxTreeNode[] {
    return this.getNodes().filter((n) => n.selected);
  }

  /**
   * Remove this node from its parent.
   */
  public remove() {
    if (this.parent) {
      const idx = this.parent.leaves.indexOf(this);
      this.parent.leaves.splice(idx, 1);
      this.parent = null;
    }
  }

  /**
   * Add the given node as a direct leaf node of this node.
   */
  public add(n: SbxTreeNode) {
    this.leaves.push(n);
    n.parent = this;
    return this;
  }

  public getAncestors(): SbxTreeNode<T>[] {
    const chain = [];
    let ancestor = this.parent;
    while (ancestor) {
      chain.unshift(ancestor);
      ancestor = ancestor.parent;
    }

    return chain;
  }

  public getAncestralTree(): SbxTreeNode<T> {
    const ancestors = this.getAncestors().concat([this]);
    let root = ancestors.pop().clone();
    while (ancestors.length > 0) {
      const newRoot = ancestors.pop().clone();
      newRoot.add(root);
      root = newRoot;
    }

    return root;
  }

  public getNodes(): SbxTreeNode<T>[] {
    const nodes = [];
    nodes.push(this);

    for (const leaf of this.leaves) {
      nodes.push(...leaf.getNodes());
    }

    return nodes;
  }

  /**
   * Clone this node without its children or parent.
   */
  public clone() {
    return new SbxTreeNode<T>(this.name, this.data);
  }
}

/**
 * Event indicating that the selection has been updated in a tree node.
 */
export class SbxTreeSelectEvent<T = any> {
  selectedNodes: SbxTreeNode[] = [];

  constructor(selectedNodes: SbxTreeNode<T>[] = []) {
    this.selectedNodes = selectedNodes;
  }
}

/**
 * Helper function for creating a new tree.
 */
export function tree<T = any>(): SbxTreeNode {
  return new SbxTreeNode<T>();
}

/**
 * Helper function for creating a new tree node.
 */
export function node<T = any>(name: string, data: T = null) {
  return new SbxTreeNode<T>(name, data);
}
