import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxDocumentReferenceComponent } from './sbx-document-reference.component';
import { SbxDropdownModule } from '../sbx-dropdown/sbx-dropdown.module';
import { SbxDocumentPickFromComponent } from './sbx-document-pick-from.component';
import { UpgradedModule } from '../upgraded/upgraded.module';
import { SbxDocumentUploadComponent } from './sbx-document-upload.component';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxDocumentWaiveComponent } from './sbx-document-waive.component';
import { SbxDocumentDeferComponent } from './sbx-document-defer.component';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';
import { FormsModule } from '@angular/forms';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxUploadMissingDocumentModule } from '../sbx-upload-missing-document/sbx-upload-missing-document.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SbxButtonModule,
    SbxIconModule,
    SbxDropdownModule,
    UpgradedModule,
    SbxModalModule,
    FormsModule,
    SbxAlertBannerModule,
    SbxGridModule,
    SbxUploadMissingDocumentModule,
  ],
  declarations: [
    SbxDocumentReferenceComponent,
    SbxDocumentPickFromComponent,
    SbxDocumentUploadComponent,
    SbxDocumentDeferComponent,
    SbxDocumentWaiveComponent,
  ],
  exports: [SbxDocumentReferenceComponent],
})
export class SbxDocumentReferenceModule {}
