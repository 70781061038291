<ng-container
  *ngTemplateOutlet="
    listTpl;
    context: { $implicit: stack, viewOnly: !canEdit, type: 'all' }
  "
></ng-container>
<ng-container
  *ngTemplateOutlet="
    listTpl;
    context: { $implicit: common, viewOnly: true, type: 'common' }
  "
></ng-container>

<ng-template #listTpl let-stack let-viewOnly="viewOnly" let-type="type">
  <ul
    *ngIf="stack"
    class="sbx-stack"
    [dndDropzone]="['group', 'class']"
    [dndDisableIf]="viewOnly"
    [dndDisableDropIf]="viewOnly"
    (dndDrop)="onDrop($event, stack, 'group')"
  >
    <div class="sbx-placeholder" dndPlaceholderRef></div>

    <li
      *ngFor="let group of stack; index as i"
      [dndDraggable]="group"
      (dndMoved)="onDragged($event, group, stack)"
      [dndDisableIf]="viewOnly"
      dndEffectAllowed="move"
      [dndType]="'group'"
    >
      <h3 *ngIf="type === 'all'">Priority Group: #{{ i + 1 }}</h3>
      <h3 *ngIf="type === 'common'">Common</h3>

      <ul
        [dndDropzone]="['class']"
        (dndDrop)="onDrop($event, group.classes, 'class')"
        [dndDisableIf]="viewOnly"
        [dndDisableDropIf]="viewOnly"
      >
        <div class="sbx-placeholder" dndPlaceholderRef></div>

        <li
          *ngFor="let klass of group.classes; index as j"
          [dndType]="'class'"
          [dndDraggable]="klass"
          dndEffectAllowed="move"
          (dndMoved)="onDragged($event, klass, group.classes)"
          [dndDisableIf]="viewOnly"
        >
          <div class="sbx-class-main">
            <div class="sbx-color-tag" [ngStyle]="{ background: klass.color }"></div>
            <div class="sbx-class-meta">
              <h3>{{ klass.name }}</h3>
              <span *ngIf="klass.prefLabel"
                >Liquidation Preference: {{ klass.prefLabel }}<br
              /></span>
              <span *ngIf="klass.liquidationCap"
                >Cap: {{ klass.liquidationCap | currency }}<br
              /></span>
              <span *ngIf="klass.liquidationMultiplier"
                >Multiplier: {{ klass.liquidationMultiplier }}</span
              >
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</ng-template>
