<div class="sbx-signature">
  <div class="sbx-signature-canvas">
    <canvas
      #canvasVisible
      class="sbx-canvas-visible"
      [width]="width"
      [height]="height"
    ></canvas>
    <canvas
      #canvas
      class="sbx-canvas-hidden"
      [width]="width"
      [height]="height"
    ></canvas>
  </div>
  <sbx-button class="sbx-button-clear" title="Clear" (click)="clear()"></sbx-button>
  <div class="sbx-signature-error" *ngIf="error">
    <div class="sbx-signature-error-icon"><sbx-icon type="alert"></sbx-icon></div>
    <div class="sbx-signature-error-message">{{ error }}</div>
  </div>
</div>
