<tr #collapsableHeader (click)="collapsed = !collapsed" class="sbx-collapsable-tr">
  <ng-container *ngFor="let col of headerColumnValues; first as isFirst">
    <td>
      <sbx-icon
        *ngIf="isFirst"
        [type]="collapsed ? 'triangleRight' : 'triangleDown'"
      ></sbx-icon>
      {{ col }}
    </td>
  </ng-container>
</tr>

<ng-container *ngIf="!collapsed">
  <ng-content></ng-content>
</ng-container>
