import { SbxHttpClient } from '@/core/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DocumentReferences,
  GenericMessageResponse,
  GenericStatusResponse,
} from '@shoobx/types/webapi-v2';

@Injectable({ providedIn: 'root' })
export class SbxDocumentApiService {
  public constructor(private readonly http: SbxHttpClient) {}

  public getDocumentReferences$(id: string): Observable<DocumentReferences> {
    return this.http.entity('2').get(`documents/${id}/references`);
  }

  public saveReference$(
    sourceId: string,
    referenceId: string,
  ): Observable<GenericMessageResponse> {
    return this.http
      .entity('2')
      .post(`documents/${sourceId}/user-defined-reference/${referenceId}`);
  }

  public removeReference$(
    sourceId: string,
    referenceId: string,
  ): Observable<GenericStatusResponse> {
    return this.http
      .entity('2')
      .delete(`documents/${sourceId}/user-defined-reference/${referenceId}`);
  }
}
