import { Map, List } from 'immutable';

/**
 * @ngdoc object
 * @name sb.workitem.editBoard.EditBoardModel
 * @requires lib/sb.promise.SimpleHTTPWrapper
 * @requires lib/sb.url.BackendLocation
 *
 * @description
 * This service is in charge of model data for the Edit Board workitem.
 */
export const EditBoardModel = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  'TableItemData',
  'TableCellData',
  function (SimpleHTTPWrapper, BackendLocation, TableItemData, TableCellData) {
    return {
      $$reservationClass: function (seat) {
        if (!seat.formData.reservation_class) {
          // eslint-disable-next-line camelcase
          seat.formData.reservation_class = '--NOCLASS--';
        }
        const value = seat.formData.reservation_class;
        return TableCellData(value === '--NOCLASS--' ? undefined : value);
      },

      $$updateData: function (boardSeats, formData, columns, messages, editonly) {
        const occupiedSeats = boardSeats.reduce(
          (total, seat) => total + (seat.formData.seatType === 'occupied' ? 1 : 0),
          0,
        );
        const emptySeats = boardSeats.length - occupiedSeats;
        this.$quorum = occupiedSeats >= emptySeats;
        boardSeats = (boardSeats || []).map((seat) =>
          TableItemData(
            seat.id,
            Map({
              $fullName: TableCellData(seat.fullName),
              $email: TableCellData(seat.email),
              // eslint-disable-next-line camelcase
              $reservation_class: this.$$reservationClass(seat),
              $pictureUrl: seat.pictureUrl,
              $stockClassColor: seat.stockClassColor,
              $initials: seat.initials,
            }).merge(seat.formData),
            true,
            !editonly,
            'Edit Seat',
          ),
        );
        this.$items = List(boardSeats);
        if (messages) {
          this.$topMessage = messages.top;
          this.$middleMessage = messages.middle;
        }
        if (editonly) {
          this.$editOnly = editonly;
        }
        if (formData) {
          this.$forms = formData;
        }
        if (columns) {
          this.$columns = columns.map((col) => ({
            name: col.title,
            key: col.attr,
          }));
        }
      },

      $$postData: function (url, data) {
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url,
            data,
          },
          'Error saving board memeber.',
        )
          .then((data) => this.$$updateData(data.items))
          .finally(() => {
            this.$loading = false;
          });
      },

      $hasEditOrDelete: true,
      $editOnly: false,

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#$loading
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Bool indicating if the model is loading.
       */
      $loading: false,

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#message
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Message to display above the edit board widget.
       */
      $topMessage: undefined,

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#message
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Message to display inbetween edit board widget and board table.
       */
      $middleMessage: undefined,

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#$items
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Immutable list of objects describing the board seats.
       */
      $items: List(),

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#$columns
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Array of objects describing the columns to display
       * in table.
       */
      $columns: [],

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#$forms
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Object of form names to form defitions.
       */
      $forms: {},

      /**
       * @ngdoc property
       * @name sb.workitem.editBoard.EditBoardModel#$quorum
       * @propertyOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Boolean that indicates whether a majority of board seats are occupied.
       */
      $quorum: true,

      /**
       * @ngdoc method
       * @name sb.workitem.editBoard.EditBoardModel#$load
       * @methodOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Load the (full) model fresh.
       *
       * @returns {promise} Resolves on successful persit or rejects with
       *    reason.
       */
      $load: function () {
        const modelObject = this;
        modelObject.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.context(1) + 'table',
          },
          'Error loading board members.',
        )
          .then((data) => {
            const { items, forms, columns, messages, editonly } = data;
            modelObject.$$updateData(items, forms, columns, messages, editonly);
          })
          .finally(() => {
            modelObject.$loading = false;
          });
      },

      /**
       * @ngdoc method
       * @name sb.workitem.editBoard.EditBoardModel#$remove
       * @methodOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Remove a seat from the model.
       *
       * @param {number} seatId ID to remove.
       * @returns {promise} Resolves on successful persit or rejects with
       *    reason.
       */
      $remove: function (seatId) {
        const modelObject = this;
        modelObject.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: BackendLocation.context(1) + 'table/item-delete/',
            data: {
              seatId: seatId,
            },
          },
          'Error loading board members.',
        )
          .then((data) => modelObject.$$updateData(data.items))
          .finally(() => {
            modelObject.$loading = false;
          });
      },

      /**
       * @ngdoc method
       * @name $add
       * @methodOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Add a seat to the model.
       *
       * @param {object} data Data values for seat
       *
       * @returns {promise} Success/failure promise.
       */
      $add: function (data) {
        return this.$$postData(`${BackendLocation.context(1)}table/item-add/`, {
          seatData: data,
        });
      },

      /**
       * @ngdoc method
       * @name $edit
       * @methodOf sb.workitem.editBoard.EditBoardModel
       *
       * @description
       * Edit a seat from the model.
       *
       * @param {string} id String ID of seat
       * @param {object} data Data values for seat
       *
       * @returns {promise} Success/failure promise.
       */
      $edit: function (id, data) {
        return this.$$postData(`${BackendLocation.context(1)}table/items/`, {
          seatData: data,
          seatId: id,
        });
      },
    };
  },
]; // end EditBoardModel
