import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxScrollableListComponent } from './sbx-scrollable-list.component';
import { SbxScrollableModule } from '../sbx-scrollable/sbx-scrollable.module';

@NgModule({
  imports: [CommonModule, SbxScrollableModule],
  declarations: [SbxScrollableListComponent],
  exports: [SbxScrollableListComponent],
})
export class SbxScrollableListModule {}
