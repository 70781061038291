/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.administration.object:FolderManager
 *
 * @description
 * Call this function to produce a folder manager for a flat list of sorted folder
 * levels.
 *
 * @param {array<folderLevel>} levels A sorted list of:
 *   @property {string} id Unique ID of this level.
 *   @property {string|null} parent ID of parent.
 *   @property {boolean} [disabled=false] If level is disabled from toggle/is always open.
 * @param [strings] [openNodes=undefined] Optional list of open to IDs.
 * @param {string|null} [root=null] Optional root node ID.
 *
 * @returns {object} Folder manger, see interface below.
 */
export const FolderManager = [
  function () {
    class FMan {
      constructor(levels, openNodes, root = null) {
        const reduction = levels.reduce(
          (accum, level) => {
            const { parent, id, disabled } = level;
            const indent = parent === root ? 0 : accum.indents[parent] + 1;
            accum.indents[id] = indent;
            accum.hidden[id] = indent !== 0 && !disabled && !accum.open[parent];
            accum.open[id] = disabled;
            accum.children[id] = [];
            accum.parents[id] = parent;
            const parentsChildren = accum.children[parent];
            if (parentsChildren) {
              parentsChildren.push(id);
            }
            return accum;
          },
          {
            parents: {},
            children: {},
            indents: {},
            hidden: {},
            open: {},
          },
        );
        this._root = root;
        this._indents = reduction.indents;
        this._hidden = reduction.hidden;
        this._children = reduction.children;
        this._open = reduction.open;
        this._parents = reduction.parents;

        const openToNode = (nodeId) => {
          const { parents } = reduction;
          let parent = parents[nodeId];
          while (parent && parent !== root) {
            this._toggleHidden(parent, false);
            this._open[parent] = true;
            parent = parents[parent];
          }
        };

        this._parentPaths = Object.freeze(
          levels.reduce((accum, level) => {
            const { id } = level;
            const keys = this.getFolderPath(level)
              .map((parentId) => id + '-' + parentId)
              .reduce((accumulator, key) => {
                accumulator[key] = true;
                return accumulator;
              }, {});
            return Object.assign(accum, keys);
          }, {}),
        );

        if (openNodes && openNodes.length) {
          openNodes.forEach(openToNode);
        }
      }

      _toggleHidden(levelId, closing) {
        (this._children[levelId] || []).forEach((childId) => {
          this._hidden[childId] = closing;
          // If we are closing or we are opening and child is open
          // then we toggle this items children too
          if (closing || (!closing && this._open[childId])) {
            this._toggleHidden(childId, closing);
          }
        });
      }

      /**
       * @ngdoc method
       * @name isOpen
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       *
       * @returns {boolean} Truthy if level is open (can see children).
       */
      isOpen(level) {
        return Boolean(this._open[level.id]);
      }

      /**
       * @ngdoc method
       * @name hasChildren
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       *
       * @returns {boolean} Truthy if level has child levels.
       */
      hasChildren(level) {
        return Boolean(this._children[level.id].length);
      }

      /**
       * @ngdoc method
       * @name getFolderPath
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       *
       * @returns {List} of parents.
       */
      getFolderPath(level) {
        const nodes = [];
        let nodeId = level.id;
        // 'null' should never be an id from a level adding it to path
        while (nodeId && this._root !== nodeId) {
          nodeId = this._parents[nodeId];
          nodes.push(nodeId);
        }
        return nodes;
      }

      /**
       * @ngdoc method
       * @name isParentOf
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       * @param {parentId} parent level id
       *
       * @returns {Boolean} if parent is in child's path.
       */

      isParentOf(level, parentId) {
        return Boolean(this._parentPaths[level.id + '-' + parentId]);
      }
      /**
       * @ngdoc method
       * @name isHidden
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       *
       * @returns {boolean} Truthy if level is hidden (some parent is closed)
       */
      isHidden(level) {
        return Boolean(this._hidden[level.id]);
      }

      /**
       * @ngdoc method
       * @name indentOf
       * @methodOf sb.lib.administration.FolderManager
       *
       * @param {folderLevel} level Object with level id.
       *
       * @returns {number} The indentation level as a number, [0-n)
       */
      indentOf(level) {
        return this._indents[level.id];
      }

      /**
       * @ngdoc method
       * @name toggleLevel
       * @methodOf sb.lib.administration.FolderManager
       *
       * @description
       * This will toggle the open state of a level and hide its children.
       *
       * @param {folderLevel} level Object with level id.
       */
      toggleLevel(level) {
        const { id, disabled } = level;
        if (disabled) {
          return;
        }
        const wasOpen = Boolean(this._open[id]);
        this._open[id] = !wasOpen;
        this._toggleHidden(id, wasOpen);
      }
    }

    return (levels, openNodes, root) => new FMan(levels, openNodes, root);
  },
]; // end FolderManager

/**
 * @ngdoc component
 * @name sb.lib.administration.compoent:sbFolderTree
 *
 * @description
 * This component can add folder management to transcluded content. It adds to the
 * trancluded scope the following functions:
 *   @name $toggleLevel
 *     @param {folderLevel} level Object with level id.
 *     @param {object} [evt=undefined] If passed, will stop propagation. Useful to stop
 *       selections at higher DOM level.
 *   @name $isOpen
 *     @param {folderLevel} level Object with level id.
 *   @name $isHidden
 *     @param {folderLevel} level Object with level id.
 *   @name $indentOf
 *     @param {folderLevel} level Object with level id.
 *   @name $hasChildren
 *     @param {folderLevel} level Object with level id.
 *
 * @param {array<folderLevel>} levels A sorted list of:
 *   @property {string} id Unique ID of this level.
 *   @property {string|null} parent ID of parent.
 * @param {string|null} [root=null] Optional root node ID.
 * @param {string} [openNodes=undefined] If passed, open the path to these ids. Useful
 *   for initilizing tree in certain state.
 *
 * @example
   <sb-folder-tree levels="::vm.levels">
     <!-- Notice that names above can be used. -->
     <sb-your-folder-level
       ng-click="vm.select(level)"
       indent="$indentOf(level)"
       on-toggle="$toggleLevel(level, $event)"
       ng-repeat="level in ::vm.levels track by level.id">
     </sb-your-folder-level>
   </sb-folder-tree>
 */
export const sbFolderTree = {
  transclude: true,
  bindings: {
    levels: '<',
    root: '<?',
    openNodes: '<?',
  },
  controller: [
    '$element',
    '$transclude',
    'FolderManager',
    function ($element, $transclude, FolderManager) {
      function toggle(level, evt) {
        if (evt) {
          evt.stopPropagation();
        }
        this.manager.toggleLevel(level);
      }
      this.$postLink = () => {
        this.manager = FolderManager(this.levels, this.openNodes, this.root || null);
        $transclude((transElement, transScope) => {
          const { manager } = this;
          transScope.$toggleLevel = toggle.bind(this);
          transScope.$isOpen = manager.isOpen.bind(manager);
          transScope.$isHidden = manager.isHidden.bind(manager);
          transScope.$isParentOf = manager.isParentOf.bind(manager);
          transScope.$indentOf = manager.indentOf.bind(manager);
          transScope.$hasChildren = manager.hasChildren.bind(manager);
          $element.append(transElement);
        });
      };
    },
  ],
}; // end sbFolderTree
