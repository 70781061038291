import { fromJS } from 'immutable';

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.dataItems.object:BaseDataItemsModel
 *
 * @description
 * This function returns a data items model base class that is newable or extendable.
 *
 * @returns {class} A data items model object has the following form:
 *    * `$loading` Boolean indicating that the model has outstanding operations.
 *    * `$hasEditOrDelete` Boolean indicating that the model has an item that
 *      can be previewed, edited, or deleted.
 *    * `$items` Array of data objects with `id` and `data`. Will be `undefined`
 *      before initialization.
 *    * `$init()` Returns a promise that resolves with items after initilizing
 *      the model.
 *    * `$add(data)` Returns a promise that resolves with the newly added item.
 *    * `$edit(id, data)` Returns a promise that resolves with the newly updated
 *      item.
 *    * `$remove(id)` Returns a promise that resolves when the row has been
 *      removed.
 */
const BaseDataItemsModel = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    class DataItemsModel {
      constructor() {
        this.$loading = false;
        this.$items = undefined;
        this.$hasEditOrDelete = false;
        this.beforePromise = undefined;
      }

      $$checkForEditOrDelete() {
        this.$hasEditOrDelete = Boolean(
          this.$items.find(
            (item) =>
              item.get('editable') || item.get('deletable') || item.get('previewable'),
          ),
        );
      }

      $forms() {
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.context(1) + 'items/forms',
          },
          'Failed to fetch forms.',
        )
          .then(
            (data) => {
              return data.forms;
            },
            (err) => $q.reject(err),
          )
          .finally(() => {
            this.$loading = false;
          });
      }

      $init() {
        this.beforePromise = this.$forms;
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.context(1) + 'items',
          },
          'Failed to fetch items.',
        )
          .then(
            (data) => {
              this.$items = fromJS(data.items);
              this.$$checkForEditOrDelete();
              return this.$items;
            },
            (err) => $q.reject(err),
          )
          .finally(() => {
            this.$loading = false;
          });
      }

      $add(itemData) {
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: BackendLocation.context(1) + 'items',
            data: { itemData: itemData },
          },
          'Failed to add new item.',
        )
          .then(
            (data) => {
              const newData = fromJS(data);
              this.$items = this.$items.push(newData);
              this.$$checkForEditOrDelete();
              return newData;
            },
            (err) => $q.reject(err),
          )
          .finally(() => {
            this.$loading = false;
          });
      }

      $edit(itemId, itemData) {
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: BackendLocation.context(1) + 'items/' + itemId,
            data: { itemData: itemData },
          },
          'Failed to edit item.',
        )
          .then(
            (data) => {
              const newData = fromJS(data);
              this.$items = this.$items.map((item) => {
                return item.get('id') === itemId ? newData : item;
              });
              this.$$checkForEditOrDelete();
              return newData;
            },
            (err) => $q.reject(err),
          )
          .finally(() => {
            this.$loading = false;
          });
      }

      $remove(itemId) {
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: BackendLocation.context(1) + 'items/' + itemId,
          },
          'Failed to remove item.',
        )
          .then(
            () => {
              this.$items = this.$items.filter((item) => item.get('id') !== itemId);
              this.$$checkForEditOrDelete();
              return this.$items;
            },
            (err) => $q.reject(err),
          )
          .finally(() => {
            this.$loading = false;
          });
      }
    } // end DataItemsModel

    return DataItemsModel;
  },
];

export default BaseDataItemsModel;
