import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Downgrade } from '../downgrade';
import { SettingsProfileService } from './service/settings-profile.service';

@Downgrade.Component('ngShoobx', 'sbx-tree-picker')
@Component({
  selector: 'sbx-tree-picker',
  templateUrl: './sbx-tree-picker.component.html',
  styleUrls: ['./sbx-tree-picker.component.scss'],
})
export class SbxTreePickerComponent implements OnInit {
  root;
  selectedNode;
  @Input() model = {};
  @Input() modelKey = '';
  @Input() settingsType = '';
  @Input() useWhitelist = false;
  @Input() whitelist: string[] = [];
  @Output() change = new EventEmitter();
  @Output() treeSelect = new EventEmitter();

  constructor(
    @Inject(SettingsProfileService) private service: SettingsProfileService,
    private elRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.service.getProfileType(this.settingsType).subscribe((profileType) => {
      this.root = this.service.constructProfileTree(profileType);

      if (this.useWhitelist) {
        this.service.markWhitelistedSelectable(this.root, this.whitelist);
      }
      this.setSelectedNode();
      this.changeDetectorRef.detectChanges();
    });
  }

  handleChange(nodes) {
    this.selectedNode = nodes.selectedNodes.length ? nodes.selectedNodes[0] : null;
    const newModelValue = this.selectedNode ? this.selectedNode.data.id : null;
    this.model[this.modelKey] = newModelValue;

    this.change.emit(this.elRef.nativeElement);
    this.treeSelect.emit(nodes);
  }

  setSelectedNode() {
    const nodes = this.root.getNodes();

    for (const node of nodes) {
      if (node.data.id === this.getId(this.modelKey, this.model)) {
        this.selectedNode = node;
        break;
      }
    }
  }

  getId(key, model) {
    const id = key.split('.').reduce((acc, cur) => acc[cur], model);
    return id;
  }
}
