/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbRecordList
 * @restrict EA
 *
 * @description
 * This directive is for displaying a button/modal for record lists.
 * the method formatRecordListHtml in format.py generates one of these
 *
 * @element ANY
 * @param {string} sbRecordListTitle Title of the field
 * @param {object} sbRecordListHeaders Modal data headers
 * @param {object} sbRecordListData Modal data
 *
 * @example
 * <sb-record-list
 *  sb-record-list-title="title"
 *  sb-record-list-headers="['header']"
 *  sb-record-list-data="[['val1'], ['val2']]"
 */
export const sbRecordList = [
  function () {
    return {
      restrict: 'EA',
      scope: {
        title: '@sbRecordListTitle',
        headers: '=sbRecordListHeaders',
        data: '=sbRecordListData',
      },
      template: require('./templates/widgets/record-list.html'),
      controller: [
        '$scope',
        '$sbModal',
        'PromiseErrorCatcher',
        function ($scope, $sbModal, PromiseErrorCatcher) {
          function openModal() {
            $sbModal
              .open({
                size: 'lg',
                windowClass: 'record-list-modal',
                keyboard: true,
                resolve: {
                  FieldTitle: () => $scope.title,
                  Headers: () => $scope.headers,
                  Data: () => $scope.data,
                },
                template: require('./templates/widgets/record-list-modal.html'),
                controller: 'RecordListModalCtrl',
              })
              .result.catch(PromiseErrorCatcher);
          }
          $scope.openModal = openModal;
        },
      ],
    };
  },
];

/**
 * @ngdoc controller
 * @name sb.lib.tables.controller:RecorListModalCtrl
 *
 * @description
 * Controller for a `sbTable` modal.
 */
export const RecordListModalCtrl = [
  '$scope',
  'FieldTitle',
  'Headers',
  'Data',
  function ($scope, FieldTitle, Headers, Data) {
    $scope.FieldTitle = FieldTitle;
    $scope.Headers = Headers;
    $scope.Data = Data;
  },
];
