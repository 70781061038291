import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
} from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, Subject, isObservable, pairwise } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormArray, FormGroup, NgForm } from '@angular/forms';
import { Downgrade } from '../downgrade';
import { SbxHttpClient } from '@/core/http';
import { getModelChangeKeys } from '@/shared/utils/model-change-path.util';

@Component({
  selector: 'sbx-form',
  templateUrl: './sbx-form.component.html',
  styleUrls: ['sbx-form.component.scss'],
})
@Downgrade.Component('ngShoobx', 'sbx-form')
export class SbxFormComponent implements OnInit, OnChanges {
  @ViewChild('formlyForm') formlyForm: NgForm;
  @Input() formName = '';
  @Input() form = new FormGroup({});
  // XXX: Value as observable is deprecated. Please use FormlyFieldConfig[] type.
  @Input() formFields: Observable<FormlyFieldConfig[]> | FormlyFieldConfig[];
  @Input() serverErrors = {};
  @Input() updateUrl?: string;
  @Input() documentReferenceUploadUrl?: string;
  // Use config to pass additional data to form fields. Curently supports
  // objectlist table columns configuration.
  @Input() config = {};
  @Input() readOnly = false;
  @Output() modelChange = new EventEmitter();
  @Output() submit = new EventEmitter();
  @Input() model: any;
  fields: FormlyFieldConfig[] = [];
  @Input() options: FormlyFormOptions = {};
  modelChanges$ = new Subject();
  originalFields;
  addressFields: { [key: string]: [] } = {};

  constructor(@Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient) {}

  ngOnInit() {
    if (isObservable(this.formFields)) {
      this.formFields.subscribe((fields) => {
        // Temporary solution to converting circular structure to JSON affecting Cap Table Options form
        if (fields.some((field) => field.type === 'address')) {
          this.originalFields = JSON.parse(JSON.stringify(fields));
        }
        this.fields = this.prepareFields(fields);
        this.prepareModel(this.model, this.fields);
      });
    } else {
      if (this.formFields.some((field) => field.type === 'address')) {
        this.originalFields = JSON.parse(JSON.stringify(this.formFields));
      }
      this.fields = this.prepareFields(this.formFields);
      this.prepareModel(this.model, this.fields);
    }

    this.modelChanges$
      .pipe(
        map((model) => JSON.parse(JSON.stringify(model))),
        pairwise(),
      )
      .subscribe(([prevModel, nextModel]) => {
        const { changeKey } = getModelChangeKeys(prevModel, nextModel);

        if (changeKey) {
          this.updateCalculatedFields(changeKey);

          this.modelChange.emit({
            model: nextModel,
            changeKey,
            form: this.form,
          });
        }
      });
    this.modelChanges$.next(this.model);
  }

  async updateCalculatedFields(key: string) {
    if (!this.updateUrl) {
      return;
    }

    const fields = this.fields.map((field) => field.fieldGroup?.[0] || field);
    const field = fields.find((field) => field.key === key);

    if (!field.templateOptions.providesFormContext) {
      return;
    }

    const response = await this.sbxHttpClient
      .entity('2')
      .post<{ data: { [key: string]: any } }>(this.updateUrl, {
        params: {
          name: this.formName,
          data: {
            ...this.model,
            additionalContext: field.templateOptions.additionalContext || {},
            fieldsToReload: [],
          },
        },
      })
      .toPromise();

    Object.entries(response.data).forEach(([key, value]) => {
      const control = this.form.controls[key];
      if (control) {
        control.setValue(value);
      }
    });
  }

  ngOnChanges() {
    // eslint-disable-next-line dot-notation
    this.options['config'] = this.config;
    // eslint-disable-next-line dot-notation
    if (!this.options.formState) {
      this.options.formState = {};
    }
    this.options.formState.serverErrors = this.serverErrors;
  }

  onModelChange(model) {
    this.modelChanges$.next(model);
  }

  onSubmit(event) {
    if (this.submit) {
      this.submit.emit();
    }
    event?.stopPropagation();
  }

  onManualSubmit(event?): void {
    this.form.markAsTouched();
    const form = this.formlyForm as any;
    form.submitted = true;
    if (this.form.valid) {
      this.onSubmit(event);
    }
  }

  private prepareFieldClass(field) {
    const grid = field.templateOptions && field.templateOptions.grid;

    return [
      'sbx-form-col',
      `sbx-col-lg-${grid && grid.lg ? grid.lg : '12'}`,
      grid && grid.md && `sbx-col-md-${grid.md}`,
      grid && grid.md && `sbx-col-sm-${grid.sm}`,
      grid && grid.md && `sbx-col-xs-${grid.xs}`,
      field.className,
    ]
      .filter((c) => c)
      .join(' ');
  }

  private prepareModel(formModel, formFields) {
    // This function is executed after prepareFields, which means all fields
    // will have fieldGroup
    const fields = formFields.map(({ fieldGroup }) => fieldGroup).flat();

    // If model value is undefined, assign it to null to avoid ngModelChanges
    fields.forEach((field) => {
      const keys = field.key.split('.');
      let model = formModel;
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!model[key]) {
          model[key] = {};
        }
        model = model[key];
      }
      const key = keys[keys.length - 1];
      if (model[key] === undefined) {
        const value = field.defaultValue === undefined ? null : field.defaultValue;
        model[key] = value;
      }
    }, {});
  }

  private prepareFields(fields) {
    for (const field of fields) {
      // Wrap fields w/o fieldGroup with fieldGroup

      if (!field.fieldGroup) {
        const fieldGroup = [
          {
            ...field,
          },
        ];

        for (const key in field) {
          // eslint-disable-next-line no-prototype-builtins
          if (field.hasOwnProperty(key)) {
            delete field[key];
          }
        }

        field.fieldGroup = fieldGroup;
        field.hideExpression = fieldGroup[0].hideExpression;
        field.templateOptions = {};
      }

      field.fieldGroupClassName = 'sbx-form-row';
      field.fieldGroup = this.transformFields(field.fieldGroup);
    }

    return fields;
  }

  private transformFields(fields) {
    for (const field of fields) {
      field.className = this.prepareFieldClass(field);

      if (this.readOnly) {
        field.templateOptions.readOnly = true;
      }

      if (field.type === 'dropzone') {
        if (!field.validators) {
          field.validators = {};
        }
        field.validators.validation = ['dropzone'];
      }

      if (field.type === 'list') {
        const { valueType: listValueConfig } = field.templateOptions;
        if (listValueConfig && !field.templateOptions.readOnly) {
          if (listValueConfig.type === 'stakeholder') {
            field.type = 'stakeholder-list';

            // Copy over all applicable template options
            for (const key in listValueConfig.templateOptions) {
              // eslint-disable-next-line no-prototype-builtins
              if (listValueConfig.templateOptions.hasOwnProperty(key)) {
                field.templateOptions[key] = listValueConfig.templateOptions[key];
              }
            }
          } else if (listValueConfig.type === 'profile') {
            field.type = 'profile-list';
          }
        }
      }

      if (field.type === 'email-textline') {
        if (!field.validators) {
          field.validators = {};
        }
        field.validators.validation = ['email'];
      }

      if (field.type === 'stakeholder') {
        if (!field.validators) {
          field.validators = {};
        }
        field.validators.validation = ['selectionRequired'];
      }

      if (field.type === 'document-reference' && field.templateOptions.required) {
        if (!field.validators) {
          field.validators = {};
        }

        field.validators = { validation: ['documentRequired'] };
      }

      /* Adjust our field model to what formly forms really wants. */
      if (field.type === 'dictionary') {
        field.formControl = new FormArray([]);
      }
      if (field.type === 'address') {
        field.hooks = {
          onInit: (field) => {
            field.formControl.valueChanges.subscribe(() => {
              let newFields;
              if (field.templateOptions.fields) {
                this.addressFields[field.key] = field.templateOptions.fields;
                newFields = this.processOriginalFields();

                this.fields = this.prepareFields(newFields);
                this.prepareModel(this.model, newFields);
                delete this.form.controls[Object.keys(this.form.controls)[0]];
                field.templateOptions.fields.forEach((field) => {
                  delete this.model[field.fieldGroup[0].key];
                });
              }
            });
          },
        };
      }
      if (field.type && field.type.indexOf('enum-') === 0) {
        field.templateOptions.options = field.templateOptions.enumVocab;
      }
      if (field.type === 'document-reference' && !field.templateOptions.apiResource) {
        // if the doc reference field does not already contain apiResource, we manually create one.
        if (this.documentReferenceUploadUrl) {
          field.templateOptions = {
            ...field.templateOptions,
            apiResource: `${this.documentReferenceUploadUrl}/${field.key}`,
          };
        }
      }

      /* Now look into children to make sure that everything gets updated. */
      if (field.type === 'dictionary') {
        field.formControl = new FormArray([]);
      }
      if (field.type === 'record') {
        field.formControl = new FormGroup({});
        field.fieldArray = field.templateOptions.valueSchema;
      }
      if (field.type === 'list') {
        this.transformFields([field.templateOptions.valueType]);
      }
      if (field.type === 'dictionary') {
        this.transformFields([field.templateOptions.keyType]);
        this.transformFields([field.templateOptions.valueType]);
      }
      if (field.type === 'record') {
        this.transformFields(field.templateOptions.valueSchema);
      }
    }

    return fields;
  }

  private processOriginalFields() {
    // Only applicable when form consists of an address field
    // Returns an array of form fields when form consists of address fields
    const newFields = [];
    this.originalFields.forEach((originalField) => {
      // Calling this after fields have been initialized and prepared results in fields
      // being wrapped with fieldGroup.
      originalField = originalField.fieldGroup?.[0] || originalField;
      // For address field, add multiple fields as per the schema
      if (originalField.type === 'address') {
        const addressFields = this.addressFields[originalField.key];
        // Only process current address field
        if (addressFields) {
          // Check for adding address label
          if (!originalField.templateOptions.hideAddressLabel) {
            const addressLabelField = {
              key: `${originalField.key}.label`,
              type: 'label',
              templateOptions: {
                title: originalField.templateOptions.label,
                className: 'sbx-address-field-label',
                subfield: originalField.templateOptions.subfield,
              },
              hideExpression: originalField.hideExpression,
              defaultValue: null,
            };

            newFields.push(addressLabelField);
          }

          newFields.push(...addressFields);
        } else {
          // Address field is hidden using hideExpression. We need to keep it to
          // process it when hideExpression condition changes.
          newFields.push(originalField);
        }
      } else {
        // For non address field add as it is
        newFields.push(originalField);
      }
    });
    return newFields;
  }
}
