import { Component, Input, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface IDraggableListItem {
  itemTitle?: string;
}

@Component({
  selector: 'sbx-draggable-list',
  templateUrl: './sbx-draggable-list.component.html',
  styleUrls: ['./sbx-draggable-list.component.scss'],
})
export class SbxDraggableListComponent {
  @Input() items: IDraggableListItem[];
  @Input() itemTemplate: TemplateRef<any>;

  drop(event: CdkDragDrop<IDraggableListItem[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }
}
