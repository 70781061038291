import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-bool-radios',
  templateUrl: './sbx-bool-radios.component.html',
  styleUrls: ['./sbx-bool-radios.component.scss'],
})
export class SbxBoolRadiosComponent extends FieldType<FieldTypeConfig> {
  @Input() layout: 'horizontal' | 'vertical' = 'horizontal';

  items = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  constructor() {
    super();
  }

  handleChange(value) {
    this.formControl.setValue(value);
  }
}
