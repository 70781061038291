import angular from 'angular';
import { Iterable } from 'immutable';

import {
  sbFormatField,
  Formatters,
  stakeholderName,
  stripHTML,
  fromBoolean,
  stripTrailingZeros,
  createAnchors,
} from './format';

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:mutable
 *
 * @description
 * Allows user to convert a immutable (immutable js) object into an object
 * that `ngRepeat` can understand.
 *
 * @example
 <div ng-repeat="item in immutableItems | mutable track by item.get('id')">
 {{ item.get('property') }}
 </div>
 */
const mutable = [
  function () {
    const cache = new Map();
    const { isIterable } = Iterable;
    return (val) => {
      if (!isIterable(val)) {
        return val;
      }
      const cachedVal = cache.get(val);
      if (cachedVal) {
        return cachedVal;
      }
      const mutableVal = val.toArray();
      cache.set(val, mutableVal);
      return mutableVal;
    };
  },
];

/**
 * @ngdoc directive
 * @name sb.lib.tools.directive:sbBindEvents
 * @restrict A
 *
 * @description
 * This is a small directive to add event any number of handlers to an element,
 * custom or otherwise.
 *
 * @param {object} sbBindEvents Expected to be an object where keys are event
 *    names and values are expressions that are later evaluated. The expressions
 *    will be evalutated with `$event` in the namespace. *NOTE:* the keys are
 *    essentially one time bound.
 *
 * @example
 // JS/$scope
 $scope.marker = 'test';
 $scope.parentClick = evt => {
     const { mark } = evt.originalEvent;
     if ( mark ) {
       console.log(`A ${mark} marked element was clicked.`);
     } else {
       console.log('A non-marked element was clicked.');
     }
   };
 $scope.markerClick = (evt, markerStr) => {
     evt.originalEvent.mark = markerStr;
   };

 // HTML
 <parent-element sb-bind-events="{ click: 'parentClick($event)' }">
 <child-element sb-bind-events="{ click: 'markerClick($event, marker)' }">
 </child-element>
 <child-element></child-element>
 </parent-element>
 */
const sbBindEvents = [
  '$parse',
  function ($parse) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const eventHandlers = $parse(attrs.sbBindEvents, null, true)(scope);
        Object.keys(eventHandlers).forEach((eventName) => {
          element.bind(eventName, (evt) => {
            scope.$apply(() => {
              $parse(eventHandlers[eventName])(scope, { $event: evt });
            });
          });
        });
      },
    };
  },
]; // end sbBindEvents

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:truncate
 *
 * @description
 * Truncate/maximum length string function.
 *
 * @param {string} content The content string.
 * @param {number} length The maximum length of the content.
 * @param {string} [after='...'] String to append to end when cot
 *
 * @example
 {{ content | truncate:5:'..' }}
 */
function truncate() {
  return (content, length, after = '...') => {
    return angular.isString(content) && content.length > length
      ? content.substring(0, length) + after
      : content;
  };
} // end truncate

export default angular
  .module('sb.lib.tools', [])

  .directive('sbBindEvents', sbBindEvents)
  .factory('Formatters', Formatters)
  .filter('fromBoolean', fromBoolean)
  .filter('sbFormatField', sbFormatField)
  .filter('stakeholderName', stakeholderName)
  .filter('mutable', mutable)
  .filter('stripHTML', stripHTML)
  .filter('truncate', truncate)
  .filter('stripTrailingZeros', stripTrailingZeros)
  .filter('createAnchors', createAnchors)

  /**
   * @ngdoc object
   * @kind function
   * @name sb.lib.tools.object:$uuid
   *
   * @description
   * Makes a random UUID string. This isn't a real uuid because we removed dashes
   * for HTML friendliness but it should provide sufficient uniqueness.
   * {@link https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript}.
   *
   * @returns {string} Newly created id.
   */
  .factory('$uuid', () => () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  })

  /**
   * @ngdoc filter
   * @name sb.lib.tools.filter:indexToChar
   *
   * @description
   * Take an array index and covert it to char equivalent (0 -> A, 1 -> B, etc).
   *
   * @param {number} index Index to convert [0-25].
   *
   * @example
   {{ $index | indexToChar }}
   */
  .filter('indexToChar', () => (index) => String.fromCharCode(index + 65)).name;
