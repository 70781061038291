import { AppConfig } from '@/core/config/app.config';
import { SbxHttpClient } from '@/core/http';
import { SbxUrlService } from '@/core/url/url.service';
import {
  INotification,
  INotificationsGroup,
  SbxUserNotifications,
} from '@/core/user-notifications/user-notifications.service';
import { Downgrade } from '@/shared/downgrade';
import { SbxDropdownComponent } from '@/shared/sbx-dropdown/sbx-dropdown.component';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { SbxSideMenuService } from '../sbx-side-menu/sbx-side-menu.service';
import { NGB_CONTAINER_TOKEN } from '@/shared/sbx-form/fields/ngb-container.token';
import { NGB_BODY_ATTACHED_CONTAINER_CONFIG } from '@/shared/sbx-form/fields/ngb-container.config';

@Downgrade.Component('ngShoobx', 'sbx-header')
@Component({
  selector: 'sbx-header',
  templateUrl: './sbx-header.component.html',
  styleUrls: ['./sbx-header.component.scss'],
  providers: [
    {
      provide: NGB_CONTAINER_TOKEN,
      useValue: NGB_BODY_ATTACHED_CONTAINER_CONFIG,
    },
  ],
})
export class SbxHeaderComponent implements OnInit {
  @ViewChild('entitiesDropdown') entitiesDropdownRef: SbxDropdownComponent;
  @ViewChild('notificationsDropdown') notificationsDropdownRef: SbxDropdownComponent;
  @ViewChild('notificationModalTemplate') notificationModalTemplate: TemplateRef<{
    notification: INotification;
  }>;

  showNotificationsClearButton: boolean;
  disableNotificationsClearButton = false;
  isMobile = false;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(AppConfig) public appConfig: AppConfig,
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(SbxModalService) public sbxModalService: SbxModalService,
    @Inject(SbxSideMenuService) public sbxSideMenuService: SbxSideMenuService,
    @Inject(SbxUserNotifications) public sbxUserNotifications: SbxUserNotifications,
    @Inject('Window') public window: Window,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.window.innerWidth < 1024;

    this.listenToNotificationsChange();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = this.window.innerWidth < 1024;
  }

  async changeEntity(name: string) {
    await this.appConfig.entityInit(name);
    this.entitiesDropdownRef.close();
    this.navigate(this.sbxUrlService.dashboardUrl());
  }

  hasNotifications(notifications: INotificationsGroup[]) {
    return this.getNotificationsCount(notifications) > 0;
  }

  getNotificationsCount(notifications: INotificationsGroup[]) {
    return notifications?.map((n) => n.items).flat().length;
  }

  navigateToEntitiesPage() {
    this.entitiesDropdownRef.close();
    this.navigate('entities');
  }

  getLogoPath() {
    if (!this.appConfig.currentEntity) {
      return this.sbxUrlService.canonicalUrl([''], {
        withSpaPrefix: true,
      });
    }

    return this.sbxUrlService.dashboardUrl({ withSpaPrefix: true });
  }

  openMobileMenu() {
    this.sbxSideMenuService.open = !this.sbxSideMenuService.open;
  }

  openNotification(notification: INotification) {
    this.notificationsDropdownRef.close();
    this.sbxModalService.open(this.notificationModalTemplate, {
      data: { notification },
    });
  }

  handleAllNotificationsDelete() {
    this.sbxUserNotifications.wsNotificationsCount$.subscribe(() => {
      this.disableNotificationsClearButton = false;
    });
    this.disableNotificationsClearButton = true;
    this.sbxUserNotifications.deleteAll();
  }

  handleNotificationDelete(notification: INotification) {
    this.sbxUserNotifications.deleteSingle(notification.key);
  }

  async handleNotificationsDropdownClick() {
    await this.sbxUserNotifications.fetch();
  }

  async handleNoNotificationsDropdownClick() {
    await this.sbxUserNotifications.fetch();

    this.sbxUserNotifications.notifications.pipe(take(1)).subscribe((notifications) => {
      if (notifications.length) {
        this.notificationsDropdownRef.open();
      }
    });
  }

  dismissAndClose(notification) {
    this.sbxUserNotifications.deleteSingle(notification.key);
    this.sbxModalService.close();
  }

  async logout() {
    const state = { state: { ignoreCamefrom: true } };
    await this.sbxHttpClient.root('2').post('auth/logout').toPromise();
    this.appConfig.reset();
    this.navigate('logout', state);
  }

  isSpaPage() {
    return Boolean(document.querySelector('sbx-app'));
  }

  navigate(url: string, state?: NavigationExtras) {
    if (this.isSpaPage()) {
      this.router.navigate([url], state);
      return;
    }

    location.href = `/spa/${url}`;
  }

  private listenToNotificationsChange(): void {
    this.sbxUserNotifications.notifications
      .pipe(
        tap(
          (notifications) =>
            (this.showNotificationsClearButton = notifications
              ?.flatMap((ng) => ng.items)
              .some((n) => n.type === 'message')),
        ),
        filter((notifications) => !notifications.length),
      )
      .subscribe(() => {
        this.notificationsDropdownRef?.close();
      });
  }
}
