import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http';
import { Component, Input, ViewChild, TemplateRef, Inject } from '@angular/core';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import {
  ISbxTableColumns,
  ISbxTableData,
  ISbxTableGroups,
} from '@/shared/sbx-table/sbx-table.component';
import { PickupFile } from '@shoobx/types/webapi-v2';

interface ISummary {
  hasConditionalGrants: boolean;
  fairMarketValue: number;
  existingGrants: {
    totalShares: number;
    totalValue: number;
    isoShares: number;
    isoValue: number;
    nqoShares: number;
    nqoValue: number;
  };
  proposedSplit: {
    totalShares: number;
    totalValue: number;
    isoShares: number;
    isoValue: number;
    nqoShares: number;
    nqoValue: number;
  };
}

const ISO_LIMIT = 100000;

function getInitialData() {
  const data: ISummary = {
    hasConditionalGrants: false,
    fairMarketValue: 0,
    existingGrants: {
      totalShares: 0,
      totalValue: 0,
      isoShares: 0,
      isoValue: 0,
      nqoShares: 0,
      nqoValue: 0,
    },
    proposedSplit: {
      totalShares: 0,
      totalValue: 0,
      isoShares: 0,
      isoValue: 0,
      nqoShares: 0,
      nqoValue: 0,
    },
  };
  return data;
}

const SELECTOR = 'sbx-iso-nqo-table';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-iso-nqo-table.component.html',
  styleUrls: ['./sbx-iso-nqo-table.component.scss'],
})
export class SbxIsoNqoTableComponent {
  @Input() data;
  @Input() stakeholderName;
  @Input() mode = 'value';
  @ViewChild('proposedSplitTemplate', { static: true })
  proposedSplitTemplate: TemplateRef<object>;
  @ViewChild('totalTemplate', { static: true }) totalTemplate: TemplateRef<object>;
  @ViewChild('yearTemplate', { static: true }) yearTemplate: TemplateRef<object>;
  @ViewChild('existingIsoTemplate', { static: true })
  existingIsoTemplate: TemplateRef<object>;
  @ViewChild('pendingIsoTemplate', { static: true })
  pendingIsoTemplate: TemplateRef<object>;

  yearsWithPendingNQO = {};
  isLoading = false;

  tableData: ISbxTableData = [];
  globalData: ISummary = getInitialData();
  tableGroups: ISbxTableGroups = [
    {
      key: 'existingGrants',
      title: 'Existing ISO Grants',
    },
    {
      key: 'proposedGrants',
      title: 'Your Proposed Grants',
      highlightColor: 'gray',
    },
    {
      key: 'totalValue',
      title: 'Total ISO FMV (ISO $100k Limit)',
    },
    {
      key: 'proposedSplit',
      title: 'Proposed ISO-NQO Split',
      highlightColor: 'gray',
    },
  ];

  tableColumns: ISbxTableColumns = [];

  constructor(
    @Inject(SbxHttpClient) public http: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
  ) {}

  ngOnInit() {
    this.collapseData();
    this.updateYearlySummaryInfo();
    this.tableColumns = [
      {
        title: 'Year',
        key: 'year',
        template: this.yearTemplate,
      },
      {
        key: 'existingGrants',
        group: 'existingGrants',
        title: 'ISO',
        align: 'right',
        template: this.existingIsoTemplate,
      },
      {
        key: 'pendingGrantIso',
        group: 'proposedGrants',
        title: 'ISO',
        align: 'right',
        template: this.pendingIsoTemplate,
      },
      {
        key: 'pendingGrantNqo',
        group: 'proposedGrants',
        title: 'NQO',
        align: 'right',
      },
      {
        key: 'sumOfGrants',
        group: 'totalValue',
        title: 'ISO',
        template: this.totalTemplate,
        align: 'right',
      },
      {
        key: 'proposedIso',
        group: 'proposedSplit',
        title: 'ISO',
        template: this.proposedSplitTemplate,
        align: 'right',
      },
      {
        key: 'proposedNqo',
        group: 'proposedSplit',
        title: 'NQO',
        template: this.proposedSplitTemplate,
        align: 'right',
      },
    ];
  }

  toggleMode() {
    if (this.mode === 'shares') {
      this.mode = 'value';
    } else {
      this.mode = 'shares';
    }
    this.updateYearlySummaryInfo();
  }

  collapseData() {
    Object.keys(this.data).forEach((k) => {
      const infoList = this.data[k];
      let prevInfo = null;
      const newList = [];
      infoList.forEach((info) => {
        const merged = this.mergeInfo(prevInfo, info);
        if (!merged) {
          newList.push(info);
          prevInfo = info;
        }
        if (info.isPending && info.nqoShares) {
          this.yearsWithPendingNQO[k] = true;
        }
      });

      // replace original list with merged list
      infoList.splice(0, infoList.length, ...newList);
    });
  }

  mergeInfo(info, infoToMerge) {
    // Only grants with the same top level information should
    // be merged into a single sell
    if (
      !info ||
      info.isPending !== infoToMerge.isPending ||
      info.vestingStatusDisplay !== infoToMerge.vestingStatusDisplay ||
      info.vestingTypeDisplay !== infoToMerge.vestingTypeDisplay
    ) {
      return false;
    }

    // For pending grants, separate individual grants
    // For existing grants, merge all grants together
    if (info.isPending && info.grantId !== infoToMerge.grantId) {
      return false;
    }

    info.isoShares += infoToMerge.isoShares;
    info.isoValue += infoToMerge.isoValue;
    info.nqoShares += infoToMerge.nqoShares;
    info.nqoValue += infoToMerge.nqoValue;
    info.totalShares += infoToMerge.totalShares;
    info.totalValue += infoToMerge.totalValue;

    return true;
  }

  sumInfoItems(infos, type) {
    let sum = 0;
    infos.forEach((info) => {
      sum += this._getNum(info, type);
    });
    return this._formatNum(sum);
  }

  _getNum(info, type) {
    if (this.mode === 'value') {
      if (type === 'total') {
        return info.isoValue + info.nqoValue;
      } else if (type === 'iso') {
        return info.isoValue;
      }
      return info.nqoValue;
    }

    if (type === 'total') {
      return info.totalShares;
    } else if (type === 'iso') {
      return info.isoShares;
    }
    return info.nqoShares;
  }

  sortedKeys() {
    return Object.keys(this.data).sort();
  }

  _formatNum(num) {
    if (!num) {
      return '--';
    }
    if (this.mode === 'value') {
      return '$' + num.toLocaleString(undefined, { minimumFractionDigits: 2 });
    }
    return num.toLocaleString();
  }

  updateYearlySummaryInfo() {
    const newList = [];

    const summaryData = getInitialData();

    Object.keys(this.data).forEach((k) => {
      const infos = this.data[k];

      let existingGrants = 0;
      let pendingGrants = 0;
      let proposedIso = 0;
      let proposedNqo = 0;
      let sumGrants = 0;
      let hasPendingConditionalGrants = false;
      let hasExistingConditionalGrants = false;
      let hasDuplicateEntries = false;

      infos.forEach((info) => {
        if (info.isPending) {
          pendingGrants += this._getNum(info, 'total');
          proposedIso += this._getNum(info, 'iso');
          proposedNqo += this._getNum(info, 'nqo');

          summaryData.fairMarketValue = info.fmv;
          if (!info.duplicateEntry) {
            Object.keys(summaryData.proposedSplit).forEach((k) => {
              summaryData.proposedSplit[k] += info[k];
            });
          }

          if (
            info.vestingTypeDisplay === 'Conditional-Vesting' ||
            info.vestingTypeDisplay === 'Custom-Vesting'
          ) {
            hasPendingConditionalGrants = true;
          }

          sumGrants += info.isoValue + info.nqoValue;
        } else {
          existingGrants += this._getNum(info, 'iso');
          if (!info.duplicateEntry) {
            Object.keys(summaryData.existingGrants).forEach((k) => {
              summaryData.existingGrants[k] += info[k];
            });
          }
          if (
            info.vestingTypeDisplay === 'Conditional-Vesting' ||
            info.vestingTypeDisplay === 'Custom-Vesting'
          ) {
            hasExistingConditionalGrants = true;
          }
          sumGrants += info.isoValue;
        }
        if (info.duplicateEntry) {
          hasDuplicateEntries = true;
        }
      });

      if (!summaryData.hasConditionalGrants) {
        summaryData.hasConditionalGrants =
          (hasExistingConditionalGrants || hasPendingConditionalGrants) &&
          hasDuplicateEntries;
      }

      newList.push({
        year: k,
        existingGrants: this._formatNum(existingGrants),
        pendingGrantIso: this._formatNum(pendingGrants),
        pendingGrantNqo: '--',
        sumOfGrants:
          '$' + sumGrants.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        violation: sumGrants > ISO_LIMIT,
        proposedIso: this._formatNum(proposedIso),
        proposedNqo: this._formatNum(proposedNqo),
        hasExistingConditionalGrants: hasExistingConditionalGrants,
        hasPendingConditionalGrants: hasPendingConditionalGrants,
      });
    });

    this.tableData = newList;
    this.globalData = summaryData;
  }

  fmt(num, type = 'shares') {
    if (type === 'dollars') {
      return num.toLocaleString(undefined, { minimumFractionDigits: 2 });
    }

    return num.toLocaleString();
  }

  async handleXlsDownload() {
    this.isLoading = true;
    const data = await this.http
      .entity('2')
      .get<PickupFile>('equity/isonqo/distribution_details', {
        params: { processId: this.processUrlInfo.processId() },
      })
      .toPromise();
    window.location.replace(data.downloadUrl);
    this.isLoading = false;
  }
}
