import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxIsoNqoTableComponent } from './sbx-iso-nqo-table.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxIsoNqoTableComponent],
})
export class SbxIsoNqoTableModule {
  static entryComponents = [SbxIsoNqoTableComponent];

  static forRoot(): ModuleWithProviders<SbxIsoNqoTableModule> {
    return {
      ngModule: SbxIsoNqoTableModule,
    };
  }
}
