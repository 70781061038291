import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SbxCheckboxComponent } from './sbx-checkbox.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, FormsModule],
  declarations: [SbxCheckboxComponent],
  exports: [SbxCheckboxComponent],
})
export class SbxCheckboxModule {}
