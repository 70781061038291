import { Component, Input, Inject, OnInit } from '@angular/core';
import { SbxUrlService } from '@/core/url/url.service';
import { ISbxIconType } from '@/core/constants/icons';
import { Downgrade } from '@/shared/downgrade';
import { SbxDocumentHeaderService } from './sbx-document-header.service';
import { DocumentHeader } from '@shoobx/types/webapi-v2';
import { EMPTY, catchError, finalize } from 'rxjs';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { formatDate } from '@angular/common';

const ERROR_MESSAGE = 'An error occurred while fetching document header details.';

export interface IDocumentHeaderDetails {
  icon: ISbxIconType;
  key: string;
  value: string;
  popup?: Array<string>;
}

export interface IDocumentHeaderButton {
  icon: ISbxIconType;
  value: string;
}

const SELECTOR = 'sbx-document-header';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-document-header.component.html',
  styleUrls: ['./sbx-document-header.component.scss'],
})
export class SbxDocumentHeaderComponent implements OnInit {
  @Input() documentId: string;
  @Input() backText: string;
  @Input() backUrl: string;

  data: DocumentHeader;
  items: IDocumentHeaderDetails[] = [];
  buttons: IDocumentHeaderButton[] = [];
  isSpaPage = false;
  loading = false;
  error: string;

  constructor(
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(SbxDocumentHeaderService)
    private documentHeaderService: SbxDocumentHeaderService,
    @Inject(SbxFormModalService) private sbxFormModalService: SbxFormModalService,
  ) {}

  ngOnInit() {
    if (!this.backUrl) {
      this.backUrl = this.sbxUrlService.dashboardUrl({ withSpaPrefix: true });
    }
    this.isSpaPage = Boolean(this.sbxUrlService.spaUrl(location.pathname));
    this.loadDocumentHeader();
  }

  async loadDocumentHeader() {
    this.loading = true;

    await this.documentHeaderService
      .getDocumentHeader(this.documentId)
      .pipe(
        catchError((e) => {
          this.error = e?.error?.message || ERROR_MESSAGE;
          return EMPTY;
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((data: DocumentHeader) => {
        this.data = data;
        this.processFields(this.data);
      });
  }

  processFields(data: DocumentHeader) {
    this.items = [];
    for (const [key, value] of Object.entries(data)) {
      if (key === 'status' && data.shouldDisplayStatus && value) {
        this.items.push({ icon: 'help', key: key, value: value });
      }
      if (key === 'effectiveDate' && value) {
        this.items.push({
          icon: 'calendarSolid',
          key: key,
          value: formatDate(value, 'MMMM d, yyyy', 'en'),
        });
      }
      if (key === 'customizations' && value > 0) {
        this.items.push({
          icon: 'pen',
          key: key,
          value: 'Contains Customizations',
        });
      }
      if (key === 'folders') {
        const folders = value.map((f) => f.path.join(' > '));
        value.map((f) => {
          this.items.push({
            icon: 'folder',
            key: key,
            value: f.name,
            popup: folders,
          });
        });
      }
      if (key === 'displayType') {
        this.items.push({ icon: this.getIcon(value), key: key, value: value });
      }
      if (key === 'template' && value) {
        this.items.push({ icon: 'book', key: key, value: value });
      }
    }
  }

  async open(id: string) {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.documentHeaderService.getDocumentTitleFormUrl(id),
        },
      }).result;

      this.loadDocumentHeader();
    } catch (e) {
      if (e?.userCancelled) {
        return;
      }
      this.error = e?.error?.message || ERROR_MESSAGE;
    }
  }

  getIcon(displayType: string) {
    switch (displayType) {
      case 'generated':
        return 'docGenerated';
      case 'uploaded':
        return 'docUploaded';
      case 'deferred':
        return 'docDeferred';
      case 'pending':
        return 'docPending';
      case 'certificate':
        return 'docCertificate';
      default:
        return 'document';
    }
  }
}
