import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http/';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import { SbxAlertBannerComponent } from '@/shared/sbx-alert-banner/sbx-alert-banner.component';

const SELECTOR = 'sbx-wait-for-signatures';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-wait-for-signatures.component.html',
  styleUrls: ['./sbx-wait-for-signatures.component.scss'],
})
export class SbxWaitForSignaturesWorkitemComponent implements OnInit {
  @ViewChild('statusBanner') statusBanner: SbxAlertBannerComponent;

  @Input() hideothers: Array<any>;
  @Input() signatures: Array<any>;
  @Input() workitem? = '';

  endpoint: string;

  constructor(
    @Inject(SbxHttpClient) private service: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
  ) {}

  ngOnInit() {
    // Variables used in communicating with backend.
    let wiId = this.workitem; // If this is in a composable workitem, ID is inherited.
    if (wiId === '') {
      wiId = this.processUrlInfo.wiId(); // Otherwise, ID is in URL.
    }
    this.endpoint = `workitems/${wiId}/signatures/remind`;
  }

  async handleRemind(remindExtradata) {
    const remindResult: any = await this.service
      .entity('2')
      .post(this.endpoint, { params: remindExtradata })
      .toPromise();
    if (remindResult.remindSucceeded) {
      this.statusBanner.show({
        customText: `Reminder sent to ${remindResult.remindName}.`,
        error: false,
        warning: false,
      });
    } else {
      this.statusBanner.show({
        customText: `Could not send reminder to ${remindResult.remindName}.`,
        error: true,
        warning: false,
      });
    }
  }

  handleInviteWrapper(sb) {
    // Wrapper is needed to allow below function access to
    // this.statusBanner
    return function handleInvite(data) {
      if (data.success === true) {
        sb.show({
          customText: data.message,
          error: false,
          warning: false,
        });
      } else {
        sb.show({
          customText: 'Could not send invitation',
          error: true,
          warning: false,
        });
      }
    };
  }
}
