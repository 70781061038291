/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.signature.object:$createSignatureModal
 *
 * @description
 * Call this function to open a modal that contains a create signature form.
 *
 * @param {object} model This is the model object that will be interacted with
 *    by this modal. It expects the following interface:
 *    * `.$saveSig(data)` promise returning function to save the data.
 *    * `.$signatureDialog` HTML string of the form.
 *    * `.$loading` Boolean that the model is loading.
 * @param {string} [confirmButtonText="Create Signature"] Text of the submit
 *    button.
 *
 * @returns {promise} Resolves when the signature is submitted and saved
 *    successfully and rejects when the modal is closed for any other reason.
 *
 * @TODO this uses old school forms and jquery calls. Fix this and remove the
 * `.$signatureDialog` dependency.
 */
export const $createSignatureModal = [
  '$sbModal',
  function ($sbModal) {
    return function (model, confirmButtonText) {
      return $sbModal.open({
        template: require('./templates/create-modal.html'),
        controller: 'CreateSignatureModalCtrl',
        resolve: {
          ConfirmText: function () {
            return confirmButtonText || 'Create Signature';
          },
          Model: function () {
            return model;
          },
        },
      }).result;
    };
  },
]; // end $createSignatureModal

/**
 * @ngdoc controller
 * @name sb.lib.signature.controller:CreateSignatureModalCtrl
 *
 * @description
 * This is the defined behavior of the create signature modal.
 */
export const CreateSignatureModalCtrl = [
  '$scope',
  '$sce',
  'Model',
  'ConfirmText',
  function ($scope, $sce, Model, ConfirmText) {
    function confirmSignature() {
      $scope.error = null;
      Model.$saveSig(new FormData(document.querySelector('.signature-form'))).then(
        $scope.$close,
        (error) => {
          $scope.error = error;
        },
      );
    }

    $scope.model = Model;
    $scope.confirmText = ConfirmText;
    $scope.signatureDialog = $sce.trustAsHtml(Model.$signatureDialog);
    $scope.confirmSignature = confirmSignature;
  },
]; // end CreateSignatureModalCtrl
