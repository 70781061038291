import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import Promise from 'lib/promise';

import { SubProcessModel } from './model';

/**
 * @ngdoc directive
 * @name sb.workitem.manageSubprocesses.directive:sbManageProcessControl
 * @restrict EA
 *
 * @description
 * This element is a self contained manager for a process. Likely this is used
 * with a workitem like manage subprocesses. It has two (optional) transclusion
 * slots for completed and finished.
 *
 * @param {object} sbManageProcessControlProcess A process object that this
 *    control will manage. It may also be `null`/`undefined` to signify the
 *    process has not yet started. This will show a button to start the process.
 * @param {object} sbManageProcessControlModel The model object this control
 *    is interacting with.
 *
 * @example
   <sb-manage-process-control
     data-sb-manage-process-control-process="proc"
     data-sb-manage-process-control-model="$model">

     <sb-manage-process-control-not-started>
       Provide Document(s) for {{ ::proc.$subjectFullName }}
     </sb-manage-process-control-not-started>

     <sb-manage-process-control-completed>
       Document(s) Provided for {{ ::proc.$subjectFullName }}
     </sb-manage-process-control-completed>

   </sb-manage-process-control>
 */
function sbManageProcessControl() {
  return {
    restrict: 'EA',
    template: require('./templates/manage-process-control.html'),
    scope: {
      process: '&sbManageProcessControlProcess',
      model: '=sbManageProcessControlModel',
      editable: '<sbManageProcessControlEditable',
    },
    transclude: {
      notStarted: '?sbManageProcessControlNotStarted',
      completed: '?sbManageProcessControlCompleted',
    },
    controller: [
      '$scope',
      '$timeout',
      '$confirm',
      'ProcessStatus',
      'PromiseErrorCatcher',
      '$processModal',
      function (
        $scope,
        $timeout,
        $confirm,
        ProcessStatus,
        PromiseErrorCatcher,
        $processModal,
      ) {
        function showError(reason) {
          ProcessStatus.$setStatus(reason, 'danger');
        }
        function addProcess(process) {
          $scope.model.$new({ subject: process.$subject }).then((newProcess) => {
            // Wait a $timeout to allow the html to compile.
            return $timeout(() => {
              $scope.$broadcast('startProcess-' + newProcess.id);
            });
          }, showError);
        }
        function removeProcess(process) {
          $confirm({
            body: '<p>Are you sure you want to restart?</p>',
          })
            .then(() => {
              return $scope.model.$remove(process.id).catch(showError);
            })
            .catch(PromiseErrorCatcher);
        }
        function editProcess(process) {
          $scope.model.$edit(process.id).then((processes) => {
            const proc = processes.find((x) => x.id === process.id);
            const workItem = proc.workitems[0];
            $processModal($scope, workItem, $scope.model);
          });
        }
        $scope.addProcess = addProcess;
        $scope.removeProcess = removeProcess;
        $scope.editProcess = editProcess;
      },
    ],
  };
} // end sbManageProcessControl

/**
 * @ngdoc directive
 * @name sb.workitem.manageSubprocesses.directive:sbManageSubprocessesWi
 * @restrict A
 *
 * @description
 * Add this attribute to any workitem to automatically augment the scope with
 * behavior for mangaing subprocesses.
 *    * `$model.$process` Array of processes objects.
 *    * `$model.$extraData` Extra data reported by the context
 *    * `$addNewProcess()` Add a process to the model and pop the modal up.
 *    * `$removeProcess(process)` Remove process object from model.
 *    * `$restartProcess(process)` Restart process object (restart).
 *    * `$manuallyFinishProcess(process)` Claim a process.
 */
function sbManageSubprocessesWi() {
  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$q',
      '$location',
      'PromiseErrorCatcher',
      '$processModal',
      '$confirm',
      'SubProcessModel',
      'ProcessStatus',
      'ProcessButtonModel',
      '$window',
      function (
        $scope,
        $q,
        $location,
        PromiseErrorCatcher,
        $processModal,
        $confirm,
        SubProcessModel,
        ProcessStatus,
        ProcessButtonModel,
        $window,
      ) {
        function addConfirmDialog(buttonName, options, condition) {
          ProcessButtonModel.$addSubmitCondition(buttonName, () => {
            return $q((resolve, reject) => {
              if (angular.isDefined(condition) && !condition()) {
                return resolve();
              }
              return $confirm(options).then(resolve, reject);
            });
          });
        }
        function procModal(proc) {
          if (currentProcModal) {
            currentProcModal.close();
          }
          currentProcModal = $processModal(
            $scope,
            {
              id: proc.currentWiId,
              title: proc.title,
            },
            subProcessModelCopy,
          );
          currentProcModal.result.catch(PromiseErrorCatcher).finally(() => {
            currentProcModal = null;
          });
        }
        function showError(reason) {
          ProcessStatus.$setStatus(reason, 'danger');
        }
        function addNewProcess() {
          ProcessStatus.$setStatus();
          subProcessModelCopy.$new().then((newProcess) => {
            procModal(newProcess);
          }, showError);
        }
        function canManuallyFinishProcess(proc) {
          return (
            !proc.isFinished &&
            $scope.$model.$extraData.canFinishManually &&
            proc.isDiscardable &&
            !proc.hasAction
          );
        }
        function manuallyFinishProcess(proc, evt) {
          ProcessStatus.$setStatus();
          evt.preventDefault();
          let msg = `Please confirm that you want to
                 continue. NOTE: You will not be able to return to this
                 page and proceed with the workflow until all
                 of the steps in this process are completed.`;
          if (subProcessModelCopy.$extraData.manualConfirmMessage) {
            msg = subProcessModelCopy.$extraData.manualConfirmMessage;
          }
          $confirm({
            body: msg,
          })
            .then(
              () => subProcessModelCopy.$manuallyFinish(proc.id),
              () => $q.reject(),
            )
            .then((newProcess) => {
              if (!newProcess.isFinished) {
                $location.path(`/${newProcess.currentWiId}`);
              }
            }, showError);
        }
        function canOverrideProcess(proc) {
          return (
            !proc.isFinished &&
            $scope.$model.$extraData.canOverrideProcess &&
            proc.isDiscardable
          );
        }
        function overrideProcess(proc, evt, key) {
          ProcessStatus.$setStatus();
          evt.preventDefault();
          let msg = 'Please confirm that you want to continue.';
          if (subProcessModelCopy.$extraData.overrides[key].message) {
            msg = subProcessModelCopy.$extraData.overrides[key].message;
          }
          $confirm({
            body: msg,
          })
            .then(
              () => subProcessModelCopy.$override(proc.id, key),
              () => $q.reject(),
            )
            .then((newProcess) => {
              if (!newProcess.isFinished && newProcess.currentWiId) {
                $location.path(`/${newProcess.currentWiId}`);
              }
            }, showError);
        }
        function canRestartProcess(proc) {
          return (
            $scope.$model.$extraData.canReset &&
            proc.$canReset &&
            (proc.hasAction || $scope.$model.$extraData.alwaysShowReset)
          );
        }
        function restartProcess(proc, evt) {
          ProcessStatus.$setStatus();
          evt.preventDefault();
          subProcessModelCopy
            .$restart(proc.id)
            .then((newProcess) => {
              // When user click on Edit button, it directly prompt user
              // to either workitem page or workitem modal, based on whether
              // the process can finish manually
              if (
                subProcessModelCopy.$extraData.canFinishManually &&
                newProcess.currentWiId
              ) {
                $location.path(`/${newProcess.currentWiId}`);
              } else if (subProcessModelCopy.$extraData.canFinishManually) {
                // if the newProcess was deleted, and currentWiId no longer exists, then
                // we just want to refresh the page to update the table
                $window.location.reload();
              } else {
                procModal(newProcess);
              }
            })
            .catch(showError);
        }

        function canRemoveProcess(proc) {
          return (
            $scope.$model.$extraData.canRemove &&
            proc.isDiscardable &&
            proc.$preseededDiscardAvailable
          );
        }
        function removeProcess(proc, evt) {
          ProcessStatus.$setStatus();
          evt.preventDefault();
          let msg = '<p>Are you sure you want to remove this item?</p>';
          if (proc.$preseededDiscardMessage) {
            msg = proc.$preseededDiscardMessage;
          }
          $confirm({ body: msg })
            .then(() => {
              subProcessModelCopy.$remove(proc.id).then(() => {
                subProcessModelCopy.$load();
              }, showError);
            })
            .catch(PromiseErrorCatcher);
        }
        function changeSort(evt, newSortKey) {
          if (newSortKey === $scope.procOrderKey) {
            $scope.reverse = !$scope.reverse;
          } else {
            $scope.reverse = false;
          }
          $scope.procOrderKey = newSortKey;
          evt.preventDefault();
        }
        let currentProcModal;
        let subProcessModelCopy = new SubProcessModel();
        if ($scope.vm) {
          if ($scope.vm.extraContext.workitemId) {
            subProcessModelCopy = new SubProcessModel(
              $scope.vm.extraContext.workitemId,
            );
          } else {
            subProcessModelCopy = new SubProcessModel($scope.vm.extraContext);
          }
        }

        ProcessButtonModel.disable('continue');
        subProcessModelCopy.$init().then(() => {
          // We wait until the model has inited so that the workitem
          // has an opportunity to define behavior.
          const exp = '$model.$extraData.canContinue';
          const watchCanContinue = $scope.$watch(exp, (nv, ov) => {
            if (angular.isUndefined(nv)) {
              // This means the workitem does not define this property.
              // Unlock and stop watching.
              ProcessButtonModel.requestEnable('continue');
              watchCanContinue();
            } else if (nv) {
              ProcessButtonModel.requestEnable('continue');
            } else if (nv !== ov) {
              ProcessButtonModel.disable('continue');
            }
          });
        }, showError);
        addConfirmDialog('back', {
          title: 'Go back?',
          body:
            '<p>Note: If you go back, <strong>all data</strong> entered on ' +
            'this page will be <strong>deleted</strong>.</p>',
          confirmButtonText: 'Go Back/Reset Data',
        });
        addConfirmDialog(
          'continue',
          {
            title: 'Continue?',
            alertType: 'warning',
            body:
              '<p>One or more of these items need more information ' +
              '(<i class="fa fa-exclamation-circle" style="color: orange;"></i>)' +
              ' or are waiting for another user (<i class="far fa-clock" style="color: orange;"></i>). ' +
              'You may continue, but the in-progress work items will be discarded. Do you ' +
              'wish to proceed?</p>',
            confirmButtonText: 'Continue',
          },
          () => {
            $scope.attemptedToContinue = true;
            return (subProcessModelCopy.$processes || []).some(
              (proc) => !proc.isNotStarted && !proc.isFinished,
            );
          },
        );

        $scope.$watch('$model.$loading', (nv, ov) => {
          if (nv) {
            ProcessButtonModel.disable();
          } else if (nv !== ov) {
            ProcessButtonModel.requestEnable();
          }
        });

        $scope.$watch('$model.$processesStarting', (nv, ov) => {
          if (nv) {
            ProcessButtonModel.disable('continue');
          } else if (nv !== ov) {
            ProcessButtonModel.requestEnable('continue');
          }
        });

        $scope.$on('ProcessCtrl::newSubProcess', (evt, { subProcId, subProcModal }) => {
          if (subProcId) {
            // We have to provide reasonable process model to `procModal`, but we
            // only have workitem id here. So we are guessing process id from
            // workitem id. Ideally, we should provide root subprocess id in `id`
            // property here so process modal knows when to stop following the
            // process in the modal (it passes `id` as `restrictNext` parameter
            // to the modal eventually). However, current modal design doesn't
            // allow subprocess manager to manage that, so we are assuming
            // managed subprocesses will never run other subprocesses (this
            // assumption was true at the moment of writing, and in-modal process
            // implementation couldn't support subprocesses, spawned from managed
            // processes for many other reasons)
            if (subProcModal) {
              if (currentProcModal) {
                currentProcModal.close();
                currentProcModal = subProcModal;
                currentProcModal.result.finally(() => {
                  currentProcModal = null;
                });
              }
            } else {
              procModal({ currentWiId: subProcId, id: subProcId.split('-')[0] });
            }
          } else if (currentProcModal) {
            currentProcModal.close();
            currentProcModal = null;
          }
        });

        function removeOnModalClose(evt, data) {
          const currentSubProcId = data.id.split('-')[0];
          $scope.$model.$removeIfNoInteraction(currentSubProcId);
        }

        $scope.$on('$processModal::closed', removeOnModalClose);
        $scope.procOrderKey = 'title';
        $scope.reverse = false;
        $scope.changeSort = changeSort;
        $scope.$addNewProcess = addNewProcess;
        $scope.$canRemoveProcess = canRemoveProcess;
        $scope.$removeProcess = removeProcess;
        $scope.$canRestartProcess = canRestartProcess;
        $scope.$restartProcess = restartProcess;
        $scope.$canManuallyFinishProcess = canManuallyFinishProcess;
        $scope.$manuallyFinishProcess = manuallyFinishProcess;
        $scope.$model = subProcessModelCopy;
        $scope.$overrideProcess = overrideProcess;
        $scope.$canOverrideProcess = canOverrideProcess;
      },
    ], // end controller
  };
} // end sbManageSubprocessesWi

/**
 * @ngdoc overview
 * @name sb.workitem.manageSubprocesses
 *
 * @description
 * This is the module for the Add and Mange SubProcesses and
 */
export default angular
  .module('sb.workitem.manageSubprocesses', [ngSanitize, Promise])

  .directive('sbManageProcessControl', sbManageProcessControl)
  .directive('sbManageSubprocessesWi', sbManageSubprocessesWi)
  .factory('SubProcessModel', SubProcessModel).name;
