import { Downgrade } from '@/shared/downgrade';
import { Injectable, Inject } from '@angular/core';
import {
  BehaviorSubject,
  Subscription,
  distinctUntilChanged,
  pairwise,
  startWith,
} from 'rxjs';
import { AppConfig } from '../config/app.config';
import { SbxHttpClient } from '../http';
import { SbxWsService } from '../ws/sbx-ws.service';

interface IBaseNotification {
  key: string;
  link: string;
  status: string;
  timeAgo: string;
}

interface IWorkitemNotification extends IBaseNotification {
  type: 'workitem';
  processName: string;
  workitemName: string;
}

interface IMessageNotification extends IBaseNotification {
  type: 'message';
  title: string;
  content: string;
  severity: string;
}

export type INotification = IWorkitemNotification | IMessageNotification;

export interface INotificationsGroup {
  key: string;
  title: string;
  items: INotification[];
}

interface INotifications {
  notifications: INotificationsGroup[];
}

@Injectable({
  providedIn: 'root',
})
@Downgrade.Injectable('ngShoobx', 'SbxUserNotifications')
export class SbxUserNotifications {
  wsNotificationsTopicSubscription: Subscription = null;
  notificationsSource = new BehaviorSubject<INotificationsGroup[]>([]);
  wsNotificationsCount$ = new BehaviorSubject<number>(0);
  notifications = this.notificationsSource.pipe(
    distinctUntilChanged((prev, curr) => {
      const prevItems = prev.flatMap((g) => g.items.map((i) => i.key));
      const nextItems = curr.flatMap((g) => g.items.map((i) => i.key));

      if (nextItems.length !== prevItems.length) {
        return false;
      }

      return nextItems.every((id) => prevItems.includes(id));
    }),
  );

  constructor(
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    private readonly ws: SbxWsService,
  ) {
    this.appConfig.userInfo$
      .pipe(startWith(null), pairwise())
      .subscribe(([prevUserInfo, nextUserInfo]) => {
        if (!nextUserInfo?.currentStake) {
          this.clear();
          this.wsNotificationsTopicSubscription?.unsubscribe();
          return;
        }

        if (prevUserInfo?.currentStake?.id !== nextUserInfo?.currentStake?.id) {
          this.fetch();

          if (this.appConfig.webSocket?.notificationsTopic) {
            this.wsNotificationsTopicSubscription = this.ws
              .listenTopic$<string>(this.appConfig.webSocket.notificationsTopic)
              .subscribe(() => {
                this.fetch();
                this.wsNotificationsCount$.next(this.wsNotificationsCount$.value + 1);
              });
          }
        }
      });
  }

  async fetch() {
    const { notifications } = await this.sbxHttpClient
      .entity('1')
      .get<INotifications>('notifications')
      .toPromise();
    this.notificationsSource.next(notifications);
  }

  /**
   *  Backend uses Queue system to delete all notifications.
   *  Websockets are responsible for refreshing the current state.
   */
  async deleteAll() {
    await this.sbxHttpClient.entity('1').delete(`notifications/all`).toPromise();
  }

  async deleteSingle(id: string) {
    await this.sbxHttpClient.entity('1').delete(`notifications/${id}`).toPromise();
    await this.fetch();
  }

  clear() {
    this.notificationsSource.next([]);
  }
}
