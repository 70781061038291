const PaylocityStageEmployeesCtrl = [
  '$sbModal',
  '$confirm',
  'PromiseErrorCatcher',
  'SimpleHTTPWrapper',
  'BackendLocation',
  'EmployeeChanges',
  class {
    constructor(
      $sbModal,
      $confirm,
      PromiseErrorCatcher,
      SimpleHTTPWrapper,
      BackendLocation,
      EmployeeChanges,
    ) {
      this.$sbModal = $sbModal;
      this.$confirm = $confirm;
      this.PromiseErrorCatcher = PromiseErrorCatcher;
      this.SimpleHTTPWrapper = SimpleHTTPWrapper;
      this.BackendLocation = BackendLocation;
      this.EmployeeChanges = EmployeeChanges;

      this.apiUrl = BackendLocation.entity('1') + 'paylocity';
      this.nonEnrolledFormUrl = this.apiUrl + '/non-enrolled-form';

      this.employees = null;
      this.loading = false;
      this.status = null;

      this.openAddModal = this.openAddModal.bind(this);
      this.openOmitModal = this.openOmitModal.bind(this);
      this.onEnrollSuccess = this.onEnrollSuccess.bind(this);
      this.showStatusMessage = this.showStatusMessage.bind(this);
    }

    $postLink() {
      this.loading = true;

      return this.SimpleHTTPWrapper({
        method: 'GET',
        url: `${this.apiUrl}/enrollable`,
      })
        .then((response) => {
          this.employees = response.employees;

          this.loading = false;
        })
        .catch((error) => {
          this.showStatusMessage(error || 'There was a response error.', 'danger');

          this.loading = false;
        });
    }

    openAddModal(employee) {
      const modal = this.$sbModal.open({
        size: 'md',
        keyboard: false,
        backdrop: 'static',
        template: require('./templates/paylocity-stage-modal.html'),
        controllerAs: 'vm',
        bindToController: true,
        resolve: {
          apiUrl: () => this.apiUrl,
          employee: () => employee,
        },
        controller: [
          'SimpleHTTPWrapper',
          'apiUrl',
          'employee',
          function (SimpleHTTPWrapper, apiUrl, employee) {
            this.SimpleHTTPWrapper = SimpleHTTPWrapper;
            this.apiUrl = apiUrl;
            this.employee = employee;
            this.status = null;

            this.stage = stage.bind(this);

            function stage() {
              return this.SimpleHTTPWrapper({
                method: 'POST',
                url: `${this.apiUrl}/stage`,
                data: { id: this.employee.stakeholderId },
              })
                .then((response) => {
                  this.$close(response);
                })
                .catch((error) => {
                  this.status = error || 'There was a response error.';
                });
            }
          },
        ],
      }).result;

      modal
        .then((newEmployee) => {
          this.employees = this.employees.map((employee) => {
            if (employee.stakeholderId === newEmployee.stakeholderId) {
              employee = newEmployee;
            }

            return employee;
          });

          // Let other subscribed views to know that employee was updated
          this.EmployeeChanges.emit(newEmployee.stakeholderId);

          this.showStatusMessage(
            `${newEmployee.stakeholder.fullName} has been added to the payroll system.
        Complete set up in payroll system now.`,
            'success',
          );
        })
        .catch(this.PromiseErrorCatcher);
    }

    openOmitModal(employee) {
      const modal = this.$sbModal.open({
        size: 'md',
        keyboard: false,
        backdrop: 'static',
        template: require('./templates/paylocity-omit-modal.html'),
        controllerAs: 'vm',
        bindToController: true,
        resolve: {
          apiUrl: () => this.apiUrl,
          employee: () => employee,
        },
        controller: [
          'SimpleHTTPWrapper',
          'apiUrl',
          'employee',
          function (SimpleHTTPWrapper, apiUrl, employee) {
            this.SimpleHTTPWrapper = SimpleHTTPWrapper;
            this.apiUrl = apiUrl;
            this.employee = employee;
            this.status = null;

            this.omit = omit.bind(this);

            function omit() {
              return this.SimpleHTTPWrapper({
                method: 'POST',
                url: `${this.apiUrl}/omit`,
                data: { id: this.employee.id },
              })
                .then(() => {
                  this.$close(this.employee);
                })
                .catch(() => {
                  this.status = 'There was a response error.';
                });
            }
          },
        ],
      }).result;

      modal
        .then((employee) => {
          this.employees = this.employees.filter(
            (e) => e.stakeholder.id !== employee.id,
          );

          this.showStatusMessage(
            `${employee.fullName} has been omitted from the payroll system.`,
            'success',
          );
        })
        .catch(this.PromiseErrorCatcher);
    }

    onEnrollSuccess(data) {
      // Let other subscribed views to know that employee was updated

      return this.$confirm({
        body:
          `Employee ${data.request.paylocityAccountNumber} is ` +
          `<b>${data.employee.firstName} ${data.employee.lastName}</b> ` +
          'on Paylocity. Do you still want to connect this payroll account to ' +
          ` <b>${data.request.employee.sh.fullName}</b>?`,
        alertType: 'warning',
        confirmButtonText: 'Yes',
        dismissButtonText: 'No',
      })
        .then(() => {
          return this.SimpleHTTPWrapper({
            method: 'POST',
            url: `${this.apiUrl}/connect-employee`,
            data: data.request,
          });
        })
        .then((data) => {
          this.showStatusMessage(
            `${data.employee.sh.fullName} has been added to the payroll system.`,
            'success',
          );
          this.EmployeeChanges.emit(data.employee.sh.id);
        });
    }

    showStatusMessage(msg, type) {
      if (!msg) {
        this.status = null;
      }

      this.status = {
        type,
        message: msg,
      };
    }
  },
];

/**
 * @ngdoc component
 * @name sb.payroll.sbPaylocityStageEmployees
 *
 * @description
 * This element will display the stage new employees table for
 * Paylocity payroll integration.
 *
 */
export const sbPaylocityStageEmployees = {
  controllerAs: 'vm',
  template: require('./templates/paylocity-stage.html'),
  controller: PaylocityStageEmployeesCtrl,
};
