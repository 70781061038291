import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import SignaturePad from 'signature_pad';

const SIGNATURE_COLOR = '#000000';
const LINE_COLOR = '#141414';

@Component({
  selector: 'sbx-signature',
  templateUrl: './sbx-signature.component.html',
  styleUrls: ['sbx-signature.component.scss'],
})
export class SbxSignatureComponent implements AfterViewInit {
  @ViewChild('canvasVisible', { read: ElementRef }) canvasVisible;
  @ViewChild('canvas', { read: ElementRef }) canvas;
  sigVisible: SignaturePad;
  sig: SignaturePad;
  @Output() onCapture = new EventEmitter();
  @Output() onClear = new EventEmitter();

  width = '520';
  height = '130';
  imageBase64: string;
  error;

  ngAfterViewInit() {
    this.sigVisible = new SignaturePad(this.canvasVisible.nativeElement, {
      minWidth: 1,
      maxWidth: 1,
      penColor: SIGNATURE_COLOR,
    });
    this.drawLine();

    this.sig = new SignaturePad(this.canvas.nativeElement);

    this.sigVisible.addEventListener(
      'endStroke',
      () => {
        this.capture();
      },
      { once: false },
    );
  }

  capture() {
    this.sig.fromData(this.sigVisible.toData());
    this.imageBase64 = this.sig.toDataURL();
    this.onCapture.emit(this.imageBase64);
  }

  clear() {
    this.imageBase64 = '';
    this.clearCanvas();
    this.onClear.emit();
  }

  private clearCanvas() {
    this.sigVisible.clear();
    this.drawLine();
  }

  private drawLine() {
    const ctx = this.canvasVisible.nativeElement.getContext('2d');
    ctx.beginPath();
    ctx.strokeStyle = LINE_COLOR;
    ctx.moveTo(50, 110);
    ctx.lineTo(450, 110);
    ctx.stroke();
  }
}
