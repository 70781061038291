import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SbxRouterLinkDirective } from './sbx-router-link.directive';

@NgModule({
  declarations: [SbxRouterLinkDirective],
  imports: [CommonModule, RouterModule],
  exports: [SbxRouterLinkDirective],
})
export class SbxNavigationModule {}
