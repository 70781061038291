<sbx-select
  *ngIf="isMobile; else sideNavTemplate"
  [selectOptions]="tabs | tabsToSelectOptions"
  [model]="activeTabIndex"
  (selectItem)="changeActiveTabEvent.emit(tabs[$event])"
></sbx-select>

<ng-template #sideNavTemplate>
  <sbx-side-nav-list
    [links]="tabs | tabsToSideNavListLinks"
    [activeIndex]="activeTabIndex"
    (linkClick)="changeActiveTabEvent.emit(tabs[$event.index])"
  ></sbx-side-nav-list>
</ng-template>
