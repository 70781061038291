import angular from 'angular';
import sbAjaxRedirectHandler from 'lib/url/ajax-handler';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { $dropTransaction } from './drag';

const runObservable = [
  '$observable',
  '$q',
  '$window',
  function ($observable, $q, $window) {
    const DEFAULT_AJAX_CONFIG = Object.freeze({
      headers: Object.freeze({
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    });

    $observable.sbAjax = function (config) {
      return new Observable((observer) => {
        $window._sbAjaxCounter = ($window._sbAjaxCounter || 0) + 1;
        return ajax(Object.assign({}, DEFAULT_AJAX_CONFIG, config))
          .subscribe({
            next: (ajaxValue) => {
              const explicitlyAllowedStatus =
                angular.isArray(config.allowResponses) &&
                config.allowResponses.includes(ajaxValue.status);
              if (!explicitlyAllowedStatus) {
                const redirecting = sbAjaxRedirectHandler(
                  $window,
                  ajaxValue.status,
                  ajaxValue.response,
                );
                if (redirecting) {
                  return;
                }
              }
              observer.next(ajaxValue);
            },
            error: (error) => {
              const explicitlyAllowedStatus =
                angular.isArray(config.allowResponses) &&
                config.allowResponses.includes(error.status);
              if (!explicitlyAllowedStatus) {
                const redirecting = sbAjaxRedirectHandler(
                  $window,
                  error.status,
                  error.response,
                );
                if (redirecting) {
                  return;
                }
              }
              observer.error(error);
            },
            complete: () => {
              observer.complete();
            },
          })
          .add(() => {
            $window._sbAjaxCounter -= 1;
            if ($window._sbAjaxCounter === 0) {
              $window.document.dispatchEvent(new Event('sbAjaxObservableDone'));
            }
          });
      });
    };

    $observable.fromWatcher = function (scope, watchExpr, objectEquality) {
      return new Observable((observer) =>
        scope.$watch(
          watchExpr,
          (newValue, oldValue) => {
            observer.next({ newValue, oldValue });
          },
          objectEquality,
        ),
      ).takeUntilScopeDestroy(scope);
    };

    $observable.prototype.takeUntilScopeDestroy = function (scope) {
      return new Observable((observer) => {
        const stopListening = scope.$on('$destroy', () => {
          observer.complete();
        });
        const sub = this.subscribe(
          (v) => observer.next(v),
          (e) => observer.error(e),
          () => observer.complete(),
        );
        return () => {
          stopListening();
          sub.unsubscribe();
        };
      });
    };

    /**
     * @ngdoc method
     * @name tackOn
     * @methodOf sb.lib.rx.$observable
     *
     * @description
     * Adds one more value to an observable with a map function _if_ there was at least one value
     * in the observable. Consider the following examples, if you have a usage like
     * `numbers.tackOn(a => a + 1)`:
     *   --5--6--7--| will become --5--6--7--8|
     *   -----------| will stay as -----------| will stay as since no items were ever emitted.
     */
    $observable.prototype.tackOn = function (mapFn) {
      return new Observable((observer) => {
        let lastValue, hasLastValue;
        return this.subscribe(
          (value) => {
            hasLastValue = true;
            lastValue = value;
            observer.next(value);
          },
          (err) => {
            observer.error(err);
          },
          () => {
            if (hasLastValue) {
              try {
                observer.next(mapFn(lastValue));
              } catch (e) {
                observer.error(e);
                return;
              }
            }
            observer.complete();
          },
        );
      });
    };

    $observable.prototype.$applySubscribe = function (scope, onNext, onError, onComp) {
      function applyFn(fn = angular.noop) {
        return (...args) =>
          scope.$applyAsync(() => {
            fn(...args);
          });
      }
      const sub = this.subscribe(applyFn(onNext), applyFn(onError), applyFn(onComp));
      scope.$on('$destroy', () => {
        sub.unsubscribe();
      });
      return sub;
    };

    $observable.prototype.$toPromise = function () {
      return this.toPromise($q);
    };
  },
];

export default angular
  .module('sb.lib.rx', [])

  .run(runObservable)
  .factory('$observable', () => Observable)
  .factory('$dropTransaction', $dropTransaction).name;
