import { Downgrade } from '@/shared/downgrade';
import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { SettingsProfileService } from '../../sbx-tree/service/settings-profile.service';

@Downgrade.Component('ngShoobx', 'sbx-profile-list-base')
@Component({
  selector: 'sbx-profile-list-base',
  templateUrl: './sbx-profile-list-base.component.html',
  styleUrls: ['./sbx-profile-list-base.component.scss'],
})
export class SbxProfileListBaseComponent implements OnInit {
  root;
  @Input() model;
  @Input() modelKey;
  @Input() settingsType;
  @Output() change = new EventEmitter();

  constructor(
    @Inject(SettingsProfileService) private service: SettingsProfileService,
  ) {}

  handleChange(event) {
    const selectedNodes = event.selectedNodes.map((node) => node.data.id);
    this.model[this.modelKey].splice(
      0,
      this.model[this.modelKey].length,
      ...selectedNodes,
    );
    // Can't condition on this.formControl - it'll
    // just die if formControl isn't set
    this.change.emit(this.model[this.modelKey]);
  }

  setSelected() {
    const nodes = this.root.getNodes();
    const selectedIdMap = new Map(this.model[this.modelKey].map((id) => [id, true]));

    for (const node of nodes) {
      if (selectedIdMap.get(node.data.id)) {
        node.selected = true;
      }
    }
  }

  ngOnInit() {
    this.service.getProfileType(this.settingsType).subscribe((profileType) => {
      this.root = this.service.constructProfileTree(profileType);
      this.setSelected();
    });
  }
}
