<sbx-modal-header>
  <h4 class="modal-title">{{ title }}</h4>
</sbx-modal-header>
<sbx-modal-content>
  <div class="sbx-proportion">
    <div [class]="proportion">
      <iframe
        [src]="videoSrc | resourceUrl"
        allowfullscreen=""
        allow="encrypted-media"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</sbx-modal-content>
