/**
 * @ngdoc object
 * @name sb.lib.url.ProcessUrlInfo
 * @requires $location
 *
 * @description
 * This service reports/sets various information about the current process URL.
 */
export const ProcessUrlInfo = [
  '$location',
  '$window',
  '$httpParamSerializer',
  'BackendLocation',
  'AppConfig',
  'SbxUrlService',
  function (
    $location,
    $window,
    $httpParamSerializer,
    BackendLocation,
    AppConfig,
    SbxUrlService,
  ) {
    let $$baseUrl;

    function baseUrl() {
      // Use explicitly provided base url if we have it. In situations when we
      // didn't set base url yet (e.g. before we load any workitem), fallback to
      // global base url for the entity.
      if ($$baseUrl) {
        return $$baseUrl;
      }

      // Return entity UI url
      if ($location.absUrl().includes('++shoobx')) {
        return BackendLocation.entity('ui');
      }
      return `${BackendLocation.root('ui')}${AppConfig.parseEntityNameFromUrl(
        location.href,
      )}`;
    }
    function workitemUrl(id) {
      return baseUrl() + '/workitems/' + id;
    }
    function workitemIds() {
      let url = $location.path();
      if (url[0] === '/') {
        url = url.slice(1);
      }
      return url.split('/');
    }
    function fullWiId() {
      return workitemIds()[0];
    }
    function addProcessKwargs(url, isSubprocess) {
      const kwargs = $httpParamSerializer({ isBare: isSubprocess || undefined });
      return kwargs ? `${url}?${kwargs}` : url;
    }
    function baseProcUrl() {
      return `${baseUrl()}/processes/${fullWiId().split('-')[0]}`;
    }

    return {
      setBaseUrl: function (baseUrl) {
        $$baseUrl = baseUrl;
      },

      /**
       * @ngdoc method
       * @name processUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the current "base" process URL.
       */
      processUrl: function () {
        return addProcessKwargs(baseProcUrl(), !this.isParentProcess());
      },

      /**
       * @ngdoc method
       * @name dashboardUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the current "base" dashboard URL of this
       *    process.
       */
      dashboardUrl: function () {
        return baseUrl();
      },

      /**
       * @ngdoc method
       * @name currentWiUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the URL to the "real" workitem object.
       */
      currentWiUrl: function () {
        return workitemUrl(fullWiId());
      },

      /**
       * @ngdoc method
       * @name subProcessWiId
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string|undefined} Returns the full wi id of the subprocess,
       *    if there is one.
       */
      subProcessWiId: function () {
        const ids = workitemIds();
        if (ids.length > 1 && ids[1].length) {
          return ids[1];
        }
        return undefined;
      },

      /**
       * @ngdoc method
       * @name wiId
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the full WI ID (including processID).
       */
      wiId: function () {
        return fullWiId();
      },

      /**
       * @ngdoc method
       * @name processId
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the process ID.
       */
      processId: function () {
        // Using absURL so that HTML5 hash urls doesn't truncate the important part
        const url = $location.absUrl();
        // If not on a process page return undefined
        return /\/[^/]*process\//.test(url) ? fullWiId().split('-')[0] : undefined;
      },

      /**
       * @ngdoc method
       * @name urlByWorkItemId
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @param {string} id Id of wi to generate URL of.
       *
       * @returns {string} Returns the process ID.
       */
      urlByWorkItemId: function (id) {
        return workitemUrl(id);
      },

      /**
       * @ngdoc method
       * @name activityId
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the activity ID of the WI.
       */
      activityId: function () {
        return fullWiId().split('-')[1];
      },

      /**
       * @ngdoc method
       * @name currentProcessInfoUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the info URL for the current process.
       */
      currentProcessInfoUrl: function () {
        return baseProcUrl() + '/info';
      },

      /**
       * @ngdoc method
       * @name currentProcessGotoUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns the goto URL for the current process.
       */
      currentProcessGotoUrl: function () {
        return `${BackendLocation.entity('2')}processes/${this.processId()}/goto`;
      },

      /**
       * @ngdoc method
       * @name currentProcessInfoUrlTask
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @param {string} [taskId=undefined] ID of requested task. If falsy, will
       *    just return `currentProcessInfoUrl()`.
       *
       * @returns {string} Returns pollable async status url for a taskID.
       */
      currentProcessInfoUrlTask: function (taskId) {
        if (taskId) {
          return (
            '/async-status.json?task_name=shoobx.app.tasks.continueProcess' +
            '&task_id=' +
            taskId
          );
        }
        return this.currentProcessInfoUrl();
      },

      /**
       * @ngdoc method
       * @name abortUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns abort url for the current process.
       */
      abortUrl: function () {
        const procId = fullWiId().split('-')[0];
        let backUrl = $window.location.pathname;
        if ($window.location.search) {
          backUrl += $window.location.search;
        }
        if ($window.location.hash) {
          backUrl += $window.location.hash;
        }

        return SbxUrlService.canonicalUrl(['workflows', procId, 'abort'], {
          withSpaPrefix: true,
          queryParams: { backUrl },
        });
      },

      /**
       * @ngdoc method
       * @name doneUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns url to redirect user after process (or their
       *     part of the process) is complete.
       */
      doneUrl: function () {
        return baseProcUrl() + '/done';
      },

      /**
       * @ngdoc method
       * @name nextUrl
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {string} Returns url to redirect user after current
       *     worktiem is complete.
       */
      nextUrl: function () {
        return baseProcUrl() + '/next';
      },

      /**
       * @ngdoc method
       * @name isParentProcess
       * @methodOf sb.lib.url.ProcessUrlInfo
       *
       * @returns {boolean} Returns truthy if this is the parent most process, ie it is not a subprocess.
       */
      isParentProcess: function () {
        return $window === $window.top;
      },
    };
  },
]; // end ProcessUrlInfo
