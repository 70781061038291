import 'core-js';
import 'zone.js';
import 'zone.js/plugins/task-tracking';
import '@angular/localize/init';
import '@/shared/styles/index';

import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { AppModule } from './app.module';

if (process.env.NODE_ENV === 'production') {
  enableProdMode();
}

window.addEventListener('DOMContentLoaded', () => {
  platformBrowser().bootstrapModule(AppModule);
});
