import { Component, Input, ViewChild, TemplateRef, Inject } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { ISbxModalConfig } from '../sbx-modal/interfaces';

/**
 * Component to allow inviting uninitialized stakeholders
 *
 * Example:
 *   <sbx-invite-stakeholder
 *     [stakeholderId]="sh.id"
 *     [stakeholderInvited]="sh.invited"
 *     [statusCallback]="someCallback"
 *   </sbx-invite-stakeholder>
 *
 *  stakeholderId: the ID of the stakeholder to invite
 *  stakeholderInvited: whether the stakeholder is invited. Determines language to use
 *  buttonType: 'dropdown' or 'icon'
 *  statusCallback: a function that gets called after invitation
 *  relationshipDeclined: whether the stakeholder has declined the relationship.
 *    { success: true if no error,
 *      message: UI-appropriate message,
 *      changes: true if there were changes
 *    }
 */
const SELECTOR = 'sbx-invite-stakeholder';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-invite-stakeholder.component.html',
  styleUrls: ['sbx-invite-stakeholder.component.scss'],
})
export class SbxInviteStakeholderComponent {
  @Input() stakeholderId: string;
  @Input() stakeholderInvited: boolean;
  @Input() buttonType = 'dropdown';
  @Input() workitemId: string = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() statusCallback: Function = null;
  @Input() relationshipDeclined: boolean;
  @Input() canInvite = true;
  @Input() canEdit = true;
  @Input() modalOpen = false;

  stakeholderName: string;
  stakeholderEmail: string = null;
  stakeholderEntity: string = null;
  stakeholderFund: string = null;

  config: ISbxModalConfig<unknown> = {};

  modal: any = null;

  @ViewChild('content')
  private modalTemplate: TemplateRef<any>;

  constructor(
    private sbxHttp: SbxHttpClient,
    @Inject(SbxModalService)
    private sbxInviteModalService: SbxModalService,
  ) {}

  ngOnInit() {
    if (this.modalOpen) {
      this.getModalData();
    }
  }

  closeModal() {
    if (this.modal) {
      this.modal.close();
    } else {
      this.sbxInviteModalService.close();
    }
  }

  onStatusUpdate(success: boolean, message: string, changes: boolean) {
    if (this.statusCallback) {
      this.statusCallback({ success: success, message: message, changes: changes });
    }
  }

  sendInvitation() {
    if (this.stakeholderEmail) {
      this.sbxInviteModalService.close();
      let url = `stakeholder/${this.stakeholderId}/invite`;
      if (this.workitemId) {
        url += `?workitem=${this.workitemId}`;
      }
      this.sbxHttp
        .entity('2')
        .get(url)
        .subscribe(
          (res: any) => {
            this.onStatusUpdate(!res.error, res.status, !this.stakeholderInvited);
          },
          (errors) => {
            this.onStatusUpdate(false, 'Invitation failed to send.', false);
          },
        );
    }
  }

  getModalData() {
    this.sbxHttp
      .entity('2')
      .get(`stakeholder/${this.stakeholderId}/edit-stakeholder`)
      .subscribe(
        (res: any) => {
          this.stakeholderName = [
            [res.model.first_name, res.model.middle_name, res.model.last_name]
              .filter(Boolean)
              .join(' '),
            res.model.suffix,
          ]
            .filter(Boolean)
            .join(', ');
          this.stakeholderEmail = res.model.email;
          this.stakeholderEntity = res.model.entity_name;
          this.stakeholderFund = res.model.isFund ? res.model.fund_name : null;
        },
        (errors) => {
          this.onStatusUpdate(false, 'Failed to retrieve stakeholder data.', false);
        },
      );
  }

  populateForm() {
    this.getModalData();
    this.modal = this.sbxInviteModalService.open(this.modalTemplate, this.config);
  }
}
