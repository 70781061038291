import { Component, Input, Inject } from '@angular/core';
import { SbxPageHeaderTemplateService } from './sbx-page-header.service';
import { SbxUrlService } from '@/core/url/url.service';
import { Downgrade } from '@/shared/downgrade';
import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';

/**
 *  Shoobx Themed Page Header
 *
 *  Example:
 *    <sbx-page-header title="New Hire Settings" backUrl="index.html">
 *       Lorem ipsum dolor sit amet...
 *    </sbx-page-header>
 */
const SELECTOR = 'sbx-page-header';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-page-header.component.html',
  styleUrls: ['sbx-page-header.component.scss'],
})
export class SbxPageHeaderComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() backText: string;
  @Input() backUrl: string;
  @Input() actionItems: ISbxActionsMenu;
  @Input() beta = false;
  backToDashboardText = 'Back to Workspace';
  isSpaPage = false;

  constructor(
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    public templateService: SbxPageHeaderTemplateService,
  ) {}

  ngOnInit() {
    this.isSpaPage = Boolean(this.sbxUrlService.spaUrl(location.pathname));

    if (!this.backUrl) {
      this.backUrl = this.sbxUrlService.dashboardUrl({ withSpaPrefix: true });
    }

    if (!this.actionItems) {
      this.templateService.actionMenuItems$.subscribe((items) => {
        this.actionItems = items;
      });
    }
  }

  getBackButtonText() {
    return this.backText || this.backToDashboardText;
  }
}
