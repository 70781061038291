<sbx-select
  [name]="$any(field.key)"
  [attr.name]="field.key"
  [selectOptions]="$any(field.templateOptions.options)"
  [initialOption]="field.formControl.value"
  [disabled]="to.readOnly"
  [model]="field.formControl.value"
  (selectItem)="selectItem($event)"
  (focus)="to.focus ? to.focus(field, $event) : null"
  (blur)="formControl.markAsTouched()"
  ngDefaultControl
></sbx-select>
