<h4>{{ group.groupName }}</h4>
<div class="dr-exhibits">
  <div class="list-group">
    <div *ngFor="let reference of group.refs" class="list-group-item">
      <sbx-document-info
        [document]="reference"
        (removeReference)="removeReference.emit(reference.documentCard.id)"
      ></sbx-document-info>
    </div>
  </div>
</div>
