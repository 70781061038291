import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform {
  transform(name: string): string {
    if (typeof name === 'string') {
      return name
        .split(' ')
        .slice(0, 2)
        .map((s) => s[0])
        .join('');
    }
    return '';
  }
}
