import angular from 'angular';

const FORM_VISIBLE_EVALS = {
  newPerson(permissions, { limitCreateDomains, allowPerson, allowCreation }) {
    return (
      (permissions.get('createAllowed') || limitCreateDomains) &&
      allowPerson &&
      allowCreation
    );
  },
  existingPerson(permissions, { allowPerson, allowExisting }) {
    return permissions.get('selectAllowed') && allowPerson && allowExisting;
  },
  newEntity(permissions, { allowEntity, allowCreation, allowStakes }) {
    return (
      permissions.get('createAllowed') && (allowEntity || allowStakes) && allowCreation
    );
  },
  existingEntity(permissions, { allowEntity, allowExisting, allowStakes }) {
    return (
      permissions.get('selectAllowed') && allowEntity && allowExisting && !allowStakes
    );
  },
  existingStake(permissions, { allowStakes, allowExisting }) {
    return permissions.get('selectAllowed') && allowStakes && allowExisting;
  },
};

const FORM_VALID_EVALS = {
  newPerson(fc) {
    return fc.formly_newPersonForm && fc.formly_newPersonForm.$valid;
  },
  existingPerson(fc) {
    return fc.existingPerson && fc.existingPerson.$valid;
  },
  newEntity(fc) {
    return (
      fc.formly_newEntityForm &&
      fc.formly_newEntityForm.$valid &&
      (!fc.newEntityAffiliateForm || fc.newEntityAffiliateForm.$valid)
    );
  },
  existingEntity(fc) {
    return (
      fc.existingEntity &&
      fc.existingEntity.$valid &&
      (!fc.existingEntityAffiliateForm || fc.existingEntityAffiliateForm.$valid)
    );
  },
  existingStake(fc) {
    return (
      fc.existingStake &&
      fc.existingStake.$valid &&
      (!fc.existingStakeAffiliateForm || fc.existingStakeAffiliateForm.$valid)
    );
  },
};

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbStakeholderForm
 *
 * @description
 * This paints an accordion of stakeholder forms. It takes the standard set of
 * stakeholder field options as to which forms are available. Note that this widget
 * will take no persistent actions, only export what the has filled out in the
 * sub forms. Models will have a `.type`, correlating to type of stakeholder
 * selected, and a `.sh` with everything else. The nature of this `.sh` property
 * depends on the type of stakeholder selected.
 *
 * Possible values for `.type` are:
 *   * `newPerson`
 *   * `existingPerson`
 *   * `newEntity`
 *   * `existingEntity`
 *   * `existingStake`
 *
 * @param {expression} ngModel Model to bind value to.
 * @param {object} sbStakeholderFormStakeholders Stakeholders model object (this
 *    is most likely a `Stakeholders` injected service at a higher context, but
 *    anything that has the same interface will work.
 * @param {object} sbStakeholderFormFieldOptions Standard stakeholder field options
 *    for a MD field.
 */
export const sbStakeholderForm = {
  template: require('./templates/form.html'),
  require: {
    parentFormCtrl: '^?form',
    ngModelCtrl: 'ngModel',
  },
  controllerAs: 'vm',
  bindings: {
    model: '=ngModel',
    name: '@?',
    stakeholders: '<sbStakeholderFormStakeholders',
    options: '<sbStakeholderFormFieldOptions',
  },
  controller: [
    '$scope',
    function ($scope) {
      function showForm(formName) {
        return FORM_VISIBLE_EVALS[formName](
          this.stakeholders.permissions,
          this.options,
        );
      }
      const openOnlyVisibleForm = () => {
        const visibleForms = Object.keys(FORM_VISIBLE_EVALS).filter((key) =>
          this.showForm(key),
        );
        if (visibleForms.length === 1) {
          // We can just automatically show the only form as open and "hide" the
          // accordion with some CSS.
          this.model.type = visibleForms[0];
          this.hideAccordion = true;
        }
      };

      this.$onInit = () => {
        this.model = this.model || {};
        this.name = this.name || 'defaultShForm';
        this.internalModel = {};
        this.errors = {};
        this.showForm = showForm.bind(this);

        $scope.$watch('vm.model.sh', (nv) => {
          const type = this.model.type;
          if (nv && type) {
            this.internalModel[type] = nv;
          }
        });
        $scope.$watch('vm.model.affiliate', (nv) => {
          const type = this.model.type;
          if (nv && type) {
            this.internalModel[type + 'Affiliate'] = {
              addAffiliate: true,
              affiliateName: { title: nv },
            };
          }
        });
        $scope.$watch('vm.internalModel[vm.model.type]', (nv) => {
          this.model.sh = nv;
        });
        const afExpr = 'vm.internalModel[vm.model.type + "Affiliate"]';
        const exportExpr = `${afExpr}.addAffiliate && ${afExpr}.affiliateName.title`;
        $scope.$watch(exportExpr, (nv) => {
          this.model.affiliate = nv ? nv : null;
        });
        this.stakeholders.getForms();
      };

      this.$postLink = () => {
        const getFc = $scope.$watch('shForm', (nv) => {
          if (!nv) {
            return;
          }
          const fc = nv;
          if (this.parentFormCtrl) {
            // Never have the internal form attached.
            this.parentFormCtrl.$removeControl(fc);
          }
          $scope.$watch(
            () => {
              const func = FORM_VALID_EVALS[this.model.type];
              return func ? func.call(this, fc) : false;
            },
            (nv) => {
              this.ngModelCtrl.$setValidity('invalidStakeholder', Boolean(nv));
            },
          );
          getFc();
        });
        const loadingWatch = $scope.$watch('vm.stakeholders.loading', (nv) => {
          if (!nv) {
            loadingWatch();
            openOnlyVisibleForm();
          }
        });
      };
    },
  ],
}; // end sbStakeholderForm

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbAffiliateForm
 *
 * @description
 * This paints an affiliate form on the page. It will internally make sure
 * that the form has the configured labels using the standard stakeholder
 * field options.
 *
 * @param {expression} ngModel Model to bind value to.
 * @param {string} [sbAffiliateFormFor=undefined] This ID will be watched and the
 *    options the user can typeahed to on the affiliateName will change accordingly.
 * @param {object} [sbAffiliateFormOptions=undefined] Standard stakeholder field
 *    options.
 */
function newAffilateFormDefintion() {
  const CHECKBOX_DEFAULT =
    'Investment is through a separate Investment Vehicle ' +
    'or Fund affiliated with the entity above.';
  const PLACEHOLDER_DEFAULT = 'e.g. Hazel Entreprenuers Fund III, L.P.';
  return [
    {
      key: 'addAffiliate',
      type: 'bool-checkbox',
      defaultValue: true,
      data: {},
      templateOptions: {
        label: this.options.affiliateCheckboxLabel || CHECKBOX_DEFAULT,
      },
    },
    {
      key: 'affiliateName',
      type: 'object-typeahead-textline',
      hideExpression: 'model.addAffiliate !== true',
      data: {},
      templateOptions: {
        label: this.options.affiliateLabel || 'Fund Name',
        placeholder: this.options.affiliatePlaceholder || PLACEHOLDER_DEFAULT,
        keyLabel: 'title',
        options: [],
        required: true,
        subfield: 1,
      },
    },
  ];
}
export const sbAffiliateForm = {
  template: require('./templates/affiliate-form.html'),
  require: 'ngModel',
  controllerAs: 'vm',
  bindings: {
    name: '@?',
    model: '=ngModel',
    forEntity: '<?sbAffiliateFormFor',
    options: '<?sbAffiliateFormOptions',
  },
  controller: [
    '$scope',
    'Stakeholders',
    function ($scope, Stakeholders) {
      const updateTypeAhead = (affiliates) => {
        this.internalDefinition[1].templateOptions.options = affiliates;
      };

      this.$onInit = () => {
        this.model = this.model || {};
        this.name = this.name || 'affiliateForm';
        this.options = this.options || {};
        this.errors = {};

        this.internalDefinition = newAffilateFormDefintion.call(this);
        $scope.$watch('vm.forEntity.id', (nv) => {
          if (nv) {
            Stakeholders.getAffiliates(nv).then(updateTypeAhead, () =>
              updateTypeAhead([]),
            );
          }
        });
        $scope.$watch('vm.forEntity.foreign_entity_id', (nv) => {
          if (nv) {
            Stakeholders.getAffiliatesOfEntity(nv).then(updateTypeAhead, () =>
              updateTypeAhead([]),
            );
          }
        });
      };
    },
  ],
};

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbSimilarStakeholders
 *
 * @description
 * Given a stakeholder model to watch, this will show the transcluded content
 * when the similar stakeholders are found.
 *
 * @param {object} stakeholder The representive stakeholder object used for the
 *    similar search.
 *
 * @example
   <sb-similar-stakeholders stakeholder="model.sh">
     <div class="alert alert-warning">
       Found similar to {{ model.sh.fullName }}!
       This will show up model.sh has similar.
     </div>
   </sb-similar-stakeholders>
 */
const SIMILAR_EXPS = ['vm.stakeholder.lastName', 'vm.stakeholder.firstName'];
export const sbSimilarStakeholders = {
  bindings: {
    stakeholder: '<',
  },
  transclude: true,
  controllerAs: 'vm',
  template: '<ng-transclude ng-if="vm.hasSimilar"></ng-transclude>',
  controller: [
    '$scope',
    'Stakeholders',
    'PromiseErrorCatcher',
    function ($scope, Stakeholders, PromiseErrorCatcher) {
      this.$onInit = () => {
        let curReqId = 0;
        const ifMostRecent = (reqId, fn) => (arg) => curReqId - 1 === reqId && fn(arg);

        $scope.$watchGroup(SIMILAR_EXPS, ([lastName, firstName]) => {
          this.hasSimilar = false;
          if (!firstName || !lastName) {
            return;
          }
          const thisRequest = curReqId;
          curReqId += 1;
          Stakeholders.findSimilarPersonStakeholders(firstName, lastName)
            .then(
              ifMostRecent(thisRequest, (foundStakeholders) => {
                // We cannot just match on object, since similar stakeholders
                // returns stakeholder jsons with more keys than this.stakeholder
                foundStakeholders = foundStakeholders.filter(
                  (sh) => sh.id !== this.stakeholder.shId,
                );
                this.hasSimilar = foundStakeholders.length > 0;
              }),
            )
            .catch(PromiseErrorCatcher)
            .finally(
              ifMostRecent(thisRequest, () => {
                curReqId = 0;
              }),
            );
        });
      };
    },
  ],
}; // end sbSimilarStakeholders

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbSameAffiliateWarning
 *
 * @description
 * This paints a small warning when affiliate name and entity name are the same.
 */
const SAME_AFF_EXPRS = [
  'vm.affiliateData.addAffiliate',
  'vm.affiliateData.affiliateName.title',
  'vm.stakeholderName',
];
export const sbSameAffiliateWarning = {
  template: require('./templates/same-affiliate-warning.html'),
  bindings: {
    affiliateData: '<',
    stakeholderName: '<',
  },
  controllerAs: 'vm',
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        $scope.$watchGroup(SAME_AFF_EXPRS, ([af, an, sn]) => {
          this.showWarning = af && an && sn && an.toLowerCase() === sn.toLowerCase();
        });
      };
    },
  ],
}; // end sbSameAffiliateWarning

export const entityFilter = [
  'AppConfig',
  function (AppConfig) {
    // selfId is undefined if not including self
    const selfId = AppConfig.currentEntity.name;
    return (stakeholders, type, includeSelf) => {
      return (stakeholders || {}).filter((sh) => {
        if (includeSelf && sh.foreign_entity_id === selfId) {
          return true;
        }
        if (!includeSelf && sh.foreign_entity_id === selfId) {
          return false;
        }
        if (!type) {
          return true;
        }
        return sh.relation_type === type;
      });
    };
  },
];

const DEF_ADD_AFFILIATE_LABEL =
  'Investment is through a separate Investment ' +
  'Vehicle or Fund affiliated with the entity below.';
const DEF_AFFILIATE_LABEL = 'Fund Name';
const DEF_AFFILIATE_PLACEHOLDER = 'e.g. Hazel Entrepreneurs Fund III, L.P.';
const DEF_ENTITY_NAME_LABEL = 'Entity Name';
function entityNameFields(
  hasAffiliate,
  addAffiliateLabel,
  entityNameLabel,
  entityNamePlaceholder,
  affiliateNamePlaceholder,
  affiliateLabel,
  nameReadOnly,
) {
  return [
    {
      key: 'addAffiliate',
      type: 'bool-checkbox',
      defaultValue: false,
      hideExpression: (!hasAffiliate).toString(),
      data: {},
      templateOptions: {
        label: addAffiliateLabel || DEF_ADD_AFFILIATE_LABEL,
        required: true,
      },
    },
    {
      key: 'entityTitle',
      type: 'string-textline',
      data: {},
      templateOptions: {
        label: entityNameLabel || DEF_ENTITY_NAME_LABEL,
        // We must `||` an empty string since this field is angular string
        // interpolated (we don't want `null` as our placeholder text).
        placeholder: entityNamePlaceholder || '',
        required: true,
        readOnly: nameReadOnly,
      },
    },
    {
      key: 'affiliateTitle',
      type: 'string-textline',
      hideExpression: hasAffiliate ? 'model.addAffiliate !== true' : 'true',
      data: {},
      templateOptions: {
        label: affiliateLabel || DEF_AFFILIATE_LABEL,
        placeholder: affiliateNamePlaceholder || DEF_AFFILIATE_PLACEHOLDER,
        required: true,
      },
    },
  ];
}

export function contactFields(hasContact, requireEmail, doNotCollectEmail) {
  const prefix = hasContact ? 'Contact ' : '';
  const fields = [
    {
      key: 'firstName',
      type: 'string-textline',
      data: {},
      templateOptions: {
        required: true,
        label: prefix + 'First Name',
      },
    },
    {
      key: 'lastName',
      type: 'string-textline',
      data: {},
      templateOptions: {
        required: true,
        label: prefix + 'Last Name',
      },
    },
  ];
  const emailFields = [
    {
      key: 'email',
      type: 'email-textline',
      data: {},
      templateOptions: {
        required: requireEmail,
        label: prefix + 'Email',
      },
    },
    {
      key: 'email2',
      type: 'email-textline',
      data: {},
      templateOptions: {
        confirmOf: ['email'],
        required: requireEmail,
        label: `Confirm ${prefix}Email`,
      },
    },
  ];
  return doNotCollectEmail ? fields : fields.concat(emailFields);
}

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbStakeholderCreationForm
 *
 * @description
 * This paints a stakeholder creation form. Note that this widget will take
 * no persitent actions, only export what the has filled out in the
 * sub forms. Models will have a `.type`, correlatiing to type of stakeholder
 * selected, and a `.sh` with everything else. The nature of this `.sh` property
 * depends on the type of stakeholder selected.
 *
 * Possible values for `.type` are:
 *   * `person`
 *   * `team`
 *
 * @param {expression} ngModel Model to bind value to.
 * @param {object} options Standard stakeholder field options.
 *
 * @event {StakeholderCreationForm::setSubmitted} When this is emitted, this
 *    directive will set all of the child forms as submitted.
 */
const DEF_TEAM_FORM_TITLE =
  'This user is an <strong>entity</strong> (e.g. firm, family trust, fund).';
const DEF_PERSON_FORM_TITLE = 'This user is a <strong>person</strong>.';
export const sbStakeholderCreationForm = {
  controllerAs: 'vm',
  template: require('./templates/creation-form.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    options: '<',
    teamSeed: '<?',
    personSeed: '<?',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        const { options } = this;
        const {
          allowPerson,
          allowEntity,
          allowStakes,
          entityOptions,
          requireEmail,
          forceEphemeral,
        } = options;
        this.allowStakes = allowStakes;
        this.model = this.model || {};
        this.errors = {};
        this.internalModel = {
          person: Object.assign({}, this.personSeed || {}),
          team: Object.assign({}, this.teamSeed || {}),
        };

        this.PERSON_CONTACT_FIELDS = contactFields(false, requireEmail, forceEphemeral);
        this.ENTITY_CONTACT_FIELDS = allowStakes
          ? []
          : contactFields(true, requireEmail, forceEphemeral, false);
        this.ENTITY_NAME_FORM_FIELDS = entityNameFields(
          Boolean(entityOptions.affiliates),
          options.affiliateCheckboxLabel,
          options.creationModalEntityNameLabel,
          options.creationModalEntityNamePlaceholder,
          options.affiliatePlaceholder,
          options.affiliateLabel,
          this.internalModel.team.readOnly,
        );

        this.TEAM_TITLE = options.creationModalTeamLabel || DEF_TEAM_FORM_TITLE;
        this.PERSON_TITLE =
          options.creationModalIndividualLabel || DEF_PERSON_FORM_TITLE;

        const single = Boolean(allowEntity ? !allowPerson : allowPerson);
        this.SINGLE_OPTION = single;
        if (single && allowPerson) {
          this.model.type = 'person';
        } else if (single && allowEntity) {
          this.model.type = 'team';
        }
        const setShModelFromType = (nv) => {
          this.model.sh = this.internalModel[nv];
        };
        if (single) {
          setShModelFromType(this.model.type);
        } else {
          $scope.$watch('vm.model.type', setShModelFromType);
        }
      };

      this.$postLink = () => {
        const { mainForm } = this;
        $scope.$on('StakeholderCreationForm::setSubmitted', () => {
          ['formly_personForm', 'formly_teamNameForm', 'formly_teamContactForm']
            .map((name) => mainForm[name])
            .filter(angular.identity)
            .forEach((frm) => {
              frm.$setSubmitted();
            });
        });
        $scope.$watch(
          () => {
            const { model } = this;
            switch (model.type) {
              case 'person':
                return mainForm.formly_personForm && mainForm.formly_personForm.$valid;
              case 'team': {
                const contactValid = this.allowStakes
                  ? mainForm.formly_teamContactForm &&
                    mainForm.formly_teamContactForm.$valid
                  : true;
                return (
                  contactValid &&
                  mainForm.formly_teamNameForm &&
                  mainForm.formly_teamNameForm.$valid
                );
              }
            }
            return false; // Invlaid if no type
          },
          (nv) => {
            this.ngModelCtrl.$setValidity('invalidStakeholder', Boolean(nv));
          },
        );
      };
    },
  ],
};
