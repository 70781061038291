import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

const ADD_SECTION_FIELDS = (anchors, locations) => [
  {
    key: 'anchor',
    data: {},
    type: 'enum-dropdown',
    templateOptions: {
      required: true,
      label: 'Section Anchor',
      enumVocab: anchors,
    },
  },
  {
    type: 'dynamic-enum-dropdown',
    templateOptions: {
      subfield: 1,
      required: true,
      label: 'Location',
      enumVocab: locations,
      key: 'anchor',
    },
    data: {},
    key: 'position',
    defaultValue: 'after',
  },
  {
    type: 'string-textline',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'Section Title',
    },
    data: {},
    key: 'title',
    defaultValue: '',
  },
  {
    type: 'bool-checkbox',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'This is a numbered section.',
    },
    data: {},
    key: 'count',
    defaultValue: true,
  },
];

export const createDocxForm = {
  controllerAs: 'vm',
  template: require('./templates/create-docx-form.html'),
  bindings: {
    actors: '<',
  },
  controller: [
    '$scope',
    '$sbModal',
    '$uuid',
    'SimpleHTTPWrapper',
    'PromiseErrorCatcher',
    'PDFFormCreationModel',
    '$formModal',
    '$confirm',
    'BackendLocation',
    'ProcessUrlInfo',
    'DebounceMaker',
    '$observable',
    'ProcessButtonModel',
    class {
      constructor(
        $scope,
        $sbModal,
        $uuid,
        SimpleHTTPWrapper,
        PromiseErrorCatcher,
        PDFFormCreationModel,
        $formModal,
        $confirm,
        BackendLocation,
        ProcessUrlInfo,
        DebounceMaker,
        $observable,
        ProcessButtonModel,
      ) {
        // Injected stuff
        this.$scope = $scope;
        this.$sbModal = $sbModal;
        this.$uuid = $uuid;
        this.SimpleHTTPWrapper = SimpleHTTPWrapper;
        this.PromiseErrorCatcher = PromiseErrorCatcher;
        this.PDFFormCreationModel = PDFFormCreationModel;
        this.$confirm = $confirm;
        this.$formModal = $formModal;
        this.BackendLocation = BackendLocation;
        this.ProcessUrlInfo = ProcessUrlInfo;
        this.$observable = $observable;
        this.ProcessButtonModel = ProcessButtonModel;

        // Variables
        const baseWiUrl = `${this.BackendLocation.context(
          2,
        )}workitems/${this.ProcessUrlInfo.wiId()}`;
        this.url = `${baseWiUrl}/definetexttemplate/data`;
        this.sections = null;
        this.schema = null;
        this.model = null;
        this.fields$ = new BehaviorSubject({});
        this.references$ = new BehaviorSubject({});
        this.lastSave = null;

        // Bindings
        this.$onInit = this.$onInit.bind(this);
        this.addSection = this.addSection.bind(this);
        this.removeSection = this.removeSection.bind(this);
        const debounce = DebounceMaker();
        const boundSave = this.save.bind(this);
        this.debouncedSave = () => debounce(() => boundSave(), 5000);
        this.save = boundSave;
      }

      $onInit() {
        this.model = this.PDFFormCreationModel(this.actors[0].value, this.url);
        this.model.init().then((data) => {
          this.sections = data.sections;
          this.schema = data.schema;

          this.references$.next(this.buildReferences(data.sections));

          const field$ = this.$observable
            .fromWatcher(this.$scope, () => this.model.fields, true)
            .pipe(map((field) => field?.newValue));
          this.$observable
            .combineLatest(field$, (fields) =>
              fields.reduce(
                (accum, field) => {
                  accum.fields.push(field);
                  accum.invalid = accum.invalid || field.formModel.invalid;
                  return accum;
                },
                { fields: [], invalid: false },
              ),
            )
            .$applySubscribe(this.$scope, (newValue) => {
              if (newValue.invalid === false) {
                const mappedFields = this.model.fields.map((field) => {
                  return {
                    value: `${this.schema}.${field.get('id')}`,
                    label: field.get('formModel').title,
                  };
                });

                const mappedTerms = this.model.fields.reduce((acc, field) => {
                  acc[`${this.schema}.${field.get('id')}`] = {
                    value: null,
                    title: field.get('formModel').title,
                    type: field.get('type'),
                    formats: [
                      {
                        format: 'default',
                        title: 'Default',
                        value: null,
                      },
                    ],
                  };

                  return acc;
                }, {});

                this.fields$.next({
                  fields: mappedFields,
                  terms: mappedTerms,
                  schema: this.schema,
                });
              }
            });
        });

        this.ProcessButtonModel.$addSubmitCondition('continue', async () => {
          this.ProcessButtonModel.disable();

          try {
            await this.save();
            return Promise.resolve();
          } catch (e) {
            return Promise.reject();
          } finally {
            this.ProcessButtonModel.requestEnable();
          }
        });
      }

      addSection(sectionToEdit) {
        const anchors = this.sections.map((section) => ({
          value: section.name,
          label: section.title,
        }));

        const locations = this.sections.reduce((acc, section) => {
          acc[section.name] = {
            values: ['before', 'after', 'child'],
            labels: ['Before Anchor Section', 'After Anchor Section', 'In Section'],
          };

          return acc;
        }, {});

        const sectionToEditFormData = {};
        if (sectionToEdit) {
          const sectionToEditIndex = this.sections.findIndex(
            (s) => s.name === sectionToEdit.name,
          );
          const anchor =
            this.sections[sectionToEditIndex === 0 ? 1 : sectionToEditIndex - 1].name;
          const childOrAfter =
            sectionToEdit.level > this.sections[sectionToEditIndex - 1].level
              ? 'child'
              : 'after';
          const position = sectionToEditIndex === 0 ? 'before' : childOrAfter;

          sectionToEditFormData.anchor = anchor;
          sectionToEditFormData.position = position;
          sectionToEditFormData.title = sectionToEdit.title;
          sectionToEditFormData.count = sectionToEdit.count;
        }

        this.$formModal(
          {
            title: `${sectionToEdit ? 'Edit' : 'Add'} section`,
            primaryButtonText: `${sectionToEdit ? 'Update' : 'Add'}`,
            forms: { form: { fields: ADD_SECTION_FIELDS(anchors, locations) } },
            formData: { form: sectionToEditFormData },
            htmlContent: require('./templates/add-section.html'),
            onConfirmPromise({ $formData }) {
              return $formData;
            },
          },
          'md',
        )
          .then(({ form }) => {
            let id = this.$uuid();
            let content = '';
            // If we are editing section, lets just remove it and add a new one (but keep track of id)
            if (sectionToEdit) {
              const sectionToRemoveIndex = this.sections.findIndex(
                (s) => s.name === sectionToEdit.name,
              );
              const removedSection = this.sections.splice(sectionToRemoveIndex, 1);
              id = removedSection[0].name;
              content = removedSection[0].content;
            }

            // Add new section
            const section = this.sections.find((s) => s.name === form.anchor);
            const sectionIndex = this.sections.findIndex((s) => s.name === form.anchor);

            const newSection = {
              content,
              count: form.count,
              level: section.level + (form.position === 'child' ? 1 : 0),
              name: id,
              ref: '',
              singleRef: '',
              terms: [],
              title: form.title,
            };
            const newSectionIndex = sectionIndex + (form.position === 'before' ? 0 : 1);

            this.sections.splice(newSectionIndex, 0, newSection);
            this.save();
          })
          .catch(this.PromiseErrorCatcher);
      }

      removeSection(section) {
        this.$confirm({
          body: `Are you sure you want to remove ${section.title} section?`,
          alertType: 'warning',
          confirmButtonText: 'Yes',
          dismissButtonText: 'No',
        })
          .then(() => {
            this.sections = this.sections.filter((s) => s.name !== section.name);
            this.save();
          })
          .catch(this.PromiseErrorCatcher);
      }

      buildReferences(sections) {
        return sections
          .filter((section) => section.count)
          .map((section) => {
            const ref = section.ref === null ? '' : `${section.ref} - `;
            const indent = Array(section.level + 1).join('-- ');

            return {
              label: `${indent}${ref}${section.title}`,
              value: section.name,
              ref: section.ref,
              singleRef: section.singleRef,
              // This title is used in tooltip
              title: `${section.ref} - ${section.title}`,
            };
          });
      }

      save(data) {
        return this.SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: this.url,
            data: data
              ? data
              : {
                  sections: this.sections,
                  fields: this.model._serializeFields(this.model.fields),
                },
          },
          'Could not save template information.',
        ).then((data) => {
          this.sections = data.sections;
          this.references$.next(this.buildReferences(data.sections));
          this.schema = data.schema;
          this.model._deserializeFields(data.fields);

          const time = new moment();
          this.lastSave = time.format('HH:mm:ss');
        });
      }

      stopAccordion(e) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
  ],
};
