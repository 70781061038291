import angular from 'angular';
/**
 * @ngdoc object
 * @name sb.lib.relationships.controller:RelationshipPermissionsModalCtrl
 *
 * @description
 * Controller for the modal that a pops up to edit/add a new member to a
 * relationship.
 *
 * Options is an object with these properties:
 *
 *   * allowedFolderTree: tree of folders that are allowed to be granted
 *   * stakeholderOptions: Options for stakeholder selection form, used to
 *                         add new member.
 *   * canGiveCreate: user is allowed to grant other users the Create Stakeholder
 *                    options
 *
 * Persist is an object with these methods:
 *
 *   * editMember(member) - method to update member data
 *   * addMember(member) - method to add new member to the team
 */
export const RelationshipPermissionsModalCtrl = [
  '$scope',
  '$q',
  'FormSubmitResolve',
  'Member',
  'Persist',
  'Stakeholders',
  'Title',
  'AccessSuppressed',
  'Options',
  function (
    $scope,
    $q,
    FormSubmitResolve,
    Member,
    Persist,
    Stakeholders,
    Title,
    AccessSuppressed,
    Options,
  ) {
    function saveMember() {
      $scope.$error = null;

      resolver
        .resolve()
        .then(() => {
          const member = $scope.member;
          const type = $scope.typeModel.value;
          const primary = type === 'primary';

          const memberData = {
            type: member.type,
            manager: primary || type === 'manager' || type === 'manager-full',
            fullManager: primary || type === 'manager-full',
            primary: primary,
            canAdd: member.canAdd,
            stakeholder: member.stakeholder,
            permissions: member.permissions,
            isPermanentManager: member.isPermanentManager,
            restrictedFolders: member.restrictedFolders,
            isSigner: member.isSigner,
            sigTitle: member.sigTitle,
            sigByline: member.sigByline,
          };

          if ($scope.isEdit) {
            return Persist.editMember(memberData);
          }
          return Persist.addMember(memberData);
        })
        .then(() => {
          $scope.$close();
        })
        .catch((error) => {
          $scope.$error = error || '';
        });
    }

    function isHiddenAccess(member, value) {
      return (
        member.permissions &&
        member.permissions.length &&
        !$scope.accessSuppressed &&
        value !== 'primary'
      );
    }
    const resolver = FormSubmitResolve($scope, $scope.formFeedback);
    $scope.member = Member || { permissions: $scope.permissions };
    $scope.permissions = $scope.member.permissions;
    $scope.isEdit = Boolean(
      $scope.member.stakeholder &&
        $scope.member.stakeholder.sh &&
        $scope.member.stakeholder.sh.id,
    );
    $scope.persist = Persist;
    $scope.saveMember = saveMember;
    $scope.isHiddenAccess = isHiddenAccess;
    $scope.title = Title;
    $scope.errors = {};
    $scope.accessSuppressed = AccessSuppressed;
    $scope.shmodel = Stakeholders;
    $scope.options = Options;

    $scope.isInvalidStakeholder = () => {
      const { member } = $scope.selectorForm || {};
      if (!member || member.$invalid) {
        return true;
      }
      const { stakeholder } = $scope.member;
      if (stakeholder.type.startsWith('new')) {
        return false;
      }
      const { id } = stakeholder;
      return (Persist.$members || []).reduce(
        (accum, member) => accum || member.stakeholder.sh.id === id,
        false,
      );
    };

    if ($scope.member.primary) {
      $scope.typeModel = { value: 'primary' };
    } else if ($scope.member.fullManager) {
      $scope.typeModel = { value: 'manager-full' };
    } else if ($scope.member.manager) {
      $scope.typeModel = { value: 'manager' };
    } else {
      $scope.typeModel = { value: 'member' };
    }

    $scope.change = function (id, value) {
      const folders = $scope.member.restrictedFolders;
      const idIndex = folders.indexOf(id);
      if (!value && idIndex > -1) {
        folders.splice(idIndex, 1);
      } else if (value && idIndex === -1) {
        folders.push(id);
      }
      updateSelectedLevels();
    };

    const updateSelectedLevels = () => {
      const folders = $scope.member.restrictedFolders || [];
      $scope.selectedLevels = folders.reduce((ob, id) => {
        const level = $scope.options.allowedFolderTree.find((i) => i.id === id);
        ob[id] = Object.assign({ level: null }, $scope.shareable(level), level);
        return ob;
      }, {});
    };

    $scope.isChecked = (id) => {
      const folders = $scope.member.restrictedFolders;
      return folders && folders.indexOf(id) > -1;
    };

    const allowedTree = $scope.options.allowedFolderTree;
    $scope.canEditDocumentAccess = allowedTree && allowedTree.length > 0;

    $scope.shareable = (level) => {
      const shareables = new Map(
        $scope.options.allowedFolderGrants.map((fld) => [fld.id, fld]),
      );
      const levels = new Map(
        $scope.options.allowedFolderTree.map((lvl) => [lvl.id, lvl]),
      );

      while (level) {
        if (shareables.has(level.id)) {
          return shareables.get(level.id);
        }
        level = levels.get(level.parent);
      }
      return undefined;
    };

    updateSelectedLevels();

    $scope.openNodes = Object.keys($scope.selectedLevels);
  },
]; // end RelationshipPermissionsModalCtrl

/**
 * @ngdoc object
 * @name sb.lib.relationships.PermissionsBehavior
 *
 * @description
 * This function augments relationship directives with common functionality.
 */
export const PermissionsBehavior = [
  '$injector',
  function ($injector) {
    const behavior = [
      '$scope',
      '$sbModal',
      'SimpleHTTPWrapper',
      'Stakeholders',
      'PromiseErrorCatcher',
      'persist',
      'AccessMember',
      'DocumentMember',
      'GetModalOptions',
      function (
        $scope,
        $sbModal,
        SimpleHTTPWrapper,
        Stakeholders,
        PromiseErrorCatcher,
        persist,
        AccessMember,
        DocumentMember,
        GetModalOptions,
      ) {
        function editAccess(member) {
          $sbModal
            .open({
              windowClass: 'add-team-member-modal',
              // One has multiple steps, the other just the permissions.
              template: GetModalOptions().editTeamRole
                ? require('./templates/permissions-modal.html')
                : require('./templates/target-permissions-modal.html'),
              controller: 'RelationshipPermissionsModalCtrl',
              resolve: {
                Member: AccessMember(member),
                StakeholdersPop: () => Stakeholders.populate(),
                Persist: function () {
                  return persist;
                },
                Title: function () {
                  return member ? 'Modify Role' : 'Permissions: Access';
                },
                AccessSuppressed: function () {
                  return $scope.accessSuppressed();
                },
                Options: GetModalOptions,
              },
            })
            .result.catch(PromiseErrorCatcher);
        }
        function editPermissions(member) {
          $sbModal
            .open({
              windowClass: 'add-team-member-modal',
              // One has multiple steps, the other just the permissions.
              template: require('./templates/target-permissions-modal.html'),
              controller: 'RelationshipPermissionsModalCtrl',
              resolve: {
                Member: AccessMember(member),
                StakeholdersPop: () => Stakeholders.populate(),
                Persist: function () {
                  return persist;
                },
                Title: function () {
                  return 'Modify Permissions';
                },
                AccessSuppressed: function () {
                  return $scope.accessSuppressed();
                },
                Options: GetModalOptions,
              },
            })
            .result.catch(PromiseErrorCatcher);
        }
        function editSignatureBlock(member) {
          $sbModal
            .open({
              windowClass: 'add-team-member-modal',
              // One has multiple steps, the other just the permissions.
              template: require('./templates/edit-signature-block.html'),
              controller: 'RelationshipPermissionsModalCtrl',
              resolve: {
                Member: AccessMember(member),
                StakeholdersPop: () => Stakeholders.populate(),
                Persist: function () {
                  return persist;
                },
                Title: function () {
                  return `Edit Signature Block for ${member.stakeholder.sh.fullName}`;
                },
                AccessSuppressed: function () {
                  return $scope.accessSuppressed();
                },
                Options: GetModalOptions,
              },
            })
            .result.catch(PromiseErrorCatcher);
        }
        function addMember(member) {
          const url = `${this.sourceTeamModel.$$url}forms/securityticket`;
          SimpleHTTPWrapper({ method: 'GET', url }).then(({ securityTicketID }) => {
            $sbModal
              .open({
                windowClass: 'add-team-member-modal',
                template: require('./templates/add-team-member-modal.html'),
                controller: 'RelationshipPermissionsModalCtrl',
                resolve: {
                  Member: DocumentMember(member),
                  StakeholdersPop: () =>
                    Stakeholders.populate(true, undefined, securityTicketID),
                  Persist: () => persist,
                  Title: () => 'Modify Access',
                  AccessSuppressed: () => $scope.accessSuppressed(),
                  Options: GetModalOptions,
                },
              })
              .result.catch(PromiseErrorCatcher);
          });
        }
        function editDocumentAccess(member) {
          $sbModal
            .open({
              template: require('./templates/document-access-modal.html'),
              controller: 'RelationshipPermissionsModalCtrl',
              resolve: {
                Member: DocumentMember(member),
                StakeholdersPop: () => Stakeholders.populate(),
                Persist: function () {
                  return persist;
                },
                Title: function () {
                  return 'Modify Document Access';
                },
                AccessSuppressed: function () {
                  return $scope.accessSuppressed();
                },
                Options: GetModalOptions,
              },
            })
            .result.catch(PromiseErrorCatcher);
        }

        $scope.persist = persist;
        $scope.editAccess = editAccess;
        $scope.addMember = addMember;
        $scope.editDocumentAccess = editDocumentAccess;
        $scope.editSignatureBlock = editSignatureBlock;
        $scope.editPermissions = editPermissions;
      },
    ];

    return function (
      passedScope,
      persist,
      accessMember,
      documentMember,
      getModalOptions,
    ) {
      $injector.invoke(behavior, null, {
        persist: persist,
        $scope: passedScope,
        AccessMember: accessMember,
        DocumentMember: documentMember,
        GetModalOptions: getModalOptions,
      });
    };
  },
]; // end PermissionsBehavior

/**
 * @ngdoc directive
 * @name sb.lib.relationships.directive:sbTargetRelationshipPermissions
 * @restrict EA
 *
 * @description
 * This directive is used to display permissions inside a targeted entity
 * about the source. It also has edit functions built in.
 *
 * @element ANY
 * @param {template} sbTargetRelationshipPermissionsSourceName ID name of
 *    source entity.
 * @param {template} sbTargetRelationshipPermissionsType Type of target
 *    relationships.
 * @param {boolean} sbTargetRelationshipPermissionsCanEdit True if this user
 *    sees write operations.
 * @param {boolean} sbTargetRelationshipDiligenceCanEdit True if this user
 *    can edit diligence mode.
 * @param {boolean} sbTargetRelationshipPermissionsAccessSuppressed True if
 *    this users access is surpressed.
 *
 * @example
   <sb-target-relationship-permissions
     data-sb-target-relationship-permissions-source-name="law_firm"
     data-sb-target-relationship-permissions-type="legal"
     data-sb-target-relationship-permissions-can-edit="true"
     data-sb-target-relationship-diligence-can-edit="true"
     data-sb-target-relationship-permissions-access-suppressed="false">
   </sb-target-relationship-permissions>
 */
export function sbTargetRelationshipPermissions() {
  return {
    restrict: 'EA',
    template: require('./templates/target-relationship-permissions.html'),
    scope: {
      stakeholderId: '@sbTargetRelationshipShId',
      targetModel: '=sbTargetRelationshipModel',
      canEdit: '&sbTargetRelationshipPermissionsCanEdit',
      canEditDiligence: '&sbTargetRelationshipDiligenceCanEdit',
      accessSuppressed: '&sbTargetRelationshipPermissionsAccessSuppressed',
    },
    controller: [
      '$scope',
      '$rootScope',
      'PermissionsBehavior',
      'AppConfig',
      '$dataroomIndexModal',
      'PromiseErrorCatcher',
      function (
        $scope,
        $rootScope,
        PermissionsBehavior,
        AppConfig,
        $dataroomIndexModal,
        PromiseErrorCatcher,
      ) {
        function accessMember() {
          return function () {
            return angular.copy({
              stakeholder: { sh: { id: 'TeamStakeholder' } },
              permissions: $scope.targetModel.$permissions,
            });
          };
        }

        function documentMember() {
          return function () {
            return angular.copy({
              stakeholder: { sh: { id: 'TeamStakeholder' } },
              tags: $scope.targetModel.$tags,
            });
          };
        }

        const getModalOptions = () => {
          return {
            allowedFolderTree: $scope.targetModel.$allowedFolderTree,
          };
        };

        const persist = {
          editMember: (member) => {
            return $scope.targetModel.$edit(member);
          },
          addMember: () => {
            throw new Error(
              'addMember makes no sense in sbTargetRelationshipPermissions',
            );
          },
        };

        $scope.reload = () => {
          $scope.targetModel.$init();
        };

        $scope.exportDataroomIndex = () => {
          // on current entity
          const dataroomOnEntity = null;
          // for team stakeholder
          const dataroomForSh = $scope.stakeholderId;
          const relationshipType = $scope.targetModel.$relationType;
          const email =
            AppConfig.userProfile.email || AppConfig.userProfile.contactEmail;
          $dataroomIndexModal(email, dataroomOnEntity, dataroomForSh, relationshipType)
            .then(() => {
              const reason = 'A link to your index will be sent to you shortly.';
              $rootScope.errorMsg = reason;
              $rootScope.errorType = 'success';
            })
            .catch(PromiseErrorCatcher);
        };

        PermissionsBehavior(
          $scope,
          persist,
          accessMember,
          documentMember,
          getModalOptions,
        );
        $scope.model = $scope.targetModel;
        $scope.baseName = AppConfig.currentEntity.title;
      },
    ],
  };
} // end sbTargetRelationshipPermissions

/**
 *  @ngdoc function
 *  @name selectAllPermissions
 *  @description
 *  Helper function that makes all the allowed permissions selected.
 *  @param {array} permissions Array of available permissions.
 */
function selectAllPermissions(permissions) {
  const selectAll = (perm) => {
    perm.selected = !perm.disabled;
    angular.forEach(perm.children, selectAll);
  };
  angular.forEach(permissions, selectAll);
}

/**
 *  @ngdoc function
 *  @name getAvailablePermissions
 *  @description
 *  Helper function that returns a list of permissions that are not disabled.
 *  @param {array} permissions Array of all permissions.
 */
function getAvailablePermissions(permissions) {
  let result = [];
  angular.forEach(permissions, (p) => {
    if (!p.disabled) {
      result.push(p);
    }
    result = result.concat(getAvailablePermissions(p.children));
  });
  return result;
}

/**
 *  @ngdoc function
 *  @name getAllRootFolderIds
 *  @description
 *  Helper function that returns ids of all accessible folders that don't have
 *  an accessible parent.
 */
function getAllRootFolderIds(allowedFolderTree) {
  const levels = new Map(allowedFolderTree.map((lvl) => [lvl.id, lvl]));
  const findRoot = (level) => {
    if (level && level.parent && !levels.get(level.parent).disabled) {
      return findRoot(levels.get(level.parent));
    }
    return level;
  };
  const result = [];
  angular.forEach(allowedFolderTree, (level) => {
    const root = findRoot(level);
    if (root && result.indexOf(root.id) === -1) {
      result.push(root.id);
    }
  });
  return result;
}

/**
 * @ngdoc directive
 * @name sb.lib.relationships.directive:sbSourceRelationshipPermissionsTable
 * @restrict EA
 *
 * @description
 * This directive is used to display a table of team members and their access
 * inside a source relationship.
 *
 * @element ANY
 * @param {template} sbSourceRelationshipPermissionsTableTargetName Name ID of
 *    target entity.
 * @param {template} sbSourceRelationshipPermissionsTableType Type of target
 *    relationship.
 * @param {boolean} sbSourceRelationshipPermissionsTableCanEdit True if user
 *    sees write operations.
 * @param {boolean} sbSourceRelationshipPermissionsTableAccessSuppressed True
 *    if this users access is surpressed.
 *
 * @example
   <sb-source-relationship-permissions-table
     data-sb-source-relationship-permissions-table-target-name="{{ ::targetName }}"
     data-sb-source-relationship-permissions-table-type="{{ ::type }}"
     data-sb-source-relationship-permissions-table-can-edit="canEdit"
     data-sb-source-relationship-permissions-table-access-suppressed="suppressed">
   </sb-source-relationship-permissions-table>
 */
export function sbSourceRelationshipPermissionsTable() {
  return {
    restrict: 'EA',
    template: require('./templates/source-permissions-table.html'),
    scope: {
      model: '=sbSourceRelationshipPermissionsTableModel',
      canEdit: '&sbSourceRelationshipPermissionsTableCanEdit',
      accessSuppressed: '&sbSourceRelationshipPermissionsTableAccessSuppressed',
      foreignManaged: '<sbSrptForeignManaged',
      onModelUpdate: '&',
    },
    controller: [
      '$scope',
      '$q',
      'PermissionsBehavior',
      'Stakeholders',
      'BackendLocation',
      'SimpleHTTPWrapper',
      function (
        $scope,
        $q,
        PermissionsBehavior,
        Stakeholders,
        BackendLocation,
        SimpleHTTPWrapper,
      ) {
        function editMember(member) {
          return function () {
            return angular.copy(member);
          };
        }
        function removeTeamMember(member) {
          $scope.model.$remove(member);
        }
        function toggleSigner(member) {
          member.isPermanentManager = !member.$isPermanentManager;
          const isEdit = Boolean(
            member.stakeholder && member.stakeholder.sh && member.stakeholder.sh.id,
          );
          if (isEdit) {
            $scope.persist.editMember(member);
          } else {
            $scope.addMember(member);
          }
        }
        function addTeamMember() {
          // When adding new team member, pre-select existing person
          const member = {
            stakeholder: {
              type: $scope.foreignManaged === false ? 'newPerson' : 'existingPerson',
            },
            fullManager: true,
            permissions: angular.copy($scope.model.$settings.permissions),
            permissionsAvailable: getAvailablePermissions(
              $scope.model.$settings.permissions,
            ),
            tags: angular.copy($scope.model.$settings.tags),
            restrictedFolders: getAllRootFolderIds($scope.model.$allowedFolderTree),
          };
          selectAllPermissions(member.permissions);

          $scope.addMember(member);
        }
        function canEdit(member) {
          return (
            isEditable(member) ||
            isDREditable(member) ||
            isRemovable(member) ||
            isInvitable(member) ||
            isSignatureEditable(member) ||
            canToggleSignatory(member)
          );
        }
        function isSignatureEditable(member) {
          return $scope.canEdit() && member.$isPermanentManager;
        }
        function isPermissionsEditable(member) {
          return (
            $scope.canEdit() &&
            !$scope.accessSuppressed() &&
            !(member.$isPrimary || member.$isFullManager)
          );
        }
        function isEditable(member) {
          return $scope.canEdit() && !$scope.accessSuppressed() && !member.$isPrimary;
        }
        function isDREditable(member) {
          return (
            $scope.canEdit() && !$scope.accessSuppressed() && !member.$isFullManager
          );
        }
        function isInvitable(member) {
          return (
            member.stakeholder.sh.approvalStatus === 'approved' &&
            !member.stakeholder.sh.isActivated
          );
        }
        function isRemovable(member) {
          // Probably should check for only member.
          return isEditable(member) && !member.$isOnlySigner && !isOnlyMember(member);
        }

        function isOnlyMember(member) {
          const team = $scope.sourceTeamModel.$members;
          return team.length === 1 && team[0] === member;
        }

        function canToggleSignatory(member) {
          const team = $scope.sourceTeamModel.$members;
          const foreignPendingApproval = $scope.foreignManaged && member.pending;
          const signers = team.filter((x) => x.$isPermanentManager);
          const isOnlyTeamSigner = signers.length === 1 && signers[0] === member;
          return (
            $scope.model.$relationType !== 'legal' &&
            !isOnlyTeamSigner &&
            !foreignPendingApproval &&
            !$scope.sourceTeamModel.$fixedSigners
          );
        }

        function sendReminderEmail(member) {
          const url = BackendLocation.context(1) + 'permissions/invite_relation';
          const data = Object.assign({
            id: member.stakeholder.sh.id,
            from: 'target',
          });

          return SimpleHTTPWrapper(
            {
              method: 'POST',
              url,
              data,
            },
            'Failed to send user reminder.',
          ).then(
            (data) => {
              const $sent = Boolean(data.emailSent);
              /* The backend can return quite a few custom status possibilities now.
               We will also provide a fallback, though. */
              const $genericStatus = $sent ? 'Reminder sent.' : 'Reminder pending.';
              $scope.statusMsg = data.status ? data.status : $genericStatus;
              $scope.banner = true;
            },
            (error) => {
              $scope.errorMsg = error;
            },
          );
        }

        $scope.model.$onModelUpdate(() => {
          $scope.onModelUpdate({ $model: $scope.model });
        });

        const getModalOptions = () => {
          const stakeholderOptions = $scope.foreignManaged
            ? $scope.model.$settings.stakeholderOptions
            : {
                allowCreation: true,
                allowEntity: false,
                allowExisting: false,
                allowPerson: true,
                allowGuest: true,
                createGuest: true,
              };
          return {
            allowedFolderTree: $scope.model.$allowedFolderTree,
            allowedFolderGrants: $scope.model.$allowedFolderGrants,
            stakeholderOptions: stakeholderOptions,
            canGiveCreate: $scope.foreignManaged
              ? Boolean($scope.model.$canGiveCreate)
              : false,
            canModifyPrimary: Boolean($scope.model.$canModifyPrimary),
            hasVerifiedDomain: Boolean($scope.model.$hasVerifiedDomain),
            editTeamRole: true,
          };
        };

        const persist = {
          editMember: (member) => {
            return $scope.model.$edit(member);
          },
          addMember: (member) => {
            return $q((resolve, reject) => {
              if (member.stakeholder.type !== 'newPerson') {
                // We are adding existing stakeholder, we can just add it to a
                // team.
                resolve(member);
                return;
              }

              // We are asked to create new stakeholder to a team. First, create
              // stakeholder itself, then add it to a team.
              const createData = {
                description: member.stakeholder.sh,
                type: 'person',
              };

              const modalOptions = getModalOptions();
              if (modalOptions.stakeholderOptions.limitCreateDomains) {
                return SimpleHTTPWrapper({
                  method: 'POST',
                  url: BackendLocation.entity(1) + 'stakeholders/createWithDomain',
                  data: createData,
                })
                  .then((sh) => {
                    // After stakeholder has been created, pretend we are adding team
                    // member for existing stakeholder
                    member.stakeholder = { sh: sh, type: 'existingPerson' };
                    resolve(member);
                  })
                  .catch(reject);
              }

              return Stakeholders.create(
                createData,
                null,
                null,
                null,
                null,
                !$scope.foreignManaged,
              ).then((sh) => {
                // After stakeholder has been created, pretend we are adding team
                // member for existing stakeholder
                member.stakeholder = { sh: sh, type: 'existingPerson' };
                resolve(member);
              });
            }).then((member) => {
              return $scope.model.$add(member);
            });
          },
        };

        PermissionsBehavior($scope, persist, editMember, editMember, getModalOptions);

        $scope.addTeamMember = addTeamMember;

        $scope.sourceTeamModel = $scope.model;
        $scope.fundModel = $scope.model;

        $scope.banner = false;
        $scope.statusMsg = '';

        $scope.teamTableControls = {
          canEdit: canEdit,
          isEditable: isEditable,
          isDREditable: isDREditable,
          isInvitable: isInvitable,
          isRemovable: isRemovable,
          canToggleSignatory: canToggleSignatory,
          toggleSigner: toggleSigner,
          isSignatureEditable: isSignatureEditable,
          isPermissionsEditable: isPermissionsEditable,
          sendReminderEmail: sendReminderEmail,
          accessSuppressed: $scope.accessSuppressed,
          removeTeamMember: removeTeamMember,
          editAccess: $scope.editAccess,
          editDocumentAccess: $scope.editDocumentAccess,
          editPermissions: $scope.editPermissions,
          editSignatureBlock: $scope.editSignatureBlock,
          statusMsg: $scope.statusMsg,
          errorMsg: $scope.errorMsg,
          banner: $scope.banner,
        };
      },
    ],
  };
} // end sbSourceRelationshipPermissionsTable

/**
 * @ngdoc directive
 * @name sb.lib.relationships.directive:sbTargetRelationshipSourceTeam
 * @restrict E
 *
 * @description
 * This directive is used to display source team on a target relationship page.
 *
 * @element ANY
 *
 * @param {boolean} sbTargetRelationshipSourceTeamCanEdit True if user
 *    sees write operations.
 *
 * @example
    <sb-target-relationship-source-team
      sb-target-relationship-source-team-model="{{ targetModel.sourceTeam }}"
      data-sb-target-relationship-source-team-can-edit="canEdit">
    </sb-target-relationship-source-team>
 */
export function sbTargetRelationshipSourceTeam() {
  return {
    restrict: 'E',
    template: require('./templates/source-permissions-table.html'),
    scope: {
      model: '=sbTargetRelationshipModel',
      canEdit: '&sbTargetRelationshipSourceTeamCanEdit',
      foreignManaged: '<sbTrstForeignManaged',
      onModelUpdate: '&',
    },

    controller: [
      '$scope',
      'PermissionsBehavior',
      'BackendLocation',
      'SimpleHTTPWrapper',
      function ($scope, PermissionsBehavior, BackendLocation, SimpleHTTPWrapper) {
        function addSourceTeamMember() {
          // When adding new team member, pre-select existing person
          const member = {
            stakeholder: {
              type: 'newPerson',
            },
            fullManager: true,
            permissions: angular.copy($scope.model.$sourceTeam.$allowedGroups),
            permissionsAvailable: getAvailablePermissions(
              $scope.model.$sourceTeam.$allowedGroups,
            ),
            restrictedFolders: getAllRootFolderIds(
              $scope.model.$sourceTeam.$allowedFolderTree,
            ),
          };
          selectAllPermissions(member.permissions);
          $scope.addMember(member);
        }

        function removeTeamMember(member) {
          $scope.model.$remove(member);
        }

        function editMember(member) {
          return function () {
            return angular.copy(member);
          };
        }

        function canEdit(member) {
          return (
            isEditable(member) ||
            isDREditable(member) ||
            isRemovable(member) ||
            isInvitable(member) ||
            isSignatureEditable(member) ||
            canToggleSignatory(member)
          );
        }

        function isSignatureEditable(member) {
          return (
            $scope.canEdit() &&
            member.$isPermanentManager &&
            (!$scope.foreignManaged || !member.stakeholder.sh.id)
          );
        }

        function isPermissionsEditable(member) {
          return (
            $scope.canEdit() &&
            !$scope.accessSuppressed() &&
            !(member.$isPrimary || member.$isFullManager)
          );
        }

        function isEditable(member) {
          return !member.$isPrimary;
        }

        function isDREditable(member) {
          return !member.$isFullManager;
        }

        function canToggleSignatory(member) {
          const team = $scope.sourceTeamModel.$members;
          const signers = team.filter((x) => x.$isPermanentManager);
          const isOnlyTeamSigner = signers.length === 1 && signers[0] === member;
          return (
            !$scope.foreignManaged &&
            $scope.model.$relationType !== 'legal' &&
            !isOnlyTeamSigner &&
            !$scope.sourceTeamModel.$fixedSigners
          );
        }

        function toggleSigner(member) {
          member.isPermanentManager = !member.$isPermanentManager;
          const isEdit = Boolean(
            member.stakeholder && member.stakeholder.sh && member.stakeholder.sh.id,
          );
          if (isEdit) {
            $scope.model.$updateSourceMember(member);
          } else {
            $scope.model.$requestSourceMember(member);
          }
        }

        function isInvitable(member) {
          return (
            member.stakeholder.sh.approvalStatus === 'approved' &&
            !member.stakeholder.sh.isActivated
          );
        }

        function isRemovable(member) {
          // Pending members are removable even if primary, they don't have
          // the stakeholder id.
          return (
            ((isEditable(member) && !member.$isOnlySigner) ||
              !member.stakeholder.sh.id) &&
            !isOnlyMember(member)
          );
        }

        function isOnlyMember(member) {
          const team = $scope.sourceTeamModel.$members;
          return team.length === 1 && team[0] === member;
        }

        function getPrimaryManager() {
          const team = $scope.sourceTeamModel.$members;
          const primary = team.find((member) => member.primary);
          return primary ? primary.stakeholder.sh : null;
        }

        function sendReminderEmail(member) {
          const url = BackendLocation.context(1) + 'permissions/invite_relation';
          const data = Object.assign({
            id: member.stakeholder.sh.id,
            from: 'source',
          });
          return SimpleHTTPWrapper(
            {
              method: 'POST',
              url,
              data,
            },
            'Failed to send user reminder.',
          ).then(
            (data) => {
              const $sent = Boolean(data.emailSent);
              /* The backend can return quite a few custom status possibilities now.
               We will also provide a fallback, though. */
              const $genericStatus = $sent ? 'Reminder sent.' : 'Reminder pending.';
              $scope.statusMsg = data.status ? data.status : $genericStatus;
              $scope.banner = true;
            },
            (error) => {
              $scope.errorMsg = error;
            },
          );
        }

        $scope.model.$onModelUpdate(() => {
          $scope.onModelUpdate({ $model: $scope.model });
        });

        const getModalOptions = () => {
          return {
            allowedFolderTree: $scope.model.$sourceTeam.$allowedFolderTree,
            allowedFolderGrants: $scope.model.$sourceTeam.$allowedFolderGrants,
            // On target side, we cannot pick stakeholders from the entity, so we
            // only allow creation of new ones.
            stakeholderOptions: {
              allowCreation: true,
              allowEntity: false,
              allowExisting: false,
              allowPerson: true,
              skipSilmilarSearch: true,
            },
            canGiveCreate: false,
            canModifyPrimary: true,
            editTeamRole: true,
          };
        };

        $scope.accessSuppressed = () => false;

        const persist = {
          editMember: (member) => {
            return $scope.model.$updateSourceMember(member);
          },
          addMember: (member) => {
            return $scope.model.$requestSourceMember(member);
          },
        };

        PermissionsBehavior($scope, persist, editMember, editMember, getModalOptions);

        // Set scope variables required by template
        $scope.addTeamMember = addSourceTeamMember;
        $scope.sourceTeamModel = $scope.model.$sourceTeam;
        $scope.banner = false;
        $scope.statusMsg = '';

        $scope.teamTableControls = {
          canEdit: canEdit,
          isEditable: isEditable,
          isDREditable: isDREditable,
          isInvitable: isInvitable,
          isRemovable: isRemovable,
          isSignatureEditable: isSignatureEditable,
          isPermissionsEditable: isPermissionsEditable,
          sendReminderEmail: sendReminderEmail,
          accessSuppressed: () => false,
          removeTeamMember: removeTeamMember,
          getPrimaryManager: getPrimaryManager,
          editAccess: $scope.editAccess,
          editSignatureBlock: $scope.editSignatureBlock,
          editDocumentAccess: $scope.editDocumentAccess,
          editPermissions: $scope.editPermissions,
          canToggleSignatory: canToggleSignatory,
          toggleSigner: toggleSigner,
          statusMsg: $scope.statusMsg,
          errorMsg: $scope.errorMsg,
          banner: $scope.banner,
        };
      },
    ],
  };
}

/**
 * @ngdoc directive
 * @name sb.lib.relationships.directive:sbRelationshipTeamTable
 * @restrict E
 *
 * @description
 * This directive is used to display source team table. It also renders
 * controls for each member with customizable actions for each of them. Controls
 * is an object containing thse methods:
 *
 *  * accessSuppressed: () => boolean: determines if any row is editable,
 *  * isEditable(member) => boolean: determines if member is editable,
 *  * isRemovable(member): isRemovable => boolean: determines if member is removable,
 *  * sendReminderEmail(member): action is called on "Remind" button click,
 *  * removeTeamMember(member): action is called on "remove" action click,
 *  * editAccess(member): action is called on "edit permissions" click,
 *  * editDocumentAccess(member): action is called on "edit document access" click
 *
 * @element ANY
 * @param {array} sbRelationshipTeamTableMembers List of members to display.
 *                                               Each member is defined by
 *                                               SourceRelationship.$models
 *                                               data structure
 * @param {boolean} sbRelationshipTeamTableReady Flag that is set to `true`
 *                                                 when model is ready to be
 *                                                 changed by UI (e.g. loaded)
 * @param {boolean} sbRelationshipTeamTableControls Object containing checks
 *                                                  and actions for various
 *                                                  controls (see above)
 * @param {boolean} sbRelationshipTeamTableCanEdit Flag that is set to `true`
 *                                                  if members can be edited
 *                                                  (uses canEdit of parent
 *                                                   directive)
 *
 * @example
  <sb-relationship-team-table
    sb-relationship-team-table-members="sourceTeamModel.$pendingMembers"
    sb-relationship-team-table-ready="!model.$loading"
    sb-relationship-team-table-controls="teamTableControls"
    sb-relationship-team-table-can-edit="canEdit()"
    >
 */
export function sbRelationshipTeamTable() {
  return {
    restrict: 'E',
    template: require('./templates/relationship-team-table.html'),
    scope: {
      members: '=sbRelationshipTeamTableMembers',
      ready: '=sbRelationshipTeamTableReady',
      controls: '=sbRelationshipTeamTableControls',
      canEdit: '=sbRelationshipTeamTableCanEdit',
      teamTitle: '=sbRelationshipTeamTableTeamTitle',
    },
  };
}

export function sbRelationshipFundTable() {
  return {
    restrict: 'E',
    template: require('./templates/relationship-fund-table.html'),
    scope: {
      funds: '=sbFundsTableFunds',
      teamTitle: '=sbFundsTableFirm',
      foreignManaged: '=sbFundsTableForeignManaged',
      ready: '=sbFundsTableReady',
      controls: '=sbFundsTableControls',
      canEdit: '=sbFundsTableCanEdit',
    },
  };
}

/**
 * @ngdoc directive
 * @name sb.lib.relationships.directive:sbRelationshipAccessPage
 * @restrict E
 *
 * @description
 * This directive is used to display information regarding the relationship
 * and a link the other entity if current user is part of the team
 *
 * @element ANY
 * @param {template} sbRelationshipAccessPageCurrentShId Stakeholder
 *                                                       ID of current user.
 * @param {boolean} sbRelationshipAccessPageLoadSourceModel Whether to load
 *                                                          source relationship
 *                                                          model
 * @param {boolean} sbRelationshipAccessPageLoadTargetModel Whether to load
 *                                                          target relationship
 *                                                          model
 * @param {string} sbRelationshipAccessPageRelType Relationship type
 * @param {string} sbRelationshipAccessPageSourceEntityId Id of source entity
 *
 * @example
   <sb-relationship-access-page
      <a href="./company" ng-if="isTeam">
   </sb-relationship-access-page>
 */
export function sbRelationshipAccessPage() {
  return {
    restrict: 'E',
    controller: [
      '$scope',
      '$attrs',
      'SourceRelationship',
      'TargetRelationship',
      'SimpleHTTPWrapper',
      'WindowLocation',
      '$confirm',
      '$formModal',
      '$dataroomIndexModal',
      'PromiseErrorCatcher',
      'BackendLocation',
      'AppConfig',
      function (
        $scope,
        $attrs,
        SourceRelationship,
        TargetRelationship,
        SimpleHTTPWrapper,
        WindowLocation,
        $confirm,
        $formModal,
        $dataroomIndexModal,
        PromiseErrorCatcher,
        BackendLocation,
        AppConfig,
      ) {
        // We don't want to define isolated scope here, so we read all parameters
        // straight from the attributes
        const currentShId = $attrs.sbRelationshipAccessPageCurrentShId;
        const loadSourceModel = $attrs.sbRelationshipAccessPageLoadSourceModel;
        const loadTargetModel = $attrs.sbRelationshipAccessPageLoadTargetModel;
        const relationshipType = $attrs.sbRelationshipAccessPageRelType;
        const foreignEntityId = $attrs.sbRelationshipAccessPageForeignEntityId;
        const foreignEntityTitle = $attrs.sbRelationshipAccessPageEntityTitle;
        const sourceTitle = $attrs.sbRelationshipAccessPageEntitySourceTitle;
        const currentEntityId = $attrs.sbRelationshipAccessPageCurrentEntityId;
        const teamId = $attrs.sbRelationshipAccessPageTeamShId;
        const sourceAddress = JSON.parse(
          $attrs.sbRelationshipAccessPageEntitySourceAddress || '{}',
        );

        $scope.isTeamMember = false;
        $scope.isTeamManager = false;
        $scope.modelUpdate = modelUpdate;
        $scope.entTitle = foreignEntityTitle;
        $scope.sourceTitle = sourceTitle || foreignEntityTitle;
        $scope.sourceAddress = sourceAddress;

        if (loadTargetModel) {
          $scope.targetModel = TargetRelationship(
            foreignEntityId,
            relationshipType,
            foreignEntityTitle,
          );
          $scope.targetModel
            .$init()
            .then(() => {
              $scope.targetEntityTitle =
                $scope.targetModel.$sourceTeam.$targetEntityTitle;
            })
            .catch((reason) => {
              $scope.error = reason;
            });
        }
        if (loadSourceModel) {
          $scope.sourceModel = SourceRelationship(
            foreignEntityId,
            relationshipType,
            sourceTitle,
          );
          $scope.sourceModel
            .$init()
            .then(() => {
              $scope.targetEntityTitle = $scope.sourceModel.$targetEntityTitle;
            })
            .catch((reason) => {
              $scope.error = reason;
            });
        }

        $scope.terminateRelationship = (evt) => {
          evt.preventDefault();
          const url = evt.currentTarget.getAttribute('termination-url');
          let msg = '';
          if ($scope.isTeamManager) {
            msg = `Are you sure you would like to terminate your company's relationship
with ${$scope.targetEntityTitle}
        in Fidelity Private Shares? Any access to documents, reports, and actions that was previously
granted to you will be revoked
        except for documents you are directly a party to. This action can not be undone.`;
          } else {
            msg = `Are you sure you would like to terminate your company's relationship with ${$scope.entTitle}
        in Fidelity Private Shares? Any access to documents, reports, and actions that was previously granted
        will be revoked except for documents they are directly a party to. This action can not be undone.`;
          }
          $confirm({
            body: msg,
          })
            .then(() =>
              SimpleHTTPWrapper(
                { method: 'POST', url },
                'Error terminating relationship.',
              ),
            )
            .then((data) => {
              if (data.redirectUrl !== null) {
                WindowLocation.replace(data.redirectUrl);
                return;
              }
              WindowLocation.reload();
            })
            .catch((errorMsg) => {
              $scope.errorMsg = errorMsg;
              $scope.errorType = 'danger';
            });
        };

        $scope.createEditForm = () => {
          return {
            form: {
              fields: [
                {
                  key: 'title',
                  data: {},
                  type: 'string-textline',
                  templateOptions: {
                    required: true,
                    label: 'Name',
                  },
                },
                {
                  key: 'address',
                  data: {},
                  type: 'address',
                  templateOptions: {
                    required: false,
                    label: 'Address',
                  },
                },
              ],
            },
          };
        };

        $scope.modifyCompanyData = (evt) => {
          evt.preventDefault();
          const url = BackendLocation.context(1) + '/permissions/source-details-update';
          if ($scope.isTeamManager) {
            $formModal(
              {
                title: 'Edit Company Name and Address',
                primaryButtonText: 'Update',
                htmlContent: require('./templates/edit-company-details.html'),
                forms: $scope.createEditForm(),
                formData: {
                  form: { title: $scope.sourceTitle, address: $scope.sourceAddress },
                },
                onConfirmPromise: ({ $formData }) => {
                  return SimpleHTTPWrapper(
                    {
                      method: 'POST',
                      url: url,
                      data: $formData.form,
                    },
                    'Failed to save changes.',
                  ).then(() => {
                    WindowLocation.reload();
                  });
                },
              },
              'lg',
            ).catch(PromiseErrorCatcher);
          }
        };

        $scope.modifySourceCompanyData = (evt) => {
          evt.preventDefault();
          const url = BackendLocation.context(1) + '/permissions/source-details-update';
          $formModal(
            {
              title: 'Edit Company Name and Address',
              primaryButtonText: 'Update',
              htmlContent: require('./templates/edit-company-details.html'),
              forms: $scope.createEditForm(),
              formData: {
                form: { title: $scope.sourceTitle, address: $scope.sourceAddress },
              },
              onConfirmPromise: ({ $formData }) => {
                return SimpleHTTPWrapper(
                  {
                    method: 'POST',
                    url: url,
                    data: $formData.form,
                  },
                  'Failed to save changes.',
                ).then(() => {
                  WindowLocation.reload();
                });
              },
            },
            'lg',
          ).catch(PromiseErrorCatcher);
        };

        $scope.inviteStakeholderCallback = ({ success, message }) => {
          $scope.errorMsg = message;
          $scope.errorType = success ? 'success' : 'danger';
          if (success) {
            $scope.invitedMsg =
              'This company has not yet activated their Fidelity Private Shares account.';
          }
        };

        $scope.toggleAccess = (evt) => {
          const target = evt.currentTarget;
          const url = target.getAttribute('href');
          const icon = target.querySelector('i');
          evt.preventDefault();
          SimpleHTTPWrapper({ method: 'POST', url }).then((data) => {
            if (data.access) {
              target.querySelector('.revoke-access-label').innerHtml =
                'Suspend All Access';
              icon.classList.remove('fa-plus');
              icon.classList.add('fa-ban');
            } else {
              target.querySelector('.revoke-access-label').innerHtml =
                'Restore All Access';
              icon.classList.remove('fa-ban');
              icon.classList.add('fa-plus');
            }
            WindowLocation.reload();
          });
        };

        $scope.unregisterCounsel = (evt) => {
          const target = evt.currentTarget;
          const url = target.getAttribute('href');
          evt.preventDefault();
          const msg = `You are about to remove yourself as ${$scope.entTitle}'s legal counsel
        in Fidelity Private Shares. This action can not be undone.
        <br/>You will no longer have the granted access to documents or reports for ${$scope.entTitle}.
        You will no longer be able to run workflows on their behalf. You will retain access
        to any documents that your company is a direct party to. `;
          $confirm({
            body: msg,
          })
            .then(() =>
              SimpleHTTPWrapper(
                { method: 'POST', url },
                'Error terminating relationship.',
              ),
            )
            .then((data) => {
              if (data.redirectUrl !== null) {
                WindowLocation.replace(data.redirectUrl);
                return;
              }
              WindowLocation.reload();
            })
            .catch((errorMsg) => {
              $scope.errorMsg = errorMsg;
              $scope.errorType = 'danger';
            });
        };

        $scope.sourceTerminateRelationship = (evt) => {
          evt.preventDefault();
          const url = evt.currentTarget.getAttribute('termination-url');
          const msg = `Are you sure you would like to terminate your company's relationship with ${$scope.entTitle}
        in Fidelity Private Shares? Any access to documents, reports, and actions that was previously granted
        will be revoked except for documents your company is directly a party to. This action can not be undone.`;
          $confirm({
            body: msg,
          })
            .then(() =>
              SimpleHTTPWrapper(
                { method: 'POST', url },
                'Error terminating relationship.',
              ),
            )
            .then((data) => {
              if (data.redirectUrl !== null) {
                WindowLocation.replace(data.redirectUrl);
                return;
              }
              WindowLocation.reload();
            })
            .catch((errorMsg) => {
              $scope.errorMsg = errorMsg;
              $scope.errorType = 'danger';
            });
        };

        function modelUpdate(model) {
          $scope.isTeamMember = model.$members.some(
            (mem) => mem.stakeholder.sh.id === currentShId,
          );
          $scope.isTeamManager = model.$members.some(
            (mem) => mem.manager && mem.stakeholder.sh.id === currentShId,
          );
        }
        //
        $scope.exportDataroomIndex = (evt) => {
          evt.preventDefault();
          // if we are loading target page, we are downloading index for team sh
          // on current entity else if we are loading source page, we will be
          // downloading index on foreign entity (target) for our representative.
          const dataroomOnEntity = loadTargetModel ? currentEntityId : foreignEntityId;
          const dataroomForSh = loadTargetModel ? teamId : null;
          const email =
            AppConfig.userProfile.email || AppConfig.userProfile.contactEmail;
          $dataroomIndexModal(email, dataroomOnEntity, dataroomForSh, relationshipType)
            .then(() => {
              const reason = 'A link to your index will be sent to you shortly.';
              $scope.errorMsg = reason;
              $scope.errorType = 'success';
            })
            .catch(PromiseErrorCatcher);
        };
      },
    ],
  };
}
