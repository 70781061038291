export const sbContextSubprocButton = {
  controllerAs: 'vm',
  template: require('./templates/context-subproc-button.html'),
  transclude: true,
  bindings: {
    key: '<',
  },
  controller: [
    '$scope',
    'ProcessModel',
    'SimpleHTTPWrapper',
    'BackendLocation',
    '$processModal',
    function ($scope, ProcessModel, SimpleHTTPWrapper, BackendLocation, $processModal) {
      this.apiUrl = () => `${BackendLocation.context(1)}context-subproc/`;
      this.startProcess = () => {
        const startContextSubprocess = () => {
          return SimpleHTTPWrapper(
            {
              method: 'POST',
              url: this.apiUrl(),
              data: { key: this.key },
            },
            'Could not start process.',
          );
        };

        const abortContextSubprocess = (procId) => {
          return SimpleHTTPWrapper(
            {
              method: 'DELETE',
              url: this.apiUrl(),
              data: { key: this.key, process: procId },
            },
            'Could not discard the process.',
          );
        };

        const processModalSvc = {
          $remove: (procId) => {
            return abortContextSubprocess(procId);
          },
          $load: () => {
            // modal closed, nothing to do
          },
        };

        ProcessModel.$$startLoad();
        startContextSubprocess()
          .then((wi) => {
            $processModal($scope, wi, processModalSvc);
          })
          .finally(ProcessModel.$$finishLoad);
      };
    },
  ],
};
