/**
 *
 * @description
 * This functions handle real time validation.
 * Disable error status when field is focused.
 *
 */

export function rtvOnFocus(field) {
  if (field?.formControl) {
    field.formControl[0].$invalid = false;
  }
}

export function rtvOnChange(field) {
  if (field?.validation) {
    field.validation.show = false;
  }
}

export function rtvOnBlur(field) {
  const fCs = field.formControl;
  if (!fCs) {
    return;
  }
  if (fCs[0]?.$viewValue && fCs[0]?.$viewValue !== '') {
    field.validation.show = true;
    return;
  }
  fCs[0].$commitViewValue();
  field.validation.show = false;
}
