import angular from 'angular';
import Table from '../../lib/table';
import BaseDataItemsModelFactory from './model';
import { sbUploadEditDocuments } from './upload-edit-docs';

/**
 * @ngdoc directive
 * @name sb.workitem.dataItems.directive:sbDataItems
 * @restrict E
 *
 * @description
 * This directive is intended for the data items workitem. It will create and
 * use a `DataItemsModel` for persistance.
 *
 * @element ANY
 * @param {array} sbDataItemsColumns Array of objects for columns that is passed
 *    along to an `sbTable`.
 * @param {template} sbDataItemsItemTitle String for a single item title; its
 *    passed along to a table.
 * @param {object} [sbDataItemsForms=undefined] Form defintion object thats passed
 *    along to an `sbTable`.
 * @param {boolean} [sbDataItemsAddable=false] If add operation is enabled.
 * @param {object} [sbDataItemsAddSeed=false] If truthy object then this is the
 *    seed data for the form.
 * @param {string} [sbDataItemsModalHtml=undefined] HTML for the modal. This html
 *    will be compiled with `model` similar to a show page.
 *
 * @example
   <sb-data-items
     data-sb-data-items-columns="columns"
     data-sb-data-items-item-title="{{ ::itemTitle }}"
     data-sb-data-items-model="modelObject">
   </sb-data-items>
 */
function sbDataItems() {
  return {
    restrict: 'E',
    template: require('./templates/data-items.html'),
    scope: {
      columns: '=sbDataItemsColumns',
      dataSeed: '=sbDataItemsDataSeed',
      addable: '&sbDataItemsAddable',
      formDefintions: '&?sbDataItemsForms',
      modalHtml: '&sbDataItemsModalHtml',
      itemTitle: '@sbDataItemsTitle',
    },
    controller: [
      '$scope',
      'DataItemsModel',
      'ProcessStatus',
      'ProcessButtonModel',
      function ($scope, DataItemsModel, ProcessStatus, ProcessButtonModel) {
        $scope.model = DataItemsModel();
        $scope.formDefintions = (id) => {
          return $scope.model.$forms(id);
        };

        $scope.$watch('model.$loading', ProcessButtonModel.$disableWatch());

        $scope.model.$init().catch((error) => {
          ProcessStatus.$setStatus(error, 'danger');
        });
      },
    ],
  };
} // end sbDataItems

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.dataItems.object:DataItemsModel
 *
 * @description
 * This function returns a data items model identical to `BaseDataItemsModel`.
 */
const DataItemsModel = [
  'BaseDataItemsModel',
  function (BaseDataItemsModel) {
    return () => new BaseDataItemsModel();
  },
]; // end DataItemsModel

/**
 * @ngdoc overview
 * @name sb.workitem.dataItems
 *
 * @description
 * This module houses the data items workitem family.
 */
export default angular
  .module('sb.workitem.dataItems', [Table])

  .directive('sbUploadEditDocuments', sbUploadEditDocuments)
  .directive('sbDataItems', sbDataItems)
  .factory('BaseDataItemsModel', BaseDataItemsModelFactory)
  .factory('DataItemsModel', DataItemsModel).name;
