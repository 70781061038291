<div class="sbx-tree-select-line">
  <sbx-checkbox
    *ngIf="multiSelect"
    [model]="root.selected"
    (change)="updateSelected()"
  ></sbx-checkbox>
  <label (click)="updateSelected()">
    <span
      class="prefix"
      [ngClass]="{ 'sbx-default-child-1': multiSelect && root.parent }"
    >
      <ng-container *ngFor="let parentRoot of rootChain">
        {{ parentRoot.name }}
        <sbx-icon type="chevronRight"></sbx-icon>
      </ng-container>
    </span>
    <span class="name">{{ root.name }}</span>
  </label>
</div>

<ng-container *ngIf="renderChildren">
  <div class="sbx-tree-select-children" *ngFor="let node of root.leaves">
    <sbx-tree-node-select
      [root]="node"
      [rootChain]="(rootChain || []).concat([root])"
      [multiSelect]="multiSelect"
      (change)="emitChange($event.selectedNodes)"
    >
    </sbx-tree-node-select>
  </div>
</ng-container>
