import angular from 'angular';
/**
 * @ngdoc directive
 * @name sb.lib.url.directive:sbPermissionLink
 * @restrict A
 *
 * @description
 * Add this attribute to a link or button tag to show a warning when the user
 * does not have permission to access the document. Note this directive probably
 * conflicits with `sb-popover` or any other popover code.
 *
 * @element ANY
 * @param {boolean} [sbPermissionLink=false] If this is `true` forbide navigation
 *    of this link and show the warning. Missing attribute is assumed falsey
 *    value.
 * @param {template} [sbPermissionLinkText="to download this document"] Optional
 *    configurable string to change the popover text in the string `"You don't
 *    have permission {sbPermissionLinkText}."
 * @param {template} [sbPermissionLinkHtml=undefined] Optional
 *   Supply an entire html string instead.
 *
 * @example
   <a href="/ent/documents/123/"
     data-sb-permission-link="true"
     data-sb-permission-link-text="to view this Data Room object.">
     Doc Title
   </a>

   <!-- Here is a button too. -->
   <sb-document-download
     sb-document-download="doc"
     sb-document-download-wait-for-render="true"
     sb-permission-link="true">
     <i class="fa fa-download"></i>PDF
   </button>
 */
export const sbPermissionLink = [
  '$compile',
  function ($compile) {
    return {
      priority: -1, // We need a lower priority so our post link happens first.
      restrict: 'A',
      link(scope, element, attrs) {
        const $body = angular.element('body');
        const action = attrs.sbPermissionLinkText || 'to view this document';
        const defaultHtml = `
          You don't have permission ${action}.
          <br>Contact your Shoobx administrator for more information.
        `;
        const html = attrs.sbPermissionLinkHtml || defaultHtml;
        const content = angular.element('<div ng-bind-html="::content"></div>');
        const popScope = scope.$new();
        popScope.content = html;
        $compile(content)(popScope);
        let forbidden;
        element.popover({
          html: true,
          trigger: 'manual',
          placement: 'auto top',
          container: 'body',
          content,
        });
        scope.$watch(attrs.sbPermissionLink, (nv) => {
          forbidden = nv === true;
        });
        element.click((evt) => {
          if (forbidden) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            element.popover('show');
            $body.one('click', () => {
              element.popover('hide');
            });
          }
        });
        scope.$on('$destroy', () => {
          element.popover('destroy');
          popScope.$destroy();
        });
      },
    };
  },
]; // end sbPermissionLink
