import angular from 'angular';
import { Map, Record } from 'immutable';

import Form from '../form/form';
import Tools from '../tools';
import Modal from '../modal/modal';
import Bootstrap from '../bootstrap/bootstrap';
import { sbTable } from './table';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.table:TableCellData
 *
 * @description
 * This is a convience function to create an *immutable* sbTable cell data.
 * See `sbTable`.
 *
 * @param {string} fmtValue HTML string that will be a cell output.
 * @param {any} [value=undefined] Sortable value.
 *
 * @returns {Record} A record with the above params as keys.
 */
function TableCellData() {
  const TableCellData = new Record({ fmtValue: undefined, value: undefined });
  return (fmtValue, value) => TableCellData({ fmtValue, value });
}

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.table:TableItemData
 *
 * @description
 * This is a convience function to create an *immutable* sbTable item data.
 * See `sbTable`.
 *
 * @param {string} id Item's ID
 * @param {any} data Any immutable map/record as data.
 * @param {boolean} [editable=false] Item is editable.
 * @param {boolean} [deletable=false] Item is deletable.
 * @param {string} [editTitle='Edit'] Item edit modal title.
 *
 * @returns {Record} A record with the above params as keys.
 */
function TableItemData() {
  const TableItemData = new Record({
    id: undefined,
    data: Map(),
    deletable: false,
    editable: false,
    editTitle: 'Edit',
  });
  return (id, data, editable, deletable, editTitle) => {
    const construct = { id, data };
    if (editable) {
      construct.editable = true;
    }
    if (deletable) {
      construct.deletable = true;
    }
    if (editTitle) {
      construct.editTitle = editTitle;
    }
    return TableItemData(construct);
  };
}

/**
 * @ngdoc overview
 * @name sb.lib.table
 *
 * @description
 * This module houses table code for CRUD.
 */
export default angular
  .module('sb.lib.table', [Form, Modal, Bootstrap, Tools])

  .component('sbTable', sbTable)
  .factory('TableCellData', TableCellData)
  .factory('TableItemData', TableItemData).name;
