import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageModel } from '@/shared/sbx-message/message.model';

@Injectable()
export class MessagesService {
  private readonly messages$: Observable<MessageModel[]>;

  private readonly messagesSource$: BehaviorSubject<MessageModel[]> =
    new BehaviorSubject([]);

  public constructor() {
    this.messages$ = this.messagesSource$.asObservable();
  }

  public getMessages$(): Observable<MessageModel[]> {
    return this.messages$;
  }

  public setMessages(messages: MessageModel[]): void {
    this.messagesSource$.next(messages);
  }

  public addMessage(message: MessageModel): void {
    const messages = this.messagesSource$.value;

    this.messagesSource$.next([...messages, message]);
  }

  public removeMessage(messageToRemove: MessageModel): void {
    const messages = this.messagesSource$.value;
    const index = messages.findIndex(
      (message: MessageModel) => message === messageToRemove,
    );

    if (index === -1) {
      return;
    }

    this.messagesSource$.next([
      ...messages.slice(0, index),
      ...messages.slice(index + 1),
    ]);
  }

  public clearMessages(): void {
    this.messagesSource$.next([]);
  }
}
