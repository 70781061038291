const WAIVE_STOCKHOLDER_WARNING = `Are you certain you want to waive stockholder approval?
   If you are unsure, consult counsel before proceeding.`;

const WAIVE_BOARD_WARNING = `Are you certain you want to waive board approval?
   If you are unsure, consult counsel before proceeding.`;

/**
 * @ngdoc component
 * @name sb.workitem.showPage.component:sbApprovalMethodTemplate
 *
 * @description
 * This directive is intended for use with the approval method form (a show page
 * workitem).
 *
 * @param {expression} ngModel Model expression.
 * @param {boolean} showBoardOptions When truthy, show the board approval options.
 * @param {boolean} showStockholderOptions When truthy, show the stockholder
 *    approval options.
 * @param {boolean} brsAlreadyApproved When truthy, the relevant board
 *    resolution documents have already been approved.
 * @param {boolean} srsAlreadyApproved When truthy, the relevant stockholder
 *    resolution documents have already been approved.
 * @param {string|null} uploadedBrApprovalLink URL to existing, uploaded
 *    board resolution approval document in Data Room, if applicable.
 * @param {string|null} uploadedSrApprovalLink URL to existing, uploaded
 *    stockholder resolution approval document in Data Room, if applicable.
 * @param {boolean} [showStockholderWaiveOption=false] When truthy, stockholder
 *    options will include a _waive_ option.
 * @param {boolean} [showBoardWaiveOption=false] When truthy, board
 *    options will include a _waive_ option.
 * @param {boolean} [showUploadExecuteOptions=false] When truthy,
 *    board and stockholder options will show upload and execute option.
 * @param {object} stockholderApprovalDocumentation An object with `document`,
 *    `stakeholder` and optionally, `date` keys. This is used in conjunction with
 *    a `sbMetadataChange` element.
 * @param {template} [fieldNamePrefix=''] Prefix for the field names (likely a
 *    form name prefix).
 */
export const sbApprovalMethodTemplate = {
  template: require('./templates/approval-method.html'),
  require: 'ngModel',
  bindings: {
    model: '=ngModel',
    showBoardOptions: '<',
    showStockholderOptions: '<',
    brsAlreadyApproved: '<',
    srsAlreadyApproved: '<',
    uploadedBrApprovalLink: '@',
    uploadedSrApprovalLink: '@',
    showStockholderWaiveOption: '<?',
    showBoardWaiveOption: '<?',
    showUploadExecuteOptions: '<?',
    stockholderApprovalDocumentation: '<',
    namePrefix: '@?fieldNamePrefix',
  },
  controllerAs: 'vm',
  controller: [
    '$q',
    '$confirm',
    'ProcessButtonModel',
    function ($q, $confirm, ProcessButtonModel) {
      this.$onInit = () => {
        if (!this.namePrefix) {
          this.namePrefix = '';
        }
        // Use a higher prority submit condition to finish before form submit:
        ProcessButtonModel.$addSubmitCondition(
          'continue',
          () => {
            const stockholderMethod = this.model.execute_shareholder_consent;
            const boardMethod = this.model.execute_board_consent;
            // Do just about anything to meet the condition if we can..
            const noShowBoardNoShWaive =
              !this.showBoardOptions && stockholderMethod !== 'waive';
            const noShowShNoBoardWaive =
              !this.showStockholderOptions && boardMethod !== 'waive';
            const waiveNeither =
              stockholderMethod !== 'waive' && boardMethod !== 'waive';

            if (noShowBoardNoShWaive || noShowShNoBoardWaive || waiveNeither) {
              return $q.resolve();
            }
            if (stockholderMethod === 'waive') {
              return $confirm({
                body: WAIVE_STOCKHOLDER_WARNING,
                alertType: 'warning',
                confirmButtonText: 'Waive Stockholder Approval',
              });
            }
            if (boardMethod === 'waive') {
              return $confirm({
                body: WAIVE_BOARD_WARNING,
                alertType: 'warning',
                confirmButtonText: 'Waive Board Approval',
              });
            }
          },
          1,
        );
      };
    },
  ],
}; // end sbApprovalMethodTemplate
