import angular from 'angular';
/**
 * @ngdoc directive
 * @name sb.lib.bootstrap.directive:sbWindowHeight
 * @restrict A
 *
 * @description
 * Add this attribute to any element that you want to take up the entire height
 * of the window with dynamic javascript (ie, will resize with `window.resize()`)
 * Consider using this directive only when you cannot use CSS (`height: 100%`).
 * It may also be wise to use a `max-height`/`min-height` CSS property as well.
 *
 * @element ANY
 * @param {number} [sbWindowHeight=0] Set to number of pixels *less* that this
 *    elements height will be. To be clear this if this is set to 300 and the
 *    windows height is 1000, then this element's height will be 700 pixels.
 *    *Note:* This is only parsed once at post-compile, so it considered one
 *    time-binding (likely you want to use it with a number constant/literal).
 */
export const sbWindowHeight = [
  '$window',
  '$parse',
  function ($window, $parse) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const offset = $parse(attrs.sbWindowHeight)(scope) || 0;
        const minHeight = $parse(attrs.sbWindowHeightMin)(scope) || 0;
        const $jWindow = angular.element($window);

        function callback() {
          let newHeight = $jWindow.height() - offset;
          if (newHeight < minHeight) {
            newHeight = minHeight;
          }
          element.css('height', newHeight);
        }

        $jWindow.resize(callback);
        scope.$on('$destroy', () => {
          $jWindow.off('resize', callback);
        });
        callback();
      },
    };
  },
]; // end sbWindowHeight
