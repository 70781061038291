import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import { forkJoin } from 'rxjs';

@Injectable()
export class StakeholderSearchService {
  constructor(private service: SbxHttpClient) {}

  search(text, params): Observable<any> {
    for (const listParam of ['includeStates', 'excludeStates']) {
      if (params[listParam]) {
        params[listParam] = params[listParam].toString();
      }
    }

    // Filter out falsy params
    const filteredParams = Object.keys(params).reduce((filtered, key) => {
      if (params[key]) {
        filtered[key] = params[key];
      }
      return filtered;
    }, {});

    const requests = [];
    requests.push(
      this.service.entity('2').get('stakeholder/name-search', {
        params: {
          name: text,
          ...filteredParams,
        },
      }),
    );

    if (params.allowRelationship) {
      requests.push(
        this.service.entity('2').get('relationship/name-search', {
          params: {
            name: text,
            ...filteredParams,
          },
        }),
      );
    }

    return forkJoin(requests, (...results) =>
      results.reduce((acc, result) => {
        acc = { ...acc, ...result };
        return acc;
      }, {}),
    );
  }
}
