<div class="sbx-tree-node-view">
  <div class="sbx-tree-view-line">
    <div class="sbx-tree-name">
      <span class="sbx-tree-name-{{ depth }}">{{ root.name }}</span>
    </div>
  </div>

  <div *ngFor="let node of root.leaves" class="sbx-tree-view-children">
    <sbx-tree-node-view [depth]="depth + 1" [root]="node"> </sbx-tree-node-view>
  </div>
</div>
