import { AppConfig } from '@/core/config/app.config';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';

@Injectable()
export class SbxAuthGuard implements CanActivate {
  constructor(
    private appConfig: AppConfig,
    private router: Router,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot) {
    const entityName = {
      fromUrl: next.paramMap.get('entity'),
      fromAppConfig: this.appConfig.currentEntity?.name,
    };

    // We need to reinitialize appConfig if current entity doesn't correspond to the entity in url params
    if (
      entityName.fromUrl &&
      entityName.fromAppConfig &&
      entityName.fromUrl !== entityName.fromAppConfig
    ) {
      try {
        await this.appConfig.entityInit(entityName.fromUrl);
      } catch {
        this.router.navigate([], { replaceUrl: true });
      }
    }

    if (this.appConfig.authenticated) {
      return true;
    }

    const { hash, pathname, search } = location;
    const params = new URLSearchParams(search);
    const loginParams: Params = {};
    loginParams.camefrom = `${pathname}${search || ''}${hash || ''}`;

    const secToken = params.get('sec_token');
    if (secToken) {
      // eslint-disable-next-line camelcase
      loginParams.sec_token = secToken;
    }
    return this.router.createUrlTree(['auth', 'login'], {
      queryParams: loginParams,
    });
  }
}
