import angular from 'angular';
import { BehaviorSubject, of } from 'rxjs';

const EXHIBIT_HELP_TEXT = `
  You can reference exhibits as "Exhibit A," "Exhibit B," etc. in the field above.
`;

function cleanResolutionContent(resolution, delim) {
  delim = angular.element('<span>' + delim + '</span>')[0];
  const elem = angular.element('<div>' + resolution.content.trim() + '</div>')[0];
  const firstElem = elem.firstElementChild;

  if (firstElem && firstElem.tagName === 'P' && !firstElem.hasAttribute('class')) {
    firstElem.insertBefore(delim, firstElem.firstChild);
  } else {
    elem.insertBefore(delim, elem.firstChild);
  }
  return elem.outerHTML.trim();
}

function joinSectionResolutions(resolutions, delim) {
  return resolutions.map((res) => cleanResolutionContent(res, delim)).join('');
}

function getModelSearchTitles(approved) {
  const titles = approved
    .filter((group) => group.custom)
    .reduce((accum, group) => {
      group.sections.forEach(({ title }) => {
        accum[title] = group;
      });
      return accum;
    }, {});
  return Object.keys(titles).map((title) => ({
    searchTitle: title.toLowerCase(),
    group: titles[title],
  }));
}

function getModalParams(model, type, group, sectionId = 0) {
  const isCreation = !group;
  const section = group && group.sections[sectionId];

  const isResolution = type === 'resolution' || type === 'general authority';
  const isIntro = type === 'introduction';
  const resDocIndenifier = isResolution ? group && group.id : 'whereas';

  const verb = isCreation ? 'Create new' : 'Edit';
  const title = isCreation ? '' : section.title;

  const resolutionStr = `${isResolution || isIntro ? '' : '<b>WHEREAS</b>:'}`;
  const whereasesContent = isCreation
    ? resolutionStr
    : joinSectionResolutions(section.whereases, isIntro ? '' : '<b>WHEREAS</b>:');
  const resolutionsContent = isCreation
    ? '<b>RESOLVED</b>:'
    : joinSectionResolutions(section.resolutions, '<b>RESOLVED</b>:');

  const searchTitles =
    isCreation && isResolution ? getModelSearchTitles(model.approved) : [];

  return {
    modalTitle: `${verb} ${type}`,
    hasTitle: isResolution,
    title,
    whereasesContent,
    resolutionsContent,
    searchTitles,
    resDocIndenifier,
    section,
  };
}

export const ResolutionModalCtrl = [
  '$q',
  '$sbModal',
  '$formModal',
  'PromiseErrorCatcher',
  'ExhibitListing',
  'Model',
  'Group',
  'Type',
  'SectionId',
  function (
    $q,
    $sbModal,
    $formModal,
    PromiseErrorCatcher,
    ExhibitListing,
    Model,
    Group,
    Type,
    SectionId,
  ) {
    function normalizeQuery(query) {
      query = query || '';
      return query.trim().toLowerCase();
    }

    function save() {
      this.error = null;

      const { title } = this.createModel;
      const sectionId = this.section ? this.section.id : null;

      let groupId = '';
      if (Type === 'whereas') {
        groupId = 'whereas';
      } else if (Type === 'introduction') {
        groupId = 'intro';
      } else {
        groupId = sectionId;
      }

      this.model
        .save(
          this.whereasesContent,
          this.resolutionsContent,
          title,
          groupId,
          this.exhibits.queuedExhibitDeletions(),
        )
        .then(
          () => {
            this.$close();
          },
          (err) => {
            this.error = err;
          },
        );
    }
    function selectGroupTitle(group) {
      return group.sections[0].title;
    }
    function dismiss() {
      this.model.addExhibits(this.exhibits.addedExhibits());
      this.$dismiss();
    }
    function search(query) {
      query = normalizeQuery(query);
      return searchTitles.filter(({ searchTitle }) => searchTitle.startsWith(query));
    }
    function exhibitModal(
      title,
      formData,
      successCallbackFn = null,
      failureCallbackFn = null,
    ) {
      $formModal(
        {
          title,
          htmlContent: require('./templates/add-exhibit-modal.html'),
          forms: { addExhibit: angular.copy(Model.exhibitForm) },
          formData: { addExhibit: formData },
          onConfirmPromise: ({ $formData }) => {
            return this.exhibits
              .addExhibit($formData.addExhibit)
              ?.catch((e) => Promise.reject({ addExhibit: e }));
          },
        },
        undefined,
        'add-exhibit-modal',
      )
        .then(() => {
          if (successCallbackFn) {
            successCallbackFn(this.exhibits.listing);
          }
          const element = document.body.querySelector(
            ".richtext-exhibit-pill[selected='true']",
          );
          this.exhibitPillsData$.next({
            exhibits: this.exhibits.listing,
          });
          element.click();
          setTimeout(selectExhibit, 100);
        })
        .catch((error) => {
          if (failureCallbackFn) {
            failureCallbackFn(error);
          }
          PromiseErrorCatcher(error);
        });
    }
    function selectExhibit() {
      const btn = Array.from(document.querySelectorAll('sbx-button'))
        .reverse()
        .find((el) => el.textContent.toLowerCase().trim() === 'select');
      btn.click();
    }
    function editExhibit(exhibit) {
      const { title, id } = exhibit;
      exhibitModal.call(this, 'Edit Exhibit', {
        resolutionWorkitemExhibitDoc: {
          type: 'upload',
          documentTitle: title,
          documentId: id,
        },
        resolutionWorkitemExhibitTitle: title,
      });
    }
    function addExhibit(event) {
      const successCallbackFn = event && event.detail && event.detail.successCallbackFn;
      const failureCallbackFn = event && event.detail && event.detail.failureCallbackFn;
      exhibitModal.call(this, 'Add Exhibit', {}, successCallbackFn, failureCallbackFn);
    }
    function deleteExhibit(exhibitId) {
      this.exhibits.deleteExhibit(exhibitId);
      this.exhibitPillsData$.next({
        exhibits: this.exhibits.listing,
      });
    }
    function updateWhereasesContent($event) {
      this.whereasesContent = $event;
    }

    function updateResolutionsContent($event) {
      this.resolutionsContent = $event;
    }

    const {
      modalTitle,
      hasTitle,
      title,
      whereasesContent,
      resolutionsContent,
      searchTitles,
      resDocIndenifier,
      section,
    } = getModalParams(Model, Type, Group, SectionId);
    this.exhibitHelpText = EXHIBIT_HELP_TEXT;
    this.type = Type;
    this.hasTitle = hasTitle;
    this.title = modalTitle;
    this.model = Model;
    this.section = section;
    this.exhibits = ExhibitListing(Model, resDocIndenifier);
    this.exhibitPillsData$ = new BehaviorSubject({
      exhibits: this.exhibits.listing,
    });
    this.createModel = {
      whereasesContent,
      resolutionsContent,
      title,
    };
    this.whereasesContent = whereasesContent;
    this.resolutionsContent = resolutionsContent;
    this.save = save.bind(this);
    this.selectGroupTitle = selectGroupTitle.bind(this);
    this.dismiss = dismiss.bind(this);
    this.search = search.bind(this);
    this.addExhibit = addExhibit.bind(this);
    this.editExhibit = editExhibit.bind(this);
    this.deleteExhibit = deleteExhibit.bind(this);
    this.updateWhereasesContent = updateWhereasesContent.bind(this);
    this.updateResolutionsContent = updateResolutionsContent.bind(this);
    this.introductionEditorFeatures = {
      enableParaStyle: true,
      enableSpanStyle: true,
      enableFontStyle: true,
      enableListStyle: true,
      customSubElement: true,
    };
    this.whereasEditorFeatures = {
      ...this.introductionEditorFeatures,
      insertClause: of({ title: 'WHEREAS' }),
      exhibitPills: this.exhibitPillsData$,
    };
    this.resolvedEditorFeatures = {
      ...this.introductionEditorFeatures,
      insertClause: of({ title: 'RESOLVED' }),
      exhibitPills: this.exhibitPillsData$,
    };
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.resolution.object:$resolutionEditModal
 *
 * @description
 * This opens a resolution edit modal.
 *
 * @param {object} model The model state of the higher context.
 * @param {string} type Type of modal, either `resolution` or `whereas`.
 * @param {object} [group=undefined] Group object to be edited. `Undefined` implies
 *   creation.
 */
export const $resolutionEditModal = [
  '$sbModal',
  function ($sbModal) {
    return (model, type, group, sectionId) =>
      $sbModal.open({
        size: 'lg',
        controllerAs: 'vm',
        bindToController: true,
        keyboard: false,
        backdrop: 'static',
        windowClass: 'resolution-create-modal',
        template: require('./templates/create-modal.html'),
        controller: ResolutionModalCtrl,
        resolve: {
          Model: () => model,
          Group: () => group,
          Type: () => type,
          SectionId: () => sectionId,
        },
      }).result;
  },
]; // end $resolutionEditModal

const SelectEditResolutionModalCtrl = [
  'Config',
  'SimpleHTTPWrapper',
  'BackendLocation',
  'ProcessUrlInfo',
  function (Config, SimpleHTTPWrapper, BackendLocation, ProcessUrlInfo) {
    function save() {
      this.loading = true;
      this.config.warnBound().then(
        SimpleHTTPWrapper(
          { method: 'POST', url: this.url + '/save_groups' },
          'Could not save groups.',
        )
          .then(
            (data) => {
              this.$close();
              angular.extend(this, data);
            },
            (error) => {
              this.error = error;
            },
          )
          .finally(() => {
            this.loading = false;
          }),
      );
    }
    this.save = save.bind(this);
    this.config = Config;
    this.docId = this.config.doc.get('id');
    this.docTitle = this.config.doc.get('title');
    const baseUrl = `${BackendLocation.entity(
      1,
    )}processes/${ProcessUrlInfo.processId()}`;
    const savePath = `/select_and_edit_resolution/${this.docId}`;
    this.url = `${baseUrl}${savePath}`;
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.resolution.object:$selectEditResolutionModal
 *
 * @description
 * This opens a resolution edit modal.
 *
 * @param {object} config configuration object. Includes the following:
 *  doc: standard document object,
 *  isWorkitem: [false]
 */
export const $selectEditResolutionModal = [
  '$sbModal',
  function ($sbModal) {
    return (config) =>
      $sbModal.open({
        size: 'lg',
        controllerAs: 'vm',
        bindToController: true,
        keyboard: false,
        backdrop: 'static',
        template: require('./templates/select-edit-resolution-modal.html'),
        controller: SelectEditResolutionModalCtrl,
        resolve: {
          Config: () => config,
        },
      }).result;
  },
]; // end $selectEditResolutionModal
