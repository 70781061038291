import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SbxCollapsibleTableComponent,
  SbxCollapsibleTableColumnTemplate,
} from './sbx-collapsible-table.component';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, SbxButtonModule, SbxIconModule],
  declarations: [SbxCollapsibleTableComponent, SbxCollapsibleTableColumnTemplate],
  exports: [SbxCollapsibleTableComponent, SbxCollapsibleTableColumnTemplate],
})
export class SbxCollapsibleTableModule {}
