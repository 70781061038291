import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxAppComponent } from './sbx-app.component';
import { SbxHeaderComponent } from './sbx-header/sbx-header.component';
import { SbxFooterComponent } from './sbx-footer/sbx-footer.component';
import { SbxAppRouterModule } from './sbx-app.router';
import { SbxSideMenuComponent } from './sbx-side-menu/sbx-side-menu.component';
import { SbxSideMenuService } from './sbx-side-menu/sbx-side-menu.service';
import { NGB_CONTAINER_TOKEN } from '@/shared/sbx-form/fields/ngb-container.token';

@NgModule({
  imports: [SbxAppRouterModule, CommonModule, SharedModule],
  declarations: [
    SbxAppComponent,
    SbxHeaderComponent,
    SbxFooterComponent,
    SbxSideMenuComponent,
  ],
  providers: [
    { provide: 'Window', useValue: window },
    SbxSideMenuService,
    {
      provide: NGB_CONTAINER_TOKEN,
      useValue: undefined,
    },
  ],
})
export class SbxAppModule {
  static entryComponents = [SbxAppComponent];

  static forRoot(): ModuleWithProviders<SbxAppModule> {
    return {
      ngModule: SbxAppModule,
    };
  }
}
