import angular from 'angular';
import { Observable, merge, of } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  distinctUntilChanged,
  scan,
  concatWith,
  tap,
} from 'rxjs/operators';

/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbDocumentDownload
 * @restrict E
 *
 * @description
 * Use this directive as a high level PDF download button.
 * The transcluded content is used when the rendering has completed.
 *
 * @param {object} sbDocumentDownload Document object from a backend `jsonify`.
 *    Can be a immutable object or plain JS object.
 * @param {boolean} [sbDocumentDownloadPdf=false] Download a converted PDF version.
 * @param {boolean} [sbDocumentDownloadWaitForRender=false] If true, document
 *    render will block the process buttons.
 * @param {expression} [sbDocumentDownloadOnReady]
 *    Function to call when rendering is complete (failed or succeeded).
 * @param {expression} [sbDocumentDownloadOnClick=download()]
 *    Function to call when download button is clicked
 * @param {object} [buttonClassOverrides={ 'button-int': true }] ng-class overrides
 *    for the button.
 *
 * @example
   <sb-document-download
     sb-document-download="doc"
     sb-document-download-pdf="true"
     sb-document-download-wait-for-render="true">
     <i class="fa fa-download"></i>PDF
   </sb-document-download>
 */
export function sbDocumentDownload() {
  const FAILURE_STRING = Object.freeze({
    RENDER: 'Document Render Failure',
    CERTIFY: 'Document Certify Failure',
  });
  return {
    restrict: 'E',
    template: require('./templates/document-download.html'),
    transclude: true,
    scope: {
      doc: '<sbDocumentDownload',
      downloadPdf: '<?sbDocumentDownloadPdf',
      waitForRender: '<?sbDocumentDownloadWaitForRender',
      onRenderReady: '&sbDocumentDownloadOnReady',
      clickHandler: '&?sbDocumentDownloadOnClick',
      buttonClassOverrides: '<?',
    },
    controller: [
      '$scope',
      '$window',
      '$observable',
      '$exceptionHandler',
      'ProcessButtonModel',
      'EntityDocumentsService',
      function (
        $scope,
        $window,
        $observable,
        $exceptionHandler,
        ProcessButtonModel,
        EntityDocumentsService,
      ) {
        function objToDoc(doc) {
          return doc.toJS ? doc.toJS() : doc;
        }

        function download() {
          const doc = objToDoc($scope.doc);
          if ($window.shoobxTesting) {
            $window.shoobxTesting.ignoreNextPageUnload();
          }
          if ($scope.downloadPdf) {
            $window.location.href = doc.href.pdf;
          } else {
            // Deferred  docs don't return href.content -
            // default back to doc.href.pdf
            $window.location.href = doc.href.content || doc.href.pdf;
          }
        }

        function buttonClasses() {
          return Object.assign(
            {
              'async-document-download-failed': $scope.failReason,
              'btn-int': true,
            },
            $scope.buttonClassOverrides || {},
          );
        }
        $scope.buttonClasses = buttonClasses;

        // Handle state of buttons (disable when rendering, enable when
        // rendered). ProcessButtonModel only expects number of "disables" to be
        // equal to number of "enables". Download button might start waiting for
        // rendering before previous wait completes, so we need to swap status of
        // process button only when we want to change it.
        let updateProcessButton;

        // Organize our requests to disable process buttons into stream of
        // booleans.
        new Observable((observer) => {
          updateProcessButton = (enable) => observer.next(enable);
        })
          .pipe(
            // Enable only after disabling, and vice versa
            distinctUntilChanged(),
          )
          .$applySubscribe(
            $scope,
            (enable) => {
              if (enable) {
                ProcessButtonModel.requestEnable();
              } else {
                ProcessButtonModel.disable();
              }
            },
            $exceptionHandler,
          );

        // Organize actions, that affect download button into strams of event:
        // stream of model updates and stream of clicks on a button.

        // Stream of model updates.
        const modelUpdate$ = $observable.fromWatcher($scope, 'doc').pipe(
          map((doc) => doc?.newValue),
          filter((doc) => angular.identity(doc)),
          map(objToDoc),
          map((doc) => ({ doc: doc, modelUpdate: true })),
        );

        // Using this to prevent non-intended downloads triggered by model updates.
        let downloadPending = false;

        // Stream of clicks on download button.
        const click$ = new Observable((observer) => {
          $scope.onClick = (ev) => observer.next(ev);
        }).pipe(
          tap(() => {
            downloadPending = true;
            // Show the spinner on a button, because we are about to retrieve the
            // new version of a document
            $scope.$applyAsync(() => {
              $scope.rendering = true;
            });
          }),
          switchMap(() => {
            const doc = objToDoc($scope.doc);
            return EntityDocumentsService.$getDocument(doc.id, doc.entityId, true);
          }),
          map((doc) => ({ doc: doc, click: true })),
        );

        // Merge these two streams into single one so that we handle only the
        // latest event in a stream
        const docUpdate$ = merge(modelUpdate$, click$).pipe(
          // Meke sure click is handled even if model update happen after the
          // user clicked to download
          scan(
            (oldValue, newValue) => ({
              doc: newValue.doc,
              modelUpdate: newValue.modelUpdate,
              click: oldValue.click || newValue.click,
            }),
            {},
          ),
        );

        // Stream of button states emits values when rendering or certification
        // is completed.
        const renderState$ = docUpdate$.pipe(
          switchMap((docUpdate) => {
            const { doc, modelUpdate, click } = docUpdate;

            const initial$ = of({
              doc,
              modelUpdate,
              click,
              rendering: true,
              certifying: true,
            });

            const renderEvent$ = EntityDocumentsService.$waitForDocumentToRender(
              doc,
            ).pipe(
              map((success) => ({
                doc,
                modelUpdate,
                click,
                rendering: false,
                renderFailed: !success,
              })),
            );
            const certifyEvent$ = EntityDocumentsService.$waitForDocumentToCertify(
              doc,
            ).pipe(
              map((success) => ({
                doc,
                modelUpdate,
                click,
                certifying: false,
                certifyFailed: !success,
              })),
            );

            return initial$.pipe(concatWith(renderEvent$), concatWith(certifyEvent$));
          }),
        );

        // Finally, we can subscribe to renderState$ to update the state of the
        // button and handle these events
        renderState$.$applySubscribe(
          $scope,
          (renderEvent) => {
            // Set $scope variables that affect the state of the button
            $scope.rendering = renderEvent.rendering;
            $scope.certifying = renderEvent.certifying;
            if (renderEvent.renderFailed) {
              $scope.failReason = FAILURE_STRING.RENDER;
            }
            if (renderEvent.certifyFailed) {
              $scope.failReason = FAILURE_STRING.CERTIFY;
            }

            if ('rendering' in renderEvent) {
              // Enable or disable process "Continue" button while document is
              // rendering
              const renderSuccessful =
                !renderEvent.rendering && !renderEvent.renderFailed;

              updateProcessButton(renderSuccessful);

              // Call a callback when rendering is done
              if (renderSuccessful && $scope.onRenderReady) {
                $scope.onRenderReady();
              }

              // Handle automatic download if user has clicked on a button
              // Only triggers download if there are pending download requests.
              if (renderEvent.click && renderSuccessful && downloadPending) {
                // Let click handler to prevent actual download from happening. If
                // clickHandler is defined and returns truish value, actual
                // download will not happen.
                if (!$scope.clickHandler || !$scope.clickHandler()) {
                  download();
                }
                downloadPending = false;
              }
            }
          },
          $exceptionHandler,
        );
      },
    ],
    link(scope, element) {
      element.click((evt) => {
        evt.preventDefault();
      });
    },
  };
}

/**
 * @ngdoc component
 * @name sb.lib.document.component:sbDocumentDownloadDropdown
 *
 * @description
 * A dropdown button that contains a sbExportDocumentDownload and
 * a sbWatermarkedDocumentDownload. It displays the state of document preparation
 * and will automatically start downloading once document is rendered.
 * @param {string} docId of the document to request.
 * @param {boolean} [canExport=false] decides whether to display export doc button.
 * @param {boolean} [canDownload=true] decides whether to display download pdf button.
 * @param {string} [canDownloadRevisionHistory=false] decides whether to show the revision button.
 * @param {string} [canDownloadCustomization=false] decides whether to show the customization report button.
 * @param {string} [canDownloadRedline=false] decides whether to show the redline button.
 * @param {Boolean} [fullSized=true] decides which type of download button to show. 'FULL' or 'MICRO'.
 * @param {string} [title=null] optional title text.
 *
 * @example
   <sb-document-download-dropdown
    document-id="{{ ::doc.id }}"
    can-export="true"
    can-download="true">
   </sb-document-download-dropdown>
 */
export const sbDocumentDownloadDropdown = {
  controllerAs: 'vm',
  template: require('./templates/download-dropdown.html'),
  bindings: {
    docId: '@documentId',
    canExport: '<?',
    canDownload: '<?',
    canDownloadWatermarked: '<?',
    canDownloadRevisionHistory: '<?',
    canDownloadCustomization: '<?',
    canDownloadRedline: '<?',
    fullSized: '<?',
    title: '@?',
  },
  controller: [
    '$scope',
    '$window',
    'AppConfig',
    'EntityDocumentsService',
    'BackendLocation',
    class {
      constructor($scope, $window, AppConfig, EntityDocumentsService, BackendLocation) {
        this.$scope = $scope;
        this.$window = $window;
        this.backendLocation = BackendLocation;
        this.docs = EntityDocumentsService;
        this.entity = AppConfig.currentEntity.name;
      }

      $onInit() {
        const {
          entity,
          docs,
          docId,
          $window,
          $scope,
          showWatermarkedPdfButton,
          showExportButton,
          showDownloadRevisionHistory,
          showCustomizationPdfButton,
          showRedlineButton,
          fullSized,
        } = this;
        if (fullSized === undefined) {
          this.fullSized = true;
        }
        this.preparing = true;
        this.docTypes = [];
        this.skipRender = false;

        const addDocType = (endpoint, text) => this.docTypes.push({ endpoint, text });

        if (showWatermarkedPdfButton) {
          addDocType('watermark-doc', 'Request PDF');
        }

        if (showExportButton) {
          addDocType('export-doc', 'Request DOCX');
        }

        if (showDownloadRevisionHistory) {
          addDocType('revisions-doc', 'Revision Report');
        }

        if (showCustomizationPdfButton) {
          addDocType('customizations-doc', 'Document Customization Report');
        }

        if (showRedlineButton) {
          addDocType('redline-doc', 'Template Redline');
        }

        docs
          .$getDocument(docId, entity, true)
          .pipe(switchMap(docs.$waitForDocumentToRender))
          .$applySubscribe(
            $scope,
            () => {
              this.preparing = false;
              $scope.$on('documentReady', (event, docUrl) => {
                $window.location = docUrl;
              });
            },
            () => {
              this.skipRender = true;
            },
          );
      }

      get state() {
        const { preparing, docTypes } = this;
        if (preparing) {
          return 'preparing';
        }

        if (docTypes.length) {
          return 'dropdown';
        }

        return 'button-only';
      }

      get isFullSized() {
        return this.fullSized && !this.title;
      }

      get buttonType() {
        return this.isFullSized ? 'btn-int' : 'btn-link';
      }

      get url() {
        return `${this.backendLocation.entity(1)}documents/${this.docId}/content`;
      }

      get showExportButton() {
        return this.canExport && !this.skipRender;
      }

      get showPdfButton() {
        return this.canDownload && !this.skipRender;
      }

      get showWatermarkedPdfButton() {
        return this.canDownloadWatermarked && !this.skipRender;
      }

      get showCustomizationPdfButton() {
        return this.canDownloadCustomization && !this.skipRender;
      }

      get showRedlineButton() {
        return this.canDownloadRedline && !this.skipRender;
      }

      get showDownloadRevisionHistory() {
        return this.canDownloadRevisionHistory && this.showPdfButton;
      }

      get showDownloadButton() {
        return (
          this.showPdfButton || this.showExportButton || this.showWatermarkedPdfButton
        );
      }

      get onlyShowPdfDownloadButton() {
        return this.showPdfButton && this.docTypes.length <= 0;
      }
    },
  ],
}; // end sbWatermarkedDocumentDownload
