import {
  Component,
  Inject,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SbxHttpClient } from '@/core/http';
import { SbxFormModalService } from '../../sbx-form-modal/sbx-form-modal.service';
import { SbxModalService } from '../../sbx-modal/sbx-modal.service';
import {
  SbxNewStakeholderModalComponent,
  ICreationParams,
} from './sbx-new-stakeholder-modal.component';
import { of } from 'rxjs';
import { Downgrade } from '@/shared/downgrade';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';

export enum SbxStakeholderBaseMode {
  FULL = 'FULL',
  SEARCH = 'SEARCH',
  CARD = 'CARD',
}

const NEW_STAKEHOLDER_ID = 'addNew';
@Downgrade.Component('ngShoobx', 'sbx-stakeholder-base')
@Component({
  selector: 'sbx-stakeholder-base',
  templateUrl: './sbx-stakeholder-base.component.html',
  styleUrls: ['./sbx-stakeholder-base.component.scss'],
})
export class SbxStakeholderBaseComponent implements OnInit {
  @Input() stakeholderOptions;
  @Input() placeholderText = 'Enter a name...';
  @Input() model;
  @Input() securityTicketId: string = null;
  @Input() modelKey: string;
  @Input() initShId: string = null;
  @Input() noClearButton = false;
  @Input() cardDisplayFormat = 'full';
  @Input() excludeFn; // used for lists of stakeholders
  @Input() showSearchIcon: boolean;
  @Input() mode: SbxStakeholderBaseMode = SbxStakeholderBaseMode.FULL;
  @Output() selectStakeholder = new EventEmitter();
  @Output() removeStakeholder = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() change = new EventEmitter();

  allowCreation;
  creationParams: ICreationParams = {};

  requireEmail = false;

  constructor(
    public formModalService: SbxFormModalService,
    public modalService: SbxModalService,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    private elRef: ElementRef,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getModel(): any {
    if (!this.model) {
      this.model = {};
      this.updateModel(null);
    }
    return this.model[this.modelKey];
  }

  updateModel(val): void {
    this.model[this.modelKey] = val;
    this.change.emit(this.elRef.nativeElement);
  }

  async ngOnInit(): Promise<void> {
    // Options for this current component, not the stakeholder-search component
    if (Object.prototype.hasOwnProperty.call(this.stakeholderOptions, 'requireEmail')) {
      this.requireEmail = this.stakeholderOptions.requireEmail;
      this.creationParams.requireEmail = this.requireEmail;
    }
    if (
      Object.prototype.hasOwnProperty.call(this.stakeholderOptions, 'allowCreation')
    ) {
      this.allowCreation = this.stakeholderOptions.allowCreation;
    }
    if (
      Object.prototype.hasOwnProperty.call(this.stakeholderOptions, 'creatorIsContact')
    ) {
      this.creationParams.creatorIsContact = this.stakeholderOptions.creatorIsContact;
    }
    if (
      Object.prototype.hasOwnProperty.call(this.stakeholderOptions, 'forceEphemeral')
    ) {
      this.creationParams.forceEphemeral = this.stakeholderOptions.forceEphemeral;
    }

    if (Object.prototype.hasOwnProperty.call(this.stakeholderOptions, 'createGuest')) {
      this.creationParams.createGuest = this.stakeholderOptions.createGuest;
    }
    // Options shared with Search
    const entityOptions = this.stakeholderOptions.entityOptions || {};
    this.creationParams.handleAffiliates = entityOptions.affiliates;
    this.creationParams.relationshipType = entityOptions.type;
    this.creationParams.allowSelfTeam = entityOptions.allowSelfTeam;
    this.creationParams.allowEntity = this.stakeholderOptions.allowEntity;
    this.creationParams.allowPerson = this.stakeholderOptions.allowPerson;

    // Creation modal options
    this.creationParams.affiliatePlaceholder =
      this.stakeholderOptions.affiliatePlaceholder;
    this.creationParams.titleText = this.stakeholderOptions.creationModalTitleText;
    this.creationParams.individualLabel =
      this.stakeholderOptions.creationModalIndividualLabel;
    this.creationParams.teamLabel = this.stakeholderOptions.createModalTeamLabel;
    this.creationParams.entityNameLabel =
      this.stakeholderOptions.createModalEntityNameLabel;
    this.creationParams.entityNamePlaceholder =
      this.stakeholderOptions.creationModalEntityNamePlaceholder;
    this.creationParams.affiliateLabel = this.stakeholderOptions.affiliateLabel;

    if (this.initShId) {
      const res = await this.sbxHttpClient
        .entity('1')
        .get(`stakeholders/${this.initShId}`, {
          params: { securityTicketID: this.securityTicketId },
        })
        .toPromise();
      this.updateModel(res);
    }
  }

  emitFocus(event) {
    this.focus.emit(event);
  }

  canEditSh(stakeholderObject) {
    return (
      stakeholderObject.sh &&
      (stakeholderObject.sh.ephemeral ||
        ((stakeholderObject.sh.relationType === 'investment' ||
          stakeholderObject.sh.relationType === 'investment_fund') &&
          this.creationParams.handleAffiliates))
    );
  }

  async editSh(stakeholderObject): Promise<void> {
    const res = await this.modalService.open(SbxNewStakeholderModalComponent, {
      size: 'lg',
      backdropClass: 'custom-modal-backdrop',
      windowClass: 'custom-modal',
      data: {
        ...this.creationParams,
        shObject: stakeholderObject,
        securityTicketId: this.securityTicketId,
      },
    }).result;
    this._addStakeholderObject(res.result);
  }

  async addFromStake(shObject): Promise<void> {
    const sh = shObject.sh;
    const data = {
      description: {
        fullName: sh.fullName,
        type: 'team',
        title: sh.fullName,
        parentForeignEntityId: sh.parentForeignEntityId,
        parentTitle: sh.parentTitle,
        parentId: sh.parentForeignEntityId,
        teamStakeholder: true,
      },
      fromStake: true,
      type: 'team',
      entityId: sh.id,
      processId: this.processUrlInfo.processId(),
      relationshipType: this.creationParams.relationshipType,
      asId: null,
      creatorIsContact: true,
      forceEphemeral: this.stakeholderOptions.forceEphemeral,
      createGuest: false,
    };

    const res = await this.sbxHttpClient
      .entity('1')
      .post('stakeholders', {
        params: {
          ...data,
          securityTicketID: this.securityTicketId,
        },
      })
      .toPromise();
    this._addStakeholderObject({ sh: res, type: 'team' });
  }

  async editEmail(stakeholderObject): Promise<void> {
    const form = new FormGroup({});
    const model = { email: stakeholderObject.sh.email };
    const formFields = of([
      {
        data: {},
        key: 'email',
        type: 'email-textline',
        templateOptions: {
          label: 'User Email Address',
          required: true,
          placeholder: 'Email',
          subfield: 0,
        },
      },
    ]);

    const config: ISbxFormModalConfig = {
      backdropClass: 'custom-modal-backdrop',
      windowClass: 'custom-modal',
      data: {
        title: 'provide user email address',
        form,
        model,
        formFields,
      },
    };

    await this.formModalService.open(config).result;
    this.updateEmail(stakeholderObject, model.email);
  }

  async updateEmail(stakeholderObject, newEmail): Promise<void> {
    try {
      await this.sbxHttpClient
        .entity('1')
        .post(`stakeholders/${stakeholderObject.sh.id}/add-contact-info`, {
          params: { email: newEmail, securityTicketID: this.securityTicketId },
        })
        .toPromise();
      stakeholderObject.sh.email = newEmail;
    } catch {}
  }

  addNewUserOption(searchText: string, results, searchGroups): void {
    if (this.allowCreation) {
      const name = searchText.split(' ');
      searchGroups.push({
        results: [
          {
            id: NEW_STAKEHOLDER_ID,
            displayName: 'Create New User',
            icon: 'plus',
            resultClass: 'sbx-gray-result',
            firstName: name.shift(),
            lastName: name.join(' '),
            fullName: searchText,
          },
        ],
      });
    }
  }

  requireEmailInput(stakeholderObject): boolean {
    return (
      this.requireEmail && stakeholderObject.sh && stakeholderObject.sh.missingEmail
    );
  }

  addItem(stakeholder): void {
    const stakeholderObject = {
      sh: stakeholder,
      type: stakeholder.teamStakeholder ? 'team' : 'person',
      processId: this.processUrlInfo?.processId(),
    };
    if (stakeholder.id === NEW_STAKEHOLDER_ID) {
      // We don't actually know the type, so clear it out
      stakeholderObject.type = '';
      this.editSh(stakeholderObject);
    } else if (stakeholder.stake) {
      this.addFromStake(stakeholderObject);
    } else {
      this._addStakeholderObject(stakeholderObject);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async _lockStakeholder(stakeholderObject: any, processId: string): Promise<void> {
    try {
      await this.sbxHttpClient
        .entity('2')
        .post(
          `stakeholder/${stakeholderObject.sh.id}/lock?lockId=${processId}&needsFamilyProcessId=true`,
          {
            params: {
              securityTicketID: this.securityTicketId,
            },
          },
        )
        .toPromise();
    } catch {}
  }

  _addStakeholderObject(stakeholderObject): void {
    stakeholderObject.processId = this.processUrlInfo?.processId();
    this.selectStakeholder.emit(stakeholderObject);
    if (this.requireEmailInput(stakeholderObject)) {
      this.editEmail(stakeholderObject);
    }
    this.updateModel(stakeholderObject);
    if (stakeholderObject.processId) {
      this._lockStakeholder(stakeholderObject, stakeholderObject.processId);
    }
  }

  removeItem(): void {
    this.removeStakeholder?.emit(this.getModel());
    this.updateModel(null);
  }

  canShowSearch(): boolean {
    switch (this.mode) {
      case SbxStakeholderBaseMode.CARD:
        return false;
      case SbxStakeholderBaseMode.SEARCH:
        return true;
      default:
        return !this.getModel();
    }
  }

  canShowCard(): boolean {
    switch (this.mode) {
      case SbxStakeholderBaseMode.SEARCH:
        return false;
      default:
        return this.getModel();
    }
  }
}
