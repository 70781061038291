import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SbxGestureConfig extends HammerGestureConfig {
  // Override default Hammer settings.
  overrides = <any>{};
}
