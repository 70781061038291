import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ISbxIconType } from '@/core/constants/icons';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { NgbContainerConfig } from '../sbx-form/fields/ngb-container.config';

export interface ISearchGroup {
  header: string;
  headerIcon: ISbxIconType;
  noResultsText: string;
  results: any[];
  footer: string;
  footerLink: string;
  error?: boolean;
}

/**
 *  Shoobx Type Ahead Search Bar
 *
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-search-bar type="document"></sbx-icon>
 *      `,
 *    })
 *    class MyComponent {
 *      constructor() {}
 *    }
 */
@Component({
  selector: 'sbx-search-bar',
  templateUrl: './sbx-search-bar.component.html',
  styleUrls: ['./sbx-search-bar.component.scss'],
  providers: [
    {
      provide: NgbTypeaheadConfig,
      useFactory: (ngbContainer: NgbContainerConfig) => {
        const config = new NgbTypeaheadConfig();

        if (!ngbContainer) {
          return config;
        }

        config.container = ngbContainer.container;
        config.popperOptions = ngbContainer.popperOptions;

        return config;
      },
      deps: [NGB_CONTAINER_TOKEN],
    },
  ],
})
export class SbxSearchBarComponent {
  @ViewChild('selectComponent') selectComponent;
  @Input() rowTemplate;
  @Input() placeholderText = '';
  @Input() searchResults: (text: string) => Observable<ISearchGroup[] | []>;
  @Input() hideIcon = false;
  @Input() disableAutoComplete = false;
  @Input() selectFirstOnEnter = true;
  @Output() select = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @Output() input = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();
  icon: ISbxIconType;
  errorState: ISearchGroup[] = [
    {
      header: null,
      headerIcon: null,
      footer: null,
      footerLink: null,
      error: true,
      noResultsText: null,
      results: [],
    },
  ];

  selectedOption: HTMLElement;

  resultFormatter = (result) => '';
  resultParser = (result) => result;

  constructor(
    private readonly typeaheadConfig: NgbTypeaheadConfig,
    private elementRef: ElementRef,
  ) {
    this.icon = 'search';
  }

  // This should only be triggered on "Enter" key press
  selectItem(event) {
    if (!this.selectedOption && !this.selectFirstOnEnter) {
      this.select.emit(this.selectComponent.model);
      return;
    }

    this.selectedOption =
      this.selectedOption || this.getTypeaheadWindow().querySelector('.result-item');

    if (this.selectedOption) {
      this.selectedOption.click();
      this.selectedOption = null;
    }
  }

  handleKeydown(event) {
    if (this.selectComponent.isPopupOpen()) {
      this.selectedOption =
        this.getTypeaheadWindow().querySelector('.sbx-selected-item');

      const selectOption = (el) => {
        if (this.selectedOption) {
          this.selectedOption.classList.remove('sbx-selected-item');
        }
        el.classList.add('sbx-selected-item');
        this.selectedOption = el;
      };

      const items = this.getTypeaheadWindow().querySelectorAll('.result-item');

      if (!items.length) {
        return;
      }

      const selectedIndex = this.selectedOption
        ? Array.prototype.indexOf.call(items, this.selectedOption)
        : -1;

      // Down key
      if (event.which === 40) {
        if (items.length === selectedIndex + 1) {
          selectOption(items[0]);
        } else {
          selectOption(items[selectedIndex + 1]);
        }
      }
      // Up key
      if (event.which === 38) {
        if (selectedIndex <= 0) {
          selectOption(items[items.length - 1]);
        } else {
          selectOption(items[selectedIndex - 1]);
        }
      }
    } else {
      this.selectedOption = null;
    }
  }

  handleFocus(event) {
    this.focus.emit(event);
  }

  handleBlur(event) {
    this.blur.emit(event);
  }

  onInputChange(event) {
    if (event.data) {
      this.icon = 'delete';
    } else {
      this.icon = 'search';
    }
    this.input.emit(event);
  }

  onSelect(item, $event) {
    if (item !== undefined) {
      this.select.emit(item);
      this.icon = 'search';
    }
    this.selectComponent.dismissPopup();
    this.selectComponent.clear();
    $event.stopPropagation();
  }

  onClear() {
    if (this.icon === 'delete') {
      this.selectComponent.clear();
      this.icon = 'search';
      this.clear.emit();
    }
  }

  private getTypeaheadWindow(): Element {
    const container = this.typeaheadConfig.container as string;
    let anchor = this.elementRef.nativeElement;

    if (container) {
      anchor = anchor.closest(container);
    }

    return anchor.querySelector('ngb-typeahead-window');
  }
}
