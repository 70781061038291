/**
 * @ngdoc directive
 * @kind function
 * @name sb.lib.signature.object:sbSignature
 *
 * @description
 * Generate signature blocks with by-line and title.
 *
 * @param {string} sbSignature The signature html supplied by backend.
 * @param {string} sbSignatureTitle The current signature title text.
 * @param {string} sbSignatureByLine The current signature by line text.
 * @param {boolean} [sbSignatureEditable=false] use textarea to display the
 *    signature block, or render the signature as-is.
 */
export const sbSignature = [
  '$compile',
  function ($compile) {
    const EDITABLE_TITLE = `<input type="text" id="sigPreviewTitle" name="sigPreviewTitle"
      class="form-control text-widget textline-field"
      data-ng-model="signatureTitle">`;
    const EDITABLE_BYLINES = `<textarea id="sigPreviewByLine" class="form-control"
      data-ng-model="signatureByLine"></textarea>`;
    return {
      restrict: 'A',
      scope: {
        signature: '=sbSignature',
        signatureTitle: '=sbSignatureTitle',
        signatureByLine: '=sbSignatureByLine',
        editable: '=sbSignatureEditable',
      },
      link(scope, element) {
        function editableTitle() {
          const compiledTitle = $compile('<br>' + EDITABLE_TITLE)(scope);
          element.find('.sig-title').html(compiledTitle);
        }
        function editableByLines() {
          const compiledBylines = $compile('<br>' + EDITABLE_BYLINES)(scope);
          element.find('.sig-by-line').html(compiledBylines);
        }
        function displayTitle() {
          let html = (scope.signatureTitle || '')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
          if (scope.signatureTitle) {
            html += '<br>';
          }
          element.find('.sig-title').html(html);
        }
        function displayByLines() {
          if (!scope.signatureByLine) {
            return;
          }
          const html =
            '<br>' +
            scope.signatureByLine
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/(?:\r\n|\r|\n)/g, '<br>');
          element.find('.sig-by-line').html(html);
        }

        const sig = scope.signature;
        if (!sig) {
          return;
        }

        element.html(sig);
        if (scope.editable) {
          editableTitle();
          editableByLines();
        } else {
          displayTitle();
          displayByLines();
        }
      },
    };
  },
]; // end sbSignature
