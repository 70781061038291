import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { ISbxIconType } from '@/core/constants/icons';
import { Observable, map } from 'rxjs';
import { PrincipalStakeholderSearchService } from './principal-stakeholder-search.service';
import { IStaticOption } from './models/static-options.model';
import { IStakeholderOptions } from './models/stakeholder-options.model';
import { IFilterOptions } from './models/filter-options.model';

const SELECTOR = 'sbx-principal-chooser';

const searchErrorResults = {
  id: 'searchError',
  $displayName: 'Error searching users...',
  $unselectable: true,
};

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-principal-chooser.component.html',
  styleUrls: ['./sbx-principal-chooser.component.scss'],
})
export class SbxPrincipalChooserComponent implements OnInit {
  @Input() placeholderText: string;
  @Input() options: IFilterOptions;
  @Input() ignoreKeys = [];
  @Input() staticOptions: IStaticOption[];
  @Input() name: string;
  @Input() initShId: string;
  @Input() excludeFn: () => boolean;

  @Output() change = new EventEmitter<any>();

  internalOptions: IStakeholderOptions;
  defaultChooserOptions = {
    allowExisting: true,
    restrictToCurrentUser: false,
    allowEntity: true, // list entities, TeamStakeholders
    allowPerson: true, // list Person Stakeholders
    allowCreation: false,
    stakes: false,
    withUsersOnly: false,
    format: 'medium',
    entityOptions: {
      affiliates: false,
      type: null,
      allowSelfTeam: false,
    },
    pickRepresentativeOnly: false,
  };
  defaultPlaceholder = 'Enter a name...';
  focused: boolean;
  queryText: string;
  keySelectedIndex: number;
  selectable: boolean;
  icon: ISbxIconType;
  trackBy = 'id';

  @ViewChild('selectComponent') selectComponent: Component;

  constructor(private service: PrincipalStakeholderSearchService) {
    this.searchStakeholderList = this.searchStakeholderList.bind(this);
  }

  ngOnInit(): void {
    this.internalOptions =
      this.options?.stakeholderOptions || this.defaultChooserOptions;
    this.ignoreKeys = this.ignoreKeys || [];
    this.placeholderText =
      this.placeholderText ||
      this.internalOptions.placeholderText ||
      this.defaultPlaceholder;

    this.staticOptions = (this.staticOptions || []).map((item) => {
      item.id = item.value;
      return item;
    });
  }

  filterStaticOptions(query: string) {
    this.queryText = query;

    const items = (this.staticOptions || []).filter((item) =>
      item.label.toLowerCase().includes(this.queryText.toLowerCase()),
    );

    return items;
  }

  searchStakeholderList(query: string): Observable<any[]> {
    const {
      entityOptions,
      allowPerson,
      allowStakes,
      restrictToCurrentUser,
      allowEntity,
      includeStates,
      excludeStates,
      includeGroups,
      onlyActive,
      withUsersOnly,
      pickRepresentativeOnly,
    } = this.internalOptions;
    const allowAffiliates = Boolean(entityOptions.affiliates);
    const type = entityOptions.type;
    const searchParams = {
      allowEntity,
      allowPerson,
      allowAffiliates,
      allowStakes,
      restrictToCurrentUser,
      type,
      includeStates,
      excludeStates,
      includeGroups,
      onlyActive,
      withUsersOnly,
    };

    return this.service.search(query, searchParams).pipe(
      map(
        (result) => {
          const shs = result.results.filter(this.excludeFn || (() => true));

          if (pickRepresentativeOnly) {
            shs.forEach((sh) => {
              if (sh.type === 'team') {
                sh.$unselectable = true;
              }
            });
          }

          const items = this.filterStaticOptions(query);

          const newItems = items
            .concat(shs)
            .filter((item) => !this.ignoreKeys.includes(item.id));

          const output = {
            results: newItems,
            noResultsText: 'No users found...',
          };

          return [output];
        },
        () => searchErrorResults,
      ),
    );
  }

  selectItem(event) {
    if (event.type === 'select') {
      return;
    }
    return this.change.emit({ newItem: event });
  }

  value(selectedStakeholder) {
    if (!selectedStakeholder) {
      return null;
    }

    if (selectedStakeholder.id) {
      return selectedStakeholder;
    }

    return selectedStakeholder.value;
  }
}
