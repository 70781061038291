import angular from 'angular';

function sortBySplitTags(list) {
  return (list || [])
    .map((tag) => [tag.split(':').length, tag])
    .sort(([lenA], [lenB]) => lenA - lenB)
    .map((tag) => tag[1]);
}
const ROOT_TAG_NAME = 'Data Room';

function prettyPrintTag(tag) {
  if (tag === ROOT_TAG_NAME) {
    return tag;
  }
  return tag
    .replace(ROOT_TAG_NAME + ' : ', '')
    .split(' : ')
    .join(' \\ ');
}
function findPrefix(needleTag, searchTags) {
  return searchTags.filter((tag) => tag !== needleTag && needleTag.startsWith(tag))
    .length;
}
function computeAdded(original, newModel) {
  return newModel.filter(
    (item) => !original.includes(item) && !findPrefix(item, newModel),
  );
}
function computeRemoved(original, newModel) {
  if (!newModel.length) {
    // If there is no model, just add the root. The algorithm below does not
    // work when newModel is empty.
    return [ROOT_TAG_NAME];
  }
  const rawRemoved = original.filter(
    (item) => !newModel.includes(item) && !findPrefix(item, newModel),
  );
  const removed = [];
  for (const removedTag of rawRemoved) {
    const removedTagParent = removedTag.split(':').slice(0, -1).join(':');
    for (const newTag of newModel) {
      if (newTag.startsWith(removedTagParent)) {
        removed.push(removedTag);
        break;
      }
    }
  }
  return removed;
}

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbMultiSelectTreeListing
 * @restrict EA
 *
 * @description
 * This is a _recursive_ directive that draws a permissions tree. This listing
 * if for when *many* items can be selected. A permission object is expected to
 * come in the form:
 *    * `.name` A programatic name that is unique to this permission.
 *    * `.title` A human readable title.
 *    * `.selected` The boolean model of this permission.
 *    * `.disabled` The boolean if this is disabled (considered one time binding).
 *    * `.description` An optional description string.
 *    * `.children` An array of permission objects.
 *
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {boolean} sbFolders True or false if this has folder icons.
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-multi-select-tree-listing
     data-ng-repeat="perm in ::sh.permissions track by perm.name"
     data-ng-model="perm">
   </sb-multi-select-tree-listing>
 */
export const sbMultiSelectTreeListing = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'EA',
      require: ['ngModel', '^^sbTree'],
      template: require('./templates/tree-listing.html'),
      scope: {
        collapse: '=sbCollapse',
        folders: '=sbFolders',
        model: '=ngModel',
        listingPrefix: '@sbTreeListingPrefix',
      },
      link: function (scope, element, attrs, controllers) {
        const sbTree = controllers[1];

        function onChange(newValue) {
          if (scope.model.disabled) {
            return;
          }
          scope.$broadcast('sbMultiSelectTreeListing::changeTo', newValue);
          if (!newValue) {
            scope.$emit('sbMultiSelectTreeListing::changeTo', false);
          }
          sbTree.$$setToTreeStructure(newValue, scope.model.name);
        }

        scope.$on('sbTreeListing::setValue', (evt, values) => {
          scope.model.selected =
            angular.isArray(values) && values.indexOf(scope.model.name) >= 0;
        });

        function onLabelClick() {
          if (scope.model.disabled) {
            return;
          }
          scope.model.selected = !scope.model.selected;
          onChange(scope.model.selected);
        }

        function onToggleCollapse() {
          scope.model.collapsed = !scope.model.collapsed;
        }

        scope.$on('sbMultiSelectTreeListing::changeTo', (evt, newValue) => {
          if (!scope.model.disabled) {
            scope.model.selected = newValue;
          }
        });

        scope.onChange = onChange;
        scope.onLabelClick = onLabelClick;
        scope.onToggleCollapse = onToggleCollapse;
        scope.MULTI = true;

        if (!scope.model.children || !scope.model.children.length) {
          return;
        }
        const contents = angular.element(
          `<span data-ng-show="!model.collapsed || !collapse">
          <sb-multi-select-tree-listing
            data-ng-class="{
              'opened': !model.collapsed || !collapse,
              'closed': model.collapsed && collapse,
              'selected': perm.selected,
            }"
            data-sb-tree-listing-prefix="{{ ::listingPrefix }}"
            data-sb-folders="::folders"
            data-sb-collapse="::collapse"
            data-ng-repeat="perm in ::model.children track by perm.name"
            data-ng-model="perm">
          </sb-multi-select-tree-listing>
        </span>`,
        );
        $compile(contents)(scope);
        element.append(contents);
      },
    };
  },
]; // end sbMultiSelectTreeListing

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbSingleSelectTreeListing
 * @restrict EA
 *
 * @description
 * This is a _recursive_ directive that draws a permissions tree. This listing
 * if for when *one* items can be selected. A permission object is expected to
 * come in the form described in {@link sbMultiSelectTreeListing}. This must
 * be used in conjuction with a parent `sbTree` as the root.
 *
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {boolean} sbFolders True or false if this has folder icons.
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-single-select-tree-listing
     data-ng-repeat="perm in ::sh.permissions track by perm.name"
     data-ng-model="perm">
   </sb-single-select-tree-listing>
 */
export const sbSingleSelectTreeListing = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'EA',
      require: ['ngModel', '^^sbTree'],
      template: require('./templates/tree-listing.html'),
      scope: {
        collapse: '=sbCollapse',
        folders: '=sbFolders',
        model: '=ngModel',
        listingPrefix: '@sbTreeListingPrefix',
      },
      link(scope, element, attrs, controllers) {
        const sbTree = controllers[1];

        function onChange(newValue) {
          if (scope.model.disabled) {
            return;
          }
          sbTree.$$toggleViewValue(newValue, scope.model.name);
        }

        function onToggleCollapse() {
          scope.model.collapsed = !scope.model.collapsed;
        }

        function onLabelClick() {
          // If we click the label and can't do anything other the toggle collapse
          // Then toggle collapse else check the boxes.
          if (
            scope.collapse &&
            scope.model.children &&
            scope.model.children.length > 0
          ) {
            onToggleCollapse();
          } else {
            scope.model.selected = !scope.model.selected;
            onChange(scope.model.selected);
          }
        }

        scope.$on('sbTreeListing::setValue', (evt, name) => {
          scope.model.selected = name === scope.model.name;
        });

        scope.onChange = onChange;
        scope.onLabelClick = onLabelClick;
        scope.onToggleCollapse = onToggleCollapse;
        scope.MULTI = false;

        if (!scope.model.children || !scope.model.children.length) {
          return;
        }
        const contents = angular.element(
          `<span data-ng-if="!model.collapsed || !collapse">
          <sb-single-select-tree-listing
            data-ng-class="{
              'opened': !model.collapsed || !collapse,
              'closed': model.collapsed && collapse,
              'selected': model.selected,
            }"
            data-sb-tree-listing-prefix="{{ ::listingPrefix }}"
            data-sb-folders="::folders"
            data-sb-single-select="::singleSelect"
            data-sb-collapse="::collapse"
            data-ng-repeat="perm in ::model.children track by perm.name"
            data-ng-model="perm">
          </sb-single-select-tree-listing>
        </span>`,
        );
        $compile(contents)(scope);
        element.append(contents);
      },
    };
  },
]; // end sbSingleSelectTreeListing

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbSingleSelectAnyTreeListing
 * @restrict EA
 *
 * @description
 * This is a _recursive_ directive that draws a permissions tree. This listing
 * if for when *one* items can be selected. A permission object is expected to
 * come in the form described in {@link sbMultiSelectTreeListing}. This must
 * be used in conjuction with a parent `sbTree` as the root.
 *
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {boolean} sbFolders True or false if this has folder icons.
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-single-select-any-tree-listing
     data-ng-repeat="perm in ::sh.permissions track by perm.name"
     data-ng-model="perm">
   </sb-single-select-tree-listing>
 */
export const sbSingleSelectAnyTreeListing = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'EA',
      require: ['ngModel', '^^sbTree'],
      template: require('./templates/tree-listing-any.html'),
      scope: {
        collapse: '=sbCollapse',
        folders: '=sbFolders',
        model: '=ngModel',
        listingPrefix: '@sbTreeListingPrefix',
        indent: '=sbIndent',
      },
      link(scope, element, attrs, controllers) {
        const sbTree = controllers[1];

        function onChange(newValue) {
          if (scope.model.disabled) {
            return;
          }
          sbTree.$$toggleViewValue(newValue, scope.model.name);
        }

        function onToggleCollapse() {
          scope.model.collapsed = !scope.model.collapsed;
        }

        function onLabelClick() {
          if (
            scope.collapse &&
            scope.model.children &&
            scope.model.children.length > 0
          ) {
            if (
              angular.isUndefined(scope.model.collapsed) ||
              scope.model.collapsed ||
              scope.model.selected
            ) {
              onToggleCollapse();
            }
          }
          if (!scope.model.selected) {
            scope.model.selected = !scope.model.selected;
            onChange(scope.model.selected);
          }
        }

        scope.$on('sbTreeListing::setValue', (evt, name) => {
          scope.model.selected = name === scope.model.name;
        });

        scope.onChange = onChange;
        scope.onLabelClick = onLabelClick;
        scope.onToggleCollapse = onToggleCollapse;

        if (!scope.model.children || !scope.model.children.length) {
          return;
        }
        const contents = angular.element(
          `<span data-ng-if="!model.collapsed || !collapse">
          <sb-single-select-any-tree-listing
            data-ng-class="{
              'opened': !model.collapsed || !collapse,
              'closed': model.collapsed && collapse,
              'selected': model.selected,
            }"
            data-sb-tree-listing-prefix="{{ ::listingPrefix }}"
            data-sb-folders="::folders"
            data-sb-single-select="::singleSelect"
            data-sb-collapse="::collapse"
            data-ng-repeat="perm in ::model.children track by perm.name"
            data-sb-indent="indent + 1"
            data-ng-model="perm">
          </sb-single-select-any-tree-listing>
        </span>`,
        );
        $compile(contents)(scope);
        element.append(contents);
      },
    };
  },
]; // end sbSingleSelectAnyTreeListing

/**
 * @ngdoc component
 * @name sb.lib.administration.component:sbTagTreeChanges
 *
 * @description
 * This is a directive for showing diffs on a tag list between its original
 * and newest state.
 *
 * @param {string[]} list This is the array of tags. The initial value will
 *   be treated as the original list to diff against.
 *
 * @example
   <sb-tag-tree-changes list="theList">
   </sb-tag-tree-changes>
 */
export const sbTagTreeChanges = {
  controllerAs: 'vm',
  template: require('./templates/list-changes.html'),
  bindings: {
    list: '<',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        this.prettyPrint = prettyPrintTag;

        // On init, save a copy of the original list sorted by the number
        // of colon seperators:
        const sortedOriginal = sortBySplitTags(this.list);

        // Trigger on changes to model, but skip the first (no diff anyway).
        $scope.$watchCollection(
          () => this.list,
          (nv, ov) => {
            if (nv === ov) {
              return;
            }
            nv = nv || [];
            this.added = computeAdded(sortedOriginal, nv);
            this.removed = computeRemoved(sortedOriginal, nv);
          },
        );
      };
    },
  ],
}; // end sbTagTreeChanges

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbTagsTree
 * @restrict EA
 *
 * @description
 * This is a directive for tags tree
 *
 * @param {boolean} sbTreeSingleSelect True or false if this tree only allows
 *    one slection at a time.
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {boolean} sbFolders True or false if this has folder icons.
 * @param {object} [sbTreeStructure] optional tree structure
 *    description
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-tags-tree
     name=""
     data-sb-tree-single-select="true"
     data-sb-collapse="true"
     data-sb-folders="folders"
     data-ng-model="model.value">
   </sb-tags-tree>
 */
export function sbTagsTree() {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: require('./templates/tags-tree.html'),
    scope: {
      name: '@name',
      model: '=ngModel',
      treeStructure: '=?sbTreeStructure',
      singleSelect: '&sbTreeSingleSelect',
      collapse: '=sbCollapse',
      folders: '=sbFolders',
    },
    link(scope, element, attrs, ngModelCtrl) {
      scope.sbTreeModel = angular.copy(scope.model);
      scope.$watch('sbTreeModel', (newValue) => {
        ngModelCtrl.$setViewValue(sortBySplitTags(newValue));
      });
    },
  };
}

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbGroupsTree
 * @restrict EA
 *
 * @description
 * This is a directive for stakeholder groups tree. This is a special type
 * of tree because (a) it has ui-groups which are title headers sprinkled
 * throughout (as defined by configuration) (b) Its auto- check/uncheck
 * functionality is defined by a separate tree (node.dependents) as opposed to
 * the viewing tree structure (node.children)
 *
 * NOTE this does not handle companyRoles groups, should be handled separately
 *
 * @param {boolean} sbTreeSingleSelect True or false if this tree only allows
 *    one slection at a time.
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {object} sbTreeStructure tree structure
 *    description
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-groups-tree
     name=""
     data-sb-collapse="true"
     data-ng-model="model.value">
   </sb-groups-tree>
*/
export function sbGroupsTree() {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: require('./templates/groups-tree.html'),
    scope: {
      name: '@name',
      model: '=ngModel',
      treeStructure: '<?sbTreeStructure',
      collapse: '<sbCollapse',
      sbUiGroups: '<?sbUiGroups',
      stakeholderId: '@?sbStakeholderId',
      context: '@?sbGroupsContext',
    },
    controller: [
      '$scope',
      '$q',
      'BackendLocation',
      'SimpleHTTPWrapper',
      function ($scope, $q, BackendLocation, SimpleHTTPWrapper) {
        const context =
          ($scope.context ? $scope.context + '/' : '') + 'securityGroupTrees';
        const GROUPS_URL = BackendLocation.context('1') + context;
        const updateSelections = (nodes) => {
          angular.forEach(nodes, (node) => {
            node.collapsed = $scope.collapse;
            node.selected = $scope.model.indexOf(node.name) > -1;
            updateSelections(node.children);
            if (!node.chilren && node.selected) {
              node.collapsed = false;
            }
            angular.forEach(node.children, (child) => {
              if (!child.collapsed) {
                node.collapsed = false;
              }
            });
          });
        };

        const setupTree = () => {
          if ($scope.stakeholderId && !($scope.sbUiGroups && $scope.treeStructure)) {
            return SimpleHTTPWrapper(
              {
                method: 'GET',
                url: `${GROUPS_URL}`,
                params: { shId: $scope.stakeholderId },
              },
              'Could not receive security groups',
            ).then((data) => {
              updateSelections(data.treeStructure);
              $scope.treeStructure = data.treeStructure;
              $scope.sbUiGroups = data.sbUiGroups;
              return true;
            });
          }

          const deferred = $q.defer();
          deferred.resolve(true);
          return deferred.promise;
        };
        $scope.setupTree = setupTree;
      },
    ],
    link: function (scope, element, attrs, ngModelCtrl) {
      scope.setupTree().then(() => {
        const flattenTreeValue = (nodes) => {
          const values = {};
          if (!nodes) {
            nodes = scope.treeStructure;
          }
          angular.forEach(nodes, (node) => {
            values[node.name] = node;
            angular.extend(values, flattenTreeValue(node.children));
          });
          return values;
        };

        const flatGroups = flattenTreeValue(scope.treeStructure);

        // Recursive helper function to handle unchecking checkboxes.
        const uncheckCheckboxes = (newValues, oldValues) => {
          // Get list of unchecked boxes
          const uncheckedByUser = oldValues.filter((v) => newValues.indexOf(v) < 0);

          // Uncheck everything in above list
          let toUncheck = uncheckedByUser;

          // Uncheck all the dependents of unchecked items
          uncheckedByUser.forEach((unchecked) => {
            toUncheck = toUncheck.concat(flatGroups[unchecked].dependents);
          });

          // Uncheck all elements with unchecked dependents
          newValues.forEach((newGroupId) => {
            const allDeps = flatGroups[newGroupId].dependents;
            // If any of my dependents is not checked, uncheck me and all
            // my dependents
            allDeps.forEach((dep) => {
              if (uncheckedByUser.indexOf(dep) >= 0) {
                toUncheck.push(newGroupId);
              }
            });
          });

          // Get list of boxes still checked
          let lst = newValues.filter((v) => toUncheck.indexOf(v) < 0);

          // If we unchecked any boxes in the current iteration, recurse the function
          // to make sure we pick up any other dependents/parents that need to be unchecked
          if (!angular.equals(newValues.sort(), lst.sort())) {
            const newLst = uncheckCheckboxes(lst, newValues);
            // Updated list of boxes still checked
            lst = lst.filter((v) => newLst.indexOf(v) >= 0);
          }
          return lst;
        };
        // Recursive helper function to handle find all dependents of a given node.
        const findDependents = (nodeToCheck) => {
          let toCheck = [];
          const dependents = nodeToCheck.dependents;
          toCheck = toCheck.concat(dependents);
          // Check its dependents
          dependents.forEach((dep) => {
            const depNode = flatGroups[dep];
            if (depNode.dependents.length > 0) {
              toCheck = toCheck.concat(findDependents(depNode));
            }
          });
          return toCheck;
        };
        /* Model watcher for autclick dependent groups
           This will recursively be called (you watch a model that you will update
           in the watcher function. So each level just has to check its own
           dependencies and check/uncheck them
        */
        const updateCheckboxes = (newValues, oldValues) => {
          newValues = newValues || [];
          oldValues = oldValues || [];
          if (angular.equals(newValues.sort(), oldValues.sort())) {
            return;
          }

          let result = {};
          // If there is a newly checked element, click its children
          if (newValues.length > oldValues.length) {
            // This should be improved
            let selected = Object.values(flatGroups).filter((i) => i.selected);
            selected = selected.map((i) => i.name);
            result = selected.reduce((acc, cVal) => {
              acc[cVal] = true;
              angular.extend(
                acc,
                flatGroups[cVal].dependents.reduce((pdep, cdep) => {
                  pdep[cdep] = true;
                  return pdep;
                }, {}),
              );
              return acc;
            }, {});

            const keys = Object.keys(result);
            if (!angular.equals(newValues.sort(), keys.sort())) {
              ngModelCtrl.$setViewValue(keys);
              updateCheckboxes(keys, newValues);
            }
          } else {
            // Unchecking case is more complicated, because it has two cases:
            // (1) if unchecked node was directly checked, uncheck it and its
            // dependents
            // (2) if unchecked node was indirectly checked (i.e., lastUnchecked
            // is true for a node), uncheck its tree and check only the node and
            // its dependents

            // First, we find the nodes to be unchecked
            const uncheckedByUser = oldValues.filter((v) => newValues.indexOf(v) < 0);
            // If lastUnchecked is true for any unchecked node, then we are in scenario (2)
            let lastUnchecked = uncheckedByUser.filter(
              (v) => flatGroups[v].lastUnchecked,
            );
            let toCheck = [];
            if (lastUnchecked.length === 1) {
              lastUnchecked = lastUnchecked[0];
              const lastUncheckedNode = flatGroups[lastUnchecked];
              lastUncheckedNode.indirect = false;
              // Find all nodes that should be checked in scenario (2)
              toCheck = findDependents(lastUncheckedNode);
              toCheck.push(lastUncheckedNode.name);
            }
            // Find out which nodes remain checked after performing the unchecking operation
            let results = uncheckCheckboxes(newValues, oldValues);
            // Add in any nodes that need to be checked to fulfill scenario 2
            results = results.concat(toCheck);
            // Update model.
            ngModelCtrl.$setViewValue(results);
          } // endElse
        };
        // Start watcher
        scope.$watch('model', updateCheckboxes);
        // Initialize checkboxes
        let selected = Object.values(flatGroups).filter((i) => i.selected);
        selected = selected.map((i) => i.name);
        updateCheckboxes(selected, []);
        // End Model watcher for autoclick dependent groups

        // Set headers in display structure
        scope.groupTreeStructure = [];
        const seen = [];

        const keys = Object.keys(scope.sbUiGroups);
        keys.sort((a, b) => {
          return scope.sbUiGroups[a].weight - scope.sbUiGroups[b].weight;
        });

        keys.forEach((gindex) => {
          const group = scope.sbUiGroups[gindex];
          angular.forEach(scope.treeStructure, (node) => {
            if (node.uiGroupId === gindex) {
              if (seen.indexOf(gindex) < 0) {
                seen.push(gindex);
                node.uiGroupTitle = group.title;
              }
              scope.groupTreeStructure.push(node);
            }
          });
        });
      });
    },
  };
}

/**
 * @ngdoc directive
 * @name sb.lib.administration.directive:sbTree
 * @restrict EA
 *
 * @description
 * This is a root directive for a tree.
 *
 * @param {boolean} sbTreeSingleSelect True or false if this tree only allows
 *    one slection at a time.
 * @param {boolean} sbCollapse True or false if this supports collapse.
 * @param {boolean} sbFolders True or false if this has folder icons.
 * @param {boolean} sbIndirectChecking optional, true if tree should support
 *    indirect checking (children will be checked in gray, and if they are
 *    clicked when checked, they will become directly checked).
 * @param {object} [sbTreeStructure=undefined] optional tree structure
 *    description. Nodes with a "ui-group-title" will have header text shown
 *    above them
 * @param {expression} ngModel Model expression.
 *
 * @param {boolean} allSelectable All nodes are selectable, not just leaves
 *
 * @example
   <sb-tree
     data-sb-tree-single-select="true"
     data-sb-collapse="true"
     data-sb-folders="folders"
     data-ng-model="model.value">
   </sb-tree>
 */
export function sbTree() {
  return {
    restrict: 'EA',
    require: ['ngModel', 'sbTree'],
    template: require('./templates/tree.html'),
    scope: {
      treeStructure: '=?sbTreeStructure',
      singleSelect: '&sbTreeSingleSelect',
      collapse: '=sbCollapse',
      folders: '=sbFolders',
      indirectChecking: '<?sbIndirectChecking',
      model: '=ngModel',
      allSelectable: '=sbAllSelectable',
    },
    controller: angular.noop,
    link: {
      pre(scope, element, attrs, controllers) {
        const flattenTreeValue = (
          nodes,
          parentSelected,
          newValue = false,
          nodeName = '',
        ) => {
          let values = [];

          if (!nodes) {
            nodes = scope.treeStructure;
          }

          const dependents =
            nodes && nodes[0].selected && nodes[0].dependents
              ? nodes[0].dependents
              : [];
          angular.forEach(nodes, (node) => {
            if (node.selected) {
              values.push(node.name);
            }
            if (scope.indirectChecking) {
              // lastUnchecked is true if this node was indirectly checked before, and
              // the user just clicked on it. In this case, we want to make this node
              // directly checked. This will require unchecking this node's tree, and
              // then checking only the node and its dependents.
              node.lastUnchecked = !newValue && node.indirect && node.name === nodeName;
              // If the parent is selected, then this node is only selected
              // indirectly, not directly. Same if the top level components are
              // dependents, then they are also selected indirectly (e.g.,
              // this happens when the user selects "Company Full Access", a
              // top-level permission, which has several other top-level
              // permissions as dependents).
              node.indirect = parentSelected || dependents.indexOf(node.name) > -1;
            }
            angular.forEach(node.children, (child) => {
              values = values.concat(
                flattenTreeValue(
                  [child],
                  node.indirect || node.selected || node.lastUnchecked,
                  newValue,
                  nodeName,
                ),
              );
            });
          });
          return values;
        };

        const [ngModel, sbTree] = controllers;
        scope.prefix = attrs.name;

        sbTree.$$setToTreeStructure = (newValue, nodeName) => {
          const flattenedList = flattenTreeValue(
            scope.treeStructure,
            false,
            newValue,
            nodeName,
          );
          ngModel.$setViewValue(flattenedList);
        };

        sbTree.$$toggleViewValue = (value, name) => {
          // Only un-select the currently selected value
          if (!value && scope.model === name) {
            ngModel.$setViewValue('');
          } else if (value) {
            // add validators
            ngModel.$setViewValue(name);
          }
          ngModel.$render();
        };
        scope.$watchCollection('model', (newValue) => {
          scope.$broadcast('sbTreeListing::setValue', newValue);
        });

        ngModel.$render = () => {
          scope.$broadcast('sbTreeListing::setValue', ngModel.$viewValue);
        };
      }, // end pre link
    },
  };
} // end sbTree

// XXX: eventually, the tree described in setUpUploadExistingPicker
// in src/shoobx/app/ui/resources/js/shoobx.js
// should probably move in here too.
