import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxAlertBannerComponent } from './sbx-alert-banner.component';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxPopoverModule],
  declarations: [SbxAlertBannerComponent],
  exports: [SbxAlertBannerComponent],
})
export class SbxAlertBannerModule {
  static entryComponents = [SbxAlertBannerComponent];

  static forRoot(): ModuleWithProviders<SbxAlertBannerModule> {
    return {
      ngModule: SbxAlertBannerModule,
    };
  }
}
