import { Input, Directive, HostBinding, Inject } from '@angular/core';
import { $processStatusModal } from '../../../js/process/modal';
import { HostListener } from '@angular/core';

/**
 * A button that, when clicked, displays the status modal for a process
 *
 * processId
 *
 * Example:
 *  <sbx-process-status-button
 *    processId='12345678'
 *    iconButton='false'>
 *  </sbx-process-status-button>
 */
@Directive({
  selector: '[sbxProcessStatusButton]',
})
export class SbxProcessStatusButtonDirective {
  @Input() sbxProcessStatusButton: string;
  @HostBinding('class.sbx-process-status-button') defaultClass = true;
  @HostListener('click') click() {
    this.processStatusModal(this.sbxProcessStatusButton);
  }

  constructor(@Inject($processStatusModal) private processStatusModal) {}
}
