const STATUS_ICON_MAP = Object.freeze({
  Active: 'fa fa-check-circle doc-status-active',
  Pending: 'sbf doc-status-pending',
  Inactive: 'fa fa-times-circle doc-status-inactive',
});

/**
 * @ngdoc component
 * @name sb.dataroom.component:sbDocumentStatusType
 *
 * @description
 * This element will display a status icon/text for a document listing.
 *
 * @param {string} status The status string of the document.
 */
export const sbDocumentStatusType = {
  controllerAs: 'vm',
  template: require('./templates/status-type.html'),
  bindings: {
    status: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.icon = STATUS_ICON_MAP[this.status] + ' status-icon';
      };
    },
  ],
};

/**
 * @ngdoc component
 * @name sb.dataroom.component:sbDocumentDetailsSummary
 *
 * @description
 * This element will display a list of prominent metadata and other info. Empty values,
 * effective date, and status will _not_ be displayed.
 *
 * @param {object} document The document object.
 *   @property {array<object>} prominent_metadata List of `value_html`/`label` pairs.
 */
export const sbDocumentDetailsSummary = {
  controllerAs: 'vm',
  template: require('./templates/details-summary.html'),
  bindings: {
    document: '<',
  },
  controller: function () {
    this.$onInit = () => {
      const { document: doc } = this;
      const promMd = (doc.prominent_metadata || []).filter((md) => {
        // Filter any values missing value_html. We also dont show ED/status since
        // its redundant with the listing itself.
        const label = md.label.toLowerCase();
        return label !== 'effective date' && label !== 'status' && md.value_html;
      });
      this.listing = Object.freeze(promMd);
    };
  },
};

/**
 * @ngdoc component
 * @name sb.dataroom.component:sbDataroomListing
 *
 * @description
 * This element will a listing of folders/documents dataroom display
 *
 * @param {array<folder>} folders List of folder objects.
 *   @param {string} id Unique folder ID.
 *   @param {string} title Human readable title.
 *   @param {string} parent Unique ID of folder.
 *   @param {boolean} managed If folder is managed/unmanaged by Shoobx.
 *   @param {array<folder>} [breadcrumbs=undefined] If defined and current folder is not
 *     the listed folder's parent, this will display as a "mini" breadcrumb.
 * @param {array<document>} documents List of document objects.
 *   @param {string} id Unique doc ID.
 *   @param {string} title Human readable title.
 *   @param {boolean} managed If doc is managed/unmanaged by Shoobx.
 *   @param {string} status Document status (Active, Inactive, etc.).
 *   @param {string} date Effective date (already formatted).
 * @param {boolean} loading When truthy, listing will be in "loading" state.
 * @param {string} currentFolderId The ID of the currently displayed folder.
 * @param {boolean} isRoot Set to true when at root.
 * @param {function} isDocumentOpen When called with doc, must return bool if details
 *   are visible.
 * @param {expression} onFolderClick This will be evaluated on click that requrires folder
 *   navigation. `$folder` will be in the namespace.
 * @param {expression} onDocumentClick This will be evaluated on click of a document.
 *   `$document` will be in the namespace.
 * @param {expression} gotoRoot This will be evaluted when user requests root navigation.
 * @param {expression} changeSort This will be evaluted when user requests a change
 *   to the sorting. `$columnName` will be in the namespace and will be `'name'` or
 *   `'date'`.
 * @param {expression} [onOpenDocumentToggle=undefined] Will be evaluted when document
 *   details are toggled open/closed. `$document` will be in the namespace.
 * @param {boolean} [isSearch=false] If truthy, the empty view will have search flavor.
 * @param {string} [sortKey=undefined] Expected to be `'name'` or `'date'`.
 * @param {string} [sortOrder=undefined] Expected to be `'asc'` or `'dsc'`.
 * @param {string} [selectedId=undefined] ID of "selected/hightlighted" document.
 * @param {string|number} [height=650] Number of pixels height of the listing.
 * @param {boolean} [onlyUnmanaged=undefined] Whether or not we're displaying only unmanaged documents.
 */
export const sbDataroomListing = {
  controllerAs: 'vm',
  template: require('./templates/dr-listing.html'),
  bindings: {
    folders: '<',
    documents: '<',
    loading: '<',
    currentFolderId: '<',
    isRoot: '<',
    isDocumentOpen: '<',
    onFolderClick: '&',
    onDocumentClick: '&',
    gotoRoot: '&',
    changeSort: '&',
    onOpenDocumentToggle: '&',
    isSearch: '<?',
    sortKey: '<?',
    sortOrder: '<?',
    selectedId: '<?',
    onlyUnmanaged: '<?',
  },
  controller: [
    function () {
      function toggleDocumentDetails(evt, $document) {
        evt.stopPropagation();
        this.onOpenDocumentToggle({ $document });
      }
      function rootNav(evt) {
        evt.preventDefault();
        this.gotoRoot();
      }
      this.$onInit = () => {
        this.toggleDocumentDetails = toggleDocumentDetails.bind(this);
        this.rootNav = rootNav.bind(this);
      };
    },
  ],
}; // end sbDataroomListing
