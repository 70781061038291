import angular from 'angular';
import Modal from '../modal/modal';
import Table from '../table';
import RX from '../rx';

import {
  sbTree,
  sbSingleSelectTreeListing,
  sbMultiSelectTreeListing,
  sbSingleSelectAnyTreeListing,
  sbTagTreeChanges,
  sbTagsTree,
  sbGroupsTree,
} from './permissions';
import { sbFolderTree, FolderManager } from './folder';
import { SettingsService } from './settings';
import { sbAccessKeys, AccessKeysModel, $accessKeySecretModal } from './accesskey';

/**
 * @ngdoc overview
 * @name sb.lib.administration
 *
 * @description
 * This module houses administration helpers
 */
export default angular
  .module('sb.lib.administration', [Modal, Table, RX])

  .component('sbAccessKeys', sbAccessKeys)
  .component('sbTagTreeChanges', sbTagTreeChanges)
  .component('sbFolderTree', sbFolderTree)
  .directive('sbTree', sbTree)
  .directive('sbMultiSelectTreeListing', sbMultiSelectTreeListing)
  .directive('sbSingleSelectTreeListing', sbSingleSelectTreeListing)
  .directive('sbSingleSelectAnyTreeListing', sbSingleSelectAnyTreeListing)
  .directive('sbTagsTree', sbTagsTree)
  .directive('sbGroupsTree', sbGroupsTree)
  .factory('SettingsService', SettingsService)
  .factory('AccessKeysModel', AccessKeysModel)
  .factory('$accessKeySecretModal', $accessKeySecretModal)
  .factory('FolderManager', FolderManager).name;
