import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import { DocumentHeader } from '@shoobx/types/webapi-v2';

@Injectable()
export class SbxDocumentHeaderService {
  constructor(private http: SbxHttpClient) {}

  getDocumentHeader(id: string): Observable<DocumentHeader> {
    return this.http.entity('2').get(`documents/${id}/header`);
  }

  getDocumentTitleFormUrl(id: string) {
    return `documents/${id}/editTitle`;
  }
}
