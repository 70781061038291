<sbx-messages></sbx-messages>

<ng-container *ngIf="documentReferences$ | async as documentReferences">
  <h3>Documents Referenced in This Document</h3>
  <div>
    <ng-container
      *ngIf="documentReferences.references.length; else emptyReferencesTemplate"
    >
      <sbx-document-reference-group
        *ngFor="let referenceGroup of documentReferences.references"
        [group]="referenceGroup"
        (removeReference)="handleRemoveReference(documentId, $event)"
      ></sbx-document-reference-group>
    </ng-container>

    <sbx-button
      title="Add Reference"
      icon="link"
      class="sbx-view-document-button sbx-add-user-reference"
      (click)="handleAddReference()"
      *ngIf="documentReferences.canManageReferences"
    ></sbx-button>
  </div>

  <h3>Documents Containing References To This Document</h3>
  <div>
    <ng-container
      *ngIf="documentReferences.backreferences.length; else emptyBackReferencesTemplate"
    >
      <sbx-document-reference-group
        *ngFor="let referenceGroup of documentReferences.backreferences"
        [group]="referenceGroup"
        (removeReference)="handleRemoveReference($event, documentId)"
      ></sbx-document-reference-group>
    </ng-container>

    <sbx-button
      title="Add Reference"
      icon="link"
      class="sbx-view-document-button sbx-add-user-back-reference"
      (click)="handleAddBackReference()"
      *ngIf="documentReferences.canManageReferences"
    ></sbx-button>
  </div>
</ng-container>

<ng-template #emptyReferencesTemplate>
  <p>This document contains no references to other documents.</p>
</ng-template>

<ng-template #emptyBackReferencesTemplate>
  <p>There is no document containing references to this document.</p>
</ng-template>
