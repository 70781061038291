import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'sbx-compiled-content',
})
export class SbxCompiledContentComponent extends UpgradeComponent {
  @Input() content: object;
  @Input() extraContext: object;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('sbCompiledContentComponent', elementRef, injector);
  }
}
