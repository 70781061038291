/**
 * @ngdoc directive
 * @name sb.onboarding.sbOnboardingTicketsPage
 *
 * This is a directive that creates the "My Tickets" Page.
 *
 * Most parameters pass through to the sbFilteredListPage, so look there for
 * what the paramters are for.
 **/
export const sbReportsPage = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/reports.html'),
      scope: {
        title: '@sbTitle',
        redirect: '@sbRedirect',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbItems',
        columns: '<sbColumns',
        endpoint: '@sbEndpoint',
      },
      controller: [
        '$scope',
        'SbxExportFormProvider',
        'SbxFormModalService',
        function ($scope, SbxExportFormProvider, SbxFormModalService) {
          $scope.model = Object.assign({}, ListPageModel);
          $scope.model.initialize(
            $scope.filterData,
            $scope.requiredFilterValues,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + $scope.endpoint,
              method: 'POST',
            },
            ListLocation.updateUrl,
          );
          $scope.model.hidePaging = false;
          ListLocation.updateModelOnLocationChange($scope, null, false);
          if ($scope.model.sort.sort.endsWith('_rev')) {
            $scope.sortKey = $scope.model.sort.sort.slice(0, -4);
            $scope.reverse = true;
          } else {
            $scope.sortKey = $scope.model.sort.sort;
            $scope.reverse = false;
          }

          function changeSort(evt, newSortKey) {
            evt.preventDefault();
            let key = newSortKey;

            if (newSortKey === $scope.sortKey) {
              $scope.reverse = !$scope.reverse;
            } else {
              $scope.reverse = false;
            }
            if ($scope.reverse) {
              key = key + '_rev';
            }
            $scope.sortKey = newSortKey;
            $scope.model.sort.sort = key;
            $scope.model.setSort();
          }

          $scope.changeSort = changeSort;

          if ($scope.actions) {
            $scope.actions.forEach((action) => {
              action.click = async function () {
                if (action.link) {
                  const params = await SbxExportFormProvider.getForm()
                    .then((formConfig) => SbxFormModalService.open(formConfig).result)
                    .then((result) => result.result);
                  const queryParams = new URLSearchParams(params).toString();

                  window.location.href = `${action.link}?${queryParams}`;
                }
              };
            });
          }
        },
      ],
    };
  },
];
