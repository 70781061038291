import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SbxModalComponent } from './sbx-modal.component';
import { ISbxModalConfig } from './interfaces';
import { ComponentType } from '@angular/cdk/overlay';
import { NavigationStart, Router } from '@angular/router';

export abstract class SbxNgbModalRef extends NgbModalRef {
  innerComponentInstance?: any;
  beforeDismiss?(): Promise<void> | void;
  beforeClose?(): Promise<void> | void;
}

/**
 *  Shoobx Modal Service
 *
 *  Service for managing modals on a page using a stack.
 *  Wraps bootstrap's modal service.
 *
 *  Should not be needed for direct use in view components.
 *
 *  See: SbxModalDirective
 */
@Injectable({ providedIn: 'root' })
export class SbxModalService {
  private modals: SbxNgbModalRef[] = [];

  constructor(
    private modalService: NgbModal,
    private readonly router: Router,
  ) {
    this.listenToRouterEvents();
  }

  private add(modal: SbxNgbModalRef) {
    this.modals.push(modal);
  }

  /**
   * Creates a new modal given a tempalte and configuration and
   * pushes it onto the stack.
   *
   * @returns the created modal.
   **/
  open<DataModel>(
    content: TemplateRef<unknown> | ComponentType<unknown>,
    sbxModalConfig: ISbxModalConfig<DataModel> = {},
  ): SbxNgbModalRef {
    const ngbModalOptions: NgbModalOptions = {
      keyboard: false,
      scrollable: true,
    };

    // Remove size option if 'md', and allow service to handle defaults
    if (sbxModalConfig.size !== 'md') {
      ngbModalOptions.size = sbxModalConfig.size;
    }

    // Clicking backdrop closes modal but doesn't fire dismiss. To prevent that
    // we disable backdrop clicks.
    ngbModalOptions.backdrop = 'static';

    if (sbxModalConfig.windowClass) {
      ngbModalOptions.windowClass = sbxModalConfig.windowClass;
    }

    if (sbxModalConfig.backdropClass) {
      ngbModalOptions.backdropClass = sbxModalConfig.backdropClass;
    }

    const modal: SbxNgbModalRef = this.modalService.open(
      SbxModalComponent,
      ngbModalOptions,
    );
    // It's a bad idea to access private variable of modal instance, but it looks
    // like a better hack than using querySelector to select element.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modal._backdropCmptRef.location.nativeElement.style = '';

    // Function means component
    if (typeof content === 'function') {
      const componentRef =
        modal.componentInstance.containerRef.createComponent(content);

      if (componentRef.instance.beforeDismiss) {
        modal.beforeDismiss = componentRef.instance.beforeDismiss;
      }

      if (componentRef.instance.beforeClose) {
        modal.beforeClose = componentRef.instance.beforeClose;
      }

      // Set component instance data
      Object.entries(sbxModalConfig.data || {}).forEach(([key, value]) => {
        componentRef.instance[key] = value;
      });

      modal.innerComponentInstance = componentRef.instance;
    } else {
      modal.componentInstance.containerRef.createEmbeddedView(
        content,
        sbxModalConfig.data,
      );
    }

    // If upper level beforeDismiss is defined, override component level dismiss
    if (sbxModalConfig.beforeDismiss) {
      modal.beforeDismiss = sbxModalConfig.beforeDismiss;
    }

    // If upper level beforeClose is defined, override component level dismiss
    if (sbxModalConfig.beforeClose) {
      modal.beforeClose = sbxModalConfig.beforeClose;
    }

    this.add(modal);

    return modal;
  }

  /**
   * Closes the current modal.
   */
  async close(result?) {
    const modal = this.modals[this.modals.length - 1];

    try {
      if (modal.beforeClose) {
        // Passing rendered components 'this' context
        await modal.beforeClose.call(
          modal.innerComponentInstance ? modal.innerComponentInstance : this,
        );
      }

      modal.close({ result });
      this.modals.pop();
    } catch {}
  }

  /**
   * Dismisses the current modal.
   */
  async dismiss(reason = { userCancelled: true, message: null }) {
    const modal = this.modals[this.modals.length - 1];

    try {
      if (modal.beforeDismiss) {
        // Passing rendered components 'this' context
        await modal.beforeDismiss.call(
          modal.innerComponentInstance ? modal.innerComponentInstance : this,
        );
      }

      modal.dismiss(reason);
      this.modals.pop();
    } catch {}
  }

  private listenToRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dismissAll();
      }
    });
  }

  private async dismissAll() {
    await Array.from({ length: this.modals.length }).forEach(async () => {
      await this.dismiss();
    });
  }
}
