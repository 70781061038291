const QUORUM_MESSAGE =
  'The board composition does not allow for a quorum based ' +
  'on the number of empty board seats. Delaware Law generally provides that ' +
  'a quorum is a majority of the total number of directors, unless the ' +
  'certificate of incorporation or bylaws provide that a greater or lesser ' +
  'percentage (but not less than one third of the total number) of ' +
  'directors constitutes a quorum. You should review the balance of empty ' +
  'board seats and consult with your attorney on composition of the board ' +
  'if you have questions.';

/**
 * @ngdoc object
 * @name sb.workitem.editBoard.controller:EditBoardWiCtrl
 * @requires sb.workitem.editBoard.EditBoardModel
 *
 * @description
 * Controller for the edit board workitem.
 */
export const EditBoardWiCtrl = [
  '$scope',
  '$element',
  '$timeout',
  'EditBoardModel',
  'ProcessStatus',
  'ProcessButtonModel',
  '$confirm',
  '$q',
  function (
    $scope,
    $element,
    $timeout,
    EditBoardModel,
    ProcessStatus,
    ProcessButtonModel,
    $confirm,
    $q,
  ) {
    // We must manually trigger click events because the circle wants to have
    // a side effect on the table. We do it in (native) timeout to avoid
    // the double $digest.
    $scope.circleEdit = (member) => {
      const select = `sb-table .table-item-${member.id} .btn-edit`;
      $timeout(
        () => {
          $element.find(select).click();
        },
        0,
        false,
      );
    };
    $scope.circleAdd = () => {
      $timeout(
        () => {
          $element.find('sb-table table + .btn-int').click();
        },
        0,
        false,
      );
    };

    $scope.model = EditBoardModel;
    EditBoardModel.$load()
      .then(() => {
        $scope.COLUMNS = [
          { name: 'Name', key: 'fullName' },
          { name: 'Email', key: 'email' },
        ]
          .concat($scope.model.$columns)
          .map((col) => {
            col.key = '$' + col.key;
            return col;
          });
      })
      .catch((reason) => {
        ProcessStatus.$setStatus(reason, 'danger');
      });

    ProcessButtonModel.$addSubmitCondition(
      'continue',
      () => {
        if (EditBoardModel.$quorum === false) {
          return $confirm({
            body: QUORUM_MESSAGE,
            alertType: 'warning',
            confirmButtonText: 'Continue',
          });
        }
        return $q.resolve();
      },
      1,
    );
  },
]; // end EditBoardWiCtrl
