import angular from 'angular';
import Form from '../form/form';
import Tools from '../tools';
import Events from '../events/events';
import RX from '../rx';

import { sbFieldConfigurationListing } from './listing';
import {
  sbGroupFieldConfiguration,
  isGroupFieldConfigurationType,
  sbFieldConfiguration,
} from './field';

/**
 * @ngdoc overview
 * @name sb.lib.fieldConfiguration
 *
 * @description
 * This module houses widgets to _configure_ form fields.
 */
export default angular
  .module('sb.lib.fieldConfiguration', [RX, Form, Tools, Events])

  .component('sbFieldConfiguration', sbFieldConfiguration)
  .component('sbFieldConfigurationListing', sbFieldConfigurationListing)
  .component('sbGroupFieldConfiguration', sbGroupFieldConfiguration)
  .factory('isGroupFieldConfigurationType', isGroupFieldConfigurationType).name;
