import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@/shared/shared.module';
import { SbxTaxService } from './sbx-tax.service';
import { SbxTaxAdjustEventModalService } from './sbx-tax-adjust-event-modal/sbx-tax-adjust-event-modal.service';
import { SbxTaxAdjustEventModalComponent } from './sbx-tax-adjust-event-modal/sbx-tax-adjust-event-modal.component';
import { SbxTaxAdjustEventTaxesComponent } from './sbx-tax-adjust-event-taxes/sbx-tax-adjust-event-taxes.component';
import { SbxTaxCustomEntryModalComponent } from './sbx-tax-custom-entry-modal/sbx-tax-custom-entry-modal.component';
import { SbxTaxCustomEntryModalService } from './sbx-tax-custom-entry-modal/sbx-tax-custom-entry-modal.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [
    SbxTaxService,
    SbxTaxAdjustEventModalService,
    SbxTaxCustomEntryModalService,
  ],
  declarations: [
    SbxTaxAdjustEventModalComponent,
    SbxTaxCustomEntryModalComponent,
    SbxTaxAdjustEventTaxesComponent,
  ],
})
export class SbxTaxModule {}
