import angular from 'angular';
import sbBootstrap from './../bootstrap/bootstrap';
import Form from './../form/form';
import Tools from '../tools';

import { sbSharingBtn, sbSharingGrant, sbSharingList, SharingService } from './sharing';
import { sbBulkSharingBtn, sbBulkSharingLevel, sbBulkSharingTable } from './bulk';

/**
 * @ngdoc overview
 * @name sb.lib.sharing
 *
 * @description
 * This module houses handy sharing permissions.
 */
export default angular
  .module('sb.lib.sharing', [Form, sbBootstrap, Tools])

  .component('sbSharingBtn', sbSharingBtn)
  .component('sbSharingList', sbSharingList)
  .component('sbSharingGrant', sbSharingGrant)
  .component('sbBulkSharingBtn', sbBulkSharingBtn)
  .component('sbBulkSharingLevel', sbBulkSharingLevel)
  .component('sbBulkSharingTable', sbBulkSharingTable)
  .factory('SharingService', SharingService).name;
