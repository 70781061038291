import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { ISbxIconType, ISbxIconTypes, SbxIconTypes } from '@/core/constants/icons';
import { Downgrade } from '@/shared/downgrade';

/**
 *  Shoobx Themed Icon Component
 *
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-icon type="document"></sbx-icon>
 *      `,
 *    })
 *    class MyComponent {
 *      constructor() {}
 *    }
 */
const SELECTOR = 'sbx-icon';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-icon.component.html',
  styleUrls: ['sbx-icon.component.scss'],
})
export class SbxIconComponent implements AfterViewInit {
  @Input() type: ISbxIconType;
  @Input() bubble = false;
  @HostBinding('class.lozenge') lozenge = false;

  constructor(
    @Inject(SbxIconTypes) public icons: ISbxIconTypes,
    // Needed to detect innerText
    private element: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.lozenge = this.element.nativeElement.innerText
      ? this.element.nativeElement.innerText.length > 2
      : false;
  }
}
