import { Inject, Injectable } from '@angular/core';
import { SbxAngularRoutingService } from './sbx-angular-routing.service';
import { Router } from '@angular/router';
import { SbxNavigationStateService } from './sbx-navigation-state.service';
import { SbxUrlService } from '@/core/url/url.service';

export enum NavigationState {
  FROM_SPA_TO_SPA,
  FROM_SPA_TO_NON_SPA,
  FROM_NON_SPA_TO_SPA,
  FROM_NON_SPA_TO_NON_SPA,
  FROM_SHOOBX_TO_EXTERNAL,
}

@Injectable({ providedIn: 'root' })
export class SbxNavigationService {
  public constructor(
    @Inject('Window') private readonly window: Window,
    private readonly navigationStateService: SbxNavigationStateService,
    private readonly angularRoutingService: SbxAngularRoutingService,
    private readonly router: Router,
    private readonly urlService: SbxUrlService,
  ) {}

  public navigate(routingFragments: string[]): void;
  public navigate(href: string): void;
  public navigate(reference: string | string[]): void {
    const href = Array.isArray(reference) ? reference.join('/') : reference;
    const url: URL = this.urlService.mapToUrl(href);
    const state = this.navigationStateService.getState(url);

    switch (state) {
      case NavigationState.FROM_SPA_TO_SPA:
        return this.navigateByRouterLink(url);
      default:
        return this.loadPage(url);
    }
  }

  /**
   * Leveraging Angular Router for navigation
   */
  private navigateByRouterLink(url: URL): void {
    const { routerLink, queryParams, fragment } =
      this.angularRoutingService.mapUrlToNavigationModel(url);

    this.router.navigate(routerLink, { queryParams, fragment });
  }

  /**
   * Loading page with Refreshing effect
   */
  private loadPage(url: URL): void {
    this.window.open(url.toString(), '_self');
  }
}
