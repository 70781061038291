import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderComponent } from './sbx-loader.component';
import { SbxLoaderDirective } from './sbx-loader.directive';

@NgModule({
  imports: [CommonModule, SbxIconModule],
  declarations: [SbxLoaderComponent, SbxLoaderDirective],
  exports: [SbxLoaderDirective],
})
export class SbxLoaderModule {}
