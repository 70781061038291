import angular from 'angular';

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbSendVerifyEmailButton
 *
 * @description
 * This directive draws a button that can be used to send email verification messages.
 *
 * @param {template} email Email address to send the verify to.
 * @param {template} stakeholderId Stakeholder ID to send verify to.
 * @param {expression} [onSend=undefined] Evaluated after the verify was successfully sent. `$message`
 *   will be in the namespace (comes from the backend request).
 * @param {expression} [onFailure=undefined] Evaluated after the verify failed. `$message`
 *   will be in the namespace (comes from the backend request).
 * @param {boolean} link Whether the email should contain a verification link
 *   rather than the short code.
 */
export const sbSendVerifyButton = {
  controllerAs: 'vm',
  template: require('./templates/send-verify-button.html'),
  bindings: {
    model: '=',
    stakeholderId: '@',
    onFailure: '&?',
    onSend: '&?',
    challenge: '=',
    subtype: '=',
    link: '<?',
  },
  controller: [
    '$scope',
    '$timeout',
    'StandaloneButtonDisableTime',
    'EntityDomain',
    'Stakeholders',
    '$interval',
    'PasswordReset',
    function (
      $scope,
      $timeout,
      StandaloneButtonDisableTime,
      EntityDomain,
      Stakeholders,
      $interval,
      PasswordReset,
    ) {
      function sendVerifyEmail() {
        return Stakeholders.verifyEmail(this.stakeholderId, this.model, this.link);
      }

      function sendVerifyText() {
        return Stakeholders.verifyText(this.stakeholderId, this.model);
      }

      function sendChallenge() {
        return PasswordReset.challenge(this.model, this.subtype);
      }

      function sendDomainVerifyEmail() {
        return EntityDomain.challenge(this.model);
      }

      function getVerifyFunc() {
        const verifyDispatch = {
          phone: this.sendVerifyText,
          email: this.sendVerifyEmail,
          challenge: this.sendChallenge,
          domainEmail: this.sendDomainVerifyEmail,
          undefined: this.sendVerifyEmail,
        };
        if (this.challenge) {
          return this.sendChallenge;
        }
        return verifyDispatch[this.subtype];
      }

      function sendVerify() {
        this.sending = true;
        this.sent = false;
        this.resend = true;

        this.getVerifyFunc()()
          .finally(() => {
            this.sending = false;
          })
          .then((message) => {
            this.sent = true;
            if (this.onSend) {
              this.onSend({ message });
            }

            //  Controls the countdown text (in seconds) displayed on the button.
            //  convert from miliseconds to seconds
            this.countdown = StandaloneButtonDisableTime / 1000;
            this.currentInternval = $interval(
              () => {
                this.countdown -= 1;
              },
              1000,
              this.countdown,
            );

            return $timeout(angular.noop, StandaloneButtonDisableTime);
          })
          .catch(($message) => {
            if (this.onFailure) {
              this.onFailure({ $message });
            }
          })
          .finally(() => {
            this.sent = false;
          });
      }

      function reset() {
        if (this.currentInternval) {
          $interval.cancel(this.currentInternval);
          this.currentInternval = null;
        }
        this.countdown = 0;
        this.sending = this.sent = false;
      }

      function $onInit() {
        this.reset();
        this.iconType = this.subtype === 'phone' ? 'comment' : 'envelope';
      }

      $scope.$watch('vm.model', () => {
        if (this.sending || this.sent) {
          this.reset();
        }
      });

      this.reset = reset.bind(this);
      this.sendVerify = sendVerify.bind(this);
      this.getVerifyFunc = getVerifyFunc.bind(this);
      this.sendVerifyEmail = sendVerifyEmail.bind(this);
      this.sendVerifyText = sendVerifyText.bind(this);
      this.sendDomainVerifyEmail = sendDomainVerifyEmail.bind(this);
      this.sendChallenge = sendChallenge.bind(this);
      this.$onInit = $onInit.bind(this);
      if (angular.isUndefined(this.link)) {
        this.link = false;
      }
    },
  ],
}; // end sbSendVerifyEmailButton

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbRequireVerification
 *
 * @description
 * This component shows a textline and a sbSendVerifyButton for verifying the email entered.
 * It also requires a verification token sent to the email inputted.
 * If it's used a in a process, it is recommended to use validators to make sure that emails entered have
 * been verified before continuting.
 * @param {expression} ngModel Model expression.
 */
export const sbRequireVerification = {
  controllerAs: 'vm',
  template: require('./templates/verification.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    isVerified: '=?',
    subtype: '<subtype',
    maskedAccount: '@maskedAccount',
    onVerify: '&?',
  },
  controller: [
    '$scope',
    'AppConfig',
    'EntityDomain',
    'Stakeholders',
    'PasswordReset',
    function ($scope, AppConfig, EntityDomain, Stakeholders, PasswordReset) {
      function $onInit() {
        this.pending = false;
        this.token = null;
        this.alreadyVerified = false;
        this.message = '';
        this.errorMessage = '';
        this.warningMessage = '';
        this.stakeholderId = AppConfig.userProfile ? AppConfig.userProfile.id : null;
        this.verifyingEmail = '';
        this.placeHolderText =
          this.subtype === 'phone' ? 'U.S. Phone Number' : 'Email Address';
        this.isChallengeMode = Boolean(this.maskedAccount);
      }

      function displayErrorMessage(message) {
        this.errorMessage = message;
      }

      function displayWarningMessage(message) {
        this.pending = true;
        this.displayErrorMessage('');
        //  It's bad but the backend only provides a string error message. Given that
        //  the message itself probably not going to change very often, I think this
        //  could be reasonable.
        if (message && message.includes('already verified')) {
          this.isVerified = true;
          this.alreadyVerified = true;
        } else if (message) {
          this.warningMessage = message;
        }
      }

      function verifyToken() {
        let verify;
        if (this.isChallengeMode) {
          verify = PasswordReset.verify({
            email: this.model,
            token: this.token,
            method: this.subtype,
          });
        } else if (this.subtype === 'phone') {
          verify = Stakeholders.verifyPhoneToken(
            this.stakeholderId,
            this.token,
            this.model,
          );
        } else if (this.subtype === 'domainEmail') {
          verify = EntityDomain.verify(this.model, this.token);
        } else {
          verify = Stakeholders.verifyEmailToken(
            this.stakeholderId,
            this.token,
            this.model,
          );
        }
        verify
          .then((data) => {
            this.isVerified = true;
            if (this.isChallengeMode) {
              this.onVerify({ token: data });
            }
          })
          .catch((err) => {
            this.displayErrorMessage(err);
          });
      }

      function reset() {
        if (this.subtype === 'phone') {
          // Do nothing, only UI will reset allowing to verify new recovery method.
          // User recovery method will be changed when a new method is verified.
          // The design is a little odd, but it ensures that users always have a valid recovery method.
        } else if (this.subtype === 'email') {
          // Do nothing, only UI will reset allowing to verify new recovery method.
          // User recovery method will be changed when a new method is verified.
          // The design is a little odd, but it ensures that users always have a valid recovery method.
        } else if (this.subtype === 'domainEmail') {
          EntityDomain.reset();
        }
        this.isVerified = this.sent = this.alreadyVerified = false;
        this.model = this.token = '';
      }

      function $clearMessages() {
        if (this.message) {
          this.message = '';
        }
        if (this.errorMessage) {
          this.errorMessage = '';
        }
      }

      //  If user enters a new email it shouldn't have been verified. And we should stop subscribing to previous
      //  isVerified observable.
      $scope.$watch('vm.model', () => {
        if (this.sent || this.pending) {
          if (this.model) {
            this.model = this.model.toLowerCase().replace(/\s+/g, '');
          }
          this.pending = false;
          this.isVerified = false;
        }
        if (!this.isChallengeMode && this.subtype === 'phone' && this.model) {
          this.model = this.model.replace(/[a-zA-Z]/, '');
        }
      });

      this.verifyToken = verifyToken.bind(this);
      this.displayErrorMessage = displayErrorMessage.bind(this);
      this.displayWarningMessage = displayWarningMessage.bind(this);
      this.$clearMessages = $clearMessages.bind(this);
      this.reset = reset.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbRequireVerification

// sbRequireVerification component has ng-model binding which conflicts with [ngModel]
// Angular binding when upgraded, thus we need to change ng-model binding to model
// binding to upgrade this component
export const sbRequireVerificationNg1 = {
  controllerAs: 'vm',
  template: `
    <sb-require-verification
      ng-model="vm.model"
      is-verified="vm.isVerified"
      subtype="vm.subtype"
      masked-account="{{ vm.maskedAccount }}"
      on-verify="vm.onVerify(token)"
    ></sb-require-verification>`,
  bindings: {
    model: '=',
    isVerified: '=?',
    subtype: '<',
    maskedAccount: '@',
    onVerify: '&?',
  },
};
