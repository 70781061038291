import { NgModule } from '@angular/core';
import { SbxPropertiesListComponent } from './sbx-properties-list.component';
import { CommonModule } from '@angular/common';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';

@NgModule({
  imports: [CommonModule, SbxPopoverModule],
  declarations: [SbxPropertiesListComponent],
  exports: [SbxPropertiesListComponent],
})
export class SbxPropertiesListModule {}
