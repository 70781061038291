import { Component, Inject, Input } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxUrlService } from '@/core/url/url.service';

interface IProcessTypeOption {
  formField: string; // Which form field are we displaying the radio for?
  fieldValue: string; // Which field value? (e.g., "guided")
  radioText: string; // Text of the actual radio button
  titleText?: string; // Optional big gray text above the radio button
  descriptionText?: string; // Optional description text directly under the radio
  descriptionTextBolded?: boolean; // Whether to bold the text - e.g., if it is a list header
  subCheckboxes?: IProcessTypeSubOption[]; // Extra checkboxes associated with an option.
  subRadios?: IProcessTypeSubOption[]; // Extra radios associated with an option.
  descriptionBullets?: IOptionDescriptionBullet[]; // List description bullets associated with an option.
  postDescriptionText?: string; // Optional description text after checkboxes or bullets.
  // eslint-disable-next-line max-len
  grayedOutInfoText?: string; // Info about why an option might be grayed out - also used to determine if indeed grayed out.
  disabledForSupport?: boolean;
}

// In general, we will have either sub-checkboxes, sub-radios, or description bullets. They
// appear at roughly the same visual level.

interface IProcessTypeSubOption {
  formField: string; // Which (boolean) form field are we displaying the checkbox for?
  // CHECKBOX SPECIFIC FIELDS
  checkboxText?: string; // Text of the checkbox
  checkboxHelp?: string; // Help text for popover.
  // RADIO SPECIFIC FIELDS
  subRadioValues?: ISubRadioFields[];
}

interface ISubRadioFields {
  value: string;
  text: string;
}

interface IOptionDescriptionBullet {
  text: string; // Text of the description bullet
  helpText?: string; // Optional help popover text for bullet
  subBullets?: IOptionDescriptionBullet[]; // Optional additional level of list nesting
}

const SELECTOR = 'sbx-process-intro';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-process-intro.component.html',
  styleUrls: [],
})

/**
 * A standard Shoobx process introduction page - generally, where you choose process type.
 * @example Component
 * The component is used within process intro TAL templates and must be passed
 * information from the process to determine what should be presented:
 *
 * <sbx-process-intro
 *   form-name="add_employee_intro"
 *   workflow-description-text="This is add employee."
 *   workflow-description-help-text="Sometimes there is even help text!"
 *   workflow-description-extra-text="And even more text in other cases!"
 *   how-to-proceed-text="Also this special text, if you like."
 *   [options]=[ IProcessTypeOption ]
 *   [sbmodel]="model"></sbx-process-intro>
 */
export class SbxProcessIntroComponent {
  @Input() showBetaImage = false;
  @Input() widthClass = 'col-lg-offset-1 col-lg-10';
  @Input() formName: string; // To get the actual form we want to hook up
  @Input() sbmodel: any;

  @Input() workflowDescriptionText: string;
  @Input() workflowDescriptionHelpText?: string; // Mouseover question bubble
  @Input() workflowDescriptionExtraText?: string;
  @Input() showHowToProceedText = false;
  @Input() howToProceedText = 'How would you like to proceed?';
  @Input() finalDescriptionText?: string;

  @Input() options: IProcessTypeOption[];

  constructor(@Inject(SbxUrlService) public sbxUrlService: SbxUrlService) {}

  getFormField(name) {
    return this.sbmodel.$forms[this.formName].fields.find(
      (element) => element.key === name,
    );
  }
}
