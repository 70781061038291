import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxSlideToggleComponent } from './sbx-slide-toggle.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [SbxSlideToggleComponent],
  exports: [SbxSlideToggleComponent],
})
export class SbxSlideToggleModule {}
