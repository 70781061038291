import { Injectable } from '@angular/core';
import { Subject, delay } from 'rxjs';
import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';

@Injectable({
  providedIn: 'root',
})
export class SbxPageHeaderTemplateService {
  // allow undefined for resetting
  private templateSource = new Subject<ISbxActionsMenu | undefined>();
  actionMenuItems$ = this.templateSource.asObservable().pipe(delay(0));

  setActionMenuItems(actionMenuItems?: ISbxActionsMenu): void {
    this.templateSource.next(actionMenuItems);
  }
}
