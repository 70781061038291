import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentReferences } from '@shoobx/types/webapi-v2';

@Injectable({ providedIn: 'root' })
export class SbxDocumentStoreService {
  public readonly documentReferences$: Observable<DocumentReferences>;

  private readonly documentReferencesSubject = new BehaviorSubject<DocumentReferences>(
    undefined,
  );

  public constructor() {
    this.documentReferences$ = this.documentReferencesSubject.asObservable();
  }

  public setDocumentReferences(documentReferences: DocumentReferences): void {
    this.documentReferencesSubject.next(documentReferences);
  }
}
