import { Component, Input } from '@angular/core';

/**
 *  Shoobx Themed Modal Content
 *
 *  Include in the input template to [sbxModalTemplate]
 *  to supply a standard content layout.
 *
 *  Example:
 *
 *  @Component({
 *    selector: 'get-help',
 *    template: `
 *      <sbx-button type="help"
 *        sbxModal
 *        [sbxModalTemplate]="content"
 *        >
 *        Get Help Now!
 *      </sbx-button>
 *      <ng-template #content>
 *        <sbx-modal-content>
 *          This is some helpful content!
 *        </sbx-modal-content>
 *      </ng-template>
 *    `,
 *  })
 *  class GetHelpComponent {}
 */
@Component({
  selector: 'sbx-modal-content',
  templateUrl: './sbx-modal-content.component.html',
  styleUrls: ['./sbx-modal-content.component.scss'],
})
export class SbxModalContentComponent {
  @Input() fixedHeight = false;
}
