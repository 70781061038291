import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxUpdateLiquidationStackWorkitemComponent } from './sbx-update-liquidation-stack-workitem.component';
import { SbxInvestmentModule } from '@/sbx-investment/sbx-investment.module';

@NgModule({
  imports: [CommonModule, SharedModule, SbxInvestmentModule],
  declarations: [SbxUpdateLiquidationStackWorkitemComponent],
  exports: [SbxUpdateLiquidationStackWorkitemComponent],
})
export class SbxUpdateLiquidationStackWorkitemModule {
  static entryComponents = [SbxUpdateLiquidationStackWorkitemComponent];

  static forRoot(): ModuleWithProviders<SbxUpdateLiquidationStackWorkitemComponent> {
    return {
      ngModule: SbxUpdateLiquidationStackWorkitemComponent,
    };
  }
}
