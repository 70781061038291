import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface TabViewModel {
  readonly name: string;
  readonly title: string;
}

@Component({
  selector: 'sbx-responsive-nav',
  templateUrl: './sbx-responsive-nav.component.html',
  styleUrls: ['./sbx-responsive-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxResponsiveNavComponent {
  @Input({ required: true }) public tabs: TabViewModel[];
  @Input({ required: true }) public isMobile: boolean;
  @Input({ required: true }) public activeTabIndex: number;
  @Output() public readonly changeActiveTabEvent = new EventEmitter<TabViewModel>();
}
