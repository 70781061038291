import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'sbx-document-download-dropdown',
})
export class SbxDocumentDownloadDropdownComponent extends UpgradeComponent {
  @Input() docId: string;
  @Input() canExport: boolean;
  @Input() canDownload: boolean;
  @Input() canDownloadWatermarked: boolean;
  @Input() canDownloadRevisionHistory: boolean;
  @Input() canDownloadCustomization: boolean;
  @Input() canDownloadRedline: boolean;
  @Input() fullSized: boolean;
  @Input() title: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('sbDocumentDownloadDropdown', elementRef, injector);
  }
}
