import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  NgbModule,
  NgbDateParserFormatter,
  NgbDateAdapter,
} from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxTreeModule } from '../sbx-tree/sbx-tree.module';
import { SbxTableModule } from '../sbx-table/sbx-table.module';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';
import { SbxCheckboxModule } from '../sbx-checkbox/sbx-checkbox.module';
import { SbxActionModule } from './../sbx-action-menu/sbx-action-menu.module';

import { SbxFormModalService } from '../sbx-form-modal/sbx-form-modal.service';

import { SbxFormConfig } from './sbx-form.config';
import { SbxFormComponent } from './sbx-form.component';
import { SbxStringTextlineComponent } from './fields/sbx-string-textline.component';
import { SbxEmailTextlineComponent } from './fields/sbx-email-textline.component';
import { SbxFormFieldComponent } from './wrappers/sbx-form-field.component';
import { SbxStringComponent } from './fields/sbx-string.component';
import { SbxBoolRadiosComponent } from './fields/sbx-bool-radios.component';
import { SbxBoolRadiosVerticalComponent } from './fields/sbx-bool-radios-vertical.component';
import { SbxEnumRadiosComponent } from './fields/sbx-enum-radios.component';
import { SbxBoolCheckboxComponent } from './fields/sbx-bool-checkbox.component';
import { SbxChecklistComponent } from './fields/sbx-checklist.component';
import { SbxEnumDropdownComponent } from './fields/sbx-enum-dropdown.component';
import { SbxNumberTextlineBaseComponent } from './fields/sbx-number-textline-base.component';
import { SbxNumberTextlineComponent } from './fields/sbx-number-textline.component';
import { SbxSelectModule } from '../sbx-select/sbx-select.module';
import { SbxDropzoneModule } from '../sbx-dropzone/sbx-dropzone.module';
import { SbxTitleModule } from '../sbx-title/sbx-title.module';
import { SbxListComponent } from './fields/sbx-list.component';
import { SbxProfileListComponent } from './fields/sbx-profile-list.component';
import { SbxProfileListBaseComponent } from './fields/sbx-profile-list-base.component';
import { SbxProfileComponent } from './fields/sbx-profile.component';
import { SbxProfileDisplayComponent } from './fields/sbx-profile-display.component';
import { SbxStockTickerComponent } from './fields/sbx-stock-ticker.component';

import { SbxDateBaseComponent } from './fields/sbx-date-base.component';
import { SbxDateComponent } from './fields/sbx-date.component';
import {
  SbxDateBaseParserFormatter,
  SbxDateBaseAdapter,
} from './fields/sbx-date-base.component.formatters';
import { SbxDocumentReferenceFormComponent } from './fields/sbx-document-reference-form.component';
import { SbxDocumentReferenceModule } from '../sbx-document-reference/sbx-document-reference.module';
import { SbxStakeholderSearchModule } from '../sbx-stakeholder-search/sbx-stakeholder-search.module';
import { SbxSearchBarModule } from '../sbx-search-bar/sbx-search-bar.module';
import { SbxStakeholderListComponent } from './fields/sbx-stakeholder-list.component';
import { SbxStakeholderComponent } from './fields/sbx-stakeholder.component';
import { UpgradedModule } from '../upgraded/upgraded.module';
import { ProcessUrlInfoServiceProvider } from '../upgraded/process-url-info.service';
import { SbxRadioGroupModule } from '../sbx-radio-group/sbx-radio-group.module';
import { SbxRadioModule } from '../sbx-radio/sbx-radio.module';
import { SbxPdfUploadFormComponent } from './fields/sbx-pdf-upload-form.component';
import { SbxObjectListComponent } from './fields/sbx-object-list.component';
import { SbxDisplayTemplateComponent } from './fields/sbx-display-template.component';
import { SbxUploadDropzoneComponent } from './fields/sbx-upload-dropzone.component';
import { SbxClipboardComponent } from './fields/sbx-clipboard.component';
import { SbxRichtextComponent } from './fields/sbx-richtext.component';
import { SbxEditorModule } from '../sbx-editor/sbx-editor.module';
import { SbxPipesModule } from '../pipes/pipes.module';
import { SbxDisplayHtmlComponent } from './fields/sbx-display-html.component';
import { SbxSectionHeaderComponent } from './fields/sbx-section-header.component';
import { SbxStakeholderBaseComponent } from './sbx-stakeholder-base/sbx-stakeholder-base.component';
import { SbxNewStakeholderModalComponent } from './sbx-stakeholder-base/sbx-new-stakeholder-modal.component';
import { SbxSimilarEntitiesModalComponent } from './sbx-stakeholder-base/sbx-similar-entities-modal.component';
import { SbxStakeholderCardModule } from '@/shared/sbx-stakeholder-card/sbx-stakeholder-card.module';
import { SbxExistingStakeholderModalComponent } from './sbx-stakeholder-base/sbx-existing-stakeholder-modal.component';
import { SbxAddressComponent } from './fields/sbx-address.component';
import { SbxVerificationComponent } from './fields/sbx-verification.component';
import { SbxLabelComponent } from './fields/sbx-label.component';
import { SbxDocumentListComponent } from './fields/sbx-document-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(SbxFormConfig),
    SbxSelectModule,
    SbxDropzoneModule,
    SbxIconModule,
    SbxTableModule,
    SbxModalModule,
    SbxPopoverModule,
    SbxLoaderModule,
    SbxButtonModule,
    SbxRadioGroupModule,
    SbxRadioModule,
    NgbModule,
    SbxStakeholderSearchModule,
    SbxTreeModule,
    SbxAlertBannerModule,
    SbxCheckboxModule,
    SbxActionModule,
    UpgradedModule,
    SbxDocumentReferenceModule,
    SbxEditorModule,
    SbxTitleModule,
    SbxPipesModule,
    SbxSearchBarModule,
    SbxStakeholderCardModule,
  ],
  declarations: [
    SbxFormComponent,
    SbxFormFieldComponent,
    SbxStringTextlineComponent,
    SbxEmailTextlineComponent,
    SbxStringComponent,
    SbxBoolRadiosComponent,
    SbxBoolRadiosVerticalComponent,
    SbxEnumRadiosComponent,
    SbxBoolCheckboxComponent,
    SbxChecklistComponent,
    SbxEnumDropdownComponent,
    SbxProfileListBaseComponent,
    SbxProfileListComponent,
    SbxProfileComponent,
    SbxProfileDisplayComponent,
    SbxNumberTextlineBaseComponent,
    SbxNumberTextlineComponent,
    SbxListComponent,
    SbxDateBaseComponent,
    SbxDateComponent,
    SbxStakeholderListComponent,
    SbxStakeholderComponent,
    SbxDocumentReferenceFormComponent,
    SbxPdfUploadFormComponent,
    SbxObjectListComponent,
    SbxDisplayTemplateComponent,
    SbxUploadDropzoneComponent,
    SbxClipboardComponent,
    SbxRichtextComponent,
    SbxDisplayHtmlComponent,
    SbxSectionHeaderComponent,
    SbxStockTickerComponent,
    SbxStakeholderBaseComponent,
    SbxNewStakeholderModalComponent,
    SbxSimilarEntitiesModalComponent,
    SbxExistingStakeholderModalComponent,
    SbxAddressComponent,
    SbxVerificationComponent,
    SbxLabelComponent,
    SbxDocumentListComponent,
  ],
  exports: [
    SbxFormComponent,
    SbxNumberTextlineBaseComponent,
    SbxStakeholderBaseComponent,
    SbxDocumentListComponent,
  ],
  providers: [
    SbxFormModalService,
    ProcessUrlInfoServiceProvider,
    { provide: NgbDateParserFormatter, useClass: SbxDateBaseParserFormatter },
    { provide: NgbDateAdapter, useClass: SbxDateBaseAdapter },
  ],
})
export class SbxFormModule {
  static entryComponents = [
    SbxFormComponent,
    SbxNumberTextlineBaseComponent,
    SbxProfileListBaseComponent,
    SbxStakeholderBaseComponent,
    SbxDateBaseComponent,
  ];

  static forRoot(): ModuleWithProviders<SbxFormModule> {
    return {
      ngModule: SbxFormModule,
    };
  }
}
