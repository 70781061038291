<div class="sbx-document-list-wrapper" (click)="handleClickEvent($event)">
  <sbx-table
    *ngIf="columns"
    [data]="data"
    [columns]="columns"
    [noHeaders]="true"
  ></sbx-table>

  <sbx-button
    icon="plus"
    theme="default"
    title="Add documents"
    (click)="handleAddDocumentEvent()"
  ></sbx-button>
</div>

<ng-template #nameTemplate let-name>
  <sbx-icon type="dataRoom"></sbx-icon>
  {{ name }}
</ng-template>

<ng-template #rowActionsTemplate let-row>
  <sbx-action-menu
    [actions]="getRowActions(row)"
    [appearance]="{ type: 'icon', aligment: 'left' }"
  ></sbx-action-menu>
</ng-template>
