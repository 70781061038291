import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxLiquidationStackModalComponent } from './sbx-liquidation-stack-modal.component';
import { SbxLiquidationStackDataModel } from '@/sbx-investment/sbx-liquidation-stack-modal/sbx-liquidation-stack-data.model';
import { LiquidationStackClass } from '@shoobx/types/plannerapi-v1';

@Injectable()
export class SbxLiquidationStackModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: SbxLiquidationStackDataModel<LiquidationStackClass[][]>) {
    const modal = this.sbxModalService.open(SbxLiquidationStackModalComponent, {
      size: 'md',
      data,
    });

    return modal;
  }
}
