<sbx-modal-header>
  <h4 class="modal-title">{{ title }}</h4>
</sbx-modal-header>

<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="globalError"
    [error]="true"
    [customText]="globalError"
  ></sbx-alert-banner>

  <div class="sbx-top-message-container">
    When adding a custom entry (country, state or other) you are responsible for
    calculating and inputting the applicable taxes.
  </div>

  <sbx-form
    [formName]="formName"
    [form]="form"
    [formFields]="formFields"
    [model]="model"
    [serverErrors]="errors"
    *ngIf="formFields"
    (submit)="form.valid ? handleSave() : null"
  ></sbx-form>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    *ngIf="!readOnly"
    [loading]="loading"
    [disabled]="!form.valid"
    theme="primary"
    (click)="handleSave()"
    title="Add"
  >
  </sbx-button>
  <sbx-button
    theme="secondary"
    [disabled]="loading"
    (click)="sbxModalService.dismiss()"
    title="Cancel"
  ></sbx-button>
</sbx-modal-footer>
