import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxSignatureComponent } from './sbx-signature.component';

@NgModule({
  declarations: [SbxSignatureComponent],
  imports: [CommonModule, SbxIconModule, SbxButtonModule],
  exports: [SbxSignatureComponent],
})
export class SbxSignatureModule {}
