import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ExportOptionsFormData } from '@shoobx/types/webapi-v2';
import { SbxHttpClient } from '@/core/http';
import { Downgrade } from '@/shared/downgrade';

@Downgrade.Injectable('ngShoobx', 'SbxExportFormProvider')
@Injectable({ providedIn: 'root' })
export class SbxExportFormProvider {
  private formCache: Promise<ExportOptionsFormData>;

  public constructor(private readonly http: SbxHttpClient) {}

  public getForm(): Promise<ExportOptionsFormData> {
    if (!this.formCache) {
      this.formCache = firstValueFrom(
        this.http.entity('2').get<ExportOptionsFormData>('hr/reports/form'),
      );
    }

    return this.formCache.then((form) => structuredClone(form));
  }
}
