<sbx-popover
  *ngIf="groups"
  [ngClass]="{
    'sbx-process-groups': true,
    'sbx-only-groups': !steps
  }"
  [sbxPopoverContent]="popoverErrorContent"
>
  <div class="sbx-process-groups-number">Part {{ currentGroup.pos }}:</div>
  <div class="sbx-process-groups-title">{{ currentGroup.title }}</div>
</sbx-popover>

<ng-template #popoverErrorContent>
  <ul class="sbx-process-groups-list">
    <li [ngClass]="{ 'sbx-active': group.active }" *ngFor="let group of groups">
      Part {{ group.pos }}: <span class="sbx-part-arrow"></span> {{ group.title }}
    </li>
  </ul>
</ng-template>

<ng-container *ngIf="steps">
  <ul
    class="sbx-process-wizard"
    [ngStyle]="{ 'left.px': scrollOffset, 'width.px': steps.length * 200 }"
  >
    <li
      *ngFor="let step of steps; index as i"
      [ngClass]="{
        'sbx-step': true,
        'sbx-step-link': step.linkId && i !== currentStepIndex,
        'sbx-step-completed': step.completed,
        'sbx-step-current': step.current,
        'sbx-step-current-view': i === currentStepIndex,
        'sbx-step-past': i < currentStepIndex,
        'sbx-step-future': i > currentStepIndex
      }"
      (click)="stepChange.emit(step)"
    >
      <p>{{ step.title }}</p>
    </li>
  </ul>

  <div
    class="sbx-process-wizard-scroll-left"
    [ngStyle]="{ 'left.px': containerOffset }"
    (click)="scrollLeft()"
    *ngIf="canScrollLeft()"
  >
    <sbx-icon type="chevronLeft"></sbx-icon>
  </div>

  <div
    class="sbx-process-wizard-scroll-right"
    (click)="scrollRight()"
    *ngIf="canScrollRight()"
  >
    <sbx-icon type="chevronRight"></sbx-icon>
  </div>
</ng-container>
