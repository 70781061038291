import angular from 'angular';
import { Map, Record } from 'immutable';
import moment from 'moment';

/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbDocumentTemplateViewerPage
 *
 * @description
 * This directive is intended for use on the template reviewer page.
 */
export function sbDocumentTemplateViewerPage() {
  return {
    controllerAs: 'vm',
    controller: [
      '$window',
      class {
        constructor($window) {
          this.print = () => $window.print();
        }
      },
    ],
  };
} // end sbDocumentTemplateViewerPage

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.document.DocumentTemplateModel
 *
 * @description
 * This factory function produces a new template model which contains data about
 * a document template and its revisions.
 *
 * @param {string} templateName The base name of the document template.
 *
 * @returns {object} The model. See below for property/method documentation.
 */
export const DocumentTemplateModel = [
  '$q',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, BackendLocation, SimpleHTTPWrapper) {
    const TEMPLATE_API_URL = BackendLocation.entity(1) + 'templates/';
    const Template = Record({
      revision: undefined,
      html: undefined,
      createdDate: undefined,
      summary: undefined,
      newest: undefined,
      diffs: Map(),
    });

    class TemplateModel {
      constructor(baseName) {
        /**
         * @ngdoc property
         * @name $baseName
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * String base name of this template model.
         */
        this.$baseName = baseName;

        /**
         * @ngdoc property
         * @name $chosenDiff
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * Currently selected diff template.
         */
        this.$chosenDiff = undefined;

        /**
         * @ngdoc property
         * @name $loading
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * Boolean describing if async is outstanding.
         */
        this.$loading = false;

        /**
         * @ngdoc property
         * @name $revisions
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * An immutable map of revision ID to Template records.
         */
        this.$revisions = Map();

        /**
         * @ngdoc property
         * @name $currentRevision
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * A referance to the currently shown revision.
         */
        this.$currentRevision = undefined;

        /**
         * @ngdoc property
         * @name $newestRevision
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * A referance to the newest revision.
         */
        this.$newestRevision = undefined;

        /**
         * @ngdoc property
         * @name $currentHtml
         * @propertyOf sb.lib.document.DocumentTemplateModel
         *
         * @description
         * The current being shown HTML.
         */
        this.$currentHtml = undefined;
      }

      $$processRevision(templateData) {
        return new Template(
          angular.extend(templateData, {
            createdDate: templateData.createdDate && moment(templateData.createdDate),
            html: templateData.$html,
            newest: templateData.$newest,
            diffs: Map(templateData.$diffs),
          }),
        );
      }

      $$processRevisions(data) {
        const revisionIds = Object.keys(data);
        const idToRevisions = revisionIds.map((revId) => [
          revId,
          this.$$processRevision(data[revId]),
        ]);
        return Map(idToRevisions);
      }

      $$updateRevsions(newRevisions, desiredRevisionId, diffTo) {
        this.$revisions = newRevisions;
        this.$newestRevision = this.$revisions.find((rev) => rev.newest);
        this.$currentRevision =
          this.$revisions.get(desiredRevisionId) || this.$newestRevision;
        this.$currentHtml = angular.isDefined(diffTo)
          ? this.$currentRevision.diffs.get(diffTo)
          : this.$currentRevision.html;
      }

      /**
       * @ngdoc method
       * @name $initRevisions
       * @methodOf sb.lib.document.DocumentTemplateModel
       *
       * @description
       * Load all revisions.
       *
       * @param {string} [revisionId=undefined] If defined, load a specific
       *    revision, otherwise the newest revision will be loaded.
       *
       * @returns {promise} Returns a promise that either resolves with the
       *    desired template revision or rejects with a reason.
       */
      $initRevisions(revisionId) {
        const concreteRevision = revisionId || revisionId === 0;
        const desiredRevisionId = concreteRevision ? revisionId : '$newest';
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: TEMPLATE_API_URL + this.$baseName,
            params: { revision: desiredRevisionId },
          },
          'Could not fetch document revisions.',
        )
          .then((data) => {
            this.$$updateRevsions(this.$$processRevisions(data), desiredRevisionId);
            return this.$currentRevision;
          })
          .finally(() => {
            this.$loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name $getDiffTo
       * @methodOf sb.lib.document.DocumentTemplateModel
       *
       * @description
       * Load the diff between the current revision and `$chosenDiff`.
       *
       * @returns {promise} Returns a promise that either resolves with the
       *    desired diff html or rejects with a reason.
       */
      $getDiffTo() {
        const comparison = this.$chosenDiff;
        const currentRevision = this.$currentRevision;
        if (!comparison || !currentRevision) {
          return $q.reject('No comparison selected.');
        }

        const cache = currentRevision.diffs.get(comparison.revision);
        if (cache) {
          this.$$updateRevsions(
            this.$revisions,
            this.$currentRevision.revision,
            comparison.revision,
          );
          return $q.resolve(cache);
        }

        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: TEMPLATE_API_URL + this.$baseName,
            params: {
              revision: currentRevision.revision,
              compare: comparison.revision,
            },
          },
          'Could not load diff.',
        )
          .then((data) => {
            const diffedRevision = this.$$processRevision(
              data[currentRevision.revision],
            );
            const newDiff = diffedRevision.diffs.get(comparison.revision);

            const newCurrentRev = currentRevision.set(
              'diffs',
              currentRevision.diffs.merge(diffedRevision.diffs),
            );
            const newRevisions = this.$revisions.set(
              newCurrentRev.revision,
              newCurrentRev,
            );
            this.$$updateRevsions(
              newRevisions,
              newCurrentRev.revision,
              comparison.revision,
            );

            return newDiff;
          })
          .finally(() => {
            this.$loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name $getRevision
       * @methodOf sb.lib.document.DocumentTemplateModel
       *
       * @description
       * Load a revision ID into the model.
       *
       * @param {string} [revisionId=undefined] If defined, load a specific
       *    revision, otherwise the newest revision will be loaded.
       *
       * @returns {promise} Returns a promise that either resolves with the
       *    desired template revision or rejects with a reason.
       */
      $getRevision(revisionId) {
        const concreteRevision = revisionId || revisionId === 0;
        let desiredRevisionId = concreteRevision ? revisionId : '$newest';
        let cache = this.$revisions.get(desiredRevisionId);
        if (desiredRevisionId === '$newest') {
          cache = this.$newestRevision;
          desiredRevisionId = this.$newestRevision.revision;
        }
        if (cache && cache.html) {
          this.$$updateRevsions(this.$revisions, cache.revision);
          this.$chosenDiff = undefined;
          return $q.resolve(cache);
        }
        this.$loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: TEMPLATE_API_URL + this.$baseName,
            params: { revision: desiredRevisionId },
          },
          'Could not fetch document revision.',
        )
          .then((data) => {
            const template = this.$$processRevision(data[desiredRevisionId]);
            this.$$updateRevsions(
              this.$revisions.set(desiredRevisionId, template),
              desiredRevisionId,
            );
            this.$chosenDiff = undefined;
            return template;
          })
          .finally(() => {
            this.$loading = false;
          });
      }
    }

    return (templateName) => new TemplateModel(templateName);
  },
]; // end DocumentTemplateModel

/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbDocumentTemplateReviewer
 *
 * @description
 * This directive is a standalone document template reviewer. It supports
 * looking at templates in "layman's" formatting, with controls for viewing diffs
 * of various revisions.
 *
 * @param {string} templateName Template basename.
 * @param {string} [initialRevision=undefined] If defined, this will be the
 *    first revision shown. Otherwise, the latest revision will be shown.
 * @param {expression} [currentTemplateRevision=undefined] Two way data binding
 *    for currently shown template revision.
 */
export function sbDocumentTemplateReviewer() {
  return {
    template: require('./templates/template-reviewer.html'),
    scope: {
      templateName: '<',
      currentTemplateRevision: '=?',
      initialRevision: '<?',
    },
    controller: [
      '$scope',
      'DocumentTemplateModel',
      function ($scope, DocumentTemplateModel) {
        const model = DocumentTemplateModel($scope.templateName);

        function setError(err) {
          if (angular.isString(err)) {
            $scope.mainError = err;
          }
        }
        function changeRevisionTo(revision) {
          $scope.mainError = null;
          model.$getRevision(revision).catch(setError);
        }
        function loadComparison() {
          $scope.mainError = null;
          model.$getDiffTo().catch(setError);
        }
        function sortFunc(rev) {
          if ($scope.sortKey === 'date') {
            return rev.valueOf();
          }
          return rev.revision;
        }
        function changeSortKey(evt, newKey) {
          if ($scope.sortKey === newKey) {
            $scope.reverse = !$scope.reverse;
          }
          $scope.sortKey = newKey;
          evt.preventDefault();
        }
        function notCurrent(rev) {
          return rev !== model.$currentRevision;
        }

        $scope.sortKey = 'revision';
        $scope.docModel = model;
        $scope.sortFunc = sortFunc;
        $scope.changeSortKey = changeSortKey;
        $scope.changeRevisionTo = changeRevisionTo;
        $scope.loadComparison = loadComparison;
        $scope.notCurrent = notCurrent;

        $scope.$watch('docModel.$currentRevision', (nv) => {
          $scope.currentTemplateRevision = nv;
        });

        model.$initRevisions($scope.initialRevision).catch(setError);
      },
    ],
  };
} // end sbDocumentTemplateReviewer
