import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { SbxHttpClient } from '@/core/http/';
import { Downgrade } from '@/shared/downgrade';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import { SbxProcessButtonModel } from '@/shared/upgraded/process-button-model.service';
import { StakeholderTableData, StakeholderTableRow } from '@shoobx/types/webapi-v2';

const SELECTOR = 'sbx-table-with-stakeholder-filter-workitem';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-table-with-stakeholder-filter.component.html',
  styleUrls: ['./sbx-table-with-stakeholder-filter.component.scss'],
})
export class SbxTableWithStakeholderFilterWorkitemComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('checkboxTpl') checkboxTpl: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('formattedItemTpl') formattedItemTpl: TemplateRef<any>;

  public selectedItems: string[] = [];
  public selectedStakeholderId: string;
  public items: StakeholderTableRow[] = [];
  public filteredItems: StakeholderTableRow[] = [];
  public columns = [];
  public isLoading = true;
  public isSelectAllChecked = false;
  public isSelectAllIndeterminate = false;
  public page = 0;
  public readonly pageSize = 50;
  public totalItems: number;
  public pageItems: StakeholderTableRow[] = [];
  public selectedPageItems: string[] = [];
  public securityTicketId?: string = undefined;

  private readonly endpoint: string;

  constructor(
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    @Inject(SbxProcessButtonModel) private procButtonModel,
    private readonly http: SbxHttpClient,
  ) {
    this.endpoint = `workitems/${this.processUrlInfo.wiId()}/table-with-stakeholder-filter`;
  }

  ngOnInit() {
    this.isLoading = true;
    this.http
      .entity('2')
      .get(this.endpoint)
      .subscribe(
        ({ selectedItems, items, columns, securityTicketID }: StakeholderTableData) => {
          this.selectedItems = selectedItems;
          this.items = items;
          this.totalItems = items.length;
          this.filteredItems = items;
          this.columns = this.setupTableColumns(columns);
          this.displayData(this.filteredItems);
          this.changeContinueButton();
          this.isLoading = false;
          this.securityTicketId = securityTicketID;
          this.triggerBulkSelect();
        },
      );
  }

  changeContinueButton() {
    if (this.selectedItems === null || this.selectedItems.length === 0) {
      this.procButtonModel.disable('continue');
    } else {
      this.procButtonModel.requestEnable('continue');
    }
  }

  setupTableColumns(types: string[][]) {
    const columns = [];

    columns.push({
      key: 'selected',
      title: '',
      template: this.checkboxTpl,
      width: '1px',
    });
    columns.push(
      ...types.map((c) => ({
        key: c[0],
        title: c[1],
        template: this.formattedItemTpl,
      })),
    );

    return columns;
  }

  filterStakeholders(data): void {
    if (data?.sh?.id) {
      this.filteredItems = this.items.filter((i) => i.stakeholder === data.sh.id);
      this.selectedStakeholderId = data.sh.id;
    }

    this.page = 0;
    this.displayData(this.filteredItems);
    this.triggerBulkSelect();
  }

  allSelected(): boolean {
    return this.selectedStakeholderId
      ? this.filteredItems.every((x) => this.selectedItems.indexOf(x.id) !== -1)
      : this.selectedItems.length === this.items.length;
  }

  clearStakeholders(): void {
    this.filteredItems = this.items;
    this.selectedStakeholderId = null;
    this.displayData(this.items);

    this.triggerBulkSelect();
  }

  async handleSelectAllInCurrentPageChange(value: boolean): Promise<void> {
    if (!value) {
      this.uncheckAllItems();
      this.changeContinueButton();
      return;
    }

    this.isSelectAllChecked = value;
    this.isSelectAllIndeterminate = false;

    this.upsertPageItemsToSelectedItems();
    this.changeContinueButton();
    this.updateSelectedItemsOnPage();
    await this.http
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedItems } })
      .toPromise();
  }

  async handleChange(id, selected) {
    this.selectedItems = selected
      ? [...this.selectedItems, id]
      : this.selectedItems.filter((idx) => idx !== id);

    this.changeContinueButton();
    this.triggerBulkSelect();

    await this.http
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedItems } })
      .toPromise();
  }

  public handlePage(event: number): void {
    this.page = event;
    this.displayData(this.filteredItems);
    this.triggerBulkSelect();
  }

  public async checkAllItems(): Promise<void> {
    this.selectedItems = this.items.map((item) => item.id);
    this.triggerBulkSelect();

    await this.http
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedItems } })
      .toPromise();
  }

  public async uncheckAllItems(): Promise<void> {
    this.selectedItems = [];
    this.triggerBulkSelect();

    await this.http
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedItems } })
      .toPromise();
  }

  private getPageItems(items: StakeholderTableRow[]): StakeholderTableRow[] {
    const offset = this.page * this.pageSize;
    return items.slice(offset, offset + this.pageSize);
  }

  private displayData(items): void {
    this.pageItems = this.getPageItems(items);
    this.totalItems = items.length;
  }

  private triggerBulkSelect(): void {
    const pageItemsOptionsLength = this.pageItems.length;
    const checkedPageItemsOptionsLength = this.checkedPageItemsLength();
    const noneOptionChecked = !checkedPageItemsOptionsLength;
    const allOptionsChecked = checkedPageItemsOptionsLength === pageItemsOptionsLength;
    const isIndeterminate = !noneOptionChecked && !allOptionsChecked;

    this.isSelectAllChecked = allOptionsChecked;
    this.isSelectAllIndeterminate = isIndeterminate;

    this.updateSelectedItemsOnPage();
  }

  private checkedPageItemsLength(): number {
    return this.pageItems.filter((item) => this.selectedItems.includes(item.id)).length;
  }

  private upsertPageItemsToSelectedItems(): void {
    this.selectedItems = this.pageItemIds.reduce((acc: string[], id: string) => {
      if (acc.includes(id)) {
        return acc;
      }

      return [...acc, id];
    }, this.selectedItems);
  }

  private removePageItemsFromSelectedItems(): void {
    this.selectedItems = this.selectedItems.filter(
      (id: string) => !this.pageItemIds.includes(id),
    );
  }

  private updateSelectedItemsOnPage(): void {
    this.selectedPageItems = this.pageItems.reduce((accumulator, current) => {
      if (!this.selectedItems.includes(current.id)) {
        return accumulator;
      }
      return [...accumulator, current.id];
    }, []);
  }

  private get pageItemIds(): string[] {
    return this.pageItems.map((item) => item.id);
  }
}
