import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICallbackLink } from '../models/link.model';

@Component({
  selector: 'sbx-side-nav-list',
  templateUrl: './sbx-side-nav-list.component.html',
  styleUrls: ['./sbx-side-nav-list.component.scss'],
})
export class SbxSideNavListComponent {
  @Input() links: ICallbackLink[] = [];
  @Input() activeIndex: number;
  @Input() enableRouter = false;
  @Output() linkClick: EventEmitter<ICallbackLink> = new EventEmitter();

  handleLinkClick(event, link) {
    this.linkClick.emit(link);
  }
}
