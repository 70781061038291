import angular from 'angular';

import { sbCollectRepresentations } from './collect-representations';

/**
 * @ngdoc overview
 * @name sb.workitem.collectRepresentations
 *
 * @description
 * This is the module for the collect representations workitem.
 */
export default angular
  .module('sb.workitem.collectRepresentations', [])
  .component('sbCollectRepresentations', sbCollectRepresentations).name;
