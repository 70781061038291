import { Injectable } from '@angular/core';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { SbxConfirmationModalComponent } from './sbx-confirmation-modal.component';
import { ISbxConfirmationModalConfig } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal-config.type';

@Injectable()
export class SbxConfirmationModalService {
  public constructor(private readonly modalService: SbxModalService) {}

  public open(config: ISbxConfirmationModalConfig) {
    return this.modalService.open(SbxConfirmationModalComponent, config);
  }
}
