/**
 * @ngdoc object
 * @name sb.listpage.sbFilteredListSort
 *
 **/
export const sbFilteredListSort = [
  function () {
    return {
      restrict: 'E',
      require: ['ngModel', '^sbFilteredList'],
      template: require('./templates/sort.html'),
      scope: {
        model: '=ngModel',
        description: '<description',
        default: '@?sortDefault',
      },
      link(scope, element, attrs, [, sbFilteredListCtrl]) {
        if (
          !scope.description ||
          !scope.description.fields ||
          !sbFilteredListCtrl.relevance
        ) {
          return;
        }

        const index = scope.description.fields.findIndex((item) => {
          return item.key === 'sort';
        });

        if (index < 0) {
          return;
        }

        const sort = scope.description.fields[index];
        const enums = sort.templateOptions.enumVocab;
        const nonRelevantEnums = enums.filter((item) => item.value !== 'relevance');
        if (!sbFilteredListCtrl.relevance) {
          scope.description.fields[index].templateOptions.enumVocab = nonRelevantEnums;
        }

        scope.$watch(
          () => sbFilteredListCtrl.activeSearch,
          (activeSearch) => {
            const sortField = scope.description.fields[index];
            if (activeSearch) {
              scope.model.sort.sort = 'relevance';
              sortField.templateOptions.enumVocab = enums;
              scope.model.setSort();
            } else {
              sortField.templateOptions.enumVocab = nonRelevantEnums;
              const vocab = sortField.templateOptions.enumVocab;
              if (scope.model.sort.sort === 'relevance') {
                scope.model.sort.sort = scope.default || vocab[0].value;
                scope.model.setSort();
              }
            }
          },
        );
      },
    };
  },
];
