<div *ngIf="canShowCard()" class="selected-container">
  <sbx-stakeholder-card
    [attr.id]="getModel().sh.id"
    [stakeholderObject]="getModel().sh"
    [format]="cardDisplayFormat"
    *ngIf="getModel().sh"
  ></sbx-stakeholder-card>
  <sbx-stakeholder-card
    [attr.id]="getModel()"
    [stakeholderId]="getModel()"
    [format]="cardDisplayFormat"
    *ngIf="!getModel().sh"
  ></sbx-stakeholder-card>
  <sbx-icon
    *ngIf="requireEmailInput(getModel())"
    (click)="editEmail(getModel())"
    type="edit"
  ></sbx-icon>
  <sbx-icon
    *ngIf="canEditSh(getModel())"
    (click)="editSh(getModel())"
    type="edit"
  ></sbx-icon>
  <sbx-icon
    *ngIf="!noClearButton"
    (click)="removeItem(); emitFocus($event)"
    type="delete"
  ></sbx-icon>
</div>

<sbx-stakeholder-search
  *ngIf="canShowSearch()"
  [options]="stakeholderOptions"
  [securityTicketId]="securityTicketId"
  [excludeFn]="excludeFn"
  [placeholderText]="placeholderText"
  [parseResultsCallback]="addNewUserOption.bind(this)"
  [showSearchIcon]="showSearchIcon"
  (selectStakeholder)="addItem($event)"
  (focus)="emitFocus($event)"
  (blur)="blur.emit($event)"
></sbx-stakeholder-search>

<!-- Backwards Zope Compatibility -->
<input
  type="hidden"
  *ngIf="getModel() && getModel().sh"
  [name]="modelKey"
  [value]="getModel().sh.id"
/>
<input
  type="hidden"
  *ngIf="getModel() && !getModel().sh"
  [name]="modelKey"
  [value]="getModel().id"
/>
<input type="hidden" *ngIf="!getModel()" [name]="modelKey" value />
