import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxWaitForSignaturesWorkitemComponent } from './sbx-wait-for-signatures.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxWaitForSignaturesWorkitemComponent],
  exports: [SbxWaitForSignaturesWorkitemComponent],
})
export class SbxWaitForSignaturesWorkitemModule {
  static entryComponents = [SbxWaitForSignaturesWorkitemComponent];

  static forRoot(): ModuleWithProviders<SbxWaitForSignaturesWorkitemModule> {
    return {
      ngModule: SbxWaitForSignaturesWorkitemModule,
    };
  }
}
