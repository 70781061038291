<sbx-container>
  <sbx-page-header title="Peer Companies"></sbx-page-header>

  <sbx-row>
    <sbx-col [lg]="12">
      Peer Companies are used as a basis against which your company is compared for
      valuation purposes. These other companies must be publically traded for 10 years
      prior to your first grant date to ensure that sufficient share price and
      accounting summaries are available for comparison. 3-5 peer companies should be
      selected, and once selected, it is advised that you do not change them.
    </sbx-col>
  </sbx-row>
  <sbx-row>
    <sbx-col [lg]="12">
      <sbx-table
        itemPlaceholderName="peer companies"
        [data]="peers"
        [columns]="columns"
      ></sbx-table>
      <sbx-button title="Add Peer Company" icon="plus" (click)="addPeer()"></sbx-button>
    </sbx-col>
  </sbx-row>
</sbx-container>

<ng-template #editCol let-item let-row="row">
  <sbx-icon type="close" (click)="removePeer(row)"></sbx-icon>
</ng-template>
