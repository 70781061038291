import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxDropzoneComponent } from './sbx-dropzone.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxActionModule } from '../sbx-action-menu/sbx-action-menu.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxPopoverModule, SbxActionModule],
  declarations: [SbxDropzoneComponent],
  exports: [SbxDropzoneComponent],
})
export class SbxDropzoneModule {
  static entryComponents = [SbxDropzoneComponent];

  static forRoot(): ModuleWithProviders<SbxDropzoneModule> {
    return {
      ngModule: SbxDropzoneModule,
    };
  }
}
