import angular from 'angular';
import { doNotDismissOnNavModalClass } from 'lib/bootstrap/bootstrap';

/**
 * @ngdoc directive
 * @name sb.process.directive:sbOpenProcessModal
 * @restrict A
 *
 * @description
 * Add this attribute to a button to make the click event open a process modal.
 *
 * @element button
 * @param {template} sbOpenProcessModal The id of the intitial workitem.
 * @param {template} sbOpenProcessModalService The name of a process model service
 *    to interact with. See second parameter of `$processModal`.
 * @param {boolean} [sbOpenProcessModalConfirm=true] If true, the process modal
 *    will prompt with discard confirmation.
 * @param {template} [sbOpenProcessModalTitle=undefined] The intial title of
 *    the modal. *Note* the title will auto update as the process progresses.
 * @param {template} [sbOpenProcessModalEventName=undefined] The string name
 *    of an event that when triggred will open the modal (this provides a
 *    programatic way to open the modal).
 */
export const sbOpenProcessModal = [
  '$parse',
  '$injector',
  '$processModal',
  'PromiseErrorCatcher',
  function ($parse, $injector, $processModal, PromiseErrorCatcher) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        function openModal() {
          let procService = $parse(attrs.sbOpenProcessModalService)(scope);
          if (angular.isString(procService)) {
            procService = $injector.get(procService);
          }
          $processModal(
            scope,
            {
              title: attrs.sbOpenProcessModalTitle,
              id: attrs.sbOpenProcessModal,
            },
            procService,
          ).result.catch(PromiseErrorCatcher);
        }
        element.click(() => {
          scope.$apply(openModal);
        });
        if (attrs.sbOpenProcessModalEventName) {
          scope.$on(attrs.sbOpenProcessModalEventName, openModal);
        }
      },
    };
  },
]; // end sbStartSubProcess

/**
 * @ngdoc object
 * @kind function
 * @name sb.process.object:$processModal
 *
 * @description
 * Call this function to open a process modal.
 *
 * @event {emit} $processModal::processProgress This event is sent when the
 *    process moves with the new WI ID. If the id is undefined, the process modal
 *    has been closed (either because its complete or the user early dismissed
 *    the modal).
 *
 * @param {object} scope The scope that is requesting this process modal. This
 *    is needed so the correct scope events are $emited.
 * @param {object} wiValue The value of the initial workitem of the process. This
 *    object is expected to have a `.id` and optionally, a `.title` for the inital
 *    title of the modal. This value can also be a promise that resolves with
 *    the aforementioned object (on rejection, the modal is not opened).
 * @param {object} procService The service object that this modal will interface
 *    with. In particular, it will call `$remove(procId)` when the user asks for
 *    the process to be removed. It will also call `$load()` when the user
 *    closes the modal.
 *
 * @returns {object} This object is the object that `$sbModal.open()` returns.
 */
export const $processModal = [
  '$sbModal',
  'PromiseErrorCatcher',
  function ($sbModal, PromiseErrorCatcher) {
    return function (scope, wiValue, procService) {
      const procModal = $sbModal.open({
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        windowClass: `process-modal ${doNotDismissOnNavModalClass}`,
        template: require('./templates/process-modal.html'),
        controller: 'ProcessModalCtrl',
        resolve: {
          InitialWorkitem: () => wiValue,
          InternalProcService: () => procService,
          OnChangeWorkitem: () => (newId, processCompleted) => {
            scope.$emit('$processModal::processProgress', {
              subProcId: newId,
              completed: processCompleted,
            });
          },
        },
      });
      procModal.result = procModal.result.finally(() => {
        scope.$emit('$processModal::closed', wiValue);
        procService.$load();
      });
      procModal.result.catch(PromiseErrorCatcher);
      return procModal;
    };
  },
]; // end $processModal

/**
 * @ngdoc controller
 * @name sb.process.controller:ProcessModalCtrl
 *
 * @description
 * This is the defined behavior of the process modal.
 */
export const ProcessModalCtrl = [
  '$scope',
  '$window',
  'InitialWorkitem',
  'OnChangeWorkitem',
  'BackendLocation',
  'ProcessModalUrlService',
  function (
    $scope,
    $window,
    InitialWorkitem,
    OnChangeWorkitem,
    BackendLocation,
    ProcessModalUrlService,
  ) {
    const workitemId = InitialWorkitem.id;
    const uiEntityUrl = BackendLocation.entity('ui');

    function procIdFromWi(wiId) {
      return wiId.split('-')[0];
    }

    function processFrameMessage({ action, payload }) {
      switch (action) {
        case 'newWorkitem': {
          OnChangeWorkitem(payload.id, false);
          break;
        }

        case 'processComplete': {
          OnChangeWorkitem(null, true);
          $scope.$close();
          break;
        }

        case 'showConfirmDiscardOrSave': {
          confirmDiscardOrSave();
          break;
        }

        case 'redirectMainWindow': {
          ProcessModalUrlService.goToUrl(payload);
          break;
        }
      }
    }

    function confirmDiscardOrSave() {
      finish();
    }

    function finish() {
      OnChangeWorkitem(null, false);
      $scope.$close();
    }

    $scope.$on('ProcessCtrl::closeSubProcess', finish);
    $scope.url = `${uiEntityUrl}/bare-process/${workitemId}?restrict-next=${procIdFromWi(
      workitemId,
    )}`;
    $scope.processFrameMessage = processFrameMessage;
    $scope.confirmDiscardOrSave = confirmDiscardOrSave;
  },
]; // end ProcessModalCtrl

/**
 * @ngdoc object
 * @kind function
 * @name sb.process.object:$processStatusModal
 *
 * @description
 * Call this function to open a status modal for a given process ID. This
 * modal shows information about outstanding workitems, emails, documents,
 * and more.
 *
 * @param {string} procId The ID of the process.
 * @param {boolean} disableDiscard Whether the `discard` button should be hidden.
 *
 * @returns {promise} This promise will be resolved when the modal is closed.
 *   The promise should never be rejected.
 */
export const $processStatusModal = [
  '$sbModal',
  'PromiseErrorCatcher',
  function ($sbModal, PromiseErrorCatcher) {
    return (procId, disableDiscard) => {
      const prodModal = $sbModal.open({
        template: require('./templates/status-modal.html'),
        size: 'lg',
        controllerAs: 'vm',
        bindToController: true,
        backdrop: 'static',
        controller: function () {
          this.processId = procId;
          this.disableDiscard = disableDiscard;
        },
      });
      prodModal.result.catch(PromiseErrorCatcher);
      return prodModal.result;
    };
  },
]; // end $processStatusModal

export const ProcessModalUrlService = [
  '$window',
  function ($window) {
    return {
      goToUrl(url) {
        if ($window.self !== $window.top && !url.includes('bare-process')) {
          this.postRedirectMainWindow(url);
        } else {
          $window.location.href = url;
        }
      },
      postRedirectMainWindow(url) {
        $window.parent.postMessage(
          {
            action: 'redirectMainWindow',
            payload: url,
          },
          '*',
        );
      },
    };
  },
];
