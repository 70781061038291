import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-bool-checkbox',
  templateUrl: './sbx-bool-checkbox.component.html',
  styleUrls: ['./sbx-bool-checkbox.component.scss'],
})
export class SbxBoolCheckboxComponent extends FieldType<FieldTypeConfig> {
  handleChange(value) {
    this.formControl.setValue(value);
  }
}
