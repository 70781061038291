import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbxDocumentCardModule } from '../sbx-document-card/sbx-document-card.module';
import { SbxDocumentsListComponent } from './sbx-documents-list.component';

@NgModule({
  imports: [CommonModule, SbxDocumentCardModule],
  declarations: [SbxDocumentsListComponent],
  exports: [SbxDocumentsListComponent],
})
export class SbxDocumentsListModule {}
