import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxRadioGroupModule } from '../sbx-radio-group/sbx-radio-group.module';
import { SbxRadioModule } from '../sbx-radio/sbx-radio.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxEditStakeholderComponent } from './sbx-edit-stakeholder.component';
import { SbxFormModule } from '../sbx-form/sbx-form.module';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';

@NgModule({
  imports: [
    CommonModule,
    SbxRadioGroupModule,
    SbxRadioModule,
    SbxButtonModule,
    SbxModalModule,
    SbxFormModule,
    SbxPopoverModule,
  ],
  declarations: [SbxEditStakeholderComponent],
  exports: [SbxEditStakeholderComponent],
  providers: [SbxEditStakeholderComponent],
})
export class SbxEditStakeholderModule {
  static entryComponents = [SbxEditStakeholderComponent];

  static forRoot(): ModuleWithProviders<SbxEditStakeholderModule> {
    return {
      ngModule: SbxEditStakeholderModule,
    };
  }
}
