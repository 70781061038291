import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

const ERROR_MESSAGE =
  'There was an error starting your webcam. Please reload the page and try again.';

@Component({
  selector: 'sbx-webcam',
  templateUrl: './sbx-webcam.component.html',
  styleUrls: ['sbx-webcam.component.scss'],
})
export class SbxWebcamComponent {
  @ViewChild('video', { read: ElementRef }) video;
  @ViewChild('canvas', { read: ElementRef }) canvas;

  @Input() title = 'Capture from Webcam';
  @Output() onCapture = new EventEmitter();

  width = '640';
  height = '480';
  imageBase64: string;
  error: any;
  isCaptured: boolean;
  showWebcam = false;

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'No output video device found.';
        }
      } catch (e) {
        this.error = ERROR_MESSAGE;
      }
    }
  }

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.imageBase64 = this.canvas.nativeElement.toDataURL('image/png');
    this.isCaptured = true;
    this.onCapture.emit(this.imageBase64);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, image.videoWidth, image.videoHeight);
  }

  async webcamRequest() {
    this.showWebcam = !this.showWebcam;
    if (this.showWebcam) {
      await this.setupDevices();
    }
  }
}
