<div *ngIf="data$ | async as data">
  <div *ngIf="showMessages">
    <p *ngIf="allFinished(data); else notdone">
      All pending workflows have finished, press continue to move forward.
    </p>
    <ng-template #notdone>
      <p>
        In order to continue, the following workflows must be finished or discarded.
      </p>
    </ng-template>
  </div>

  <table class="table table-striped" *ngIf="data.length > 0">
    <thead>
      <tr>
        <th>Status</th>
        <th>Workflow Title</th>
        <th>Workflow Owner</th>
        <ng-container *ngFor="let column of additionalinfo">
          <th>{{ column['title'] }}</th>
        </ng-container>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let procRow of data; index as i">
        <td>
          <sbx-icon
            [bubble]="true"
            type="check"
            *ngIf="procRow.process.isFinished; else other"
          ></sbx-icon>
          <ng-template #other>
            <sbx-icon [bubble]="true" type="ellipsis"></sbx-icon>
          </ng-template>
        </td>
        <td>{{ procRow.process.title }}</td>
        <td>
          <span
            *ngIf="procRow.driver || procRow.participants.length > 0; else noprocdriver"
          >
            <sbx-stakeholder-card
              [stakeholderId]="procRow.driver.id"
              format="short"
              *ngIf="procRow.driver"
            >
            </sbx-stakeholder-card>
          </span>

          <div *ngIf="!procRow.driver">
            <span *ngFor="let participant of procRow.participants">
              <sbx-stakeholder-card
                [stakeholderId]="participant.id"
                format="short"
                *ngIf="!procRow.driver"
              >
              </sbx-stakeholder-card>
            </span>
          </div>

          <ng-template #noprocdriver> N/A </ng-template>
        </td>
        <ng-container *ngFor="let columnInfo of additionalinfo">
          <td>{{ columnInfo.values[i] }}</td>
        </ng-container>
        <td>
          <sbx-button
            *ngIf="procRow.canViewStatus"
            [sbxProcessStatusButton]="procRow.process.id"
            icon="information"
            theme="default"
            title="Status"
          ></sbx-button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
