<sbx-container>
  <sbx-col *ngIf="!hideIntroText" [lg]="12">
    <div>
      Review the grants that will be included in future ASC 718 Reports. Grant-level
      customizations override calculated values in the ASC 718 Report for that grant.
    </div>
  </sbx-col>
  <sbx-col [lg]="12">
    <div class="sbx-table-controls">
      <span class="sbx-table-filter">
        <sbx-search-bar
          class="sbx-table-filter-input"
          placeholderText="Search by grantee..."
          [searchResults]="setNameFilter"
          [disableAutoComplete]="true"
          (input)="$event.target.value ? '' : setNameFilter('')"
        ></sbx-search-bar>
      </span>
      <sbx-paging
        *ngIf="totalItems > pageSize"
        class="sbx-table-pages"
        [page]="pageNum"
        [size]="pageSize"
        [totalItems]="totalItems"
        (change)="getPage($event)"
      ></sbx-paging>
    </div>
    <sbx-table
      *sbxLoader="isLoading"
      [data]="grantData"
      [columns]="columns"
      (filterChange)="setNameFilter($event)"
      itemPlaceholderName="grants"
      [placeholderText]="nameFilter ? 'No grants found' : ''"
    ></sbx-table>
  </sbx-col>
</sbx-container>

<ng-template #percentTemplate let-item>
  {{ item ? item.toLocaleString() + '%' : '' }}
</ng-template>

<ng-template #currencyTemplate let-item>
  {{
    item ? '$' + item.toLocaleString(undefined, { minimumFractionDigits: 2 }) : '$0.00'
  }}
</ng-template>

<ng-template #dateTemplate let-item>
  {{ item }}
</ng-template>

<ng-template #numberTemplate let-item>
  {{ item ? item.toLocaleString() : '' }}
</ng-template>

<ng-template #customizationsTemplate let-row="row">
  <ng-container *ngIf="row.excluded">
    <div class="sbx-customization-item">
      <span class="sbx-customization-label">Excluded:</span>
      <span class="sbx-customization-value">True</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!row.excluded">
    <div class="sbx-customization-item" *ngIf="row.expected_term">
      <span class="sbx-customization-label">Exp. Term:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="numberTemplate; context: { $implicit: row.expected_term }"
        ></ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.interest_rate">
      <span class="sbx-customization-label">Interest Rate:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="percentTemplate; context: { $implicit: row.interest_rate }"
        >
        </ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.volatility">
      <span class="sbx-customization-label">Volatility:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="percentTemplate; context: { $implicit: row.volatility }"
        >
        </ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.dividend_rate">
      <span class="sbx-customization-label">Dividend Rate:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="percentTemplate; context: { $implicit: row.dividend_rate }"
        >
        </ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.dividend_frequency">
      <span class="sbx-customization-label">Dividend Freq:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="
            numberTemplate;
            context: { $implicit: row.dividend_frequency }
          "
        >
        </ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.fair_value">
      <span class="sbx-customization-label">Fair Value:</span>
      <span class="sbx-customization-value">
        <ng-container
          *ngTemplateOutlet="currencyTemplate; context: { $implicit: row.fair_value }"
        >
        </ng-container>
      </span>
    </div>
    <div class="sbx-customization-item" *ngIf="row.cost_center">
      <span class="sbx-customization-label">Cost Center:</span>
      <span class="sbx-customization-value">{{ row.cost_center }}</span>
    </div>
  </ng-container>
</ng-template>

<ng-template #grantTemplate let-item let-row="row">
  <span class="sbx-grant-template">
    <a [href]="row.document_link" target="_blank">
      <sbx-icon type="documentFilled"></sbx-icon>
    </a>
    {{ item }}
  </span>
</ng-template>

<ng-template #actionsTemplate let-row="row">
  <div class="sbx-actions">
    <sbx-icon type="edit" (click)="editRow(row)"></sbx-icon>
  </div>
</ng-template>
