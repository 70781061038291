import angular from 'angular';
/**
 * @ngdoc controller
 * @name sb.workitem.counselSettings.controller:CounselSettingsCtrl
 *
 * @description
 * Controller for handling the counsel settings workitem.
 */
const CounselSettingsCtrl = [
  '$scope',
  '$q',
  'ProcessStatus',
  'SettingsService',
  'ProcessButtonModel',
  function ($scope, $q, ProcessStatus, SettingsService, ProcessButtonModel) {
    function saved() {
      return SettingsService.setCounselSettings().catch((error) => {
        ProcessStatus.$setStatus(error, 'danger');
        return $q.reject(error);
      });
    }
    function reset() {
      SettingsService.resetToDefaults();
    }

    SettingsService.getCounselSettings().then(
      () => {
        if (!SettingsService.requiresChange) {
          return;
        }
        const origSettings = angular.copy(SettingsService.settings);
        $scope.$watch(
          () => angular.equals(origSettings, SettingsService.settings),
          ProcessButtonModel.$disableWatch('continue'),
        );
      },
      (reason) => {
        ProcessStatus.$setStatus(reason, 'danger');
      },
    );

    angular.extend($scope, {
      model: SettingsService,
      reset: reset,
    });
    ProcessButtonModel.$addSubmitCondition('continue', saved, 1);
  },
]; // end CounselSettingsCtrl

/**
 * @ngdoc overview
 * @name sb.workitem.counselSettings
 *
 * @description
 * This is the module for the counself settings workitem.
 */
export default angular
  .module('sb.workitem.counselSettings', [])

  .controller('CounselSettingsCtrl', CounselSettingsCtrl).name;
