import angular from 'angular';
import FieldConfig from 'lib/field-configuration';
import Navigation from 'lib/navigation';

import {
  sbPdfFormCreator,
  PDFFormCreationModel,
  sbFieldCustomizedPdfViewer,
} from './form';

/**
 * @ngdoc overview
 * @name sb.lib.pdf.formCreation
 *
 * @description
 * This module houses code for creating arbitary form fields on PDFs.
 */
export default angular
  .module('sb.lib.pdf.formCreation', [FieldConfig, Navigation])

  .component('sbPdfFormCreator', sbPdfFormCreator)
  .component('sbFieldCustomizedPdfViewer', sbFieldCustomizedPdfViewer)
  .factory('PDFFormCreationModel', PDFFormCreationModel).name;
