import { Component } from '@angular/core';
import {
  NavigationStart,
  GuardsCheckEnd,
  NavigationEnd,
  NavigationCancel,
  GuardsCheckStart,
} from '@angular/router';

@Component({
  selector: 'sbx-loader',
  templateUrl: 'sbx-loader.component.html',
  styleUrls: ['sbx-loader.component.scss'],
})
export class SbxLoaderComponent {}

export function routerEventSpinnerStart(event) {
  return event instanceof NavigationStart || event instanceof GuardsCheckEnd;
}

export function routerEventSpinnerStop(event) {
  return (
    event instanceof NavigationEnd ||
    event instanceof NavigationCancel ||
    event instanceof GuardsCheckStart
  );
}
