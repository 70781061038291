<div class="sbx-webcam">
  <div class="sbx-webcam-link">
    <span (click)="webcamRequest()">{{ title }}</span>
  </div>
  <div *ngIf="showWebcam" class="sbx-webcam-video">
    <div *ngIf="!error">
      <video #video autoplay></video>
      <canvas #canvas [width]="width" [height]="height"></canvas>
    </div>

    <div class="sbx-webcam-error" *ngIf="error">
      <div class="sbx-webcam-error-icon"><sbx-icon type="alert"></sbx-icon></div>
      <div class="sbx-webcam-error-message">{{ error }}</div>
    </div>
  </div>
</div>
