import {
  Component,
  Inject,
  ViewChild,
  TemplateRef,
  Input,
  OnInit,
} from '@angular/core';
import { SbxModalService } from '../../../../sbx-modal/sbx-modal.service';

@Component({
  selector: 'sbx-exhibit-modal',
  templateUrl: './sbx-exhibit-modal.component.html',
  styleUrls: ['./sbx-exhibit-modal.component.scss'],
})
export class SbxExhibitModalComponent implements OnInit {
  @Input() listing: any;
  @ViewChild('setDownload', { static: true }) setDownload: TemplateRef<any>;

  tableColumns: any = [
    { key: 'title', title: '' },
    { key: 'href', title: '' },
  ];

  constructor(@Inject(SbxModalService) public sbxModalService: SbxModalService) {}

  ngOnInit() {
    this.tableColumns.find((c) => c.key === 'href').template = this.setDownload;
  }

  attachExhibit() {
    const exhibitPromise = new Promise((resolve, failure) => {
      const element: HTMLElement = document.getElementById(
        'attach_exhibits',
      ) as HTMLElement;
      element.dispatchEvent(
        new CustomEvent('click', {
          detail: {
            successCallbackFn: (exhibitListings) => {
              // Last exhibit will be the one that was just added
              resolve(exhibitListings[exhibitListings.length - 1]);
            },
            failureCallbackFn: (error) => {
              failure();
            },
          },
        }),
      );
    });
    this.sbxModalService.close(exhibitPromise);
  }

  assignExhibit(row) {
    const exhibitPromise = new Promise((resolve) => {
      resolve(row);
    });
    this.sbxModalService.close(exhibitPromise);
  }
}
