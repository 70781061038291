import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxModalHeaderComponent } from './sbx-modal-header.component';
import { SbxModalContentComponent } from './sbx-modal-content.component';
import { SbxModalFooterComponent } from './sbx-modal-footer.component';
import { SbxModalDirective } from './sbx-modal.directive';
import { SbxModalComponent } from './sbx-modal.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxModalWrapperComponent } from './sbx-modal-wrapper.component';

@NgModule({
  imports: [NgbModule, CommonModule, SbxIconModule],
  declarations: [
    SbxModalComponent,
    SbxModalContentComponent,
    SbxModalDirective,
    SbxModalFooterComponent,
    SbxModalHeaderComponent,
    SbxModalWrapperComponent,
  ],
  exports: [
    SbxModalHeaderComponent,
    SbxModalContentComponent,
    SbxModalFooterComponent,
    SbxModalDirective,
    SbxModalWrapperComponent,
  ],
})
export class SbxModalModule {
  static entryComponents = [SbxModalWrapperComponent];

  static forRoot(): ModuleWithProviders<SbxModalModule> {
    return {
      ngModule: SbxModalModule,
    };
  }
}
