import { SbxSelectOption } from '@/shared/sbx-select/sbx-select.component';
import { Pipe, PipeTransform } from '@angular/core';
import { LightweightProfileTab } from '@shoobx/types/webapi-v2';
import { TabViewModel } from './sbx-responsive-nav.component';

@Pipe({ name: 'tabsToSelectOptions' })
export class TabsToSelectOptionsPipe implements PipeTransform {
  public transform(value: TabViewModel[]): SbxSelectOption[] {
    return value.map(
      (tab: LightweightProfileTab, index: number) =>
        new SbxSelectOption(tab.title, index),
    );
  }
}
