import { Subject } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.rx.object:$dropTransaction
 *
 * @description
 * Since drop and moved callbacks happen in different js contexts,
 * one can use this helper to make it happen within one "transaction".
 *
 * @param {string} startDragName Key into context where there the start drag subject
 *   should live.
 * @param {string} finishDragName Key into context where there the finish drag subject
 *   should live.
 *
 * @returns {observable<Object>} This will emit events of objects with `startArgs` and
 *   `finishArgs`.
 */
export const $dropTransaction = [
  function () {
    return function (startDragName, finishDragName) {
      const startDrag$ = (this[startDragName] = new Subject());
      return startDrag$.pipe(
        switchMap((startArgs) => {
          const finish$ = (this[finishDragName] = new Subject());
          // For safety, use first (probably not needed).
          return finish$.pipe(
            first(),
            map((finishArgs) => ({ startArgs, finishArgs })),
          );
        }),
      );
    };
  },
]; // end $dropTransaction
