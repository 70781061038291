import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Downgrade } from '../downgrade';
import { DocumentCard } from '@shoobx/types/webapi-v2';
import { SbxUrlService } from '@/core/url/url.service';
import { SbxDocumentCardService } from './sbx-document-card.service';
import { ISbxIconType } from '@/core/constants/icons';

export type SbxDocumentCardFormat = 'full' | 'detailed' | 'standard' | 'lite' | 'micro';

@Downgrade.Component('ngShoobx', 'sbx-document-card')
@Component({
  selector: 'sbx-document-card',
  templateUrl: './sbx-document-card.component.html',
  styleUrls: ['./sbx-document-card.component.scss'],
})
export class SbxDocumentCardComponent implements OnChanges {
  @Input() documentId: string;
  @Input() documentCard: DocumentCard;
  @Input() format: SbxDocumentCardFormat = 'standard';
  @Input() showDocumentLinks = true;
  @Input() showProminentMetadata = true;

  docIcons: { [key: string]: ISbxIconType } = {
    generated: 'docGenerated',
    uploaded: 'docUploaded',
    deferred: 'docDeferred',
    pending: 'docPending',
    certificate: 'docCertificate',
  };
  loading = false;

  constructor(
    @Inject(SbxDocumentCardService) private documentCardService: SbxDocumentCardService,
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentId) {
      this.loadDocumentCard(changes.documentId.currentValue);
    }

    if (changes.documentCard) {
      this.setDocumentCard(changes.documentCard.currentValue);
    }
  }

  getDocumentUrl() {
    const returnTo = btoa(`${location.href}^^^`);
    return this.sbxUrlService.canonicalUrl(['documents', this.documentCard.id], {
      queryParams: {
        // eslint-disable-next-line camelcase
        return_to: returnTo,
      },
    });
  }

  setDocumentCard(value?: DocumentCard): void {
    if (value) {
      this.documentCard = value;
    } else {
      this.documentCard = {
        title: '',
        id: '',
        effectiveDate: null,
        type: null,
        displayType: null,
        canView: false,
        shouldDisplayStatus: false,
        status: null,
        customizations: 0,
        prominentMetadata: [],
      };
    }
  }

  async loadDocumentCard(id: string) {
    this.loading = true;

    try {
      const card = await this.documentCardService
        .getDocumentCard(id, this.showProminentMetadata)
        .toPromise();
      this.setDocumentCard(card);
    } catch {
      this.setDocumentCard();
    } finally {
      this.loading = false;
    }
  }
}
