import moment from 'moment';

/**
 * @ngdoc compoent
 * @name sb.stakeholder.sbStakeholdersEvents
 *
 **/
export const sbStakeholdersEvents = {
  controllerAs: 'vm',
  template: require('./templates/stakeholder-events.html'),
  bindings: {
    employee: '<sbEmployee',
    noteForm: '<sbNoteForm',
    items: '<sbItems',
    links: '<sbLinks',
    stakeholderId: '@sbStakeholderId ',
    errorMessage: '@?sbErrorMessage',
  },
  controller: [
    '$scope',
    '$sbModal',
    'PromiseErrorCatcher',
    function ($scope, $sbModal, PromiseErrorCatcher) {
      function noteModal(noteId) {
        $sbModal
          .open({
            size: 'lg',
            template: require('./templates/note-modal.html'),
            controllerAs: 'vm',
            bindToController: true,
            resolve: {
              note: this.items.find((item) => item.id === noteId) || {},
              form: () => this.noteForm,
              stakeholderId: () => this.stakeholderId,
            },
            controller: NoteModalCtrl,
          })
          .result.catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        this.noteModal = noteModal.bind(this);
        if (!this.employee.terminationDate) {
          const today = moment();
          const startDate = moment(this.employee.startDate, 'YYYY-MM-DD');

          const years = today.diff(startDate, 'year');
          startDate.add(years, 'years');

          const months = today.diff(startDate, 'months');
          startDate.add(months, 'months');
          const days = today.diff(startDate, 'days');

          this.duration = '';
          if (years) {
            this.duration += `${years} years `;
          }
          if (months) {
            this.duration += `${months} months `;
          }
          if (days) {
            this.duration += `${days} days`;
          }
        }
      };
    },
  ],
};

const NoteModalCtrl = [
  'note',
  'form',
  'stakeholderId',
  'StakeholderEvents',
  '$confirm',
  '$window',
  'PromiseErrorCatcher',
  function (
    note,
    form,
    stakeholderId,
    StakeholderEvents,
    $confirm,
    $window,
    PromiseErrorCatcher,
  ) {
    function saveNote() {
      StakeholderEvents.saveStakeholderNote(this.stakeholderId, this.model).then(() =>
        $window.location.reload(),
      );
    }
    function deleteNote() {
      $confirm({
        body: `
        <b>DELETE NOTE?</b>
        <p>
          Depending on the content of the information you are about to delete, it could
          be considered part of an employee's file or records and subject to certain
          statutory retention and disclosure requirements. Consult the company's
          attorney if you are unsure about the status of the information you wish to
          delete.
        </p>
      `,
        alertType: 'warning',
        confirmButtonText: 'Yes',
        dismissButtonText: 'No',
      })
        .then(() =>
          StakeholderEvents.deleteStakeholderNote(this.stakeholderId, {
            noteId: this.model.noteId,
          }),
        )
        .then(() => $window.location.reload())
        .catch(PromiseErrorCatcher);
    }

    this.stakeholderId = stakeholderId;
    this.form = form;
    this.errors = {};
    this.modalTitle = note && note.id ? 'Edit Note' : 'Add Note';
    this.model = {
      title: note.title,
      effectiveDate: note.effectiveDate,
      noteId: note.id,
      note: note.note,
    };
    this.saveNote = saveNote.bind(this);
    this.deleteNote = deleteNote.bind(this);
  },
]; // end NoteModalCtrl

/**
 * @ngdoc compoent
 * @name sb.stakeholder.sbStakeholderNoteHistory
 *
 **/
export const sbStakeholderEventHistory = {
  controllerAs: 'vm',
  template: require('./templates/history.html'),
  bindings: {
    items: '<sbItems',
  },
  controller() {
    this.$onInit = () => {
      this.model = { selected: false };
    };
  },
};
