import angular from 'angular';
import RX from '../rx';
import Promise from '../promise';

import { sbClickOutside } from './click-outside';
import { $scrollProps, sbScrollable, sbScrollTo, sbScrollNotAtBottom } from './scroll';

/**
 * @ngdoc directive
 * @name sb.lib.events.directive:sbNoPropagation
 * @restrict A
 *
 * @description
 * This allows you to easily add stopPropagation() to events on element.
 *
 * @element ANY
 * @param {template} sbNoPropagation Events to block. You may specify any number
 *    by seperating them with spaces.
 *
 * @example
   <div data-ng-init="counter = 0" data-ng-click="counter = counter + 1">
      Counter: {{ counter }}
      <div data-sb-no-propagation="click hover">
        This div will not emit click or hover events.
        <button type="button">This button</button> will <b>not</b>
        emit an event.
      </div>
      <button type="button">This button</button> will emit a counter though
   </div>
 */
function sbNoPropagation() {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.on(attrs.sbNoPropagation, (evt) => {
        evt.stopPropagation();
      });
    },
  };
} // end sbNoPropagation

/**
 * @ngdoc overview
 * @name sb.lib.events
 *
 * @description
 * This module houses handy JavaScript event utilities.
 */
export default angular
  .module('sb.lib.events', [RX, Promise])

  .directive('sbScrollTo', sbScrollTo)
  .directive('sbScrollable', sbScrollable)
  .directive('sbScrollNotAtBottom', sbScrollNotAtBottom)
  .directive('sbClickOutside', sbClickOutside)
  .directive('sbNoPropagation', sbNoPropagation)
  .factory('$scrollProps', $scrollProps).name;
