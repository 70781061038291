export interface TypeError {
  error: boolean;
  errorMsg: string;
}

const restrictedTypes = [
  'text/x-script.python',
  'text/x-python',
  'application/vnd.microsoft.portable-executable',
  'application/x-dosexec',
  'application/x-msdownload',
];

const restrictedExtensions = ['.exe', '.com', '.bat', '.py', '.sh'];

export function checkForSuspiciousTypes(file: File): TypeError | null {
  if (!restrictedTypes.includes(file.type) && !isForbiddenExtension(file.name)) {
    return null;
  }
  const result: TypeError = {
    error: true,
    errorMsg: `Wrong ${file.name} type.`,
  };
  return result;
}

function isForbiddenExtension(fileName: string): boolean {
  const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1);
  return restrictedExtensions.includes(`.${fileExtension}`);
}
