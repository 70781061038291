import angular from 'angular';
function buttonText(option, defaultText) {
  if (option === false) {
    // When its *explicitly* false, we want no button of this type.
    return false;
  }
  return option || defaultText;
}

/**
 * @ngdoc component
 * @name sb.lib.bootstrap.component:sbAlertModal
 *
 * @description
 * Use this in a modal template to create an "alert" style.
 *
 * @param {expression} onConfirm Expression to evaluate on primary button click.
 * @param {expression} onDismiss Expression to evaluate on close/cancel click.
 * @param {boolean} [buttonsDisabled=false] When truthy, buttons will be disabled.
 *    This is useful for doing some async action and diasbling the controls
 * @param {string} [confirmText='Confirm'] Text of primary button.
 * @param {string} [dismissText='Cancel'] Text of dimiss button.
 * @param {template} [alertType=undefined] Can be `danger`, `warning`, or `info`.
 *    No option is also valid (will not have alert banner style).
 */
export const sbAlertModal = {
  transclude: true,
  controllerAs: 'vm',
  template: require('./templates/alert-modal.html'),
  bindings: {
    onConfirm: '&',
    onDismiss: '&',
    buttonsDisabled: '<?',
    confirmText: '<?',
    dismissText: '<?',
    alertType: '@?',
  },
  controller: function () {
    this.$onInit = () => {
      this.buttonText = buttonText;
    };
  },
};

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.bootstrap.object:$confirm
 *
 * @description
 * This is a easy way to get a confirm modal with native angular. It is intended
 * as a replacement for `$.confirm`.
 *
 * @param {object} options This object has the following optional properties:
 *    @property {string} [alertType=undefined] Type of alert (eg, danger, info).
 *    @property {string} [size='sm'] Size of the modal.
 *    @property {string} [title=undefined] Title of modal.
 *    @property {string} [body='Are you sure?'] Body HTML content (angular inside
 *        this string will not work).
 *    @property {string} [confirmButtonText='Confirm'] Text of the confirm (primary)
 *        button. Explicit `false` means no button of this type.
 *    @property {string} [dismissButtonText='Cancel'] Text of the dismiss (info)
 *        button. Explicit `false` means no button of this type.
 *    @property {function} [confirmPromise=undefined] Async action to be resolved on
 *        confirm. The promise returned by this function must be resolved before the
 *        modal can be closed.
 *    @property {function} [dismissPromise=undefined] Async action to be resolved on
 *        dismiss (see above).
 *
 * @returns {Promise} Resolves when the confirm dialog is successfully closed
 *    and is rejected when successfully dismissed.
 */
export const $confirm = [
  '$sbModal',
  '$sce',
  function ($sbModal, $sce) {
    return function (options) {
      options = options || {};
      return $sbModal.open({
        size: options.size,
        keyboard: false,
        backdrop: 'static',
        windowClass: 'confirm-modal',
        template: require('./templates/confirm-modal.html'),
        controller: [
          '$scope',
          '$q',
          function ($scope, $q) {
            function attempt(userFuncName, closeFuncName) {
              const func = angular.isFunction(options[userFuncName])
                ? options[userFuncName]
                : angular.noop;
              $scope.error = null;
              $scope.loading = true;
              $q.when(func())
                .then(
                  (success) => {
                    $scope[closeFuncName](success);
                  },
                  (rejection) => {
                    $scope.error = rejection;
                  },
                )
                .finally(() => {
                  $scope.loading = false;
                });
            }

            function trustAsHtml(str) {
              return $sce.trustAsHtml(str);
            }

            $scope.trustAsHtml = trustAsHtml;
            $scope.title = options.title || false; // Explicit false for ngIf binding.
            $scope.alertType = options.alertType;
            $scope.body = options.body || 'Are you sure?';
            $scope.confirmButtonText = buttonText(options.confirmButtonText, 'Confirm');
            $scope.dismissButtonText = buttonText(options.dismissButtonText, 'Cancel');
            $scope.attemptDismiss = function () {
              attempt('dismissPromise', '$dismiss');
            };
            $scope.attemptConfirm = function () {
              attempt('confirmPromise', '$close');
            };
          },
        ],
      }).result;
    };
  },
]; // end $confirm
