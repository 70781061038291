import { Component, Input, OnInit } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http/';
import { FormGroup } from '@angular/forms';

const SELECTOR = 'sbx-checkpoint';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-checkpoint.component.html',
  styleUrls: ['sbx-checkpoint.component.scss'],
})
/**
 * A standard Shoobx checkpoint
 * @example Component
 * The component is used within checkpoint TAL templates and must be passed
 * information from the process to determine what should be presented:
 *   <tal:block tal:define="global formnames python:
 *   ','.join([form.get('name') for form in template.get('forms')])
 *   if template.get('forms') else None"></tal:block>
 *   <sbx-checkpoint tal:attributes="
 *   processid python:parent_process_id; shid python:checkpointUser; useriscounsel python:user_is_counsel;
 *   formnames python:formnames; upload python:upload; defaultapprovers python:default_approvers"
 *   [sbmodel]="model"></sbx-checkpoint>
 *
 * <sbx-checkpoint
 *     processid="parent_process_id"
 *     shid="checkpointUser";
 *     useriscounsel="user_is_counsel"
 *     formnames="formnames"
 *     upload="upload"
 *     defaultapprovers="default_approvers"
 *     [sbmodel]="model"></sbx-checkpoint>
 */
export class SbxCheckpointComponent implements OnInit {
  @Input() processid: string;
  @Input() shid: string;
  @Input() useriscounsel: string;
  @Input() formnames: string;
  @Input() upload: string;
  @Input() defaultapprovers: string;
  @Input() defaultsetid: string;
  @Input() sbmodel: { $forms: any; $formData: any };
  data: any;
  public legalReviewForm: any;
  public formNameList = [];
  public reviewForm = 'checkpoint_automatic_release';
  public reviewField = 'auto_release_docs';
  public legalForm = 'checkpoint_lawyer_check';
  public legalField = 'legal_review_needed';
  public hasLegalForm = false;
  public formNameFieldMap = {
    [this.reviewForm]: this.reviewField,
    [this.legalForm]: this.legalField,
  };

  constructor(private service: SbxHttpClient) {}

  checkpointData(): Promise<any> {
    let endpoint =
      `checkpoint?process_id=${this.processid}&sh_id=${this.shid}` +
      `&upload=${this.upload}&default_approvers=${this.defaultapprovers}`;
    if (this.defaultsetid) {
      const setList = JSON.parse(this.defaultsetid) || [this.defaultsetid];
      endpoint += setList.map((set) => `&default_set_id=${set}`).join('');
    }
    return this.service.entity('2').get(endpoint).toPromise();
  }

  legalReviewChecked(): boolean {
    // if the checkpoint_lawyer_check form is not present, returns False
    // if present and checked, return True, else return False
    const isPresent = this.formNameList.find((f) => f === this.legalForm);
    if (isPresent === undefined) {
      return false;
    }
    const formData = this.sbmodel.$formData[this.legalForm];
    if (formData !== undefined) {
      return formData[this.legalField];
    }
  }

  docReviewPossible(): boolean {
    // checkpoint_automatic_release should be visible if one of the following is true:
    // 1. the process has approvers and is generative, not uploaded
    // 2. checkpoint_lawyer_check form is present and checked
    // 3. legal review is required (and not uploaded and not user_is_counsel?)
    const available =
      this.data.has_approvers ||
      this.legalReviewChecked() ||
      (this.data.approval_required &&
        this.upload === 'False' &&
        this.useriscounsel === 'False');
    // We need to invalidate form data if the form goes away
    if (!available) {
      this.sbmodel.$formData[this.reviewForm][this.reviewField] = false;
    }
    return available;
  }

  isFormAvailable(formName: string): boolean {
    if (formName === this.reviewForm) {
      return this.docReviewPossible();
    }
    return true;
  }

  getLabel(formName: string): string {
    return this.sbmodel.$forms[formName].fields[0].templateOptions.label;
  }

  getHelp(formName: string): string {
    return this.sbmodel.$forms[formName].fields[0].templateOptions.help;
  }

  private formToFormly(
    formName: string,
    fields?: any[],
  ): { form: FormGroup; fields: any[]; model: any } {
    const formFields = (fields || this.sbmodel.$forms[formName].fields).map(
      (field) => ({
        ...field,
        templateOptions: { ...field.templateOptions, required: false },
      }),
    );
    return {
      form: new FormGroup({}),
      fields: formFields,
      model: this.sbmodel.$formData[formName],
    };
  }

  async ngOnInit(): Promise<void> {
    this.data = await this.checkpointData();
    if (this.formnames !== undefined) {
      // Need to convert formnames from string to array using the comma separator
      this.formNameList = this.formnames.split(',');
    }
    this.hasLegalForm = this.formNameList.indexOf(this.legalForm) > -1;
    if (this.hasLegalForm) {
      const legalFields = this.sbmodel.$forms[this.legalForm].fields
        .filter((field) => field.key === 'legal_review_needed')
        .map((field) => ({
          ...field,
          templateOptions: { ...field.templateOptions, label: '' },
        }));
      this.legalReviewForm = this.formToFormly(this.legalForm, legalFields);
    }
  }
}
