import angular from 'angular';

const VotingModalCtrl = [
  '$q',
  'Voters',
  'RecordCallback',
  'Section',
  function ($q, Voters, RecordCallback, Section) {
    function submit() {
      const prom = RecordCallback({
        $section: Section,
        $abstentions: Object.keys(this.abstentions).filter(
          (shId) => this.abstentions[shId],
        ),
        $dissensions: Object.keys(this.dissensions).filter(
          (shId) => this.dissensions[shId],
        ),
      });
      this.disabled = true;
      this.status = null;
      $q.when(prom)
        .then(() => {
          this.$close();
        })
        .catch((err) => {
          this.status = err;
        })
        .finally(() => {
          this.disabled = false;
        });
    }
    const reduction = (acc, shId) => {
      acc[shId] = true;
      return acc;
    };
    this.abstentions = Section.abstentions.reduce(reduction, {});
    this.dissensions = Section.dissensions.reduce(reduction, {});
    this.voters = Voters;
    this.submit = submit.bind(this);
  },
]; // end VotingModalCtrl

/**
 * @ngdoc component
 * @name sb.workitem.resolution.component:sbResolutionGroups
 *
 * @description
 * This component is the basic block of groups for the workitem. It has two transclusion
 * slots, `emptyText`, for when there are no groups in this listing, and
 * `markGroupButton`, for the mark group button HTML.
 *
 * @param {template} header The text of the resolution "header"/prefix.
 * @param {template} resolutionType The type of resolution items
 * @param {array} groups An array of the groups to display.
 * @param {array} [allowedGroupTypes=[]] An array of allowed group drop group types.
 *   This defaults to no allowed types.
 * @param {boolean} [disabled=false] When true, controls/drag will be disabled.
 * @param {array<stakeholder>} [voters=[]] The array of eligible voters. An empty
 *   array implies no voting is allowed.
 * @param {expression} markGroup Callback when "mark" action was clicked. `$group` will
 *   be in the namespace.
 * @param {expression} [recordVoting=undefined] Callback when resolution is voted on.
 *   This function can return a promise and has `$resolution`, a list of `$abstentions`
 *   and a list of `$dissensions` in the namespace
 * @param {expression} [resolutionMoved=undefined] Callback when resolution item is moved,
 *   ie, when a resolution item is moved within this section or to another section.
 *   `$section` and `$fromIndex` will be in the namespace.
 * @param {expression} [resolutionDropped=undefined] Callback when resolution item is
 *   dropped, ie when the resolution is released in a section. `$section`, `$resolution`,
 *   and `$toIndex` will be in the namespace.
 * @param {expression} [groupMoved=undefined] Callback when group item is moved.
 *   `$fromIndex` will be in the namespace.
 * @param {expression} [groupDropped=undefined] Callback when group item is dropped.
 *   `$toIndex` and `$group` will be in the namespace.
 * @param {expression} [deleteGroup=undefined] Callback when group delete action is
 *   clicked. `$group` will be in the namespace.
 * @param {expression} [editGroup=undefined] Callback when group edit action is
 *   clicked. `$group` will be in the namespace.
 */
export const sbResolutionGroups = {
  template: require('./templates/groups.html'),
  controllerAs: 'vm',
  bindings: {
    resolutionType: '@',
    groups: '<',
    allowedGroupTypes: '<?',
    disabled: '<?',
    voters: '<?',
    hasVoting: '<?',
    markGroup: '&',
    recordVoting: '&?',
    resolutionMoved: '&?',
    resolutionDropped: '&?',
    groupMoved: '&?',
    groupDropped: '&?',
    deleteGroup: '&?',
    editGroup: '&?',
    resetGroup: '&?',
    isHidden: '=',
  },
  transclude: {
    placeholderSlot: '?emptyText',
    markGroupSlot: '?markGroupButton',
  },
  controller: [
    '$sbModal',
    '$scope',
    'PromiseErrorCatcher',
    function ($sbModal, $scope, PromiseErrorCatcher) {
      function openVotingModal(section) {
        $sbModal
          .open({
            controllerAs: 'vm',
            bindToController: true,
            windowClass: 'resolution-voting-modal',
            template: require('./templates/record-voting-modal.html'),
            resolve: {
              RecordCallback: () => this.recordVoting || angular.noop,
              Section: () => section,
              Voters: () => this.voters,
            },
            controller: VotingModalCtrl,
          })
          .result.catch(PromiseErrorCatcher);
      }
      function showResolutionDetails(resolution, group, sectionIndex) {
        $sbModal
          .open({
            size: 'lg',
            controllerAs: 'vm',
            bindToController: true,
            windowClass: 'resolution-details-modal',
            template: require('./templates/resolution-details-modal.html'),
            controller: [
              function () {
                this.content = trustAsHtml(resolution);
                this.exhibits = resolution.exhibits;
                this.allowCustom = resolution.allowCustom;
                this.exhibits.forEach((item, index) => {
                  item.name =
                    'Exhibit ' + String.fromCharCode('A'.charCodeAt(0) + index);
                  return item;
                });
                this.editGroup = $scope.vm.editGroup;
                this.group = group;
                this.sectionIndex = sectionIndex;
              },
            ],
          })
          .result.catch(PromiseErrorCatcher);
      }

      function trustAsHtml(resolution) {
        function exhibitMatch(id, exhibits) {
          for (let i = 0; i < exhibits.length; i++) {
            if (exhibits[i].id === id) {
              return {
                index: String.fromCharCode('A'.charCodeAt(0) + i),
                title: exhibits[i].title,
              };
            }
          }
        }
        const element = angular.element('<div>' + resolution.content + '</div>');
        const pills = element[0].querySelectorAll('.richtext-exhibit-pill');

        pills.forEach((item) => {
          const id = item.getAttribute('data-exhibit-id');
          const match = exhibitMatch(id, resolution.exhibits);
          if (match) {
            item.setAttribute('sb-popover', '::true');
            item.innerText = 'Exhibit ' + match.index;
            item.setAttribute('sb-popover-content', '"' + match.title + '"');
            item.setAttribute('sb-popover-placement', 'auto top');
            item.setAttribute('sb-popover-trigger', 'hover');
          }
        });
        return element.html();
      }

      function toggleSection() {
        this.hidden = !this.hidden;
      }

      this.$onInit = () => {
        this.trustAsHtml = trustAsHtml.bind(this);
        this.toggleSection = toggleSection.bind(this);
        this.openVotingModal = openVotingModal.bind(this);
        this.showResolutionDetails = showResolutionDetails.bind(this);
        this.voting = this.hasVoting === undefined ? true : this.hasVoting;
        this.hidden = this.isHidden === undefined ? false : this.isHidden;
      };
    },
  ],
}; // end sbResolutionGroups
