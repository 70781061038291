function regexEscape(str) {
  // Copied from lodash source. This is not in the JS standard. :(
  return str.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
}
function highlight(label, currentQuery) {
  const regex = new RegExp(`(${regexEscape(currentQuery)})`, 'gi');
  return label.replace(regex, '<strong>$1</strong>');
}

/**
 * @ngdoc service
 * @name sb.lib.form.AscService
 * @requires sb.lib.url.BackendLocation
 *
 * @description
 * The AscService can be used to fetch server information about stock tickers.
 */
export const AscService = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  function (SimpleHTTPWrapper, BackendLocation) {
    return {
      /**
       * @ngdoc method
       * @name sb.lib.form.AscService#getTicker
       * @methodOf sb.lib.form.AscService
       *
       * @description
       * Get the stock tickers from backend based on the query
       *
       * @param {string} query: Search query for stock tickers, partial match
       *
       * @returns {promise} Returns a promise that resolves with the form def.
       */
      getTicker(query) {
        // Bakcend call to return the result of the query
        return SimpleHTTPWrapper({
          method: 'GET',
          url: BackendLocation.entity(1) + 'asc',
          params: { query: query },
        })
          .then((response) => {
            const filtered = response;
            if (filtered.length) {
              return filtered.map(({ token, title }) => ({
                token,
                title,
                html: highlight(title, query),
              }));
            }
            return [];
          })
          .catch(() => {
            this.error = 'Sorry no ticker found';
            return [];
          });
      },
    };
  },
]; // end AscService

/**
 * @ngdoc component
 * @name sb.lib.form.sbStockTickerChooser
 *
 * @description
 * Standard widget to allow selecton of stock ticker with type ahead
 * and backend query calls
 *
 */
export const sbStockTickerChooser = {
  template: require('./templates/widgets/stock-ticker-chooser.html'),
  controllerAs: 'vm',
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
  },
  controller: [
    'AscService',
    'PromiseErrorCatcher',
    '$element',
    function (AscService, PromiseErrorCatcher, $element) {
      function findStockTicker(query) {
        return AscService.getTicker(query).catch(PromiseErrorCatcher);
      }

      function selectStockTicker({ token, title }) {
        this.model.value = token;
        return title;
      }

      this.$onInit = () => {
        this.findStockTicker = findStockTicker.bind(this);
        this.selectStockTicker = selectStockTicker.bind(this);
        if (this.model.value) {
          const $input = $element.find('input').eq(0);
          this.findStockTicker(this.model.value).then((res) => {
            $input.val(res[0].title);
          });
        }
      };
    },
  ],
}; // end sbStockTickerChooser
