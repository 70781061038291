import angular from 'angular';

const ADD_INVESTOR_FIELDS = () => [
  {
    key: 'investor_type',
    type: 'enum-dropdown',
    templateOptions: {
      label: 'Investor Type',
      help:
        'A known investor will sign the term sheet. ' +
        'A placeholder will not sign the term sheet',
      required: true,
      subfield: 0,
      enumVocab: [
        {
          label: 'Known Investor',
          value: 'investor',
        },
        {
          label: 'Placeholder Investor',
          value: 'placeholder',
        },
      ],
    },
  },
  {
    key: 'investor',
    type: 'stakeholder',
    hideExpression: '!(model["investor_type"] === "investor")',
    templateOptions: {
      label: 'Investor',
      required: true,
      subfield: 1,
      stakeholderButtonTitle: 'Investor',
      stakeholderOptions: {
        creationModalEntityNamePlaceholder: null,
        allowExisting: true,
        affiliateLabel: '',
        entityOptions: {
          affiliates: true,
          type: 'investment',
          allowSelfTeam: false,
        },
        creationModalTeamLabel: null,
        onlyActive: false,
        placeholderText: null,
        creationModalEntityNameLabel: null,
        affiliateCheckboxLabel: '',
        allowEntity: true,
        affiliatePlaceholder: '',
        allowPerson: true,
        allowStakes: false,
        format: 'full;',
        creationModalTitleText: null,
        allowCreation: true,
        creationModalIndividualLabel: null,
        alwaysDisplayAffiliate: false,
      },
    },
  },
  {
    key: 'placeholder',
    type: 'string-textline',
    hideExpression: '!(model["investor_type"] === "placeholder")',
    templateOptions: {
      label: 'Placeholder Investor',
      required: true,
      subfield: 1,
    },
  },
];

/**
 * @ngdoc object
 * @name sb.workitem.setOwnershipAndControl.controller:SetOwnershipAndControlWiCtrl
 *
 * @description
 * Main controller for the set ownership and control WI.
 */
export const SetOwnershipAndControlWiCtrl = [
  '$scope',
  '$q',
  '$formModal',
  'ProcessStatus',
  'ProcessButtonModel',
  'Stakeholders',
  'SetOwnershipAndControlModel',
  'EquityManager',
  'InvestorManager',
  'PromiseErrorCatcher',
  function (
    $scope,
    $q,
    $formModal,
    ProcessStatus,
    ProcessButtonModel,
    Stakeholders,
    SetOwnershipAndControlModel,
    EquityManager,
    InvestorManager,
    PromiseErrorCatcher,
  ) {
    const serialize = function (keepOther) {
        return angular.extend({}, $scope.dealParam, {
          investors: ($scope.investors || []).filter((investor) => {
            return investor.id !== 'otherInvestor' || keepOther;
          }),
        });
      },
      recomputePostMoneyData = function (dontRedoSlides) {
        $scope.investors = InvestorManager.computeInvestors(
          $scope.investors,
          $scope.dealParam,
        );
        $scope.postMoneyNames = angular.extend(
          InvestorManager.names(),
          $scope.CHART_NAMES,
        );
        $scope.postMoneyData = EquityManager.computePostMoneyData(
          serialize(true),
          $scope.totalCommonStock,
          $scope.keyHolderEquity,
          $scope.currentOpenPool,
          $scope.otherGrantedCommon,
        );
        if (dontRedoSlides) {
          // Don't recompute slides if explicitly asked not to
          return;
        }
        $scope.slides.ppSlides = InvestorManager.computePpSlides(
          $scope.dealParam.totalSize + $scope.conversions,
        );
        $scope.slides.shVotingSlides = InvestorManager.computeShSlides(
          $scope.dealParam.totalSize,
          $scope.dealParam.totalSize +
            EquityManager.money(
              ($scope.otherGrantedCommon || 0) + ($scope.keyHolderEquity || 0),
              $scope.postMoneyStock(),
              $scope.dealParam.totalSize + $scope.dealParam.preMoneyValuation,
            ) +
            $scope.conversions,
        );
      },
      persist = function (dontRedoSlides) {
        $scope.loadingPara = true;
        ProcessButtonModel.disable('continue');
        recomputePostMoneyData(dontRedoSlides);
        SetOwnershipAndControlModel.saveDealParameters(serialize()).then(
          saveSuccess(dontRedoSlides),
          saveFailure,
        );
      },
      saveSuccess = function (dontRedoSlides) {
        return function () {
          ProcessButtonModel.requestEnable('continue');
          $scope.loadingPara = false;
          recomputePostMoneyData(dontRedoSlides);
        };
      },
      saveFailure = function (error) {
        ProcessButtonModel.requestEnable('continue');
        $scope.loadingPara = false;
        if (error === 'canceled') {
          return;
        } // This is okay.
        ProcessStatus.$setStatus(error, 'danger');
      };

    $scope.CHART_ORDER = [
      'keyHolderEquity',
      'currentOpenPool',
      'employeePoolExpansion',
      'otherGrantedCommon',
    ];
    $scope.CHART_COLORS = {
      keyHolderEquity: '#D7C000',
      currentOpenPool: '#6FA4E6',
      employeePoolExpansion: '#E59413',
      otherGrantedCommon: '#C41A1C',
    };
    $scope.CHART_NAMES = {
      keyHolderEquity: 'Key holder equity',
      currentOpenPool: 'Current available pool',
      employeePoolExpansion: 'Employee pool expansion',
      otherGrantedCommon: 'Other Common stock',
    };
    $scope.chartColorFunc = function (color, data) {
      const investorColor = InvestorManager.color(data);
      return investorColor ? investorColor : $scope.CHART_COLORS[color] || color;
    };

    $scope.currentLabel = null;
    $scope.blurLabel = $scope.blurPieSlice = function () {
      $scope.currentLabel = null;
    };
    $scope.hoverLabel = function (name) {
      $scope.currentLabel = name;
    };
    $scope.hoverPieSlice = function (dataPoint) {
      $scope.hoverLabel(dataPoint.id);
    };
    $scope.showLabel = function (name) {
      const checker = (key) =>
        Boolean(
          ($scope[key] || []).find((item) => {
            return (
              angular.isArray(item) &&
              item.length &&
              item[0] === name &&
              angular.isNumber(item[1]) &&
              item[1] !== 0
            );
          }),
        );
      return checker('preMoneyData') || checker('postMoneyData');
    };
    $scope.deleteInvestor = function (id) {
      $scope.investors = InvestorManager.removeInvestor(
        id,
        $scope.investors,
        $scope.dealParam,
      );
      persist();
    };
    $scope.addInvestor = function () {
      $formModal({
        title: 'Add Investor',
        forms: { addInvestorForm: { fields: ADD_INVESTOR_FIELDS() } },
        formData: { addInvestorForm: {} },
        htmlContent: require('./templates/add-investor-modal.html'),
        onConfirmPromise({ $formData }) {
          const data = $formData.addInvestorForm;
          return data.investor_type === 'investor'
            ? data.investor.sh
            : data.placeholder;
        },
      })
        .then((newInvestor) => {
          $scope.investors = InvestorManager.addInvestor(
            newInvestor,
            $scope.investors,
            $scope.dealParam,
          );
          persist();
        })
        .catch(PromiseErrorCatcher);
    };
    $scope.investmentError = function (investment) {
      if (!angular.isNumber(investment) || !$scope.dealParam.totalSize) {
        return false;
      }
      const per = EquityManager.investmentPercentage(
        investment,
        $scope.dealParam.totalSize,
      );
      if (per > 100 || per < 0) {
        return true;
      }
      return false;
    };
    $scope.ownershipError = function (investment) {
      if (
        !angular.isNumber(investment) ||
        !$scope.dealParam.preMoneyValuation ||
        !$scope.dealParam.totalSize
      ) {
        return false;
      }
      const per = EquityManager.ownershipPercentage(
        investment,
        $scope.dealParam.totalSize,
        $scope.dealParam.preMoneyValuation,
      );
      if (per > 100 || per < 0) {
        return true;
      }
      return false;
    };
    $scope.updateInvestors = persist;
    $scope.investorShares = function (investment) {
      if (!angular.isNumber(investment) || investment < 0) {
        return 0;
      }
      return EquityManager.shares(
        investment,
        $scope.preMoneyStock(),
        $scope.dealParam.preMoneyValuation,
      );
    };
    $scope.postMoneyStock = function () {
      return $scope.preMoneyStock() + $scope.investmentPool();
    };
    $scope.preMoneyStock = function () {
      return $scope.totalCommonStock + $scope.expPool();
    };
    $scope.investedShares = function (keyHolder) {
      const khId = keyHolder.shId,
        matchedKH = ($scope.investors || []).find((investor) => {
          return investor.shId === khId;
        });
      return matchedKH
        ? EquityManager.shares(
            matchedKH.investment || 0,
            $scope.preMoneyStock(),
            $scope.dealParam.preMoneyValuation,
          )
        : 0;
    };
    $scope.investmentPool = function () {
      return EquityManager.shares(
        $scope.dealParam.totalSize,
        $scope.preMoneyStock(),
        $scope.dealParam.preMoneyValuation,
      );
    };

    $scope.LIQUID_PREFS = [
      { label: 'Non-particpating Preferred', value: 'non_participate' },
      { label: 'Participating Preferred', value: 'participate' },
      { label: 'Participating with Cap', value: 'cap' },
    ];
    $scope.expPool = function () {
      const preMoney = $scope.preMoneyData || [];
      const expPoolDatum = preMoney.find((datum) => {
        return datum[0] === 'employeePoolExpansion';
      });
      return expPoolDatum ? expPoolDatum[1] : 0;
    };
    $scope.$watchGroup(
      [
        'dealParam.totalSize',
        'dealParam.preMoneyValuation',
        'dealParam.targetPostMoneyOption',
      ],
      () => {
        if ($scope.dealParaForm.totalSize.$valid) {
          $scope.conversions = InvestorManager.computeConversionAmt(serialize());
        }

        if (
          $scope.dealParaForm.totalSize.$valid &&
          $scope.dealParaForm.preMoney.$valid &&
          $scope.dealParaForm.postMoney.$valid
        ) {
          $scope.preMoneyData = EquityManager.computePreMoneyData(
            serialize(),
            $scope.totalCommonStock,
            $scope.keyHolderEquity,
            $scope.currentOpenPool,
            $scope.otherGrantedCommon,
          );
        }

        // Only save if the user has made
        // at least one modification.
        if ($scope.dealParaForm.$dirty) {
          persist();
        }
      },
    );
    $scope.$watchGroup(
      ['dealParam.liquidationMultiplier', 'dealParam.capAmount'],
      (newValue, oldValue) => {
        if (newValue === oldValue || $scope.dealParaForm.$pristine) {
          return;
        }
        persist();
      },
    );

    $scope.$watch('dealParam.liquidationPreference', (nv, ov) => {
      if (nv === ov) {
        return;
      }
      persist();
    });
    $scope.$watch('dealParaForm.$valid', (newValue) => {
      /*
       * This is called once when newValue === oldValue
       * on init; this will even out the semaphore.
       * Otherwise, we assume newValue !== oldValue.
       */
      if (newValue === true) {
        ProcessButtonModel.requestEnable('continue');
      } else {
        ProcessButtonModel.disable('continue');
      }
    });

    $scope.$on('sbThresholdOutputSlider::doneSliding', () => {
      persist(true); // Don't recompute slides
    });

    $scope.loadingPara = true;
    $scope.initLoad = false;
    $scope.investors = [];
    $scope.conversions = 0;
    $scope.slides = {
      ppSlides: [],
      shVotingSlides: [],
    };
    $scope.dealParam = {};
    ProcessButtonModel.disable('continue');
    SetOwnershipAndControlModel.loadDealParameters().then((data) => {
      InvestorManager.setConverters(data.converters);
      delete data.converters;
      angular.extend($scope, data);
      $scope.loadingPara = false;
      $scope.initLoad = true;
      $scope.preMoneyData = EquityManager.computePreMoneyData(
        serialize(),
        $scope.totalCommonStock,
        $scope.keyHolderEquity,
        $scope.currentOpenPool,
        $scope.otherGrantedCommon,
      );
      saveSuccess(false)();
    }, saveFailure);
  },
]; // end SetOwnershipAndControlWiCtrl
