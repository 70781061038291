import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { SbxHttpClient } from '@/core/http';
import { PeerCompanyTicker } from '@shoobx/types/webapi-v2';
import { SbxUrlService } from '@/core/url/url.service';

const SELECTOR = 'sbx-peer-company-admin';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-peer-company-admin.component.html',
  styleUrls: ['./sbx-peer-company-admin.component.scss'],
})
export class SbxPeerCompanyAdminComponent {
  @ViewChild('editCol', { static: true }) editCol: TemplateRef<any>;
  peerDescription;
  peers: Array<PeerCompanyTicker> = [];
  columns = [];

  constructor(
    @Inject(SbxUrlService)
    public sbxUrlService: SbxUrlService,
    @Inject(SbxFormModalService)
    public sbxFormModalService: SbxFormModalService,
    @Inject(SbxConfirmationModalService)
    public sbxConfirmationModalService: SbxConfirmationModalService,
    private http: SbxHttpClient,
  ) {}

  async ngOnInit() {
    this.columns = [
      {
        key: 'title',
        title: 'Name',
      },
      {
        key: 'token',
        title: 'Stock Symbol',
      },
      {
        key: '',
        title: '',
        template: this.editCol,
      },
    ];

    const res = <Array<PeerCompanyTicker>>(
      await this.http.entity('2').get('equitySettings/peer_companies').toPromise()
    );
    this.peers = res;
  }

  removePeer(row) {
    this.sbxConfirmationModalService
      .open({
        data: {
          title: 'Confirm Delection',
          body: `Do you really want to remove ${row.token} as a peer company?`,
        },
      })
      .result.then(async () => {
        await this.http
          .entity('2')
          .delete('equitySettings/peer_companies', {
            params: row,
          })
          .toPromise();
        this.peers = this.peers.filter((peer) => peer.token !== row.token);
      });
  }

  addPeer() {
    this.sbxFormModalService
      .open({
        data: {
          saveUrl: 'equitySettings/peer_companies',
          title: 'Add Peer Company',
          model: {},
          formFields: [
            {
              templateOptions: {
                label: 'Peer Company',
                required: true,
              },
              key: 'peerTicker',
              type: 'stock-ticker',
            },
          ],
        },
      })
      .result.then(({ result }) => {
        const warning = result.warning;
        delete result.warning;
        if (warning) {
          // show confirmation warning
          this.sbxConfirmationModalService
            .open({
              data: {
                title: 'Confirm Peer Company',
                body: warning,
              },
            })
            .result.then(async () => {
              await this.http
                .entity('2')
                .post('equitySettings/peer_companies', {
                  params: {
                    peerTicker: result,
                    confirm: true,
                  },
                })
                .toPromise();
              this.peers.push(result);
            })
            .catch(() => null);
        } else {
          // If no warning, ass peer to results
          this.peers.push(result);
        }
      })
      .catch(() => null);
  }
}
