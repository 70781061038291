import {
  Component,
  Input,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISbxIconType } from '@/core/constants/icons';
import { SbxDropdownComponent } from '../sbx-dropdown/sbx-dropdown.component';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { NGB_BODY_ATTACHED_CONTAINER_CONFIG } from '../sbx-form/fields/ngb-container.config';

export type ISbxActionsMenu = ISbxActionMenuItem[];

interface ISbxActionMenuItem {
  title: string;
  popoverTitle?: string;
  popoverContent?: string | TemplateRef<unknown>;
  popoverTemplateContext?: unknown;
  popoverTriggers?: string;
  icon: ISbxIconType;
  collapsed: boolean;
  disabled?: boolean;
  hidden?: boolean;
  click?(...any): void;
}

export interface ISbxActionMenuAppearance {
  type: 'icon' | 'button';
  title?: string;
  icon?: ISbxIconType;
  aligment?: 'left' | 'right';
}

/**
 *  Shoobx themed dropdown action menu (hamburger menu for an item)
 *
 *  Example:
 *    <sbx-action-menu [actions]=actions></sbx-action-menu>
 */
@Component({
  selector: 'sbx-action-menu',
  templateUrl: './sbx-action-menu.component.html',
  styleUrls: ['sbx-action-menu.component.scss'],
  providers: [
    {
      provide: NGB_CONTAINER_TOKEN,
      useValue: NGB_BODY_ATTACHED_CONTAINER_CONFIG,
    },
  ],
})
export class SbxActionMenuComponent implements OnChanges {
  @ViewChild('dropdownRef') dropdownRef: SbxDropdownComponent;
  @Input() actions: ISbxActionsMenu;
  @Input() appearance: ISbxActionMenuAppearance = {
    type: 'icon',
    aligment: 'left',
  };

  uncollapsedActions = [];
  collapsedActions = [];

  ngOnChanges({
    actions: { previousValue, currentValue, firstChange },
  }: SimpleChanges) {
    if (
      !firstChange ||
      (previousValue && currentValue && previousValue.length === currentValue.length)
    ) {
      return;
    }

    this.uncollapsedActions = this.actions.filter((action) => !action.collapsed);
    this.collapsedActions = this.actions.filter((action) => action.collapsed);
  }

  handleClick(action, event) {
    if (!action.click || action.disabled) {
      return;
    }

    this.dropdownRef?.close();
    action.click(event);
  }

  public hasVisibleActions(actions: ISbxActionsMenu): boolean {
    return actions.some((action) => !action.hidden);
  }
}
