import { Injectable, NgZone } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

@Injectable()
@Downgrade.Injectable('ngShoobx', 'sbx-zone-service')
export class SbxZoneService {
  constructor(private zone: NgZone) {}

  getZone(): NgZone {
    return this.zone;
  }
}
