import angular from 'angular';
import ListPage from '../listpage';

import { sbReportsPage } from './reports';

/**
 * @ngdoc overview
 * @name sb.reports
 *
 * @description
 * This module houses reports
 */
export default angular
  .module('sb.reports', [ListPage])

  .directive('sbReportsPage', sbReportsPage).name;
