import { Component, ViewChild, ViewContainerRef, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { FormGroup } from '@angular/forms';
import { SbxHttpClient } from '@/core/http';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SbxTaxCustomEntryModalDataModel } from './sbx-tax-custom-entry-modal-data.model';
import { TaxAdjustmentOptions } from '@shoobx/types/webapi-v2';
import { GenericUpdateForm } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-tax-custom-entry-modal',
  templateUrl: './sbx-tax-custom-entry-modal.component.html',
  styleUrls: ['sbx-tax-custom-entry-modal.component.scss'],
})
export class SbxTaxCustomEntryModalComponent
  implements OnInit, SbxTaxCustomEntryModalDataModel
{
  @ViewChild('container', { read: ViewContainerRef, static: true }) containerRef;

  @Input() title = '';
  @Input() url = null;

  formName = '';
  form = new FormGroup({});
  model = {};
  formFields: Observable<FormlyFieldConfig[]> | FormlyFieldConfig[] | null = null;
  readOnly = false;

  loading = false;
  errors = {};
  globalError = null;

  constructor(
    public sbxModalService: SbxModalService,
    private sbxHttp: SbxHttpClient,
  ) {}

  ngOnInit() {
    this.sbxHttp
      .entity('2')
      .get(this.url)
      .subscribe(
        (res: GenericUpdateForm) => {
          this.formName = res.name;
          this.title ||= res.title;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.formFields = of(res.description.fields);
          if (res.model) {
            Object.assign(this.model, res.model);
          }
        },
        ({ error }) => {
          this.globalError = error.message;
        },
      );
  }

  handleSave() {
    this.globalError = null;

    if (!this.form.valid) {
      return;
    }

    if (this.form.valid && this.url) {
      this.loading = true;
      this.sbxHttp
        .entity('2')
        .post(this.url, {
          params: this.model,
        })
        .subscribe(
          (res: TaxAdjustmentOptions[]) => {
            this.loading = false;
            try {
              this.validateEntries(res);
              this.sbxModalService.close(res);
            } catch (e) {
              this.handleError(e);
            }
          },
          (errors) => {
            this.loading = false;
            this.handleError(errors.error);
          },
        );
    } else {
      this.sbxModalService.close(this.model);
    }
  }

  handleError(error) {
    let globalError;

    if (typeof error === 'string') {
      globalError = error;
    } else if (!error?.message) {
      globalError = 'Could not update.';
    } else if (typeof error.message === 'string') {
      globalError = error.message;
    } else {
      this.errors = error.message;
      globalError = 'Please correct the form errors below.';
      if (error.message.__form__) {
        globalError = error.message.__form__;
      }
    }
    this.globalError = globalError;
  }

  // Empty function implementation to be overridden
  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-unused-vars
  validateEntries(entries: TaxAdjustmentOptions[]): void {}
}
