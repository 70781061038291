import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxCheckpointComponent } from './sbx-checkpoint.component';
import { SbxFormModule } from '@/shared/sbx-form/sbx-form.module';
import { SbxGridModule } from '@/shared/sbx-grid/sbx-grid.module';
import { UpgradedModule } from '@/shared/upgraded/upgraded.module';
import { SbxStakeholderCardModule } from '@/shared/sbx-stakeholder-card/sbx-stakeholder-card.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SbxPopoverModule,
    SbxIconModule,
    SbxFormModule,
    SbxGridModule,
    UpgradedModule,
    SbxStakeholderCardModule,
  ],
  declarations: [SbxCheckpointComponent],
  exports: [SbxCheckpointComponent],
})
export class SbxCheckpointModule {
  static entryComponents = [SbxCheckpointComponent];

  static forRoot(): ModuleWithProviders<SbxCheckpointModule> {
    return {
      ngModule: SbxCheckpointModule,
    };
  }
}
