/**
 * @ngdoc directive
 * @name sb.dataroom.directive:sbTransformDocumentHtmlBody
 *
 * @description
 * Performs transformations on document.htmlBody content
 * rendered by .pt, .sbt, and .xslt files.
 *
 */
export const sbTransformDocumentHtmlBody = [
  function () {
    return {
      restrict: 'A',
      compile: function (element) {
        const docUrlRE = /\/documents\/([a-z0-9]{24})$/;
        const msg = `
        <a href="./content">
          Download the full PDF to view
        </a>
      `;
        element
          .find('a')
          .filter(
            (i, a) =>
              a.getAttribute('viewable') === 'false' &&
              docUrlRE.test(a.getAttribute('href')),
          )
          .each((i, a) => {
            a.setAttribute('sb-permission-link', 'true');
            a.setAttribute('sb-permission-link-html', msg);
          });
      },
    };
  },
];
