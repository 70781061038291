import { NgModule } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { SbxLogoutRedirectService } from './sbx-logout-redirect.service';

@NgModule({
  imports: [],
  providers: [CookieService, SbxLogoutRedirectService],
})
export class SbxLogoutRedirectModule {}
