import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import {
  SbxImageUploadModalComponent,
  IImageModalData,
} from './sbx-image-upload-modal.component';

@Injectable()
export class SbxImageUploadModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: IImageModalData) {
    const modal = this.sbxModalService.open(SbxImageUploadModalComponent, {
      size: 'md',
      data,
    });

    return modal;
  }
}
