import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

type GoToProfileFeedback = (pId: any) => void;

@Component({
  selector: 'sbx-profile-display',
  templateUrl: './sbx-profile-display.component.html',
  styleUrls: ['./sbx-profile-display.component.scss'],
})
export class SbxProfileDisplayComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  isDefault: boolean;
  pathToTitleMap = {};
  profileId: string;

  gotoProfileCallback: GoToProfileFeedback;

  constructor() {
    super();
  }

  ngOnInit() {
    this.isDefault = this.field.templateOptions.isDefault;
    if (this.field.templateOptions.definedBy) {
      this.profileId = this.field.templateOptions.definedBy.path.slice(-1)[0];
    }
    this.gotoProfileCallback = this.field.templateOptions.gotoProfileCallback;
  }
}
