import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxProcessActionComponent } from './sbx-process-action.component';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxPopoverModule],
  exports: [SbxProcessActionComponent],
  declarations: [SbxProcessActionComponent],
  providers: [],
})
export class SbxProcessActionModule {
  static entryComponents = [SbxProcessActionComponent];

  static forRoot(): ModuleWithProviders<SbxProcessActionModule> {
    return {
      ngModule: SbxProcessActionModule,
    };
  }
}
