export function sbFundManagement() {
  return {
    restrict: 'EA',
    template: require('./templates/relationship-fund.html'),
    scope: {
      model: '=sbTeamModel',
      memberVocab: '<sbMemberVocab',
      availableMembers: '<sbAvailableMembers',
    },
    controller: [
      '$scope',
      '$formModal',
      'PromiseErrorCatcher',
      function ($scope, $formModal, PromiseErrorCatcher) {
        function createForm(scope, readOnlyMember) {
          return {
            form: {
              fields: [
                {
                  key: 'signerId',
                  data: {},
                  type: 'enum-dropdown',
                  templateOptions: {
                    required: true,
                    label: 'Signatory',
                    enumVocab: scope.memberVocab,
                    readOnly: readOnlyMember,
                  },
                },
                {
                  key: 'sigByline',
                  data: {},
                  type: 'text',
                  templateOptions: {
                    required: false,
                    label: 'Signature Byline',
                  },
                },
                {
                  key: 'sigTitle',
                  data: {},
                  type: 'text',
                  templateOptions: {
                    required: false,
                    label: 'Signature Title',
                  },
                },
              ],
            },
          };
        }
        function createMemberFromFormData(formData) {
          let teamMember = this.availableMembers.find(
            (m) => m.stakeholder.sh.id === formData.signerId,
          );
          if (!teamMember) {
            teamMember = this.availableMembers.find(
              (m) => m.stakeholder.sh.email === formData.signerId,
            );
          }
          const fundMember = Object.assign({}, teamMember);
          fundMember.isPermanentManager = true;
          fundMember.sigTitle = formData.sigTitle;
          fundMember.sigByline = formData.sigByline;
          return fundMember;
        }
        function composeFormDataFromMember(member) {
          return {
            form: {
              signerId: member.stakeholder.sh.id || member.stakeholder.sh.email,
              sigTitle: member.sigTitle,
              sigByline: member.sigByline,
            },
          };
        }

        function addSignatory() {
          $formModal({
            title: `Add Signatory to ${$scope.model.fund}`,
            primaryButtonText: 'Add',
            htmlContent: require('./templates/add-fund-modal.html'),
            forms: createForm($scope, false),
            formData: { form: {} },
            onConfirmPromise({ $formData }) {
              const memberData = $scope.createMemberFromFormData($formData.form);
              if (memberData.stakeholder.sh.id) {
                $scope.model.members.push(memberData);
              } else {
                $scope.model.pendingMembers.push(memberData);
              }
            },
          }).catch(PromiseErrorCatcher);
        }
        function removeSignatory(signatoryId) {
          const members = $scope.model.members.filter(
            (x) => x.stakeholder.sh.id !== signatoryId,
          );
          const pendingMembers = $scope.model.pendingMembers.filter(
            (x) => x.stakeholder.sh.email !== signatoryId,
          );
          $scope.model.members = members;
          $scope.model.pendingMembers = pendingMembers;
        }
        function editSignatory(member) {
          $formModal({
            title: `Edit Signature Block in ${$scope.model.fund}`,
            primaryButtonText: 'Update',
            htmlContent: require('./templates/add-fund-modal.html'),
            forms: createForm($scope, true),
            formData: $scope.composeFormDataFromMember(member),
            onConfirmPromise({ $formData }) {
              const memberData = $scope.createMemberFromFormData($formData.form);
              const shId = memberData.stakeholder.sh.id;
              let idx = $scope.model.members.findIndex(
                (x) => x.stakeholder.sh.id === shId,
              );
              if (idx >= 0) {
                $scope.model.members[idx] = memberData;
              }
              if (idx === -1) {
                const email = memberData.stakeholder.sh.email;
                idx = $scope.model.pendingMembers.findIndex(
                  (x) => x.stakeholder.sh.email === email,
                );
                if (idx >= 0) {
                  $scope.model.pendingMembers[idx] = memberData;
                }
              }
            },
          }).catch(PromiseErrorCatcher);
        }
        $scope.createForm = createForm;
        $scope.createMemberFromFormData = createMemberFromFormData;
        $scope.composeFormDataFromMember = composeFormDataFromMember;
        $scope.fundControls = {
          addSignatory: addSignatory,
          removeSignatory: removeSignatory,
          editSignatory: editSignatory,
        };
      },
    ],
  };
}

export function sbFundsManagement() {
  return {
    restrict: 'EA',
    template: require('./templates/relationship-funds.html'),
    scope: {
      model: '=sbTeamModel',
      canEdit: '&sbCanEdit',
      accessSuppressed: '&sbAccessSuppressed',
      foreignManaged: '<sbForeignManaged',
    },
    controller: [
      '$scope',
      '$formModal',
      '$sbModal',
      'PromiseErrorCatcher',
      function ($scope, $formModal, $sbModal, PromiseErrorCatcher) {
        function addFund() {
          $formModal({
            title: 'Add Fund',
            primaryButtonText: 'Add',
            htmlContent: require('./templates/add-fund-modal.html'),
            forms: {
              form: {
                fields: [
                  {
                    key: 'fundName',
                    type: 'string-textline',
                    data: {},
                    templateOptions: {
                      required: true,
                      label: 'Fund Name',
                    },
                  },
                ],
              },
            },
            formData: { form: {} },
            onConfirmPromise({ $formData }) {
              const form = $formData.form;
              const fundName = form.fundName;
              $scope.model.$addFund(fundName);
            },
          }).catch(PromiseErrorCatcher);
        }
        function removeFund(fundId) {
          $scope.model.$removeFund(fundId);
        }
        function editFundTitle(fund) {
          $formModal({
            title: 'Add Fund',
            primaryButtonText: 'Save',
            htmlContent: require('./templates/add-fund-modal.html'),
            forms: {
              form: {
                fields: [
                  {
                    key: 'fundName',
                    type: 'string-textline',
                    data: {},
                    templateOptions: {
                      required: true,
                      label: 'Fund Name',
                    },
                  },
                ],
              },
            },
            formData: { form: { fundName: fund.fund } },
            onConfirmPromise({ $formData }) {
              const form = $formData.form;
              fund.fund = form.fundName;
              $scope.teamModel.$editFund({ fund });
            },
          }).catch(PromiseErrorCatcher);
        }
        function editFundSignatories(fund) {
          const memberVocab = [];
          const availableMembers = [];
          $scope.teamModel.$members.forEach((m) => {
            memberVocab.push({
              label: m.stakeholder.sh.fullName || m.stakeholder.sh.email,
              value: m.stakeholder.sh.id || m.stakeholder.sh.email,
            });
            availableMembers.push(m);
          });
          // $scope.teamModel.$pendingMembers.forEach(m => {
          //   memberVocab.push({
          //     label: m.stakeholder.sh.fullName,
          //     value: m.stakeholder.sh.email,
          //   });
          //   availableMembers.push(m);
          // });
          $sbModal
            .open({
              windowClass: 'add-team-member-modal',
              template: require('./templates/fund-signatories-modal.html'),
              controller: [
                '$scope',
                function ($scope) {
                  $scope.fundData = Object.assign({}, fund);
                  $scope.memberVocab = memberVocab;
                  $scope.availableMembers = availableMembers;
                  $scope.save = () => {
                    const model = $scope.targetModel || $scope.sourceModel;
                    model
                      .$editFund({ fund: $scope.fundData })
                      .then(() => {
                        $scope.$dismiss();
                      })
                      .catch(PromiseErrorCatcher);
                  };
                },
              ],
            })
            .result.catch(PromiseErrorCatcher);
        }
        $scope.teamModel = $scope.model.$isTarget
          ? $scope.model.$sourceTeam
          : $scope.model;
        $scope.fundsControls = {
          addFund: addFund,
          removeFund: removeFund,
          editFundTitle: editFundTitle,
          editFundSignatories: editFundSignatories,
        };
      },
    ],
  };
}
