import moment from 'moment';
import {
  NgbDateParserFormatter,
  NgbDateAdapter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

export const INTERNAL_FORMAT = 'YYYY-MM-DD';
const DISPLAY_FORMAT = 'M/D/YYYY';
const MOMENT_FORMATS = [
  INTERNAL_FORMAT,
  'M/D/YY',
  'MM/D/YY',
  'M/DD/YY',
  'MM/DD/YY',
  'M/D/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'MM/DD/YYYY',
  DISPLAY_FORMAT,
];

const convertMomentToNgbDate = function (mmnt: moment.Moment): NgbDateStruct {
  return <NgbDateStruct>{
    year: mmnt.year(),
    month: mmnt.month() + 1,
    day: mmnt.date(),
  };
};

const convertNgbDateToMoment = function (ngbDate: NgbDateStruct): moment.Moment {
  return moment(
    {
      year: ngbDate.year,
      month: ngbDate.month - 1,
      date: ngbDate.day,
    },
    true,
  );
};

@Injectable({
  providedIn: 'root',
})
export class SbxDateBaseAdapter extends NgbDateAdapter<string> {
  fromModel(datestring: string): NgbDateStruct {
    if (!datestring) {
      return null;
    }
    const mdate = moment(datestring, true);
    return mdate.isValid() ? convertMomentToNgbDate(moment(datestring)) : null;
  }
  toModel(ngbDate: NgbDateStruct): string {
    if (!ngbDate) {
      return '';
    }
    const mdate = convertNgbDateToMoment(ngbDate);
    return mdate.isValid() ? mdate.format(INTERNAL_FORMAT) : '';
  }
}

@Injectable({
  providedIn: 'root',
})
export class SbxDateBaseParserFormatter extends NgbDateParserFormatter {
  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }
    const mdate = convertNgbDateToMoment(date);
    return mdate.isValid() ? mdate.format(DISPLAY_FORMAT) : '';
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const mdate = moment(value, MOMENT_FORMATS, true);
    return mdate.isValid() ? convertMomentToNgbDate(mdate) : null;
  }
}
