<sbx-properties-list
  [items]="items"
  [listTitle]="listTitle"
  [itemStyle]="itemStyle"
  [layout]="layout"
  [isSubList]="isSubList"
>
</sbx-properties-list>

<ng-template #contact let-contacts>
  <div *ngFor="let contact of contacts">
    <span>{{ contact.name }}</span>
    <span class="sbx-contact-email">&nbsp;|&nbsp;{{ contact['email'] }}</span>
  </div>
</ng-template>

<ng-template #alignRight let-item>
  <div class="sbx-value-box">
    <span class="sbx-value-right-align">{{ item }}</span>
  </div>
</ng-template>

<ng-template #alignRightFlagged let-item>
  <div class="sbx-value-box">
    <div class="sbx-value-right-align">
      <span class="sbx-attention"><sbx-icon type="flag"></sbx-icon></span>
      <span>{{ item }}</span>
    </div>
  </div>
</ng-template>
