import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
@Component({
  selector: 'sbx-stakeholder',
  templateUrl: './sbx-stakeholder.component.html',
  styleUrls: ['./sbx-stakeholder.component.scss'],
})
export class SbxStakeholderComponent extends FieldType<FieldTypeConfig> {
  public handleSelect(value): void {
    this.formControl.setValue(value);
  }

  public handleRemove(value): void {
    this.formControl.setValue(null);
  }

  public handleFocus(event): void {
    if (this.to.focus) {
      this.to.focus(this.field, event);
    }
  }

  public handleBlur(): void {
    this.formControl.markAsTouched();
  }
}
