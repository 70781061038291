import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SbxHttpClient } from '@/core/http';

export interface ITailoredProcess {
  id: string;
  enabled: boolean;
  title: string;
  creator: string;
  createdDate: string;
}

@Injectable()
export class TailoredProcessListService {
  data$: Subject<ITailoredProcess>;
  constructor(private http: SbxHttpClient) {
    this.data$ = new Subject<ITailoredProcess>();
  }

  getProcessList() {
    this.http
      .entity('2')
      .get<ITailoredProcess>('tailored')
      .subscribe((val) => this.data$.next(val));
  }

  remove(procId: string, onError) {
    const request = {
      params: {
        procId: procId,
      },
    };
    this.http
      .entity('2')
      .delete('tailored', request)
      .subscribe(
        (val) => val,
        (error) => onError(error.message),
      );
  }

  edit(procId: string, onError) {
    const request = {
      params: {
        procId: procId,
      },
    };
    this.http
      .entity('2')
      .put('tailored', request)
      .subscribe(
        (val) => val,
        (error) => onError(error.message),
      );
  }
}
