<sbx-stakeholder-base
  [model]="model"
  [stakeholderOptions]="field.templateOptions.stakeholderOptions"
  [attr.name]="field.id"
  [modelKey]="$any(field.key)"
  (selectStakeholder)="handleSelect($event)"
  (removeStakeholder)="handleRemove($event)"
  (focus)="handleFocus($event)"
  (blur)="handleBlur()"
></sbx-stakeholder-base>
