import angular from 'angular';
import Promise from 'lib/promise';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists.min.js';

import { SetOwnershipAndControlWiCtrl } from './controllers';
import { sbCowMaxTotalsize } from './directives';
import {
  investmentPercentage,
  ownershipPercentage,
  ownershipPercentageFromShares,
} from './filters';
import {
  SetOwnershipAndControlModel,
  EquityManager,
  InvestorManager,
} from './providers';

/**
 * @ngdoc overview
 * @name sb.workitem.setOwnershipAndControl
 *
 * @description
 * This is the module for the set ownership and control workitem.
 * *All hail the scared COW.*
 */
export default angular
  .module('sb.workitem.setOwnershipAndControl', ['dndLists', Promise])

  .controller('SetOwnershipAndControlWiCtrl', SetOwnershipAndControlWiCtrl)
  .directive('sbCowMaxTotalsize', sbCowMaxTotalsize)
  .factory('SetOwnershipAndControlModel', SetOwnershipAndControlModel)
  .factory('EquityManager', EquityManager)
  .factory('InvestorManager', InvestorManager)
  .filter('investmentPercentage', investmentPercentage)
  .filter('ownershipPercentage', ownershipPercentage)
  .filter('ownershipPercentageFromShares', ownershipPercentageFromShares).name;
