import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import Modal from '../modal/modal';
import Process from '../../process';

import {
  sbMultiStepStep,
  sbMultiStep,
  sbMultiStepButtons,
  sbMultiStepNavigation,
  ShowPageWizardCtrl,
} from './multistep';

/**
 * @ngdoc overview
 * @name sb.lib.multistep
 *
 * @description
 * This is the module for the multistep controller.
 */
export default angular
  .module('sb.lib.multistep', [ngSanitize, Modal, Process])

  .controller('ShowPageWizardCtrl', ShowPageWizardCtrl)
  .component('sbMultiStepButtons', sbMultiStepButtons)
  .component('sbMultiStepNavigation', sbMultiStepNavigation)
  .directive('sbMultiStep', sbMultiStep)
  .directive('sbMultiStepStep', sbMultiStepStep).name;
