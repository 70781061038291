<sbx-alert-banner #statusBanner></sbx-alert-banner>
<div *ngIf="hideothers[0] === 'False'">
  <div class="sbx-approve-document-group" *ngFor="let templategroup of signatures">
    <div class="sbx-approve-document" *ngFor="let doc of templategroup.items">
      <sbx-row>
        <sbx-col [sm]="9">
          <h4><sbx-icon type="documentFilled"></sbx-icon>{{ doc.name }}</h4>
        </sbx-col>
        <sbx-col [sm]="3">
          <div class="sbx-approve-document-actions">
            <sbx-button
              [href]="doc.doc_download_url"
              icon="download"
              theme="default"
              title="Download PDF"
            ></sbx-button>
            <sbx-button
              [href]="doc.doc_details_url"
              icon="details"
              theme="default"
              title="Details"
            ></sbx-button>
          </div>
        </sbx-col>
      </sbx-row>
      <div *ngFor="let item of doc.items; last as lastItem">
        <sbx-row
          *ngFor="let sig of item.blockers; last as lastSig"
          class="{{
            'sbx-signer-row ' +
              (item.has_signature ? 'check' : sig.wi_url ? 'share' : 'remind') +
              (lastItem && lastSig ? '' : ' show_or')
          }}"
        >
          <sbx-col [sm]="1" [lg]="1">
            <sbx-icon type="check" *ngIf="item.has_signature"></sbx-icon>
            <sbx-icon type="sign" *ngIf="!item.has_signature && !sig.wi_url"></sbx-icon>
            <sbx-icon type="share" *ngIf="!item.has_signature && sig.wi_url"></sbx-icon>
          </sbx-col>
          <sbx-col class="sbx-approve-role" [sm]="3" [lg]="3">
            {{ item.role }}
          </sbx-col>
          <sbx-col [sm]="4" [lg]="4">
            <div class="sbx-signer-name" attr.data-name="{{ sig.signatory_fullname }}">
              {{ sig.signatory_fullname }}
            </div>
          </sbx-col>
          <sbx-col [sm]="4" [lg]="4" class="sbx-align-right">
            <sbx-button
              *ngIf="sig.canRemind"
              title="Remind"
              name="remindSH"
              theme="default"
              attr.data-name="{{ sig.signatory_fullname }}"
              icon="envelope"
              (click)="handleRemind(sig.remind_extraData)"
            ></sbx-button>
            <sbx-invite-stakeholder
              *ngIf="!sig.canRemind && !sig.initialized && !item.has_signature"
              buttonType="standalone-button"
              [stakeholderId]="sig.shId"
              [workitemId]="workitem"
              [stakeholderInvited]="sig.invited"
              [statusCallback]="handleInviteWrapper(statusBanner)"
            ></sbx-invite-stakeholder>
            <span class="sbx-approve-time" *ngIf="item.relative_timestamp">
              ({{ item.relative_timestamp }})
            </span>
            <sbx-button
              *ngIf="sig.wi_url && !item.has_signature"
              [href]="sig.wi_url"
              icon="sign"
              theme="default"
              title="Sign"
            ></sbx-button>
          </sbx-col>
        </sbx-row>
      </div>
    </div>
  </div>
</div>
