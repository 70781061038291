import { Component } from '@angular/core';
import { Downgrade } from '../downgrade';

/**
 *  Shoobx Themed Row Container
 */
@Downgrade.Component('ngShoobx', 'sbx-row')
@Component({
  selector: 'sbx-row',
  templateUrl: './sbx-row.component.html',
  styleUrls: ['sbx-row.component.scss'],
})
export class SbxRowComponent {}
