import { Component, Input } from '@angular/core';
import { SbxVideoProportion } from './sbx-video.component';

@Component({
  selector: 'sbx-video-modal',
  templateUrl: './sbx-video-modal.component.html',
  styleUrl: './sbx-video-modal.component.scss',
})
export class SbxVideoModalComponent {
  @Input()
  public videoSrc: string;
  @Input()
  public proportion: SbxVideoProportion = SbxVideoProportion['16:9'];
  @Input()
  public title: string;
}
