import { AbstractControl } from '@angular/forms';

export function addErrorToFormControl(control: AbstractControl, error: string): void {
  if (control.getError(error)) {
    return;
  }

  control.setErrors({
    ...control.errors,
    [error]: true,
  });
}
export function removeErrorFromFormControl(
  control: AbstractControl,
  errorKey: string,
): void {
  if (!control.getError(errorKey)) {
    return;
  }

  const filteredErrors: { [key: string]: string } = Object.keys(control.errors)
    .filter((key) => key !== errorKey)
    .reduce((object: { [key: string]: string }, key: string) => {
      object[key] = control.errors[key];

      return object;
    }, {});
  const hasErrors = Boolean(Object.keys(filteredErrors).length);

  if (!hasErrors) {
    control.setErrors(null);

    return;
  }

  control.setErrors(filteredErrors);
}
