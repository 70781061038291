<ng-template #resultTemplate let-result="result">
  <div *ngIf="result.header" class="search-group-header">
    <sbx-icon [type]="result.headerIcon"></sbx-icon>
    <span>{{ result.header }}</span>
  </div>
  <div *ngIf="result?.error" class="search-group-no-results">
    <i>An error has occured, please try again later.</i>
  </div>
  <div *ngFor="let item of result.results">
    <div
      class="result-item"
      [ngClass]="item.resultClass"
      (click)="onSelect(item, $event)"
    >
      <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: item }">
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="result.results.length === 0 && !result?.error"
    class="search-group-no-results"
  >
    {{ result.noResultsText }}
  </div>
  <div class="search-group-footer" *ngIf="result.footerLink">
    <a class="more-items" href="{{ result.footerLink }}">
      {{ result.footer }}
      <sbx-icon type="chevronRight"></sbx-icon>
    </a>
  </div>
</ng-template>

<div class="search-bar">
  <sbx-icon
    *ngIf="!hideIcon"
    class="search-icon"
    [ngClass]="[icon]"
    [type]="selectComponent.searching ? 'spinner' : icon"
    (click)="onClear()"
  ></sbx-icon>
  <sbx-select
    #selectComponent
    [asyncDataSource]="searchResults"
    [formatter]="resultFormatter"
    [parser]="resultParser"
    [resultTemplate]="resultTemplate"
    [placeholderText]="placeholderText"
    [disableAutoComplete]="disableAutoComplete"
    [clearOnEnter]="false"
    customTypeaheadWindowClass="sbx-search-bar-typeahead-window"
    (selectItem)="selectItem($event)"
    (input)="onInputChange($event)"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    (keydown)="handleKeydown($event)"
  >
  </sbx-select>
</div>
