import { Component, Input, Inject } from '@angular/core';
import { SettingsProfileService } from './service/settings-profile.service';

@Component({
  selector: 'sbx-tree-hover',
  templateUrl: './sbx-tree-hover.component.html',
  styleUrls: ['./sbx-tree-hover.component.scss'],
})
export class SbxTreeHoverComponent {
  @Input() ids: any[];
  @Input() defaultToRoot = false;

  root;
  idNodeMap;

  constructor(@Inject(SettingsProfileService) private service: SettingsProfileService) {
    this.service.getProfileType('employee').subscribe((profileType) => {
      this.root = this.service.constructProfileTree(profileType);
      const nodes = [this.root, ...this.root.getNodes()];
      this.idNodeMap = new Map();
      nodes.forEach((node) => {
        this.idNodeMap.set(node.data.id, node);
      });
    });
  }

  filterIds() {
    let filteredIds = this.ids ? this.ids.filter((id) => this.idNodeMap.get(id)) : [];
    if (!filteredIds.length && this.defaultToRoot) {
      filteredIds = [this.root.data.id];
    }
    return filteredIds;
  }
}
