import { Component, Inject } from '@angular/core';
import { SbxModalService } from './sbx-modal.service';

/**
 *  Shoobx Themed Modal Header
 *
 *  Include in the input template to [sbxModalTemplate]
 *  to supply a standard header layout.
 *
 *  Example:
 *
 *  @Component({
 *    selector: 'get-help',
 *    template: `
 *      <sbx-button type="help"
 *        sbxModal
 *        [sbxModalTemplate]="content"
 *        >
 *        Get Help Now!
 *      </sbx-button>
 *      <ng-template #content>
 *        <sbx-modal-header>
 *          Help Modal
 *        </sbx-modal-header>
 *        <sbx-modal-content>
 *          This is some helpful content!
 *        </sbx-modal-content>
 *      </ng-template>
 *    `,
 *  })
 *  class GetHelpComponent {}
 */
@Component({
  selector: 'sbx-modal-header',
  templateUrl: './sbx-modal-header.component.html',
  styleUrls: ['./sbx-modal-header.component.scss'],
})
export class SbxModalHeaderComponent {
  constructor(public sbxModalService: SbxModalService) {}
}
