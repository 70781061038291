function getDocumentPreviewURL(window, docid) {
  return (
    window.location.href.substring(0, window.location.href.indexOf('process/')) +
    `documents/${docid}/preview_content`
  );
}

function pickFromModal($pickFromDataroomModal) {
  const { config, pickFromModalTitle, excludeFn } = this;
  return $pickFromDataroomModal({
    title: pickFromModalTitle,
    showManaged: config.allowPickManaged,
    rootFolderId: config.rootFolderId,
    managedTagFilter: config.managedTagFilter,
    allowUnfinalized: config.allowUnfinalized,
    allowPending: config.allowPending,
    allowAllFromProc: config.allowAllFromProc,
    excludeFn: excludeFn,
  }).then(({ id, title }) => ({
    type: 'pickFrom',
    documentId: id,
    documentTitle: title,
    pdfURL: getDocumentPreviewURL(window, id),
  }));
}

function uploadDocumentModal($sbModal) {
  const {
    config: { collectTitleInUpload, defaultTitle },
    uploadApi,
  } = this;
  const initTitle = collectTitleInUpload && defaultTitle ? defaultTitle : '';
  return $sbModal.open({
    controllerAs: 'vm',
    bindToController: true,
    windowClass: 'upload-document-modal',
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    template: require('./templates/upload-document-modal.html'),
    controller: [
      function () {
        this.uploadApi = uploadApi;
        this.model = { otherUploadData: { title: initTitle } };
        this.otherUploadFields = collectTitleInUpload
          ? [
              {
                type: 'string-textline',
                key: 'title',
                data: {},
                templateOptions: { label: 'Document Title' },
              },
            ]
          : false;
        this.saveUpload = () => {
          const {
            uploadId,
            file,
            otherUploadData: { title },
          } = this.model;
          if (!uploadId) {
            this.formError = 'Please upload a document.';
            return;
          }
          // Usually user won't get to this point because the button will be disabled.
          if (!file.ready) {
            this.formError = 'Please wait while the document is being uploaded.';
            return;
          }
          this.formError = null;

          this.$close({
            type: 'upload',
            pdfURL: file.pdfURL,
            uploadId,
            documentTitle: title || defaultTitle || file.name,
            fieldId: uploadApi.split('/')[uploadApi.split('/').length - 1],
          });
        };
      },
    ],
  }).result;
}

function deferDocumentModal($formModal, $q) {
  const fields = [
    {
      key: 'title',
      type: 'string-textline',
      data: {},
      templateOptions: { required: true, label: 'Deferred Document Title' },
    },
  ];
  const {
    config: { collectTitleInUpload, defaultTitle },
  } = this;
  if (collectTitleInUpload || !defaultTitle) {
    return $formModal(
      {
        title: 'Defer Document',
        htmlContent: require('./templates/defer-document-modal.html'),
        forms: { deferDocument: { fields } },
        formData: { deferDocument: { title: defaultTitle } },
        onConfirmPromise({ $formData }) {
          return { type: 'defer', documentTitle: $formData.deferDocument.title };
        },
      },
      'lg',
      'defer-document-modal',
    );
  }
  const deferred = $q.defer();
  deferred.resolve({ type: 'defer', documentTitle: defaultTitle });
  return deferred.promise;
}

/**
 * @ngdoc component
 * @name sb.lib.document.component:sbDocumentReference
 *
 * @description
 * This element is a standard document reference field type.
 *
 * @param {expression} ngModel Model expression. Shape looks like:
 *   @property {string} type `waive`, `upload`, `pickFrom` or `defer`.
 *   @property {string} [documentTitle=undefined] Title of document for every type
 *     except waive.
 *   @property {string} [uploadId=undefined] Upload ID for upload type.
 * @param {object} config The options for this reference (which options to allow)
 *   @property {boolean} allowDefer Truthy allows defferals.
 *   @property {boolean} allowPickFrom Truthy allows pick from dataroom.
 *   @property {string} rootFolderId For pick from, ID of root/init folder.
 *   @property {array<string>} [managedTagFilter=undefined] Array of tags to filter
 *     managed documents.
 *   @property {boolean} [allowPickManaged=false] Show managed documents in pick
 *     from modal.
 *   @property {boolean} allowUpload Truthy allows new pdf uploads.
 *   @property {boolean} allowWaive Truthy allows waive.
 * @param {string} [uploadApi=undefined] Required when `allowUpload`. URL location of
 *   upload API.
 * @param {template} [pickFromModalTitle='Pick Document from Data Room'] String template
 *   for the pick from modal title.
 * @param {function} [excludeFn=() => true] given a document object, returns true if it should be included in
 *   search results.
 * @example
   <sb-document-reference
     config="::{ allowUpload: true }"
     upload-api="::'/ent/upload123'"
     ng-model="model.document">
   </sb-document-reference>
*/
export const sbDocumentReference = {
  template: require('./templates/reference.html'),
  controllerAs: 'vm',
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    config: '<',
    required: '<',
    uploadApi: '<',
    pickFromModalTitle: '@?',
    excludeFn: '<?',
  },
  controller: [
    '$q',
    '$formModal',
    '$sbModal',
    '$pickFromDataroomModal',
    '$scope',
    'BackendLocation',
    'ProcessUrlInfo',
    'PromiseErrorCatcher',
    function (
      $q,
      $formModal,
      $sbModal,
      $pickFromDataroomModal,
      $scope,
      BackendLocation,
      ProcessUrlInfo,
      PromiseErrorCatcher,
    ) {
      function setViewValue(value) {
        value = value || {};
        const processId = ProcessUrlInfo.processId();
        const newViewValue = Object.assign(value, {
          documentId: value.documentId || this.originalDocumentId,
          processId,
        });
        this.ngModelCtrl.$setViewValue(newViewValue);
      }
      function fillOutType(evt, type) {
        evt.preventDefault();
        let prom;
        if (type === 'upload') {
          prom = uploadDocumentModal.call(this, $sbModal);
        } else if (type === 'pickFrom') {
          prom = pickFromModal.call(this, $pickFromDataroomModal);
        } else if (type === 'defer') {
          prom = deferDocumentModal.call(this, $formModal, $q);
        } else if (type === 'waive') {
          prom = $q.resolve({ type });
        }
        prom.then(setViewValue.bind(this)).catch(PromiseErrorCatcher);
      }
      $scope.previewModal = function (pdfURL) {
        $sbModal
          .open({
            template: require('../pdf/templates/pdf-review-modal.html'),
            controller: [
              '$scope',
              function ($scope) {
                $scope.pdfURL = pdfURL;
              },
            ],
          })
          .result.catch(PromiseErrorCatcher);
      };
      this.$onInit = () => {
        this.fillOutType = fillOutType.bind(this);
        this.setViewValue = setViewValue.bind(this);
        if (this.model && this.model.documentId) {
          if (this.model.type === 'upload' && this.uploadApi) {
            // We have an upload that was converted to a document
            const backend = BackendLocation.context(1);
            this.model.pdfURL =
              backend + this.uploadApi + '/preview?docid=' + this.model.documentId;
          } else if (this.model.type === 'pickFrom') {
            this.model.pdfURL = getDocumentPreviewURL(window, this.model.documentId);
          }
        }
        this.setViewValue(this.model);
        this.originalDocumentId = (this.model || {}).documentId;
      };
      this.$postLink = () => {
        if (this.required) {
          this.ngModelCtrl.$validators.required = (modelValue) => {
            // if we allow waive just return true, otherwise wait for modelValue
            // to be set.
            return this.config.allowWaive || modelValue.type !== undefined;
          };
        }
      };
    },
  ],
}; // end sbDocumentReference
