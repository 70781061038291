/**
 * @ngdoc object
 * @name sb.listpage.sbFilteredListColumnSort
 *
 **/
export const sbFilteredListColumnSort = [
  function () {
    return {
      restrict: 'E',
      require: ['ngModel', '^sbFilteredList'],
      template: require('./templates/columnSort.html'),
      scope: {
        title: '@title',
        model: '=ngModel',
        asc: '@asc',
        desc: '@desc',
      },
      link(scope, element, attrs, [, sbFilteredListCtrl]) {
        scope.toggleSort = () => {
          switch (scope.model.sort.sort) {
            case scope.asc:
              scope.model.sort.sort = sbFilteredListCtrl.activeSearch
                ? 'relevance'
                : 'newest';
              break;

            case scope.desc:
              scope.model.sort.sort = scope.asc;
              break;

            default:
              scope.model.sort.sort = scope.desc;
          }

          scope.model.setSort();
        };

        scope.$watch(
          () => sbFilteredListCtrl.activeSearch,
          (activeSearch) => {
            if (activeSearch && scope.model.sort.sort === 'newest') {
              scope.model.sort.sort = 'relevance';
              scope.model.setSort();
            } else if (!activeSearch && scope.model.sort.sort === 'relevance') {
              scope.model.sort.sort = 'newest';
              scope.model.setSort();
            }
          },
        );
      },
    };
  },
];
