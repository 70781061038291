import angular from 'angular';
import Raven from 'raven-js';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists.min.js';
import 'angular-chosen-localytics/dist/angular-chosen.min.js';
import 'ngsticky/dist/sticky.min.js';
import APICheck from 'api-check';

import sbAjaxRedirectHandler from 'lib/url/ajax-handler';
import Lib from './lib';
import Process from './process';
import ListPage from './listpage';
import HR from './hr';
import Billing from './billing';
import Workitem from './workitem';
import Dataroom from './dataroom';
import Reports from './reports';
import StakeholderProfile from './stakeholder-profile';
import Payroll from './payroll';
import CreateDocxForm from './create-docx-form';

const appConfig = [
  '$provide',
  '$httpProvider',
  '$animateProvider',
  '$compileProvider',
  '$locationProvider',
  function (
    $provide,
    $httpProvider,
    $animateProvider,
    $compileProvider,
    $locationProvider,
  ) {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'production') {
      // Faster Compile in production
      $compileProvider.debugInfoEnabled(false);
    }

    // Default hashPrefix of ! is conflicting with
    // the angular router when using downgraded components
    $locationProvider.hashPrefix('');

    // AngularJS causes random page reloading after angular-router navigation
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
      rewriteLinks: false,
    });

    // Animation Override Class Name
    $animateProvider.classNameFilter(/^((?!(angular-no-animate)).)*$/);

    // $http Configuration
    $httpProvider.useApplyAsync(true);
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $httpProvider.interceptors.push([
      '$q',
      '$window',
      'AppConfig',
      function ($q, $window, AppConfig) {
        return {
          responseError: function (rejection) {
            const { status, data } = rejection;
            const context = {
              entity: AppConfig.currentEntity?.name,
            };

            const redirecting = sbAjaxRedirectHandler($window, status, data, context);
            // If the handler is doing a redirect, just return a promise that does not
            // ever finish since we are doing a browser reload somewhere else anyway.
            return redirecting ? $q(angular.noop) : $q.reject(rejection);
          },
        };
      },
    ]);

    $provide.decorator('$exceptionHandler', [
      '$delegate',
      function ($delegate) {
        return function (exception, cause) {
          // eslint-disable-next-line no-undef
          if (process.env.NODE_ENV !== 'development') {
            Raven.captureException(exception, { extra: { cause } });
          }

          $delegate(exception, cause);
        };
      },
    ]);
  },
]; // end appConfig

const appRun = [
  'APICheck',
  '$window',
  function (APICheck, $window) {
    if ($window.shoobxTesting) {
      // expose raven to shoobxTesting for debugging tests and QA
      $window.shoobxTesting.Raven = Raven;
    }
    // Disable APICheck
    APICheck.globalConfig.disabled = true;
  },
];

/**
 * @ngdoc overview
 * @name ngShoobx
 *
 * @description
 * This is the main module for all of Shoobx!
 */
export default angular
  .module('ngShoobx', [
    ngAnimate,
    ngSanitize,
    Lib,
    Process,
    Workitem,
    ListPage,
    HR,
    Billing,
    Dataroom,
    StakeholderProfile,
    Reports,
    Payroll,
    CreateDocxForm,

    'sticky',
    'dndLists',
    'localytics.directives',
  ])

  .config(appConfig)
  .run(appRun)
  .value('APICheck', APICheck)
  .value('StandaloneButtonDisableTime', 30000).name; // Half minute disable timer
