<sbx-search-bar
  [searchResults]="search"
  [rowTemplate]="resultTemplate"
  [placeholderText]="placeholderText"
  [hideIcon]="!showSearchIcon"
  [disableAutoComplete]="true"
  (select)="$event.id ? selectStakeholder.emit($event) : null"
  (focus)="handleFocus($event)"
  (blur)="handleBlur($event)"
></sbx-search-bar>

<ng-template #resultTemplate let-item>
  <span class="sbx-stakeholder-custom-result">
    <sbx-icon *ngIf="item.icon" [type]="item.icon"></sbx-icon>
    <span *ngIf="item.displayName">{{ item.displayName }}</span>
  </span>
  <sbx-stakeholder-card
    *ngIf="!item.displayName"
    [attr.id]="item.id"
    [stakeholderId]="item.id"
    [stakeholderObject]="item"
    [format]="options?.format || 'medium'"
  ></sbx-stakeholder-card>
</ng-template>
