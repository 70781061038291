/**
 * @ngdoc directive
 * @name sb.lib.messages.directive:sbFourOhFour
 * @restrict EA
 *
 * @description
 * A simple 404 page template.
 */
export function sbFourOhFour() {
  return {
    restrict: 'EA',
    template: require('./templates/four-oh-four.html'),
    controller: [
      '$scope',
      '$window',
      function ($scope, $window) {
        $scope.back = function (evt) {
          evt.preventDefault();
          $window.history.back(1);
        };
      },
    ],
  };
} // end sbFourOhFour
