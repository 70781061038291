import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxSearchCompanyComponent } from './sbx-search-company.component';
import { SbxSearchBarModule } from '@/shared/sbx-search-bar/sbx-search-bar.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxNavigationModule } from '@/shared/sbx-navigation/sbx-navigation.module';
import { RouterLink } from '@angular/router';
@NgModule({
  imports: [
    SbxSearchBarModule,
    SbxIconModule,
    SbxNavigationModule,
    CommonModule,
    RouterLink,
  ],
  declarations: [SbxSearchCompanyComponent],
  exports: [SbxSearchCompanyComponent],
})
export class SbxSearchCompanyModule {}
