/* eslint-disable dot-notation */
/* eslint-disable no-implicit-coercion */
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { SbxModalService } from '../../sbx-modal/sbx-modal.service';
import { SbxSimilarEntitiesModalComponent } from './sbx-similar-entities-modal.component';
import { SbxExistingStakeholderModalComponent } from './sbx-existing-stakeholder-modal.component';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '../../upgraded/process-url-info.service';
import { SbxHttpClient } from '@/core/http';
import { of } from 'rxjs';
import { FormGroup } from '@angular/forms';

export interface ICreationParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

@Component({
  selector: 'sbx-new-stakeholder-modal',
  templateUrl: './sbx-new-stakeholder-modal.component.html',
  styleUrls: ['./sbx-new-stakeholder-modal.component.scss'],
})
export class SbxNewStakeholderModalComponent implements OnInit {
  @ViewChild('template') template;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  personForm: any = new FormGroup({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teamForm: any = new FormGroup({});

  loading;
  shType;

  @Input() shObject;
  @Input() securityTicketId;
  @Input() allowEntity: boolean;
  @Input() allowPerson: boolean;
  @Input() creatorIsContact = false;
  @Input() forceEphemeral = false;
  @Input() createGuest = false;
  @Input() relationshipType: string;
  @Input() handleAffiliates: boolean;
  @Input() requireEmail = true;
  @Input() hasSimilarStakeholder;
  @Input() affiliatePlaceholder: string;
  @Input() titleText: string;
  @Input() individualLabel: string;
  @Input() teamLabel: string;
  @Input() entityNameLabel: string;
  @Input() entityNamePlaceholder: string;
  @Input() affiliateLabel: string;

  setSimilarStakeholderRequest;

  DEF_TEAM_FORM_TITLE = 'This user is an entity (e.g. firm, family trust, fund).';
  DEF_PERSON_FORM_TITLE = 'This user is a person.';
  DEF_ENTTIY_LABEL = 'Entity Name';
  DEF_FUND_LABEL = 'Fund Name';
  DEF_FUND_PLACEHOLDER = 'e.g. Hazel Entrepreneurs Fund III, L.P.';

  constructor(
    public sbxModalService: SbxModalService,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
  ) {}

  ngOnInit() {
    if (!this.allowEntity) {
      this.setShType('person');
    }
    if (!this.allowPerson) {
      this.setShType('team');
    }
    // Copy the passed in object
    this.shObject = JSON.parse(JSON.stringify(this.shObject));
    this._adaptStakeholderObjectForForm();

    if (!this.shType && this.shObject.type) {
      this.setShType(this.shObject.type);
    }
  }

  setShType(type) {
    this.shType = type;
    if (type === 'person') {
      this.setSimilarStakeholder();
    }
  }

  _adaptStakeholderObjectForForm() {
    const sh = this.shObject.sh;
    // Add existing email to both email + confirm email
    // We group the emails to allow for easier sameness validation
    // So also temporarily change model to reflect this
    if (sh.email) {
      sh.email = {
        email: sh.email,
        email2: sh.email,
      };
    }

    if (sh.contactEmail) {
      sh.contactEmail = {
        email: sh.contactEmail,
        email2: sh.contactEmail,
      };
    }

    // If SH is affiliate, map parentTitle / fullName
    if (sh.parentTitle) {
      sh.entityTitle = sh.parentTitle;
      sh.affiliateTitle = sh.fullName;
      sh.addAffiliate = true;
    } else {
      sh.entityTitle = sh.fullName;
    }
  }

  hasErrors() {
    if (!this.shType) {
      return true;
    }
    if (this.shType === 'team') {
      return !Object.keys(this.teamForm.value).length || this.teamForm.invalid;
    }

    return !Object.keys(this.personForm.value).length || this.personForm.invalid;
  }

  async setSimilarStakeholder() {
    if (this.shType === 'person') {
      if (!this.setSimilarStakeholderRequest) {
        this.setSimilarStakeholderRequest = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const similarShs = <any[]>await this.sbxHttpClient
          .entity('1')
          .get('stakeholders/similar', {
            params: {
              type: 'person',
              firstName: this.shObject.sh.firstName,
              lastName: this.shObject.sh.lastName,
              securityTicketID: this.securityTicketId,
            },
          })
          .toPromise();

        this.setSimilarStakeholderRequest = false;
        this.hasSimilarStakeholder = similarShs.filter(
          (shId) => shId !== this.shObject.sh.id,
        ).length;
      }
      return this.hasSimilarStakeholder;
    }
  }

  _adaptStakeholderObjectForSave() {
    const sh = this.shObject.sh;

    if (sh.id === 'addNew') {
      delete sh['id'];
    }

    const isTeam = this.shType === 'team';

    // Reconstruct proper email/contac
    const email = this.shObject.sh['email'];
    delete this.shObject.sh['email'];
    const contactEmail = this.shObject.sh['contactEmail'];
    delete this.shObject.sh['contactEmail'];
    const emailToUse = isTeam ? contactEmail : email;

    if (isTeam) {
      this.shObject.sh['firstName'] = this.shObject.sh['contactFirstName'];
      this.shObject.sh['lastName'] = this.shObject.sh['contactLastName'];
    }

    return {
      description: {
        ...this.shObject.sh,
        ...emailToUse,
      },
      type: this.shType,
      processId: this.processUrlInfo.processId(),
      relationshipType: this.relationshipType,
      asId: this.shObject.sh['id'],
      creatorIsContact: this.creatorIsContact,
      forceEphemeral: this.forceEphemeral,
      createGuest: this.createGuest,
    };
  }

  save() {
    if (this.hasErrors()) {
      return;
    }

    if (this.shType === 'team') {
      this._handleEntitySave();
    } else {
      this._save();
    }
  }

  async _save(additionalData = {}) {
    this.loading = true;
    const data = this._adaptStakeholderObjectForSave();

    const newSh = await this.sbxHttpClient
      .entity('1')
      .post('stakeholders', {
        params: {
          ...data,
          ...additionalData,
          securityTicketID: this.securityTicketId,
        },
      })
      .toPromise();
    const newShObject = {
      sh: newSh,
      type: newSh['teamStakeholder'] ? 'team' : 'person',
    };
    const isFullNameSame =
      newShObject.sh['firstName'] === data.description['firstName'] &&
      newShObject.sh['lastName'] === data.description['lastName'];

    if (
      newShObject.type === 'person' &&
      isFullNameSame &&
      data.description['id'] &&
      data.description['id'] !== newShObject.sh['id']
    ) {
      this.handleExistingShModal(newShObject);
      return;
    }
    if (
      newShObject.type === 'person' &&
      !isFullNameSame &&
      data.description['id'] !== newShObject.sh['id']
    ) {
      this.handleExistingShModal(newShObject);
      return;
    }
    this.loading = false;
    this.sbxModalService.close(newShObject);
  }

  handleExistingShModal(shObject) {
    const existingStakeholderModal = this.sbxModalService.open(
      SbxExistingStakeholderModalComponent,
      {
        backdropClass: 'custom-modal-backdrop',
        windowClass: 'custom-modal',
        data: {
          shTitle: shObject.sh['fullName'],
        },
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    existingStakeholderModal.result.then((res) => {
      this.loading = false;
      this.sbxModalService.close(shObject);
    });
  }

  onPersonModelChange(change) {
    const { changeKey } = change;
    if (changeKey === 'lastName' || changeKey === 'firstName') {
      this.setSimilarStakeholder();
    }
  }

  async _handleEntitySave() {
    const data = {
      includeAffiliates: this.handleAffiliates,
      entityTitle: this.shObject.sh.entityTitle,
      affiliateTitle: this.shObject.sh.affiliateTitle,
      relationType: this.relationshipType,
      type: 'entity',
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const similarEntities = <any[]>await this.sbxHttpClient
      .entity('1')
      .get('stakeholders/similar', {
        params: {
          ...data,
          securityTicketID: this.securityTicketId,
        },
      })
      .toPromise();

    if (similarEntities.length) {
      const modal = this.sbxModalService.open(SbxSimilarEntitiesModalComponent, {
        backdropClass: 'custom-modal-backdrop',
        windowClass: 'custom-modal',
        data: {
          similarEntities,
          handleAffiliates: this.handleAffiliates,
          entityTitle: this.shObject.sh.entityTitle,
          affiliateTitle: this.shObject.sh.affiliateTitle,
        },
      });
      modal.result.then(
        (res) => {
          const { similarityWhitelist, selectedEntity, selectedAffiliate } = res.result;
          const additionalSaveArgs = {
            similarityWhitelist,
          };
          const hasAffiliate =
            selectedAffiliate &&
            (selectedAffiliate.id || selectedAffiliate.shId || selectedAffiliate.title);
          if (hasAffiliate) {
            this.shObject.sh.entityTitle = selectedEntity.title;
            this.shObject.sh.affiliateTitle = selectedAffiliate.title;
            this.shObject.sh.addAffiliate = true;
            additionalSaveArgs['entityId'] = selectedAffiliate.id;
            additionalSaveArgs['id'] = selectedAffiliate.shId;
            additionalSaveArgs['parentId'] = selectedEntity.id;
            additionalSaveArgs['parentShId'] = selectedEntity.shId;
            if (!selectedAffiliate.shId && !selectedAffiliate.id) {
              // User selected to create a new affiliate
              this.shObject.sh.id = null;
            }
          } else {
            this.shObject.sh.entityTitle = selectedEntity.title;
            this.shObject.sh.affiliateTitle = '';
            this.shObject.sh.addAffiliate = false;
            additionalSaveArgs['entityId'] = selectedEntity.id;
            additionalSaveArgs['id'] = selectedEntity.shId;
          }

          this._save(additionalSaveArgs);
        },
        // eslint-disable-next-line
        () => {},
      );
    } else {
      this._save();
    }
  }

  showAddAffiliatesQuestion() {
    return this.handleAffiliates;
  }

  getEntityFormFields() {
    return of([
      {
        key: 'addAffiliate',
        type: 'bool-radios-vertical',
        defaultValue: false,
        templateOptions: {
          className: 'sbx-unbounded-label',
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hideExpression: (model) => !this.handleAffiliates,
      },
      {
        key: 'entityTitle',
        type: 'string-textline',
        templateOptions: {
          label: this.entityNameLabel || this.DEF_ENTTIY_LABEL,
          required: true,
          readOnly: this.shObject.sh.id !== 'addNew' && !this.shObject.sh.ephemeral,
          placeholder: this.entityNamePlaceholder,
        },
        defaultValue: null,
      },
      {
        key: 'affiliateTitle',
        type: 'string-textline',
        templateOptions: {
          label: this.affiliateLabel || this.DEF_FUND_LABEL,
          required: true,
          subfield: 1,
          placeholder: this.affiliatePlaceholder || this.DEF_FUND_PLACEHOLDER,
        },
        defaultValue: null,
        hideExpression: (model) => !this.handleAffiliates || !model.addAffiliate,
      },
      {
        key: 'contactFirstName',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'Contact First Name',
        },
        defaultValue: null,
      },
      {
        key: 'contactLastName',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'Contact Last Name',
        },
        defaultValue: null,
      },
      {
        key: 'contactEmail',
        validators: {
          fieldMatch: {
            expression: (control) => {
              const value = control.value;
              return (
                value.email2 === value.email ||
                // avoid displaying the message error when values are empty
                !value.email2 ||
                !value.email
              );
            },
            message: '"The email and confirmation email fields must match.',
            errorPath: 'email2',
          },
        },
        fieldGroup: [
          {
            key: 'email',
            type: 'email-textline',
            templateOptions: {
              label: 'Contact Email',
              required: this.requireEmail,
            },
            defaultValue: null,
          },
          {
            key: 'email2',
            type: 'email-textline',
            templateOptions: {
              label: 'Confirm Contact Email',
              required: this.requireEmail,
              className: 'sbx-individual-group',
            },
            defaultValue: null,
          },
        ],
      },
    ]);
  }

  getPersonFormFields() {
    return of([
      {
        key: 'firstName',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'First Name',
        },
        defaultValue: null,
      },
      {
        key: 'lastName',
        type: 'string-textline',
        templateOptions: {
          required: true,
          label: 'Last Name',
        },
        defaultValue: null,
      },
      {
        key: 'email',
        validators: {
          fieldMatch: {
            expression: (control) => {
              const value = control.value;
              return (
                value.email2 === value.email ||
                // avoid displaying the message error when values are empty
                !value.email2 ||
                !value.email
              );
            },
            message: '"The email and confirmation email fields must match.',
            errorPath: 'email2',
          },
        },
        fieldGroup: [
          {
            key: 'email',
            type: 'email-textline',
            templateOptions: {
              label: 'Email',
              required: this.requireEmail,
            },
            defaultValue: null,
          },
          {
            key: 'email2',
            type: 'email-textline',
            templateOptions: {
              label: 'Confirm Email',
              required: this.requireEmail,
              className: 'sbx-individual-group',
            },
            defaultValue: null,
          },
        ],
      },
    ]);
  }
}
