import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-pdf-upload-form',
  templateUrl: './sbx-pdf-upload-form.component.html',
  styleUrls: ['./sbx-pdf-upload-form.component.scss'],
})
export class SbxPdfUploadFormComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  ngOnInit() {
    if (typeof this.field.templateOptions.apiResource !== 'function') {
      const apiStr = this.field.templateOptions.apiResource;
      this.field.templateOptions = {
        ...this.field.templateOptions,
        apiResource: () => apiStr,
      };
    }
  }

  updateUploadId(uploadId) {
    const model = this.field.model[String(this.field.key)];
    if (uploadId && model !== uploadId.uploadId && uploadId.uploadId ) {
      this.formControl.setValue(uploadId.uploadId);
    }
  }
}
