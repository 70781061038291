import { SbxUrlService } from '@/core/url/url.service';
import { Component, Input, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IMenuItem } from './models/menu-item.model';

/**
 *  Shoobx Themed Menu Item
 *
 *  Singular item for the sbx-menu-list.
 *  Shouldn't use this directly - use sbx-menu-list instead
 */

@Component({
  selector: 'sbx-menu-item',
  templateUrl: './sbx-menu-item.component.html',
  styleUrls: ['sbx-menu-item.component.scss'],
})
export class SbxMenuItemComponent {
  @Input() menuItem: IMenuItem;
  @Input() menuItems: IMenuItem[];
  @Input() overflowName: string;

  menuItemDropdownPadding: SafeStyle;

  constructor(
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(DomSanitizer) sanitizer: DomSanitizer,
  ) {
    this.menuItemDropdownPadding = sanitizer.sanitize(SecurityContext.STYLE, 10);
  }
}
