import { NgModule } from '@angular/core';
import { SbxRadioComponent } from './sbx-radio.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { CommonModule } from '@angular/common';
import { SbxDisabledModule } from '../sbx-disabled/sbx-disabled.module';

@NgModule({
  imports: [CommonModule, SbxDisabledModule, SbxIconModule],
  declarations: [SbxRadioComponent],
  exports: [SbxRadioComponent],
})
export class SbxRadioModule {}
