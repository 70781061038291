import {
  Component,
  Input,
  TemplateRef,
  OnInit,
  Inject,
  ViewChild,
  HostBinding,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgbDropdown, NgbDropdownConfig, Placement } from '@ng-bootstrap/ng-bootstrap';
import { NgbContainerConfig } from '../sbx-form/fields/ngb-container.config';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { Options } from '@popperjs/core';

@Component({
  selector: 'sbx-dropdown, [sbxDropdown]',
  styleUrls: ['sbx-dropdown.component.scss'],
  templateUrl: './sbx-dropdown.component.html',
  providers: [
    {
      provide: NgbDropdownConfig,
      useFactory: (ngbContainer: NgbContainerConfig) => {
        const config = new NgbDropdownConfig();

        if (!ngbContainer) {
          return config;
        }

        config.container = ngbContainer.container;
        config.popperOptions = ngbContainer.popperOptions;

        return config;
      },
      deps: [NGB_CONTAINER_TOKEN],
    },
  ],
})
/**
 * A standard Shoobx dropdown menu.
 * Wrapper component for the NgBootstrap dropdown widget.
 * See https://ng-bootstrap.github.io/#/components/dropdown/examples
 * @example Component
 *  <sbx-dropdown [dropDownTemplate]="menuTemplate">
 *    <div>Click here to trigger the dropdown</div>
 *  </sbx-dropdown>
 *  <ng-template #menuTemplate>
 *    <div>This appears in the dropdown</div>
 *  </ng-template>
 *
 *  If placement prop is to be set please use an array of possible values
 *  so the drop-down will reposition based on the browser window.
 *  See https://ng-bootstrap.github.io/#/guides/positioning for examples
 */
export class SbxDropdownComponent implements OnInit {
  // Content of the dropdown menu that appears after clicking the main element
  @ViewChild('dropdownRef') dropdownRef: NgbDropdown;
  @Input() dropDownTemplate: TemplateRef<unknown>;
  @Input() dropDownWidth = 'auto';
  @Input() placement: Placement[] = [
    'bottom-right',
    'top-right',
    'bottom-left',
    'top-left',
  ];
  @Input() disabled = false;
  @HostBinding('class.sbx-open') get class() {
    return this.dropdownRef?.isOpen() ?? false;
  }
  sanitizedWidth: SafeStyle;

  public readonly container: undefined | 'body';
  public readonly popperOptions: (options: Partial<Options>) => Partial<Options>;

  constructor(
    @Inject(DomSanitizer) private sanitizer: DomSanitizer,
    dropdownConfig: NgbDropdownConfig,
  ) {
    this.container = dropdownConfig.container;
    this.popperOptions = dropdownConfig.popperOptions;
  }

  ngOnInit() {
    this.sanitizedWidth = this.sanitizer.bypassSecurityTrustStyle(this.dropDownWidth);
  }

  open() {
    this.dropdownRef?.open();
  }

  close() {
    this.dropdownRef?.close();
  }

  handleClick() {
    if (this.disabled) {
      return;
    }

    this.dropdownRef?.toggle();
  }
}
