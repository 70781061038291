import { SbxUrlService } from '@/core/url/url.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DocumentReference } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-document-info',
  templateUrl: './sbx-document-info.component.html',
  styleUrls: ['./sbx-document-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxDocumentInfoComponent implements OnChanges {
  @Input({ required: true }) public document: DocumentReference;
  @Output() public readonly removeReference = new EventEmitter<void>();

  public uploadMissingDocumentApiResource?: string;
  public uploadMissingDocumentSaveUrl?: string;
  public downloadHref?: string;

  public constructor(private readonly sbxUrlService: SbxUrlService) {}

  public ngOnChanges(): void {
    const documentId = this.document.documentCard.id;

    this.uploadMissingDocumentApiResource = `../${documentId}/upload-deferred`;
    this.uploadMissingDocumentSaveUrl = `documents/${documentId}/update.html`;
    this.downloadHref = this.sbxUrlService.canonicalUrl([
      'documents',
      `${documentId}//content`,
    ]);
  }
}
