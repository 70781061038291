import { Injectable } from '@angular/core';
import {
  Connection,
  IConnectionOptions,
  ISubscription,
  ITransportDefinition,
  Session,
} from 'autobahn';
import autobahn from 'autobahn-browser';
import { Observable, filter, BehaviorSubject, map, switchMap, takeWhile } from 'rxjs';
import { ISbxWsAdapter, IWSMessage, IWSTopics } from './sbx-ws.interface';
import { AppConfig } from '../config/app.config';
import { WebSocketInfo } from '@shoobx/types/webapi-v2';

/**
 *  AutobahnConnection
 *
 *  dependency: 'autobahn-browser'
 *  types dependency: '@types/autobahn'
 */
const AutobahnConnection = (options: IConnectionOptions): Connection => {
  return new autobahn.Connection(options);
};

export interface IAutobahnMessageModel {
  readonly topic: string;
  readonly message: IWSMessage;
}

@Injectable({
  providedIn: 'root',
})
export class SbxAutobahnAdapterService implements ISbxWsAdapter {
  private readonly topics: Map<
    string,
    { session: Session; subscription: ISubscription }
  > = new Map();
  private readonly topicsSubject = new BehaviorSubject<IWSTopics>({});
  private readonly sessionSubject = new BehaviorSubject<Session | null>(undefined);

  public constructor(private readonly appConfig: AppConfig) {
    this.setUpConnection();
  }

  public listenTopic$<TopicData = void>(topic: string): Observable<TopicData> {
    return this.sessionSubject.pipe(
      filter((session: Session | null) => Boolean(session)),
      switchMap((session: Session) => {
        if (!this.topics.has(topic)) {
          this.registerTopic(topic, session);
        }

        return this.topicsSubject.pipe(
          takeWhile((topics: IWSTopics) => topics[topic] !== undefined),
          filter((topics: IWSTopics) => topics[topic] !== null),
          map((topics: IWSTopics) => <TopicData>topics[topic].args[0]),
        );
      }),
    );
  }

  public unregisterTopic(topic: string): void {
    const topicData = this.topics.get(topic);
    if (!topicData) {
      return;
    }
    const { session, subscription } = topicData;
    const { [topic]: removedTopic, ...filteredTopics } = this.topicsSubject.value;

    if (session && session.isOpen) {
      // eslint-disable-next-line
      // https://github.com/crossbario/autobahn-js/blob/c831f35c1911eb31ec8ffdfe0f62fa15e9dcccb6/packages/autobahn/lib/session.js#L1191
      session.unsubscribe(subscription);
    }
    this.topics.delete(topic);
    this.topicsSubject.next(filteredTopics);
  }

  private setUpConnection(): void {
    const webSocketInfo = this.appConfig.webSocket;

    if (!this.canConnect(webSocketInfo)) {
      return;
    }

    const connection: Connection = AutobahnConnection({
      transports: <ITransportDefinition[]>webSocketInfo.WS_TRANSPORTS,
      realm: webSocketInfo.WS_REALM,
      // the following attributes must be set for Ticket-based authentication
      authmethods: ['ticket'],
      // authid will be auto-assigned upon authentication
      // authid: ('frontend-' +
      //          Math.random().toString(36).substring(2, 15) +
      //          Math.random().toString(36).substring(2, 15)),
      onchallenge: (session: Session, method: string) => {
        if (method === 'ticket') {
          return webSocketInfo.WS_TICKET;
        }
      },
    });

    connection.onopen = (session: Session) => {
      this.sessionSubject.next(session);
    };

    connection.onclose = () => {
      this.sessionSubject.next(null);

      return false;
    };

    connection.open();
  }

  private registerTopic(topic: string, session: Session): void {
    this.topicsSubject.next({
      ...this.topicsSubject.value,
      [topic]: null,
    });

    session
      .subscribe(topic, (args, kwargs, metadata) => {
        this.topicsSubject.next({
          ...this.topicsSubject.value,
          [topic]: {
            args,
            kwargs,
            metadata,
          },
        });
      })
      .then((subscription: ISubscription) => {
        this.topics.set(topic, { session, subscription });
      });
  }

  private canConnect(webSocketInfo?: WebSocketInfo): boolean {
    if (!webSocketInfo) {
      return false;
    }

    const { WS_TRANSPORTS, WS_REALM, WS_TICKET } = webSocketInfo;

    return Boolean(WS_TRANSPORTS && WS_REALM && WS_TICKET);
  }
}
