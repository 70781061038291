import { AppcuesService } from '@/core/appcues/appcues.service';
import { AppConfig } from '@/core/config/app.config';
import { SbxPageComponent } from '@/shared/sbx-page/sbx-page.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { pairwise, startWith } from 'rxjs';
import { Downgrade } from '../shared/downgrade';

const SELECTOR = 'sbx-app';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-app.component.html',
  styleUrls: ['sbx-app.component.scss'],
})
export class SbxAppComponent implements OnInit {
  @ViewChild('pageRef') pageRef: SbxPageComponent;

  hideBackground = false;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(AppcuesService) private appcuesService: AppcuesService,
  ) {}

  ngOnInit() {
    this.router.initialNavigation();
    this.trackBackgroundChanges();
    this.trackPageChanges();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.pageRef.setLoadingFlag('global');
      }

      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.pageRef.clearLoadingFlag('global');
      }
    });
  }

  trackBackgroundChanges() {
    let tempBackground = this.hideBackground;
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        tempBackground = Boolean(event.snapshot.data.hideBackground);
        return;
      }

      if (event instanceof NavigationEnd) {
        this.hideBackground = tempBackground;
      }
    });
  }

  trackPageChanges() {
    this.appConfig.userInfo$
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]) => {
        if (prev === next) {
          return;
        }

        if (!next?.currentStake) {
          this.appcuesService.reset();
        }

        if (
          (prev?.currentStake !== next?.currentStake ||
            prev?.currentLens !== next?.currentLens) &&
          next?.currentLens
        ) {
          this.appcuesService.identify().catch((error) => {
            throw error.error;
          });
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.appcuesService.page();
      }
    });
  }
}
