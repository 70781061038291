import angular from 'angular';
import moment from 'moment';
import { List, Map } from 'immutable';

/**
 * @ngdoc object
 * @name sb.lib.administration:AccessKeysModel
 *
 * @description
 * Use this to instansiate an access key state model. This is compatible
 * with a `sbTable`.
 */
export const AccessKeysModel = [
  '$q',
  'BackendLocation',
  'SimpleHTTPWrapper',
  'TableCellData',
  'TableItemData',
  '$accessKeySecretModal',
  function (
    $q,
    BackendLocation,
    SimpleHTTPWrapper,
    TableCellData,
    TableItemData,
    $accessKeySecretModal,
  ) {
    const DEFAULT_FMT_VALUE = '<em>None</em>';
    class Model {
      constructor() {
        this.$$endpoint = BackendLocation.entity('1') + 'accessKeys';
        this.$hasEditOrDelete = true;
        this.$loading = false;
        this.$items = List();
      }

      $$formatDateString(string) {
        return moment(string, 'YYYY-MM-DD').format('MMMM Do, YYYY');
      }

      $$makeStatusHTML(status) {
        switch (status) {
          case 'active':
            return '<i class="fa fa-check green"></i> Active';
          case 'inactive':
            return '<i class="fa fa-ban red"></i> Inactive';
          case 'locked':
            return '<i class="fa fa-lock red"></i> Locked';
        }
      }

      $$makeItem(itemData) {
        const { id, title, note, expirationDate } = itemData;
        let expirationDateData;
        if (expirationDate) {
          expirationDateData = TableCellData(
            this.$$formatDateString(expirationDate),
            expirationDate,
          );
        } else {
          expirationDateData = TableCellData(DEFAULT_FMT_VALUE);
        }
        const data = Map({
          accessKey: Map({
            title,
            note,
            expirationDate,
            allowedSubnets: itemData.allowedSubnets,
          }),
          status: itemData.status,
          createdDate: this.$$formatDateString(itemData.createdDate),

          $creator: TableCellData(itemData.creatorFullName),
          $secret: itemData.secret,
          $id: TableCellData(id),
          $status: TableCellData(this.$$makeStatusHTML(itemData.status)),
          $title: TableCellData(title || DEFAULT_FMT_VALUE),
          $failedAttempts: TableCellData(itemData.failedAttempts),
          $expirationDate: expirationDateData,
        });
        return TableItemData(id, data, true, true, 'Edit Key: ' + id);
      }

      $$load(prom) {
        this.$loading = true;
        return prom.finally(() => {
          this.$loading = false;
        });
      }

      $$additionOp(method, data, errorString) {
        const prom = SimpleHTTPWrapper({ method, url: this.$$endpoint, data })
          .then((newKey) => {
            newKey = this.$$makeItem(newKey);
            const replaceId = data.id;
            if (angular.isDefined(replaceId)) {
              const index = this.$findIndex(replaceId);
              this.$items = this.$items.set(index, newKey);
            } else {
              this.$items = this.$items.push(newKey);
            }
            return newKey;
          })
          .catch((errors) =>
            $q.reject(angular.isObject(errors) ? errors : errorString),
          );
        return this.$$load(prom);
      }

      /**
       * @ngdoc method
       * @name $findIndex
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @param {string} findId ID looking for.
       *
       * @returns {number} Index of item with `findId`. `-1` on failure.
       */
      $findIndex(findId) {
        return this.$items.findIndex(({ id }) => id === findId);
      }

      /**
       * @ngdoc method
       * @name $init
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @returns {promise} Resolves after the model has been initialized.
       */
      $init() {
        const prom = SimpleHTTPWrapper({ url: this.$$endpoint }).then(({ keys }) => {
          this.$items = List(keys.map((item) => this.$$makeItem(item)));
        });
        return this.$$load(prom);
      }

      /**
       * @ngdoc method
       * @name $add
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @param {object} keyData This is the data from an `sbTable`. It is expected
       *   to have an `accesKey` property coresponding to the form of that name.
       *
       * @returns {promise} Resolves after the key is created and after
       *   the user closes the secret modal. It rejects on failure.
       */
      $add(keyData) {
        return this.$$additionOp(
          'POST',
          keyData.accessKey,
          'Failed to generate key.',
        ).then((newKey) => $accessKeySecretModal(newKey));
      }

      /**
       * @ngdoc method
       * @name $edit
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @param {string} keyId
       * @param {object} keyData This is the data from an `sbTable`. It is expected
       *   to have an `accesKey` property coresponding to the form of that name.
       *
       * @returns {promise} Resolves after the key is modified. It rejects on failure.
       */
      $edit(keyId, keyData) {
        const editData = angular.extend({ id: keyId }, keyData.accessKey);
        return this.$$additionOp('PUT', editData, 'Failed to edit key.');
      }

      /**
       * @ngdoc method
       * @name $changeStatus
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @param {string} keyId
       * @param {string} newStatus Expected to be `'active'` or `'inactive'`.
       *
       * @returns {promise} Resolves after the key is modified. It rejects on failure.
       */
      $changeStatus(keyId, newStatus) {
        const index = this.$findIndex(keyId);
        const item = this.$items.get(index);
        const key = item.get('data').get('accessKey').toJSON();
        const editData = angular.extend({ id: keyId, status: newStatus }, key);
        return this.$$additionOp('PUT', editData, 'Failed to change key status.');
      }

      /**
       * @ngdoc method
       * @name $remove
       * @methodOf sb.lib.administration.AccessKeysModel
       *
       * @param {string} keyId
       *
       * @returns {promise} Resolves after the key is removed. It rejects on failure.
       */
      $remove(keyId) {
        const data = { id: keyId };
        const prom = SimpleHTTPWrapper({
          method: 'DELETE',
          url: this.$$endpoint,
          data,
        }).then(() => {
          const index = this.$findIndex(keyId);
          this.$items = this.$items.remove(index);
        });
        return this.$$load(prom);
      }
    }
    return () => new Model();
  },
]; // end AccessKeysModel

/**
 * @ngdoc component
 * @name sb.lib.administration.component:sbAccessKeys
 *
 * @description
 * This displays a access key management table.
 *
 * @param {object} form The form defintion for add/delete of a key.
 */
const TABLE_COLUMNS = Object.freeze([
  Object.freeze({ key: '$id', name: 'Key ID' }),
  Object.freeze({ key: '$title', name: 'Title' }),
  Object.freeze({ key: '$status', name: 'Status' }),
  Object.freeze({ key: '$creator', name: 'Creator' }),
  Object.freeze({ key: '$expirationDate', name: 'Expiration Date' }),
  Object.freeze({ key: '$failedAttempts', name: 'Failed Attempts' }),
]);
const MODAL_HTML = `
  <p ng-if="::model.$formData.createdDate">
    This key was created on {{ ::model.$formData.createdDate }}.
  </p>
  <sb-form
    sb-form-name="accesKeyForm"
    sb-form-errors="model.$formErrors"
    sb-form-fields="model.$forms.accessKey.fields"
    sb-form-model="model.$formData.accessKey"></sb-form>
`;
const ACTIVE_ACTIONS = (model, id) =>
  Object.freeze([
    Object.freeze({
      id: '0',
      text: 'Deactivate',
      type: 'primary',
      method: () => model.$changeStatus(id, 'inactive'),
    }),
  ]);
const INACTIVE_ACTIONS = (model, id) =>
  Object.freeze([
    Object.freeze({
      id: '1',
      text: 'Activate',
      type: 'primary',
      method: () => model.$changeStatus(id, 'active'),
    }),
  ]);
export const sbAccessKeys = {
  template: require('./templates/access-keys.html'),
  controllerAs: 'vm',
  bindings: {
    form: '<',
  },
  controller: [
    'AccessKeysModel',
    function (AccessKeysModel) {
      this.$onInit = () => {
        this.model = AccessKeysModel();
        this.columns = TABLE_COLUMNS;
        this.modalHtml = MODAL_HTML;

        this.additionalActions = (id) => {
          if (!id) {
            return angular.noop;
          }
          const { model } = this;
          const actives = ACTIVE_ACTIONS(model, id);
          const inactives = INACTIVE_ACTIONS(model, id);
          const itemIndex = model.$findIndex(id);
          return () => {
            const keyStatus = model.$items.get(itemIndex).get('data').get('status');
            if (keyStatus === 'active') {
              return actives;
            } else if (keyStatus === 'inactive' || keyStatus === 'locked') {
              return inactives;
            }
          };
        };

        this.model.$init();
      };
    },
  ],
}; // end sbAccessKeys

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.administration.object:$accessKeySecretModal
 *
 * @description
 * This is a high level function for creating a secret showing modal.
 *
 * @param {object} key New key object (requires `.get('data').get('$secret')`).
 *
 * @returns {Promise} Resolves when the modal is closed.
 */
export const $accessKeySecretModal = [
  '$sbModal',
  function ($sbModal) {
    return (key) =>
      $sbModal.open({
        template: require('./templates/secret-modal.html'),
        bindToController: true,
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        controller: function () {
          this.key = key;
        },
      }).result;
  },
]; // end $accessKeySecretModal
