import { NgModule } from '@angular/core';
import { TabsToSelectOptionsPipe } from './tabs-to-select-options.pipe';
import { TabsToSideNavListLinksPipe } from './tabs-to-nav-list-links.pipe';
import { CommonModule } from '@angular/common';
import { SbxSideNavModule } from '../sbx-side-nav/sbx-side-nav.module';
import { SbxSelectModule } from '../sbx-select/sbx-select.module';
import { SbxResponsiveNavComponent } from './sbx-responsive-nav.component';

@NgModule({
  declarations: [
    SbxResponsiveNavComponent,
    TabsToSelectOptionsPipe,
    TabsToSideNavListLinksPipe,
  ],
  imports: [CommonModule, SbxSideNavModule, SbxSelectModule],
  exports: [SbxResponsiveNavComponent],
})
export class SbxResponsiveNavModule {}
