import { Injectable, Inject } from '@angular/core';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { COLORS } from '@/shared/sbx-chart/sbx-chart.component';
import {
  FundProfile,
  FundProfileData,
  FundSearchResults,
  FundSearchResultsItem,
  FundPartnerAccessSetting,
  FundEmailPreview,
  PickupFile,
  GenericStatusResponse,
  GenericDeleteResponse,
  GenericTable,
} from '@shoobx/types/pieapi-v1';
import { Observable, map } from 'rxjs';

export interface IFundSearchResults extends FundSearchResults {
  results: IFund[];
}

export interface IFund extends FundSearchResultsItem {
  included: boolean;
  color: string;
  profileUrl: string;
}

export enum ProviderType {
  Profile = 'profile',
  Metrics = 'metrics',
  Documents = 'documents',
  Partners = 'partners',
  PartnerClasses = 'classes',
  CapitalCalls = 'calls',
  CapitalDisbursements = 'disbursements',
  Valuations = 'valuations',
  CreditTransactions = 'transactions',
  CashBalances = 'balances',
  BookValues = 'bookvalues',
  Notes = 'notes',
}

export const generateChartColors = (funds: IFund[]): { [key: string]: string } => {
  const colors = Object.values(funds).reduce((acc, fund) => {
    acc[fund.displayName] = fund.color;
    return acc;
  }, {});

  return colors;
};

@Injectable()
export class SbxFundService {
  API_VERSION = 'pie_1';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
  ) {}

  searchFunds({ start, limit }): Observable<IFundSearchResults> {
    const params = { start, limit };
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<FundSearchResults>('funds/search', { params })
      .pipe(
        map((data: IFundSearchResults) => {
          let colorsCounter = 0;
          data.results = <IFund[]>data.results.map((fund) => ({
            ...fund,
            color: COLORS[colorsCounter++ % COLORS.length],
            included: !fund.archived,
          }));

          return data;
        }),
      );
  }

  getFundProfile(fundId: string, providers?: ProviderType[]) {
    const searchParams = new URLSearchParams();
    if (providers) {
      searchParams.append('providers', providers.join(','));
    }

    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<FundProfileData>(`funds/${fundId}/profile-data?${searchParams}`);
  }

  getFund(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<FundProfile>(`funds/${fundId}/profile`);
  }

  getFundEditFormUrl(fundId: string) {
    return `funds/${fundId}/edit`;
  }

  setArchived(fundId: string, archived: boolean) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`funds/${fundId}/archive`, {
        params: {
          archived,
        },
      });
  }

  getPartnerClassCreateFormUrl(fundId: string) {
    return `funds/${fundId}/classes/create`;
  }

  getPartnerClassEditFormUrl(fundId: string, classId: string) {
    return `funds/${fundId}/classes/${classId}/edit`;
  }

  removePartnerClass(fundId: string, classId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/classes/${classId}`)
      .toPromise();
  }

  getPartnerCreateFormUrl(fundId: string) {
    return `funds/${fundId}/partners/create`;
  }

  updatePartnerAccess(fundId: string, settings: FundPartnerAccessSetting[]) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`funds/${fundId}/partners/access`, {
        params: { settings },
      })
      .toPromise();
  }

  getPartnerEditFormUrl(fundId: string, partnerId: string) {
    return `funds/${fundId}/partners/${partnerId}/edit`;
  }

  removePartner(fundId: string, partnerId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/partners/${partnerId}`)
      .toPromise();
  }

  getCallsByPartnerClass(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/calls/byClass`)
      .toPromise();
  }

  getMyCalls(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/calls/mine`)
      .toPromise();
  }

  getCallCreateFormUrl(fundId: string) {
    return `funds/${fundId}/calls/create`;
  }

  getCallEditFormUrl(fundId: string, callId: string) {
    return `funds/${fundId}/calls/${callId}/edit`;
  }

  removeCall(fundId: string, callId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/calls/${callId}`)
      .toPromise();
  }

  getDisbursementCreateFormUrl(fundId: string) {
    return `funds/${fundId}/disbursements/create`;
  }

  getDisbursementEditFormUrl(fundId: string, disbursementId: string) {
    return `funds/${fundId}/disbursements/${disbursementId}/edit`;
  }

  removeDisbursement(fundId: string, disbursementId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/disbursements/${disbursementId}`)
      .toPromise();
  }

  getDisbursementsByPartnerClass(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/disbursements/byClass`)
      .toPromise();
  }

  getMyDisbursements(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/disbursements/mine`)
      .toPromise();
  }

  getValuationCreateFormUrl(fundId: string) {
    return `funds/${fundId}/valuations/create`;
  }

  getValuationEditFormUrl(fundId: string, valuationId: string) {
    return `funds/${fundId}/valuations/${valuationId}/edit`;
  }

  removeValuation(fundId: string, valuationId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/valuations/${valuationId}`)
      .toPromise();
  }

  getValuationsByPartnerClass(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/valuations/byClass`)
      .toPromise();
  }

  getMyValuations(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/valuations/mine`)
      .toPromise();
  }

  getBookValueCreateFormUrl(fundId: string) {
    return `funds/${fundId}/bookvalues/create`;
  }

  getBookValueEditFormUrl(fundId: string, callId: string) {
    return `funds/${fundId}/bookvalues/${callId}/edit`;
  }

  removeBookValue(fundId: string, callId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/bookvalues/${callId}`)
      .toPromise();
  }

  getBookValuesByPartnerClass(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/bookvalues/byClass`)
      .toPromise();
  }

  getMyBookValues(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/bookvalues/mine`)
      .toPromise();
  }

  getCreditTransactionCreateFormUrl(fundId: string) {
    return `funds/${fundId}/creditTransactions/create`;
  }

  getCreditTransactionEditFormUrl(fundId: string, txnId: string) {
    return `funds/${fundId}/creditTransactions/${txnId}/edit`;
  }

  removeCreditTransaction(fundId: string, txnId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/creditTransactions/${txnId}`)
      .toPromise();
  }

  getCashBalanceCreateFormUrl(fundId: string) {
    return `funds/${fundId}/cashBalances/create`;
  }

  getCashBalanceEditFormUrl(fundId: string, cashId: string) {
    return `funds/${fundId}/cashBalances/${cashId}/edit`;
  }

  removeCashBalance(fundId: string, cashId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/cashBalances/${cashId}`)
      .toPromise();
  }

  setWithGeneratedBookValue(fundId: string, state: boolean) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`funds/${fundId}/bookvalues/with-generated`, {
        params: {
          state,
        },
      })
      .toPromise();
  }

  setWithGeneratedValuation(fundId: string, state: boolean) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`funds/${fundId}/valuations/with-generated`, {
        params: {
          state,
        },
      })
      .toPromise();
  }

  getMetricsByPartnerClass(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/metrics/byClass`)
      .toPromise();
  }

  getMyMetrics(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericTable>(`funds/${fundId}/metrics/mine`)
      .toPromise();
  }

  getNoteCreateFormUrl(fundId: string): string {
    return `funds/${fundId}/notes/create`;
  }

  getNoteEditFormUrl(fundId: string, noteId: string) {
    return `funds/${fundId}/notes/${noteId}/edit`;
  }

  removeNote(fundId: string, noteId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`funds/${fundId}/notes/${noteId}`)
      .toPromise();
  }

  getDocumentsFormUrl(fundId: string) {
    return `funds/${fundId}/documents/upload`;
  }

  getProfilePdf(fundId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PickupFile>(`funds/${fundId}/pdf`);
  }

  getFundsExportUrl() {
    return 'funds/export';
  }

  getInvitePartnerUrl(fundId: string, partnerId: string, email: string) {
    return `funds/${fundId}/partners/${partnerId}/invite?email=${encodeURIComponent(
      email,
    )}`;
  }

  postInvitePartnerPreview(fundId: string, partnerId: string, email: string, form) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<FundEmailPreview>(
        `funds/${fundId}/partners/${partnerId}/previewInvitation?email=${encodeURIComponent(
          email,
        )}`,
        {
          params: form,
        },
      )
      .toPromise();
  }
}
