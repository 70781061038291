<sbx-modal-header>{{ data.modalHeader }}</sbx-modal-header>
<sbx-modal-content>
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</sbx-modal-content>
<sbx-modal-footer>
  <ng-container *ngTemplateOutlet="footerTemplate"> </ng-container>
</sbx-modal-footer>

<ng-template #contentTemplate>
  <ng-container
    *ngIf="[snapStatuses.notAllowed, snapStatuses.allowed].includes(snapStatus)"
  >
    <sbx-webcam
      #webcamTemplate
      (onCapture)="onCapture($event)"
      (click)="webcamLinkClicked()"
      *ngIf="!data.disableWebcam"
    ></sbx-webcam>

    <div class="sbx-image-upload-computer">
      <div class="sbx-image-upload-link">
        <span (click)="imageUpload()">Upload from Computer</span>
      </div>

      <div *ngIf="imageUploadShow">
        <div class="sbx-image-input">
          <input
            id="sbx-file-input"
            type="file"
            (change)="fileChangeEvent($event)"
            accept="image/*"
          />
          <label for="sbx-file-input">
            <div class="sbx-image-input-icon">
              <sbx-icon type="upload"></sbx-icon>
            </div>
          </label>
        </div>
        <div class="sbx-image-upload-info">
          <sbx-icon type="infoCircle"></sbx-icon>
          You will be able to crop your photo once it is uploaded.
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="snapStatus === snapStatuses.done">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [imageBase64]="imageBase64"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      format="png"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </ng-container>
</ng-template>

<ng-template #footerTemplate>
  <ng-container [ngSwitch]="snapStatus">
    <ng-container *ngSwitchCase="snapStatuses.notAllowed">
      <sbx-button
        class="sbx-button-snap"
        theme="primary"
        title="Snap Photo"
        [disabled]="true"
      ></sbx-button>
    </ng-container>
    <ng-container *ngSwitchCase="snapStatuses.allowed">
      <sbx-button
        class="sbx-button-snap"
        theme="primary"
        title="Snap Photo"
        (click)="snapPhoto()"
      ></sbx-button>
    </ng-container>
    <ng-container *ngSwitchCase="snapStatuses.done">
      <sbx-button
        class="sbx-button-confirm"
        theme="primary"
        title="Confirm"
        (click)="modalConfirm()"
      ></sbx-button>
      <sbx-button
        class="sbx-button-back"
        theme="secondary"
        title="Back"
        (click)="modalBack()"
      ></sbx-button>
    </ng-container>
  </ng-container>
</ng-template>
