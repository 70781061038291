import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import {
  ISbxDocumentReferenceModel,
  SbxDocumentReferenceModelType,
} from './sbx-document-reference.component';

@Component({
  selector: 'sbx-document-defer',
  templateUrl: './sbx-document-defer.component.html',
  styleUrls: ['./sbx-document-defer.component.scss'],
})
export class SbxDocumentDeferComponent implements OnInit {
  @Input() defaultTitle: string;
  @Input() shouldCollectTitle = false;
  @Output() modelChange = new EventEmitter();

  @Input()
  get model() {
    return this.deferModel;
  }
  set model(val) {
    this.deferModel = val;
    this.modelChange.emit(this.deferModel);
  }

  deferModel: SbxDocumentDeferModel;

  constructor(
    @Inject(SbxFormModalService) private sbxFormModalService: SbxFormModalService,
  ) {}

  ngOnInit() {
    if (this.defaultTitle) {
      this.model.documentTitle = this.defaultTitle;
    }
  }

  // we don't need to open a defer modal to collect title if we already know the title.
  deferDocument() {
    this.model.type = SbxDocumentReferenceModelType.Defer;
  }

  async open() {
    const model = this.model;

    try {
      const modalDataAfterClose = await this.sbxFormModalService.open({
        data: {
          title: 'Defer Document',
          topMessageText:
            'You may defer this document upload and continue. ' +
            'However you will need to visit the Data Room and upload this ' +
            'document at a later date.',
          model,
          formFields: [
            {
              key: 'documentTitle',
              type: 'string-textline',
              templateOptions: <FormlyTemplateOptions>{
                label: 'Deferred Document Title',
                required: true,
              },
            },
          ],
        },
      }).result;
      this.model.documentTitle = modalDataAfterClose.result.documentTitle;
      this.model.type = SbxDocumentReferenceModelType.Defer;
      this.modelChange.emit(this.model);
    } catch {}
  }
}

class SbxDocumentDeferModel implements ISbxDocumentReferenceModel {
  type = SbxDocumentReferenceModelType.Defer;
  documentTitle: string;
}
