import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'sbx-generate-customization-report',
})
export class SbxGenerateCustomizationReportComponent extends UpgradeComponent {
  @Input() processId: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('sbGenerateCustomizationReport', elementRef, injector);
  }
}
