/**
 * @ngdoc directive
 * @name sb.lib.events.directive:sbClickOutside
 * @restrict A
 *
 * @description
 * A directive that catches clicks outside the element.
 *
 * @param {string} sbClickOutside And expression that will be run when
 *     a click outside the element will be caught.
 *
 * @example
   <div class="dropdown" ng-class="{open: popupOpen}"
     sb-click-outside="popupOpen = false">
     <button ng-click="popupOpen = !popupOpen;">Show</button>
   </div>
 */
export const sbClickOutside = [
  '$parse',
  '$document',
  function ($parse, $document) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const expr = $parse(attrs.sbClickOutside);
        let currentClick;

        function outsideHandler(evt) {
          if (currentClick && currentClick === evt.originalEvent) {
            // They hit us, propagate and stay open.
            return true;
          }
          // They missed, run the expression
          scope.$apply(() => expr(scope, { $event: evt }));
          return true;
        }

        function clickSaveHandler(evt) {
          currentClick = evt.originalEvent;
          return true;
        }

        const body = $document.find('body');
        body.click(outsideHandler);
        element.click(clickSaveHandler);

        scope.$on('$destroy', () => {
          body.off('click', outsideHandler);
          element.off('click', clickSaveHandler);
        });
      },
    };
  },
]; // end sbClickOutside
