import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxWiPanelGroupComponent } from './sbx-wi-panel-group.component';
import { SbxWiPanelComponent } from './sbx-wi-panel.component';
import { SbxWorkflowEmailsComponent } from './sbx-workflow-emails/sbx-workflow-emails.component';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxButtonGroupModule } from '../sbx-button-group/sbx-button-group.module';
import { UpgradedModule } from '../upgraded/upgraded.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxStakeholderCardModule } from '../sbx-stakeholder-card/sbx-stakeholder-card.module';

@NgModule({
  imports: [
    CommonModule,
    SbxButtonModule,
    SbxButtonGroupModule,
    UpgradedModule,
    SbxPopoverModule,
    SbxIconModule,
    SbxStakeholderCardModule,
  ],
  declarations: [
    SbxWiPanelGroupComponent,
    SbxWiPanelComponent,
    SbxWorkflowEmailsComponent,
  ],
  exports: [SbxWiPanelGroupComponent, SbxWiPanelComponent, SbxWorkflowEmailsComponent],
})
export class SbxWiPanelGroupModule {
  static entryComponents = [SbxWorkflowEmailsComponent];

  static forRoot(): ModuleWithProviders<SbxWiPanelGroupModule> {
    return {
      ngModule: SbxWiPanelGroupModule,
    };
  }
}
