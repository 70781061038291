import { Downgrade } from '../downgrade';
import { Component } from '@angular/core';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { NGB_BODY_ATTACHED_CONTAINER_CONFIG } from '../sbx-form/fields/ngb-container.config';

const SELECTOR = 'sbx-modal-wrapper';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  template: '<ng-content />',
  providers: [
    {
      provide: NGB_CONTAINER_TOKEN,
      useValue: NGB_BODY_ATTACHED_CONTAINER_CONFIG,
    },
  ],
})
export class SbxModalWrapperComponent {}
