<sbx-modal-header>
  <span *ngIf="titleText">{{ titleText }}</span>
  <span *ngIf="!titleText">{{
    shObject.sh.id === 'addNew'
      ? 'Add User to Your Fidelity Private Shares Account'
      : 'Edit User'
  }}</span>
</sbx-modal-header>
<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="hasSimilarStakeholder"
    customText="We found users with similar names to
      &quot;{{ shObject.sh.firstName }}
      {{ shObject.sh.lastName }}&quot;.
      Please make sure this user does not already have an account before creating
      a new one."
    [warning]="true"
  ></sbx-alert-banner>
  <div class="sbx-main-form">
    <sbx-radio
      *ngIf="allowPerson && allowEntity"
      value="person"
      [model]="shType"
      (modelChange)="setShType('person')"
      [labelTemplate]="personTpl"
    ></sbx-radio>
    <ng-template #personTpl>
      <span>{{ individualLabel || DEF_PERSON_FORM_TITLE }}</span>
      <sbx-icon class="sbx-radio-label-icon" type="user"></sbx-icon>
    </ng-template>
    <div class="sbx-sub-form" *ngIf="shType == 'person'">
      <sbx-form
        class="sbx-person-form"
        [form]="personForm"
        [formFields]="getPersonFormFields()"
        [model]="shObject.sh"
        (modelChange)="onPersonModelChange($event)"
      >
      </sbx-form>
    </div>
    <sbx-radio
      *ngIf="allowPerson && allowEntity"
      value="team"
      [model]="shType"
      (modelChange)="setShType('team')"
      [labelTemplate]="entityTpl"
    ></sbx-radio>
    <ng-template #entityTpl>
      <span>{{ teamLabel || DEF_TEAM_FORM_TITLE }}</span>
      <sbx-icon class="sbx-radio-label-icon" type="buildingDark"></sbx-icon>
    </ng-template>
    <div *ngIf="shType == 'team'">
      <div *ngIf="showAddAffiliatesQuestion()" class="sbx-add-affiliate-question">
        <span class="subfield-1"
          >Will the investment be made through a separate investment vehicle or fund
          associated with the entity below?</span
        >
      </div>
      <div class="sbx-sub-form">
        <sbx-form
          class="sbx-team-form"
          [form]="teamForm"
          [formFields]="getEntityFormFields()"
          [model]="shObject.sh"
        >
        </sbx-form>
      </div>
    </div>
  </div>
</sbx-modal-content>
<sbx-modal-footer>
  <sbx-button
    [disabled]="hasErrors()"
    [title]="shObject.sh.id === 'addNew' ? 'Create' : 'Edit'"
    theme="primary"
    (click)="save()"
  ></sbx-button>
  <sbx-button
    title="Discard"
    theme="secondary"
    (click)="sbxModalService.dismiss()"
  ></sbx-button>
</sbx-modal-footer>
