export const LoadMoreModel = [
  'SimpleHTTPWrapper',
  function (SimpleHTTPWrapper) {
    const object = {
      url: null,
      pagingStart: null,
      pagingSize: null,
      items: [],
      hasMore: false,
      isLoading: false,
      loadingError: null,
      initialise: function (url, start, size, onBehalf) {
        this.url = url;

        this.pagingStart = start || 0;
        this.pagingSize = size || 20;
        this.onBehalf = onBehalf;
      },

      loadMore: function () {
        this.isLoading = true;
        this.loadingError = null;
        const request = {
          method: 'GET',
          url: this.url,
          params: { start: this.pagingStart, size: this.pagingSize },
        };
        if (this.onBehalf) {
          request.headers = {
            'X-SB-Onbehalf': this.onBehalf,
          };
        }
        return SimpleHTTPWrapper(request)
          .then((response) => {
            this.pagingStart += response.items.length;
            this.items = this.items.concat(response.items);
            this.hasMore = response.hasMore;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.loadingError =
              'Sorry, an error occurred and has been reported to Fidelity Private Shares support.';
          });
      },

      refresh: function () {
        this.pagingStart = 0;
        this.items = [];

        return this.loadMore();
      },
    };

    return object;
  },
];
