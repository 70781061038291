import angular from 'angular';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.stakeholder.$stakeholderInviteModal
 *
 * @description
 * For sending invite/remind emails to stakholders.
 *
 * @param {object} stakeholder Standard stakeholder object of person to be invited/reminded.
 * @param {string} [workitemId=undefined] The id of the workitem this invitation is assocated with.
 *
 * @returns {Promise} Returns a promise with resolution of (rejects on dismiss):
 *   @property {boolean} $wasNew Truthy when it was sent to a new email.
 *   @property {string} $email Email that the invitation/reminder was sent to.
 *   @property {Object} $stakeholder Invited stakeholder object.
 */
export const $stakeholderInviteModal = [
  '$sbModal',
  function ($sbModal) {
    return (stakeholder, workitemId) =>
      $sbModal.open({
        size: 'lg',
        template: require('./templates/invite-button-modal.html'),
        controllerAs: 'vm',
        bindToController: true,
        controller: InviteButtonModalCtrl,
        resolve: {
          Stakeholder: () => stakeholder,
          WiId: () => workitemId,
        },
      }).result;
  },
]; // end $stakeholderInviteModal

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbInviteButton
 *
 * @description
 * This directive draws a button that uses `$stakeholderInviteModal`.
 *
 * @param {object} stakeholder Standard stakeholder object of the user to remind.
 * @param {string} [wiId=undefined] Workitem ID, for optional association with a
 *   particular invitation process. This can be used, e.g., to gather the
 *   necessary parameters for handing off a relationship process to the invitee.
 * @param {expression} [onSend=undefined] Evaluated after the invatition was
 *   successfully sent. `$wasNew` {boolean}, `$email`, `$sent` {boolean}
 *   (whether an invitation email was actually sent), and `$statusMsg`
 *   will be in evalution context.
 */
export const sbInviteButton = {
  controllerAs: 'vm',
  template: require('./templates/invite-button.html'),
  bindings: {
    stakeholder: '<',
    wiId: '<?',
    onSend: '&?',
    buttonClass: '@?',
  },
  controller: [
    '$scope',
    '$stakeholderInviteModal',
    'PromiseErrorCatcher',
    function ($scope, $stakeholderInviteModal, PromiseErrorCatcher) {
      function invitationModal() {
        this.sent = false;
        $stakeholderInviteModal(this.stakeholder, this.wiId)
          .then(({ $wasNew, $email, $sent, $statusMsg }) => {
            const callback = this.onSend || angular.noop;
            callback({ $wasNew, $email, $sent, $statusMsg });
            this.sent = $sent;
          })
          .catch(PromiseErrorCatcher);
      }
      this.$onInit = () => {
        this.sent = false;
        this.buttonClass = this.buttonClass || 'btn btn-int';
      };
      this.invitationModal = invitationModal.bind(this);
    },
  ],
}; // end sbInviteButton

function nameFields(isTeam) {
  if (isTeam) {
    return [
      {
        key: 'title',
        type: 'string-textline',
        data: {},
        templateOptions: {
          label: 'Title',
          required: true,
        },
      },
    ];
  }
  return [
    {
      key: 'firstName',
      type: 'string-textline',
      data: {},
      templateOptions: {
        label: 'First Name',
        required: true,
      },
    },
    {
      key: 'lastName',
      type: 'string-textline',
      data: {},
      templateOptions: {
        label: 'Last Name',
        required: true,
      },
    },
  ];
}

const InviteButtonModalCtrl = [
  'Stakeholders',
  'Stakeholder',
  'WiId',
  function (Stakeholders, Stakeholder, WiId) {
    function isNewEmail(choice, newEmail, oldEmail) {
      return choice === 'new' && oldEmail !== newEmail;
    }
    function isNewName({ firstName, lastName, title }) {
      if (Stakeholder.teamStakeholder) {
        return title !== Stakeholder.title;
      }
      return lastName !== Stakeholder.lastName || firstName !== Stakeholder.firstName;
    }
    function sendInvitation() {
      this.error = null;
      const { choice, inviteForm, inviteNameForm } = this.model;
      const $email = inviteForm.email;
      const $wasNew =
        isNewEmail(choice, $email, this.oldEmail) || isNewName(inviteNameForm);
      Stakeholders.invite(Stakeholder.id, $email, WiId, inviteNameForm).then(
        (data) => {
          const $sent = Boolean(data.emailSent);
          /* The backend can return quite a few custom status possibilities now.
           We will also provide a fallback, though. */
          const $genericStatus = $sent ? 'Invitation sent.' : 'Invitation pending.';
          const $statusMsg = data.status ? data.status : $genericStatus;
          this.$close({
            $wasNew,
            $email,
            $stakeholder: data.stakeholder,
            $sent,
            $statusMsg,
          });
        },
        (error) => {
          this.error = error;
        },
      );
    }
    function editStakeholder() {
      this.error = null;
      const { choice, inviteForm, inviteNameForm } = this.model;
      const $email = inviteForm.email;
      const $wasNew =
        isNewEmail(choice, $email, this.oldEmail) || isNewName(inviteNameForm);
      const $sent = false;
      Stakeholders.editEternalized(Stakeholder.id, $email, WiId, inviteNameForm).then(
        (data) => {
          this.$close({
            $wasNew,
            $email,
            $stakeholder: data.stakeholder,
            $sent,
            $statusMsg: 'Data successfully updated.',
          });
        },
        (error) => {
          this.error = error;
        },
      );
    }

    this.oldEmail = Stakeholder.login || Stakeholder.email || Stakeholder.contactEmail;
    this.remindOrInvite = Stakeholder.invited ? 'Remind' : 'Invite';
    this.pendingShApproval = Stakeholder.approvalStatus === 'pending';
    this.inviteFormFields = [
      {
        key: 'email',
        type: 'email-textline',
        data: {},
        templateOptions: {
          label: 'Email',
          required: true,
          description: 'Email address of the user to invite.',
        },
      },
    ];
    this.inviteNameFields = nameFields(Stakeholder.teamStakeholder);
    this.model = {
      choice: 'current',
      inviteForm: {
        email: Stakeholder.login || Stakeholder.email || Stakeholder.contactEmail,
      },
      inviteNameForm: {
        title: Stakeholder.title,
        lastName: Stakeholder.lastName,
        firstName: Stakeholder.firstName,
      },
    };
    this.sendInvitation = sendInvitation.bind(this);
    this.editStakeholder = editStakeholder.bind(this);
  },
]; // end InviteButtonModalCtrl
