<form [formGroup]="form" (submit)="onSubmit($event)" #formlyForm="ngForm">
  <button type="submit" class="sbx-default-submit">SUBMIT</button>
  <formly-form
    [form]="form"
    [fields]="fields"
    [options]="options"
    [model]="model"
    (modelChange)="onModelChange($event)"
  ></formly-form>
</form>
