<sbx-modal-header *ngIf="title.length > 0">
  <span>{{ title }}</span>
</sbx-modal-header>
<sbx-modal-content>
  <span>{{ body }}</span>
</sbx-modal-content>
<sbx-modal-footer>
  <sbx-button
    [title]="okButtonTitle"
    theme="primary"
    (click)="modalService.close()"
  ></sbx-button>
  <sbx-button
    [title]="cancelButtonTitle"
    theme="secondary"
    (click)="modalService.dismiss()"
  ></sbx-button>
</sbx-modal-footer>
