<sbx-header></sbx-header>
<sbx-side-menu></sbx-side-menu>

<sbx-container>
  <sbx-row>
    <sbx-col [lg]="12">
      <sbx-page
        #pageRef
        [ngClass]="{
          'sbx-router-outlet-container': true,
          'sbx-page-background': !hideBackground
        }"
      >
        <router-outlet></router-outlet>
      </sbx-page>
    </sbx-col>
  </sbx-row>
</sbx-container>

<sbx-footer></sbx-footer>
