<ng-container
  *ngTemplateOutlet="buttonsTemplate; context: { $implicit: leftButtons }"
/>
<ng-container
  *ngTemplateOutlet="buttonsTemplate; context: { $implicit: centerButtons }"
/>
<ng-container
  *ngTemplateOutlet="buttonsTemplate; context: { $implicit: rightButtons }"
/>

<ng-template #buttonsTemplate let-buttons>
  <sbx-button-group>
    <ng-container *ngFor="let button of buttons">
      <sbx-button
        *ngIf="button.isVisible()"
        class="sbx-btn-{{ button.name }}"
        [theme]="getButtonTheme(button)"
        [title]="button.text"
        [icon]="getButtonLeftIcon(button)"
        [iconRight]="getButtonRightIcon(button)"
        [loading]="button.loading"
        [disabled]="button.isDisabled()"
        (click)="handleClick(button)"
      />
    </ng-container>
  </sbx-button-group>
</ng-template>
