import { Injectable, Inject } from '@angular/core';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import { ListingData } from '@shoobx/types/webapi-v2';

@Injectable()
export class SbxListingService {
  API_VERSION = '2';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
  ) {}

  getListingData(resource: string, params: URLSearchParams) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<ListingData>(`${resource}/listData?${params}`);
  }
}
