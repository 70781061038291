<ng-template #dropDownTemplate>
  <sbx-tree-select
    [root]="root"
    [includeRoot]="true"
    [multiSelect]="false"
    (change)="emitChange($event); dropdown.close()"
  ></sbx-tree-select>
</ng-template>

<sbx-dropdown #dropdown [dropDownTemplate]="dropDownTemplate">
  <span class="single-select-container">
    <div class="select-dropdown" *ngIf="!selectedNode">Select an Option</div>
    <sbx-tree-node-select
      class="select-dropdown"
      *ngIf="selectedNode"
      [root]="selectedNode"
      [renderChildren]="false"
      [rootChain]="selectedNode.getAncestors()"
    ></sbx-tree-node-select>
    <sbx-icon type="chevronDown"></sbx-icon>
  </span>
</sbx-dropdown>
