<ng-template #conditionalVestInfoIcon>
  <span class="sbx-conditional-info-icon-container">
    <sbx-popover
      sbxPopoverContent="Fidelity Private Shares identified one or more milestone-based grants for this grantee. In order to avoid ISO $100K Limit breaches, Fidelity Private Shares's proposed split calculates their most conservative vesting scenario (assuming the grants may vest in each year they are able to vest), though this may result in a suboptimal allocation of NQOs compared to ISOs."
    >
      <sbx-icon class="sbx-blue" type="infoCircle"></sbx-icon>
    </sbx-popover>
  </span>
</ng-template>

<ng-template #proposedSplitTemplate let-item let-row="row">
  <div *ngIf="row.violation">
    <b>{{ item }}</b>
  </div>
  <div *ngIf="!row.violation">
    {{ item }}
  </div>
</ng-template>

<ng-template #totalTemplate let-item let-row="row">
  <div [ngClass]="['sbx-total-template', row.violation ? 'sbx-violation-cell' : '']">
    <span>{{ item }}</span>
    <span class="sbx-total-warning-icon">
      <sbx-icon
        [type]="row.violation ? 'exclamationTriangle' : 'checkCircle'"
      ></sbx-icon>
    </span>
  </div>
</ng-template>

<ng-template #yearTemplate let-item>
  <b>{{ item }}</b>
</ng-template>

<ng-template #existingIsoTemplate let-item let-row="row">
  <b>{{ item }}</b>
  <ng-template
    *ngIf="globalData.hasConditionalGrants && row.hasExistingConditionalGrants"
    [ngTemplateOutlet]="conditionalVestInfoIcon"
  >
  </ng-template>
</ng-template>

<ng-template #pendingIsoTemplate let-item let-row="row">
  <b>{{ item }}</b>
  <ng-template
    *ngIf="globalData.hasConditionalGrants && row.hasPendingConditionalGrants"
    [ngTemplateOutlet]="conditionalVestInfoIcon"
  >
  </ng-template>
</ng-template>

<sbx-container>
  <sbx-row>
    <sbx-col>
      <div>
        You've proposed
        <b>{{ fmt(globalData.proposedSplit.totalShares) }} incentive stock options</b>
        at the
        <b>FMV price of ${{ fmt(globalData.fairMarketValue, 'dollars') }}/share</b>. In
        one or more years, this grant may exceed the
        <a
          href="https://www.fidelityprivateshares.com/blog/incentive-stock-options-the-100k-requirement"
          >ISO $100K Limit</a
        >. Treating
        <b
          >{{ fmt(globalData.proposedSplit.nqoShares) }} shares as non-qualifying stock
          options</b
        >
        will allow this grant to stay within IRS limitations. The information below
        shows a summary of the combined total of the proposed grants. For a full
        breakdown, download the XLS report.
      </div>
      <div>
        <sbx-button
          title="Proposed ISO-NQO Split XLS"
          (click)="handleXlsDownload()"
          type="default"
          [icon]="isLoading ? 'spinner' : 'download'"
          [disabled]="isLoading"
        ></sbx-button>
      </div>
    </sbx-col>
  </sbx-row>
  <sbx-row class="sbx-title-row">
    <sbx-col>
      <sbx-title type="section">Grant Treatment Summary</sbx-title>
    </sbx-col>
  </sbx-row>
  <sbx-row>
    <sbx-col [lg]="5" [sm]="6">
      <div class="sbx-grant-summary">
        <div class="sbx-grant-summary-header">PROPOSED GRANT(S)</div>
        <div class="sbx-grant-summary-box">
          <div class="sbx-grant-summary-item">
            <div class="sbx-grant-summary-label">ISO:</div>
            <div class="sbx-grant-summary-shares">
              <div>
                <b>${{ fmt(globalData.proposedSplit.totalValue, 'dollars') }}</b>
              </div>
              <div class="sbx-grant-summary-box-shares">
                ({{ fmt(globalData.proposedSplit.totalShares) }} shares)
              </div>
            </div>
          </div>
          <div class="sbx-grant-summary-item">
            <div class="sbx-grant-summary-label">NQO:</div>
            <div class="sbx-grant-summary-shares">
              <div>
                <b>${{ fmt(0, 'dollars') }}</b>
              </div>
              <div class="sbx-grant-summary-box-shares">({{ fmt(0) }} shares)</div>
            </div>
          </div>
        </div>
      </div>
    </sbx-col>
    <sbx-col [lg]="5" [sm]="6">
      <div class="sbx-grant-summary">
        <div class="sbx-grant-summary-header">PROPOSED ISO-NQO SPLIT</div>
        <div class="sbx-grant-summary-box">
          <div class="sbx-grant-summary-item">
            <div class="sbx-grant-summary-label">ISO:</div>
            <div class="sbx-grant-summary-shares">
              <div>
                <b>${{ fmt(globalData.proposedSplit.isoValue, 'dollars') }}</b>
              </div>
              <div class="sbx-grant-summary-box-shares">
                ({{ fmt(globalData.proposedSplit.isoShares) }} shares)
              </div>
            </div>
          </div>
          <div class="sbx-grant-summary-item">
            <div class="sbx-grant-summary-label">NQO:</div>
            <div class="sbx-grant-summary-shares">
              <div>
                <b>${{ fmt(globalData.proposedSplit.nqoValue, 'dollars') }}</b>
              </div>
              <div class="sbx-grant-summary-box-shares">
                ({{ fmt(globalData.proposedSplit.nqoShares) }} shares)
              </div>
            </div>
          </div>
        </div>
      </div>
    </sbx-col>
  </sbx-row>
  <sbx-row>
    <sbx-col>
      <sbx-row class="sbx-title-row">
        <sbx-col [lg]="10">
          <sbx-title type="section"
            >ISO-NQO Treatment for {{ stakeholderName }} by Year</sbx-title
          >
          <div *ngIf="globalData.hasConditionalGrants">
            <b><i>Note:</i></b> this grantee has one or more milestone/custom grants
            <ng-template [ngTemplateOutlet]="conditionalVestInfoIcon"></ng-template>.
          </div>
        </sbx-col>
        <sbx-col [lg]="2">
          <div class="sbx-toggle">
            <span class="sbx-toggle-label">Dollars</span>
            <span class="sbx-toggle-container">
              <sbx-slide-toggle (checked)="(mode !== 'value')" (toggled)="toggleMode()">
              </sbx-slide-toggle>
            </span>
            <span class="sbx-toggle-label">Shares</span>
          </div>
        </sbx-col>
      </sbx-row>
    </sbx-col>
  </sbx-row>
  <sbx-row>
    <sbx-col>
      <sbx-table [columns]="tableColumns" [groups]="tableGroups" [data]="tableData">
      </sbx-table>
    </sbx-col>
  </sbx-row>
</sbx-container>
