import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SbxPagingComponent } from './sbx-paging.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, FormsModule, SbxIconModule],
  declarations: [SbxPagingComponent],
  exports: [SbxPagingComponent],
})
export class SbxPagingModule {}
