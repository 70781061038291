<ul class="list-unstyled">
  <!-- truncate menuItems -->
  <li
    *ngFor="
      let menuItem of maxLength && maxLength < menuItems?.length
        ? (menuItems | slice : 0 : maxLength - 1)
        : menuItems
    "
  >
    <sbx-menu-item [menuItem]="menuItem"></sbx-menu-item>
  </li>
  <!-- add more rows -->
  <li *ngIf="maxLength && maxLength < menuItems?.length">
    <sbx-menu-item
      [overflowName]="itemType"
      [menuItems]="menuItems | slice : maxLength - 1"
    >
    </sbx-menu-item>
  </li>
</ul>
