export const PARA_STYLES = [
  {
    type: 'para.Small',
    label: 'Small',
    class: 'Small',
  },
  {
    type: 'para.DocumentTitle',
    label: 'Document Title',
    class: 'DocumentTitle',
  },
  {
    type: 'para.ExhibitTitle',
    label: 'Exhibit Title',
    class: 'ExhibitTitle',
  },
  {
    type: 'para.Indent',
    label: '3/4 Inch First Line Indentation',
    class: 'Indent',
  },
  {
    type: 'para.IndentOne',
    label: 'Single Indentation',
    class: 'IndentOne',
  },
  {
    type: 'para.IndentTwo',
    label: 'Double Indentation',
    class: 'IndentTwo',
  },
  {
    type: 'para.Right',
    label: 'Right Aligned',
    class: 'Right',
  },
  {
    type: 'para.Left',
    label: 'Left Aligned',
    class: 'Left',
  },
  {
    type: 'para.Center',
    label: 'Center Aligned',
    class: 'Center',
  },
  {
    type: 'para.HalfSpaceAfter',
    label: 'Half Space After',
    class: 'HalfSpaceAfter',
  },
  {
    type: 'para.QuarterSpaceAfter',
    label: 'Quarter Space After',
    class: 'QuarterSpaceAfter',
  },
  {
    type: 'para.NoSpaceAfter',
    label: 'No Space After',
    class: 'NoSpaceAfter',
  },
  {
    type: 'para.BlankMessage',
    label: 'Blank Message',
    class: 'BlankMessage',
  },
  {
    type: 'para.SectionTitle',
    label: 'Section Title',
    class: 'SectionTitle',
  },
  {
    type: 'para.Definition',
    label: 'Definition',
    class: 'Definition',
  },
  {
    type: 'para.ArticleNumber',
    label: 'Article Number',
    class: 'ArticleNumber',
  },
  {
    type: 'para.ArticleContent',
    label: 'Article Content',
    class: 'ArticleContent',
  },
  {
    type: 'para.Helvetica',
    label: 'Helvetica',
    class: 'Helvetica',
  },
];
