import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxRadioGroupModule } from '@/shared/sbx-radio-group/sbx-radio-group.module';
import { SbxRadioModule } from '@/shared/sbx-radio/sbx-radio.module';
import { SbxSignatureModule } from './sbx-signature/sbx-signature.module';
import { SbxSignatureModalComponent } from './sbx-signature-modal.component';
import { SbxSignatureModalService } from './sbx-signature-modal.service';

@NgModule({
  declarations: [SbxSignatureModalComponent],
  imports: [
    CommonModule,
    SbxButtonModule,
    SbxIconModule,
    SbxModalModule,
    SbxRadioGroupModule,
    SbxRadioModule,
    SbxSignatureModule,
  ],
  providers: [SbxSignatureModalService],
})
export class SbxSignatureModalModule {}
