import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReferenceGroup } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-document-reference-group',
  templateUrl: './sbx-document-reference-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxDocumentReferenceGroupComponent {
  @Input({ required: true }) public group: ReferenceGroup;
  @Output() public readonly removeReference = new EventEmitter<string>();
}
