import { Component, Input, ViewChild } from '@angular/core';
import { NgScrollbar, ScrollbarTrack } from 'ngx-scrollbar';

@Component({
  selector: 'sbx-scrollable',
  styleUrls: ['./sbx-scrollable.component.scss'],
  templateUrl: './sbx-scrollable.component.html',
})
export class SbxScrollableComponent {
  @ViewChild(NgScrollbar, { static: true }) ngScrollbar: NgScrollbar;
  @Input() track: ScrollbarTrack = 'vertical';
  @Input() autoCalculateContentWidth = false;
  @Input() autoCalculateContentHeight = false;
}
