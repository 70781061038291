import angular from 'angular';
import Modal from '../modal/modal';
import ngSanitize from 'angular-sanitize';

import Tools from '../tools';
import Rx from '../rx';
import Promise from 'lib/promise';
import { sbObscuredData, sbObscuredInput } from './obscured';
import { sbFourOhFour } from './error';
import { sbAlertBanner } from './alert-banner';
import iframe from './iframe';

/**
 * @ngdoc directive
 * @name sb.lib.messages.directive:sbMessage
 * @restrict E
 *
 * @description
 * A generic message directive (likely for workitems) that can display trusted
 * content. Trusted means that the HTML is unsantized. Make sure you understand
 * this before setting enabling this. Read the link below.
 *
 * @param {string} sbMessage The message contents.
 * @param {boolean} [sbMessageTrusted=false] If this is set to true, the
 *    contents of the message are trusted and unsantized. Read {@link
 *    https://docs.angularjs.org/api/ng/service/$sce} for context. *Note* this
 *    param is only evaluted when message changes.
 *
 * @example
   <sb-message
     sb-message="'This is some trusted content'"
     sb-message-trusted="true"></sb-message>
 */
const sbMessage = [
  '$sce',
  function ($sce) {
    return {
      restrict: 'E',
      scope: {
        message: '&sbMessage',
        trusted: '&sbMessageTrusted',
      },
      template: '<div data-ng-bind-html="innerMessage"></div>',
      link: function (scope) {
        scope.$watch('message()', (nv) => {
          nv = nv || '';
          if (scope.trusted()) {
            nv = $sce.trustAsHtml(nv);
          }
          scope.innerMessage = nv;
        });
      },
    };
  },
]; // end sbMessage

/**
 * @ngdoc overview
 * @name sb.lib.messages
 *
 * @description
 * This module houses message and notification related components.
 */
export default angular
  .module('sb.lib.messages', [Modal, ngSanitize, Tools, Rx, Promise])
  .component('sbAlertBanner', sbAlertBanner)
  .component('sbObscuredData', sbObscuredData)
  .component('sbObscuredInput', sbObscuredInput)
  .directive('sbFourOhFour', sbFourOhFour)
  .directive('iframe', iframe)
  .directive('sbMessage', sbMessage).name;
