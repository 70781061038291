import angular from 'angular';
/**
 * @ngdoc service
 * @kind function
 * @name sb.lib.form.FormSubmitResolve
 *
 * @description
 * This service is a abstraction for managing FormFeedback and components
 * that may trigger form submission registrations. To create a component that
 * is managed by this resolver, `$emit` the event `registerFormSubmitPromise`,
 * passing a function that returns a promise that must be resolved on form
 * submission and rejects when the form is not ready. Conversely, `$emit` the
 * event `deregisterFormSubmitPromise` later to remove the callback.
 *
 * @param {object} scope A scope object that will listen for the events
 *    described above.
 * @param {object} [feedback=undefined] A formfeedback object that must
 *    pass `submittingForm()` before any registrations are called.
 *
 * @returns {object} This object has a `.resolve()` that returns a function
 *    that on resolution, the form is safe to submit. You may also pass a feedback
 *    to this resolve and override the intial feedback provided with construction.
 *    This is useful in cases where feedback is not immediately ready or many
 *    different feedbacks are used.
 *
 * @example
   // Some lower level component:
   function callback() {
     return $q(function(resolve, reject) {
       if ( isOkayToSubmit() )
         resolve();
       else
         reject();
     });
   }
   $scope.$emit('registerFormSubmitPromise', callback);
   $scope.$on('$destroy', function() {
     $scope.$emit('deregisterFormSubmitPromise', callback);
   });

   // Some higher level form controller:
   var resolver = FormSubmitResolve($scope, $scope.formFeedback);
   $scope.save = function() {
     resolver.resolve().then(function() {
       // Form has passed both the formfeedback and all registered
       // components are good to go.
     }, function() {
       // Form is *not* good to go.
     });
   };
 */
export const FormSubmitResolve = [
  '$q',
  '$timeout',
  function ($q, $timeout) {
    function reject(rejection) {
      return $q.reject(rejection);
    }

    function Resolver(scope, feedback) {
      this.$$registrations = [];
      this.$$feedback = feedback;
      scope.$on('registerFormSubmitPromise', (evt, func) => {
        if (!this.$$registrations.includes(func)) {
          this.$$registrations.push(func);
        }
      });
      scope.$on('deregisterFormSubmitPromise', (evt, func) => {
        this.$$registrations = (this.$$registrations || []).filter(
          (item) => item !== func,
        );
      });
    }

    Resolver.prototype.resolve = function (passedFeedback) {
      const realFeedback = passedFeedback ||
        this.$$feedback || {
          submittingForm() {
            return { resolve: angular.noop };
          },
        };
      return $q
        .when(realFeedback.submittingForm())
        .then((completed) => {
          const regs = this.$$registrations || [];
          this.$$feedbackComplete = completed.resolve;
          return $q.all(regs.map((reg) => $q.when(reg())));
        }, reject)
        .then(() => {
          this.$$feedbackComplete();
          // Since this happens without garanteed $digests, wait for one timeout.
          return $timeout(null, 0, false);
        }, reject);
    };

    return function (scope, feedback) {
      return new Resolver(scope, feedback);
    };
  },
]; // end FormSubmitResolve

/**
 * @ngdoc controller
 * @name sb.lib.form.controller:sbBackendFeedbackController
 *
 * @description
 * Backend feedback
 *
 * Provide visual feedback to the user while some actions are in progress.
 * Methods of this objects returns deferred, that is supposed to be resolved
 * by the caller, when action it performs is complete. Visual feedback is
 * provided until this dererred is resolved.
 */
export const sbBackendFeedbackController = [
  '$q',
  '$timeout',
  '$window',
  function ($q, $timeout, $window) {
    const feedback = this;
    function focusFirstInvalid() {
      const firstInvalid = feedback.$$elem.find('.sb-widget-error:first')[0];
      if (firstInvalid) {
        const y =
          $window.scrollY +
          firstInvalid.getBoundingClientRect().top -
          $window.innerHeight * 0.5;
        $window.scrollTo($window.scrollX, Math.max(y, 0));
      }
    }

    function setInvalidsFields(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, '$viewValue')) {
        if (!obj.$valid) {
          obj.$invalid = true;
        }
        return;
      }
      if (Array.isArray(obj)) {
        for (const field of obj) {
          setInvalidsFields(field);
        }
        return;
      }
      for (const errorType in obj.$error) {
        if (Object.prototype.hasOwnProperty.call(obj.$error, errorType)) {
          setInvalidsFields(obj.$error[errorType]);
        }
      }
    }

    /**
     * @ngdoc method
     * @name submittingForm
     * @methodOf sb.lib.form.controller:sbBackendFeedbackController
     *
     * @description
     * Provide feedback while submitting the form.
     *
     * Form is validated and returned promise will only be resolved if form is
     * valid. Otherwise, input focus will be moved to the first invalid field.
     * After returned deferred is resolved, form will be checked for
     * errors again.
     *
     * The typical usage is in form submit handler:
     *
     * ```
     * feedbackController.submittingForm().then(function(completed) {
     *   // do something, assuming form is valid
     *   // ...
     *   completed.resolve();
     * });
     * ```
     *
     * @returns {promise} Promise that is supposed to be fulfilled when form
     *     submitting is complete.
     */
    this.submittingForm = function () {
      const submitCompleted = $q.defer();

      submitCompleted.promise.then(() => {
        $timeout(focusFirstInvalid);
      });

      return $q((resolve, reject) => {
        if (feedback.$$form.$invalid) {
          focusFirstInvalid();
          setInvalidsFields(feedback.$$form);
          reject();
        } else {
          resolve(submitCompleted);
        }
      });
    };
  },
]; // end sbBackendFeedbackController

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFormFeedback
 * @restrict A
 *
 * @description
 * Directive to add some feedback when form is submitted. It adds {@link
 * sb.lib.form.controller:sbBackendFeedbackController
 * sbBackendFeedbackController} to the scope, allowing form submission and
 * error handling easier on the form author. See {@link
 * sb.lib.form.controller:sbBackendFeedbackController
 * sbBackendFeedbackController} for more details.
 *
 * @element ANY
 * @param {string} sbFormFeedback Name of for feedback object, that will appear
 *     on `$scope`.
 */
export const sbFormFeedback = [
  '$parse',
  function ($parse) {
    return {
      restrict: 'A',
      require: ['form', 'sbFormFeedback'],
      controller: 'sbBackendFeedbackController',
      link: function (scope, elem, attrs, [formCtrl, fbCtrl]) {
        const name = attrs.sbFormFeedback;

        fbCtrl.$$form = formCtrl;
        fbCtrl.$$elem = elem;
        fbCtrl.$$name = name;

        const setter = $parse(name).assign;
        setter(scope, fbCtrl);
      },
    };
  },
]; // end sbFormFeedback
