import angular from 'angular';

import { ShowPageModel } from './providers';
import { sbShowPage } from './directives';
import { sbApprovalMethodTemplate } from './approval-method';
import { sbExerciseOptionsCalculator } from './equity';

/**
 * @ngdoc overview
 * @name sb.workitem.showPage
 *
 * @description
 * This is the module for the show page workitem.
 */
export default angular
  .module('sb.workitem.showPage', [])

  .component('sbApprovalMethodTemplate', sbApprovalMethodTemplate)
  .component('sbExerciseOptionsCalculator', sbExerciseOptionsCalculator)
  .directive('sbShowPage', sbShowPage)
  .factory('ShowPageModel', ShowPageModel).name;
