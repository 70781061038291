<sbx-row>
  <div class="col-xs-12 col-sm-5">
    <sbx-checkbox
      name="select-all"
      [model]="isSelectAllChecked"
      [indeterminate]="isSelectAllIndeterminate"
      (change)="handleSelectAllInCurrentPageChange($event)"
    ></sbx-checkbox>
    <sbx-bulk-description
      [selected]="selectedItems.length"
      [total]="items.length"
      [selectedOnPage]="selectedPageItems.length"
      [pageSize]="pageSize"
      (selectAll)="checkAllItems()"
      (clearAll)="uncheckAllItems()"
    ></sbx-bulk-description>
  </div>

  <div class="col-xs-12 col-sm-7 sbx-stakeholder-filter-wrapper">
    <sbx-stakeholder-base
      placeholderText="Search Security Holders..."
      [showSearchIcon]="true"
      [cardDisplayFormat]="'short'"
      [stakeholderOptions]="{
        allowEntity: true,
        allowPerson: true
      }"
      [securityTicketId]="securityTicketId"
      (selectStakeholder)="filterStakeholders($event)"
      (removeStakeholder)="clearStakeholders()"
    >
    </sbx-stakeholder-base>

    <sbx-paging
      [page]="page"
      [size]="pageSize"
      [totalItems]="totalItems"
      (change)="handlePage($event)"
    ></sbx-paging>
  </div>
</sbx-row>

<sbx-table
  [ngClass]="{ 'sbx-table-wrapper': isLoading }"
  *sbxLoader="isLoading"
  [columns]="columns"
  [data]="pageItems"
></sbx-table>

<ng-template #checkboxTpl let-item let-row="row">
  <sbx-checkbox
    [name]="row.id"
    [model]="selectedItems.indexOf(row.id) > -1"
    (change)="handleChange(row.id, $event)"
  >
  </sbx-checkbox>
</ng-template>

<ng-template #formattedItemTpl let-column="column" let-row="row">
  {{ row[column.key] }}
</ng-template>
