import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { ISbxIconTypes } from '@/core/constants/icons';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';

type ISbxProcessButtonName = 'back' | 'forward' | 'continue';

export interface ISbxProcessButton {
  id: string;
  text: string;
  name: ISbxProcessButtonName | string;
  type: 'primary' | 'info' | 'danger';
  confirmText: string;
  isVisible: () => boolean;
  isDisabled: () => boolean;
}

@Downgrade.Component('ngShoobx', 'sbx-process-buttons')
@Component({
  selector: 'sbx-process-buttons',
  templateUrl: './sbx-process-buttons.component.html',
  styleUrls: ['./sbx-process-buttons.component.scss'],
})
export class SbxProcessButtonsComponent {
  @Input() leftButtons: ISbxProcessButton[] = [];
  @Input() centerButtons: ISbxProcessButton[] = [];
  @Input() rightButtons: ISbxProcessButton[] = [];
  @Output() buttonClick = new EventEmitter<string>();

  constructor(
    @Inject(SbxConfirmationModalService)
    private sbxConfirmationModalService: SbxConfirmationModalService,
  ) {}

  getButtonTheme(button: ISbxProcessButton) {
    switch (button.type) {
      case 'primary':
        return 'primary';
      case 'info':
        return 'secondary';
      case 'danger':
        return 'danger';
      default:
        return null;
    }
  }

  getButtonLeftIcon(button: ISbxProcessButton): keyof ISbxIconTypes {
    switch (button.name) {
      case 'back':
        return 'chevronLeft';
      default:
        return null;
    }
  }

  getButtonRightIcon(button: ISbxProcessButton): keyof ISbxIconTypes {
    switch (button.name) {
      case 'forward':
      case 'continue':
        return 'chevronRight';
      default:
        return null;
    }
  }

  async handleClick(button: ISbxProcessButton) {
    if (button.confirmText) {
      await this.sbxConfirmationModalService.open({
        data: {
          body: button.confirmText,
          okButtonTitle: 'Confirm',
        },
      }).result;
    }

    this.buttonClick.emit(button.name);
  }
}
