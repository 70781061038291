import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import { ISearchParams } from './models/search-params.model';

@Injectable()
export class PrincipalStakeholderSearchService {
  constructor(private service: SbxHttpClient) {}

  search(text: string, params: ISearchParams): Observable<any> {
    for (const listParam of ['includeStates', 'excludeStates']) {
      if (params[listParam]) {
        params[listParam] = params[listParam].toString();
      }
    }

    params = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    return this.service.entity('1').get('stakeholders/search', {
      params: {
        terms: text,
        ...params,
      },
    });
  }
}
