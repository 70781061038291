<div
  class="popover-container"
  [popoverTitle]="sbxPopoverTitle"
  [ngbPopover]="template"
  [placement]="sbxPopoverPlacement"
  [triggers]="sbxPopoverTriggers"
  [container]="sbxPopoverContainer"
  [autoClose]="sbxPopoverAutoClose"
  [disablePopover]="sbxPopoverDisabled || !sbxPopoverContent ? true : null"
  [popoverClass]="sbxPopoverClass + (sbxPopoverAutoWidth ? ' auto-width' : '')"
>
  <ng-content></ng-content>
</div>

<ng-template #template>
  <div
    *ngIf="!isPopoverContentTemplate; else templateWithContext"
    [innerHTML]="sbxPopoverContent"
  ></div>
</ng-template>

<ng-template #templateWithContext>
  <ng-container
    *ngTemplateOutlet="
      $any(sbxPopoverContent);
      context: { $implicit: sbxPopoverTemplateContext }
    "
  ></ng-container>
</ng-template>
