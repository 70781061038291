import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxPopoverComponent } from './sbx-popover.component';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [SbxPopoverComponent],
  exports: [SbxPopoverComponent],
})
export class SbxPopoverModule {
  static entryComponents = [SbxPopoverComponent];

  static forRoot(): ModuleWithProviders<SbxPopoverModule> {
    return {
      ngModule: SbxPopoverModule,
    };
  }
}
