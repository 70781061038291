/**
 * @ngdoc directive
 * @name sb.workitem.requestSupport.directive:sbRequestSupportProgress
 * @restrict E
 *
 * @description
 *
 * Show support ticket status on ``request_support`` workitem.
 *
 * @param {string} title Title of the status block
 */
export function sbRequestSupportProgress() {
  return {
    restrict: 'E',
    template: require('./templates/progress.html'),
    scope: {
      title: '@',
    },
    controller: [
      '$scope',
      '$http',
      'BackendLocation',
      function ($scope, $http, BackendLocation) {
        const statusUrl = BackendLocation.context('1') + 'progress';

        $scope.progress = [];

        $http.get(statusUrl).then((resp) => {
          $scope.progress = resp.data;
        });
      },
    ],
  };
} // end sbRequestSupportProgress
