import SignaturePad from 'signature_pad';
import trimCanvas from 'trim-canvas';

export const sbSignatureCapture = {
  template: require('./templates/signature-capture.html'),
  controllerAs: 'vm',
  bindings: {
    viewOnly: '<',
  },
  require: {
    ngModelCtrl: 'ngModel',
  },
  controller: [
    '$element',
    '$window',
    function ($element, $window) {
      this.$onInit = () => {
        const [canvas] = $element.find('canvas');
        resizeCanvas($window, canvas);
        this.signaturePad = new SignaturePad(canvas, {
          onEnd: () => {
            let trimmedCanvas = canvas.cloneNode();
            trimmedCanvas.getContext('2d').drawImage(canvas, 0, 0);
            trimmedCanvas = trimCanvas(trimmedCanvas);
            this.ngModelCtrl.$setViewValue(trimmedCanvas.toDataURL());
          },
        });
        this.ngModelCtrl.$render = () => {
          const modelValue = this.ngModelCtrl.$modelValue;
          this.signaturePad.clear();
          if (modelValue) {
            this.signaturePad.fromDataURL(modelValue);
          }
        };
        this.resizeCb = () => {
          resizeCanvas($window, canvas);
        };
        $window.addEventListener('resize', this.resizeCb);
      };
      this.$onDestroy = () => {
        $window.removeEventListener('resize', this.resizeCb);
      };
    },
  ],
};

// Adjust canvas coordinate space taking into account pixel ratio,
// to make it look crisp on mobile devices.
// This also causes canvas to be cleared.
function resizeCanvas($window, canvas) {
  // When zoomed out to less than 100%, for some very strange reason,
  // some browsers report devicePixelRatio as less than 1
  // and only part of the canvas is cleared then.
  const ratio = Math.max($window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
}
