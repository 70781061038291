import angular from 'angular';
/**
 * @ngdoc directive
 * @name sb.workitem.showPage.directive:sbShowPage
 * @restrict E
 * @requires workitem/sb.workitem.showPage.ShowPageModel
 *
 * @description
 * The controller/element for a showPage WI.
 */
export const sbShowPage = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'E',
      template: require('./templates/show-page.html'),
      controller: [
        '$scope',
        '$q',
        'AppConfig',
        'ProcessButtonModel',
        'ProcessStatus',
        'ProcessModel',
        'ShowPageModel',
        function (
          $scope,
          $q,
          AppConfig,
          ProcessButtonModel,
          ProcessStatus,
          ProcessModel,
          ShowPageModel,
        ) {
          function submit(resolve, reject) {
            angular.forEach($scope.model.$forms, (formDef, formName) => {
              const formCtrl = $scope.mainForm['formly_' + formName];
              if (formCtrl) {
                formCtrl.$setSubmitted(true);
              }
            });
            $scope.mainFormFeedback
              .submittingForm()
              .then((complete) => {
                ProcessButtonModel.disable();
                return ShowPageModel.$submitForms(complete);
              }, $q.reject)
              .then(
                () => {
                  resolve();
                },
                (info) => {
                  reject();

                  if (info) {
                    info.complete.resolve();
                    ProcessStatus.$setStatus(info.reason, 'danger');
                    return;
                  }

                  ProcessStatus.$setStatus(
                    'Please correct the form errors below',
                    'danger',
                  );
                },
              )
              .finally(() => {
                ProcessButtonModel.requestEnable();
              });
          }

          function persist(key, onPersist, onRehydrate) {
            const data = JSON.parse(sessionStorage.getItem(key));

            if (data) {
              onRehydrate(data);
              sessionStorage.removeItem(key);
            }

            window.onbeforeunload = () => {
              const data = onPersist();
              sessionStorage.setItem(key, JSON.stringify(data));
            };
          }

          ProcessButtonModel.disable();
          // Show page allows buttons with names ending in `continue` to submit
          // the forms, e.g. `update_table_continue`, see (SF) Termination Grant
          // Repurchase.
          ProcessButtonModel.$addSubmitCondition(/continue$/, () => $q(submit));
          ProcessButtonModel.$addSubmitCondition(/signall$/, () => $q(submit));
          $scope.model = ShowPageModel;
          ShowPageModel.$init().then(
            () => {
              if (!ProcessModel.$wi.completed) {
                persist(
                  `${AppConfig.userProfile?.id || 'guest'}-${ProcessModel.$wi.id}`,
                  () => $scope.model.$formData,
                  (data) => $scope.model.$$updateData(data),
                );
              }

              ProcessButtonModel.requestEnable();
            },
            (reason) => {
              ProcessStatus.$setStatus(reason, 'danger');
            },
          );
        },
      ], // end controller
      link(scope, element) {
        const stop = scope.$watch('model.$content', (nv) => {
          if (!nv) {
            return;
          }
          const $slot = element.find('.show-page-template');
          $slot.html(nv);
          $compile($slot.contents())(scope);
          stop();
        });
      },
    };
  },
]; // end sbShowPage
