import angular from 'angular';
import OrgChart from 'OrgChart.js/dest/orgchart.min.js';

/**
 * @ngdoc component
 * @name sb.lib.orgchart.component:sbOrgChart
 *
 * @description
 * Component to display pedigree chart
 */

const DEFAULT_MAX_ADJ_NODES = 20;

const sbOrgChart = {
  controllerAs: 'vm',
  bindings: {
    errors: '=',
    organizationalData: '<',
    isHighlighted: '<',
    canStartJobChangeEvent: '<',
    jobChangeLinkTemplate: '<',
    updateOrgChartLinkTemplate: '<',
    maxAdjNodes: '<',
  },
  template: require('./templates/orgchart.html'),
  controller: class {
    static $inject = [
      '$scope',
      '$element',
      '$sbModal',
      'PromiseErrorCatcher',
      'EmployeeDetails',
    ];

    constructor($scope, $element, $sbModal, PromiseErrorCatcher, EmployeeDetails) {
      this.$scope = $scope;
      this.$element = $element;
      this.$sbModal = $sbModal;
      this.EmployeeDetails = EmployeeDetails;
      this.PromiseErrorCatcher = PromiseErrorCatcher;

      this.orgchart = null;
      this.legend = {};
      this.borderLegend = {};
      this.isLegendOpen = false;

      this.constructChart = this.constructChart.bind(this);
      this.getLevel = this.getLevel.bind(this);
      this.showPopup = this.showPopup.bind(this);

      $scope.$on('orgchart:highlightDepartments', () => {
        this.orgchart.toggleColors();
      });

      $scope.$on('orgchart:showAll', () => {
        this.constructChart(this.organizationalData.tree);
      });

      $scope.$on('orgchart:export', () => {
        this.orgchart._clickExportButton();
      });
    }

    $postLink() {
      if (this.organizationalData && this.organizationalData.tree) {
        this.constructChart(this.organizationalData.tree);
      }
    }

    toggleLegend() {
      this.isLegendOpen = !this.isLegendOpen;
    }

    constructChart(tree) {
      this.orgchart = null;

      this.$element.find('#orgchart').html('');

      const data = this.organizationalData;

      // Generate legend data
      if (data && data.options.legend_title.length > 0) {
        this.legend = this.generateLegend(data.tree, 'color');
      }

      // Generate border legend data
      if (data && data.options.border_legend_title.length > 0) {
        this.borderLegend = this.generateLegend(data.tree, 'border_color');
      }

      this.orgchart = new OrgChart({
        rootNodeTitle: 'name',
        nodeTitle: 'title',
        firstLine: 'name',
        secondLine: 'additional',
        chartContainer: '#orgchart',
        data: angular.copy(tree), // Because lib is changing data directly >.<
        depth: 999,
        verticalDepth: this.getLevel(tree),
        nodeContent: 'title',
        pan: true,
        zoom: false,
        parentNodeSymbol: '',
        showAvatars: true,
        colored: this.isHighlighted,
        colors: this.legend,
        borderColors: this.borderLegend,
        onNodeSelect: this.showPopup,
        onShowAllClick: () => this.constructChart(data.tree),
      });
    }

    generateLegend(object, key) {
      // Number of colors in orgchart.less
      const colorsCount = 19;
      const unfilteredColors = getColors(object, key);

      const filteredColors = [...new Set(unfilteredColors)];

      const colors = filteredColors.reduce((colors, color, i) => {
        colors[color] = `color-${(i % colorsCount) + 1}`;

        return colors;
      }, {});

      return colors;

      function getColors(object, key) {
        let colors = [];

        if (object[key]) {
          colors.push(object[key]);
        }

        if (object.children) {
          for (let i = 0; i < object.children.length; i++) {
            colors = colors.concat(getColors(object.children[i], key));
          }
        } else {
          return colors;
        }

        return colors;
      }
    }

    getLevel(tree) {
      let heads = [tree];
      let level = 1;

      this.maxAdjNodes = this.maxAdjNodes || DEFAULT_MAX_ADJ_NODES;

      while (heads.length > 0 && heads.length <= this.maxAdjNodes) {
        const headsClone = heads;
        heads = [];

        for (let i = 0; i < headsClone.length; i++) {
          if (headsClone[i].children) {
            heads = heads.concat(headsClone[i].children);

            if (heads.length > this.maxAdjNodes) {
              break;
            }
          }
        }

        level++;
      }

      if (level < 3) {
        level = 3;
      }

      return level;
    }

    getManagers(id) {
      let managersArray = [];
      let found = false;

      traverse(this.organizationalData.tree);
      return buildTree(managersArray);

      function traverse(tree) {
        if (tree.sh_id === id) {
          found = true;

          addToTreeArray(tree, true);

          return;
        }

        if (!found) {
          if (tree.children && tree.children.length > 0) {
            for (let i = 0; i < tree.children.length; i++) {
              traverse(tree.children[i]);
            }

            addToTreeArray(tree);
          }
        }
      }

      function addToTreeArray(node, isFound) {
        const currentNode = angular.copy(node);
        delete currentNode.children;

        managersArray.unshift(currentNode);

        if (isFound) {
          const index = managersArray.findIndex((n) => n.sh_id === currentNode.sh_id);
          managersArray = managersArray.slice(0, index + 1);
        }
      }

      function buildTree(treeArray) {
        let tree = {};
        const reversedTree = angular.copy(treeArray).reverse();

        for (const node of reversedTree) {
          const children = tree;
          tree = node;

          if (Object.keys(children).length > 0) {
            tree.children = [children];
          }
        }

        return tree;
      }
    }

    getSubordinates(id) {
      let heads = [angular.copy(this.organizationalData.tree)];

      while (heads.length > 0) {
        const headsClone = heads;
        heads = [];

        for (let i = 0; i < headsClone.length; i++) {
          if (headsClone[i].sh_id === id) {
            headsClone[i].hasSubordinates = true;
            return headsClone[i];
          }

          if (headsClone[i].children) {
            heads = heads.concat(headsClone[i].children);
          }
        }
      }
    }

    showPopup(node) {
      if (node.root) {
        return;
      }

      const getEmployeeDetails = this.EmployeeDetails(node.sh_id);
      const managers = this.getManagers(node.sh_id);
      const subordinates = this.getSubordinates(node.sh_id);
      const constructChart = this.constructChart;

      const openModal = (employee) =>
        this.$sbModal
          .open({
            size: 'md',
            windowClass: 'orgchart-modal',
            template: require('./templates/popup.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              'employee',
              'hasManagers',
              'hasSubordinates',
              'canStartJobChangeEvent',
              'jobChangeLinkTemplate',
              'updateOrgChartLinkTemplate',
              function (
                $scope,
                $uibModalInstance,
                employee,
                hasManagers,
                hasSubordinates,
                canStartJobChangeEvent,
                jobChangeLinkTemplate,
                updateOrgChartLinkTemplate,
              ) {
                $scope.employee = employee;
                $scope.hasManagers = hasManagers;
                $scope.hasSubordinates = hasSubordinates;
                $scope.canStartJobChangeEvent = canStartJobChangeEvent;
                $scope.jobChangeLinkTemplate = jobChangeLinkTemplate;
                $scope.updateOrgChartLinkTemplate = updateOrgChartLinkTemplate;

                $scope.showManagers = function () {
                  $uibModalInstance.close('managers');
                };

                $scope.showSubordinates = function () {
                  $uibModalInstance.close('subordinates');
                };
              },
            ],
            resolve: {
              employee,
              hasManagers: managers,
              hasSubordinates:
                subordinates.children && subordinates.children.length > 0,
              canStartJobChangeEvent: this.canStartJobChangeEvent,
              jobChangeLinkTemplate: () => this.jobChangeLinkTemplate + node.sh_id,
              updateOrgChartLinkTemplate: () => this.updateOrgChartLinkTemplate,
            },
          })
          .result.catch(this.PromiseErrorCatcher);

      getEmployeeDetails.then(openModal).then(
        (action) => {
          if (action === 'managers') {
            constructChart(managers);
          }

          if (action === 'subordinates') {
            constructChart(subordinates);
          }
        },
        (error) => {
          this.errors = error;
        },
      );
    }
  },
};

export default sbOrgChart;
