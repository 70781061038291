<sbx-alert-banner #statusBanner></sbx-alert-banner>
<div class="sbx-approve-document-group" *ngFor="let group of resolutiondetails">
  <div class="sbx-approve-document" *ngFor="let doc of group.details">
    <sbx-row class="sbx-title-text-row" *ngIf="doc.has_resolution">
      <sbx-icon
        *ngIf="doc.status_title !== 'Approved'"
        type="pending"
        class="pending"
      ></sbx-icon>
      <sbx-icon
        *ngIf="doc.status_title === 'Approved'"
        type="checkCircle"
        class="approved"
      ></sbx-icon>
      <h3 *ngIf="doc.has_resolution">
        {{ doc.category ? doc.category.toUpperCase() : 'BOARD APPROVAL' }}:
      </h3>

      <h4 *ngFor="let title of doc.stripped_titles; last as lastItem">
        {{ title + (lastItem ? '' : ',&nbsp;') }}
      </h4>
    </sbx-row>
    <sbx-row class="sbx-title-text-row">
      <div *ngIf="doc.consent">
        <span *ngIf="doc.has_resolution">
          This resolution {{ doc.status === 'pending' ? 'is' : 'was' }} included in a
          <strong> {{ doc.type ? doc.type : 'Board' }} Consent. </strong>
        </span>
        <span *ngIf="!doc.has_resolution">
          {{ doc.type ? doc.type : 'Board' }} Consent
        </span>
        <sbx-button
          class="view"
          theme="default"
          [href]="doc.consent.url"
          icon="show"
          title="View"
        ></sbx-button>
      </div>

      <div class="sbx-upload-link" *ngIf="doc.consent_proc">
        To upload scanned signatures or manage the upload process visit the
        <a [href]="doc.consent_proc">
          {{ doc.type ? doc.type : 'Board' }} Consent workflow
          <sbx-icon type="share"></sbx-icon> </a
        >:
      </div>
    </sbx-row>

    <sbx-row
      *ngFor="let sig of doc.signatures; last as lastItem"
      class="{{
        'sbx-signer-row ' +
          (sig.has_sig ? 'check' : sig.sign_button ? 'share' : 'remind') +
          (lastItem ? '' : ' show_or')
      }}"
    >
      <sbx-col [sm]="1" [lg]="1">
        <sbx-icon type="check" *ngIf="sig.has_sig"></sbx-icon>
        <sbx-icon type="sign" *ngIf="!sig.has_sig && !sig.sign_button"></sbx-icon>
        <sbx-icon type="share" *ngIf="!sig.has_sig && sig.sign_button"></sbx-icon>
      </sbx-col>

      <sbx-col class="sbx-approve-role" [sm]="3" [lg]="3">
        {{ doc.type && doc.type !== 'Board' ? 'Stockholder' : 'Board Member' }}
      </sbx-col>

      <sbx-col [sm]="3" [lg]="3">
        <div class="sbx-signer-name" attr.data-name="{{ sig.signatory_fullname }}">
          {{ sig.stakeholder.fullName }}
        </div>
      </sbx-col>

      <sbx-col [sm]="4" [lg]="4">
        <div *ngIf="sig.upload_extraData?.status">
          <a [href]="sig.upload_extraData.status.link">
            {{ sig.upload_extraData.status.title }}
          </a>
          <sbx-button
            theme="default"
            (click)="handleUndo(sig)"
            icon="undo"
            title="Undo"
          >
          </sbx-button>
        </div>

        <span class="sbx-approve-time" *ngIf="sig.signature.released_date">
          {{ sig.signature.released_date }}
        </span>

        <sbx-button
          *ngIf="sig.sign_button"
          theme="default"
          [href]="sig.workitem"
          icon="sign"
          title="Sign"
        >
        </sbx-button>
      </sbx-col>

      <sbx-col [sm]="1" [lg]="1">
        <sbx-action-menu [actions]="getActionList(sig, doc)"></sbx-action-menu>
      </sbx-col>
    </sbx-row>

    <sbx-row class="sbx-title-text-row">
      <sbx-button
        *ngIf="doc.type === 'Stockholder' && showDiscontinueButton"
        theme="default"
        (click)="handleDiscontinue()"
        icon="close"
        title="Discontinue remaining approvals and continue"
      ></sbx-button>

      <div *ngIf="doc.minutes !== null">
        This Resolution is part of
        <a [href]="doc.minutes.url">Board Meeting Minutes:</a>
      </div>

      <div *ngIf="doc.minutes === null && doc.consent === null">
        This Resolution is awaiting approval. Complete the
        <span *ngIf="doc.type === 'Board'">Meeting Minutes or Board</span>
        <span *ngIf="doc.type === 'Stockholder'">Stockholder</span>
        Consent workflow to obtain the necessary approval for this Resolution. This
        workflow cannot continue until the approval workflow is complete.
      </div>
    </sbx-row>
  </div>
</div>
