import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxStakeholderSearchComponent } from './sbx-stakeholder-search.component';
import { StakeholderSearchService } from './stakeholder-search.service';
import { SbxSearchBarModule } from '../sbx-search-bar/sbx-search-bar.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxIconTypesProvider } from '@/core/constants/icons';
import { UpgradedModule } from '../upgraded/upgraded.module';
import { SbxStakeholderCardModule } from '../sbx-stakeholder-card/sbx-stakeholder-card.module';

@NgModule({
  imports: [
    CommonModule,
    SbxSearchBarModule,
    SbxIconModule,
    SbxButtonModule,
    UpgradedModule,
    SbxStakeholderCardModule,
  ],
  declarations: [SbxStakeholderSearchComponent],
  exports: [SbxStakeholderSearchComponent],
  providers: [StakeholderSearchService, SbxIconTypesProvider],
})
export class SbxStakeholderSearchModule {}
