import angular from 'angular';

const addTemplateCache = [
  '$templateCache',
  function ($templateCache) {
    const otherModal = require('./templates/other-modal.html');
    $templateCache.put('workitem/edit-officers/templates/other-modal.html', otherModal);
  },
];

/**
 * @ngdoc overview
 * @name sb.workitem.editOfficers
 *
 * @description
 * This is the module for the officers workitem.
 */
export default angular
  .module('sb.workitem.editOfficers', [])

  .run(addTemplateCache).name;
