import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxMonitorApprovalsComponent } from './sbx-monitor-approvals.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxMonitorApprovalsComponent],
  providers: [{ provide: Window, useValue: window }],
  exports: [SbxMonitorApprovalsComponent],
})
export class SbxMonitorApprovalsWorkitemModule {
  static entryComponents = [SbxMonitorApprovalsComponent];

  static forRoot(): ModuleWithProviders<SbxMonitorApprovalsWorkitemModule> {
    return {
      ngModule: SbxMonitorApprovalsWorkitemModule,
    };
  }
}
