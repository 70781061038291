import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SbxHttpClient } from '@/core/http/';
import { Downgrade } from '@/shared/downgrade';
import {
  ISbxProcessUrlInfo,
  SbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import {
  ShareholderVoting,
  ShareholderVotingShareholders,
} from '@shoobx/types/webapi-v2';
import { SbxProcessButtonModel } from '@/shared/upgraded/process-button-model.service';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { formatNumber } from '@angular/common';

const SELECTOR = 'sbx-shareholder-workitem';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-shareholder.component.html',
  styleUrls: ['./sbx-shareholder.component.scss'],
})
export class SbxShareholderWorkitemComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('checkboxTpl') checkboxTpl: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('sharesTpl') sharesTpl: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('checkboxHeaderTpl') checkboxHeaderTpl: TemplateRef<any>;

  selectedShareholders: string[] = [];
  shareholders: ShareholderVotingShareholders[] = [];
  thresholds: { [key: string]: number } = {};
  stockClasses: string[] = [];
  columns = [];
  endpoint: string;
  public isSelectAllChecked = false;
  public isSelectAllIndeterminate = false;

  constructor(
    @Inject(SbxHttpClient) private service: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    @Inject(SbxProcessButtonModel) private processButtonModel,
    @Inject(SbxConfirmationModalService)
    private sbxConfirmationModal: SbxConfirmationModalService,
  ) {}

  ngOnInit() {
    this.processButtonModel.$addSubmitCondition(
      /continue$/,
      async () => {
        let thresholdsMet = true;
        let thresholdsWarning = '';

        Object.entries(this.thresholds).forEach((t) => {
          const name = t[0];
          const threshold = t[1];
          const selected = this.countSelectedShares(name);
          if (threshold > selected) {
            thresholdsMet = false;
            thresholdsWarning +=
              ` You selected ${formatNumber(selected, 'en_US')} shares of ` +
              `${name} shares but you need ${formatNumber(threshold, 'en_US')}.`;
          }
        });

        if (thresholdsMet) {
          return Promise.resolve();
        }
        try {
          await this.sbxConfirmationModal
            .open({
              data: {
                body: 'You didn’t meet one or more thresholds.' + thresholdsWarning,
                okButtonTitle: 'Proceed with Current Selection',
              },
            })
            .result.then(() => {
              return Promise.resolve();
            });
        } catch (e) {
          return Promise.reject();
        }
      },
      1,
    );
    this.endpoint = `workitems/${this.processUrlInfo.wiId()}/shareholder`;

    this.service
      .entity('2')
      .get(this.endpoint)
      .subscribe(
        ({
          selectedShareholders,
          shareholders,
          thresholds,
          stockClasses,
        }: ShareholderVoting) => {
          this.selectedShareholders = selectedShareholders;
          this.shareholders = shareholders;
          this.thresholds = thresholds;
          this.stockClasses = stockClasses;
          this.columns = this.setupTableColumns(stockClasses);

          this.triggerBulkSelect();
        },
      );
  }

  setupTableColumns(types: string[]) {
    const columns = [];

    columns.push(
      {
        key: 'selected',
        title: '',
        template: this.checkboxTpl,
        headerTemplate: this.checkboxHeaderTpl,
        width: '1px',
      },
      {
        key: 'name',
        title: 'Name',
      },
    );
    columns.push(
      ...types.map((c) => ({
        key: c,
        title: c,
        template: this.sharesTpl,
      })),
    );

    columns.push({
      key: 'convertedSharesStr',
      title: 'Converted shares',
    });

    return columns;
  }

  countSelectedShares(key) {
    return this.shareholders
      .filter((s) => this.selectedShareholders.includes(s.id))
      .reduce((acc, shareholder) => acc + shareholder.data[key].value, 0);
  }

  countTotalShares(key) {
    return this.shareholders.reduce(
      (acc, shareholder) => acc + shareholder.data[key].value,
      0,
    );
  }

  async handleSelectAllChange(value: boolean) {
    this.isSelectAllChecked = value;
    this.isSelectAllIndeterminate = false;

    this.selectedShareholders =
      this.shareholders.length === this.selectedShareholders.length
        ? []
        : this.shareholders.map((s) => s.id);

    await this.service
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedShareholders } })
      .toPromise();
  }

  async handleChange(id, selected) {
    this.selectedShareholders = selected
      ? [...this.selectedShareholders, id]
      : this.selectedShareholders.filter((idx) => idx !== id);

    this.triggerBulkSelect();

    await this.service
      .entity('2')
      .post(this.endpoint, { params: { data: this.selectedShareholders } })
      .toPromise();
  }

  private triggerBulkSelect(): void {
    const allOptionsLength = this.shareholders.length;
    const checkedOptionsLength = this.selectedShareholders.length;
    const noneOptionChecked = !checkedOptionsLength;
    const allOptionsChecked = checkedOptionsLength === allOptionsLength;
    const isIndeterminate = !noneOptionChecked && !allOptionsChecked;

    this.isSelectAllChecked = allOptionsChecked;
    this.isSelectAllIndeterminate = isIndeterminate;
  }
}
