export const isHrefValid = (href: string): boolean => {
  if (!isUrlValid(href) && !isPathValid(href)) {
    return false;
  }

  return true;
};

export const isPathValid = (path: string) => {
  const currentHref = document.location.href;

  try {
    return Boolean(new URL(path, currentHref));
  } catch (e) {
    return false;
  }
};

export const isUrlValid = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};
