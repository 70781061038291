import { NgModule } from '@angular/core';
import { SbxShareholderWorkitemModule } from './sbx-shareholder/sbx-shareholder.module';
import { SbxMonitorApprovalsWorkitemModule } from './sbx-monitor-approvals/sbx-monitor-approvals.module';
import { SbxTableWithStakeholderFilterWorkitemModule } from './sbx-table-with-stakeholder-filter/sbx-table-with-stakeholder-filter.module';
import { SbxWaitForSignaturesWorkitemModule } from './sbx-wait-for-signatures/sbx-wait-for-signatures.module';
import { SbxUpdateLiquidationStackWorkitemModule } from './sbx-update-liquidation-stack-workitem/sbx-update-liquidation-stack-workitem.module';
import { SbxTaxAdjustEventWorkflowButtonWorkitemModule } from './sbx-tax-adjust-event-workflow-button/sbx-tax-adjust-event-workflow-button.module';

@NgModule({
  exports: [
    SbxShareholderWorkitemModule,
    SbxMonitorApprovalsWorkitemModule,
    SbxTableWithStakeholderFilterWorkitemModule,
    SbxWaitForSignaturesWorkitemModule,
    SbxUpdateLiquidationStackWorkitemModule,
    SbxTaxAdjustEventWorkflowButtonWorkitemModule,
  ],
})
export class WorkitemModule {}
