import { $pickFromDataroomModal } from '../../../js/lib/document/pick-from';

export { $pickFromDataroomModal };

export function pickFromDataroomModalFactory(ajsInjector: any) {
  return ajsInjector.get('$pickFromDataroomModal');
}

export const pickFromDataroomModalProvider = {
  provide: $pickFromDataroomModal,
  useFactory: pickFromDataroomModalFactory,
  deps: ['$injector'],
};
