<div *ngFor="let item of field.formControl.value" class="selected-container">
  <sbx-stakeholder-base
    [model]="getItemModel(item)"
    [mode]="stakeholderBaseMode.CARD"
    [modelKey]="$any(field.key)"
    [stakeholderOptions]="field.templateOptions.stakeholderOptions"
    (removeStakeholder)="removeItem($event)"
    (blur)="handleBlur()"
  ></sbx-stakeholder-base>
</div>

<sbx-stakeholder-base
  [modelKey]="$any(field.key)"
  [stakeholderOptions]="field.templateOptions.stakeholderOptions"
  [placeholderText]="
    formControl.value.length ? 'Add another user...' : 'Enter a name...'
  "
  [mode]="stakeholderBaseMode.SEARCH"
  (selectStakeholder)="addItem($event)"
  (focus)="handleFocus($event)"
  (blur)="handleBlur()"
></sbx-stakeholder-base>
