/**
 * @ngdoc object
 * @name sb.lib.navigation.WindowLocation
 *
 * @description
 * Wrapper APIs for `$window.location`.
 *
 * These wrappers are intended to replace $window.location methods, used in
 * shoobx frontend to make them more testable. Here is the problem that prompted
 * introduction of this service.
 *
 * When test clicks on a button that performs location.reload() or
 * location.replace() or similar method, we cannot reliably detect that and
 * wait till the next page loads. There is a time gap between location.replace()
 * and moment when page starts unloading. If we check for page "readiness"
 * inside that gap, we cannot detect redirect in progress.
 *
 * The solution is to replace calls to location methods with this service,
 * that will emit appropriate window event we can intercept to mark page as
 * "load in progress".
 */
export const WindowLocation = [
  '$window',
  function ($window) {
    function dispatchRedirectEvent() {
      const ev = $window.document.createEvent('Event');
      ev.initEvent('sb-redirect', true, true);
      $window.dispatchEvent(ev);
    }

    return {
      /**
       * @ngdoc method
       * @name replace
       * @methodOf sb.lib.navigation.WindowLocation
       *
       * @description
       * Replaces the current resource with the one at the provided URL.
       *
       * @param {string} url URL to navigate to.
       */
      replace: function (url) {
        $window.location.replace(url);
        dispatchRedirectEvent();
      },

      /**
       * @ngdoc method
       * @name reload
       * @methodOf sb.lib.navigation.WindowLocation
       *
       * @description
       * Reloads the resource from the current URL.
       */
      reload: function () {
        $window.location.reload();
        dispatchRedirectEvent();
      },
    };
  },
]; // end WindowLocation
