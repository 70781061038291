import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxTaxModule } from '@/sbx-tax/sbx-tax.module';
import { SbxTaxAdjustEventWorkflowButtonComponent } from './sbx-tax-adjust-event-workflow-button.component';

@NgModule({
  imports: [CommonModule, SharedModule, SbxTaxModule],
  declarations: [SbxTaxAdjustEventWorkflowButtonComponent],
  exports: [SbxTaxAdjustEventWorkflowButtonComponent],
})
export class SbxTaxAdjustEventWorkflowButtonWorkitemModule {
  static entryComponents = [SbxTaxAdjustEventWorkflowButtonComponent];

  static forRoot(): ModuleWithProviders<SbxTaxAdjustEventWorkflowButtonWorkitemModule> {
    return {
      ngModule: SbxTaxAdjustEventWorkflowButtonWorkitemModule,
    };
  }
}
