import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxTimelineComponent } from './sbx-timeline.component';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';

@NgModule({
  imports: [CommonModule, SbxPopoverModule],
  declarations: [SbxTimelineComponent],
  exports: [SbxTimelineComponent],
})
export class SbxTimelineModule {}
