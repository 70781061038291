<div class="sbx-paging" *ngIf="pages > 1">
  <span
    [ngClass]="{ 'sbx-previous': true, 'sbx-disabled': !previous() }"
    (click)="previous() && flipPage(page - 1)"
  >
    <sbx-icon type="arrowLeftCircle"></sbx-icon>
  </span>

  <span
    [ngClass]="{ 'sbx-next': true, 'sbx-disabled': !next() }"
    (click)="next() && flipPage(page + 1)"
  >
    <sbx-icon type="arrowRightCircle"></sbx-icon>
  </span>

  <input
    type="number"
    [ngStyle]="{ width: inputWidth + 'em' }"
    [ngModel]="page + 1"
    (change)="handleInput($event)"
  />

  <span class="sbx-frac">/</span>
  <span class="sbx-total">{{ pages }}</span>
</div>
