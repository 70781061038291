<sbx-page-header title="Equity Financing Deal Room"></sbx-page-header>

<sbx-row class="sbx-mb-15">
  <ul class="nav nav-tabs sbx-full-width sbx-mt-15">
    <li [className]="showTab == 'overview' ? 'active' : ''">
      <a data-toggle="tab" href="#overview">Overview</a>
    </li>
    <li [className]="showTab == 'investors' ? 'active' : ''">
      <a data-toggle="tab" href="#investors">Investors</a>
    </li>
    <li [className]="showTab == 'documents' ? 'active' : ''">
      <a data-toggle="tab" href="#documents">Documents</a>
    </li>
  </ul>
</sbx-row>

<div class="tab-content sbx-deal-room" *sbxLoader="isLoading">
  <div
    [className]="'tab-pane' + (showTab == 'overview' ? ' active' : '')"
    id="overview"
  >
    <p>
      The following summarizes your current Equity Financing. Please reach out to the
      workflow owner, {{ parsedData.workflowOwner }}, if you have any questions.
    </p>
    <table class="sbx-doc-summary sbx-full-width">
      <tr>
        <td>
          <span class="sbx-green-text">{{
            parsedData.overview.newInvestment | currency : 'USD' : 'symbol'
          }}</span>
          <span class="sbx-gray-text">New Investment</span>
        </td>
        <td>
          <sbx-popover
            [sbxPopoverContent]="classStatusPopover"
            [sbxPopoverTemplateContext]="parsedData.overview.newStockClass.classDetails"
          >
            <span class="sbx-green-text">{{
              parsedData.overview.newStockClass.allStockClasses
            }}</span>
            <span class="sbx-gray-text">New Stock Class</span>
          </sbx-popover>
        </td>
        <td>
          <span class="sbx-green-text">
            {{ parsedData.docSummary.num_investors }}
            <sbx-icon type="user" class="sbx-gray"></sbx-icon>
          </span>
          <span class="sbx-gray-text">Investors</span>
        </td>
        <td>
          <span class="sbx-green-text">
            {{ parsedData.docSummary.num_documents }}
            <sbx-icon type="documentFilled" class="sbx-gray"></sbx-icon>
            <sbx-popover
              [sbxPopoverContent]="customizedStatusPopover"
              [sbxPopoverTemplateContext]="
                parsedData.docSummary.num_customized_documents
              "
            >
              <sbx-icon
                *ngIf="parsedData.docSummary.num_customized_documents > 0"
                type="highlighter"
                class="sbx-yellow"
              ></sbx-icon>
            </sbx-popover>
          </span>
          <span class="sbx-gray-text">Documents</span>
        </td>
      </tr>
    </table>
    <table class="sbx-doc-summary sbx-full-width">
      <tr>
        <td>
          <span class="sbx-green-text">{{
            parsedData.overview.postMoneyValuation | currency : 'USD' : 'symbol'
          }}</span>
          <span class="sbx-gray-text">Post-Money Valuation</span>
        </td>
        <td>
          <span class="sbx-green-text">{{ parsedData.overview.postMoneySIP }}%</span>
          <span class="sbx-gray-text">Post-Money Stock Incentive Pool</span>
        </td>
        <!-- <td>
          <span class="sbx-green-text">{{parsedData.overview.dilution}}%</span>
          <span class="sbx-gray-text">Dilution</span>
        </td> -->
        <td *ngIf="parsedData.overview.boardChanges.boardChanged">
          <sbx-popover
            [sbxPopoverContent]="boardStatusPopover"
            [sbxPopoverTemplateContext]="parsedData.overview.boardChanges"
          >
            <span class="sbx-green-text">
              <sbx-icon type="check" class="sbx-green"></sbx-icon>
            </span>
            <span class="sbx-gray-text">Board Changes</span>
          </sbx-popover>
        </td>
        <td *ngIf="!parsedData.overview.boardChanges.boardChanged">
          <sbx-popover [sbxPopoverContent]="noBoardChangesPopover">
            <span class="sbx-gray-large-text"> no </span>
            <span class="sbx-gray-text">Board Changes</span>
          </sbx-popover>
        </td>
      </tr>
    </table>
    <sbx-row>
      <sbx-col [lg]="6">
        <sbx-button
          title="Summary Details"
          [icon]="exportingSummary ? 'spinner' : 'download'"
          (click)="exportSummary()"
        >
        </sbx-button>
      </sbx-col>
    </sbx-row>
  </div>
  <div
    [className]="'tab-pane' + (showTab == 'investors' ? ' active' : '')"
    id="investors"
  >
    The following investors are participating in this financing. Please reach out to the
    workflow owner, {{ parsedData.workflowOwner }}, if you have any questions.
    <br />

    <h3>Investors</h3>
    <sbx-table
      [data]="parsedData.investorsData"
      [columns]="investorsColumns"
      itemPlaceholderName="investors"
    ></sbx-table>

    <h3>Conversions</h3>
    <sbx-table
      [data]="parsedData.conversionsData"
      [columns]="conversionsColumns"
      itemPlaceholderName="conversions"
    ></sbx-table>
  </div>
  <div
    [className]="'tab-pane' + (showTab == 'documents' ? ' active' : '')"
    id="documents"
  >
    <p>
      Below are the documents that have been prepared for this financing. These
      documents may change before execution or closing.
    </p>
    <p>
      We’ve called out the places your counsel has made customizations to the template
      documents or replaced the templates with an uploaded document. These are good
      places to better understand the work your legal counsel is doing on behalf of your
      company.
    </p>
    <p>
      The following versions are available for download for each document drafted in the
      Equity Financing workflow:
    </p>
    <ul>
      <li>Download – <i>the current version of the document</i></li>
      <li>
        Revision Report –
        <i>a full accounting of term and text changes made to the document</i>
      </li>
      <li>
        Document Customization Report –
        <i
          >only sections that have been modified from the original model documents
          (added, removed, or edited) are shown</i
        >
      </li>
      <!-- <li>
        Template Redline – <i>the full document with in-line change tracking that
        highlights the delta between the template and current draft</i>
      </li> -->
    </ul>
    <table class="sbx-doc-summary">
      <tr>
        <td *ngIf="parsedData.docSummary.num_uploaded > 0">
          <span class="sbx-yellow-text">{{ parsedData.docSummary.num_uploaded }}</span>
          document
          {{ parsedData.docSummary.num_uploaded == 1 ? 'template' : 'templates' }}
          replaced
        </td>
        <td *ngIf="parsedData.docSummary.num_customized_documents > 0">
          <span class="sbx-yellow-text">{{
            parsedData.docSummary.num_customized_documents
          }}</span>
          {{
            parsedData.docSummary.num_customized_documents == 1
              ? 'document'
              : 'documents'
          }}
          with custom sections
        </td>
        <td>
          <sbx-generate-customization-report [processId]="parsedData.processId">
            <sbx-icon type="download"></sbx-icon>
            Customization Report
          </sbx-generate-customization-report>
        </td>
      </tr>
    </table>
    <sbx-table
      [data]="parsedData.docSummary.docDetails"
      [columns]="documentsColumns"
      itemPlaceholderName="documents"
    ></sbx-table>
    <sbx-button
      title="Download all documents (ZIP)"
      icon="download"
      [href]="parsedData.docSummary.downloadAllDocuments"
    ></sbx-button>
  </div>
</div>

<!-- Investors tab templates -->
<ng-template #investorStatusTemplate let-item>
  <sbx-popover
    [sbxPopoverContent]="investorStatusPopover"
    [sbxPopoverTemplateContext]="item"
  >
    <sbx-icon
      *ngIf="item.released && item.signed"
      type="check"
      class="sbx-green sbx-mr"
    ></sbx-icon>
    <sbx-icon
      *ngIf="item.released && !item.signed"
      type="time"
      class="sbx-orange sbx-mr"
    ></sbx-icon>
    <sbx-icon *ngIf="!item.released" type="ellipsis" class="sbx-gray sbx-mr"></sbx-icon>
  </sbx-popover>
  {{ item.name }}
</ng-template>
<ng-template #amountTemplate let-item>
  {{ item | currency : 'USD' : 'symbol' }}
</ng-template>
<ng-template #postDealOwnershipTemplate let-item> {{ item }}% </ng-template>
<ng-template #contactTemplate let-item>
  {{ item.name }} | {{ item.email }}
</ng-template>
<ng-template #signatureBlockTemplate let-item>
  <sbx-popover
    [sbxPopoverContent]="signatureBlockPopover"
    [sbxPopoverTemplateContext]="item"
  >
    <sbx-icon type="show" class="sbx-orange"></sbx-icon>
  </sbx-popover>
</ng-template>
<ng-template #signatureBlockPopover let-item>
  <div [innerHTML]="item"></div>
</ng-template>

<!-- Documents tab templates -->
<ng-template #documentNameTemplate let-item>
  <sbx-popover
    [sbxPopoverContent]="documentStatusPopover"
    [sbxPopoverTemplateContext]="item"
  >
    <sbx-icon
      *ngIf="item.released && item.signed == item.signatures"
      type="check"
      class="sbx-green sbx-mr"
    ></sbx-icon>
    <sbx-icon
      *ngIf="item.released && item.signed != item.signatures"
      type="sign"
      class="sbx-yellow sbx-mr"
    ></sbx-icon>
    <sbx-icon *ngIf="!item.released" type="ellipsis" class="sbx-gray sbx-mr"></sbx-icon>
  </sbx-popover>
  {{ item.name }}
</ng-template>
<ng-template #customizationsTemplate let-item>
  <div *ngIf="item.uploaded">
    <sbx-icon type="highlighter" class="sbx-yellow sbx-mr"></sbx-icon>
    Document template replaced
  </div>
  <div *ngIf="item.customized > 0">
    <sbx-icon type="highlighter" class="sbx-yellow sbx-mr"></sbx-icon>
    <b>{{ item.customized }}</b> custom
    {{ item.customized == 1 ? 'section' : 'sections' }}
  </div>
</ng-template>
<ng-template #downloadTemplate let-item>
  <sbx-document-download-dropdown
    [docId]="item.id"
    [canDownload]="true"
    [canDownloadRevisionHistory]="item.showChangeHistory"
    [canDownloadCustomization]="item.showChangeHistory && item.customized"
  >
  </sbx-document-download-dropdown>
</ng-template>

<!-- Popover Templates -->
<ng-template #customizedStatusPopover let-item>
  {{ item }} {{ item == 1 ? 'document' : 'documents' }} customized
</ng-template>
<ng-template #classStatusPopover let-item class="sbx-popover">
  <div *ngFor="let c of item; let last = last">
    <span class="sbx-gray-text"> New Stock Class: <br /> </span>
    {{ c.name }}<br />
    <div *ngIf="c.authorizedShares">
      <span class="sbx-gray-text"> Number of Authorized Shares: <br /> </span>
      {{ c.authorizedShares | number }}<br />
    </div>
    <div *ngIf="c.liquidationPreference">
      <span class="sbx-gray-text"> Liquidation Preference: <br /> </span>
      <ng-container [ngSwitch]="c.liquidationPreference.preference">
        <span *ngSwitchCase="'non_participate'">Non-participating</span>
        <span *ngSwitchCase="'participate'">Fully participating</span>
        <span *ngSwitchCase="'cap'">Participating</span>
      </ng-container>
      &nbsp;at {{ c.liquidationPreference.liquidation_multiplier }}x
      <div *ngIf="c.liquidationPreference.liquidation_pref_cap_amount">
        &nbsp;with
        {{
          c.liquidationPreference.liquidation_pref_cap_amount
            | currency : 'USD' : 'symbol'
        }}
        cap
      </div>
      <br />
    </div>
    <div *ngIf="c.dilute">
      <span class="sbx-gray-text"> Anti-Dilution Provision: <br /> </span>
      {{ c.dilute.dilute_weighted_scope | titlecase }}&nbsp;
      <ng-container [ngSwitch]="c.dilute.antidilution">
        <span *ngSwitchCase="'weighted'">weighted average</span>
        <span *ngSwitchCase="'full_rachet'">full-ratchet</span>
        <span *ngSwitchCase="'not_price'">not price based</span>
      </ng-container>
      <br />
    </div>
    <hr *ngIf="!last" />
  </div>
</ng-template>
<ng-template #boardStatusPopover let-item class="sbx-popover">
  <div *ngIf="item.boardSizeChange != 0">
    <span class="sbx-gray-text"> New Board Size: </span>
    {{ item.boardSize }} {{ item.boardSize == 1 ? 'seat' : 'seats' }}
    <i>
      ({{ Math.abs(item.boardSizeChange) }}
      {{ Math.abs(item.boardSizeChange) == 1 ? 'seat' : 'seats' }}
      {{ item.boardSizeChange > 0 ? 'added' : 'removed' }})
    </i>
  </div>
  <div *ngIf="item.addedDirectorsNumber != 0">
    <span class="sbx-gray-text"> Board Members Added: </span>
    <div *ngFor="let member of item.addedDirectors">
      <br />
      <sbx-stakeholder-card
        [stakeholderId]="member"
        format="short"
      ></sbx-stakeholder-card>
    </div>
  </div>
  <div *ngIf="item.removedDirectorsNumber != 0">
    <span class="sbx-gray-text"> Board Members Removed: </span>
    <div *ngFor="let member of item.removedDirectors">
      <br />
      <sbx-stakeholder-card
        [stakeholderId]="member"
        format="short"
      ></sbx-stakeholder-card>
    </div>
  </div>
</ng-template>
<ng-template #noBoardChangesPopover>
  No changes have been made to the composition of the board as part of this financing.
</ng-template>
<ng-template #investorStatusPopover let-item>
  <div *ngIf="item.released && item.signed">Documents Signed</div>
  <div *ngIf="item.released && !item.signed">Documents Released for Signing</div>
  <div *ngIf="!item.released">Documents Unreleased for Signing</div>
</ng-template>
<ng-template #documentStatusPopover let-item>
  <div *ngIf="item.released">
    <div *ngIf="item.signed == item.signatures">Fully Signed</div>
    <div *ngIf="item.signed != item.signatures">
      {{ item.signed }}/{{ item.signatures }} signed
    </div>
  </div>
  <div *ngIf="!item.released">Unreleased</div>
</ng-template>
