import { Component, Input } from '@angular/core';
import { IMenuItem } from './models/menu-item.model';

/**
 *  Shoobx Themed Menu List
 *
 *  List of menu items. Given a maxLength, will truncate the list
 *  to meet the given length. If necessary, the last element of the list
 *  will trigger a dropdown containing the rest of the elements.
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-menulist [maxLength]=2 [menuItems]="[{iconName: 'testO', linkText: 'testLt', linkUrl: 'testLu'}]"></sbx-menulist>
 *      `,
 *    })
 *    class MyComponent {}
 */
@Component({
  selector: 'sbx-menu-list',
  templateUrl: './sbx-menu-list.component.html',
  styleUrls: ['sbx-menu-list.component.scss'],
})
export class SbxMenuListComponent {
  @Input() menuItems: IMenuItem[];
  @Input() maxLength: number;
  @Input() itemType = 'more';
}
