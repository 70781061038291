import angular from 'angular';
import Formly from 'angular-formly';
import Modal from '../modal/modal';
import ngSanitize from 'angular-sanitize';
import BSDatepickerPop from 'angular-ui-bootstrap/src/datepickerPopup';
import BSDatepicker from 'angular-ui-bootstrap/src/datepicker';
import BSTimepicker from 'angular-ui-bootstrap/src/timepicker';

import URL from '../url';
import Promise from '../promise';
import Tools from '../tools';
import formlyConfig, { TextModelOptions } from './formly-config.js';
import { $formModal, sbModalForm } from './modal.js';
import { AddressService, sbRecompileOn, sbAddressForm } from './address';
import { sbFpvBool, sbRadio, sbNoInputRadio, sbCheckbox, sbSwitch } from './bool';
import {
  DelawareCCorpService,
  sbUrlAvailabilityChecker,
  sbCcorpNamecheckForm,
  sbCcorpNamecheckPage,
  NameCheckModel,
  SimilarNamesController,
} from './ccorp-name-check';
import { sbDatePicker, sbDatePickerInput, sbTimePicker } from './datetime';
import { sbExpDateDropDown } from './datetime';
import { sbExpDate } from './exp-date';
import { ErrorCodeStrings, sbErrorTooltip } from './error';
import {
  FormSubmitResolve,
  sbBackendFeedbackController,
  sbFormFeedback,
} from './feedback';
import { sbDictionary, sbCheckList, sbCheckboxGrid } from './iterable';
import {
  ListFunctionality,
  sbList,
  sbFormsList,
  FormsListModalController,
  ListButtonFactory,
  sbListRemove,
  sbListEdit,
  sbIconDropDown,
  sbListOfStakeholder,
  sbListOfDocument,
  sbListOfStockTicker,
  sbRecordTable,
  sbDictTable,
  tableFmt,
} from './list';
import { sbFpvEmptyEmail, sbFpvEmail, sbFpvConfirm } from './text';
import { sbRecord } from './record';
import { sbTypeaheadObject, sbTypeaheadInput } from './typeahead';
import { sbRecordList, RecordListModalCtrl } from './record-list';
import { sbRadioForm } from './radio-form';
import { sbReadOnlyValue } from './read-only';
import { AscService, sbStockTickerChooser } from './asc';

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFieldLabel
 * @restrict E
 *
 * @description
 * This is a small abstraction for field labels. It does not have an isolate scope
 * and is (intentionally) tightly coupled to the structure of formly's fields.
 */
function sbFieldLabel() {
  return {
    restrict: 'E',
    template: require('./templates/field-label.html'),
  };
} // end sbFieldLabel

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbForm
 * @restrict E
 *
 * @description
 * Small abstraction for Shoobx Forms. Intended to handle any API breaks
 * since this is such a major dependency. See
 * {@link http://docs.angular-formly.com/} for full documentation.
 * This directive also applies a number of transformations to form defintions.
 * These transformations are done once `sbFormFields` becomes an array but
 * not again, like a one time binding. Essentially, the expectation is that
 * the form's defintion will not change over the lifetime of the page (after
 * initial load).
 *
 * @element ANY
 * @param {template} [sbFormName=undefined] Form name. It will be prepended with
 *    a `formly_`.
 * @param {template} [sbFormFieldPrefix=undefined] Prefix to use in fields.
 *    This is usually unneeded except in cases of "subforms" as field values
 *    (like address).
 * @param {expression} sbFormErrors Object in the form of
 *    `{ fieldKey: ['error strings'] }`.
 * @param {expression} sbFormModel Model object.
 * @param {expression} sbFormFields Fields array.
 * @param {string} [sbFormUploadUrl=undefined] A URL location for this forms uploads.
 * @param {string} [sbFormUpdateUrl=undefined] A URL location to update the form when field data changes.
 *
 * @example
   <sb-form
     sb-form-name="myForm"
     sb-form-field-prefix="fields."
     sb-form-errors="errorObj"
     sb-form-model="modelObj"
     sb-form-fields="fieldsArray">
   </sb-form>
 */
function sbForm() {
  return {
    restrict: 'E',
    // We use a template function here so that
    // bind name can be ready at compile of formly-form.
    template: function (el, attrs) {
      const attr = attrs.sbFormName ? attrs.sbFormName : '';
      const bindName = "'" + attr + "'";
      return (
        '<formly-form ng-repeat="form in $formDescriptions" ' +
        'data-bind-name="' +
        bindName +
        '" ' +
        'data-model="model" ' +
        'data-options="::options" ' +
        'data-track-by="field.key" ' +
        'data-fields="fields"></formly-form>'
      );
    },
    scope: {
      fields: '=sbFormFields',
      errors: '=sbFormErrors',
      model: '=sbFormModel',
      uploadUrl: '<?sbFormUploadUrl',
      updateUrl: '<?sbFormUpdateUrl',
      fieldPrefix: '@sbFormFieldPrefix',
    },
    controller: [
      '$scope',
      '$rootScope',
      '$attrs',
      'SimpleHTTPWrapper',
      'BackendLocation',
      function ($scope, $rootScope, $attrs, SimpleHTTPWrapper, BackendLocation) {
        if (!$scope.model) {
          $scope.model = {};
        }
        let prefix = '';
        if ($scope.fieldPrefix) {
          prefix = $scope.fieldPrefix;
        } else if ($attrs.sbFormName) {
          prefix = $attrs.sbFormName;
        }
        if ($scope.updateUrl === undefined && $scope.uploadUrl) {
          // fall back to default form update endpoint
          $scope.updateUrl = `${$scope.uploadUrl}/updateForm`;
        }

        this.fieldChange$ = new Subject();

        $rootScope.$on('sbFormField::change', (event, { value, field, scope }) => {
          // If you have two sbForm on the same page they both will get this
          // event. We're only interested in the event if field is in $scope.fields
          if ($scope.fields) {
            const sField = $scope.fields.find(({ id }) => id === field.id);
            if (sField) {
              this.fieldChange$.next({ value, field, scope });
              event.stopPropagation();
            }
          }
        });

        this.replaceForm = (definition) => {
          $scope.fields = definition.fields;
          $scope.$formDescriptions = [
            {
              model: $scope.model,
              options: $scope.options,
              fields: $scope.fields,
            },
          ];
        };

        this.updateForm = () => {
          $scope.$loading = true;

          const additionalContext = {};
          const fieldsByName = $scope.fields.reduce((acc, f) => {
            acc[f.key] = f;
            return acc;
          }, {});

          $scope.$fieldsToReload.forEach((fieldName) => {
            const field = fieldsByName[fieldName];
            if (field && field.templateOptions) {
              Object.assign(
                additionalContext,
                field.templateOptions.additionalContext || {},
              );
            }
          });

          const fieldsToReload = $scope.$fieldsToReload.splice(
            0,
            $scope.$fieldsToReload.length,
          );
          const formURL = BackendLocation.context(1) + $scope.updateUrl;
          SimpleHTTPWrapper(
            {
              method: 'POST',
              url: formURL,
              data: {
                name: $attrs.sbFormName || $scope.fieldPrefix,
                data: {
                  ...$scope.model,
                  additionalContext,
                  fieldsToReload,
                },
              },
            },
            'Could not update the form.',
          )
            .then(
              (response) => {
                $scope.model = { ...$scope.model, ...response.data };
                const newFieldsCopy = angular.copy(response.definition?.fields);

                if (
                  response.definition &&
                  !angular.equals($scope.fieldsCopy, newFieldsCopy)
                ) {
                  this.replaceForm(response.definition);
                  $scope.fieldsCopy = newFieldsCopy;
                }
              },
              // TODO: handle errors
            )
            .finally(() => {
              $scope.$loading = false;
            });
        };

        this.initFormReload = () => {
          this.fieldChange$
            .pipe(filter(({ field }) => field.templateOptions.providesFormContext))
            .takeUntilScopeDestroy($scope)
            .subscribe(({ field }) => {
              if (!$scope.$fieldsToReload.includes(field.key)) {
                $scope.$fieldsToReload.push(field.key);
                $scope.$fieldsToReload = $scope.$fieldsToReload.splice(
                  0,
                  $scope.$fieldsToReload.length,
                );
              }
            });

          $scope.$watchGroup(['$loading', '$fieldsToReload.length'], () => {
            if (!$scope.$loading && $scope.$fieldsToReload.length) {
              this.updateForm();
            }
          });
        };

        $scope.$loading = false;
        $scope.$fieldsToReload = [];

        if ($scope.updateUrl) {
          this.initFormReload();
        }

        $scope.options = {
          data: {
            uploadUrl: $scope.uploadUrl,
            updateUrl: $scope.updateUrl,
            prefix: prefix + '-',
            arrayIsIn: function (array, item) {
              if (!angular.isArray(array)) {
                return false;
              }
              return array.indexOf(item) >= 0;
            },
          },
        };

        // XXX: Temporary fix for acroform radio / checkbox group form fields creation
        $scope.fieldsCopy = $scope.fields?.some((field) => field.controller)
          ? $scope.fields
          : angular.copy($scope.fields);
        $scope.$formDescriptions = [
          {
            model: $scope.model,
            options: $scope.options,
            fields: $scope.fields,
          },
        ];

        $scope.$watch('errors', () => {
          angular.forEach($scope.fields, (field) => {
            field.data.serverErrors = undefined;
            field.data.subobjectErrors = undefined;
            if ($scope.errors) {
              const err = $scope.errors[field.key];
              if (angular.isUndefined(err) || angular.isArray(err)) {
                field.data.serverErrors = err;
              } else {
                // shoobx.app.ui.userio.validators.subobjectValidator
                // returns {field: [errors], ...}
                field.data.subobjectErrors = err;
              }
            }
          });
        });
      },
    ],
  };
} // end sbForm

/**
 * @ngdoc overview
 * @name sb.lib.form
 * @requires https://github.com/formly-js/angular-formly
 *
 * @description
 * This module houses form elements and helpers.
 */
export default angular
  .module('sb.lib.form', [
    ngSanitize,
    Formly,
    Modal,
    URL,
    Tools,
    formlyConfig,
    BSDatepickerPop,
    BSDatepicker,
    BSTimepicker,
    Promise,
  ])

  .constant('TextModelOptions', TextModelOptions)
  .controller('sbBackendFeedbackController', sbBackendFeedbackController)
  .controller('SimilarNamesController', SimilarNamesController)
  .controller('FormsListModalController', FormsListModalController)
  .controller('RecordListModalCtrl', RecordListModalCtrl)
  .component('sbReadOnlyValue', sbReadOnlyValue)
  .component('sbRadioForm', sbRadioForm)
  .component('sbTypeaheadObject', sbTypeaheadObject)
  .component('sbCheckbox', sbCheckbox)
  .component('sbSwitch', sbSwitch)
  .component('sbExpDateDropDown', sbExpDateDropDown)
  .component('sbNoInputRadio', sbNoInputRadio)
  .component('sbIconDropDown', sbIconDropDown)
  .component('sbListOfStakeholder', sbListOfStakeholder)
  .component('sbListOfStockTicker', sbListOfStockTicker)
  .component('sbListOfDocument', sbListOfDocument)
  .component('sbRecordTable', sbRecordTable)
  .component('sbDictTable', sbDictTable)
  .component('sbDictionary', sbDictionary)
  .component('sbStockTickerChooser', sbStockTickerChooser)
  .factory('$formModal', $formModal)
  .factory('AddressService', AddressService)
  .factory('AscService', AscService)
  .factory('FormSubmitResolve', FormSubmitResolve)
  .factory('ErrorCodeStrings', ErrorCodeStrings)
  .factory('ListFunctionality', ListFunctionality)
  .factory('ListButtonFactory', ListButtonFactory)
  .factory('DelawareCCorpService', DelawareCCorpService)
  .factory('NameCheckModel', NameCheckModel)
  .directive('sbModalForm', sbModalForm)
  .directive('sbRecompileOn', sbRecompileOn)
  .directive('sbAddressForm', sbAddressForm)
  .directive('sbFpvBool', sbFpvBool)
  .directive('sbRadio', sbRadio)
  .directive('sbUrlAvailabilityChecker', sbUrlAvailabilityChecker)
  .directive('sbCcorpNamecheckForm', sbCcorpNamecheckForm)
  .directive('sbCcorpNamecheckPage', sbCcorpNamecheckPage)
  .directive('sbDatePicker', sbDatePicker)
  .directive('sbDatePickerInput', sbDatePickerInput)
  .directive('sbTimePicker', sbTimePicker)
  .directive('sbExpDate', sbExpDate)
  .directive('sbErrorTooltip', sbErrorTooltip)
  .directive('sbFormFeedback', sbFormFeedback)
  .directive('sbCheckList', sbCheckList)
  .directive('sbCheckboxGrid', sbCheckboxGrid)
  .directive('sbFpvEmptyEmail', sbFpvEmptyEmail)
  .directive('sbFpvEmail', sbFpvEmail)
  .directive('sbFpvConfirm', sbFpvConfirm)
  .directive('sbList', sbList)
  .directive('sbFormsList', sbFormsList)
  .directive('sbListEdit', sbListEdit)
  .directive('sbListRemove', sbListRemove)
  .directive('sbTypeaheadInput', sbTypeaheadInput)
  .directive('sbRecord', sbRecord)
  .directive('sbRecordList', sbRecordList)
  .directive('sbFieldLabel', sbFieldLabel)
  .directive('sbForm', sbForm)
  .filter('tableFmt', tableFmt).name;
