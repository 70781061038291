import {
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ElementRef,
} from '@angular/core';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject,
  Observable,
  of,
  merge,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { Options } from '@popperjs/core';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { NgbContainerConfig } from '../sbx-form/fields/ngb-container.config';

export class SbxSelectOption {
  label: string;
  value: any;

  constructor(label, value) {
    this.label = label;
    this.value = value;
  }
}

@Component({
  selector: 'sbx-select',
  templateUrl: './sbx-select.component.html',
  styleUrls: ['./sbx-select.component.scss'],
  providers: [
    {
      provide: NgbTypeaheadConfig,
      useFactory: (ngbContainer: NgbContainerConfig) => {
        const config = new NgbTypeaheadConfig();

        if (!ngbContainer) {
          return config;
        }

        config.container = ngbContainer.container;
        config.popperOptions = ngbContainer.popperOptions;

        return config;
      },
      deps: [NGB_CONTAINER_TOKEN],
    },
  ],
})
export class SbxSelectComponent implements OnInit {
  @Input() model: string | number;
  @Input() name: string;
  @Input() selectOptions: SbxSelectOption[];
  @Input() asyncDataSource: (text: string) => Observable<any[]>;
  @Input() initialOption: SbxSelectOption;
  @Input() resultTemplate: any = undefined;
  @Input() placeholderText = '';
  @Input() disableAutoComplete = true;
  @Input() disabled = false;
  @Input() clearOnEnter = true;
  @Input() customTypeaheadWindowClass = '';
  @Output() focus = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() input = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  initialValue = '';
  public readonly container: undefined | 'body';
  public readonly popperOptions: (options: Partial<Options>) => Partial<Options>;

  @ViewChild('instance') ngbTypeahead: NgbTypeahead;
  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;
  focus$ = new Subject<FocusEvent>();
  click$ = new Subject<MouseEvent>();
  searching = false;
  @Input() formatter = (result: SbxSelectOption) => result.label;
  @Input() parser = (result: SbxSelectOption) => result.value;

  public constructor(typeaheadConfig: NgbTypeaheadConfig) {
    this.container = typeaheadConfig.container;
    this.popperOptions = typeaheadConfig.popperOptions;
  }

  ngOnInit() {
    if (this.selectOptions) {
      this.formatter = (result: SbxSelectOption) => {
        // Handle results chosen by picker
        if (result instanceof Object) {
          return result.label;
        }
        const option = this.selectOptions.find((e) => e.value === result);
        return option ? option.label : '';
      };
    }

    if (this.initialOption) {
      this.initialValue = this.formatter(this.initialOption);
    }

    // XXX For backward compatibility we will just filter out unused option 'Select Options' here,
    // instead of changing the backend. Once all select-widget are using the sbx-enum-dropdown, then
    // we can change the backend and remove this filter here.
    if (this.selectOptions) {
      this.selectOptions = this.selectOptions.filter((v) => v.value !== '--NOVALUE--');
    }

    this.focus$.subscribe((event) => {
      this.focus.emit(event);
    });
  }

  handleKeyDown(key) {
    if (key.code === 'ArrowDown' && !this.ngbTypeahead.isPopupOpen()) {
      this.inputElement.nativeElement.focus();
    }
  }

  dismissPopup() {
    return this.ngbTypeahead.dismissPopup();
  }

  isPopupOpen() {
    return this.ngbTypeahead.isPopupOpen();
  }

  selectItemHandler(item) {
    if (!this.clearOnEnter) {
      item.preventDefault();
    }
    this.selectItem.emit(this.parser(item.item));
  }

  clear() {
    this.inputElement.nativeElement.value = '';
    this.inputElement.nativeElement.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
      }),
    );
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.ngbTypeahead.isPopupOpen()),
    );

    if (this.selectOptions) {
      const shouldShowAllOptions = (term) => !term || !this.ngbTypeahead.isPopupOpen();
      const filteredOptions = (term, options) => {
        return options.filter(
          (v) => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1,
        );
      };

      // if widget is select, we want to show all options on inputFocus. Then
      // we will show search result once there's user text inputs.
      // maybe only show all if it's less than 10, otherwise show a scrollbar?
      return merge<any>(clicksWithClosedPopup$, this.focus$, debouncedText$).pipe(
        map((event) => {
          const term = event instanceof Object ? event.target.value : event;
          const res = shouldShowAllOptions(term)
            ? this.selectOptions
            : filteredOptions(term, this.selectOptions).slice(0, 10);
          return res.length > 0 ? res : [null];
        }),
      );
    }

    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        return null;
      }),
      switchMap((term) => {
        return term ? this.asyncDataSource(term) : of([]);
      }),
      tap(() => {
        this.searching = false;
        return null;
      }),
    );
  };
}
