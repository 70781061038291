import angular from 'angular';

function safeWhen($q, fn = angular.noop, ...args) {
  return $q.when(fn(...args));
}

const FormModalCtrl = [
  '$scope',
  '$q',
  '$exceptionHandler',
  'Options',
  'PromiseErrorCatcher',
  function ($scope, $q, $exceptionHandler, Options, PromiseErrorCatcher) {
    function submit() {
      const { formModalMainForm } = $scope;
      angular.forEach($scope.model.$forms, (form, formName) => {
        const subForm = formModalMainForm[`formly_${formName}`];
        if (subForm) {
          subForm.$setSubmitted(true);
        }
      });
      this.error = null;
      this.nonFieldErrors = [];
      $scope.$loading = () => true;
      $scope.formModalMainFormFeedback
        .submittingForm()
        .then((done) =>
          safeWhen($q, Options.onConfirmPromise, $scope.model).catch((error) => {
            done.resolve();
            return $q.reject(error);
          }),
        )
        .then(
          (successData) => {
            $scope.$loading = () => false;
            this.$close(successData);
          },
          (error) => {
            $scope.$loading = () => false;
            if (error instanceof Error) {
              $exceptionHandler(error);
              return;
            }
            if (angular.isObject(error)) {
              // Field level errors on the form
              $scope.model.$formErrors = error;
              // Form level errors not associated with a specific field
              for (const formError of Object.values(error)) {
                if (!formError.__form__) {
                  continue;
                }
                for (const errorMsg of formError.__form__) {
                  this.nonFieldErrors.push(errorMsg);
                }
              }
            }
            if (angular.isString(error)) {
              this.error = error;
            } else {
              this.error = 'Please fix the errors below.';
            }

            const modal = document.querySelector('.modal-content');
            if (modal) {
              modal.scrollIntoView();
            }
          },
        );
    }
    this.submit = submit.bind(this);
    $scope.model = { $formErrors: {} };

    const setFromOptions = (opts = {}) => {
      this.title = this.title || opts.title;
      this.descriptionText = this.descriptionText || opts.descriptionText;
      this.htmlContent = this.htmlContent || opts.htmlContent;
      this.actions = this.actions || opts.extraActions;
      this.primaryButtonText = this.primaryButtonText || opts.primaryButtonText;
      $scope.$loading = $scope.$loading || opts.loading;
      $scope.model.$formData = $scope.model.$formData || opts.formData;
      $scope.model.$forms = $scope.model.$forms || opts.forms;
      $scope.extraInfo = $scope.extraInfo || opts.extraInfo;
    };
    setFromOptions(Options);
    safeWhen($q, Options.onOpenPromise).then(setFromOptions).catch(PromiseErrorCatcher);
  },
]; // end FormModalCtrl

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.form.object:$formModal
 *
 * @description
 * This is a easy way to get a modal with a basic form template (standard workitem
 * template namespace).
 *
 * @param {object|function} options This is expected to be a function that returns
 *   a promise with object options **OR** can just be the options directly. This
 *   object has the following optional properties. **NOTE**: Most of these
 *   properties can only be set once either at init or in the resolution of
 *   `onOpenPromise` (see below).
 *   @property {string} title Title for the modal.
 *   @property {string} descriptionText Description of the modal.
 *   @property {string} htmlContent The html string of the modal body (will be
 *     angular compiled).
 *   @property {object} [forms=undefined] Expected to an object of
 *     { <formName>: <IO description of form>,... }. This will be available in the
 *     template standard location of `model.$forms`.
 *   @property {object} [formData=undefined] Expected to an object of
 *     { <formName>: <IO Data>,... }. This will be available in the
 *     template standard location of `model.$formData`.
 *   @property {function} [loading=undefined] This is expected to be a function
 *     that when evaluated, returns a boolean indicating that there is an
 *     outstanding async/loading action. This will effectively disable the controls
 *     in the modal. It is also available as `$loading()` on scope for use in
 *     `htmlContent`.
 *   @property {object} [extraInfo=undefined] This is extra information to be provided
 *     to the template
 *   @property {string} [primaryButtonText='Save'] Allows overwrite of primary
 *     button text
 *   @property {object} [defaultModel=undefined'] Allows the caller to supply a default
 *     object this object will be combined with whatever is returned from the api.
 *   @property {function} [extraActions=undefined] This function will be evaluted in
 *     the template (meaning in $digest cycle). It is handed `formModel`, `$close`,
 *     and `$dismiss` parameters. It is expected to return an array of: **NOTE**:
 *     You must be careful to return the same array over and over to avoid infinite
 *     loops (remember Angular dirty checks and your function might create new data
 *     structures over and over).
 *       @property {string} id Unique ID for the button (track by).
 *       @property {function} method Function callback for click.
 *       @property {string} text Button text content.
 *       @property {string} [type='info'] btn-{{ type }} for button.
 *   @property {function} [onOpenPromise=undefined] This is a function that will be
 *     evaluated *after* the modal is already opened. You may return a promise that
 *     resolves with options you have not already set previously.
 *   @property {function} [onConfirmPromise=undefined] This is a function that will be
 *     evaluated when confirm is clicked. You may return a promise that
 *     resolves with the modal's successData (see @returns below) or can reject with
 *     an error.
 * @param {string} [size='sm'] Size of the modal.
 * @param {string} [windowClass='form-modal'] Class to add to modal.
 *
 * @returns {Promise} Resolves when the confirm dialog is successfully closed
 *    and is rejected when successfully dismissed.
 */

export const $formModal = [
  '$sbModal',
  function ($sbModal) {
    return (options = {}, size, windowClass) =>
      $sbModal.open({
        backdrop: 'static',
        keyboard: options.keyboard,
        template: require('./templates/form-modal.html'),
        bindToController: true,
        controllerAs: 'vm',
        windowClass: windowClass || 'form-modal',
        size,
        resolve: {
          Options: angular.isFunction(options) ? options : () => options,
        },
        controller: FormModalCtrl,
      }).result;
  },
]; // end $formModal

export function sbModalForm() {
  return {
    restrict: 'A',
    scope: {
      keyboard: '<?sbModalKeyboard',
      endpoint: '@sbModalForm',
      title: '@?sbModalFormTitle',
      descriptionText: '@?sbModalFormDescriptionText',
      primaryButtonText: '@?sbModalFormPrimaryButtonText',
      defaultModel: '<?sbModalDefaultModel',
      defaultData: '=?sbModalFormDefaults',
      onPostSuccess: '&?sbModalFormOnPostSuccess',
      onPostFailure: '&?sbModalFormOnPostFailure',
    },
    controller: [
      '$scope',
      '$element',
      '$formModal',
      '$q',
      'SimpleHTTPWrapper',
      'PromiseErrorCatcher',
      function (
        $scope,
        $element,
        $formModal,
        $q,
        SimpleHTTPWrapper,
        PromiseErrorCatcher,
      ) {
        function openModal() {
          let loading = true;
          $formModal({
            htmlContent: require('./templates/modal-form-content.html'),
            size: 'lg',
            keyboard: Boolean($scope.keyboard),
            title: $scope.title,
            descriptionText: $scope.descriptionText,
            primaryButtonText: $scope.primaryButtonText,
            loading: () => loading, // turn loading to true until onOpenPromise comes back
            onConfirmPromise: (model) =>
              SimpleHTTPWrapper({
                url: $scope.endpoint,
                method: 'POST',
                data: model.$formData.modalFormName,
              })
                .then(($successData) => {
                  if ($scope.onPostSuccess) {
                    $scope.onPostSuccess({ $successData });
                  }
                })
                .catch(($failData) => {
                  if ($scope.onPostFailure) {
                    $scope.onPostFailure({ $failData });
                  }
                  return $q.reject({ modalFormName: $failData });
                }),
            // XXX Since we are using GET, there might be data here
            // that is not porperly serialized.
            onOpenPromise: () =>
              SimpleHTTPWrapper({
                url: $scope.endpoint,
                params: $scope.defaultData,
              }).then((formData) => {
                loading = false;
                const model = angular.extend(
                  {},
                  $scope.defaultModel || {},
                  formData.model,
                );
                return {
                  forms: { modalFormName: formData.description },
                  formData: { modalFormName: model },
                  title: $scope.title || formData.title,
                  descriptionText: $scope.descriptionText || formData.descriptionText,
                };
              }),
          }).catch(PromiseErrorCatcher);
        }
        $element.click(() => {
          if (!$scope.endpoint) {
            return;
          }
          $scope.$apply(openModal);
        });
      },
    ],
  };
} // end sbModalForm
