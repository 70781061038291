import { Map } from 'immutable';
import { of } from 'rxjs';
import { bufferCount, concatMap, first, map, scan, startWith } from 'rxjs/operators';

/**
 * @ngdoc service
 * @name sb.workitem.grantConstraintsWorkitem.service:ConstraintService
 *
 * @description
 *
 * Service for requesting grant constraint reports.
 *
 * The current webapi endpoint generates reports based on a set of
 * pending grants, usually associated with an in-flight process such as
 * grant_plan_shares.
 *
 * Calculating constraints requires captable-level of work and is very
 * slow. The current workaround is to request batches of grant calculations
 * serially.
 *
 */
export const ConstraintService = [
  '$observable',
  '$httpParamSerializer',
  'BackendLocation',
  function ($observable, $httpParamSerializer, BackendLocation) {
    const baseUrl = () => `${BackendLocation.context(1)}constraints`;
    return {
      /**
       * @ngdoc method
       *
       * @name $getEntityReport
       * @methodOf sb.lib.document.service:ConstraintService
       * @description
       *
       * Return {observable<OrderedMap<object>} A stream of the currently
       * completed constraint reports
       *
       * @param {string} entityId to request constraint reports.
       */
      $getEntityReport: function ({ processId }) {
        const params = $httpParamSerializer({ processId });
        return $observable
          .sbAjax({
            url: `${baseUrl()}/entity?${params}`,
          })
          .pipe(
            map((resp) => resp?.response),
            first(),
          );
      },
      /**
       * @ngdoc method
       *
       * @name $getGrantReport
       * @methodOf sb.lib.document.service:ConstraintService
       * @description
       *
       * Return {observable<OrderedMap<object>} A stream of the currently
       * completed constraint reports
       *
       * Serially requests batches
       *
       * @param {Array<string>} grantIds to request cosntraint reports.
       */
      $getGrantReport: function (grantIds) {
        const pendingObj = (title) => new Map({ loading: true, title });
        const initGrants = new Map(
          grantIds.map((id) => [id, pendingObj('Fetching...')]),
        );
        const mkRequest = (ids) => {
          const params = $httpParamSerializer({ grantIds: ids });
          const url = `${baseUrl()}/grants?${params}`;
          return $observable.sbAjax({ url }).pipe(
            map((resp) => resp?.response),
            first(),
          );
        };
        return of(...grantIds).pipe(
          bufferCount(10),
          concatMap(mkRequest),
          scan((a, b) => a.merge(b), initGrants),
          startWith(initGrants),
        );
      },

      $getGrantByGranteeReport: function (grantsByGrantee) {
        const pendingObj = (title) => new Map({ loading: true, title });
        const initGrants = new Map(
          Object.entries(grantsByGrantee)
            .map((g) => g[1])
            .flat()
            .map((id) => [id, pendingObj('Fetching...')]),
        );
        const mkRequest = (data) => {
          const grants = data.map((d) => d[1]).flat();
          const params = $httpParamSerializer({ grantIds: grants });
          const url = `${baseUrl()}/grants?${params}`;
          return $observable.sbAjax({ url }).pipe(
            map((resp) => resp?.response),
            first(),
          );
        };
        return of(...Object.entries(grantsByGrantee)).pipe(
          bufferCount(8), // probably could be 10 but on the occassion someone gets 2 grants
          concatMap(mkRequest),
          scan((a, b) => a.merge(b), initGrants),
          startWith(initGrants),
        );
      },
    };
  },
]; // end ConstraintService
