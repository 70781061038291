<ng-container *ngIf="menuItem">
  <a [sbxRouterLink]="menuItem.linkUrl" [target]="menuItem.linkTarget || '_self'">
    <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="menuItems">
  <sbx-dropdown [dropDownTemplate]="extraItemDropdown" [dropDownWidth]="'100%'">
    <div class="sbx-menu-item-content">
      <span class="sbx-menu-item-text"
        >{{ menuItems.length }} {{ overflowName | uppercase }}</span
      >
      <span class="sbx-caret-styling caret"></span>
    </div>
  </sbx-dropdown>
</ng-container>

<ng-template #menuItemContent>
  <span class="sbx-menu-item-content" [ngClass]="[menuItem.linkStyle || '']">
    <span class="sbx-menu-item-text">{{ menuItem.linkText }}</span>
    <span class="sbx-icon-wrapper fa-fw">
      <sbx-icon type="chevronRight"></sbx-icon>
    </span>
  </span>
</ng-template>

<ng-template #extraItemDropdown>
  <div class="sbx-menu-dropdown-menu">
    <div
      class="sbx-menu-item-menu-list-wrapper"
      [style.paddingLeft.px]="menuItemDropdownPadding"
      [style.paddingRight.px]="menuItemDropdownPadding"
    >
      <sbx-menu-list [menuItems]="menuItems"></sbx-menu-list>
    </div>
  </div>
</ng-template>
