<sbx-draggable-list
  [items]="exhibits"
  [itemTemplate]="exhibit_item"
></sbx-draggable-list>
<sbx-button title="Add Exhibit" (click)="addExhibit()" icon="plus"> Add </sbx-button>

<ng-template #exhibit_item let-exhibit="item">
  <div class="sbx-exhibit-item">
    <div class="sbx-exhibit-item-column">
      <span class="exhibit-list-index">Exhibit {{ exhibit.referenceId }}</span>
      <span class="title">
        <sbx-icon class="sbx-exhibit-title" type="paperclip"></sbx-icon
        >{{ exhibit.referenceTitle }}</span
      >
    </div>
    <div class="sbx-exhibit-item-buttons">
      <!-- TODO: dropdown for multiple documents *ngFor='let doc of exhibit.included_documents'-->
      <sbx-icon
        (click)="navigateToUrl(exhibit.referenceDoc.downloadURL)"
        type="download"
      >
      </sbx-icon>
      <sbx-icon (click)="editExhibit(exhibit)" type="edit"> </sbx-icon>
      <sbx-icon (click)="deleteExhibit(exhibit)" type="delete"> </sbx-icon>
    </div>
  </div>
</ng-template>
