<sbx-dropzone
  [model]="model"
  [canEdit]="field.templateOptions.canEdit"
  [readOnly]="field.templateOptions.readOnly"
  [canDelete]="field.templateOptions.canDelete"
  [uploadUrl]="field.templateOptions.uploadURL"
  [apiVersion]="field.templateOptions.apiVersion ?? 'pie_1'"
  [key]="$any(field.key)"
  [multiple]="field.templateOptions.multiple"
  (handleDropzoneChange)="handleDropzoneChange($event)"
></sbx-dropzone>
