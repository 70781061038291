import { Component } from '@angular/core';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';

@Component({
  selector: 'sbx-confirmation-modal',
  templateUrl: './sbx-confirmation-modal.component.html',
  styleUrls: ['./sbx-confirmation-modal.component.scss'],
})
export class SbxConfirmationModalComponent {
  title = '';
  body = '';
  cancelButtonTitle = 'Cancel';
  okButtonTitle = 'Continue';

  constructor(public modalService: SbxModalService) {}
}
