import angular from 'angular';
import { List, Record } from 'immutable';

function regexEscape(str) {
  // Copied from lodash source. This is not in the JS standard. :(
  return str.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
}
function highlight(label, currentQuery) {
  const regex = new RegExp(`(${regexEscape(currentQuery)})`, 'gi');
  return label.replace(regex, '<strong>$1</strong>');
}
function isMatchFunc(query) {
  query = query.trim().toLowerCase();
  return ({ label }) => label.toLowerCase().includes(query);
}

/**
 * @ngdoc component
 * @name sb.lib.employee.component:sbDepartmentChooser
 *
 * @description
 * Standard widget to allow selecton of employee.
 *
 * @param {expression} ngModel Model to bind value to.
 * @param {array} options Array of object selections:
 *   @param {string} value Unique ID/model value.
 *   @param {string} label UI label.
 * @param {boolean} [isTypeahead=false] User selects with typeahead or dropdown.
 */
export const sbDepartmentChooser = {
  template: require('./templates/department-chooser.html'),
  controllerAs: 'vm',
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    departmentOptions: '<options',
    isTypeahead: '<?',
  },
  controller: [
    '$scope',
    function ($scope) {
      function findDepartment(query) {
        const filtered = this.internalOptions.filter(isMatchFunc(query));
        if (filtered.length) {
          return filtered.map(({ value, label }) => ({
            value,
            label,
            html: highlight(label, query),
          }));
        }
        return [];
      }
      this.$onInit = () => {
        this.findDepartment = findDepartment.bind(this);
        this.selectDepartment = ({ label }) => label;

        $scope.$watch(
          () => this.departmentOptions,
          (newvalue) => {
            newvalue = newvalue || [];
            const currentValue = this.model;
            const currentDept = newvalue.find(({ value }) => value === currentValue);
            if (!currentValue || currentDept) {
              // currentDept is in the available options
              this.internalOptions = newvalue;
              return;
            }
            // add currentValue because it needs to show up
            this.internalOptions = [
              { value: currentValue, label: currentValue },
            ].concat(newvalue);
          },
        );
      };
    },
  ],
}; // end sbDepartmentChooser

/**
 * @ngdoc object
 * @name sb.lib.employee.object:DepartmentAdminModel
 *
 * @description
 * This model object is for department administration.
 */
export const DepartmentsAdminModel = [
  '$q',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, BackendLocation, SimpleHTTPWrapper) {
    const Department = new Record({
      title: undefined,
      path: undefined,
      level: 0,
    });

    class Model {
      constructor() {
        this._url = BackendLocation.entity(1) + 'departments';

        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.lib.employee.DepartmentsAdminModel
         *
         * @description
         * Boolean describing if the async is outstanding.
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name initialized
         * @propertyOf sb.lib.employee.DepartmentsAdminModel
         *
         * @description
         * Boolean describing if the model has been initialized (starts
         * as `undefined`).
         */
        this.initialized = undefined;

        /**
         * @ngdoc property
         * @name mdForm
         * @propertyOf sb.lib.employee.DepartmentsAdminModel
         *
         * @description
         * Mutable form model object.
         */
        this.mdForm = {};

        /**
         * @ngdoc property
         * @name departments
         * @propertyOf sb.lib.employee.DepartmentsAdminModel
         *
         * @description
         * Immutable departments model.
         *   @property {string} path Unique (path) ID of department.
         *   @property {string} title Human readable title (leaf name).
         *   @property {number} level Depth of department node.
         */
        this.departments = List();

        /**
         * @ngdoc property
         * @name error
         * @propertyOf sb.lib.employee.DepartmentsAdminModel
         *
         * @description
         * Error from model actions (excluding add/edit).
         */
        this.error = null;
      }

      _load(prom, setError) {
        this.loading = true;
        return prom
          .then((data) => {
            this.departments = List(data.departments.map(Department));
            this.mdForm = data.md;
          })
          .catch((err) => {
            if (setError) {
              this.error = err;
            }
            return $q.reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.lib.employee.DepartmentsAdminModel
       *
       * @description
       * Init the model and fetch the state.
       *
       * @returns {promise} Promise that resolves/rejects on success/failure.
       */
      init() {
        const prom = SimpleHTTPWrapper(
          {
            url: this._url,
          },
          'Could not fetch departments.',
        );
        return this._load(prom, true).finally(() => {
          this.initialized = true;
        });
      }

      /**
       * @ngdoc method
       * @name removeDepartment
       * @methodOf sb.lib.employee.DepartmentsAdminModel
       *
       * @description Remove a department.
       *
       * @param {number} index Location of the department by index.
       *
       * @returns {promise} Promise that resolves/rejects on success/failure.
       */
      removeDepartment(index) {
        const prom = SimpleHTTPWrapper({
          url: this._url + '/remove',
          method: 'POST',
          data: { path: this.departments.get(index, {}).path },
        });
        return this._load(prom, true);
      }

      /**
       * @ngdoc method
       * @name editDepartment
       * @methodOf sb.lib.employee.DepartmentsAdminModel
       *
       * @description Edit a department.
       *
       * @param {string} title Title of the department.
       * @param {number} index Location of the department by index.
       *
       * @returns {promise} Promise that resolves/rejects on success/failure.
       */
      editDepartment(title, index) {
        const prom = SimpleHTTPWrapper({
          method: 'POST',
          url: this._url + '/save',
          data: { title, path: this.departments.get(index, {}).path },
        });
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name addDepartment
       * @methodOf sb.lib.employee.DepartmentsAdminModel
       *
       * @description Add a department.
       *
       * @param {string} title Title of the department.
       * @param {number} [index=undefined] Location of the department by index
       *   or `undefined` for no parent (root).
       *
       * @returns {promise} Promise that resolves/rejects on success/failure.
       */
      addDepartment(title, index) {
        const parent = angular.isNumber(index)
          ? this.departments.get(index).path
          : undefined;
        const prom = SimpleHTTPWrapper({
          method: 'POST',
          url: this._url + '/save',
          data: { title, parent },
        });
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name saveForm
       * @methodOf sb.lib.employee.DepartmentsAdminModel
       *
       * @description Save the metadata.
       *
       * @returns {promise} Promise that resolves/rejects on success/failure.
       */
      saveForm() {
        const prom = SimpleHTTPWrapper(
          {
            url: this._url + '/save',
            method: 'POST',
            data: {
              md: this.mdForm,
            },
          },
          'Could not save departments.',
        );
        return this._load(prom, true);
      }
    }
    return () => new Model();
  },
]; // end DepartmentsAdminModel

/**
 * @ngdoc component
 * @name sb.lib.employee.component:sbDepartmentsListManager
 *
 * @description
 * Widget shows a list of departments with add/edit/remove functions.
 *
 * @param {List} departments Listing model.
 * @param {boolean} [ngDisabled=false] When truthy, disable actions.
 * @param {expression} [onAdd=undefined] Expression to eval on add. `$index` will
 *   be in the namespace and represents the index of the parent (`null` for no
 *   parent).
 * @param {expression} [onEdit=undefined] Expression to eval on edit. `$index` will
 *   be in the namespace and represents the index of the editing department.
 * @param {expression} [onRemove=undefined] Expression to eval on remove. `$index`
 *   will be in the namespace and represents the index of the removing department.
 */
export const sbDepartmentsListManager = {
  template: require('./templates/departments-list-manager.html'),
  controllerAs: 'vm',
  bindings: {
    departments: '<',
    disabled: '<?ngDisabled',
    onAdd: '&?',
    onEdit: '&?',
    onRemove: '&?',
  },
}; // end sbDepartmentsListManager

/**
 * @ngdoc component
 * @name sb.lib.employee.component:sbDepartmentsAdmin
 *
 * @description
 * Admin screen for managing departments.
 */
const ADMIN_FORM_FIELDS = () =>
  Object.freeze([
    {
      type: 'bool-checkbox',
      data: {},
      key: 'enabled',
      templateOptions: {
        required: true,
        label: 'Enable Department Management',
      },
    },
  ]);
const MODAL_FORM_FIELDS = () =>
  Object.freeze([
    {
      type: 'string-textline',
      data: {},
      key: 'title',
      templateOptions: {
        required: true,
        label: 'Title',
      },
    },
  ]);
export const sbDepartmentsAdmin = {
  template: require('./templates/departments-admin.html'),
  controllerAs: 'vm',
  controller: [
    '$scope',
    '$element',
    '$q',
    '$formModal',
    '$confirm',
    'DepartmentsAdminModel',
    'PromiseErrorCatcher',
    function (
      $scope,
      $element,
      $q,
      $formModal,
      $confirm,
      DepartmentsAdminModel,
      PromiseErrorCatcher,
    ) {
      const modelIsLoading = () => this.model.loading;
      function openModal(onConfirmPromise, title, formData) {
        return $formModal({
          onConfirmPromise,
          title,
          loading: modelIsLoading,
          model: this.model,
          htmlContent: require('./templates/department-addedit-modal.html'),
          formData: { departmentForm: formData },
          forms: { departmentForm: { fields: MODAL_FORM_FIELDS() } },
        });
      }
      function editDepartment(index) {
        const callback = ({ $formData }) => {
          const { title } = $formData.departmentForm;
          return this.model.editDepartment(title, index);
        };
        const department = this.model.departments.get(index);
        const { title } = department;
        openModal
          .call(this, callback, 'Edit ' + title, { title })
          .catch(PromiseErrorCatcher);
      }
      function addDepartment(index) {
        const callback = ({ $formData }) => {
          const { title } = $formData.departmentForm;
          return this.model.addDepartment(title, index);
        };
        let title;
        if (angular.isNumber(index)) {
          const department = this.model.departments.get(index);
          title = 'Add Sub Department of ' + department.title;
        } else {
          title = 'Add New Department';
        }
        openModal.call(this, callback, title, { title: '' }).catch(PromiseErrorCatcher);
      }
      function removeDepartment(index) {
        $confirm({
          body: `
          Are you sure you want to remove
          ${this.model.departments.get(index, {}).title} ?
          <br>
          <br>
          Warning: this is going to remove all subdepartments too!
        `,
          alertType: 'warning',
          confirmButtonText: 'Yes',
          dismissButtonText: 'No',
        })
          .then(() => this.model.removeDepartment(index))
          .catch((error) => {
            if (error) {
              this.model.error = error;
            } else {
              PromiseErrorCatcher(error);
            }
          });
      }
      this.$onInit = () => {
        this.editDepartment = editDepartment.bind(this);
        this.addDepartment = addDepartment.bind(this);
        this.removeDepartment = removeDepartment.bind(this);
        this.model = DepartmentsAdminModel();
        this.MD_FORM_FIELDS = ADMIN_FORM_FIELDS();
      };

      this.$postLink = () => {
        this.model.init().then(() => {
          const enabledCheckFieldData = this.MD_FORM_FIELDS[0].data;
          $scope.$watch(modelIsLoading, (nv) => {
            enabledCheckFieldData.disabledBoolean = nv;
          });
          $scope.$watch(
            () => this.model.mdForm.enabled,
            (nv, ov) => {
              if (nv === ov) {
                return;
              }
              this.model.saveForm();
            },
          );
        });
      };
    },
  ],
}; // end sbDepartmentsAdmin
