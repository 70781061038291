import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-document-reference-form',
  templateUrl: './sbx-document-reference-form.component.html',
})
export class SbxDocumentReferenceFormComponent extends FieldType<FieldTypeConfig> {
  handleChange(event: Event) {
    this.formControl.setValue(event);
  }
}
