import { List, Record } from 'immutable';

/**
 * @ngdoc object
 * @kind function
 * @name sb.process.$workitemRecord
 *
 * @description
 * Use this function to instance a Workitem record from an object.
 *
 * @param {object} obj To make the workitem record from.
 *
 * @returns {Record} Immutable workitem record with immutable wait info records
 *   internally. See the Record type below for properties.
 */
export function $workitemRecord() {
  function makeInfo(obj) {
    const { subInfo } = obj;
    return (
      obj &&
      new wiWaitInfoRecord(
        Object.assign({}, obj, {
          subInfo: makeInfos(subInfo),
        })
      )
    );
  }
  function makeInfos(list) {
    list = list || [];
    return List(list.map(makeInfo));
  }
  const wiWaitInfoRecord = Record({
    title: undefined,
    finishedDate: undefined,
    modifiedDate: undefined,
    completed: false,
    blockingStakeholder: undefined,
    subInfo: List(),
    isDocument: false,
    isSignature: false,
  });
  const wiRecord = Record({
    id: undefined,
    type: undefined,
    title: undefined,
    performers: List(),
    requestorId: undefined,
    waitInfo: undefined,
    canRemind: false,
    doable: false,
    claimable: false,
    url: undefined,
  });
  return (obj) => {
    obj = obj || {};
    const { waitInfo, performers } = obj;
    const instance = Object.assign({}, obj, {
      waitInfo: makeInfos(waitInfo),
      performers: List(performers),
    });
    return new wiRecord(instance);
  };
} // end $workitemRecord
