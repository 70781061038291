import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ISbxWiPanelItem, ISbxWiPanelClickEvent } from './sbx-wi-panel-group.component';

@Component({
  selector: 'sbx-wi-panel',
  templateUrl: './sbx-wi-panel.component.html',
  styleUrls: ['./sbx-wi-panel.component.scss'],
})
export class SbxWiPanelComponent {
  @Input() item: ISbxWiPanelItem;
  @Output() buttonClick = new EventEmitter<ISbxWiPanelClickEvent>();

  emitButtonClick(buttonId: string) {
    this.buttonClick.emit({
      buttonId: buttonId,
      data: this.item.data,
    });
  }
}
