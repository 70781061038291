import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

interface NavigationModel {
  readonly routerLink: string[];
  readonly queryParams?: Params;
  readonly fragment?: string;
}

@Injectable({ providedIn: 'root' })
export class SbxAngularRoutingService {
  public constructor(@Inject(APP_BASE_HREF) private readonly baseHref: string) {}

  public mapUrlToNavigationModel(url: URL): NavigationModel {
    const routerLink = this.getRouterLink(url);
    const queryParams = this.getQueryParams(url);
    const fragment = this.getFragment(url);

    return { routerLink, queryParams, fragment };
  }

  private getRouterLink(url: URL): string[] {
    const path = url.pathname;
    const pathWithoutBaseHref = path.replace(this.baseHref, '');

    return pathWithoutBaseHref.split('/');
  }

  private getQueryParams(url: URL): Params {
    const searchParams: URLSearchParams = new URL(url).searchParams;
    const queryParams = Object.fromEntries(searchParams);

    return queryParams;
  }

  private getFragment(url: URL): string | undefined {
    const fragmentWithPrefix = url.hash;
    const fragment = fragmentWithPrefix.substring(1);

    return fragment || undefined;
  }
}
