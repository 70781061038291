import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-upload-dropzone',
  templateUrl: './sbx-upload-dropzone.component.html',
  styleUrls: ['./sbx-upload-dropzone.component.scss'],
})
export class SbxUploadDropzoneComponent extends FieldType<FieldTypeConfig> {
  handleDropzoneChange(value) {
    this.formControl.setValue(value);
  }
}
