import { AppConfig } from '@/core/config/app.config';
import { SbxUrlService } from '@/core/url/url.service';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sbx-forbidden-access-page',
  templateUrl: './sbx-forbidden-access-page.component.html',
  styleUrls: ['./sbx-forbidden-access-page.component.scss'],
  standalone: true,
  imports: [CommonModule, SbxButtonModule],
})
export class SbxForbiddenAccessPageComponent implements OnInit {
  public firstName: string;
  public companyName?: string;

  constructor(
    public readonly sbxUrlService: SbxUrlService,
    private readonly appConfig: AppConfig,
  ) {}

  public ngOnInit(): void {
    this.firstName = this.appConfig.userProfile?.firstName;
    this.companyName = this.appConfig.currentEntity?.title;
  }

  protected goBack(): void {
    window.history.back();
  }
}
