<ng-container *ngFor="let item of to.valueOptions">
  <sbx-checkbox
    [name]="item.value"
    [model]="isChecked(item.value)"
    [readOnly]="field.templateOptions.readOnly"
    [label]="item.label"
    (change)="handleChange(item.value)"
    (focus)="to.focus ? to.focus(field, $event) : null"
  ></sbx-checkbox>
</ng-container>
