const ZERO_COST = '$0.00';

/**
 * @ngdoc object
 * @name sb.workitem.chargeCreditCard:ChargeCreditCardModel
 * @kind function
 *
 * @description
 * A state object for the charge credit card workitem.
 */
export const ChargeCreditCardModel = [
  '$q',
  '$stripe',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, $stripe, BackendLocation, SimpleHTTPWrapper) {
    return () => ({
      /**
       * @ngdoc property
       * @name selectedCard
       * @propertyOf sb.workitem.chargeCreditCard.ChargeCreditCardModel
       *
       * @description
       * The current model from a sbBillingCardForm.
       */
      selectedCard: undefined,

      _generateTokenFromNewCard({ cardholderName, stripeCardElement }) {
        if (!cardholderName) {
          return $q.reject('Please enter a cardholder name.');
        }
        return $stripe
          .then((api) =>
            api.createToken(stripeCardElement.getCard(), { name: cardholderName }),
          )
          .then(({ error, token }) => {
            return error ? $q.reject(error.message) : token.id;
          });
      },

      _saveData(data) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BackendLocation.context(1)}token`,
            data,
          },
          'Could not save credit card.',
        );
      },

      /**
       * @ngdoc method
       * @name saveSelectedToken
       * @methodOf sb.workitem.chargeCreditCard.ChargeCreditCardModel
       *
       * @description
       * Take and send to the workitem the current model of `selectedCard`.
       */
      saveSelectedToken() {
        const { selectedCard } = this;
        if (!selectedCard) {
          return $q.reject('You must select or enter a credit card to continue.');
        }
        const { type } = selectedCard;
        let prom;
        if (type === 'NEW') {
          prom = this._generateTokenFromNewCard(selectedCard.newCard).then(
            (tokenId) => ({
              tokenId,
            }),
          );
        } else if (type === 'SAVED') {
          prom = $q.resolve({ cardId: selectedCard.savedCard });
        }
        return prom.then(this._saveData);
      },
    });
  },
]; // end ChargeCreditCardModel

/**
 * @ngdoc component
 * @name sb.billing.component:sbChargeCreditCardWorkitem
 *
 * @description
 * This component is the body of the charge credit card workitem.
 *
 * @param {Order} order An object with `.items` (array) and `.total`.
 * @param {array<Cards>} savedCards List of order objects for display.
 * @param {string} [topMessage=undefined] Top message of html for display.
 * @param {string} [bottomMessage=undefined] Bottom message of html for display.
 */
export const sbChargeCreditCardWorkitem = {
  controllerAs: 'vm',
  template: require('./templates/workitem.html'),
  bindings: {
    order: '<',
    savedCards: '<',
    topMessage: '<?',
    bottomMessage: '<?',
  },
  controller: [
    'ProcessButtonModel',
    'SerializeAndSubmitProcessForm',
    'ChargeCreditCardModel',
    'PromiseErrorCatcher',
    function (
      ProcessButtonModel,
      SerializeAndSubmitProcessForm,
      ChargeCreditCardModel,
      PromiseErrorCatcher,
    ) {
      function $onInit() {
        this.model = ChargeCreditCardModel();
        ProcessButtonModel.$addSubmitCondition('continue', () => {
          ProcessButtonModel.disable();
          if (this.showBillingForm()) {
            return this.model.saveSelectedToken().finally(() => {
              ProcessButtonModel.requestEnable();
            });
          }

          return () => ProcessButtonModel.requestEnable();
        });
      }
      function checkForOffline(evt) {
        const {
          target: { classList, tagName },
        } = evt;
        if (tagName === 'A' && classList.contains('offline-file-link')) {
          evt.preventDefault();
          SerializeAndSubmitProcessForm('skipPayment').catch(PromiseErrorCatcher);
        }
      }

      function showItem(item) {
        // Hide zero cost items if there was an invoice,
        // unless the entire bill was $0
        if (
          item.subtotal === ZERO_COST &&
          this.order.invoiceId &&
          this.order.total !== ZERO_COST
        ) {
          return false;
        }

        return true;
      }

      function showBillingForm() {
        return this.order.total !== ZERO_COST;
      }

      this.checkForOffline = checkForOffline.bind(this);
      this.showItem = showItem.bind(this);
      this.showBillingForm = showBillingForm.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbChargeCreditCardWorkitem
