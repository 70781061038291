import angular from 'angular';
import { Record, List } from 'immutable';
import { filter, map } from 'rxjs/operators';

const EXTRA_FORM_FIELDS = Object.freeze([
  {
    type: 'stakeholder',
    data: {},
    key: 'billingContact',
    templateOptions: {
      required: true,
      label: 'Billing Contact',
      stakeholderOptions: {
        allowPerson: true,
        allowCreation: false,
        allowEntity: false,
        allowStakes: false,
        creatorIsContact: false,
        entityOptions: {},
        format: 'medium',
        requireEmail: true,
      },
    },
  },
]);

const BILLING_CODE_DESC = `If there is a billing code (e.g. tax identification
  number, VAT number, purchase order number, etc.)  that you would like to appear
  on your invoices, you may enter it here. This text will appear on all of your
  invoices from Shoobx.`;

const BILLING_INFO_FIELDS = Object.freeze([
  {
    type: 'string-textline',
    data: {},
    key: 'billingName',
    templateOptions: {
      label: 'Name',
    },
  },
  {
    type: 'address',
    data: {},
    key: 'billingAddress',
    templateOptions: {
      label: 'Address',
    },
  },
  {
    type: 'string-textline',
    data: {},
    key: 'billingCode',
    templateOptions: {
      label: 'Custom Billing Code',
      help: BILLING_CODE_DESC,
    },
  },
]);

const CC_RECIPIENT_FIELD = Object.freeze([
  {
    type: 'email-textline',
    data: {},
    key: 'ccRecipient',
    templateOptions: {
      label: "Recipient's Email Address",
    },
  },
]);

/**
 * @ngdoc object
 * @name sb.billing:BillingAdminModel
 * @kind function
 *
 * @description
 * A state object for the billing admin page.
 */
export const BillingAdminModel = [
  '$q',
  '$stripe',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, $stripe, BackendLocation, SimpleHTTPWrapper) {
    const Card = Record({
      externalId: undefined,
      isPrimary: false,
      holderName: undefined,
      lastFourDigits: undefined,
      expDate: undefined,
      brand: undefined,
    });

    const BASE_URL = `${BackendLocation.entity(2)}billing`;
    return () => ({
      /**
       * @ngdoc property
       * @name cards
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * An immutable list of Cards:
       *   @property {string} externalId Stripe ID.
       *   @property {string} holderName The name of the cardholder.
       *   @property {boolean} isPrimary Is the card the primary card?
       *   @property {string} brand Visa/Matercard/etc
       *   @property {string} expDate Exp date as m/yyyy
       *   @property {string} lastFourDigits Last digits
       */
      cards: List(),

      /**
       * @ngdoc property
       * @name ccRecipients
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * An immutable list of cc recipients:
       */
      ccRecipients: List(),

      /**
       * @ngdoc property
       * @name loading
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * Boolean loading indicator.
       */
      loading: false,

      /**
       * @ngdoc property
       * @name msg
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * Object of `.content` and `.type`.
       */
      msg: null,

      /**
       * @ngdoc property
       * @name extraFormModel
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * The model of the "extra" information.
       */
      extraFormModel: undefined,

      /**
       * @ngdoc property
       * @name billingAddressHTML
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * The model of the "extra" information.
       */
      billingAddressHTML: undefined,

      /**
       * @ngdoc property
       * @name billingAddressHTML
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * The model of the "extra" information.
       */
      billingName: undefined,

      /**
       * @ngdoc property
       * @name billingCode
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * The billing code for the entity
       */
      billingCode: undefined,

      /**
       * @ngdoc property
       * @name oldContactId
       * @propertyOf sb.billing.BillingAdminModel
       *
       * @description
       * The string ID of the old contact (so that we can autosave, we keep a revference to compare to).
       */
      oldContactId: undefined,

      _addError(error) {
        if (angular.isString(error)) {
          this.msg = { type: 'danger', content: error };
        }
        return $q.reject(error);
      },

      _load(prom) {
        this.loading = true;
        this.msg = null;
        return prom.catch(this._addError.bind(this)).finally(() => {
          this.loading = false;
        });
      },

      _checkLatestProm(originalPromise, propertyName, fn) {
        return (x) => {
          if (originalPromise === this[propertyName]) {
            return fn(x);
          }
        };
      },

      /**
       * @ngdoc method
       * @name changeBillingContact
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {string} billingContactId Stakeholder to become the billing contact.
       *
       * @returns {promise} Resolves after transaction completes. Rejects otherwise.
       */
      changeBillingContact(billingContactId) {
        const prom = (this._latestBillingProm = SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BASE_URL}/set-billing-contact/${billingContactId}`,
          },
          'Could not save billing contact.',
        ));
        this.loading = true;
        this.msg = null;
        return prom
          .then(
            this._checkLatestProm(prom, '_latestBillingProm', () => {
              this.msg = {
                type: 'success',
                content: 'The billing contact has been updated.',
              };
              this.oldContactId = billingContactId;
            }),
          )
          .catch(
            this._checkLatestProm(
              prom,
              '_latestBillingProm',
              this._addError.bind(this),
            ),
          )
          .finally(
            this._checkLatestProm(prom, '_latestBillingProm', () => {
              this.loading = false;
              this._latestBillingProm = null;
            }),
          );
      },

      /**
       * @ngdoc method
       * @name changeBillingInfo
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {object} address The new billing address.
       *
       * @returns {promise} Resolves after transaction completes. Rejects otherwise.
       */
      changeBillingInfo(billingName, billingAddress, billingCode) {
        const prom = (this._latestBillingAddressProm = SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BASE_URL}/set-billing-info`,
            data: {
              billingName: billingName,
              billingAddress: billingAddress,
              billingCode: billingCode,
            },
          },
          'Could not save billing address.',
        ));
        this.loading = true;
        this.msg = null;
        return prom
          .then(() => {
            this.init().then(() => {
              this.msg = {
                type: 'success',
                content: 'The billing name and address has been set.',
              };
            });
          })
          .catch(
            this._checkLatestProm(
              prom,
              '_latestBillingAddressProm',
              this._addError.bind(this),
            ),
          )
          .finally(
            this._checkLatestProm(prom, '_latestBillingAddressProm', () => {
              this.loading = false;
              this._latestBillingAddressProm = null;
            }),
          );
      },

      /**
       * @ngdoc method
       * @name addCcRecipient
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {object} ccRecipient Cc Recipient.
       *
       * @returns {promise} Resolves after transaction completes. Rejects otherwise.
       */
      addCcRecipient(ccRecipient) {
        const prom = (this._addCcRecipientProm = SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BASE_URL}/add-cc-recipient`,
            data: {
              ccRecipient: ccRecipient,
            },
          },
          'Could not add the cc recipient',
        ));
        this.loading = true;
        this.msg = null;
        return prom
          .then(() => {
            this.init().then(() => {
              this.msg = {
                type: 'success',
                content: 'Added ' + ccRecipient + ' as a cc recipient.',
              };
            });
          })
          .catch(
            this._checkLatestProm(
              prom,
              '_addCcRecipientProm',
              this._addError.bind(this),
            ),
          )
          .finally(
            this._checkLatestProm(prom, '_addCcRecipientProm', () => {
              this.loading = false;
              this._addCcRecipientProm = null;
            }),
          );
      },

      /**
       * @ngdoc method
       * @name removeCcRecipient
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {object} ccRecipient Cc Recipient.
       *
       * @returns {promise} Resolves after transaction completes. Rejects otherwise.
       */
      removeCcRecipient(ccRecipient) {
        const prom = (this._removeCcRecipientProm = SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BASE_URL}/remove-cc-recipient`,
            data: {
              ccRecipient: ccRecipient,
            },
          },
          'Could not remove the cc recipient',
        ));
        this.loading = true;
        this.msg = null;
        return prom
          .then(() => {
            this.init().then(() => {
              this.msg = {
                type: 'success',
                content: 'Removed ' + ccRecipient + ' as a cc recipient.',
              };
            });
          })
          .catch(
            this._checkLatestProm(
              prom,
              '_removeCcRecipientProm',
              this._addError.bind(this),
            ),
          )
          .finally(
            this._checkLatestProm(prom, '_removeCcRecipientProm', () => {
              this.loading = false;
              this._removeCcRecipientProm = null;
            }),
          );
      },

      /**
       * @ngdoc method
       * @name resetBillingInfo
       * @methodOf sb.billing.BillingAdminModel
       *
       *
       * @returns {promise} Resolves after transaction completes. Rejects otherwise.
       */
      resetBillingInfo() {
        const prom = (this._latestBillingAddressProm = SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: `${BASE_URL}/reset-billing-address`,
          },
          'Could not reset billing address.',
        ));
        this.loading = true;
        this.msg = null;
        return prom
          .then(() => {
            this.init().then(() => {
              this.msg = {
                type: 'success',
                content: 'The billing name and address has been reset.',
              };
            });
          })
          .catch(
            this._checkLatestProm(
              prom,
              '_latestBillingAddressProm',
              this._addError.bind(this),
            ),
          )
          .finally(
            this._checkLatestProm(prom, '_latestBillingAddressProm', () => {
              this._latestBillingAddressProm = null;
            }),
          );
      },

      /**
       * @ngdoc method
       * @name makeCardPrimary
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {string} cardId Stripe unique ID.
       *
       * @returns {promise} Resolves after the card is made primary. Rejects otherwise.
       */
      makeCardPrimary(cardId) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: `${BASE_URL}/make-card-primary`,
            data: {
              cardId: cardId,
            },
          },
          'Could not make card primary.',
        );
        return this._load(prom).then(() => {
          this.cards = this.cards.map((card) =>
            card.set('isPrimary', card.externalId === cardId),
          );
        });
      },

      /**
       * @ngdoc method
       * @name removeCard
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {string} cardId Stripe unique ID.
       *
       * @returns {promise} Resolves after the card is removed. Rejects otherwise.
       */
      removeCard(cardId) {
        const prom = SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${BASE_URL}/remove-card`,
            data: {
              cardId: cardId,
            },
          },
          'Could not delete the card.',
        );
        return this._load(prom).then(() => {
          this.cards = this.cards.filter((card) => card.externalId !== cardId);
        });
      },

      /**
       * @ngdoc method
       * @name addCard
       * @methodOf sb.billing.BillingAdminModel
       *
       * @param {object} cardData
       *   @property {object} stripeCardElement A standard stripe element object.
       *   @property {string} cardholderName The name of the cardholder.
       *
       * @returns {promise} Resolves after the card has been added. Rejects on failure.
       */
      addCard({ stripeCardElement, cardholderName }) {
        const prom = $stripe
          .then((api) =>
            api.createToken(stripeCardElement.getCard(), { name: cardholderName }),
          )
          .then(({ error, token }) => {
            if (error) {
              return $q.reject(error.message);
            }
            return SimpleHTTPWrapper(
              {
                method: 'POST',
                url: `${BASE_URL}/add-new-card`,
                data: {
                  externalId: token.id,
                },
              },
              'Could not add new card.',
            );
          });
        return prom.then((newCard) => {
          this.cards = this.cards.push(Card(newCard));
        });
      },

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.billing.BillingAdminModel
       *
       * @returns {promise} Resolves after the state is hydrated. It rejects on failure.
       */
      init() {
        const prom = SimpleHTTPWrapper(
          { url: `${BASE_URL}/get-all-billing` },
          'Could not fetch credit cards.',
        );
        return this._load(prom).then(
          ({
            cards,
            extraFormData,
            billingAddressHTML,
            billingName,
            ccRecipients,
            billingCode,
          }) => {
            this.extraFormModel = extraFormData;
            this.billingAddressHTML = billingAddressHTML;
            this.billingName = billingName;
            this.billingCode = billingCode;
            this.oldContactId =
              extraFormData.billingContact && extraFormData.billingContact.sh.id;
            this.ccRecipients = List(ccRecipients);
            this.cards = List(cards.map(Card));
          },
        );
      },
    });
  },
]; // end BillingAdminModel

/**
 * @ngdoc component
 * @name sb.billing.compoent:sbBillingAdministration
 *
 * @description
 * This component is the billing administration page.
 */
export const sbBillingAdministration = {
  template: require('./templates/admin.html'),
  controllerAs: 'vm',
  controller: [
    '$scope',
    '$observable',
    'PromiseErrorCatcher',
    '$addCreditCardModal',
    'BillingAdminModel',
    '$formModal',
    function (
      $scope,
      $observable,
      PromiseErrorCatcher,
      $addCreditCardModal,
      BillingAdminModel,
      $formModal,
    ) {
      function addCard() {
        const { model } = this;
        model.msg = null;
        model.loading = true;
        $addCreditCardModal(model)
          .catch(PromiseErrorCatcher)
          .finally(() => {
            model.msg = null;
            model.loading = false;
          });
      }
      function makePrimary(card) {
        this.model.makeCardPrimary(card.externalId).catch(PromiseErrorCatcher);
      }

      function deleteCard(card) {
        this.model.removeCard(card.externalId).catch(PromiseErrorCatcher);
      }

      function updateBillingInfo() {
        const { model } = this;
        return $formModal({
          title: 'Edit Billing Address',
          htmlContent: require('./templates/modal-form-content.html'),

          forms: { modalFormName: { fields: angular.copy(BILLING_INFO_FIELDS) } },
          formData: {
            modalFormName: {
              billingAddress: model.extraFormModel.billingAddress,
              billingName: model.billingName,
              billingCode: model.billingCode,
            },
          },
          primaryButtonText: 'Save',
          onConfirmPromise: ({ $formData: formData }) => {
            const address = formData.modalFormName.billingAddress;
            const name = formData.modalFormName.billingName;
            const billingCode = formData.modalFormName.billingCode;
            return $scope.vm.model.changeBillingInfo(name, address, billingCode);
          },
        }).then(PromiseErrorCatcher);
      }

      function addCcRecipient() {
        const { model } = this;
        return $formModal({
          title: 'Add Carbon Copy Recipient',
          htmlContent: require('./templates/modal-form-content.html'),
          forms: { modalFormName: { fields: angular.copy(CC_RECIPIENT_FIELD) } },
          formData: {
            modalFormName: {
              ccRecipient: model.extraFormModel.ccRecipient,
            },
          },
          primaryButtonText: 'Save',
          onConfirmPromise: ({ $formData: formData }) => {
            const ccRecipient = formData.modalFormName.ccRecipient;
            return $scope.vm.model.addCcRecipient(ccRecipient);
          },
        }).then(PromiseErrorCatcher);
      }

      function removeCcRecipient(ccRecipient) {
        this.model.removeCcRecipient(ccRecipient).catch(PromiseErrorCatcher);
      }

      function resetBillingInfo() {
        const { model } = this;
        model.msg = null;
        model.loading = true;
        this.model.resetBillingInfo().catch(PromiseErrorCatcher);
      }

      function $onInit() {
        const model = (this.model = BillingAdminModel());
        this.extraFormFields = EXTRA_FORM_FIELDS;
        model.init().catch(PromiseErrorCatcher);
        $observable
          .fromWatcher($scope, 'vm.model.extraFormModel.billingContact.sh.id')
          .pipe(
            map((field) => field?.newValue),
            filter((id) => id && id !== model.oldContactId),
          )
          .$applySubscribe($scope, (newContactId) => {
            model.changeBillingContact(newContactId).catch(PromiseErrorCatcher);
          });
      }
      this.addCard = addCard.bind(this);
      this.makePrimary = makePrimary.bind(this);
      this.deleteCard = deleteCard.bind(this);
      this.$onInit = $onInit.bind(this);
      this.updateBillingInfo = updateBillingInfo.bind(this);
      this.addCcRecipient = addCcRecipient.bind(this);
      this.removeCcRecipient = removeCcRecipient.bind(this);
      this.resetBillingInfo = resetBillingInfo.bind(this);
    },
  ],
}; // end sbBillingAdministration
