import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxProcessBlockedListComponent } from './sbx-process-blocked-list/sbx-process-blocked-list.component';
import { SbxProcessDoneMessageComponent } from './sbx-process-done-message/sbx-process-done-message.component';
import { SharedModule } from '@/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxProcessBlockedListComponent, SbxProcessDoneMessageComponent],
  exports: [SbxProcessBlockedListComponent, SbxProcessDoneMessageComponent],
})
export class ProcessModule {
  static entryComponents = [SbxProcessBlockedListComponent];

  static forRoot(): ModuleWithProviders<ProcessModule> {
    return {
      ngModule: ProcessModule,
    };
  }
}
