import angular from 'angular';
import Rx from '../rx';
import Form from '../form/form';
import { sbTypeahead, sbTypeaheadItem } from './typeahead';

export default angular
  .module('sb.lib.typeahead', [Rx, Form])

  .component('sbTypeahead', sbTypeahead)
  .directive('sbTypeaheadItem', sbTypeaheadItem).name;
