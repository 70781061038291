import angular from 'angular';
import { List, Map } from 'immutable';
import { Observable } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

/**
 * @ngdoc directive
 * @name sb.listpage.directive:sbFilteredList
 *
 * @description
 * This is a directive that creates a page with a list component.
 *
 * The display of the items is the job of the transcluded scope of this directive.
 *
 * The model for this directive is `ListPageModel`, please see documentation for
 * `ListPageModel` for more information.
 *
 * @param {object} sbModel A hydration/intial model for ListPageModel.
 * @param {object} sbFilters The form description of the filter form.
 * @param {object} sbSorts The form description of the sort form.
 * @param {string} sbRedirect The URL for the back to dashboard button.
 * @param {string} sbRedirectTitle The text of the back to dashboard button.
 * @param {array<object>} [sbActionLinks=undefined] An array of action objects.
 *   @property {string} link The URL of the action.
 *   @property {string} name The text content for the action.
 *   @property {string} iconClass The font awesome icon class for the action.
 * @param {object} [status=undefined] Status object for showing messages.
 *   @property {string} type `danger` or `success` etc
 *   @property {string} reason Message itself.
 * @param {expression} [actionHandler=undefined] Optional callback to handle an
 *   action's behavior. $action and $event will be available in the namespace.
 *
 * @example
   <sb-filtered-list
     sb-sorts="sortFormDesc"
     sb-filters="filterFormDesc"
     sb-total-size="2"
     sb-title="Page Title"
     sb-model="initModel"
     sb-redirect="dashboardURL">
     <sb-body>
       <div ng-repeat="item in model.items">{{ item | json }}</div>
     </sb-body>
   </sb-filtered-list>
 */
export function sbFilteredList() {
  return {
    restrict: 'E',
    transclude: {
      header: '?sbHeader',
      filter: '?sbFilter',
      body: 'sbBody',
    },
    template: require('./templates/page.html'),
    scope: {
      title: '<sbTitle',
      filterForms: '<sbFilters',
      filtersOnTop: '<sbFiltersOnTop',
      totalSize: '<sbTotalSize',
      sortForms: '<sbSorts',
      actions: '<sbActionLinks',
      model: '<sbModel',
      redirect: '<sbRedirect',
      redirectTitle: '<?sbRedirectTitle',
      status: '<?',
      actionHandler: '&?',
    },
    controller: [
      '$scope',
      '$window',
      function ($scope, $window) {
        // Sort and filter are tied if there is a search
        if (!$scope.sortForms) {
          this.relevance = false;
          return;
        }
        const sort = ($scope.sortForms.fields || []).filter(
          (item) => item.key === 'sort',
        );

        if (!sort.length || !$scope.filterForms || !$scope.filterForms.fields) {
          this.relevance = false;
          return;
        }

        const queries = $scope.filterForms.fields
          .filter((item) => item.type === 'string-textline')
          .map((item) => item.key);

        const relevance = sort[0].templateOptions.enumVocab.find(
          (item) => item.value === 'relevance',
        );
        this.relevance = Boolean(queries.length && relevance);

        $scope.$watch(
          () => {
            const filterModel = $scope.model.filter;
            const lengths = queries.map((key) => {
              const modelValue = filterModel[key];
              return modelValue ? modelValue.length : 0;
            });
            const moreThanZero = lengths.filter((len) => len > 0);
            return Boolean(moreThanZero.length);
          },
          (activeSearch) => {
            this.activeSearch = activeSearch;
          },
        );

        $scope.isMobile = $window.innerWidth < 1280;
        if (!$scope.redirectTitle) {
          $scope.redirectTitle = 'Back to Workspace';
        }
      },
    ],
  };
}

/**
 * @ngdoc directive
 * @name sb.listpage.sbFilteredListPaging
 *
 * Paging controlls for the sbFilteredList directive
 *
 * internal directive.
 *
 **/
export function sbFilteredListPaging() {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: require('./templates/paging.html'),
    scope: {
      model: '=ngModel',
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.changePage = (page) => {
          if ($scope.isNumber(page)) {
            $scope.model.getPage(page);
          }
        };
        $scope.isNumber = angular.isNumber;

        if (!$scope.batch) {
          $scope.batch = {};
        }

        $scope.changeBatchSize = () => {
          $scope.model.setBatch();
        };

        $scope.computePages = (cp, tp) => {
          const totalPages = tp - 1,
            currentPage = cp;

          if (currentPage < 0 || tp === 0) {
            $scope.pages = [];
            return;
          } else if (currentPage > 4) {
            $scope.pages = [0, 'preEllipse'];
          } else {
            $scope.pages = totalPages > 1 ? [0, 1] : [0];
          }

          let start = currentPage - 2;

          // add before current page
          while (start < currentPage) {
            if (start >= 0 && $scope.pages.indexOf(start) < 0) {
              $scope.pages.push(start);
            }
            start++;
          }

          if ($scope.pages.indexOf(currentPage) < 0) {
            $scope.pages.push(currentPage);
          }

          start = currentPage + 1;
          // add before current page
          while (start < currentPage + 3) {
            if (start < totalPages && $scope.pages.indexOf(start) < 0) {
              $scope.pages.push(start);
            }
            start++;
          }

          if (totalPages - currentPage > 4) {
            $scope.pages.push('postEllipse');
          } else if (
            totalPages - currentPage === 4 &&
            $scope.pages.indexOf(totalPages - 1) < 0
          ) {
            $scope.pages.push(totalPages - 1);
          }
          if (totalPages !== currentPage && $scope.pages.indexOf(totalPages) < 0) {
            $scope.pages.push(totalPages);
          }
        };

        $scope.$watchGroup(
          ['model.paging.page', 'model.paging.totalPages'],
          ([page, totalPages]) => {
            $scope.computePages(page, totalPages);
          },
        );

        $scope.batchSizes = [
          { label: '10 PER PAGE', value: 10 },
          { label: '20 PER PAGE', value: 20 },
          { label: '50 PER PAGE', value: 50 },
        ];
      },
    ],
  };
}

/**
 *
 * URL update factory used to populate search fields.
 *
 * @param $location
 */
export const ListLocation = [
  '$location',
  'PromiseErrorCatcher',
  function ($location, PromiseErrorCatcher) {
    const PLAIN_CONVERTER = (key, allData) => ({ [key]: allData[key] });
    const ARRAY_CONSTRANTED_CONVERTER = (key, allData) => {
      // These types must be an array, but sometimes are in the url as only one string
      const value = allData[key];
      const data = value && !angular.isArray(value) ? [value] : value;
      return { [key]: data };
    };
    const DATE_CONVERTER = (key, allData) => ({
      // Dates are specialized to two keys in template
      dateFrom: allData.dateFrom,
      dateTo: allData.dateTo,
    });
    const CONVERTERS = Object.freeze({
      tree: ARRAY_CONSTRANTED_CONVERTER,
      checklist: ARRAY_CONSTRANTED_CONVERTER,
      switchlist: ARRAY_CONSTRANTED_CONVERTER,
      'enum-dropdown': PLAIN_CONVERTER,
      'string-textline': PLAIN_CONVERTER,
      date: DATE_CONVERTER,
      'folder-tree': PLAIN_CONVERTER,
    });
    function scopeEvtObservable(scope, evtName) {
      return new Observable((observer) =>
        scope.$on(evtName, (evt) => {
          observer.next(evt);
        }),
      );
    }
    function createReduceFields(allData, converters) {
      converters = converters || {};
      return (accum, { key, type }) => {
        const converter = converters[type] || CONVERTERS[type];
        if (!converter) {
          throw new Error('Unknown filter/sort field type: ' + type);
        }
        return Object.assign(accum, converter(key, allData));
      };
    }
    return {
      updateModelOnLocationChange(scope, converters, skipConditionally = true) {
        return scopeEvtObservable(scope, '$locationChangeSuccess')
          .pipe(
            // We skip the first one becauase we expect the model to be hydrated on init.
            skip(skipConditionally ? 1 : 0),
            takeUntil(scopeEvtObservable(scope, '$destroy')),
          )
          .subscribe(() => {
            const flags = scope.flags;
            const allData = $location.search() || {};
            const reduceFn = createReduceFields(allData, converters);
            const sortData = scope.sortForms.fields.reduce(reduceFn, {});
            const filterData = (scope.filterForms.fields || []).reduce(reduceFn, {});
            // Special case: we have a feature exploited by certain HR views
            // that want to show an explicit list of documents by passing
            // their IDs.  We want to retain these IDs when we sort
            // and we don't have an "invisible" sort field at the moment.
            // eslint-disable-next-line no-prototype-builtins
            if (allData.hasOwnProperty('ids')) {
              filterData.ids = allData.ids;
            }
            const otherData = Object.keys(allData).reduce(
              (accum, key) => {
                if (
                  // eslint-disable-next-line no-prototype-builtins
                  !filterData.hasOwnProperty(key) &&
                  // eslint-disable-next-line no-prototype-builtins
                  !sortData.hasOwnProperty(key)
                ) {
                  accum[key] = allData[key];
                }
                return accum;
              },
              angular.extend({}, flags),
            );
            scope.model
              .update(filterData, sortData, otherData)
              .catch(PromiseErrorCatcher);
          });
      },
      updateUrl: (data) => {
        angular.forEach($location.search(), (value, key) => {
          $location.search(key, null);
        });
        angular.forEach(data, (value, key) => {
          $location.search(key, value);
        });
      },
    };
  },
];

/**
 * @ngdoc object
 * @name sb.listpage.ListPageModel
 * The model for listpage.  The model can be initialized with the initialized method
 * or updated by instance .
 * This model is responsible for making post requests to the given url, as well as
 * maintaining the batchsize, paging, sort and filter values for listpage.
 *
 **/
export const ListPageModel = [
  '$q',
  'SimpleHTTPWrapper',
  'DebounceMaker',
  function ($q, SimpleHTTPWrapper, DebounceMaker) {
    const object = {
      defaultMapper: (data, requiredFilterValues) => {
        const updateData = angular.extend({}, requiredFilterValues);
        angular.forEach(data, (value, key) => {
          const filterValue = updateData[key];
          if ((!value || value.length === 0) && filterValue) {
            return;
          }
          updateData[key] = value;
        });
        return updateData;
      },
      mapper: undefined,
      url: undefined,
      filter: Map(),
      sort: Map(),
      size: undefined,
      paging: Map(),
      items: List(),
      requiredFilterValues: Map(),
      onChangeCallBack: undefined,
      loading: false,
      _debounce: DebounceMaker(true), // No $apply.
      hidePaging: false, // Hide paging if there is just one page?
      isTopSortingEnabled: true,
      _latestUpdateProm: undefined,

      _checkLatestUpdate(originalProm, resolve) {
        return (x) => {
          if (originalProm === this._latestUpdateProm) {
            return resolve(x);
          }
        };
      },

      /**
       * convert to data
       *
       * @returns serialized version of the model
       */
      data() {
        const data = angular.copy(this.filter);
        data.size = this.size;
        data.page = this.paging.page;
        angular.forEach(this.sort, (value, key) => {
          if (angular.isUndefined(data[key])) {
            data[key] = value;
          }
        });
        return this.mapper(data, this.requiredFilterValues);
      },

      /**
       * Calls backend and update the current items based on the current
       * state of the model (filters, sort, page, and size)
       */
      update(filterData, sortData, otherData) {
        return this._debounce(angular.noop, 100).then(() => {
          this.loading = true;
          const request = {
            method: this.method || 'GET',
            url: this.url,
          };
          // Always ensure requiredFilters are part of request with lower precedence.
          // Sometimes these keys are in there but have undefined values.
          const updateData = angular.extend({}, filterData, sortData, otherData);
          Object.keys(this.requiredFilterValues).forEach((key) => {
            const value = this.requiredFilterValues[key];
            if (angular.isDefined(value) && angular.isUndefined(updateData[key])) {
              updateData[key] = value;
            }
          });

          if (request.method === 'GET') {
            request.params = updateData;
          } else {
            request.data = updateData;
          }

          const prom = (this._latestUpdateProm = SimpleHTTPWrapper(
            request,
            'Failed to update list.',
          ));
          const onComplete = () => {
            this.loading = false;
          };
          const onlyLatest = (cb) => this._checkLatestUpdate(prom, cb);
          // We need to wrap in $q so that client .then chains don't get triggered
          // when old requests get resolved
          return $q((resolve, reject) => {
            const onResolve = (response) => {
              const data = { response, filterData, sortData, otherData };
              this.onSuccess(data);
              resolve(data);
            };
            const onReject = (error) => {
              this.status = angular.isString(error)
                ? error
                : 'Something went wrong. Unable to load results.';
              reject(error);
            };
            prom
              .then(onlyLatest(onResolve))
              .catch(onlyLatest(onReject))
              .finally(onlyLatest(onComplete));
          });
        });
      },

      /**
       * Refresh item models given the current the current flags, filters, sort, and paging.
       */
      refreshWithCurrentParams() {
        const { size, filter, sort, paging, flags } = this;
        const page = (paging && paging.page) || 0;
        const otherData = angular.extend({ page, size }, flags);
        return this.update(filter, sort, otherData);
      },

      onSuccess({ response, sortData, filterData, otherData }) {
        const { items, ctime, message, batch } = response;
        this.filter = filterData || {};
        this.sort = sortData || {};
        const { page, size } = otherData || {};
        this.size = size || 20;
        this.paging.page = page || 0;
        this.items = List(items);
        if (ctime) {
          this.ctime = ctime;
        }
        this.status = message;
        if (batch) {
          const { total } = batch;
          this.paging.totalPages = Math.ceil(total / this.size);
          this.paging.totalItems = total;
        }
      },

      /**
       * Is model unavailable
       * @returns {boolean}
       */
      isLoading() {
        return this.loading;
      },

      /**
       * updates after changing the current page to 0 and  fetches new items
       */
      setBatch() {
        this.paging.page = 0;
        this.onChangeCallBack(this.data());
      },

      /**
       * getPage Given a pageNumber fetches data from backend
       * @param pageNumber
       */
      getPage(pageNumber) {
        this.paging.page = pageNumber;
        this.onChangeCallBack(this.data());
      },

      /**
       * changes the page and serializes the model and fetches items
       */
      setFilter() {
        this.paging.page = 0;
        this.onChangeCallBack(this.data());
      },

      /**
       * changes the sort of the model and fetches items
       */
      setSort() {
        this.paging.page = 0;
        this.onChangeCallBack(this.data());
      },

      /**
       * Do we need to show paging?
       * @returns {boolean}
       */
      isPagingVisible() {
        return !this.hidePaging || this.paging.totalPages !== 1;
      },

      /**
       * initializes the model for the listpage view
       *
       * @param filter is the initial state of the filter model
       * @param requiredFilterValues is a dictionary of required filters to
       *   be provided to the endpoint
       * @param size initial state of the max items in this batch
       * @param sort initial state of the current sort filter.
       * @param paging a dictionary of total pages and current page.
       *   `{page: 1, totalPages: 200}`
       * @param items initial list of items to be displayed
       * @param request {url: url, method: 'GET'}
       *  the full that accepts filters and returns items, and the method.
       * @param onChangeCallBack an optional callback when the set of items
       *   are populated.
       * @param mapper is a mapping function or undefined if defaultMapper
       *   is to be used
       * @param flags a dictionary for optional request parameter flags
       *   see: webapi.v1.process.PROCES_FLAGS
       */
      initialize(
        filter,
        requiredFilterValues,
        size,
        sort,
        paging,
        items,
        status,
        request,
        onChangeCallBack,
        mapper,
        flags,
      ) {
        if (onChangeCallBack) {
          this.onChangeCallBack = onChangeCallBack;
        }
        const { url, method } = request;
        if (url) {
          this.url = url;
        }

        this.method = method || 'GET';
        this.mapper = mapper || this.defaultMapper;
        this.filter = filter || {};
        this.requiredFilterValues = requiredFilterValues || {};
        this.sort = sort || {};
        this.size = size || 1;
        this.paging = paging || {};
        this.status = status;
        this.flags = flags || {};

        if (paging) {
          this.paging.totalPages = Math.ceil(this.paging.totalItems / this.size);
        }

        this.items = List(items);
      },
    };

    return object;
  },
];

/**
 * @ngdoc component
 * @name sb.listpage.sbListMobileMenu
 *
 * @description
 * Creates a side-menu for mobile views
 *
 **/
export const sbListMobileMenu = {
  template: require('./templates/mobilemenu.html'),
  transclude: true,
  controllerAs: 'vm',
  controller: [
    function () {
      this.open = false;

      function openMenu() {
        this.open = true;
      }
      function closeMenu() {
        this.open = false;
      }
      function toggleMenu() {
        this.open = !this.open;
      }

      this.$onInit = () => {
        this.openMenu = openMenu.bind(this);
        this.closeMenu = closeMenu.bind(this);
        this.toggleMenu = toggleMenu.bind(this);
      };
    },
  ],
}; // end sbListMobileMenu
