<div class="sbx-radio-group {{ layout }}">
  <sbx-radio
    [name]="name"
    [label]="item.label"
    [labelTemplate]="item.labelTemplate"
    [value]="item.value"
    [model]="model"
    [disabled]="disabled"
    (modelChange)="handleChange($event)"
    (focus)="handleFocus($event)"
    *ngFor="let item of items"
  ></sbx-radio>
</div>
