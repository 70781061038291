import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldArrayTypeConfig, FieldType } from '@ngx-formly/core';

@Component({
  selector: 'sbx-list',
  templateUrl: './sbx-list.component.html',
  styleUrls: ['./sbx-list.component.scss'],
})
export class SbxListComponent
  extends FieldType<FieldArrayTypeConfig>
  implements OnInit
{
  internalModel: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    desc: Object;
    id: number;
    value: any;
  }[];
  @ViewChild('textInput') textInput;

  ngOnInit() {
    this.formControl.valueChanges.subscribe(() => {
      this.parseInternalModel();
    });
    this.parseInternalModel();
  }

  updateModel() {
    const values = this.internalModel.map((item) => item.value) || [];
    while (this.formControl.controls.length < values.length) {
      this.formControl.push(new FormControl({}));
    }
    while (this.formControl.controls.length > values.length) {
      this.formControl.removeAt(0);
    }
    this.formControl.setValue(values);
  }

  parseInternalModel() {
    this.internalModel = this.field.model[String(this.field.key)].map((item, i) => {
      return {
        desc: this.field.templateOptions.valueType,
        id: i++,
        value: item,
      };
    });
  }

  displayItem(item) {
    return item.value;
  }

  removeItem(item) {
    for (let i = 0; i < this.internalModel.length; ++i) {
      if (this.internalModel[i].id === item.id) {
        this.internalModel.splice(i, 1);
        break;
      }
    }
    this.updateModel();
  }

  addItem(value) {
    const ids = this.internalModel.map((item) => item.id);
    let newId = 0;
    if (ids.length) {
      newId = Math.max(...ids) + 1;
    }
    this.internalModel.push({
      desc: this.field.templateOptions.valueType,
      id: newId,
      value: value,
    });

    this.updateModel();
  }

  parseInput() {
    this.addItem(this.textInput.nativeElement.value);
    this.textInput.nativeElement.value = '';
  }

  onKeyUp(event) {
    if (event.key === 'Enter' && event.target.value) {
      this.parseInput();
    }
  }
}
