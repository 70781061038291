import angular from 'angular';
import sbBootstrap from '../lib/bootstrap/bootstrap';
import Tools from '../lib/tools';
import Form from '../lib/form/form';
import Table from '../lib/table';
import Ticket from '../lib/tickets';
import ListPage from '../listpage';
import Promise from '../lib/promise';

import {
  sbHrTicketsAdmin,
  sbHrTicketsPage,
  sbHrCompleteButton,
  sbHrTasksReport,
  sbOnboardingTaskReportMenu,
} from './onboarding';

/**
 * @ngdoc overview
 * @name sb.onboarding
 *
 * @description
 * This module houses hr onboarding
 */
export default angular
  .module('sb.onboarding', [sbBootstrap, Tools, Form, Table, Ticket, ListPage, Promise])
  .directive('sbHrTicketsPage', sbHrTicketsPage)
  .directive('sbHrTasksReport', sbHrTasksReport)
  .component('sbHrCompleteButton', sbHrCompleteButton)
  .component('sbOnboardingTaskReportMenu', sbOnboardingTaskReportMenu)
  .component('sbHrTicketsAdmin', sbHrTicketsAdmin).name;
