<div class="sbx-tree-view">
  <div *ngIf="!includeRoot && root.leaves.length == 0">
    <span>&lt; empty &gt;</span>
  </div>

  <div *ngIf="includeRoot" class="sbx-tree-view-root">
    <span>{{ root.name }}</span>
  </div>

  <div class="sbx-tree-view-children">
    <sbx-tree-node-view
      *ngFor="let node of root.leaves"
      [depth]="includeRoot ? 1 : 0"
      [root]="node"
    >
    </sbx-tree-node-view>
  </div>
</div>
