import angular from 'angular';

// sb-form-name attribute cannot have illegal javascript identifier chars
const getSubFormName = (name) => {
  if (name) {
    return `${name.replace('-', '_')}_address_form`;
  }
  return 'address';
};

/**
 * @ngdoc service
 * @name sb.lib.form.AddressService
 * @requires sb.lib.url.BackendLocation
 *
 * @description
 * The AddressService can be used to fetch server information about addresses.
 */
export const AddressService = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  function (SimpleHTTPWrapper, BackendLocation) {
    return {
      /**
       * @ngdoc method
       * @name sb.lib.form.AddressService#getForm
       * @methodOf sb.lib.form.AddressService
       *
       * @description
       * Get the form definition of an address form.
       *
       * @param {string} countryCode Unique country code to fetch the form. This
       *    may also be a schema name directly if `isSchema` is true. A falsey
       *    value will return th default address form.
       * @param {boolean} [isSchema=false] Indication that countryCode is a schema
       *    name directly.
       *
       * @returns {promise} Returns a promise that resolves with the form def.
       */
      getForm(countryCode, isSchema) {
        const country = countryCode || 'DEFAULT';
        const params = {};
        if (isSchema) {
          params.isSchema = true;
        }
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.entity(1) + 'address/' + country,
            params: params,
          },
          `Failed to get address for ${country}.`,
        );
      },
    };
  },
]; // end AddressService

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbRecompileOn
 * @restrict A
 *
 * @description
 * This directive can recompile a section of html on a trigger. Used for
 * address form, since angular formly cannot handle changing fields well.
 *
 * @element ANY
 *
 * @param {template} sbRecompileOn Watch expression to fire recompile on.
 */
export const sbRecompileOn = [
  '$compile',
  '$parse',
  function ($compile, $parse) {
    return {
      restrict: 'A',
      scope: true,
      priority: 700, // so we are higher than ngIf
      compile(cElement, cAttrs) {
        const parsedTemplateFn = $parse(cAttrs.onCompile);
        const rawElement = cElement.clone();
        if (cAttrs.ngIf) {
          throw new Error('Do not use sbRecompileOn on the same element as ngIf');
        }
        return function (scope, element, attrs) {
          const stop = scope.$parent.$watch(attrs.sbRecompileOn, (nv, ov) => {
            if (nv === ov) {
              // Small optimization: We expect angular to have already compiled this
              // on init; therefore, we need not do it again. Only on first real
              // change of the data in attrs.sbRecompileOn.
              return;
            }
            const newElm =
              parsedTemplateFn(scope.$parent, { $element: rawElement }) || rawElement;
            element.replaceWith($compile(newElm)(scope.$parent));
            // this attribute _itself_ is part of the recompile content
            // stop to avoid proliferating watchers
            stop();
            scope.$destroy();
          });
        };
      },
    };
  },
]; // end sbRecompileOn

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbAddressForm
 * @restrict E
 *
 * @description
 * This directive is for displaying an address form. It will automatically
 * manage changing country code changes.
 *
 * @element ANY
 * @param {expression} ngModel Model object. Subproperties correlate to address
 *    fields.
 * @param {expression} sbAddressFormErrors Errors object.
 * @param {template} sbAddressFormName Field name for address (for namespace).
 * @param {template} sbAddressFormValueSchema Static value schema name.
 * @param {array} [sbAddressFormReadonlyFields=undefined] Names of fields that
 *    should be *overidden* as read-only.
 * @param {array} [sbAddressFormRequiredFields=undefined] Names of fields that
 *    should be *overidden* as required.
 * @param {object} [sbAddressFormTextFieldOptions=undefined] ngModelOptions
 *    overrides for any sub textlines.
 *
 * @example
   <sb-address-form
     data-sb-address-form-name="{{ ::options.key }}"
     data-sb-address-form-errors="errors.address"
     data-sb-address-form-value-schema="us-county"
     data-sb-address-form-readonly-fields="['country','state']"
     data-sb-address-form-required-fields="['country']"
     data-ng-model="model.address"></sb-address-form>
 */
export function sbAddressForm() {
  return {
    restrict: 'E',
    require: {
      ngModelCtrl: 'ngModel',
      formCtrl: '^?form',
    },
    template: require('./templates/widgets/address.html'),
    scope: {
      model: '=ngModel',
      errors: '=sbAddressFormErrors',
      prefix: '@sbAddressFormName',
      valueSchema: '@sbAddressFormValueSchema',
      readOnlyFields: '&sbAddressFormReadonlyFields',
      requiredFields: '&sbAddressFormRequiredFields',
      textFieldOptions: '&sbAddressFormTextFieldOptions',
    },
    link: function (scope, element, attrs, { formCtrl }) {
      if (!formCtrl) {
        return;
      }
      const name = `formly_${getSubFormName(attrs.name)}`;
      scope.$watch(
        () => formCtrl.$submitted && formCtrl[name],
        (subFormCtrl) => {
          if (subFormCtrl) {
            subFormCtrl.$setSubmitted();
          }
        },
      );
    },
    controller: [
      '$scope',
      '$attrs',
      'AddressService',
      'PromiseErrorCatcher',
      function ($scope, $attrs, AddressService, PromiseErrorCatcher) {
        const readOnlyLookup = {};
        const requiredLookup = {};
        angular.forEach($scope.readOnlyFields(), (name) => {
          readOnlyLookup[name] = true;
        });
        angular.forEach($scope.requiredFields(), (name) => {
          requiredLookup[name] = true;
        });

        $scope.addSbFormName = ($element) => {
          $element.find('sb-form').attr('sb-form-name', getSubFormName($attrs.name));
          return $element;
        };

        $scope.$watch('model.country', () => {
          if (!$scope.model) {
            $scope.model = {};
          }
          const { country: oldCountry } = $scope.model;
          AddressService.getForm(
            $scope.valueSchema || oldCountry,
            Boolean($scope.valueSchema),
          )
            .then((data) => {
              const { country } = $scope.model || {};
              if (oldCountry !== country) {
                // Don't change fields if country has changed again
                return;
              }
              $scope.fields = data.fields;
              angular.forEach($scope.fields, (field) => {
                if (readOnlyLookup[field.key]) {
                  field.templateOptions.readOnly = true;
                }
                if (requiredLookup[field.key]) {
                  field.templateOptions.required = true;
                } else if (!$attrs.required) {
                  // if the parent address is not required, we must make
                  // the sub fields not required.
                  field.templateOptions.required = false;
                }
              });
              const textOptions = $scope.textFieldOptions();
              if (textOptions) {
                angular.forEach($scope.fields, (field) => {
                  if (field.type === 'text' || field.type.indexOf('-textline') >= 0) {
                    angular.extend(field, { modelOptions: textOptions });
                  }
                });
              }
            })
            .catch(PromiseErrorCatcher);
        });
      },
    ],
  };
} // end sbAddressForm
