/**
 * @ngdoc directive
 * @name sb.lib.messages.directive:iframe
 * @restrict E
 *
 * @description
 * This directive adds angular support for iframe message passing. See {@link
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * window.postMessage()} on the MDN. Messages may be passed down into the iframe
 * by publishing the event `iframe::sendMessage` with a message content as the
 * parameter.
 *
 * @element iframe
 * @param {expression} [sbOnMessage=undefined] This expression will be evaluated
 *    when the iframe passes a message up. The expression context also includes
 *    the standard `$event` object as well as `$message` for just the contents
 *    of the message.
 */
const iframe = [
  '$parse',
  '$window',
  function ($parse, $window) {
    return {
      restrict: 'E',
      compile: function ($element, attrs) {
        let onRecieve;
        if (attrs.sbOnMessage) {
          onRecieve = $parse(attrs.sbOnMessage, null, true);
        }
        return function (scope, element) {
          function messageCallback(evt) {
            scope.$apply(() => {
              onRecieve(scope, { $event: evt, $message: evt.data });
            });
          }
          if (attrs.name) {
            const $innerWindow = element[0].contentWindow;
            scope.$on(attrs.name + '::sendMessage', (evt, message) => {
              $innerWindow.postMessage(message, '*');
            });
          }
          if (onRecieve) {
            $window.addEventListener('message', messageCallback, false);
            scope.$on('$destroy', () => {
              $window.removeEventListener('message', messageCallback, false);
            });
          }
        };
      },
    };
  },
]; // end iframe

export default iframe;
