import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxEditorComponent } from './sbx-editor.component';
import { SbxExhibitModalComponent } from './plugins/AddOrEditExhibit/sbx-exhibit-modal/sbx-exhibit-modal.component';
import { SbxTermPillService } from './plugins/AddOrEditTerm/AddOrEditTerm';
import { SbxReferencePillService } from './plugins/AddOrEditReference/AddOrEditReference';
import { SbxExhibitPillService } from './plugins/AddOrEditExhibit/AddOrEditExhibit';
import { SbxInsertClauseService } from './plugins/insertClause/InsertClause';
import { SbxSoftBreakService } from './plugins/SoftBreak/SoftBreak';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxTableModule } from '../sbx-table/sbx-table.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';

@NgModule({
  imports: [
    CommonModule,
    SbxModalModule,
    SbxButtonModule,
    SbxTableModule,
    SbxIconModule,
  ],
  declarations: [SbxEditorComponent, SbxExhibitModalComponent],
  providers: [
    SbxTermPillService,
    SbxReferencePillService,
    SbxExhibitPillService,
    SbxInsertClauseService,
    SbxSoftBreakService,
  ],
  exports: [SbxEditorComponent],
})
export class SbxEditorModule {
  static entryComponents = [SbxEditorComponent];

  static forRoot(): ModuleWithProviders<SbxEditorModule> {
    return {
      ngModule: SbxEditorModule,
    };
  }
}
