import { Injectable } from '@angular/core';
import { SbxHttpClient } from '@/core/http';
import { Observable, of } from 'rxjs';
import {
  SbxTreeNode,
  tree as constructTree,
  node as constructNode,
} from '../sbx-tree.model';

export interface ISettingsProfile {
  id?: string;
  settingsType: string;
  title: string;
  parentId: string;
  creatorId?: string;
  isDefault?: boolean;
  metadata?: Record<string, unknown>;
}

export interface ISettingsProfileType {
  rootProfileId: string;
  profiles: ISettingsProfile[];
  form: Record<string, unknown>;
}

const VALID_PROFILE_TYPES = ['new-hire', 'employee'];

function validateProfileType(profileType: string) {
  if (!VALID_PROFILE_TYPES.includes(profileType)) {
    throw new Error('Invalid profile type provided.');
  }
  return profileType;
}

function validateProfile(profile: ISettingsProfile, requireId = false) {
  validateProfileType(profile.settingsType);
  if (requireId && !profile.id) {
    throw new Error('Profile must be created before it can be modified.');
  }
  return profile;
}

function profileTypePath(profileType: string) {
  validateProfileType(profileType);
  return 'settings-profile-types/' + profileType;
}

function profilePath(profileId: string) {
  return 'settings-profiles/' + profileId;
}

@Injectable({ providedIn: 'root' })
export class SettingsProfileService {
  getProfileTypeCache: {
    data?: ISettingsProfileType;
    timestamp?: number;
  } = {};

  constructor(private http: SbxHttpClient) {}

  getProfileType(profileType: string) {
    if (
      !this.getProfileTypeCache.data ||
      // 10 second cache
      this.getProfileTypeCache.timestamp < new Date().getTime() - 1 * 1000 * 10
    ) {
      this.getProfileTypeCache.timestamp = new Date().getTime();
      validateProfileType(profileType);
      const settingsProfileObservable = this.http
        .entity('2')
        .get<ISettingsProfileType>(profileTypePath(profileType));

      settingsProfileObservable.subscribe((data) => {
        this.getProfileTypeCache.data = data;
      });

      return settingsProfileObservable;
    }
    return of(this.getProfileTypeCache.data);
  }

  getProfile(profileId: string): Observable<ISettingsProfile> {
    return this.http.entity('2').get<ISettingsProfile>(profilePath(profileId));
  }

  isEnabled(): Observable<boolean> {
    return this.http.entity('2').get('settings-profiles/enabled');
  }

  createProfile(profile: ISettingsProfile): Observable<ISettingsProfile> {
    validateProfile(profile);
    this.getProfileTypeCache = {};
    return this.http
      .entity('2')
      .post<ISettingsProfile>(profileTypePath(profile.settingsType), {
        params: {
          title: profile.title,
          parentId: profile.parentId,
        },
      });
  }

  updateProfile(profile: ISettingsProfile): Observable<ISettingsProfile> {
    validateProfile(profile, true);
    this.getProfileTypeCache = {};
    return this.http.entity('2').post<ISettingsProfile>(profilePath(profile.id), {
      params: {
        title: profile.title,
      },
    });
  }

  deleteProfile(profile: ISettingsProfile): Observable<ISettingsProfile> {
    validateProfile(profile, true);
    this.getProfileTypeCache = {};
    return this.http.entity('2').delete<ISettingsProfile>(profilePath(profile.id));
  }

  constructProfileTree(profileType: ISettingsProfileType): SbxTreeNode {
    const nodeMap = new Map<string, SbxTreeNode<ISettingsProfile>>();
    const rootId = profileType.rootProfileId;
    const orphanNodes: ISettingsProfile[] = [];
    nodeMap.set(rootId, constructTree<ISettingsProfile>());

    profileType.profiles.forEach((profile) => {
      nodeMap.set(profile.id, constructNode<ISettingsProfile>(profile.title, profile));
    });

    nodeMap.forEach((node) => {
      if (node.data.parentId) {
        if (nodeMap.has(node.data.parentId)) {
          const parentNode = nodeMap.get(node.data.parentId);
          parentNode.add(node);
        } else {
          orphanNodes.push(node.data);
        }
      }
    });

    return nodeMap.get(rootId);
  }

  markWhitelistedSelectable(root: SbxTreeNode<ISettingsProfile>, whitelist: string[]) {
    root.getNodes().forEach((n) => {
      let cur = n;
      n.selectable = false;
      // If any node in a chain is on a whitelist, this node is selectable
      do {
        if (whitelist.indexOf(cur.data.id) !== -1) {
          n.selectable = true;
          break;
        }
        cur = cur.parent;
      } while (cur !== null);
    });
  }

  markNonDefaultRemovable(root: SbxTreeNode<ISettingsProfile>) {
    root.getNodes().forEach((n) => {
      n.removable = !n.data.isDefault;
    });
  }
}
