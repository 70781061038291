import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxPdfViewerComponent } from './sbx-pdf-viewer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxPdfViewerComponent],
  exports: [SbxPdfViewerComponent],
})
export class SbxPdfViewerModule {
  static entryComponents = [SbxPdfViewerComponent];

  static forRoot(): ModuleWithProviders<SbxPdfViewerModule> {
    return {
      ngModule: SbxPdfViewerModule,
    };
  }
}
