import { Component, Inject } from '@angular/core';
import { TailoredProcessListService } from './service/TailoredProcessList.service';
import { Observable } from 'rxjs';
import { Downgrade } from '@/shared/downgrade';

const SELECTOR = 'sbx-tailored-process-list';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-tailored-process-list.component.html',
  styleUrls: ['sbx-tailored-process-list.component.scss'],
})
export class SbxTailoredProcessListComponent {
  TailoredProcessListData$: Observable<any>;
  tableColumns: any;

  constructor(
    @Inject(TailoredProcessListService) public service: TailoredProcessListService,
  ) {}
}
