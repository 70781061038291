import angular from 'angular';
import Form from '../../lib/form/form';
import RX from '../../lib/rx';
import Bootstrap from '../../lib/bootstrap/bootstrap';
import bindHtmlCompile from 'angular-bind-html-compile';

import { sbSelectResolutions, ResolutionWIModel } from './select';
import { ExhibitListing } from './exhibit';
import { $resolutionEditModal, $selectEditResolutionModal } from './modal';
import { sbResolutionGroups } from './group';

export default angular
  .module('sb.workitem.resolution', [Form, RX, Bootstrap, bindHtmlCompile])

  .component('sbSelectResolutions', sbSelectResolutions)
  .component('sbResolutionGroups', sbResolutionGroups)
  .factory('ResolutionWIModel', ResolutionWIModel)
  .factory('ExhibitListing', ExhibitListing)
  .factory('$resolutionEditModal', $resolutionEditModal)
  .factory('$selectEditResolutionModal', $selectEditResolutionModal).name;
