import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxProcessSummaryComponent } from './sbx-process-summary.component';
import { UpgradedModule } from '../upgraded/upgraded.module';

@NgModule({
  imports: [CommonModule, SbxLoaderModule, UpgradedModule],
  declarations: [SbxProcessSummaryComponent],
  exports: [SbxProcessSummaryComponent],
})
export class SbxProcessSummaryModule {}
