<sbx-container [fluid]="true">
  <sbx-row>
    <sbx-col [lg]="12" class="sbx-flex">
      <div *ngIf="isMobile" class="sbx-hamburger-button" (click)="openMobileMenu()">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <a [sbxRouterLink]="getLogoPath()">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </a>

      <sbx-icon
        class="sbx-logo-plus-logo"
        type="plus"
        *ngIf="
          appConfig.config?.entityInfo?.partnerLogoUrl ||
          appConfig.rootConfig?.entityInfo?.partnerLogoUrl
        "
      ></sbx-icon>

      <div class="sbx-partner-logo-container">
        <img
          class="sbx-partner-logo"
          [src]="
            appConfig.config?.entityInfo?.partnerLogoUrl ||
            appConfig.rootConfig?.entityInfo?.partnerLogoUrl
          "
          *ngIf="
            appConfig.config?.entityInfo?.partnerLogoUrl ||
            appConfig.rootConfig?.entityInfo?.partnerLogoUrl
          "
        />
      </div>

      <sbx-dropdown
        *ngIf="appConfig.currentEntity"
        class="sbx-dropdown sbx-entities"
        [dropDownTemplate]="entitiesTemplate"
        [disabled]="appConfig.userInfo.stakes.length === 0"
        #entitiesDropdown
      >
        <ng-container
          *ngTemplateOutlet="
            dropdownContent;
            context: {
              $implicit: appConfig.currentEntity?.title,
              id: 'current-stake',
              disabled: appConfig.userInfo.stakes.length === 0
            }
          "
        ></ng-container>
      </sbx-dropdown>

      <div class="sbx-flex sbx-header-right">
        <sbx-dropdown
          *ngIf="appConfig.userProfile"
          class="sbx-dropdown sbx-profile"
          [dropDownTemplate]="profileTemplate"
          #profileDropdown
        >
          <div class="sbx-dropdown-content">
            Hello {{ appConfig.userProfile.firstName }}!
            <sbx-icon type="triangleDown"></sbx-icon>
          </div>
        </sbx-dropdown>

        <div class="sbx-notifications-container" *ngIf="appConfig.currentEntity">
          <ng-container
            *ngIf="sbxUserNotifications.notifications | async as notifications"
          >
            <sbx-dropdown
              #notificationsDropdown
              class="sbx-dropdown sbx-notifications"
              [dropDownTemplate]="notificationsTemplate"
              [disabled]="!hasNotifications(notifications)"
            >
              <div
                class="sbx-notifications-badge"
                *ngIf="hasNotifications(notifications); else noNotificationsBadge"
                (click)="handleNotificationsDropdownClick()"
              >
                {{ getNotificationsCount(notifications) }}
              </div>
              <ng-template #noNotificationsBadge>
                <sbx-popover
                  sbxPopoverContent="Your tasks will appear here"
                  sbxPopoverPlacement="bottom-right"
                >
                  <sbx-icon
                    type="inbox"
                    class="sbx-no-tasks-icon"
                    (click)="handleNoNotificationsDropdownClick()"
                  ></sbx-icon>
                </sbx-popover>
              </ng-template>
            </sbx-dropdown>
          </ng-container>
        </div>
      </div>
    </sbx-col>
  </sbx-row>
</sbx-container>

<ng-template #logo>
  <img
    class="sbx-logo"
    [src]="
      isMobile
        ? sbxUrlService.assetUrl('img/logo-mobile.svg')
        : sbxUrlService.assetUrl('img/logo-desktop.svg')
    "
  />
</ng-template>

<ng-template #dropdownContent let-title let-id="id" let-disabled="disabled">
  <div
    class="sbx-dropdown-content"
    [ngClass]="{ 'sbx-disabled': disabled }"
    [attr.id]="id"
  >
    {{ title }}
    <sbx-icon type="triangleDown" *ngIf="!disabled"></sbx-icon>
  </div>
</ng-template>

<ng-template #entitiesTemplate>
  <ng-container *ngIf="appConfig.userInfo.stakes.length > 0">
    <div class="sbx-entities-list-title">Switch to another company</div>
    <ul
      *ngIf="appConfig.userInfo.stakes.length <= 10; else entitySearchCompany"
      class="sbx-dropdown-menu sbx-entities-list"
    >
      <li *ngFor="let stake of appConfig.userInfo.stakes">
        <span (click)="changeEntity(stake.name)">{{ stake.title }}</span>
      </li>
    </ul>
    <ng-template #entitySearchCompany>
      <sbx-search-company></sbx-search-company>
    </ng-template>
    <sbx-button
      icon="share"
      title="View all my companies"
      (click)="navigateToEntitiesPage()"
      class="sbx-all-entities-button"
    ></sbx-button>
  </ng-container>
</ng-template>

<ng-template #profileTemplate>
  <ul class="sbx-dropdown-menu">
    <li>
      <a
        *ngIf="appConfig.userProfile.profileUrl"
        [href]="appConfig.userProfile?.profileUrl"
      >
        Manage Profile
      </a>
    </li>
    <li>
      <span class="sbx-logout" (click)="logout()">Sign Out</span>
    </li>
  </ul>
</ng-template>

<ng-template #notificationsTemplate>
  <div class="sbx-notifications-dropdown-menu">
    <ul
      class="sbx-notifications-list"
      *ngFor="let notificationsGroup of sbxUserNotifications.notifications | async"
    >
      <li *ngIf="notificationsGroup.title" class="sbx-notifications-title">
        {{ notificationsGroup.title }}
      </li>
      <li *ngFor="let notification of notificationsGroup.items">
        <sbx-icon
          [type]="notification.type === 'workitem' ? 'exclamationTriangle' : 'envelope'"
        ></sbx-icon>
        <div class="sbx-content">
          <strong>
            {{
              notification.type === 'workitem'
                ? notification.processName
                : notification.title
            }}
          </strong>
          <br />
          <a
            [href]="notification.link"
            *ngIf="notification.type === 'workitem'"
            class="sbx-inner-content"
          >
            {{ notification.workitemName }}
          </a>
          <span
            class="sbx-inner-content sbx-clickable"
            *ngIf="notification.type === 'message'"
            (click)="openNotification(notification)"
          >
            {{ notification.content | stripHtml }}
          </span>
        </div>

        <a [href]="notification.link" *ngIf="notification.type === 'workitem'">
          <sbx-icon type="share"></sbx-icon>
        </a>

        <span
          class="sbx-clickable"
          *ngIf="notification.type === 'message'"
          (click)="handleNotificationDelete(notification)"
        >
          <sbx-icon type="delete"></sbx-icon>
        </span>
      </li>
    </ul>
    <div *ngIf="showNotificationsClearButton" class="sbx-clear-all-messages-container">
      <sbx-button
        icon="delete"
        [disabled]="disableNotificationsClearButton"
        title="Clear all messages"
        (click)="handleAllNotificationsDelete()"
      ></sbx-button>
    </div>
  </div>
</ng-template>

<ng-template #notificationModalTemplate let-notification="notification">
  <sbx-modal-header> MESSAGE: {{ notification?.title }} </sbx-modal-header>

  <sbx-modal-content>
    <div [innerHTML]="notification.content | html"></div>
  </sbx-modal-content>

  <sbx-modal-footer>
    <sbx-button
      title="Dismiss & close"
      theme="primary"
      (click)="dismissAndClose(notification)"
    ></sbx-button>
    <sbx-button
      title="Close"
      theme="secondary"
      (click)="sbxModalService.dismiss()"
    ></sbx-button>
  </sbx-modal-footer>
</ng-template>
