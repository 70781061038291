<ul class="sbx-action-menu {{ appearance.aligment }}" *ngIf="actions.length > 0">
  <ng-container
    *ngTemplateOutlet="actionsList; context: { $implicit: uncollapsedActions }"
  ></ng-container>

  <sbx-dropdown
    [dropDownTemplate]="actionsListDropdown"
    *ngIf="hasVisibleActions(collapsedActions)"
    #dropdownRef
  >
    <li class="sbx-action-menu-dropdown {{ appearance.type }}">
      <sbx-icon *ngIf="appearance.type === 'icon'" type="bars"></sbx-icon>
      <sbx-button
        *ngIf="appearance.type === 'button'"
        [title]="appearance.title"
        [icon]="appearance.icon"
        iconRight="triangleDown"
      ></sbx-button>
    </li>
  </sbx-dropdown>
</ul>

<ng-template #actionsListDropdown>
  <ul class="sbx-action-menu dropdown">
    <ng-container
      *ngTemplateOutlet="actionsList; context: { $implicit: collapsedActions }"
    ></ng-container>
  </ul>
</ng-template>

<ng-template #actionsList let-actions>
  <ng-container *ngFor="let action of actions">
    <ng-container *ngIf="!action.hidden">
      <ng-container
        *ngTemplateOutlet="actionsListItem; context: { $implicit: action }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #actionsListItem let-action>
  <li
    (click)="handleClick(action, $event)"
    sbxPopover
    [sbxPopoverTitle]="action.popoverTitle"
    [sbxPopoverContent]="action.popoverContent"
    [sbxPopoverTemplateContext]="action.popoverTemplateContext"
    [sbxPopoverTriggers]="action.popoverTriggers || 'mouseenter:mouseleave'"
  >
    <div
      class="sbx-list-item-inner"
      [ngClass]="{
        active: (action.click && !action.disabled) || action.popoverTriggers === 'click'
      }"
    >
      <sbx-icon [type]="action.icon"></sbx-icon>
      <span>{{ action.title }}</span>
    </div>
  </li>
</ng-template>
