import { Component, HostBinding, Input } from '@angular/core';
import { Downgrade } from '../downgrade';

/**
 *  Shoobx-Themed container
 *
 *  Centered with standard gutter width and media breakpoints.
 */
@Downgrade.Component('ngShoobx', 'sbx-container')
@Component({
  selector: 'sbx-container',
  templateUrl: './sbx-container.component.html',
  styleUrls: ['sbx-container.component.scss'],
})
export class SbxContainerComponent {
  @Input() fluid = false;
  @HostBinding('class.sbx-fluid') get isFluid() {
    return this.fluid;
  }
}
