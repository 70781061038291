import { NgModule } from '@angular/core';
import { SbxScrollableComponent } from './sbx-scrollable.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [NgScrollbarModule],
  declarations: [SbxScrollableComponent],
  exports: [SbxScrollableComponent],
})
export class SbxScrollableModule {}
