import angular from 'angular';

/**
 * @ngdoc filter
 * @name sb.lib.form.filter:stripTrailingZeros
 *
 * @description
 * This filter will remove trailing zeros from a number.
 * Ex: 1.0001000 becomes 1.0001
 */
export function stripTrailingZeros() {
  function replace(fullString, numbersAfterDot) {
    return numbersAfterDot ? '.' + numbersAfterDot : '';
  }
  return (strNumber) => strNumber && strNumber.replace(/\.(\d*[1-9])?0+$/, replace);
}

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.tools:Formatters
 *
 * @description
 * This is used to lookup formatter functions. Generally one wants to use the higher
 * level `sbFormat` filter instead.
 *
 * @param {string} type The lookup key (something like `'string'`).
 * @param {function} [defualtFunc=idenity] A defualt function if the lookup fails.
 *
 * @returns {function} The formatter function itself.
 */
export const Formatters = [
  '$filter',
  function ($filter) {
    // Numbers
    const STRINGIFY_NUMBER = $filter('number');
    const REMOVE_TRAILING_ZEROS = $filter('stripTrailingZeros');
    const NUMBER_FILTER = (val, precision) => {
      const stringNumber = STRINGIFY_NUMBER(val, precision);
      return REMOVE_TRAILING_ZEROS(stringNumber);
    };

    // Dates
    const DEFAULT_DATE_FORMAT = 'MMMM d, y';
    const ORIG_DATE_FILTER = $filter('date');
    const DATE_FILTER = (v, f = DEFAULT_DATE_FORMAT, tz) => ORIG_DATE_FILTER(v, f, tz);

    // Stakeholder
    const SH_FILTER = $filter('stakeholderName');

    // Bool
    const BOOLEAN_FILTER = $filter('fromBoolean');

    const LOOKUPS = {
      number: NUMBER_FILTER,
      'bool-radios': BOOLEAN_FILTER,
      'number-textline': NUMBER_FILTER,
      date: DATE_FILTER,
      stakeholder: SH_FILTER,
    };
    return ({ templateOptions }, defaultFunc = angular.identity) => {
      const { originalType } = templateOptions;
      const formatter = LOOKUPS[originalType] || defaultFunc;
      let args = [];
      if (originalType.includes('number')) {
        args = [templateOptions.precision];
      }
      return (value) => formatter(value, ...args);
    };
  },
];

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:sbFormatField
 *
 * @description
 * A easy filter for Shoobx field formatting!
 *
 * @example
   {{ 1000 | sbFormat:options }} -> 1,000
   {{ 2016-01-01 | sbFormat:options }} -> January 1st, 2016
 */
export const sbFormatField = [
  'Formatters',
  function (Formatters) {
    const DEFAULT_DEF = { templateOptions: { originalType: 'string' } };
    return (val, fieldDefinition = DEFAULT_DEF) => Formatters(fieldDefinition)(val);
  },
];

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:stakeholderName
 *
 * @description
 * A filter formatter for stakeholder data models.
 */
export function stakeholderName() {
  return (val) => ((val || {}).sh || {}).fullName || 'Unknown Stakeholder';
}

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:fromBoolean
 *
 * @description
 * A filter formatter for boolean data models.
 */
export function fromBoolean() {
  return (val) => {
    return val ? 'Yes' : 'No';
  };
}

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:createAnchors
 *
 * @description
 * A filter for create hyperlinks if there are any urls in provided string.
 */
export const createAnchors = [
  function () {
    return (str) =>
      str &&
      str
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/(http[^\s]+)/g, '<a href="$1">$1</a>');
  },
];

/**
 * @ngdoc filter
 * @name sb.lib.tools.filter:stripHTML
 *
 * @description
 * Takes a string and removes any html characters from it, so it can be displayed
 * as plain without all the nasty user unfriendly html.
 */
export const stripHTML = [
  function () {
    return (htmlString) => htmlString && htmlString.replace(/(<([^>]+)>)/gi, '');
  },
]; // end stripHTML
