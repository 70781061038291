import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';

import { SbxDealRoomComponent } from './sbx-deal-room.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxDealRoomComponent],
})
export class SbxDealRoomModule {
  static entryComponents = [SbxDealRoomComponent];

  static forRoot(): ModuleWithProviders<SbxDealRoomModule> {
    return {
      ngModule: SbxDealRoomModule,
    };
  }
}
