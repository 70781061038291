import angular from 'angular';
import { OrderedSet } from 'immutable';

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.resolution.object:ExhibitListing
 *
 * @description
 * This factory function will create an instance of a model for managing the state
 * (ordering, letters, valid IDs, added/deleted items) of an exhibit listing.
 *
 * @param {object} higherContext This is expected to be the state of the context
 *   in which this listing is being created (basically, the state of the resolution
 *   page in which this listing is to be created). It is expected that this context
 *   state will *not change for the lifetime of this listing*. This context must
 *   have this interface at the moment of construction:
 *   @property {string} url Backend base url for API calls.
 *   @property {array} whereas Whereas groups.
 *   @property {array} approved Approved resolution groups.
 *   @property {array} exhibits Array of all exhibits currently uploaded.
 * @param {string} [currentResDocId=undefined] The ID of the group being edited
 *   so the listing will keep "prior" exhibits in check. `undefined` is assumed
 *   to be creation mode.
 */
export const ExhibitListing = [
  'SimpleHTTPWrapper',
  function (SimpleHTTPWrapper) {
    class ExhibitCtrl {
      constructor(context, currentResDocId) {
        this._endpoint = context.url + '/exhibits';
        this._modCallback = angular.noop;
        this._exhibitsMarkedForDeletion = {};
        this._addedExhibits = [];
        const indexArray = context.whereas.length
          ? context.intro
              .concat(context.whereas)
              .concat(context.approved)
              .concat(context.generalAuthority)
          : context.intro.concat(context.approved).concat(context.generalAuthority);
        const docIndexes = indexArray.reduce((accum, group, index) => {
          accum[group.id] = index;
          return accum;
        }, {});

        const currentGroupIndex = angular.isDefined(currentResDocId)
          ? docIndexes[currentResDocId]
          : Infinity;
        const listing = angular.copy(context.exhibits) || [];
        const refExhibitProperities = indexArray.reduce(
          (accum, group, index) => {
            if (index < currentGroupIndex) {
              accum = group.referencedExhibits.reduce((a, exId) => {
                a.immovable[exId] = true;
                return a;
              }, accum);
            }
            if (index !== currentGroupIndex) {
              accum = group.referencedExhibits.reduce((a, exId) => {
                a.undeleteable[exId] = true;
                return a;
              }, accum);
            }
            return accum;
          },
          { immovable: {}, undeleteable: {} },
        );
        this._immovableExhibits = Object.keys(refExhibitProperities.immovable);
        this._undeleteableExhibits = Object.freeze(refExhibitProperities.undeleteable);

        /**
         * @ngdoc property
         * @name listing
         * @propertyOf sb.workitem.resolution.ExhibitListing
         *
         * @description
         * This array will be the correctly ordered state of exhibit objects.
         */
        this._setListing(listing);
      }

      _setListing(list) {
        this.listing = list.filter((ex) => !this._exhibitsMarkedForDeletion[ex.id]);
        this._letters = this.listing.reduce((accum, ex, index) => {
          accum[ex.id] = String.fromCharCode(index + 65);
          return accum;
        }, {});
        this._exLookup = list.reduce((accum, ex) => {
          accum[ex.id] = ex;
          return accum;
        }, {});
      }

      /**
       * @ngdoc method
       * @name onListingModify
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @description
       * Add a callback for whenver exhibits are added/removed from the state; this does
       * not include reorderings. NOTE: only one callback can be added at one time.
       *
       * @param {function} fn Callback function.
       */
      onListingModify(fn) {
        this._modCallback = fn;
      }

      /**
       * @ngdoc method
       * @name isImmutable
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @param {string} exhibitId Queried exhibit ID.
       *
       * @returns {boolean} If true, exhibit cannot be deleted.
       */
      isImmutable(exhibitId) {
        return Boolean(this._undeleteableExhibits[exhibitId]);
      }

      /**
       * @ngdoc method
       * @name getLetter
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @param {string} exhibitId Queried exhibit ID.
       *
       * @returns {string|undefined} The letter for the corresponding ID. This is
       *   `undefined` when exhibit ID is not yet known.
       */
      getLetter(exhibitId) {
        return this._letters[exhibitId];
      }

      /**
       * @ngdoc method
       * @name queuedExhibitDeletions
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @returns {array} The IDs of all the exhibits that have been marked for deletion.
       */
      queuedExhibitDeletions() {
        return Object.keys(this._exhibitsMarkedForDeletion);
      }

      /**
       * @ngdoc method
       * @name addedExhibits
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @returns {array} All exhibit IDs of newly uploaded exhibits.
       */
      addedExhibits() {
        return this._addedExhibits;
      }

      /**
       * @ngdoc method
       * @name getExhibitId
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @param {string} letter Queried exhibit letter.
       *
       * @returns {string|undefined} The exhibit ID of the corresponding lettering.
       */
      getExhibitId(letter) {
        const index = letter.charCodeAt(0) - 65;
        const item = this.listing[index];
        return item && item.id;
      }

      /**
       * @ngdoc method
       * @name getExhibit
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @param {string} exhibitId Queried exhibit ID.
       *
       * @returns {object|undefined} The exhibit or nothing.
       */
      getExhibit(exhibitId) {
        return this._exLookup[exhibitId];
      }

      /**
       * @ngdoc method
       * @name deleteExhibit
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @description
       * "Mark" an exhibit for deletion. NOTE: this is not a peristent to backend change.
       *
       * @param {string} exhibitId Exhibit for deletion.
       */
      deleteExhibit(exhibitId) {
        if (this._undeleteableExhibits[exhibitId]) {
          throw new Error('Cannot delete this exhibit.');
        }
        this._exhibitsMarkedForDeletion[exhibitId] = true;
        this._setListing(this.listing);
        this._modCallback(this.listing);
      }

      /**
       * @ngdoc method
       * @name addExhibit
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @param {object} exhibitFormData Add exhibit form data.
       *
       * @returns {promise} Promise of async success/failure.
       */
      addExhibit(exhibitFormData) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._endpoint,
            data: exhibitFormData,
          },
          'Problem uploading exhibit document.',
        ).then(({ exhibit }) => {
          if (exhibit.id in this._exhibitsMarkedForDeletion) {
            delete this._exhibitsMarkedForDeletion[exhibit.id];
          }
          this._addedExhibits = this._addedExhibits.concat([exhibit]);
          this._setListing(this.listing.concat([exhibit]));
          this._modCallback(this.listing);
          this.normalizeListing([]);
        });
      }

      /**
       * @ngdoc method
       * @name normalizeListing
       * @methodOf sb.workitem.resolution.ExhibitListing
       *
       * @description
       * Call this method to "normalize"/reorder the exhibit listing to be consistent
       * with the current edit context.
       *
       * @param {array} orderedExhibitMentions The IDs of exhibits mentioned in the
       *   current edit context. It is expected to be in the order they are mentioned.
       */
      normalizeListing(orderedExhibitMentions) {
        const { _immovableExhibits, _exLookup } = this;
        const newListing = OrderedSet(
          // Construct a list of all the immovable items:
          _immovableExhibits

            // Then add to the set orderedExhibitMentions
            .concat(orderedExhibitMentions.filter((id) => _exLookup[id]))

            // TODO add in exhibits that are mentioned elsewhere?

            // Then add all items to cover anything not previously in the set
            .concat(Object.keys(_exLookup)),
        );
        this._setListing(newListing.toArray().map((exId) => _exLookup[exId]));
      }
    }

    return (higherContext, currentResDocId) =>
      new ExhibitCtrl(higherContext, currentResDocId);
  },
]; // end ExhibitListing
