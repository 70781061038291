import angular from 'angular';
/**
 * @ngdoc object
 * @kind function
 * @name sb.listpage.object:$trashModal
 *
 * @description
 * This is a high level function for opening a dataroom trash modal.
 *
 * @returns {Promise} Resolves when the user restores at least one item, rejects in all
 *   other cases (dismiss included).
 */
export const $trashModal = [
  '$sbModal',
  function ($sbModal) {
    return () =>
      $sbModal.open({
        backdrop: 'static',
        keyboard: false,
        template: require('./templates/trash-modal.html'),
        bindToController: true,
        controllerAs: 'vm',
        size: 'lg',
        controller: [
          'PromiseErrorCatcher',
          '$confirm',
          'TrashModel',
          function (PromiseErrorCatcher, $confirm, TrashModel) {
            function decideMessageClose() {
              if (this.model.hasRestoredItems) {
                this.$close();
              } else {
                this.$dismiss();
              }
            }
            function empty() {
              $confirm({ body: '<p>Are you sure you want to empty the trash?</p>' })
                .then(() => this.model.empty())
                .then(() => {
                  this.decideMessageClose();
                })
                .catch(PromiseErrorCatcher);
            }
            this.model = TrashModel();
            this.model.init();
            this.empty = empty.bind(this);
            this.decideMessageClose = decideMessageClose.bind(this);
          },
        ],
      }).result;
  },
]; // end $trashModal

/**
 * @ngdoc object
 * @kind function
 * @name sb.dataroom.object:TrashModel
 *
 * @description
 * Call this function to make an instance of trash view model.
 */
export const TrashModel = [
  '$q',
  'BackendLocation',
  'SimpleHTTPWrapper',
  function ($q, BackendLocation, SimpleHTTPWrapper) {
    class Model {
      constructor() {
        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.workitem.TrashModel
         *
         * @description
         * Truthy when async is outstanding.
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name folders
         * @propertyOf sb.workitem.TrashModel
         *
         * @description
         * Array of folder objects in the trash.
         */
        /**
         * @ngdoc property
         * @name documents
         * @propertyOf sb.workitem.TrashModel
         *
         * @description
         * Array of documents objects in the trash.
         */
        this.folders = this.documents = [];

        /**
         * @ngdoc property
         * @name status
         * @propertyOf sb.workitem.TrashModel
         *
         * @description
         * Current async status.
         */
        this.status = null;

        /**
         * @ngdoc property
         * @name hasRestoredItems
         * @propertyOf sb.workitem.TrashModel
         *
         * @description
         * A boolean that is true when at least one item has been restored (this is useful
         * so one knows if they need to reload the dataroom underneath).
         */
        this.hasRestoredItems = false;
      }

      _load(reqConfig, successFn) {
        this.loading = true;
        return SimpleHTTPWrapper(reqConfig)
          .then(
            (data) => {
              successFn(data);
              this.status = null;
            },
            (err) => {
              this.status = err;
              return $q.reject(err);
            },
          )
          .finally(() => {
            this.loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name isEmpty
       * @methodOf sb.workitem.TrashModel
       *
       * @returns {boolean} True if the model has no items.
       */
      isEmpty() {
        return !this.documents.length && !this.folders.length;
      }

      /**
       * @ngdoc method
       * @name empty
       * @methodOf sb.workitem.TrashModel
       *
       * @description Empty the trash of all items.
       *
       * @returns {promise} Resolves on succes and rejects on failure.
       */
      empty() {
        return this._load({ url: Model.url + '/empty', method: 'POST' }, () => {
          this.folders = this.documents = [];
        });
      }

      /**
       * @ngdoc method
       * @name restoreItem
       * @methodOf sb.workitem.TrashModel
       *
       * @description Restore an item from the trash.
       *
       * @param {'document'|'folder'} type Type of item to restore.
       * @param {string} itemId ID of the restored item.
       *
       * @returns {promise} Resolves on succes and rejects on failure.
       */
      restoreItem(type, itemId) {
        const data = { type, id: itemId };
        return this._load({ url: Model.url + '/restore', method: 'POST', data }, () => {
          const key = type + 's';
          this[key] = this[key].filter((item) => item.id !== itemId);
          this.hasRestoredItems = true;
        });
      }

      /**
       * @ngdoc method
       * @name deleteItem
       * @methodOf sb.workitem.TrashModel
       *
       * @description Delete an item from the trash.
       *
       * @param {'document'|'folder'} type Type of item to delete.
       * @param {string} itemId ID of the restored item.
       *
       * @returns {promise} Resolves on succes and rejects on failure.
       */
      deleteItem(type, itemId) {
        const data = { type, id: itemId };
        return this._load(
          // TODO get these params right
          { url: Model.url + '/delete', method: 'POST', data },
          () => {
            const key = type + 's';
            this[key] = this[key].filter((item) => item.id !== itemId);
          },
        );
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.workitem.TrashModel
       *
       * @param {object} [hydration=undefined] Optionally, one my init the model
       *   from this parameter instead of from API.
       *   @property {array<folder>} folders List of folders in model.
       *   @property {array<document>} documents List of documents in model.
       *
       * @returns {promise} Resolves on succes and rejects on failure.
       */
      init(hydration) {
        if (hydration) {
          this.documents = hydration.documents;
          this.folders = hydration.folders;
          return $q.resolve();
        }
        return this._load({ url: Model.url }, (data) => {
          angular.extend(this, data);
        });
      }
    }
    Model.url = BackendLocation.entity('1') + 'trash';

    return () => new Model();
  },
]; // end TrashModel

/**
 * @ngdoc component
 * @name sb.dataroom.component:sbTrashListing
 *
 * @description
 * This component lists trash items with restore and empty actions.
 *
 * @param {object} model A `TrashModel` instance.
 */
export const sbTrashListing = {
  template: require('./templates/trash-listing.html'),
  controllerAs: 'vm',
  bindings: {
    model: '<',
  },
}; // end sbTrashListing
