import angular from 'angular';
/**
 * @ngdoc component
 * @name sb.lib.messages.component:sbObscuredData
 *
 * @description
 * Use this for toggleable obscuring of sensitive data (SSNs, bank account
 * numbers).
 *
 * @param {template} data Raw data string.
 * @param {number} [visibleCharacters=3] Number of characters to show while hidden.
 * @param {number} [starCharacters=8] Number of star characters to show while hidden.
 */
export const sbObscuredData = {
  template: require('./templates/obscured-data.html'),
  controllerAs: 'vm',
  bindings: {
    visibleCharacters: '<?',
    starCharacters: '<?',
  },
  controller: [
    '$attrs',
    function ($attrs) {
      function setData(nv) {
        if (this.hidden && nv.length) {
          this.data = nv.substring(0, this.visibleCharacters) + this.stars;
        } else {
          this.data = nv;
        }
      }
      function toggle() {
        this.hidden = !this.hidden;
        setData.call(this, $attrs.data);
      }

      this.$onInit = () => {
        this.toggle = toggle.bind(this);
        this.hidden = true;

        if (!angular.isNumber(this.visibleCharacters)) {
          this.visibleCharacters = 3;
        }
        if (!angular.isNumber(this.starCharacters)) {
          this.starCharacters = 8;
        }
        this.stars = '*'.repeat(this.starCharacters);

        $attrs.$observe('data', setData.bind(this));
      };
    },
  ],
}; // end sbObscuredData

/**
 * @ngdoc component
 * @name sb.lib.messages.component:sbObscuredInput
 *
 * @description
 * Use this for toggleable obscuring of sensitive data in input form.
 *
 * @param {expression} ngModel Model expression.
 * @param {template} [initModel=undefined] For backwards compat with zope forms.
 */
export const sbObscuredInput = {
  template: require('./templates/obscured-input.html'),
  controllerAs: 'vm',
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    initModel: '@?',
    inputName: '@?',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.toggle = () => {
          this.hidden = !this.hidden;
        };
        this.hidden = true;
        if (this.initModel) {
          this.model = this.initModel;
        }
      };
    },
  ],
}; // end sbObscuredInput
