import angular from 'angular';
/**
 * @ngdoc service
 * @name sb.process.ProcessButtonModel
 *
 * @description
 * Use this service to control buttons within a process. One can disable/enable
 * buttons or add conditions that must be satisfied for submission.
 *
 * @TODO consider making this better by each "namespacing" disable/enable
 * semaphores so that users of this don't have to worry about globally messing
 * up the state of the button.
 */
export const ProcessButtonModel = [
  '$q',
  '$timeout',
  function ($q, $timeout) {
    class ButtonModel {
      constructor() {
        this.$clear();
      }

      $$allPromises(group) {
        return $q.all((group || []).map((item) => item.maker()));
      }

      $$buildChain(i, priors, groups, reject, build) {
        if (i > priors.length) {
          return build;
        }
        const current = build.then(() => this.$$allPromises(groups[priors[i]]), reject);
        return this.$$buildChain(i + 1, priors, groups, reject, current);
      }

      $$callConditions(proms, resolve, reject) {
        // First group promises into an object of { <priotity>: [promise,...],... }
        proms = proms || [];
        const groups = proms.reduce((accum, promise) => {
          const { priority } = promise;
          accum[priority] = (accum[priority] || []).concat([promise]);
          return accum;
        }, {});
        // Now grap these priorities and sort them (then reverse)
        const priors = Object.keys(groups).sort();
        priors.reverse();
        const first = this.$$allPromises(groups[priors[0]]);
        this.$$buildChain(1, priors, groups, reject, first).then(resolve, reject);
      }

      /**
       * @ngdoc method
       * @name $clear
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Clear out the model of all disables and conditions. Generally, this API is
       * not intended for userland. If you find yourself in need of this call, *most
       * likely* you are doing it wrong.
       */
      $clear(doNotClearSubmitConditions = false) {
        angular.extend(this, {
          $$disableCount: 0, // Semaphore for all buttons
          $$disableCountMap: {}, // Semaphore for specific buttons
          $$regexPromises: [], // List of regex promises
        });

        if (!doNotClearSubmitConditions) {
          angular.extend(this, {
            $$promiseMap: {}, // Map of buttonName to array of conditions
            $$promiseMapId: 0, // Primary key increment for promise IDs
          });
        }
      }

      /**
       * @ngdoc method
       * @name disable
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Disable all buttons or a specific button.
       *
       * @param {string} [buttonName=undefined] Name of button to disable. If
       *    `undefined`, then all buttons are disabled.
       */
      disable(buttonName) {
        if (buttonName && this.$$disableCountMap[buttonName]) {
          this.$$disableCountMap[buttonName] += 1;
        } else if (buttonName) {
          this.$$disableCountMap[buttonName] = 1;
        } else {
          this.$$disableCount += 1;
        }
      }

      /**
       * @ngdoc method
       * @name requestEnable
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * *Request* enable of all the buttons or a specific button.
       *
       * @param {string} [buttonName=undefined] Name of button to enable. If
       *    `undefined`, then all buttons are enabled.
       */
      requestEnable(buttonName) {
        if (this.$$disableCountMap[buttonName]) {
          this.$$disableCountMap[buttonName] -= 1;
        } else if (buttonName && !this.$$disableCountMap[buttonName]) {
          this.$$disableCountMap[buttonName] = 0;
        } else if (this.$$disableCount) {
          this.$$disableCount -= 1;
        }
      }

      /**
       * @ngdoc method
       * @name $isDisabled
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Reports if a button should be disabled.
       *
       * @param {string} buttonName Name of button.
       *
       * @returns {boolean} True if the button is disabled.
       *    conditions have been satisfied and is rejected when any fail.
       */
      $isDisabled(buttonName) {
        return this.$$disableCountMap[buttonName] > 0 || this.$$disableCount > 0;
      }

      /**
       * @ngdoc method
       * @name $requestSubmit
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Request a particular button submit of a WI.
       *
       * @param {string} buttonName Name of button that clicked.
       *
       * @returns {promise} This promise is resolved when all submit
       *    conditions have been satisfied and is rejected when any fail.
       */
      $requestSubmit(buttonName) {
        if (this.$$disableCount > 0 || this.$$disableCountMap[buttonName] > 0) {
          return $q.reject();
        }
        const regProms = this.$$regexPromises.filter((item) =>
          item.regex.test(buttonName),
        );
        const namedProms = this.$$promiseMap[buttonName] || [];
        const promises = namedProms.concat(regProms);
        if (!promises.length) {
          return $q.resolve();
        }
        return $q((resolve, reject) => {
          $timeout(
            () => {
              this.$$callConditions(promises, resolve, reject);
            },
            0,
            true,
          );
        });
      }

      /**
       * @ngdoc method
       * @name $removeSubmitCondition
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Remove a submit condition.
       *
       * @param {number} id The unique id of the condition. This was returned
       *    from `$addSubmitCondition`.
       */
      $removeSubmitCondition(id) {
        this.$$promiseMap = Object.keys(this.$$promiseMap || {}).reduce(
          (accum, key) => {
            const items = this.$$promiseMap[key] || [];
            accum[key] = items.filter((item) => item.id !== id);
            return accum;
          },
          {},
        );
      }

      /**
       * @ngdoc method
       * @name $addSubmitCondition
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Adds an async "condition" that must be satisfied before the process
       * can be submitted.
       *
       * @param {string|RexExp} buttonName Name/ID of the button that this condition
       *    is attached too. It may also be a regular expression.
       * @param {function} promiseMaker This function must return a promise
       *    that signifies the condition is met on resolution and is not
       *    met on rejection.
       * @param {number} [priority=0] The oridinal position of this
       *    condition. Higher numbers mean this condition will attempt
       *    resolution earlier in the chain. Conditions of the same priority
       *    are resolved async at the same time.
       *
       * @returns {number} The unique ID of this condtion. Use this ID later
       *    with `$removeSubmitCondition` to remove this condition.
       */
      $addSubmitCondition(buttonName, promiseMaker, priority) {
        const currentId = this.$$promiseMapId;
        const newItem = {
          maker: promiseMaker,
          id: currentId,
          priority: priority || 0,
        };
        if (angular.isObject(buttonName)) {
          newItem.regex = buttonName;
          this.$$regexPromises.push(newItem);
        } else {
          if (!this.$$promiseMap[buttonName]) {
            this.$$promiseMap[buttonName] = [];
          }
          this.$$promiseMap[buttonName].push(newItem);
        }
        this.$$promiseMapId += 1;
        return currentId;
      }

      /**
       * @ngdoc method
       * @name $disableWatch
       * @methodOf sb.process.ProcessButtonModel
       *
       * @description
       * Easy helper factory for `$watch` callbacks. It will disable the button
       * when value is true, enable otherwise.
       *
       * @param {string} [buttonName=undefined] Name/ID of the button that the
       *    callback will affect (`undefined` means all buttons).
       *
       * @returns {function} A callback function.
       */
      $disableWatch(buttonName) {
        return (newValue, oldValue) => {
          if (newValue) {
            this.disable(buttonName);
          } else if (newValue !== oldValue) {
            // We check here to make sure that this is not the first callback
            // so that we don't enable the button while the button is already
            // enabled and ruin the semaphore count.
            this.requestEnable(buttonName);
          }
        };
      }
    }

    return new ButtonModel();
  },
]; // end ProcessButtonModel

/**
 * @ngdoc directive
 * @name sb.process.directive:sbProcessSubmitLink
 * @restrict A
 *
 * @description
 * This is a small directive that allows you to submit workitems to custom
 * handlers from links.  On click, the directive submits a the process.
 *
 * @element a
 * @param {template} sbProcessSubmitLink Name of the button handler to fire.
 * @param {object} [sbProcessSubmitLink=undefined] An object of `{ <name>:
 *    <value>' }` of data to *also* submit with the POST.
 *
 * @example
   <!-- This button will submit the continue button -->
   <a href="#" data-sb-process-submit-link="continue">Continue!</a>

   <!--
   This button will submit the a button named 'alertSh' with the data
   shId=123
   -->
   <a href="#"
     data-sb-process-submit-link="alertSh"
     data-sb-process-submit-link-extra-data="{ shId: '123' }">
     Alert a Stakeholder!
   </a>
 */
export const sbProcessSubmitLink = [
  'SerializeAndSubmitProcessForm',
  '$parse',
  'PromiseErrorCatcher',
  function (SerializeAndSubmitProcessForm, $parse, PromiseErrorCatcher) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const expr = $parse(attrs.sbProcessSubmitLinkExtraData);
        element.click((evt) => {
          evt.preventDefault();
          scope.$apply(() => {
            SerializeAndSubmitProcessForm(attrs.sbProcessSubmitLink, expr(scope)).catch(
              PromiseErrorCatcher,
            );
          });
        });
      },
    };
  },
]; // end sbProcessSubmitLink

/**
 * @ngdoc directive
 * @name sb.process.directive:sbDisableProcessButton
 * @restrict A
 *
 * @description
 * Add this directive to have an easy *declarative* approach to disabling
 * a process button. No controller needed.
 *
 * @element ANY
 * @param {boolean} sbDisableProcessButton Condition that when true will disable
 *    the button.
 * @param {template} [sbDisableProcessButtonName='continue'] Name of the button
 *    to add condition to.
 *
 * @example
   <!-- This will disable the button named signAll when model.isOkay is falsey -->
   <div
     data-sb-disable-process-button="!model.isOkay"
     data-sb-disable-process-button-name="signAll">
     Some content
   </div>
 */
export function sbDisableProcessButton() {
  return {
    restrict: 'A',
    scope: {
      condition: '&sbDisableProcessButton',
      name: '@sbDisableProcessButtonName',
    },
    controller: [
      '$scope',
      'ProcessButtonModel',
      function ($scope, ProcessButtonModel) {
        const name = $scope.name || 'continue';
        $scope.$watch('condition()', ProcessButtonModel.$disableWatch(name));
      },
    ],
  };
} // end sbDisableProcessButton

/**
 * @ngdoc component
 * @name sb.process.component:sbWorkitemRemindButton
 *
 * @description
 * Standard widget to show a remind button. This is self contained and makes the
 * request itself, with success/error callbacks if needed.
 *
 * @param {string} workitemUid The UID of the workitem to remind about.
 * @param {string} [recipient=undefined] Optional stakeholder ID to make reminder specific
 * to
 * @param {expression} [onSuccess=undefined] Expression evaluated on successful
 *   remind request. `$data` is available in namespace.
 * @param {expression} [onFailure=undefined] Expression evaluated on failed
 *   remind request. `$data` is available in namespace.
 */
export const sbWorkitemRemindButton = {
  controllerAs: 'vm',
  template: require('./templates/remind-button.html'),
  bindings: {
    workitemUid: '<',
    recipient: '<?',
    onSuccess: '&?',
    onFailure: '&?',
  },
  controller: [
    '$timeout',
    'StandaloneButtonDisableTime',
    'BackendLocation',
    'SimpleHTTPWrapper',
    function (
      $timeout,
      StandaloneButtonDisableTime,
      BackendLocation,
      SimpleHTTPWrapper,
    ) {
      function click() {
        this.sending = true;
        this.sent = false;
        SimpleHTTPWrapper({
          method: 'POST',
          url: BackendLocation.entity(1) + 'workitems/' + this.workitemUid + '/remind',
          data: { recipient: this.recipient },
        })
          .finally(() => {
            this.sending = false;
          })
          .then(
            ($data) => {
              if (this.onSuccess) {
                this.onSuccess({ $data });
              }
              this.sent = true;
              return $timeout(angular.noop, StandaloneButtonDisableTime);
            },
            ($data) => {
              if (this.onFailure) {
                this.onFailure({ $data });
              }
            },
          )
          .finally(() => {
            this.sent = false;
          });
      }
      this.$onInit = () => {
        this.sending = this.sent = false;
        this.click = click.bind(this);
      };
    },
  ],
}; // end sbWorkitemRemindButton

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessStatusButton
 *
 * @description
 * Standard widget to show a process status open button.
 *
 * @param {string} processId The UID of the process to show status for.
 */
export const sbProcessStatusButton = {
  controllerAs: 'vm',
  template: require('./templates/status-button.html'),
  bindings: {
    processId: '<',
    disableDiscard: '<',
  },
  controller: [
    '$processStatusModal',
    'PromiseErrorCatcher',
    function ($processStatusModal, PromiseErrorCatcher) {
      function openModal() {
        $processStatusModal(this.processId, this.disableDiscard).catch(
          PromiseErrorCatcher,
        );
      }
      this.$onInit = () => {
        this.openModal = openModal.bind(this);
      };
    },
  ],
}; // end sbProcessStatusButton

/**
 * @ngdoc component
 * @name sb.process.component:sbWorkitemClaimButton
 *
 * @description
 * Standard widget to show a claim button. This is self contained and makes the
 * request itself, with success/error callbacks if needed.
 *
 * @param {string} workitemUid The UID of the workitem to be claimed
 *   by the current user..
 * @param {expression} [onSuccess=undefined] Expression evaluated on successful
 *   claim request. `$data` is available in namespace.
 * @param {expression} [onFailure=undefined] Expression evaluated on failed
 *   claim request. `$data` is available in namespace.
 */
export const sbWorkitemClaimButton = {
  controllerAs: 'vm',
  template: require('./templates/claim-button.html'),
  bindings: {
    workitemUid: '<',
    onSuccess: '&?',
    onFailure: '&?',
  },
  controller: [
    'BackendLocation',
    'SimpleHTTPWrapper',
    function (BackendLocation, SimpleHTTPWrapper) {
      function callbackFunc(method) {
        return ($data) => {
          return method && method({ $data });
        };
      }
      function click() {
        this.loading = true;
        SimpleHTTPWrapper({
          method: 'POST',
          url: BackendLocation.entity(1) + 'workitems/' + this.workitemUid + '/claim',
          data: {},
        })
          .then(callbackFunc(this.onSuccess), callbackFunc(this.onFailure))
          .finally(() => {
            this.loading = false;
          });
      }
      this.$onInit = () => {
        this.click = click.bind(this);
      };
    },
  ],
}; // end sbWorkitemClaimButton
