import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import {
  ISbxDocumentReferenceModel,
  SbxDocumentReferenceModelType,
} from './sbx-document-reference.component';

@Component({
  selector: 'sbx-document-waive',
  templateUrl: './sbx-document-waive.component.html',
  styleUrls: ['./sbx-document-waive.component.scss'],
})
export class SbxDocumentWaiveComponent {
  @Input()
  get model() {
    return this.waiveModel;
  }
  set model(val) {
    this.waiveModel = val;
    this.modelChange.emit(this.waiveModel);
  }
  @Output() modelChange = new EventEmitter();
  waiveModel: SbxDocumentWaiveModel;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  waiveDocument() {
    this.model.type = SbxDocumentReferenceModelType.Waive;
  }
}

class SbxDocumentWaiveModel implements ISbxDocumentReferenceModel {
  type: SbxDocumentReferenceModelType;
  documentTitle = '';
}
