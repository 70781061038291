import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxButtonComponent } from './sbx-button.component';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxDisabledModule } from '../sbx-disabled/sbx-disabled.module';

@NgModule({
  imports: [CommonModule, SbxIconModule, SbxDisabledModule],
  declarations: [SbxButtonComponent],
  exports: [SbxButtonComponent],
})
export class SbxButtonModule {}
