<div class="sbx-content-section">
  <img
    [src]="sbxUrlService.assetUrl('img/fidelity/permission-error.svg')"
    alt="permission-error"
  />

  <span class="sbx-content-section-title">Permission Error</span>

  <p class="sbx-content-section-error-message">
    Sorry <span>{{ firstName }}</span
    >, you do not have permission to access this page. If you believe this is a mistake,
    please contact your <span>{{ companyName }}</span> administrator.
  </p>
</div>
<div class="sbx-bottom-section">
  <sbx-button title="GO BACK" theme="secondary" (click)="goBack()"></sbx-button>
</div>
