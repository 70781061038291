import { Component, Inject, Input } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { ISbxIconType } from '@/core/constants/icons';
import { MessagesService } from '@/shared/sbx-message/messages.service';
import { MessageSeverity } from '@/shared/sbx-message/message-severity';
import { SbxTaxAdjustEventModalService } from '@/sbx-tax/sbx-tax-adjust-event-modal/sbx-tax-adjust-event-modal.service';
import { SbxTaxAdjustEventModalModalDataModel } from '@/sbx-tax/sbx-tax-adjust-event-modal/sbx-tax-adjust-event-modal-data.model';
import {
  ISbxSerializeAndSubmitProcessForm,
  SbxSerializeAndSubmitProcessForm,
} from '@/shared/upgraded/serialize-and-submit-process-form.service';
import { SbxTaxService } from '@/sbx-tax/sbx-tax.service';

/**
 * Button to adjust a taxable event taxes, to be used in a workflow.
 * Opens a tax adjustment modal.
 * On save, submits the adjustments to the workflow and,
 * if asked, continues the process with `processaction` button.
 * The action handler should be defined with <xpdl:ExtendedAttribute Name="ui:endpoint:******:action">
 * in the process.
 * Use it with SbxMessagesComponent to show error messages in desired place.
 *
 * Example:
 * <sbx-messages></sbx-messages>
 * <div>
 *     ...
 * </div>
 *   <sbx-tax-adjust-event-workflow-button
 *     processaction="recalculate"
 *   ></sbx-tax-adjust-event-workflow-button>
 */

@Downgrade.Component('ngShoobx', 'sbx-tax-adjust-event-workflow-button')
@Component({
  selector: 'sbx-tax-adjust-event-workflow-button',
  templateUrl: './sbx-tax-adjust-event-workflow-button.component.html',
})
export class SbxTaxAdjustEventWorkflowButtonComponent {
  @Input() workitemId: string;
  @Input() documentId: string;
  @Input() eventId: string;
  @Input() title: string = 'Adjust Taxes';
  @Input() icon: ISbxIconType = 'edit';
  @Input() processAction: string;

  errorMessage = {
    text: 'There was an error updating taxes.',
    severity: MessageSeverity.ERROR,
    closable: true,
  };

  constructor(
    @Inject(SbxTaxAdjustEventModalService)
    private adjustEventModalService: SbxTaxAdjustEventModalService,
    private readonly messagesService: MessagesService,
    @Inject(SbxSerializeAndSubmitProcessForm)
    private sbxSerializeAndSubmitProcessForm: ISbxSerializeAndSubmitProcessForm,
    @Inject(SbxTaxService)
    private sbxTaxService: SbxTaxService,
  ) {}

  async openModal() {
    this.messagesService.removeMessage(this.errorMessage);
    if (!this.workitemId || !this.documentId || !this.eventId) {
      return;
    }
    try {
      const adjustmentUrl = this.sbxTaxService.getWorkitemEventAdjustmentUrl(
        this.workitemId,
        this.documentId,
        this.eventId,
      );
      const options = <SbxTaxAdjustEventModalModalDataModel>{
        title: 'Adjust Taxes',
        url: adjustmentUrl,
      };
      const modal = this.adjustEventModalService.open(options);
      await modal.result;
    } catch (e) {
      if (!e?.userCancelled) {
        this.messagesService.addMessage(this.errorMessage);
      }
      return;
    }
    if (this.processAction) {
      this.sbxSerializeAndSubmitProcessForm(this.processAction);
    }
  }
}
