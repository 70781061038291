import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import BSModal from 'bs-modal-wrap';
import BSAccordion from 'angular-ui-bootstrap/src/accordion';

import { sbCollapsedActions } from './actions';
import { $confirm, sbAlertModal } from './confirm';
import { sbWindowHeight } from './window-height';
import { sbCompiledContent, sbCompiledContentComponent } from './compiled-content';

/**
 * @ngdoc directive
 * @name sb.lib.bootstrap.directive:sbAccordionGroupRadio
 * @restrict EA
 *
 * @description
 * Accordion group with built-in radio button.
 * The radio button is bound to data and follows open accordion state.
 *
 * This is basically extension of ui.bootstrap `accordion-group` directive,
 * that adds a bound radio button to the heading.
 *
 * @example
   <uib-accordion>
     <sb-accordion-group-radio heading="Type One" ng-model="type" value="type_one">
       Contents for type 1
     </sb-accordion-group-radio>
     <sb-accordion-group-radio heading="Type Two" ng-model="type" value="type_two">
       Contents for type 2
     </sb-accordion-group-radio>
   </uib-accordion>
 */
function sbAccordionGroupRadio() {
  return {
    restrict: 'EA',
    require: '^uibAccordion',
    transclude: true,
    template: require('./templates/accordion-group-radio.html'),
    scope: {
      isDisabled: '=?',
      model: '=ngModel',
      heading: '@',
      value: '@',
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.$watch('selected', (nv) => {
          if (nv) {
            $scope.model = $scope.value;
          }
        });
        $scope.$watch('model', () => {
          $scope.selected = $scope.value && $scope.model === $scope.value;
        });
      },
    ],
  };
} // end sbAccordionGroupRadio

/**
 * @ngdoc directive
 * @name sb.lib.bootstrap.directive:sbPopover
 * @restrict A
 *
 * @description
 * This is a wrapper for Bootstrap's jQuery popover.
 *
 * This also has some additional features to support
 * these popovers inside of processes and attached to fixed
 * elements.
 *
 * See the {@link http://getbootstrap.com/javascript/#popovers docs} for
 * possible values for inputs.
 *
 * @element ANY
 * @param {expression} sbPopover Bool if the popover is enabled.
 * @param {expression} sbPopoverContent HTML String of the content.
 *    Can also be a element object from a $compile() call.
 * @param {expression} [sbPopoverTitle=undefined] String of the title.
 * @param {template} [sbPopoverPlacement='top'] Placement/position.
 * @param {template} [sbPopoverTrigger='click'] Trigger event string.
 * @param {expression} [sbPopoverFixed=false] Bool if the popover should be
 *    fixed position (usually because the element its attached to is fixed).
 * @param {expresssion} [sbPopoverManualExpression=undefined] When expression
 *    is true show popover, otherwise hide it. Only used if `sbPopoverTrigger`
 *    is `'manual'`.
 *
 * @example
   <div
      sb-popover="true"
      sb-popover-content="'Here\'s the <b>html</b> content'"
      sb-popover-title="'Here\'s the title with content'"
      sb-popover-placement="auto top"
      sb-popover-trigger="hover"
      sb-popover-fixed="false">
      This is the div that the popover is attached too.
    </div>
 */
const sbPopover = [
  '$sanitize',
  function ($sanitize) {
    return {
      restrict: 'A',
      scope: {
        enabled: '&sbPopover',
        content: '=sbPopoverContent',
        title: '&sbPopoverTitle',
        manualExpression: '&sbPopoverManualExpression',
        fixed: '&sbPopoverFixed',
        popoverClass: '@sbPopoverClass',
      },
      link: function (scope, element, attrs) {
        function init() {
          const extraClass = scope.fixed() ? ' popover-fixed' : '';
          element.popover({
            html: true,
            container: 'body',
            template: `
            <div class="popover${extraClass} ${
              scope.popoverClass || ''
            }" role="tooltip">
              <div class="arrow"></div>
              <h3 class="popover-title"></h3>
              <div class="popover-content"></div>
            </div>`,
            title: $sanitize(scope.title()) || undefined,
            trigger: attrs.sbPopoverTrigger || 'click',
            placement: attrs.sbPopoverPlacement || 'top',
            content: $sanitize(scope.content),
          });
        }
        function cleanup() {
          element.popover('destroy');
        }

        element.on('$destroy', cleanup);
        scope.$watchGroup(['content', 'title()', 'enabled()'], () => {
          cleanup();
          if (!scope.enabled()) {
            return;
          }
          init();
        });
        if (attrs.sbPopoverTrigger === 'manual') {
          scope.$watch('manualExpression()', (newValue) => {
            if (newValue) {
              element.popover('show');
            } else {
              element.popover('hide');
            }
          });
        }
      },
    };
  },
]; // end sbPopover

// Add this class to a modal to keep it open on navigate.
export const doNotDismissOnNavModalClass = 'do-not-dissmiss-on-navigate';

/**
 * @ngdoc overview
 * @name sb.lib.bootstrap
 *
 * @description
 * This the module for Shoobx-wrapped, {@link http://getbootstrap.com/ Bootstrap}
 * elements.
 */
export default angular
  .module('sb.lib.bootstrap', [ngSanitize, BSAccordion, BSModal])

  .factory('$confirm', $confirm)
  .component('sbAlertModal', sbAlertModal)
  .component('sbCollapsedActions', sbCollapsedActions)
  .component('sbCompiledContentComponent', sbCompiledContentComponent)
  .directive('sbWindowHeight', sbWindowHeight)
  .directive('sbCompiledContent', sbCompiledContent)
  .directive('sbAccordionGroupRadio', sbAccordionGroupRadio)
  .directive('sbPopover', sbPopover).name;
