import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

// load from dist because we use internals which aren't in the types.
import { bb, ChartOptions } from 'billboard.js';
import { format } from 'd3';

export const COLORS = [
  '#8DC63F',
  '#E59413',
  '#5ECCD6',
  '#D7C000',
  '#6FA4E6',
  '#9C987D',
  '#E66B4D',
  '#A5795B',
  '#97AAAA',
  '#A985E3',
  '#BFCF0B',
  '#D671B4',
  '#141414',
  '#74D1A0',
  '#747CCE',
];

export type ISbxChartConfigDataColumns = [string, ...(number | string)[]][];

export type ISbxChartConfigDataTypes = { [columnName: string]: 'area' };
type ISbxLabelPositionHorizontal =
  | 'inner-right'
  | 'inner-center'
  | 'inner-left'
  | 'outer-right'
  | 'outer-center'
  | 'outer-left';
type ISbxLabelPositionVertical =
  | 'inner-top'
  | 'inner-middle'
  | 'inner-bottom'
  | 'outer-top'
  | 'outer-middle'
  | 'outer-bottom';
export type ISbxLabelPosition = ISbxLabelPositionHorizontal | ISbxLabelPositionVertical;
export interface AxisLabel {
  text?: string;
  position?: ISbxLabelPosition;
}
interface AxisTick {
  format?: ((value: number) => string) | string;
  rotate?: number;
  count?: number;
  fit?: boolean;
  outer?: boolean;
}
export interface XAxisTick extends AxisTick {
  centered?: boolean;
  culling?: boolean | { max: number };
}
export type YAxisTick = AxisTick;

export interface ISbxChartConfigDataColors {
  [key: string]: string;
}

export type ISbxChartConfig = ChartOptions;

function formatTooltipDefault(value: number) {
  // eslint-disable-next-line dot-notation
  const valFormat = format(',.2f');
  return valFormat(value);
}

export function formatTooltipContents(
  items: { color: string; title: string; value: string }[],
) {
  return `
    <table class="bb-tooltip">
      <tbody>
        ${items.map(
          (item) => `
          <tr style="white-space: nowrap;">
            <td class="name">
              <span style="background-color: ${item.color};"></span>
              ${item.title}
            </td>
            <td class="value">${item.value}</td>
          </tr>
        `,
        )}
      </tbody>
    </table>
  `;
}

@Component({
  selector: 'sbx-chart',
  templateUrl: './sbx-chart.component.html',
  styleUrls: ['sbx-chart.component.scss'],
})
export class SbxChartComponent implements OnInit, OnDestroy {
  @ViewChild('chartRef') chartRef: ElementRef;
  @Input() config: ISbxChartConfig;
  @Output() init = new EventEmitter<void>();

  chart = null;

  ngOnInit() {
    setTimeout(() => {
      const colors = this.config.data.colors || this.generateColors(this.config.data);
      const order = this.config.data.order || null;
      const formatTooltip = this.config.tooltip?.format?.value ?? formatTooltipDefault;

      this.chart = bb.generate({
        bindto: this.chartRef.nativeElement,
        oninit: () => this.init.emit(),
        ...this.config,
        data: {
          ...this.config.data,
          colors,
          order,
        },
        // If you want to display a legend, use sbx-chart-shared-legend component
        legend: {
          show: false,
        },
        tooltip: {
          ...this.config.tooltip,
          format: {
            value: formatTooltip,
          },
          // The code is taken from https://github.com/c3js/c3/blob/master/src/tooltip.ts#L213 and
          // adjusted to move the tooltip to the left when it obscures hovered part of the chart

          position: function (dataToShow, tWidth, tHeight, element, pos) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias, @typescript-eslint/no-explicit-any
            const $$: any = this;
            const config = $$.internal.config;
            const forArc = $$.internal.hasArcType();
            let svgLeft, tooltipLeft, tooltipRight, tooltipTop, chartRight;
            // Determin tooltip position
            const width = $$.internal.getCurrentWidth();
            const height = $$.internal.getCurrentHeight();
            if (forArc) {
              tooltipLeft = (width - ($$.isLegendRight ? tWidth : 0)) / 2 + pos.x;
              tooltipTop =
                ($$.internal.hasType('gauge') ? height : height / 2) + pos.y + 20;
            } else {
              svgLeft = $$.internal.getSvgLeft(true);
              if (config.axis_rotated) {
                tooltipLeft = svgLeft + pos.x + 100;
                tooltipRight = tooltipLeft + tWidth;
                chartRight = width - $$.internal.getCurrentPaddingByDirection('right');
                tooltipTop = $$.x(dataToShow[0].x) + 20;
              } else {
                tooltipLeft =
                  svgLeft +
                  $$.internal.getCurrentPaddingByDirection('left', true) +
                  pos.xAxis +
                  20;
                tooltipRight = tooltipLeft + tWidth;
                chartRight =
                  svgLeft + width - $$.internal.getCurrentPaddingByDirection('right');
                tooltipTop = pos.y + 15;
              }
              if (tooltipRight > chartRight) {
                if (pos.xAxis > tWidth + 40) {
                  tooltipLeft -= tWidth + 40;
                } else {
                  // 20 is needed for Firefox to keep tooltip width
                  tooltipLeft -= tooltipRight - chartRight + 20;
                }
              }
              if (tooltipTop + tHeight > $$.currentHeight) {
                tooltipTop -= tHeight + 30;
              }
            }
            if (tooltipTop < 0) {
              tooltipTop = 0;
            }
            return {
              top: tooltipTop,
              left: tooltipLeft,
            };
          },
        },
      });
    }, 0);
  }

  generateColors(data) {
    return data.columns.reduce((acc, column, i) => {
      const key = column[0];

      if (key) {
        acc[key] = COLORS[i];
      }

      return acc;
    }, {});
  }

  ngOnDestroy() {
    // We should destroy our chart instance here, but c3 version is too old
    // and it has a bug which throws errors after destroying chart instance.
  }

  public async update(data) {
    const colors = data.colors || this.generateColors(data);

    await new Promise((res) =>
      this.chart.load({ ...data, colors, unload: true, done: res }),
    );

    // This needs to be refactored some day
    if (data.groups) {
      this.chart.groups(data.groups);
    }

    // This needs to be refactored some day
    if (
      (data.axis && data.axis.x && data.axis.x.max) ||
      (data.axis && data.axis.y && data.axis.y.max)
    ) {
      this.chart.axis.max({
        x: (data.axis && data.axis.x && data.axis.x.max) || undefined,
        y: (data.axis && data.axis.y && data.axis.y.max) || undefined,
      });
    }
  }
}
