import { Set, is } from 'immutable';
import { Subject } from 'rxjs';
import { filter, distinct, takeUntil } from 'rxjs/operators';

/**
 * @ngdoc controller
 * @name sb.workitem.signDocuments.controller:FullDocumentModalCtrl
 *
 * @description
 * Controller for the modal with the full PDF frame. Displays the
 * signature controls to sign the document inline.
 */
export const FullDocumentModalCtrl = [
  '$scope',
  'Model',
  'Document',
  function ($scope, Model, Document) {
    $scope.doc = Document;
    $scope.model = Model;
  },
]; // end FullDocumentModalCtrl

/**
 * @ngdoc controller
 * @name sb.workitem.signDocuments.controller:SignDocumentsWiCtrl
 *
 * @description
 * The main controller for the sign documents workitem.
 */
export const SignDocumentsWiCtrl = [
  '$scope',
  '$q',
  '$sbModal',
  '$confirm',
  '$createSignatureModal',
  'PromiseErrorCatcher',
  'SignDocumentsWiModel',
  'ProcessButtonModel',
  'ProcessModel',
  'ProcessStatus',
  '$formModal',
  'SimpleHTTPWrapper',
  'ProcessUrlInfo',
  '$http',
  'SerializeAndSubmitProcessForm',
  '$observable',
  'EntityDocumentsService',
  function (
    $scope,
    $q,
    $sbModal,
    $confirm,
    $createSignatureModal,
    PromiseErrorCatcher,
    SignDocumentsWiModel,
    ProcessButtonModel,
    ProcessModel,
    ProcessStatus,
    $formModal,
    SimpleHTTPWrapper,
    ProcessUrlInfo,
    $http,
    SerializeAndSubmitProcessForm,
    $observable,
    EntityDocumentsService,
  ) {
    const CONFIRM_SIGNATURE_APPLY =
      'Are you sure you want to apply your signature ' +
      'to all documents and submit them?';
    const CONFIRM_SIGNING_STEP =
      'The signing step is not complete until you submit the signed documents.';
    const CONFIRM = 'Confirm Signature';
    const CONFIRM_AND_SIGN = 'Confirm & Sign';
    const model = SignDocumentsWiModel;

    function chainSignaturePromise(promise, buttonText) {
      if (ProcessModel.$wi.unClaimed) {
        return $q.resolve();
      }

      return promise
        .then(() => {
          if (!model.$hasSig && !ProcessModel.$wi.unClaimed) {
            // This will always resolve
            return $createSignatureModal(model, buttonText).then(
              () => true,
              () => false,
            );
          }
          return true;
        })
        .then((hasSig) => {
          if (model.$canPreview) {
            return model.$sigPreview().then((data) => {
              // If they've signed documents already, assume missing byLine + title is intentional
              const hasSignedDocuments =
                model.$unsignedDocumentsLength !== (model.$documents || []).length;
              const missingSigData = !data.byLine && !data.title;
              if (!hasSignedDocuments && missingSigData && !$scope.initialized) {
                $scope.initialized = true; // To make sure this modal only appears once.
                return $scope
                  .showEditSignatureModal($scope.notInvestmentPage)
                  .then((sigConfirmed) => {
                    return sigConfirmed;
                  });
              }

              return hasSig;
            });
          }
          return hasSig;
        });
    }

    function fullViewModal(doc) {
      return $sbModal.open({
        template: require('./templates/full-document-modal.html'),
        controller: 'FullDocumentModalCtrl',
        size: 'lg',
        resolve: {
          Document: function () {
            return doc;
          },
          Model: function () {
            return model;
          },
        },
      }).result;
    }
    function openFullViewModal(doc) {
      return fullViewModal(doc)
        .then(() => {
          return signDocument(doc.id);
        })
        .catch(PromiseErrorCatcher);
    }

    function updateInlineReviewPdf(docId) {
      if (model.$inlineReview) {
        model.$inlineLoading = true;
        const modelDoc = model.$documents.find((doc) => doc.id === docId);
        EntityDocumentsService.$getDocument(modelDoc.id, modelDoc.entityId, true)
          .$toPromise()
          .then((doc) => {
            return EntityDocumentsService.$waitForDocumentToRender(doc).$toPromise();
          })
          .then((done) => {
            model.$inlineLoading = false;

            if (done) {
              const inlineIframe = document.querySelector(
                `#inline-pdf-preview-${docId} iframe`,
              );
              if (inlineIframe) {
                // Refresh the iframe
                // eslint-disable-next-line no-self-assign
                inlineIframe.src = inlineIframe.src;
              }
            }
          })
          .catch(PromiseErrorCatcher);
      }
    }

    function onDocumentReady(doc) {
      model.$documentReady(doc);
      $scope.updateInlineReviewPdf(doc.id);
    }

    function showError(error) {
      return function () {
        ProcessStatus.$setStatus(error, 'danger');
        return $q.reject(error);
      };
    }
    function togglePreviewText() {
      $scope.showPreviewBody = !$scope.showPreviewBody;
    }
    function setNotInvestmentPage() {
      $scope.notInvestmentPage = false;
    }
    function showHelpModal() {
      $sbModal
        .open({
          template: require('./templates/signature-help.html'),
        })
        .result.catch(PromiseErrorCatcher);
    }
    function showEditSignatureModal(showPreviewInModal) {
      $scope.togglePreviewText();
      let showSaveByLineTitleModalArgs = [];
      return $sbModal
        .open({
          resolve: {
            saveByLineTitle: () => $scope.saveByLineTitle,
          },
          template: require('./templates/preview-signature.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.html = model.$signature.html;
              $scope.byLine = model.$signature.byLine;
              $scope.title = model.$signature.title;
              $scope.editable = true;
              $scope.save = function () {
                $scope.$close();
                if (showPreviewInModal) {
                  showSaveByLineTitleModalArgs = [$scope.byLine, $scope.title];
                } else {
                  model.$sigPreview($scope.byLine, $scope.title).then(() => {
                    ProcessStatus.$setStatus('Successfully saved byline.', 'success');
                  }, showError('Failed to save byline.'));
                }
              };
            },
          ],
        })
        .result.then(() => {
          if (showSaveByLineTitleModalArgs.length) {
            return $scope.saveByLineTitle(...showSaveByLineTitleModalArgs);
          }
          return true;
        })
        .catch(PromiseErrorCatcher)
        .finally(() => {
          $scope.togglePreviewText();
        });
    }
    function saveByLineTitle(byLine, title) {
      return $sbModal
        .open({
          template: require('./templates/preview-signature.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.html = model.$signature.html;
              $scope.byLine = byLine;
              $scope.title = title;
              $scope.editable = false;
              $scope.save = function () {
                $scope.$close();
                model.$sigPreview(byLine, title).then(() => {
                  ProcessStatus.$setStatus('Successfully saved byline.', 'success');
                }, showError('Failed to save byline.'));
              };
            },
          ],
        })
        .result.then(
          () => true,
          () => false,
        )
        .catch(PromiseErrorCatcher);
    }

    function openAddressModal() {
      return $formModal({
        htmlContent: require('./templates/address-modal.html'),
        title: 'Enter address',
        onConfirmPromise: ({ $formData }) => {
          return $http({
            url: ProcessUrlInfo.currentWiUrl() + '/index.html/save-address',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: 'data=' + encodeURIComponent(JSON.stringify($formData.modalFormName)),
          });
        },
        onOpenPromise: () =>
          SimpleHTTPWrapper({
            url: ProcessUrlInfo.currentWiUrl() + '/index.html/get-address',
          }).then(($formData) => {
            return {
              forms: { modalFormName: $formData.description },
              formData: { modalFormName: $formData.model },
            };
          }),
      });
    }

    function archiveDocuments() {
      return model.$archiveDocuments();
    }

    function signDocument(docId) {
      // Make sure signature exists
      chainSignaturePromise($q.resolve(), CONFIRM_AND_SIGN)
        // If the sig exists, grab validation data
        .then((hasSig) => {
          if (hasSig) {
            return SimpleHTTPWrapper({
              method: 'GET',
              url: ProcessUrlInfo.currentWiUrl() + '/index.html/validate',
            });
          }
        })
        // If we require address info, open the address modal
        .then((data) => {
          if (data) {
            if (data.requestAddress) {
              return openAddressModal();
            }
            return data;
          }
        })
        // If everything is good, sign the document
        .then((signDoc) => {
          if (signDoc) {
            return model.$signAndFetchDocuments(docId);
          }
        })
        // Then update inline pdf, if the doc was signed
        .then((signed) => {
          if (signed) {
            $scope.updateInlineReviewPdf(docId);
          }
        })
        .catch(showError('Failed to sign document.'))
        .catch(PromiseErrorCatcher);
    }

    function revokeSignature(docId) {
      model
        .$revokeAndFetchDocuments(docId)
        .then(() => $scope.updateInlineReviewPdf(docId))
        .catch(showError('Failed to revoke signature.'))
        .catch(PromiseErrorCatcher);
    }
    function confirmForward() {
      // We $watch model's `$allDocsReady` property and the continue button's
      // enabled-ness. When these conditions are true, the WI is not
      // completed, and there are at least one document on the page, we show
      // a confirmation modal.
      const docsReady = model.$allDocsReady;
      const continueDisabled = ProcessButtonModel.$isDisabled('continue');
      const documentsLength = (model.$documents || []).length;
      const processCompleted = ProcessModel.$wi.completed;
      return docsReady && !continueDisabled && !processCompleted && documentsLength;
    }

    $scope.initialized = false;
    $scope.notInvestmentPage = true;
    $scope.setNotInvestmentPage = setNotInvestmentPage;

    model.$init().then(() => {
      if (model.$canPreview) {
        model.$sigPreview();
      }
    });

    $scope.showPreviewBody = false;
    $scope.model = model;
    $scope.togglePreviewText = togglePreviewText;
    $scope.showEditSignatureModal = showEditSignatureModal;
    $scope.saveByLineTitle = saveByLineTitle;
    $scope.openFullViewModal = openFullViewModal;
    $scope.onDocumentReady = onDocumentReady;
    $scope.signDocument = signDocument;
    $scope.revokeSignature = revokeSignature;
    $scope.showHelpModal = showHelpModal;
    $scope.updateInlineReviewPdf = updateInlineReviewPdf;
    $scope.archiveDocuments = archiveDocuments;

    $scope.$watch('model.$unsignedDocumentsLength === 0', (nv, ov) => {
      if (nv && nv !== ov) {
        ProcessButtonModel.requestEnable('continue');
      } else if (!nv) {
        ProcessButtonModel.disable('continue');
      }
    });

    const signAllExp = '!model.$unsignedDocumentsLength || !model.$allDocsReady';
    $scope.$watch(signAllExp, ProcessButtonModel.$disableWatch('signall'));

    // This subject only emits when the user goes past the submit step.
    const submitResovledSubject = new Subject();
    $observable
      .fromWatcher($scope, confirmForward)
      .pipe(
        distinct(),
        filter((obj) => obj.newValue),
        // Once the submitResolvedSubject emits we know the user is passed the submit step
        // and therefore unsubscribe.
        takeUntil(submitResovledSubject),
      )
      .subscribe(() =>
        $confirm({
          body: CONFIRM_SIGNING_STEP,
          dismissButtonText: 'Cancel',
          confirmButtonText: 'Submit',
        })
          .then(() => {
            SerializeAndSubmitProcessForm('continue');
            submitResovledSubject.next();
          })
          .catch(PromiseErrorCatcher),
      );

    ProcessButtonModel.$addSubmitCondition(
      'release',
      () => {
        const docs = model.$documents || [];
        return $q.all(docs.map((doc) => model.$revokeAndFetchDocuments(doc.id)));
      },
      -1,
    );
    ProcessButtonModel.$addSubmitCondition('signall', () => {
      return SimpleHTTPWrapper({
        url: ProcessUrlInfo.currentWiUrl() + '/index.html/validate',
      })
        .then((data) => {
          return Promise.resolve(data && data.requestAddress);
        })
        .then((requestAddress) => {
          if (requestAddress) {
            return openAddressModal();
          }
          return Promise.resolve({});
        })
        .then(() => {
          return chainSignaturePromise($q.resolve(), CONFIRM).then((hasSig) => {
            if (hasSig) {
              return $confirm({ body: CONFIRM_SIGNATURE_APPLY });
            }
            return $q.reject();
          });
        })
        .catch(() => {
          return $q.reject();
        });
    });
  },
]; // end SignDocumentsWiCtrl

/**
 * @ngdoc service
 * @name sb.workitem.signDocuments.SignDocumentsWiModel
 *
 * @description
 * This service interacts with the doucment API of a sign documents workitem.
 *
 * @TODO This service uses publish traverse but should migrate to a worktiem
 * webapi and SimpleHTTPWrapper.
 */
export const SignDocumentsWiModel = [
  '$http',
  '$q',
  'ProcessUrlInfo',
  function ($http, $q, ProcessUrlInfo) {
    function internalFetch(options, write) {
      obj.$loading = true;
      return $http(options)
        .then(
          (response) => {
            const data = response.data;
            if (data && data.error) {
              return $q.reject(data.error);
            }
            write(data);
            return data;
          },
          () => $q.reject('Bad sign documents request.'),
        )
        .finally(() => {
          obj.$loading = false;
        });
    }
    function writeDocuments(data) {
      obj.$hasSig = Boolean(data.hasSig);
      obj.$canPreview = Boolean(data.canPreview);
      obj.$signatureDialog = data.signatureDialog;
      obj.$inlineReview = Boolean(data.inlineReview);
      obj.$archiveOnly = Boolean(data.archiveOnly);
      obj.$documents = data.documents || [];
      obj.$$allDocsReady = obj.$allDocsReady || !obj.$documents.length;
      const docIds = obj.$documents.map((doc) => doc.id);
      obj.$$docIds = Set(docIds);
      obj.$unsignedDocumentsLength = obj.$documents.filter((doc) => !doc.signed).length;
    }

    const obj = {};

    obj.$init = function () {
      obj.$$readyDocs = Set();

      /**
       * @ngdoc property
       * @name $loading
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Boolean indicating the model is currently loading.
       */
      obj.$loading = false;
      obj.$archiving = false;

      /**
       * @ngdoc property
       * @name $unsignedDocumentsLength
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Number of unsigned documents. Is intialized as `undefined`.
       */
      obj.$unsignedDocumentsLength = undefined;

      /**
       * @ngdoc property
       * @name $hasSig
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Boolean indicating if the user has a signature (`undefined` before load).
       */
      obj.$hasSig = undefined;

      /**
       * @ngdoc property
       * @name $canPreview
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Boolean indicating if the user can preview (`undefined` before load).
       */
      obj.$canPreview = undefined;

      /**
       * @ngdoc property
       * @name $signatureDialog
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Signature dialog (as html string).
       */
      obj.$signatureDialog = '';

      /**
       * @ngdoc property
       * @name $documents
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Array of document objects.
       */
      obj.$documents = [];

      /**
       * @ngdoc property
       * @name $allDocsReady
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Boolean indicating the all documents have been rendered.
       */
      obj.$allDocsReady = false;

      /**
       * @ngdoc property
       * @name $signature
       * @propertyOf sb.workitem.signDocuments.SignDocumentsWiModel
       *
       * @description
       * Signature data as an object:
       *   * `.html` Signature HTML string.
       *   * `.title` Byline title.
       *   * `.byLine` Byline content.
       */
      obj.$signature = {};

      return obj.$signAndFetchDocuments();
    };

    /**
     * @ngdoc method
     * @name $documentReady
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Notification method when a document has finished rendering.
     * This will potentially upadate `$allDocsReady`.
     *
     * @param {object} doc Document object.
     */
    obj.$documentReady = function (doc) {
      obj.$$readyDocs = obj.$$readyDocs.add(doc.id);
      obj.$allDocsReady = is(obj.$$readyDocs, obj.$$docIds);
    };

    /**
     * @ngdoc method
     * @name $signAndFetchDocuments
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Fetch all document info and optionally sign a document.
     *
     * @param {string} [id=undefined] The ID of the document to sign.
     *
     * @returns {promise} Resolves on successful operation, passing data and
     *    rejects on failure.
     */
    obj.$signAndFetchDocuments = function (id) {
      return internalFetch(
        {
          url: ProcessUrlInfo.currentWiUrl() + '/index.html/json',
          method: 'GET',
          params: { signDocId: id },
        },
        writeDocuments,
      );
    };

    /**
     * @ngdoc method
     * @name $revokeAndFetchDocuments
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Fetch all document info and optionally revoke a document.
     *
     * @param {string} [id=undefined] The ID of the document to revoke.
     *
     * @returns {promise} Resolves on successful operation, passing data and
     *    rejects on failure.
     */
    obj.$revokeAndFetchDocuments = function (id) {
      return internalFetch(
        {
          url: ProcessUrlInfo.currentWiUrl() + '/index.html/json',
          method: 'GET',
          params: { revokeDocId: id },
        },
        writeDocuments,
      );
    };

    /**
     * @ngdoc method
     * @name $saveSig
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Save a signature.
     *
     * @param {object} data The data to pass to the server about the signature.
     *
     * @returns {promise} Resolves on successful operation, passing data and
     *    rejects on failure.
     */
    obj.$saveSig = function (data) {
      return internalFetch(
        {
          url: ProcessUrlInfo.currentWiUrl() + '/index.html/saveSig',
          method: 'POST',
          data: data,
          // Let the browser detect the multipart/form-data.
          headers: { 'Content-Type': undefined },
        },
        (data) => {
          obj.$hasSig = Boolean(data.hasSig);
          obj.$canPreview = Boolean(data.canPreview);
          obj.$signatureDialog = data.signatureDialog;
          return data;
        },
      );
    };

    /**
     * @ngdoc method
     * @name $sigPreview
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Fetch the signature preview.
     *
     * @param {string} byLine The byline to preview with.
     * @param {string} title The title of the signature to preview with.
     *
     * @returns {promise} Resolves on successful operation, passing data and
     *    rejects on failure.
     */
    obj.$sigPreview = function (byLine, title) {
      return internalFetch(
        {
          url: ProcessUrlInfo.currentWiUrl() + '/index.html/sigPreview',
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data:
            'title=' +
            encodeURIComponent(title) +
            '&byLine=' +
            encodeURIComponent(byLine),
        },
        (data) => {
          obj.$signature = {
            html: data.preview,
            byLine: data.byLine,
            title: data.title,
          };
        },
      );
    };

    /**
     * @ngdoc method
     * @name $archiveDocuments
     * @methodOf sb.workitem.signDocuments.SignDocumentsWiModel
     *
     * @description
     * Kick off an archive task.
     *
     *
     * @returns {promise} Resolves on successful operation, passing data and
     *    rejects on failure.
     */
    obj.$archiveDocuments = function () {
      this.archiving = true;
      return internalFetch(
        {
          url: ProcessUrlInfo.currentWiUrl() + '/index.html/archive',
          method: 'GET',
          params: {},
        },
        // eslint-disable-next-line no-unused-vars
        (data) => {
          this.archiving = false;
        },
      );
    };

    return obj;
  },
]; // end SignDocumentsWiModel
