/**
 * @ngdoc component
 * @name sb.stakeholderProfile.component:sbStakeholderAccountRecovery
 *
 * @description
 * This component renders the account recovery information page.
 */
export const sbStakeholderAccountRecovery = {
  controllerAs: 'vm',
  template: require('./templates/recovery.html'),
  bindings: {
    canEditUserInfo: '<',
  },
  controller: [
    'SimpleHTTPWrapper',
    'BackendLocation',
    function (SimpleHTTPWrapper, BackendLocation) {
      this.url = BackendLocation.context('1') + 'recovery-info';
      this.formModel = {};
      this.formFields = null;

      this.load = (data) => {
        this.recoveryInfo = data;
        this.loading = false;
      };

      this.$onInit = () => {
        this.loading = true;
        const params = {};
        SimpleHTTPWrapper(
          {
            url: this.url,
            method: 'GET',
            params: params,
          },
          'Could not initialize.',
        ).then(this.load);
      };
    },
  ],
};
