import angular from 'angular';
import Events from './../../lib/events/events';
import Modal from '../../lib/modal/modal';
import Tools from '../../lib/tools';
import Table from '../../lib/table';

import { EditBoardWiCtrl } from './controllers';
import { sbBoardCircle } from './directives';
import { EditBoardModel } from './providers';

/**
 * @ngdoc overview
 * @name sb.workitem.editBoard
 *
 * @description
 * This is the module for the edit board workitem.
 */
export default angular
  .module('sb.workitem.editBoard', [Events, Modal, Tools, Table])

  .controller('EditBoardWiCtrl', EditBoardWiCtrl)
  .directive('sbBoardCircle', sbBoardCircle)
  .factory('EditBoardModel', EditBoardModel).name;
