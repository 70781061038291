import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxWorkitemContentComponent } from './sbx-workitem-content.component';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { UpgradedModule } from '../upgraded/upgraded.module';

@NgModule({
  imports: [CommonModule, SbxLoaderModule, UpgradedModule],
  declarations: [SbxWorkitemContentComponent],
  exports: [SbxWorkitemContentComponent],
})
export class SbxWorkitemContentModule {
  static entryComponents = [SbxWorkitemContentComponent];

  static forRoot(): ModuleWithProviders<SbxWorkitemContentModule> {
    return {
      ngModule: SbxWorkitemContentModule,
    };
  }
}
