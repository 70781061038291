import angular from 'angular';
/**
 * @ngdoc object
 * @name sb.workitem.showPage.ShowPageModel
 * @requires lib/sb.promise.SimpleHTTPWrapper
 * @requires lib/sb.url.BackendLocation
 *
 * @description
 * This service is in charge of model data for the show page workitem.
 */
export const ShowPageModel = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    return {
      $$updateData: function (formData, formDefs, content) {
        this.$formData = formData;
        if (angular.isDefined(formDefs)) {
          this.$forms = formDefs;
        }
        if (angular.isDefined(content)) {
          this.$content = content;
        }
      },

      /**
       * @ngdoc property
       * @name $loading
       * @propertyOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * Bool indicating if the model is loading.
       */
      $loading: false,

      /**
       * @ngdoc property
       * @name $content
       * @propertyOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * The content of the template.
       */
      $content: '',

      /**
       * @ngdoc property
       * @name $forms
       * @propertyOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * The form defintions for the page: `{ formname: {def}...}`.
       */
      $forms: {},

      /**
       * @ngdoc property
       * @name $formData
       * @propertyOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * The data for each form. `{ formname: {data}... }`.
       */
      $formData: {},

      /**
       * @ngdoc property
       * @name $formErrors
       * @propertyOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * The data for each form. `{ formname: {errors}... }`.
       */
      $formErrors: {},

      /**
       * @ngdoc method
       * @name $init
       * @methodOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * Inits the model and fills out properties.
       *
       * @returns {promise} Resolves on successful fetch or rejects with reason.
       */
      $init: function () {
        this.$loading = true;
        this.$$updateData({}, {}, '');
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.context(1) + 'forms',
          },
          'Failed to fetch page contents.',
        )
          .then((data) => {
            this.$formErrors = {};
            this.$$updateData(data.formData, data.formDefs, data.content);
          })
          .finally(() => {
            this.$loading = false;
          });
      },

      /**
       * @ngdoc method
       * @name $submitForms
       * @methodOf sb.workitem.showPage.ShowPageModel
       *
       * @description
       * Submits `$formData` to the server for validation.
       *
       * @param {promise} complete Promise to reject with in the case of failure.
       *
       * @returns {promise} Resolves on successful persist or rejects with
       *    `{ reason: {string}, complete: {promise} }`.
       */
      $submitForms: function (complete) {
        this.$loading = true;
        return SimpleHTTPWrapper({
          method: 'POST',
          url: BackendLocation.context(1) + 'forms',
          data: {
            forms: this.$formData,
          },
        })
          .then(
            (data) => {
              this.$$updateData(data.formData);
            },
            (errors) => {
              let reason;
              if (angular.isObject(errors)) {
                this.$formErrors = errors;
                angular.forEach(errors, (value) => {
                  if (value.__form__) {
                    reason = value.__form__[0];
                    return false; // exits iteration
                  }
                  return undefined;
                });
              }
              return $q.reject({
                reason: reason || 'Please correct the form errors below.',
                complete: complete,
              });
            },
          )
          .finally(() => {
            this.$loading = false;
          });
      },
    };
  },
]; // end ShowPageModel
