import angular from 'angular';
import Tools from '../../lib/tools';

import { sbWaitWorkitemInfo, sbDefaultWaitWorkitemInfo } from './info';

export default angular
  .module('sb.workitem.waitPages', [Tools])

  .component('sbWaitWorkitemInfo', sbWaitWorkitemInfo)
  .component('sbDefaultWaitWorkitemInfo', sbDefaultWaitWorkitemInfo).name;
