import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SbxHttpClient } from '@/core/http/';
import { Downgrade } from '@/shared/downgrade';
import { PickupFile } from '@shoobx/types/webapi-v2';

const SELECTOR = 'sbx-deal-room';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-deal-room.component.html',
  styleUrls: ['./sbx-deal-room.component.scss'],
})
export class SbxDealRoomComponent implements OnInit {
  // Investors tab templates
  @ViewChild('investorStatusTemplate') investorStatusTemplate: TemplateRef<any>;
  @ViewChild('amountTemplate') amountTemplate: TemplateRef<any>;
  @ViewChild('postDealOwnershipTemplate') postDealOwnershipTemplate: TemplateRef<any>;
  @ViewChild('contactTemplate') contactTemplate: TemplateRef<any>;
  @ViewChild('signatureBlockTemplate') signatureBlockTemplate: TemplateRef<any>;
  // Documents tab templates
  @ViewChild('documentNameTemplate') documentNameTemplate: TemplateRef<any>;
  @ViewChild('customizationsTemplate') customizationsTemplate: TemplateRef<any>;
  @ViewChild('downloadTemplate') downloadTemplate: TemplateRef<any>;

  showTab: string;
  isLoading = true;
  exportingSummary = false;
  workflowOwner: string;
  documentsColumns = [];
  investorsColumns = [];
  conversionsColumns = [];
  parsedData: any;
  Math: Math = Math;

  constructor(@Inject(SbxHttpClient) private service: SbxHttpClient) {}

  async ngOnInit() {
    // Get data
    this.parsedData = await this.service.entity('2').get('dealroom').toPromise();
    // Constants
    this.investorsColumns = [
      {
        key: 'investor',
        title: 'Investor',
        template: this.investorStatusTemplate,
      },
      {
        key: 'amount',
        title: 'Amount',
        template: this.amountTemplate,
      },
      {
        key: 'postDealOwnership',
        title: 'Post-Deal Ownership',
        template: this.postDealOwnershipTemplate,
      },
      {
        key: 'contact',
        title: 'Contact',
        template: this.contactTemplate,
      },
      {
        key: 'signatureBlock',
        title: 'Signature Block',
        template: this.signatureBlockTemplate,
      },
    ];
    this.conversionsColumns = [
      {
        key: 'investor',
        title: 'Investor',
        template: this.investorStatusTemplate,
      },
      {
        key: 'amount',
        title: 'Amount',
        template: this.amountTemplate,
      },
      {
        key: 'interest',
        title: 'Interest',
        template: this.amountTemplate,
      },
      {
        key: 'contact',
        title: 'Contact',
        template: this.contactTemplate,
      },
      {
        key: 'signatureBlock',
        title: 'Signature Block',
        template: this.signatureBlockTemplate,
      },
    ];
    this.documentsColumns = [
      {
        key: 'document',
        title: 'Document',
        template: this.documentNameTemplate,
      },
      {
        key: 'customizations',
        title: 'Customizations',
        template: this.customizationsTemplate,
      },
      {
        key: 'download',
        title: '',
        template: this.downloadTemplate,
      },
    ];
    // Navigate to the correct tab
    this.showTab = window.location.hash.substring(1);
    // this.parsedData = JSON.parse(this.parsedData);
    this.isLoading = false;
    this.exportingSummary = false;
  }

  async exportSummary(): Promise<void> {
    try {
      this.exportingSummary = true;
      const response = await this.service
        .entity('2')
        .get<PickupFile>('financingValuation/dealSummary', {
          params: { processId: this.parsedData.processId },
        })
        .toPromise();
      window.location.href = response.downloadUrl;
    } catch (error) {
    } finally {
      this.exportingSummary = false;
    }
  }
}
