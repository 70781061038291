import angular from 'angular';
import { Map, List } from 'immutable';

/**
 * @ngdoc directive
 * @name sb.onboarding.sbHrTicketsAdmin
 *
 * This is a directive that creates a Tickets Admin Page.
 * @param {string} type Type of tickets, e.g. 'onboarding' or 'offboarding'.
 *
 */
export const sbHrTicketsAdmin = {
  template: require('./templates/hr-tickets-admin.html'),
  controllerAs: 'vm',
  bindings: {
    type: '@',
  },
  controller: [
    '$scope',
    '$q',
    'SimpleHTTPWrapper',
    'BackendLocation',
    function ($scope, $q, SimpleHTTPWrapper, BackendLocation) {
      const vm = this;
      this.$onChanges = () => {
        function updateModel() {
          const duedateVocab = $scope.ticketsFormDesc.fields.find(
            (item) => item.key === 'deadline',
          ).templateOptions.enumVocab;

          const indexProfiles = (profiles) => {
            if (!profiles) {
              return Map({});
            }
            const idx = {};
            for (const prf of profiles) {
              idx[prf.id] = prf;
            }
            return Map(idx);
          };

          const assigneeList = (ticket) => {
            const stakeholders = (ticket.assigned_stakeholders || []).map((obj) => {
              return obj ? obj.sh.fullName : undefined;
            });
            if (ticket.supervisor_assigned) {
              stakeholders.push('Manager');
            }
            return stakeholders.join(', ');
          };

          const profileList = (profiles, prfIndex) => {
            const titles = (profiles || []).map((prfId) => {
              return prfIndex.get(prfId) ? prfIndex.get(prfId).title : '';
            });
            if (titles.length === 0) {
              return 'All Employees';
            }

            return titles.join(', ');
          };

          const deadlineFormat = (deadline) => {
            const enumVocab = duedateVocab.find((item) => item.value === deadline);
            return enumVocab ? enumVocab.label : deadline;
          };

          const mapTicketsToItems = (tickets, profiles) => {
            return List(
              tickets.map((ticket, index) => {
                return Map({
                  id: index,
                  deletable: true,
                  editable: true,
                  editTitle: 'Edit Onboarding Task',
                  data: Map(
                    angular.extend(
                      {
                        assigneesColumn: Map({ fmtValue: assigneeList(ticket) }),
                        dueDateColumn: Map({
                          fmtValue: deadlineFormat(ticket.deadline),
                        }),
                        descColumn: Map({ fmtValue: ticket.description }),
                        nameColumn: Map({ fmtValue: ticket.name }),
                        employeeGroupsColumn: Map({
                          fmtValue: profileList(
                            ticket.employee_groups || ticket.newhire_groups,
                            profiles,
                          ),
                        }),
                      },
                      ticket,
                    ),
                  ),
                });
              }),
            );
          };

          const profiles = indexProfiles($scope.profiles);
          $scope.tasksModel = {
            $items: mapTicketsToItems($scope.tickets, profiles),
            $hasEditOrDelete: true,
            $fetchItems: (tickets) => {
              return $scope.$saveTickets(tickets).then(() => {
                const profiles = indexProfiles($scope.profiles);
                $scope.tasksModel.$items = mapTicketsToItems($scope.tickets, profiles);
              });
            },
            $add: (data) => {
              const tickets = angular.copy($scope.tickets);
              tickets.push(data);
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $remove: (index) => {
              const tickets = angular.copy($scope.tickets);
              tickets.splice(index, 1);
              $scope.tasksModel.$loading = true;
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $edit: (id, data) => {
              const tickets = angular.copy($scope.tickets);
              tickets[id] = data;
              return $scope.tasksModel.$fetchItems(tickets);
            },
            $loading: false,
          };
        }
        $scope.columns = [
          { key: 'nameColumn', name: 'Name' },
          { key: 'assigneesColumn', name: 'Assignee' },
          { key: 'dueDateColumn', name: 'Due' },
          { key: 'descColumn', name: 'Description' },
          { key: 'employeeGroupsColumn', name: 'Groups' },
        ];

        $scope.tasksModel = {
          $loading: false,
        };

        $scope.$loadTickets = () => {
          $scope.tasksModel.$loading = true;
          return SimpleHTTPWrapper({
            method: 'GET',
            url: BackendLocation.entity(1) + 'ticket-templates?type=' + vm.type,
          }).then((data) => {
            $scope.tasksModel.$loading = false;
            $scope.tickets = data.tickets;
            $scope.profiles = data.profiles;
            $scope.ticketsFormDesc = data.form;
            updateModel();
            return true;
          });
        };

        $scope.$saveTickets = (tickets) => {
          $scope.tasksModel.$loading = true;
          return SimpleHTTPWrapper({
            method: 'POST',
            url: BackendLocation.entity(1) + 'ticket-templates',
            data: {
              type: vm.type,
              tickets: tickets,
            },
          }).then(
            (data) => {
              $scope.tasksModel.$loading = false;
              $scope.tickets = data.tickets;
              $scope.profiles = data.profiles;
              $scope.ticketsFormDesc = data.form;
              updateModel();
              return true;
            },
            (errors) => {
              $scope.tasksModel.$loading = false;
              if (angular.isArray(errors) && errors.length) {
                errors = errors[0];
              }
              return $q.reject(errors);
            },
          );
        };

        $scope.$loadTickets();
      };
    },
  ],
};

/**
 * @ngdoc directive
 * @name sb.onboarding.sbHrTicketsPage
 *
 * This is a directive that creates the "My Tickets" Page.
 *
 * Most parameters pass through to the sbFilteredListPage, so look there for
 * what the paramters are for.
 *
 * @param {string} type Type of tickets, e.g. 'onboarding' or 'offboarding'.
 */
export const sbHrTicketsPage = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/hr-tickets-page.html'),
      scope: {
        title: '@sbTitle',
        redirect: '@sbRedirect',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbTickets',
        type: '@',
      },
      controller: [
        '$scope',
        'PromiseErrorCatcher',
        function ($scope, PromiseErrorCatcher) {
          $scope.model = ListPageModel;
          $scope.refreshModel = () => {
            $scope.model.refreshWithCurrentParams().catch(PromiseErrorCatcher);
          };
          $scope.model.initialize(
            $scope.filterData,
            $scope.requiredFilterValues,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + 'tickets/my_hr_tickets',
              method: 'POST',
            },
            ListLocation.updateUrl,
          );
          $scope.model.hidePaging = true;
          ListLocation.updateModelOnLocationChange($scope);
        },
      ],
    };
  },
];

/**
 * @ngdoc component
 * @name sb.onboarding.sbHrCompleteButton
 *
 * @description
 * A button that brings up a modal that allows to complete a task.
 */
export const sbHrCompleteButton = {
  controllerAs: 'vm',
  template: `<button class="btn btn-int" id="complete-{{::vm.task.id}}"
                     ng-click="vm.completeModal()">
              <i class="far fa-check-circle"></i> Complete
            </button>`,
  bindings: {
    task: '=sbTask',
    onClose: '&',
  },
  controller: [
    '$scope',
    '$sbModal',
    'PromiseErrorCatcher',
    function ($scope, $sbModal, PromiseErrorCatcher) {
      function completeModal() {
        $sbModal
          .open({
            bindToController: true,
            size: 'lg',
            controllerAs: 'vm',
            template: require('./templates/complete-modal.html'),
            resolve: {
              Task: () => this.task,
              OnClose: () => this.onClose,
            },
            controller: CompleteModalCtrl,
          })
          .result.catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        this.completeModal = completeModal.bind(this);
      };
    },
  ],
};

const CompleteModalCtrl = [
  'Task',
  'OnClose',
  'TicketsService',
  function (Task, OnClose, TicketsService) {
    function completeTask() {
      return TicketsService.complete(Task.id).then((data) => {
        this.$close();
        OnClose();
        return data;
      });
    }

    this.completeTask = completeTask.bind(this);
    this.task = Task;
  },
];

export const sbHrTasksReport = [
  'ListPageModel',
  'ListLocation',
  'BackendLocation',
  function (ListPageModel, ListLocation, BackendLocation) {
    return {
      restrict: 'E',
      template: require('./templates/hr-tasks-report.html'),
      scope: {
        title: '@sbTitle',
        redirect: '@sbRedirect',
        totalSize: '<sbTotalSize',
        filterForms: '<sbFilters',
        sortForms: '<sbSorts',
        actions: '<sbActions',
        filterData: '<sbFilterData',
        sortData: '<sbSortData',
        requiredFilterValues: '<sbRequiredFilterValues',
        size: '<sbSize',
        sort: '<sbSort',
        paging: '<sbPaging',
        items: '<sbItems',
        type: '@',
      },
      controller: [
        '$scope',
        'PromiseErrorCatcher',
        'SbxFormModalService',
        function ($scope, PromiseErrorCatcher, SbxFormModalService) {
          $scope.model = ListPageModel;
          $scope.model.initialize(
            $scope.filterData,
            $scope.requiredFilterValues,
            $scope.size,
            $scope.sortData,
            $scope.paging,
            $scope.items,
            $scope.errorMessage,
            {
              url: BackendLocation.entity(1) + 'tickets/hr_report',
              method: 'POST',
            },
            ListLocation.updateUrl,
          );
          $scope.model.hidePaging = true;
          ListLocation.updateModelOnLocationChange($scope);

          $scope.refreshModel = () => {
            $scope.model.refreshWithCurrentParams().catch(PromiseErrorCatcher);
          };
          $scope.setStatus = (msg) => {
            $scope.status = msg;
          };
          $scope.add = () => {
            const modal = SbxFormModalService.open({ data: { url: 'tickets/add' } });
            modal.result.then($scope.refreshModel).catch(PromiseErrorCatcher);
          };
        },
      ],
    };
  },
];

/**
 * @ngdoc component
 * @name sb.onboarding.sbOnboardingTaskReportMenu
 *
 * @description
 * A dropdown menu of task actions.
 */
export const sbOnboardingTaskReportMenu = {
  controllerAs: 'vm',
  template: require('./templates/task-report-menu.html'),
  bindings: {
    task: '=sbTask',
    onSuccess: '&',
  },
  controller: [
    '$scope',
    'TicketsService',
    'SbxFormModalService',
    'PromiseErrorCatcher',
    'BackendLocation',
    '$confirm',
    function (
      $scope,
      TicketsService,
      SbxFormModalService,
      PromiseErrorCatcher,
      BackendLocation,
      $confirm,
    ) {
      function complete() {
        return TicketsService.complete(this.task.id).then((result) => {
          this.onSuccess();
          this.isMenuOpen = false;
          return result;
        });
      }

      function remind() {
        return TicketsService.remind(this.task.id).then((result) => {
          this.isMenuOpen = false;
          this.onSuccess({ msg: 'Reminder sent!' });
          return result;
        });
      }

      function edit() {
        const modal = SbxFormModalService.open({
          data: {
            url: `tickets/${this.task.id}/edit`,
          },
        });
        modal.result.then(this.onSuccess).catch(PromiseErrorCatcher);
      }

      function remove() {
        $confirm({
          title: 'Delete Task',
          body: 'Are you sure you want to delete this task?',
          confirmButtonText: 'Delete',
        })
          .then(() => {
            return TicketsService.remove(this.task.id).then((result) => {
              this.isMenuOpen = false;
              this.onSuccess({ msg: 'Task deleted!' });
              return result;
            });
          })
          .catch(PromiseErrorCatcher);
      }

      this.$onInit = () => {
        this.isMenuOpen = false;
        this.complete = complete.bind(this);
        this.remind = remind.bind(this);
        this.edit = edit.bind(this);
        this.remove = remove.bind(this);
      };
    },
  ],
};
