<div *ngIf="to.preText" [innerHtml]="to.preText" class="sbx-form-field-pretext"></div>
<div class="sbx-form-field {{ to.className }} sbx-widget-subfield-{{ to.subfield }}">
  <div *ngIf="to.label" class="sbx-form-field-label" [attr.sbxFormLabelKey]="key">
    <label for="{{ id }}">
      <span>{{ to.label }}</span>
      <sbx-popover
        *ngIf="to.required || to.selectionRequired"
        [sbxPopoverContainer]="''"
        [sbxPopoverContent]="'Required.'"
        [sbxPopoverPlacement]="['auto']"
      >
        <span class="sbx-required"></span>
      </sbx-popover>
    </label>
    <sbx-popover
      *ngIf="to.help"
      [sbxPopoverContent]="to.help"
      [sbxPopoverPlacement]="['bottom']"
    >
      <sbx-icon class="sbx-help-icon" type="help"></sbx-icon>
    </sbx-popover>
    <sbx-popover
      *ngIf="options.formState?.serverErrors?.[$any(field.key)]"
      [sbxPopoverContent]="options.formState.serverErrors[$any(field.key)][0]"
    >
      <sbx-icon class="sbx-icon-error" type="exclamationTriangle"></sbx-icon>
    </sbx-popover>
  </div>

  <div
    class="sbx-form-field-field"
    [ngClass]="{ 'sbx-full-width': !to.label && !to.displayHelp }"
  >
    <div class="sbx-form-field-input">
      <div
        class="sbx-form-field-input-addon sbx-prefix"
        [ngClass]="{ 'sbx-clickable': !to.readOnly && to.prefix.click }"
        *ngIf="to.prefix"
        (click)="to.readOnly || !to.prefix.click ? null : to.prefix.click($event)"
      >
        <sbx-icon [type]="to.prefix.icon" *ngIf="to.prefix.icon"></sbx-icon>
        {{ to.prefix.text }}
      </div>

      <div class="sbx-component-container" [attr.sbxFormFieldKey]="key">
        <ng-container #fieldComponent></ng-container>
      </div>

      <div
        class="sbx-form-field-input-addon sbx-postfix"
        [ngClass]="{ 'sbx-clickable': !to.readOnly && to.postfix.click }"
        *ngIf="to.postfix"
        (click)="to.readOnly || !to.postfix.click ? null : to.postfix.click($event)"
      >
        <sbx-icon [type]="to.postfix.icon" *ngIf="to.postfix.icon"></sbx-icon>
        {{ to.postfix.text }}
      </div>
    </div>

    <div class="sbx-form-field-error">
      <formly-validation-message
        [field]="field"
        *ngIf="showError"
      ></formly-validation-message>
    </div>
    <!-- For serverErrros without for fields that don't havel label text
         we'll show them here -->
    <span
      class="sbx-no-label-error"
      *ngIf="!to.label && !showError && options.formState?.serverErrors?.[$any(field.key)]"
    >
      {{ options.formState.serverErrors[$any(field.key)][0] }}
    </span>
  </div>

  <div *ngIf="to.displayHelp" class="sbx-form-field-help" [attr.sbxFormLabelKey]="key">
    <sbx-popover
      *ngIf="to.help"
      [sbxPopoverContent]="to.help"
      [sbxPopoverPlacement]="['bottom']"
    >
      <sbx-icon class="sbx-help-icon" type="help"></sbx-icon>
    </sbx-popover>
  </div>
</div>
