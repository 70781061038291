import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxFormModalService } from '../../sbx-form-modal/sbx-form-modal.service';
import { firstValueFrom, of } from 'rxjs';
import { ISbxActionsMenu } from '../../sbx-action-menu/sbx-action-menu.component';
import { ISbxTableColumns } from '../../sbx-table/sbx-table.component';
import { nestedValueByPath } from '@/shared/utils/nested-value-by-path.util';
import { SbxHttpClient } from '@/core/http';
import { GenericUpdateForm } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-list',
  templateUrl: './sbx-object-list.component.html',
  styleUrls: ['./sbx-object-list.component.scss'],
})
export class SbxObjectListComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  items = [];
  addItemButtonTitle = 'Add item';
  columns: ISbxTableColumns = [];
  canAdd = true;
  canEdit = false;
  canDelete = false;
  pipeFormatter = {};

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('dateDisplay', { static: true })
  private dateDisplayTemplate: TemplateRef<any>;
  @ViewChild('numDisplay', { static: true })
  private numDisplayTemplate: TemplateRef<any>;
  @ViewChild('dollarDisplay', { static: true })
  private dollarDisplayTemplate: TemplateRef<any>;
  @ViewChild('enumDropdownDisplay', { static: true })
  private enumDropdownDisplayTemplate: TemplateRef<any>;
  @ViewChild('documentReferenceDisplay', { static: true })
  private documentReferenceDisplayTemplate: TemplateRef<any>;

  constructor(
    @Inject(SbxFormModalService) private sbxFormModalService: SbxFormModalService,
    private readonly http: SbxHttpClient,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    const path = String(this.field.key);
    this.items = nestedValueByPath(this.model, path) || [];

    if (this.to.addItemButtonTitle) {
      this.addItemButtonTitle = this.to.addItemButtonTitle;
    }
    this.canAdd = this.to.canAdd;
    this.canEdit = this.to.canEdit;
    this.canDelete = this.to.canDelete;

    this.to.valueForm.fields.forEach((field) => {
      if (field) {
        this.pipeFormatter[field.key] = {
          type: field.type,
          templateOptions: field.templateOptions,
        };

        if (field.templateOptions.providesFormContext) {
          field.templateOptions = {
            ...field.templateOptions,
            additionalContext: this.model,
          };
        }
      }
    });

    this.setupColumn(this.items);
  }

  lookupColumnTitle(key) {
    return this.to.valueForm.fields
      .map((field) => field.fieldGroup?.[0] || field)
      .find((field) => field.key === key)?.templateOptions.label;
  }

  formatColumn(column) {
    // eslint-disable-next-line dot-notation
    const formatFn = this.options['config']?.[this.key]?.formatColumn;
    if (formatFn) {
      return formatFn(column);
    }

    return column;
  }

  setupColumn(items) {
    this.columns = [];
    if (this.to.valueTitle) {
      this.columns = [
        this.formatColumn({
          key: 'value',
          title: '',
        }),
      ];
    } else if (this.to.valueKeys) {
      for (const key of this.to.valueKeys) {
        if (key === 'index') {
          continue;
        }

        this.columns.push(
          this.formatColumn({
            key: key,
            title: this.lookupColumnTitle(key) || '',
            template: this.formatColumnTemplate(this.pipeFormatter[key]),
            templateContext: this.pipeFormatter[key],
            align: this.getAlignment(this.pipeFormatter[key]),
          }),
        );
      }
    } else if (this.to.valueForm.fields) {
      // check if the rest of conditions are necessary
      for (const field of this.to.valueForm.fields) {
        this.columns.push(
          this.formatColumn({
            key: field.key,
            title: this.lookupColumnTitle(field.key) || '',
            template: this.formatColumnTemplate(this.pipeFormatter[field.key]),
            templateContext: this.pipeFormatter[field.key],
            align: this.getAlignment(this.pipeFormatter[field.key]),
          }),
        );
      }
    } else if (items.length) {
      const keys = Object.keys(items[0]);
      for (const key of keys) {
        if (key === 'index') {
          continue;
        }

        this.columns.push(
          this.formatColumn({
            key: key,
            title: this.lookupColumnTitle(key) || '',
            template: this.formatColumnTemplate(this.pipeFormatter[key]),
            templateContext: this.pipeFormatter[key],
            align: this.getAlignment(this.pipeFormatter[key]),
          }),
        );
      }
    }

    if (!this.to.readOnly) {
      this.columns.push({
        key: 'actions',
        title: 'ACTIONS',
        width: '1px',
        template: this.actionsTemplate,
      });
    }
  }

  displayItems(items) {
    items.forEach((item, i) => {
      item.index = i;
    });
    // If valueTitle is specified in the config, we'll use a single value
    if (this.to.valueTitle) {
      items.forEach((item) => {
        item.value = this.to.valueTitle.replace(
          /{{\s*(.*?)\s*}}/g,
          (_, id) => item[id],
        );
      });
      return items;
    }

    return items;
  }

  formatColumnTemplate(formatter) {
    if (!formatter) {
      return null;
    }

    if (formatter.type === 'number-textline') {
      if (formatter.templateOptions.prefix) {
        return this.dollarDisplayTemplate;
      }
      return this.numDisplayTemplate;
    }
    if (formatter.type === 'date') {
      return this.dateDisplayTemplate;
    }
    if (formatter.type === 'enum-dropdown') {
      return this.enumDropdownDisplayTemplate;
    }
    if (formatter.type === 'pdf-file') {
      return this.documentReferenceDisplayTemplate;
    }
    return null;
  }

  getAlignment(formatter): 'left' | 'center' | 'right' | null {
    if (!formatter) {
      return null;
    }

    if (formatter.type === 'pdf-file') {
      return 'center';
    }

    return 'left';
  }

  formatEnumDropdownValue(item, column) {
    return this.pipeFormatter[column.key].templateOptions.enumVocab.find(
      (kv) => kv.value === item,
    ).label;
  }

  async handleAdd() {
    const model = {};
    const createModalUrl = this.to.valueForm.createModalUrl;
    const externalCreateForm = createModalUrl
      ? await firstValueFrom(
          this.http.entity('2').get<GenericUpdateForm>(createModalUrl),
        )
      : undefined;
    const formFields = externalCreateForm
      ? (externalCreateForm.description as any).fields
      : of(this.to.valueForm.fields);

    this.to.valueForm.fields.forEach(({ fieldGroup }) => {
      if (fieldGroup) {
        fieldGroup.forEach((field) => {
          field.formControl.reset();
        });
      }
    });

    this.sbxFormModalService
      .open({
        data: {
          title: this.to.valueFormAddLabel || this.to.valueLabel,
          formFields,
          model,
          updateUrl: this.to.valueForm.updateUrl,
        },
        size: this.to.valueForm.size || 'md',
      })
      .result.then(({ result }) => {
        this.items.push(result);
        this.updateFormModel();
      })
      .catch(() => null);
  }

  handleRemove(index) {
    this.items = this.items.filter((_, i) => i !== index);
    this.updateFormModel();
  }

  async handleEdit(item) {
    const updateModalUrl = this.to.valueForm.updateModalUrl;
    const externalUpdateForm = updateModalUrl
      ? await firstValueFrom(
          this.http.entity('2').get<GenericUpdateForm>(updateModalUrl),
        )
      : undefined;
    const formFields = externalUpdateForm
      ? (externalUpdateForm.description as any).fields
      : of(this.to.valueForm.fields);

    this.sbxFormModalService
      .open({
        data: {
          title: this.to.valueFormEditLabel || this.to.valueLabel,
          formFields,
          model: { ...item },
          updateUrl: this.to.valueForm.updateUrl,
        },
      })
      .result.then(({ result }) => {
        this.items[item.index] = result;
        this.updateFormModel();
      })
      .catch(() => null);
  }

  updateFormModel() {
    this.formControl.setValue([...this.items]);
    this.setupColumn(this.items);

    this.changeDetectorRef.detectChanges();
  }

  getActionList(item): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        click: () => this.handleEdit(item),
        collapsed: false,
        hidden: !this.canEdit,
      },
      {
        title: 'Delete',
        icon: 'delete',
        click: () => this.handleRemove(item.index),
        collapsed: false,
        hidden: !this.canDelete,
      },
    ];
  }
}
