import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxDropdownComponent } from './sbx-dropdown.component';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [SbxDropdownComponent],
  exports: [SbxDropdownComponent],
})
export class SbxDropdownModule {}
