/**
 * @ngdoc component
 * @name sb.workitem.showPage.component:sbExerciseOptionsCalculator
 *
 * @description
 * This displays the calculator of exercise options cost based on a form data model
 * from the user.
 *
 * @param {object} userModel The sbShowPage Model with exercise_options_key_data form prop:
 *   @property {string} selectedOptions Either `'all'`, `'all_vested'` or `'specific'`.
 *   @property {number} [specificOptionsNumber=0] Number of options to excercise when
 *     selectedOptions is `'specific'`.
 * @param {number} vestedOptions This is number of vested options that the user can purchase.
 * @param {number} exercisableOptions Total exercisable options
 * @param {number} sharePrice Price per share, yup!
 * @param {boolean} earlyExerciseAllowed Is early excercise allowed?
 */
export const sbExerciseOptionsCalculator = {
  controllerAs: 'vm',
  template: require('./templates/exercise-options-calc.html'),
  bindings: {
    formModel: '<',
    vestedOptions: '<',
    exercisableOptions: '<',
    sharePrice: '<',
    earlyExerciseAllowed: '<',
  },
  controller: [
    'AppConfig',
    'ProcessButtonModel',
    function (AppConfig, ProcessButtonModel) {
      function submit() {
        // Add the shares on submit.
        this.formModel.$formData.exercise_options_key_data.shares = this.getOptions();
      }
      function getOptions() {
        const userModel = this.formModel.$formData.exercise_options_key_data;
        const { selectedOptions } = userModel;
        if (selectedOptions === 'all') {
          return this.exercisableOptions;
        } else if (selectedOptions === 'all_vested') {
          return this.vestedOptions;
        } else if (selectedOptions === 'specific') {
          return userModel.specificOptionsNumber || 0;
        }
      }
      function computedCost() {
        return this.sharePrice * this.getOptions();
      }
      function $onInit() {
        const { fields } = this.formModel.$forms.exercise_options_key_data;
        this.optionsFormFields = fields.slice(0, 2);
        this.paymentFormFields = fields.slice(2, 4);

        // Some options are not available.
        const hiddenOptions = {
          all: !this.earlyExerciseAllowed,
          // eslint-disable-next-line camelcase
          all_vested: this.vestedOptions === 0,
        };
        const [selectedOptionsField] = this.optionsFormFields;
        selectedOptionsField.templateOptions.enumVocab =
          selectedOptionsField.templateOptions.enumVocab.filter(
            (option) => !hiddenOptions[option.value],
          );
      }

      ProcessButtonModel.$addSubmitCondition('continue', submit.bind(this), 1);
      this.getOptions = getOptions.bind(this);
      this.computedCost = computedCost.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
};
