import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-string-textline',
  templateUrl: './sbx-string-textline.component.html',
  styleUrls: ['./sbx-string-textline.component.scss'],
})
export class SbxStringTextlineComponent extends FieldType<FieldTypeConfig> {
  handleKeyup() {
    if (this.to.maxLength && this.formControl.value.length > this.to.maxLength) {
      this.formControl.setValue(this.formControl.value.substr(0, this.to.maxLength));
    }
  }
}
