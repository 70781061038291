import angular from 'angular';
import Table from './table';
import Typeahead from './typeahead';
import Form from './form/form';
import Modal from './modal/modal';
import URL from './url';
import Messages from './messages/messages';
import Events from './events/events';
import PDF from './pdf/pdf';
import Promise from './promise';
import Stakeholder from './stakeholder';
import Bootstrap from './bootstrap/bootstrap';
import Tasks from './tasks';
import Administration from './administration';
import Mobile from './mobile';
import Relationships from './relationships';
import Signature from './signature';
import Document from './document/document';
import StakeholderApprovals from './shapprovals';
import Metadata from './metadata';
import MultiStep from './multistep';
import Employee from './employee';
import Tickets from './tickets';
import Navigation from './navigation';
import Sharing from './sharing';
import OrgChart from './orgchart';
import Billing from './billing';
import FieldConfiguration from './field-configuration';
import LoadMoreModel from './load-more';

/**
 * @ngdoc overview
 * @name sb.lib
 *
 * @description
 * This is the main module for Shoobx library code.
 */

export default angular.module('sb.lib', [
  Form,
  Modal,
  URL,
  Messages,
  Events,
  PDF,
  Promise,
  Stakeholder,
  Bootstrap,
  Tasks,
  Tickets,
  Administration,
  Mobile,
  Relationships,
  Signature,
  Document,
  Table,
  StakeholderApprovals,
  Metadata,
  MultiStep,
  Typeahead,
  Employee,
  Navigation,
  Sharing,
  OrgChart,
  Billing,
  FieldConfiguration,
  LoadMoreModel,
]).name;
