import Raven from 'raven-js';
import { ErrorHandler } from '@angular/core';

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(err);
    } else {
      Raven.captureException(err);
    }
  }
}
