import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxFormModalComponent } from './sbx-form-modal.component';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxFormModule } from '../sbx-form/sbx-form.module';
import { SbxFormModalDirective } from './sbx-form-modal.directive';
import { SbxFormModalService } from './sbx-form-modal.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SbxModalModule,
    SbxFormModule,
    SbxButtonModule,
    SbxAlertBannerModule,
  ],
  providers: [SbxFormModalService, NgbActiveModal],
  declarations: [SbxFormModalComponent, SbxFormModalDirective],
  exports: [SbxFormModalComponent, SbxFormModalDirective],
})
export class SbxFormModalModule {}
