<div
  *ngFor="let item of internalModel"
  (click)="to.focus ? to.focus(field, $event) : null"
>
  {{ displayItem(item) }}&nbsp;
  <sbx-icon
    (click)="removeItem(item); to.focus ? to.focus(field, $event) : null"
    type="delete"
  ></sbx-icon>
</div>

<div class="sbx-list-container" (click)="to.focus ? to.focus(field, $event) : null">
  <input
    #textInput
    type="text"
    (keyup)="onKeyUp($event)"
    [ngClass]="{ 'has-errors': showError }"
    (blur)="formControl.markAsTouched()"
  />
  <span class="btn input-group-addon" (click)="parseInput()">
    <sbx-icon type="plus"></sbx-icon>
  </span>
</div>
