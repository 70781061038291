<sbx-row>
  <sbx-col [lg]="12">
    <div class="system-error">
      <p class="emphasis-title">Sorry! We'll be back soon.</p>
      <p class="message">
        We are working to resolve the unscheduled downtime ASAP. Please try again later.
      </p>
    </div>
  </sbx-col>
</sbx-row>
