import { Component, ViewChild, TemplateRef, Inject } from '@angular/core';
import { IColumnTemplate } from './column-templates.model';

@Component({
  selector: 'sbx-bool-check',
  templateUrl: './bool-check.component.html',
  styleUrls: ['./bool-check.component.scss'],
})
export class BoolCheckComponent implements IColumnTemplate {
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
}
