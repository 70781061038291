import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import { DocumentCard } from '@shoobx/types/webapi-v2';

@Injectable()
export class SbxDocumentCardService {
  constructor(private http: SbxHttpClient) {}

  getDocumentCard(id: string, withMetadata = true): Observable<DocumentCard> {
    return this.http
      .entity('2')
      .get(`documents/${id}/card`, { params: { md: Boolean(withMetadata) } });
  }
}
