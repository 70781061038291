import angular from 'angular';

const NEWCARD_FORM_FIELDS = Object.freeze([
  {
    type: 'string-textline',
    key: 'cardholderName',
    data: {},
    templateOptions: {
      label: "Cardholder's Name",
      required: true,
    },
  },
  {
    type: 'stripe-element',
    key: 'stripeCardElement',
    data: {},
    templateOptions: {
      label: 'Card Information',
      required: true,
    },
  },
]);

/**
 * @ngdoc component
 * @name sb.lib.billing.component:sbNewCardForm
 *
 * @description
 * This shows a new card form for user to make a new credit card.
 *
 * @param {expression} ngModel Model expression. Will be a complex object
 *   with a `.stripeCardElement` and `.cardholderName`.
 */
export const sbNewCardForm = {
  controllerAs: 'vm',
  template: require('./templates/new-card-form.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
  },
  controller: [
    function () {
      function $onInit() {
        this.model = this.model || {};
        this.newCardFields = angular.copy(NEWCARD_FORM_FIELDS);
      }
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbNewCardForm

/**
 * @ngdoc component
 * @name sb.lib.billing.component:sbSavedCardForm
 *
 * @description
 * This shows a saved card form for user to pick from existing cards.
 *
 * @param {expression} ngModel Model expression. It will just be the external ID of
 *   the selected card.
 * @param {array<card>} savedCards A listing of `value` and `label` pairs.
 */
export const sbSavedCardForm = {
  controllerAs: 'vm',
  template: require('./templates/saved-card-form.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    cards: '<',
  },
  controller: [
    function () {
      function $onInit() {
        this.model = this.model || this.cards[0].value;
      }
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbSavedCardForm

/**
 * @ngdoc component
 * @name sb.lib.billing.component:sbBillingCardForm
 *
 * @description
 * This shows a form for the user to choose a saved card or make a new card.
 *
 * @param {expression} ngModel Model expression. It will be a complex object with type of `'NEW'`
 *   or `'SAVED'` and a `.newCard` and `.savedCard` respectively.
 * @param {array<card>} [savedCards=undefined] A listing of `value` and `label` pairs.
 */
export const sbBillingCardForm = {
  controllerAs: 'vm',
  template: require('./templates/card-form.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    savedCards: '<?',
  },
  controller: [
    function () {
      function $onInit() {
        this.model = this.model || {};
        const useSavedCardsInit = this.savedCards && this.savedCards.length;
        this.model.type = this.model.type || (useSavedCardsInit ? 'SAVED' : 'NEW');
      }
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbBillingCardForm

/**
 * @ngdoc object
 * @name sb.lib.billing:$addCreditCardModal
 * @kind function
 *
 * @description
 * This modal pops up with information to add a credit card.
 *
 * @param {object} model A state object with:
 *   @method addCard Returns a promise for adding a card.
 *   @property {boolean} loading Loading boolean.
 *   @property {string} error Error state messaging.
 */
export const $addCreditCardModal = [
  '$sbModal',
  function ($sbModal) {
    return (model) =>
      $sbModal.open({
        bindToController: true,
        controllerAs: 'vm',
        size: 'lg',
        template: require('./templates/add-card-modal.html'),
        controller: [
          function () {
            function $onInit() {
              this.model = this.model || {};
              this.error = null;
            }

            async function addCard() {
              try {
                this.loading = true;
                this.error = null;
                const newCard = await this.model.addCard(this.newCardData);
                this.$close(newCard);
              } catch (error) {
                if (angular.isString(error)) {
                  this.error = error;
                }
              } finally {
                this.loading = false;
              }
            }

            this.$onInit = $onInit.bind(this);
            this.addCard = addCard.bind(this);
            this.model = model;
          },
        ],
      }).result;
  },
]; // end $addCreditCardModal
