import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxCounselInfoComponent } from './sbx-counsel-info.component';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxPopoverModule,
    SbxButtonModule,
    SbxModalModule,
    SbxAlertBannerModule,
  ],
  exports: [SbxCounselInfoComponent],
  declarations: [SbxCounselInfoComponent],
  providers: [],
})
export class SbxCounselInfoModule {
  static entryComponents = [SbxCounselInfoComponent];

  static forRoot(): ModuleWithProviders<SbxCounselInfoModule> {
    return {
      ngModule: SbxCounselInfoModule,
    };
  }
}
