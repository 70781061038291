<sbx-page #pageRef>
  <sbx-row>
    <sbx-col [lg]="12">
      <div class="sbx-button-group">
        <sbx-button
          title="Export pro forma cap table"
          icon="download"
          [loading]="exportLoading"
          (click)="handleExportButtonClick()"
        ></sbx-button>
        <sbx-button
          *ngIf="state.canReset"
          title="Reset"
          icon="undo"
          (click)="handleReset()"
        ></sbx-button>
        <div class="sbx-space-button-link">
          <a
            href="https://support.shoobx.com/hc/en-us/articles/360055668791-Next-Round-Planner"
            target="_blank"
          >
            <i>How Do I Use the Next Round Planner?</i>
          </a>
        </div>
      </div>
    </sbx-col>
  </sbx-row>

  <sbx-row class="sbx-mb-40" *ngIf="!pageRef.isLoading()">
    <sbx-col [lg]="12">
      <div class="sbx-chart-container">
        <sbx-row>
          <sbx-col [lg]="6">
            <div class="sbx-pie-charts-container">
              <div>
                <sbx-title type="section">Pre-money</sbx-title>
                <sbx-chart [config]="prePieChartConfig" #prePieChartRef></sbx-chart>
              </div>
              <sbx-icon type="triangleRight" class="sbx-caret-right"></sbx-icon>
              <div>
                <sbx-title type="section">Post-money</sbx-title>
                <sbx-chart
                  #postPieChartRef
                  *ngIf="hasNewRound(); else emptyChartTemplate"
                  [config]="postPieChartConfig"
                ></sbx-chart>
                <ng-template #emptyChartTemplate>
                  <div class="sbx-empty-wrapper">
                    <p>
                      Add the new stock classes and employee options pool data below to
                      see the post money distribution
                    </p>
                    <sbx-icon type="chartPie" class="sbx-large-icon"></sbx-icon>
                  </div>
                </ng-template>
              </div>
            </div>
          </sbx-col>

          <sbx-col [lg]="6" class="sbx-empty-dilution-impact-wrapper">
            <sbx-title type="section">Dilution impact</sbx-title>
            <div *ngIf="state.dilutionImpact.length">
              <ng-container *ngTemplateOutlet="shTableHeaderTemplate"></ng-container>
              <sbx-scrollable class="sbx-scrollable">
                <sbx-table
                  [columns]="stakeholdersColumns"
                  [data]="state.dilutionImpact"
                  [condensed]="true"
                ></sbx-table>
              </sbx-scrollable>
            </div>
            <div class="sbx-empty-wrapper" *ngIf="!state.dilutionImpact.length">
              <p>
                Add stakeholders to see how the new round (specified below) will dilute
                their ownership.
              </p>
              <sbx-icon type="twoPeople" class="sbx-large-icon"></sbx-icon>
            </div>
            <sbx-button
              *ngIf="state.canEdit"
              title="Add stakeholder"
              icon="plus"
              (click)="handleAddStakeholderButtonClick()"
            ></sbx-button>
          </sbx-col>
        </sbx-row>
      </div>
    </sbx-col>
  </sbx-row>

  <sbx-row class="sbx-mb-40">
    <sbx-col [lg]="12">
      <sbx-title type="section">STOCK CLASSES</sbx-title>
      <sbx-table
        [columns]="stockClassesColumns"
        [data]="state.stockClasses"
        id="stock-classes"
      >
      </sbx-table>
      <div class="sbx-button-group">
        <sbx-button
          *ngIf="state.canAddStockClass"
          title="Add stock class"
          icon="plus"
          (click)="handleAddStockClassButtonClick()"
        ></sbx-button>

        <sbx-button
          *ngIf="state.canEditLiquidationStack"
          title="Modify liquidation stack"
          icon="gear"
          (click)="handleModifyLiquidationStackButtonClick()"
        ></sbx-button>

        <sbx-button
          *ngIf="state.canEdit"
          title="Settings"
          icon="gear"
          (click)="handleSettingsButtonClick()"
        ></sbx-button>
      </div>
    </sbx-col>
  </sbx-row>

  <sbx-row class="sbx-mb-40">
    <sbx-col [lg]="12">
      <sbx-title type="section">EMPLOYEE OPTIONS POOL</sbx-title>
      <sbx-table
        [columns]="employeeOptionsColumns"
        [groups]="employeeOptionsGroups"
        [data]="state.employeeOptions"
      ></sbx-table>

      <sbx-button
        *ngIf="state.canEditEmployeeOptions"
        title="Modify employee options pool"
        icon="gear"
        sbxFormModal
        [sbxFormModalUrl]="sbxNextRoundPlannerService.changeSipSettingsFormUrl()"
        [sbxFormModalUrlVersion]="sbxNextRoundPlannerService.API_VERSION"
        (sbxFormModalOnClose)="updateState()"
      ></sbx-button>
    </sbx-col>
  </sbx-row>

  <sbx-row class="sbx-mb-40" *ngIf="state.showInvestorsTable">
    <sbx-col [lg]="12">
      <sbx-title type="section">INVESTORS</sbx-title>
      <sbx-table
        [columns]="yourInvestmentsColumns"
        [groups]="yourInvestmentsGroups"
        [data]="state.investors"
        id="investors"
      >
      </sbx-table>
      <sbx-button
        *ngIf="state.canAddInvestor"
        title="Add investor"
        icon="plus"
        (click)="handleAddInvestorButtonClick()"
      ></sbx-button>
    </sbx-col>
  </sbx-row>

  <sbx-row class="sbx-mb-40">
    <sbx-col [lg]="12">
      <div class="sbx-convertible-title">
        <sbx-title type="section" class="sbx-section">DEBT & ALTERNATIVES </sbx-title>
      </div>

      <sbx-table
        [columns]="debtsAndAlternativesColumns"
        [data]="debtsAndAlternativesData"
        id="convertibles"
      ></sbx-table>
      <sbx-button
        *ngIf="state.canAddDebt"
        title="Add Convertible"
        icon="plus"
        (click)="handleAddConvertiblesButtonClick()"
      ></sbx-button>
    </sbx-col>
  </sbx-row>

  <sbx-row>
    <sbx-col [lg]="12">
      <i>
        Fidelity Private Shares reminds you that the Next Round Planner is merely a tool
        and is not a definitive representation of how the company’s ownership will
        change as a result of an equity financing. This tool does not replace and is not
        a substitute for legal advice, and you remain responsible for calculating and
        validating the outcomes associated with an equity financing.
      </i>
    </sbx-col>
  </sbx-row>
</sbx-page>

<ng-template #investmentTemplate let-item let-row="row">
  <sbx-properties-list [items]="investmentPropertiesList(item)"></sbx-properties-list>
</ng-template>

<ng-template #convertedSharesTemplate let-item let-row="row">
  <sbx-properties-list
    [items]="debtsConvertedSharesPropertiesList(item)"
  ></sbx-properties-list>
</ng-template>

<ng-template #investmentReturnTemplate let-item let-row="row">
  {{ row.estimatedReturnStr }} | {{ row.estimatedCoCStr }}
</ng-template>

<ng-template #stockClassTitleTemplate let-item let-row="row">
  <span [ngClass]="{ 'sbx-bold': shouldBold(item) }">
    {{ row.title }}
    <sbx-popover
      [sbxPopoverTitle]="row.title"
      [sbxPopoverContent]="stockClassPopoverTemplate"
    >
      <sbx-icon type="infoCircle" class="sbx-help-icon"></sbx-icon>
    </sbx-popover>
  </span>
  <div class="sbx-color-tag" [ngStyle]="{ backgroundColor: row.color }"></div>
  <ng-template #stockClassPopoverTemplate>
    <sbx-properties-list [items]="stockClassAdditionalInfoPropertiesList(row)">
    </sbx-properties-list>
  </ng-template>
</ng-template>

<ng-template #optionsTitleTemplate let-item let-row="row">
  <span [ngClass]="{ 'sbx-bold': shouldBold(item) }">
    {{ row.title }}
  </span>
  <div class="sbx-color-tag" [ngStyle]="{ backgroundColor: row.color }"></div>
</ng-template>

<ng-template #stakeholdersActionsTemplate let-item let-row="row">
  <sbx-action-menu [actions]="stakeholdersActions(row)"></sbx-action-menu>
</ng-template>

<ng-template #stockClassActionsTemplate let-item let-row="row">
  <sbx-action-menu [actions]="stockClassActions(row)"></sbx-action-menu>
</ng-template>

<ng-template #investmentActionsTemplate let-item let-row="row">
  <sbx-action-menu [actions]="investmentActions(row)"></sbx-action-menu>
</ng-template>

<ng-template #debtActionsTemplate let-item let-row="row">
  <sbx-action-menu [actions]="debtActions(row)"></sbx-action-menu>
</ng-template>

<ng-template #debtTitleTemplate let-item let-row="row">
  <div class="sbx-debt-title">
    <div [ngClass]="{ 'subfield-1': row.type === 'note' }">
      {{ item }}
      <sbx-popover
        *ngIf="row.type === 'npa' || row.type === 'safe'"
        sbxPopoverTitle="Conversion terms"
        [sbxPopoverContent]="debtPopoverTemplate"
      >
        <sbx-icon type="infoCircle" class="sbx-help-icon"></sbx-icon>
      </sbx-popover>
    </div>
  </div>

  <ng-template #debtPopoverTemplate>
    <sbx-properties-list
      [items]="debtsAdditionalInfoPropertiesList(row)"
    ></sbx-properties-list>
  </ng-template>
</ng-template>

<ng-template #checkTemplate let-item>
  <div class="sbx-check-container" *ngIf="item">
    <sbx-icon type="check"></sbx-icon>
  </div>
</ng-template>

<ng-template #stockClassTopMessageTemplate let-context>
  <p>
    Edit any modifications to this stock class that will be made in conjunction with
    future investments
  </p>
  <sbx-properties-list [items]="context"></sbx-properties-list>
</ng-template>

<ng-template #investmentTopMessageTemplate let-context>
  <p>Edit the holdings to reflect any future investments or increases in ownership</p>
  <sbx-title type="section">Current investment</sbx-title>

  <sbx-properties-list
    [items]="investmentPropertiesList(context.currentInvestment)"
  ></sbx-properties-list>
</ng-template>

<ng-template #shTableHeaderTemplate>
  <sbx-table
    class="sbx-table-as-header"
    [columns]="stakeholdersColumns"
    [data]="[]"
    [headersOnly]="true"
  >
  </sbx-table>
</ng-template>

<ng-template #conditionalBoldTemplate let-item>
  <span [ngClass]="{ 'sbx-bold': shouldBold(item) }">{{ item }}</span>
</ng-template>

<ng-template #checkboxTpl let-item let-row="row">
  <sbx-checkbox
    [name]="row.id"
    [model]="row.converts"
    (change)="handleDebtsConvertChange(row.id, $event)"
  ></sbx-checkbox>
</ng-template>

<ng-template #checkboxHeaderTpl>
  <div class="sbx-flex">
    <sbx-checkbox
      name="select-all"
      [model]="isDebtsConvertSelectAllChecked"
      [indeterminate]="isDebtsConvertSelectAllIndeterminate"
      (change)="handleDebtsConvertSelectAllChange($event)"
    ></sbx-checkbox>
    <div class="sbx-checkbox-custom-label">CONVERTS</div>
  </div>
</ng-template>
