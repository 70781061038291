<sbx-modal-header>
  <h4 class="modal-title">{{ title }}</h4>
</sbx-modal-header>

<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="globalError"
    [error]="true"
    [customText]="globalError"
  ></sbx-alert-banner>

  <div class="sbx-top-message-container">
    You are adjusting the tax amounts manually and it is your responsibility to
    calculate taxes. This should only be done if necessary, and please consult with your
    tax, accounting, or legal advisor before doing so. The tax amounts are only being
    adjusted for this transaction in US dollars.
  </div>

  <div class="sbx-top-form">
    <sbx-form
      formName="adjustment_reason"
      [form]="form"
      [formFields]="formFields"
      [model]="formData"
    ></sbx-form>
  </div>

  <div class="sbx-joined-form">
    <div class="sbx-joined-form-content">
      <sbx-tax-adjust-event-taxes
        *ngIf="adjustments"
        [(adjustments)]="adjustments"
        (validation)="adjustTaxesFormValid = $event"
      ></sbx-tax-adjust-event-taxes>
    </div>
  </div>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    [loading]="loading"
    [disabled]="!adjustments || !form.valid || !adjustTaxesFormValid"
    theme="primary"
    (click)="saveAndClose()"
    title="Save"
  >
  </sbx-button>
  <sbx-button
    theme="secondary"
    [disabled]="loading"
    (click)="sbxModalService.dismiss()"
    title="Cancel"
  ></sbx-button>
</sbx-modal-footer>
