import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxMessagesComponent } from '@/shared/sbx-message/sbx-messages.component';
import { MessagesService } from '@/shared/sbx-message/messages.service';
import { SbxAlertBannerModule } from '@/shared/sbx-alert-banner/sbx-alert-banner.module';

@NgModule({
  imports: [CommonModule, SbxAlertBannerModule],
  declarations: [SbxMessagesComponent],
  exports: [SbxMessagesComponent],
  providers: [MessagesService],
})
export class SbxMessageModule {
  static entryComponents = [SbxMessagesComponent];
}
