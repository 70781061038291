import angular from 'angular';
import BSModal from 'bs-modal-wrap';

const $sbModal = [
  '$uibModal',
  function ($uibModal) {
    return {
      open: (options) =>
        $uibModal.open({
          ...options,
          template: `
        <sbx-modal-wrapper>
          ${options.template}
        </sbx-modal-wrapper>
      `,
        }),
    };
  },
];

const $sbModalStack = [
  '$uibModalStack',
  function ($uibModalStack) {
    return $uibModalStack;
  },
];

/**
 * @ngdoc overview
 * @name sb.lib.modal
 *
 * @description
 * This is the module for the modal.
 */
export default angular
  .module('sb.lib.modal', [BSModal])
  .factory('$sbModal', $sbModal)
  .factory('$sbModalStack', $sbModalStack).name;
