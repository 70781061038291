import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  Directive,
} from '@angular/core';
import { SbxTreeNode } from './sbx-tree.model';
import { SbxTreeSelectEvent } from './sbx-tree.model';
import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';

@Directive()
abstract class SbxTreeBaseComponent {
  @Input() root: SbxTreeNode;

  randomId() {
    return Math.random().toString(36).substring(2);
  }
}

@Directive()
abstract class SbxTreeEditBaseComponent extends SbxTreeBaseComponent {
  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() createSubgroup = new EventEmitter();
}

/**
 *  Tree Editor component, allowing the creation and modification
 *  of tree nodes.
 *
 *  Example:
 *    <sbx-tree-edit [root]="root" [actions]="actions"/>
 */
@Component({
  selector: 'sbx-tree-edit',
  styleUrls: ['sbx-tree-edit.component.scss'],
  templateUrl: './sbx-tree-edit.component.html',
})
export class SbxTreeEditComponent extends SbxTreeEditBaseComponent {}

/**
 *  Used by the SbxTreeEditComponent to build sub-nodes.
 */
@Component({
  selector: 'sbx-tree-node-edit',
  styleUrls: ['sbx-tree-node-edit.component.scss'],
  templateUrl: './sbx-tree-node-edit.component.html',
})
export class SbxTreeNodeEditComponent extends SbxTreeEditBaseComponent {
  @Input() depth = 0;
  @Input() last: boolean;
  @Input() collapseTreeToggle = false;

  actions: ISbxActionsMenu;

  ngOnInit() {
    this.actions = [];
    this.actions.push({
      title: 'Edit',
      icon: 'edit',
      click: () => {
        this.edit.emit(this.root);
      },
      collapsed: false,
    });
    this.actions.push({
      title: 'Delete',
      icon: 'trash',
      click: () => {
        this.remove.emit(this.root);
      },
      collapsed: false,
      disabled: !this.root.removable,
    });
    this.actions.push({
      title: 'Create a Sub-Group',
      icon: 'plus',
      click: () => {
        this.createSubgroup.emit(this.root);
      },
      collapsed: false,
    });
  }

  isExpanded() {
    return this.root.leaves.length > 0 && this.root.expanded;
  }
}

@Directive()
abstract class SbxTreeSelectBaseComponent extends SbxTreeBaseComponent {
  @Output() change = new EventEmitter<SbxTreeSelectEvent>();
}

/**
 * Tree Select component, allowing a number of items to be selected
 * from a tree using check boxes.
 */
@Component({
  selector: 'sbx-tree-select',
  styleUrls: ['sbx-tree-select.component.scss'],
  templateUrl: './sbx-tree-select.component.html',
})
export class SbxTreeSelectComponent extends SbxTreeSelectBaseComponent {
  @Input() includeRoot = false;
  @Input() multiSelect = true;

  selectAllEmployees(allEmployees) {
    if (allEmployees) {
      this.root.getSelectedNodes().forEach((node) => {
        node.selected = false;
      });
      this.emitChange(new SbxTreeSelectEvent([]));
    }
  }

  emitChange(childEvent: SbxTreeSelectEvent) {
    let selectedNodes = childEvent.selectedNodes;
    if (this.multiSelect) {
      const selectedNode = selectedNodes[0];
      if (selectedNode && selectedNode.selected) {
        selectedNode.getNodes().forEach((node) => {
          node.selected = true;
        });
      }
      selectedNodes = this.root.getSelectedNodes();
    }
    this.change.emit(new SbxTreeSelectEvent(selectedNodes));
  }
}

@Component({
  selector: 'sbx-tree-single-select',
  styleUrls: ['sbx-tree-single-select.component.scss'],
  templateUrl: './sbx-tree-single-select.component.html',
})
export class SbxTreeSingleSelectComponent
  extends SbxTreeSelectComponent
  implements OnInit
{
  @Input() selectedNode: SbxTreeNode;

  ngOnInit() {
    if (this.selectedNode) {
      this.selectedNode.selected = true;
    }
  }

  emitChange(childEvent: SbxTreeSelectEvent) {
    for (const prevSelected of this.root.getSelectedNodes()) {
      prevSelected.selected = false;
    }

    for (const node of childEvent.selectedNodes) {
      node.selected = true;
      this.selectedNode = node;
    }

    this.change.emit(new SbxTreeSelectEvent(this.root.getSelectedNodes()));
  }
}

/**
 * Used by the SbxTreeSelectComponent and SbxTreeSingleSelectComponent
 * to build sub-nodes.
 */
@Component({
  selector: 'sbx-tree-node-select',
  styleUrls: ['sbx-tree-node-select.component.scss'],
  templateUrl: './sbx-tree-node-select.component.html',
})
export class SbxTreeNodeSelectComponent extends SbxTreeSelectBaseComponent {
  @Input() rootChain: SbxTreeNode[] = [];
  @Input() renderChildren = true;
  @Input() multiSelect = false;

  updateSelected() {
    if (this.multiSelect) {
      this.root.selected = !this.root.selected;
    } else {
      this.root.selected = true;
    }
    this.emitChange([this.root]);
  }

  emitChange(nodes) {
    this.change.emit(new SbxTreeSelectEvent(nodes));
  }
}

/**
 *  A component for displaying an immutable tree structure.
 *
 *  Example:
 *    <sbx-tree-view [root]="root"/>
 */
@Component({
  selector: 'sbx-tree-view',
  styleUrls: ['sbx-tree-view.component.scss'],
  templateUrl: './sbx-tree-view.component.html',
})
export class SbxTreeViewComponent extends SbxTreeBaseComponent {
  @Input() includeRoot = false;
}

/**
 * Used by the SbxTreeViewComponent to build sub-nodes.
 */
@Component({
  selector: 'sbx-tree-node-view',
  styleUrls: ['sbx-tree-node-view.component.scss'],
  templateUrl: './sbx-tree-node-view.component.html',
})
export class SbxTreeNodeViewComponent extends SbxTreeBaseComponent {
  @Input() depth = 0;
}
