import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { SbxHttpClient } from '@/core/http/';
import { Downgrade } from '@/shared/downgrade';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { SbxAlertBannerComponent } from '@/shared/sbx-alert-banner/sbx-alert-banner.component';
import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';

const SELECTOR = 'sbx-monitor-approvals';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-monitor-approvals.component.html',
  styleUrls: ['./sbx-monitor-approvals.component.scss'],
})
export class SbxMonitorApprovalsComponent implements OnInit {
  @ViewChild('statusBanner') statusBanner: SbxAlertBannerComponent;

  @Input() resolutiondetails: Array<any>;
  @Input() workitem = '';
  endpoint: string;
  showDiscontinueButton = false;

  constructor(
    @Inject(SbxHttpClient) private service: SbxHttpClient,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    @Inject(SbxConfirmationModalService)
    private sbxConfirmationModalService: SbxConfirmationModalService,
    @Inject(Window) private window: Window,
  ) {}

  ngOnInit() {
    let wiId = this.workitem; // If this is in a composable workitem, ID is inherited.
    if (wiId === '') {
      wiId = this.processUrlInfo.wiId(); // Otherwise, ID is in URL.
    }
    this.endpoint = `workitems/${wiId}/monitor-approvals/`;
    this.checkDiscontinue();
  }

  async checkDiscontinue() {
    const res: any = await this.service
      .entity('2')
      .get(this.endpoint + 'discontinue')
      .toPromise();
    if (res) {
      this.showDiscontinueButton = true;
    }
  }

  async handleDiscontinue() {
    try {
      await this.sbxConfirmationModalService.open({
        data: {
          body:
            'You have previously elected to require the signatures below. Are you sure you ' +
            'want to continue without the signatures?',
        },
      }).result;
      await this.service
        .entity('2')
        .post(this.endpoint + 'discontinue')
        .toPromise();
      if (this.workitem) {
        // Refresh window in composed workitem
        this.window.location.reload();
      }
    } catch (e) {}
  }

  async handleUndo(sig) {
    const res: any = await this.service
      .entity('2')
      .post(this.endpoint + 'undoSig', { params: sig.upload_extraData })
      .toPromise();
    if (res.undoSucceeded) {
      this.window.location.reload();
    }
  }

  async handleUpload(uploadExtraData) {
    const res: any = await this.service
      .entity('2')
      .post(this.endpoint + 'upload', { params: uploadExtraData })
      .toPromise();
    this.window.location.replace(res.newUrl);
  }

  async handleRemind(remindExtradata) {
    const remindResult: any = await this.service
      .entity('2')
      .post(this.endpoint + 'remind', { params: remindExtradata })
      .toPromise();
    if (remindResult.remindSucceeded) {
      this.statusBanner.show({
        customText: `Reminder sent to ${remindResult.remindName}.`,
        error: false,
        warning: false,
      });
    } else {
      this.statusBanner.show({
        customText: `Could not send reminder to ${remindResult.remindName}.`,
        error: true,
        warning: false,
      });
    }
  }

  getActionList(sig, doc): ISbxActionsMenu {
    return [
      {
        title: 'Upload Signature',
        icon: 'upload',
        click: () => this.handleUpload(sig.upload_extraData),
        collapsed: true,
        hidden: !this.canUploadSignature(sig, doc),
      },
      {
        title: 'Remind',
        icon: 'envelope',
        click: () => this.handleRemind({ remind: sig.stakeholder.id }),
        collapsed: true,
        hidden: !this.canRemind(sig),
      },
    ];
  }

  private canUploadSignature(sig, doc): boolean {
    return (
      sig.signature.placeholder &&
      sig.upload_extraData.process &&
      sig.upload_extraData.status === null &&
      sig.stakeholder.id !== doc.me &&
      !doc.hide_links
    );
  }

  private canRemind(sig): boolean {
    return (
      sig.signature.placeholder &&
      sig.upload_extraData.process &&
      sig.upload_extraData.status === null &&
      !sig.sign_button
    );
  }
}
