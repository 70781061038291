import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TailoredProcessListService } from './service/TailoredProcessList.service';
import { SbxTailoredProcessListComponent } from './sbx-tailored-process-list.component';
import { CoreModule } from '@/core/core.module';
import { SbxTableModule } from '@/shared/sbx-table/sbx-table.module';

@NgModule({
  imports: [CoreModule, CommonModule, SbxTableModule],
  declarations: [SbxTailoredProcessListComponent],
  providers: [TailoredProcessListService],
})
export class SbxTailoredProcessListModule {
  static entryComponents = [SbxTailoredProcessListComponent];

  static forRoot(): ModuleWithProviders<SbxTailoredProcessListModule> {
    return {
      ngModule: SbxTailoredProcessListModule,
    };
  }
}
