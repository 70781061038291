import angular from 'angular';
import { Map, Record, List } from 'immutable';
import { combineLatest } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import moment from 'moment';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.pdf.formCreation:PDFFormCreationModel
 *
 * @description
 * Use this to instansiate a form creation model for PDFs. This will be all the state assoicated
 * with creating a template with fields associated. It will have fields, their parameters and do all
 * of the persistence needed.
 *
 * @param {string} defaultActor The default actor for each new field. When it is created, it will have this
 *   as the actor who fills this field out in a process.
 * @returns {object} The model object with all state.
 */
export const PDFFormCreationModel = [
  '$q',
  '$uuid',
  'SimpleHTTPWrapper',
  'isGroupFieldConfigurationType',
  function (
    $q,
    $uuid,
    SimpleHTTPWrapper,
    isGroupFieldConfigurationType,
  ) {
    const Field = class extends Record(
      {
        id: undefined,
        type: undefined,
        formModel: undefined,
        invalid: false,
        open: false,
        params: List(),
      },
      'Field',
    ) {
      get pdfformtype() {
        switch (this.type) {
          case 'string-textline':
            return 'Single-Line Text';
          case 'text':
            return 'Multi-Line Text';
          case 'number-textline':
            return 'Number';
          case 'bool-checkbox':
            return 'Checkbox';
          case 'enum-radios':
            return 'Radio Buttons';
          case 'date':
            return 'Date';
          case 'ssn':
            return 'SSN';
          case 'checklist':
            return 'Check Boxes';
          case 'signature':
            return 'Signature';
          default:
            return this.type;
        }
      }
    };
    const Parameter = Record({
      id: undefined,
      selected: false,
      pageNumber: undefined,
      height: undefined,
      width: undefined,
      top: undefined,
      left: undefined,
      formModel: undefined,
    });

    return (defaultActor, initDataUrl, initPdfUrl) => ({
      /**
       * @ngdoc property
       * @name fieldParams
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * A normalized map of paramId to Parameter records.
       */
      fieldParams: Map(),

      /**
       * @ngdoc property
       * @name fields
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * A List of Field records.
       */
      fields: List(),

      /**
       * @ngdoc property
       * @name drawingEnabledId
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * If truthy, it will be a string ID of the next param.
       */
      drawingEnabledId: null,

      /**
       * @ngdoc property
       * @name pdfUrl
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * The url string of the PDF of the template.
       */
      pdfUrl: initPdfUrl,

      /**
       * @ngdoc property
       * @name dataUrl
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * The url string of the backend location
       */
      dataUrl: initDataUrl,

      /**
       * @ngdoc property
       * @name loading
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * A boolean of outstanding async.
       */
      loading: false,

      /**
       * @ngdoc property
       * @name error
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * An error string for display.
       */
      error: null,

      /**
       * @ngdoc property
       * @name lastAutosave
       * @propertyOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Timestamp of last autosave
       */
      lastAutosave: undefined,

      setAutosaveTime() {
        const time = new moment();
        this.lastAutosave = time.format('HH:mm:ss');
      },

      _serializeParam(paramId, subfield) {
        const param = this.fieldParams.get(paramId);
        if (!param) {
          return {};
        }

        const paramModel = param.formModel || {};
        return {
          title: paramModel.title,
          x: param.left,
          y: param.top,
          height: param.height,
          width: param.width,
          page: param.pageNumber,
          value: subfield && param.id,
        };
      },

      _serializeParamsForField(field) {
        if (isGroupFieldConfigurationType(field.type)) {
          return {
            subfields: field.params.map((paramId) =>
              this._serializeParam(paramId, true),
            ),
          };
        }
        return this._serializeParam(field.params.get(0));
      },

      _serializeFields(fields) {
        return fields.map((field) => ({
          id: field.id,
          type: field.type,
          actor: field.formModel.actor,
          title: field.formModel.title,
          description: field.formModel.description,
          isSensitive: field.formModel.isSensitive,
          required: field.formModel.required,
          params: this._serializeParamsForField(field),
        }));
      },

      _deserializeFields(networkFields) {
        const paramLookup = networkFields.reduce((accum, nf) => {
          const isGroup = isGroupFieldConfigurationType(nf.type);
          const params = nf.params ? [nf.params] : [];
          const unFormatParmams = isGroup ? nf.params.subfields : params;
          accum[nf.id] = unFormatParmams.map(
            (param) =>
              new Parameter({
                id: param.value || nf.id,
                pageNumber: param.page,
                left: param.x,
                top: param.y,
                width: param.width,
                height: param.height,
                formModel: isGroup ? { title: param.title } : undefined,
              }),
          );
          return accum;
        }, {});
        this.fieldParams = Map(
          Object.values(paramLookup).reduce((accum, paramsForField) => {
            const flatFields = paramsForField.map((param) => [param.id, param]);
            return accum.concat(flatFields);
          }, []),
        );
        this.fields = List(
          networkFields.map(
            (nf, i) =>
              new Field({
                id: nf.id,
                type: nf.type,
                open: this.fields.get(i, Map()).get('open', false),
                formModel: {
                  actor: nf.actor,
                  title: nf.title,
                  description: nf.description,
                  isSensitive: nf.isSensitive,
                  required: nf.required,
                  invalid: false,
                  invalidParams: false,
                },
                params: List(paramLookup[nf.id].map((param) => param.id)),
              }),
          ),
        );
      },

      _swapIndexes(list, direction, fromIndex) {
        const fromItem = list.get(fromIndex);
        const toIndex = direction === 'DOWN' ? fromIndex - 1 : fromIndex + 1;
        const toItem = list.get(toIndex);
        return list.withMutations((mutList) => {
          mutList.set(fromIndex, toItem).set(toIndex, fromItem);
        });
      },

      /**
       * @ngdoc method
       * @name enqueueField
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Call this to queue up a field for creation. This turns drawing on.
       *
       * @param {string} type The type of the new field.
       */
      enqueueField(type) {
        this.drawingEnabledId = $uuid();
        if (type === 'signature' || type === 'bool-checkbox' || type === 'ssn') {
          this.queuedField = new Field({
            id: 'field_' + $uuid(),
            type,
            formModel: {
              actor: defaultActor,
            },
          });
        } else if (type === 'enum-radios' || type === 'checklist') {
          this.queuedField = new Field({
            id: 'field_' + $uuid(),
            type,
            formModel: {
              actor: defaultActor,
              required: false,
            },
          });
        } else {
          this.queuedField = new Field({
            id: 'field_' + $uuid(),
            type,
            formModel: {
              actor: defaultActor,
              isSensitive: false,
              required: false,
            },
          });
        }

        if (!this.pdfUrl) {
          this.createField(null);
        }
      },

      /**
       * @ngdoc method
       * @name createField
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Call this to take a queued field and add it to the model.
       *
       * @param {number} pageNumber This is the number of the page to add the field to.
       */
      createField(pageNumber) {
        if (!this.queuedField) {
          return;
        }
        const queuedField = this.queuedField;
        const queuedFieldId = queuedField.id;
        const isGroup = isGroupFieldConfigurationType(queuedField.type);
        const paramsId = this.drawingEnabledId;
        const newParam = new Parameter({
          pageNumber,
          id: paramsId,
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        });
        const params = isGroup ? newParam.set('formModel', {}) : newParam;
        this.fieldParams = this.fieldParams.set(paramsId, params);
        this.selectParams(paramsId);

        // We lookup the queuedField.id just incase this is adding new params
        // to an old field and other properies of the old field have changed since
        // queuing up the new params.
        const [foundIndex, foundField] =
          this.fields.findEntry((field) => field.id === queuedFieldId) || [];
        const newFieldBase = foundField || queuedField;
        const newField = newFieldBase.set('params', newFieldBase.params.push(paramsId));
        this.fields = foundField
          ? this.fields.set(foundIndex, newField)
          : this.fields.push(newField);
        this.drawingEnabledId = null;
        this.queuedField = null;
        this.fields = this.fields.map((field) =>
          field.set('open', field.id === queuedFieldId),
        );
      },

      /**
       * @ngdoc method
       * @name selectParams
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} paramsId ID of the prams to become selected.
       */
      selectParams(paramsId) {
        this.fieldParams = this.fieldParams.map((params, id) =>
          params.set('selected', id === paramsId),
        );
      },

      /**
       * @ngdoc method
       * @name changeParamsDimensions
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} paramsId ID of the parameter to change.
       * @param {number} top New top dimension.
       * @param {number} left New left dimension.
       * @param {number} width New width dimension.
       * @param {number} height New height dimension.
       */
      changeParamsDimensions(paramsId, top, left, width, height) {
        this.fieldParams = this.fieldParams.map((params, id) => {
          if (id === paramsId) {
            return params.merge({ top, left, width, height });
          }
          return params;
        });
      },

      /**
       * @ngdoc method
       * @name toggleFieldOpen
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} fieldId ID of the field to change.
       */
      toggleFieldOpen(fieldId) {
        this.fields = this.fields.map((field) => {
          return field.id === fieldId ? field.set('open', !field.open) : field;
        });
      },

      /**
       * @ngdoc method
       * @name deleteField
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} fieldId ID of the field to delete.
       */
      deleteField(fieldId) {
        const [fieldIndex, field] = this.fields.findEntry(
          (field) => field.id === fieldId,
        );
        this.fieldParams = this.fieldParams.withMutations((mutParams) => {
          field.params.forEach((paramId) => {
            mutParams.delete(paramId);
          });
        });
        this.fields = this.fields.delete(fieldIndex);
      },

      /**
       * @ngdoc method
       * @name addParamTo
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Like this.enqueueField but for a parameter on a field that already exists.
       *
       * @param {string} fieldId ID of the field to add the param to.
       */
      addParamTo(fieldId) {
        this.queuedField = this.fields.find((field) => field.id === fieldId);
        this.drawingEnabledId = $uuid();
      },

      /**
       * @ngdoc method
       * @name deleteParamFrom
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} fieldId ID of the field to delete from.
       * @param {string} paramId ID of the param to delete.
       */
      deleteParamFrom(fieldId, paramId) {
        this.fieldParams = this.fieldParams.delete(paramId);
        this.fields = this.fields
          .map((field) => {
            return field.id === fieldId
              ? field.set(
                  'params',
                  field.params.filter((id) => id !== paramId),
                )
              : field;
          })
          .filter((field) => field.params.size);
      },

      /**
       * @ngdoc method
       * @name moveField
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} fieldId ID of the field to move.
       * @param {string} direction `'UP'` or `'DOWN'` for _index_ direction.
       */
      moveField(fieldId, direction) {
        const { fields } = this;
        const fieldIndex = fields.findIndex((field) => field.id === fieldId);
        this.fields = this._swapIndexes(fields, direction, fieldIndex);
      },

      /**
       * @ngdoc method
       * @name moveParam
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @param {string} fieldId ID of the field the param is attached to.
       * @param {string} paramId ID of the param to move.
       * @param {string} direction `'UP'` or `'DOWN'` for _index_ direction.
       */
      moveParam(fieldId, paramId, direction) {
        const { fields } = this;
        const [fieldIndex, field] = fields.findEntry((field) => field.id === fieldId);
        const paramIndex = field.params.findIndex((pid) => pid === paramId);
        const newParams = this._swapIndexes(field.params, direction, paramIndex);
        this.fields = fields.set(fieldIndex, field.set('params', newParams));
      },

      /**
       * @ngdoc method
       * @name recreateFields
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Call to recreate all the fields as a new object. Important if you want to
       * trigger fields redraw.
       */
      recreateFields() {
        this.fields = this.fields.map(angular.identity);
      },

      /**
       * @ngdoc method
       * @name saveTemplate
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Saves the template as is (fields and proc config).
       * @param {immutable.List<Object>} fields
       * @returns {Promise} Resolves on successful save and rejects on failure.
       */
      saveTemplate(fields) {
        const { dataUrl } = this;
        if (!dataUrl) {
          return $q.resolve();
        }
        this.loading = true;
        this.error = null;
        return SimpleHTTPWrapper(
          {
            method: 'PUT',
            url: dataUrl,
            data: {
              fields: this._serializeFields(fields),
            },
          },
          'Could not save template information.',
        )
          .then((data) => {
            // Setup the fields that the backend was able to extract.
            if (data.fields) {
              this._deserializeFields(data.fields);
            }
          })
          .catch((err) => {
            if (angular.isString(err)) {
              this.error = err;
            }
            return $q.reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.lib.pdf.formCreation.PDFFormCreation
       *
       * @description
       * Initializes the model/hydrates the state
       *
       * @returns {Promise} Resolves on successful save and rejects on failure.
       */
      init() {
        const { dataUrl } = this;
        if (!dataUrl) {
          return $q.resolve();
        }
        this.loading = true;
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: dataUrl,
          },
          'Could not fetch template information.',
        )
          .then((data) => {
            if (data.fields) {
              this._deserializeFields(data.fields);
            }

            return data;
          })
          .catch((err) => {
            if (angular.isString(err)) {
              this.error = err;
            }
            return $q.reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      isGroup: isGroupFieldConfigurationType,
    });
  },
]; // end PDFFormCreationModel

/**
 * @ngdoc component
 * @name sb.lib.pdf.formCreation.component:sbPdfFormCreator
 *
 * @description
 * This is the container component for the entire custom form PDF creation flow.
 *
 * @param {array<Actor>} actors Array or actor `{ label, value }` pairs.
 * @param {dataurl} the url for backend call
 */
export const sbPdfFormCreator = {
  template: require('./templates/pdf-form-creator.html'),
  controllerAs: 'vm',
  bindings: {
    actors: '<',
    dataurl: '@?',
    pdfurl: '@?',
  },
  controller: [
    '$scope',
    '$element',
    '$observable',
    '$interval',
    '$q',
    'PromiseErrorCatcher',
    'WindowLocation',
    'PDFFormCreationModel',
    'ProcessButtonModel',
    function (
      $scope,
      $element,
      $observable,
      $interval,
      $q,
      PromiseErrorCatcher,
      WindowLocation,
      PDFFormCreationModel,
      ProcessButtonModel,
    ) {
      function autosave(fields) {
        this.model
          .saveTemplate(fields, false)
          .then(() => this.model.setAutosaveTime())
          .catch(PromiseErrorCatcher);
      }

      function toggleContinue(disable) {
        if (disable) {
          ProcessButtonModel.disable('continue');
        } else {
          ProcessButtonModel.requestEnable('continue');
        }
      }

      function $onInit() {
        const { actors, dataurl, pdfurl } = this;
        const model = (this.model = PDFFormCreationModel(
          actors[0].value,
          dataurl,
          pdfurl,
        ));
        model.init().catch(PromiseErrorCatcher);
        // Watch fields to autosave every time all the fields are in a valid state
        const field$ = $observable
          .fromWatcher($scope, () => this.model.fields, true)
          .pipe(map((field) => field?.newValue));
        combineLatest(field$, (fields) =>
          fields.reduce(
            (accum, field) => {
              accum.fields.push(field);
              if (field.type === 'enum-radios' && field.params.size <= 1) {
                field.formModel.invalid = true;
              }
              accum.invalid = accum.invalid || field.formModel.invalid;
              this.toggleContinue(accum.invalid);
              return accum;
            },
            { fields: [], invalid: false },
          ),
        )
          .pipe(debounceTime(5000))
          .$applySubscribe($scope, (newValue) => {
            if (newValue.invalid === false) {
              this.autosave(newValue.fields);
            }
          });

        ProcessButtonModel.$addSubmitCondition('continue', () => {
          this.model
            .saveTemplate(model.fields, false)
            .then(() => {
              // Continue save
            })
            .catch(PromiseErrorCatcher);
        });
      }
      this.toggleContinue = toggleContinue.bind(this);
      this.autosave = autosave.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbPdfFormCreator

/**
 * @ngdoc component
 * @name sb.lib.pdf.formCreation.component:sbFieldCustomizedPdfViewer
 *
 * @description
 * This will draw a PDF frame with customizable field draw.
 *
 * @param {immutable.List<Object>} fields
 *   @property {object} formModel Field configuration form model.
 *   @property {immutable.List<string>} params List of param IDs in this field.
 * @param {immutable.Map<Parameter>} params Map of params (boxes).
 * @param {string} drawingEnabledId If truthy string, drawing is currently enabled and
 *   the new box will be drawn with this box ID. Must be unique for boxes/params.
 * @param {template} pdfUrl The URL to the PDF file for the frame.
 * @param {expression} onBoxCreation Called when user creates a box. `$pageNumber` will be
 *   in the namespace.
 * @param {expression} onBoxChange Called when user modifies a box's location or dimensions.
 *   It will have in namespace: `$id`, `$top`, `$left`, `$width`, `$height`.
 * @param {expression} onBoxDeletion Called when user deletes a box. `$id` will be
 *   in the namespace.
 * @param {expression} onBoxSelect Called when user selects a box. It will have `$id` in namespace.
 */
export const sbFieldCustomizedPdfViewer = {
  controllerAs: 'vm',
  template: `
    <sbx-pdf-viewer
      [url]="::vm.pdfUrl"
      [mode]="'draw'"
      [drawing-id]="vm.drawingEnabledId"
      [drawing-fields]="vm.mappedFields"
      (drawing-field-add)="vm.handleFieldAdd($event)"
      (drawing-field-modify)="vm.handleFieldModify($event)"
      (drawing-field-delete)="vm.handleFieldDelete($event)"
      (drawing-field-select)="vm.handleFieldSelect($event)"
    ></sbx-pdf-viewer>
  `,
  bindings: {
    fields: '<',
    params: '<',
    drawingEnabledId: '<',
    pdfUrl: '@',
    onBoxCreation: '&',
    onBoxChange: '&',
    onBoxDeletion: '&',
    onBoxSelect: '&',
  },
  controller: [
    '$scope',
    'isGroupFieldConfigurationType',
    '$filter',
    function ($scope, isGroupFieldConfigurationType, $filter) {
      function $onInit() {
        $scope.$watchGroup(['vm.fields', 'vm.params'], ([fields, params]) => {
          fields = fields.toJS();
          params = params.toJS();

          this.mappedFields = fields.reduce((acc, field, i) => {
            const fieldParams = field.params.map((key) => params[key]);
            fieldParams.forEach((param, j) => {
              let title = i + 1;
              title += isGroupFieldConfigurationType(field.type)
                ? $filter('indexToChar')(j)
                : '';
              title += field.formModel?.required ? '*' : '';

              acc[param.id] = {
                title,
                id: param.id,
                pageNumber: param.pageNumber,
                left: param.left,
                top: param.top,
                width: param.width,
                height: param.height,
                selected: param.selected,
              };
            });
            return acc;
          }, {});
        });
      }

      function handleFieldAdd(annotation) {
        $scope.$apply(() => {
          this.onBoxCreation({ $pageNumber: annotation.pageNumber });
          this.onBoxChange({
            $id: annotation.id,
            $left: annotation.left,
            $top: annotation.top,
            $width: annotation.width,
            $height: annotation.height,
          });
        });
      }

      function handleFieldModify(annotation) {
        $scope.$apply(() => {
          this.onBoxChange({
            $id: annotation.id,
            $left: annotation.left,
            $top: annotation.top,
            $width: annotation.width,
            $height: annotation.height,
          });
        });
      }

      function handleFieldDelete(annotation) {
        $scope.$apply(() => {
          const field = this.fields.toJS().find((field) => {
            return field.params.includes(annotation.id);
          });
          this.onBoxDeletion({ $fieldId: field.id, $paramId: annotation.id });
        });
      }

      function handleFieldSelect(annotation) {
        $scope.$apply(() => {
          this.onBoxSelect({ $id: annotation.id });
        });
      }

      this.mappedFields = {};
      this.$onInit = $onInit.bind(this);
      this.handleFieldAdd = handleFieldAdd.bind(this);
      this.handleFieldModify = handleFieldModify.bind(this);
      this.handleFieldDelete = handleFieldDelete.bind(this);
      this.handleFieldSelect = handleFieldSelect.bind(this);
    },
  ],
}; // end sbFieldCustomizedPdfViewer
