import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxChartComponent } from './sbx-chart.component';
import { SbxChartSharedLegendComponent } from './sbx-chart-shared-legend.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxChartComponent, SbxChartSharedLegendComponent],
  exports: [SbxChartComponent, SbxChartSharedLegendComponent],
})
export class SbxChartModule {}
