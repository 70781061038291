import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import {
  SbxSignatureModalComponent,
  ISignatureModalData,
} from './sbx-signature-modal.component';

@Injectable()
export class SbxSignatureModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: ISignatureModalData) {
    const modal = this.sbxModalService.open(SbxSignatureModalComponent, {
      size: 'md',
      data,
    });

    return modal;
  }
}
