import { Injectable } from '@angular/core';
import { SbxModalService, SbxNgbModalRef } from '../sbx-modal/sbx-modal.service';
import { SbxFormModalComponent } from './sbx-form-modal.component';
import { Downgrade } from '../downgrade';
import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';

/**
 *  Shoobx Modal Service
 *
 *  Service for managing modals on a page using a stack.
 *  Wraps bootstrap's modal service.
 *
 *  Should not be needed for direct use in view components.
 *
 *  See: SbxModalDirective
 */
@Downgrade.Injectable('ngShoobx', 'SbxFormModalService')
@Injectable()
export class SbxFormModalService {
  public constructor(private readonly modalService: SbxModalService) {}

  public open(config: ISbxFormModalConfig): SbxNgbModalRef {
    return this.modalService.open(SbxFormModalComponent, config);
  }
}
