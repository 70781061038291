import angular from 'angular';
import { Map } from 'immutable';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @ngdoc component
 * @name sb.lib.fieldConfiguration.component:sbFieldConfigurationListing
 *
 * @description
 * This creates a listing of `sbFieldConfiguration` and `sbGroupFieldConfiguration`. This allows the user
 * to create many fields of both group and non-group types and order/manage them. Each field has one or more
 * "parameter"s (for groups there is one param for each option, for normal fields, there is only a singular).
 *
 * @param {immutable.List<Field>} fields List of field (record) objects:
 *   @property {string} id ID of the field.
 *   @property {boolean} open If group is open for editing.
 *   @property {string} type Type of the field (a user io string type)
 *   @property {object} formModel The field configuration model.
 * @param {immutable.Map<Param>} params Map of param objects.
 *   @property {string} id Unique identifier of the param.
 *   @property {object} formModel Param configuration model object.
 * @param {array<Object>} actors Array of objects with `value` and `label` actor vocab.
 * @param {expression} deleteParamFrom Called when user deletes an option from a group
 *   field. `$param` and `$field` will be in the namespace.
 * @param {expression} addParamTo Called when user adds an option to a group field. `$field`
 *   will be in the namespace.
 * @param {expression} deleteField Called when user deletes an entire group/field.
 *   `$field` will be in the namespace.
 * @param {expression} toggleField Called when user toggles open a field. `$field` will be
 *   in the namespace.
 * @param {expression} moveField Called when user moves a group with `$direction` and `$field`
 *   in the namespace. Direction will be `'DOWN'` or `'UP'`.
 * @param {expression} moveParam Called when user moves a param with `$direction`, `$field`, and
 *   `$param` in the namespace. Direction will be `'DOWN'` or `'UP'`.
 * @param {expression} onFieldNameChange Called when user changes a field title of a non-group field.
 */
export const sbFieldConfigurationListing = {
  template: require('./templates/listing.html'),
  controllerAs: 'vm',
  bindings: {
    fields: '<',
    params: '<',
    actors: '<',
    drawingEnabledId: '<',
    deleteParamFrom: '&',
    addParamTo: '&',
    deleteField: '&',
    toggleField: '&',
    moveField: '&',
    moveParam: '&',
    onFieldNameChange: '&',
    onSelect: '&',
  },
  controller: [
    '$scope',
    '$observable',
    '$exceptionHandler',
    'isGroupFieldConfigurationType',
    function ($scope, $observable, $exceptionHandler, isGroupFieldConfigurationType) {
      function $onInit() {
        // This code transforms the state passed in from the higher level scope (this.fields and this.params)
        // and transforms it into some "view models" (this.paramsSelectedFieldId and this.paramsForField) that
        // are useful for the view and that we don't want to recompute every $digest for instance.
        const field$ = $observable
          .fromWatcher($scope, () => this.fields)
          .pipe(map((field) => field?.newValue));
        const params$ = $observable
          .fromWatcher($scope, () => this.params)
          .pipe(map((field) => field?.newValue));
        combineLatest(field$, params$, (fields, params) => {
          return fields.reduce(
            (accum, field) =>
              accum.set(
                field.id,
                field.params.map((pid) => params.get(pid)).filter(angular.identity),
              ),
            Map(),
          );
        }).$applySubscribe(
          $scope,
          (paramsForField) => {
            this.paramsSelectedFieldId = paramsForField.reduce(
              (accum, params, fieldId) => {
                if (accum) {
                  return accum;
                }
                const hasSelected = params.reduce(
                  (a, param) => a || param.selected,
                  false,
                );
                return hasSelected ? fieldId : accum;
              },
              undefined,
            );
            this.paramsForField = paramsForField;
          },
          $exceptionHandler,
        );
      }
      function selectParam(field) {
        field.params.forEach((param) => {
          this.onSelect({ $param: param });
        });
      }
      this.selectParam = selectParam.bind(this);
      this.$onInit = $onInit.bind(this);
      this.isGroupFieldType = isGroupFieldConfigurationType;
    },
  ],
}; // end sbFieldConfigurationListing
