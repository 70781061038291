import angular from 'angular';
import { Map } from 'immutable';

/**
 * @ngdoc directive
 * @name sb.lib.table.directive:sbTable
 * @restrict E
 *
 * @description
 * This directive is intended to be a generic CRUD table. It has displays a table
 * with configurable columns, configurable actions, and sorting.
 *
 * @element ANY
 * @param {object} sbTableModel This is expected to be a model object that has
 *    the following interface:
 *      * `$items` is the array of items in the model. Each item must at least
 *        have a unique `.id`. The row data is also derived from an object at
 *        `.data`. Each key on data is what the table indexes into. Additionally,
 *        each item may defined a `.editable` and `.deletable` property to indicate
 *        if this item can have this action done to it. Here is an example item:
 *        ```
 *          {
 *            id: 'item1',onboarding_invite
 *            data: {
 *              // There is an available `TableCellData` and `TableItemData` Record
 *              // type for this in the table module root for convience.
 *              // Value is used as real sorting value and fmtValue is display:
 *              shares: { value: 10000, fmtValue: '10,000' },
 *              // Value will be used as a fallback display if fmtValue is not
 *              // present
 *              name: { value: 'Jason' },
 *            },
 *            deletable: false,
 *            editTitle: 'Edit Jason', // Title for edit modals
 *            editable: true,
 *          }
 *        ```
 *        Expected to be an immutable data structure.
 *      * `$loading` a persitance is outstanding (for operation disables).
 *      * `$edit(id, data)` a function that takes a row id and updates that row
 *        to be data, returns a promise.
 *      * `$add(data)` a function that takes data and adds the row representing
 *        that row, returns a promise.
 *      * `$remove(id)` a function that takes an id and removes that item, returns a promise.
 *      * `$addPromise()` a function that returns a promise that must be resolved
 *        before add modal can be opened.
 *      * `$editPromise(id)` a function that returns a promise that must be resolved
 *        before edit modal can be opened.
 * @param {array} sbTableColumns Array of objects for columns. A column item
 *    looks like:
 *      * `key` unique id of the column, will be used to index values from.
 *      * `name` Human readable heading string.
 * @param {expression} [sbTableExtraModalActions=undefined] This expression will
 *    be evaluated with `$rowId` (id of the item, undefined for add), `$close`,
 *    and `$dismiss`in the namespace. This expression may return an array of
 *    actions to add to the modal. **NOTE**: This expression is evaluated
 *    during digests make sure it memoizes results. Additionally, the sub properties
 *    are one time bound, so if you need to change them over time, be sure to change
 *    `id`, which is uses as the `track by`.
 *    @property {string} id Action ID.
 *    @property {string} text Text of the button.
 *    @property {function} method Method will be called with `ID` as the param.
 *    @property {string} [type='info'] Action button CSS type.
 * @param {template} sbTableItemTitle String for a single item title during
 *    addition.
 * @param {object} [sbTableForms=undefined] Forms defintion object for the add/edit
 *    modal. Only required if addable is true or any item is editable.
 * @param {string} [sbTableModalDescriptionText=undefined] Text above the form
 *    in the add/edit modal.
 * @param {string} [sbTableModalHtml=undefined] HTML string of the modal content.
 * @param {boolean} [sbTableAddable=false] If add operation is enabled.
 * @param {object} [sbTableDataSeed={}] Seed data for add and edit.
 * @param {expression} [extraModalActions=undefined] This expression will be
 *   evaluated on modal open with `$rowId` in the namespace. You must return
 *   a function compatible with `$formModal` extraActions param.
 *
 * @example
   <sb-table
     data-sb-table-columns="columns"
     data-sb-table-item-title="{{ ::itemTitle }}"
     data-sb-table-model="modelObject">
   </sb-table>
 */
export const sbTable = {
  restrict: 'E',
  template: require('./templates/table.html'),
  controllerAs: 'vm',
  bindings: {
    model: '=sbTableModel',
    columns: '=sbTableColumns',
    dataSeed: '=?sbTableDataSeed',
    addable: '&sbTableAddable',
    formDefinitions: '&sbTableForms',
    modalDescriptionText: '@?sbTableModalDescriptionText',
    modalHtml: '&?sbTableModalHtml',
    itemTitle: '@sbTableItemTitle',
    addTitle: '@?sbTableAddTitle',
    extraModalActions: '&?sbTableExtraModalActions',
    formUpdateURL: '<?sbTableFormUpdateUrl',
  },
  controller: [
    '$scope',
    '$q',
    '$formModal',
    '$sce',
    'PromiseErrorCatcher',
    function ($scope, $q, $formModal, $sce, PromiseErrorCatcher) {
      const ctrl = this;
      const DEFAULT_HTML = `
      <ng-form name="modalForm">
        <sb-form
          data-sb-form-name="sb_table_form"
          data-sb-form-fields="::model.$forms.fields"
          data-sb-form-model="model.$formData"
          data-sb-form-errors="model.$formErrors"
          data-sb-form-update-url="model.$forms.$formUpdateUrl"></sb-form>
      </ng-form>
    `;
      ctrl.$onInit = () => {
        this.dataSeed = this.dataSeed || {};
        ctrl.reverse = false;
        ctrl.sortKey = this.columns[0].key;
      };

      ctrl.setError = (error) => {
        ctrl.crudError = error;
      };

      ctrl.changeSort = (evt, newSortKey) => {
        if (newSortKey === ctrl.sortKey) {
          ctrl.reverse = !ctrl.reverse;
        } else {
          ctrl.reverse = false;
        }
        ctrl.sortKey = newSortKey;
        evt.preventDefault();
      };

      ctrl.formDefs = () => {
        const deferred = $q.defer();
        deferred.resolve(ctrl.formDefinitions());
        return deferred.promise;
      };

      ctrl.openModal = (title, formData, onConfirmPromise, beforePromise, id) => {
        const beforeFunc = ctrl.model[beforePromise] || ((_, o) => $q.resolve(o));
        const handleDocumentFields = (data, id) => {
          // if there's an id, we can just return as it should already have an id
          const fields = data.fields;
          if (id !== undefined || fields === undefined) {
            return data;
          }
          // Otherwise, prepend the field with a random Id
          const folderId = Math.floor(Math.random() * 1000000);
          fields.forEach((datum) => {
            if (datum.type === 'document-reference') {
              const resourceParts = datum.templateOptions.apiResource.split('/');
              const fieldParts = resourceParts[resourceParts.length - 1].split('.');
              const field = folderId + '.' + fieldParts[fieldParts.length - 1];
              resourceParts[resourceParts.length - 1] = field;
              datum.templateOptions.apiResource = resourceParts.join('/');
            }
          });
          return data;
        };
        return ctrl.formDefs().then((data) => {
          // Make a copy of form definitions, since form modal will
          // modify them in-place to append .formControl's.
          data = angular.copy(data);
          if (ctrl.formUpdateURL) {
            data.$formUpdateUrl = ctrl.formUpdateURL;
          }
          beforeFunc(id, {
            title,
            formData,
            onConfirmPromise,
            loading: () => ctrl.model.$loading,
            extraActions: (ctrl.extraModalActions || angular.noop)({ $rowId: id }),
            forms: handleDocumentFields(data, id),
            htmlContent: ctrl.modalHtml ? ctrl.modalHtml() : DEFAULT_HTML,
          }).then((opts) => $formModal(opts, 'lg').catch(PromiseErrorCatcher));
        });
      };

      ctrl.add = () => {
        ctrl.setError(null);
        ctrl
          .openModal(
            ctrl.addTitle || ctrl.itemTitle,
            angular.copy(ctrl.dataSeed),
            ({ $formData }) => ctrl.model.$add($formData),
            '$addPromise',
          )
          .catch(PromiseErrorCatcher);
      };

      ctrl.edit = (id) => {
        const dataSeedCopy = angular.copy(ctrl.dataSeed);
        const item = ctrl.model.$items.find((item) => item.get('id') === id);
        const rowData = item
          .get('data')
          .filter((val, key) => key.indexOf('$') !== 0)
          .toJS();
        ctrl.setError(null);
        ctrl
          .openModal(
            item.get('editTitle', 'Edit'),
            angular.extend(dataSeedCopy, rowData),
            ({ $formData }) => ctrl.model.$edit(id, $formData),
            '$editPromise',
            id,
          )
          .catch(PromiseErrorCatcher);
      };

      ctrl.remove = (id) => {
        ctrl.setError(null);
        ctrl.model.$remove(id).catch(ctrl.setError);
      };

      ctrl.sortFunc = (item) => {
        return item.get('data', Map()).get(ctrl.sortKey, Map()).get('value');
      };

      ctrl.trustHtml = (item, col) => {
        if (col.transclude) {
          return $sce.trustAsHtml(item.get('data').get(col.key).get('fmtValue'));
        }
        return item.get('data').get(col.key).get('fmtValue');
      };
    },
  ],
};
