import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[sbxDisabled]',
})
export class SbxDisabledDirective {
  @Input('sbxDisabled') active = true;

  @HostListener('click', ['$event'])
  suppressClick(event: any) {
    if (this.active) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
