import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
  Input,
} from '@angular/core';
import { SbxHttpClient } from '@/core/http';
import { Downgrade } from '@/shared/downgrade';
import { Asc718GrantsResult } from '@shoobx/types/webapi-v2';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import { of } from 'rxjs';

@Downgrade.Component('ngShoobx', 'sbx-asc718-grants')
@Component({
  selector: 'sbx-asc718-grants',
  templateUrl: './sbx-asc718-grants.component.html',
  styleUrls: ['./sbx-asc718-grants.component.scss'],
})
export class SbxAsc718GrantsComponent implements OnInit {
  @Input() hideIntroText: boolean;

  @ViewChild('percentTemplate', { static: true }) percentTemplate: TemplateRef<any>;
  @ViewChild('docLinkTemplate', { static: true }) docLinkTemplate: TemplateRef<any>;
  @ViewChild('periodTemplate', { static: true }) periodTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('currencyTemplate', { static: true }) currencyTemplate: TemplateRef<any>;
  @ViewChild('numberTemplate', { static: true }) numberTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('expensesTemplate', { static: true }) expensesTemplate: TemplateRef<any>;
  @ViewChild('customizationsTemplate', { static: true })
  customizationsTemplate: TemplateRef<any>;
  @ViewChild('grantTemplate', { static: true }) grantTemplate: TemplateRef<any>;
  @ViewChild('pageNumInput') pageNumInput;

  grantData = [];
  columns = [];
  pageNum = 0;
  totalItems = 0;
  pageSize = 20;
  nameFilter = '';
  formDescription;
  isLoading = false;

  math = Math;

  constructor(
    @Inject(SbxFormModalService)
    public sbxFormModalService: SbxFormModalService,
    private http: SbxHttpClient,
  ) {
    this.setNameFilter = this.setNameFilter.bind(this);
  }

  ngOnInit() {
    this.columns = [
      {
        key: 'grantee',
        title: 'Grantee',
      },
      {
        key: 'grant_id',
        title: 'Grant ID',
        width: '200px',
        template: this.grantTemplate,
      },
      {
        key: 'date',
        title: 'Grant Date',
        template: this.dateTemplate,
        width: '100px',
      },
      {
        key: 'shares',
        title: 'Grant Shares',
        template: this.numberTemplate,
      },
      {
        key: 'totalExpenses',
        title: 'Past Expense',
        template: this.currencyTemplate,
      },
      {
        key: '',
        title: 'Customizations',
        template: this.customizationsTemplate,
      },
      {
        key: '',
        title: '',
        template: this.actionsTemplate,
      },
    ];

    this.getData();
  }

  getData() {
    this.isLoading = true;
    return this.http
      .entity('2')
      .get('equitySettings/asc718/grants', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          nameFilter: this.nameFilter,
        },
      })
      .toPromise()
      .then((res: Asc718GrantsResult) => {
        this.isLoading = false;

        this.grantData = res.grants;
        this.grantData.forEach((grantDatum) => {
          grantDatum.totalExpenses = this.getTotalExpense(grantDatum);
        });
        this.totalItems = res.totalItems;
        this.formDescription = res.formDescription;
      });
  }

  getPage(page) {
    this.pageNum = page;
    this.getData();
  }

  setNameFilter(name) {
    this.nameFilter = name;
    this.pageNum = 0;
    this.getData();
    return of([]);
  }

  getTotalExpense(row) {
    return (row.expenses || []).reduce((a, x) => a + x.expense_amount, 0);
  }

  editRow(row) {
    this.sbxFormModalService
      .open({
        data: {
          saveUrl: 'form/asc718_grant_record',
          title: `${row.grantee} ${row.date} (${row.shares} shares)`,
          model: { ...row },
          formFields: this.formDescription.fields,
        },
      })
      .result.then(({ result }) => {
        result.totalExpenses = this.getTotalExpense(result);
        Object.assign(row, result);
      })
      .catch(() => null);
  }
}
