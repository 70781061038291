import angular from 'angular';

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbRadioForm
 *
 * @description
 * This component is breaking a form up by "radio headings." It fills the plato form use
 * case.
 *
 * @param {array<field>} allFields Collection of all fields in the form including
 *   at least `headingFieldKey` and `subFieldKeys` (if used).
 * @param {template} formName Form name prefix of the form associated with `allFields`.
 *   One *cannot* use interpolation here, just like `sbForm`.
 * @param {template} headingFieldKey Heading option field name.
 * @param {number} headingFieldOptionIndex Heading option field vocab index.
 * @param {array<string>} [subFieldKeys=undefined] Array of keys from `allFields` that
 *   will be the "subform" under the option. One may also use transclusion to manually
 *   specify the subform's content (for instance, another nested sbRadioForm).
 * @param {array<error>} [formErrors=undefined] sbForm errors object
 * @param {expression} ngModel Model expression.
 *
 * @example
   <sb-radio-form
     all-fields="::model.$forms.myForm.fields"
     form-name="myForm"
     heading-field-key="optionFieldKey"
     heading-field-option-index="::0"
     sub-field-keys="::['firstSubfield', 'secondSubfield']"
     ng-model="model.$formData.myForm">
   </sb-radio-form>

   <sb-radio-form
     all-fields="::model.$forms.myForm.fields"
     form-name="myForm"
     heading-field-key="optionFieldKey"
     heading-field-option-index="::1"
     ng-model="model.$formData.myForm">

     <!-- This option manually specifices the sub content instead of subFieldKeys. -->
     <sb-stakeholder-chooser ...></sb-stakeholder-chooser>

   </sb-radio-form>
*/
export const sbRadioForm = {
  template: [
    '$attrs',
    function ($attrs) {
      const $template = angular.element(require('./templates/radio-form.html'));
      const name = $attrs.formName; // Is not interpolated.
      $template.find('sb-form').attr('sb-form-name', name);
      return $template;
    },
  ],
  controllerAs: 'vm',
  transclude: true,
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    allFields: '<',
    formName: '@',
    headingFieldKey: '@',
    headingFieldOptionIndex: '<',
    subFieldKeys: '<?',
    formErrors: '<?',
    model: '=ngModel',
  },
  controller: [
    function () {
      this.$onInit = () => {
        const { headingFieldKey, subFieldKeys } = this;
        const headingField = this.allFields.find(
          (field) => field.key === headingFieldKey,
        );
        const { templateOptions } = headingField;
        this.enum = templateOptions.enumVocab[this.headingFieldOptionIndex];
        if (subFieldKeys) {
          const lookup = subFieldKeys.reduce(
            (accum, key) => Object.assign(accum, { [key]: true }),
            {},
          );
          this.subFields = this.allFields.filter((field) => lookup[field.key]);
        }
      };
    },
  ],
}; // end sbRadioForm
