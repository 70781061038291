import {
  Directive,
  ElementRef,
  Injector,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export interface UploadDetails {
  uploadId: string;
  file: any;
}

@Directive({
  selector: 'sbx-pdf-upload',
})
export class SbxPdfUploadComponent extends UpgradeComponent {
  @Input() apiResource?: any;
  @Input() backendLocation?: string;
  @Input() deletePreviousUpload = false;
  @Input() enablePreview = true;

  @Input()
  get file() {
    return this.fileModel;
  }
  set file(val) {
    this.fileModel = val;
    this.fileChange.emit(this.fileModel);
  }
  @Output() fileChange = new EventEmitter();

  // Uploaded file storage folder ID
  @Input()
  get uploadId(): UploadDetails {
    return this.uploadIdModel;
  }
  set uploadId(val: UploadDetails) {
    this.uploadIdModel = val;
    this.uploadIdChange.emit(this.uploadIdModel);
  }
  @Output() uploadIdChange = new EventEmitter<UploadDetails>();

  fileModel = {
    ready: true,
    pdfURL: '',
    name: '',
  };
  uploadIdModel: UploadDetails;
  constructor(elementRef: ElementRef, injector: Injector) {
    super(
      'sbPdfUpload',
      elementRef,
      Injector.create({
        providers: [
          {
            provide: '$scope',
            useValue: injector.get('$rootScope').$new(),
          },
          {
            provide: '$injector',
            useValue: injector.get('$injector'),
          },
        ],
      }),
    );
  }
}
