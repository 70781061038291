import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

/**
 *  Shoobx Properties list for AngularJS pages
 *
 *  This is an encapsulation of SbxPropertiesListComponent so it can be used in
 *  older angular pages with `ng-template` to customize values in the list.
 *  Value templates should be defined here, added to valueTemplates and set to
 *  item.template accordingly.
 *
 *
 *  @ngdoc component
 *  @name sbx-properties-list-templated/component:SbxPropertiesListComponentTemplated
 *  @description
 *  component for displaying list of properties in desired format
 *
 *
 *  @param items - list of key-value pairs
 *  @param layout - vertical or horizontal listing
 *  @param itemStyle - item style
 *  @param listTitle - string text for list title
 *  @param isSubList - boolean to specify the style of the title - main or sub
 *
 *  Usage:
 *    @Component({
 *      template: `
 *       <sbx-properties-list-templated
 *         tal:attributes="[items] python:summary_data['corporate_officers'];
 *                         list-title string:Corporate Officers;
 *                         item-style string:normal;
 *                         is-sub-list python:True;"
 *       >
 *       </sbx-properties-list-templated>
 *      `,
 *    })
 */

const SELECTOR = 'sbx-properties-list-templated';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-properties-list-templated.component.html',
  styleUrls: ['sbx-properties-list-templated.component.scss'],
})
export class SbxPropertiesListTemplatedComponent {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @ViewChild('contact', { static: true }) contact: TemplateRef<any>;
  @ViewChild('alignRight', { static: true }) alignRight: TemplateRef<any>;
  @ViewChild('alignRightFlagged', { static: true }) alignRightFlagged: TemplateRef<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  @Input() items = [];
  @Input() layout: 'horizontal' | 'vertical' = 'vertical';
  @Input() itemStyle: 'bold' | 'lite' | 'lite-stacked' | 'normal' = 'lite';
  @Input() listTitle: string = null;
  @Input() isSubList = false;

  valueTemplates;

  ngOnChanges() {
    this.valueTemplates = {
      contact: this.contact,
      alignRight: this.alignRight,
      alignRightFlagged: this.alignRightFlagged,
    };
    this.items.forEach((item) => {
      if (item.template !== null && typeof item.template === 'string') {
        item.template = this.valueTemplates[item.template];
      }
    });
  }
}
