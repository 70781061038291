/**
 * @ngdoc directive
 * @name sb.workitem.dataItems.directive:sbUploadEditDocuments
 * @restrict E
 *
 * @description
 * This directive is intended for the data items workitem. It will create and
 * use a `DataItemsModel` for persistance.
 *
 * @element ANY
 * @param {template} sbUploadEditDocumentsTitle String for a single item title; its
 *    passed along to a table.
 * @param {object} [sbUploadEditDocumentsForms=undefined] Form defintion object
 *    that's passed along to an `sbTable`.
 * @param {string} [sbUploadEditDocumentsModalHtml=undefined] HTML for the modal.
 *    This html will be compiled with `model` similar to a show page.
 */
export function sbUploadEditDocuments() {
  const COLUMNS = [
    { key: '$title', name: 'Title' },
    { key: '$effectiveDate', name: 'Effective Date' },
  ];
  return {
    restrict: 'E',
    template: require('./templates/data-items.html'),
    scope: {
      itemTitle: '@sbUploadEditDocumentsTitle',
      formDefintions: '&sbUploadEditDocumentsForms',
      modalHtml: '&sbUploadEditDocumentsModalHtml',
    },
    controller: [
      '$scope',
      'DataItemsModel',
      'ProcessStatus',
      'ProcessButtonModel',
      function ($scope, DataItemsModel, ProcessStatus, ProcessButtonModel) {
        $scope.addable = () => true;
        $scope.model = DataItemsModel();
        $scope.columns = COLUMNS;

        $scope.$watch('model.$loading', ProcessButtonModel.$disableWatch());

        $scope.model.$init().catch((error) => {
          ProcessStatus.$setStatus(error, 'danger');
        });
      },
    ],
  };
} // end sbUploadEditDocuments
