import angular from 'angular';
import Bootstrap from '../lib/bootstrap/bootstrap';
import Promise from '../lib/promise';
import { PubSub } from '../lib/pubsub/pubsub';
import { sbPaylocityStageEmployees } from './paylocity';
import { sbPaylocityConflicts } from './conflicts';
import { sbPaylocityLog } from './log';

export default angular
  .module('sb.payroll', [Bootstrap, Promise])
  .component('sbPaylocityStageEmployees', sbPaylocityStageEmployees)
  .component('sbPaylocityConflicts', sbPaylocityConflicts)
  .component('sbPaylocityLog', sbPaylocityLog)
  .service('EmployeeChanges', PubSub).name;
