<sbx-container *ngIf="data">
  <sbx-row>
    <sbx-col *ngIf="data.reviewers.approvers && data.reviewers.approvers.length > 0">
      <div class="fa-stack">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-thumbs-up fa-stack-1x"></i>
      </div>
      <div class="approvers-label">Approvers</div>
      <span *ngIf="data.reviewers.approvers.length < 3">
        <span *ngFor="let reviewer of data.reviewers.approvers">
          <sbx-stakeholder-card
            format="short"
            [stakeholderObject]="reviewer"
          ></sbx-stakeholder-card>
        </span>
      </span>
      <span *ngIf="data.reviewers.approvers.length >= 3">
        {{ data.reviewers.approvers.length }} Approvers
      </span>
      <ng-template #approvers>
        <span *ngIf="data.reviewers.approvers.length >= 3">
          Documents will be sent to:
          <span *ngFor="let reviewer of data.reviewers.approvers">
            <sbx-stakeholder-card
              format="medium"
              [stakeholderObject]="reviewer"
            ></sbx-stakeholder-card>
          </span>
        </span>
        <p *ngIf="data.reviewers.approvers.length > 1">
          This workflow
          <span *ngIf="data.reviewers.unanimous"
            >requires approval from each approver</span
          >
          <span *ngIf="!data.reviewers.unanimous">will need one approval</span>
          to continue.
        </p>
        <em>
          Note: Approvers can be configured in the Workflow Administration section of
          the Administration area of Fidelity Private Shares.
        </em>
      </ng-template>
      <sbx-popover [sbxPopoverContent]="approvers"
        >&nbsp;
        <sbx-icon type="infoCircle"></sbx-icon>
      </sbx-popover>
    </sbx-col>
    <sbx-col
      *ngIf="
        data.counsel_irrelevant == false && data.reviewers.legal.reviewers.length > 0
      "
    >
      <div class="fa-stack">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-balance-scale fa-stack-1x"></i>
      </div>
      <span
        *ngIf="
          data.approval_required && !hasLegalForm;
          then required;
          else not_required
        "
      ></span>
      <ng-template #not_required>
        <ng-template #legal_reviewers>
          Documents will be sent to:
          <span *ngFor="let reviewer of data.reviewers.legal.reviewers">
            <sbx-stakeholder-card
              format="medium"
              [stakeholderObject]="reviewer"
            ></sbx-stakeholder-card>
          </span>
        </ng-template>
        <div><strong>Send for Legal Review?</strong></div>
        <sbx-popover class="sbx-icon-popover" [sbxPopoverContent]="legal_reviewers">
          <sbx-icon type="help"></sbx-icon>
        </sbx-popover>

        <sbx-form
          [form]="legalReviewForm.form"
          [formFields]="legalReviewForm.fields"
          [model]="legalReviewForm.model"
        ></sbx-form>

        <span *ngIf="data.should_alert_counsel">
          <ng-template #notified>
            <div style="width: 350px">
              <p>
                <i class="fa fa-envelope-open-text fa-4x leftside-image"></i>
                Once complete, a summary of this workflow will be sent to the following
                members of your legal counsel:
              </p>

              <span *ngFor="let reviewer of data.reviewers.legal.reviewers">
                <sbx-stakeholder-card
                  format="medium"
                  [stakeholderObject]="reviewer"
                ></sbx-stakeholder-card>
              </span>
            </div>
          </ng-template>
          <sbx-popover
            class="sbx-icon-popover"
            [sbxPopoverContent]="notified"
            [sbxPopoverAutoWidth]="true"
          >
            <i class="fa fa-envelope"></i>
          </sbx-popover>
        </span>
      </ng-template>
      <ng-template #required>
        <div class="approvers-label">Legal<br />Review</div>
        <span *ngIf="data.reviewers.legal.reviewers.length < 2">
          <span *ngFor="let reviewer of data.reviewers.legal.reviewers">
            <sbx-stakeholder-card
              format="short"
              [stakeholderObject]="reviewer"
            ></sbx-stakeholder-card>
          </span>
        </span>
        <span *ngIf="data.reviewers.legal.reviewers.length >= 2">
          <div>
            <strong>{{ data.reviewers.legal.entityName }}</strong>
            |
            {{ data.reviewers.legal.reviewers.length }} Reviewers
          </div>
          <ng-template #legalReviewers>
            <span *ngFor="let reviewer of data.reviewers.legal.reviewers">
              <sbx-stakeholder-card
                format="medium"
                [stakeholderObject]="reviewer"
              ></sbx-stakeholder-card>
            </span>
          </ng-template>
          <sbx-popover [sbxPopoverContent]="legalReviewers"
            >&nbsp;
            <sbx-icon type="infoCircle"></sbx-icon>
          </sbx-popover>
        </span>
      </ng-template>
    </sbx-col>
  </sbx-row>

  <ng-container *ngFor="let formName of formNameList">
    <label
      class="sbx-checkbox-container"
      *ngIf="isFormAvailable(formName) && formName !== legalForm"
    >
      <input
        type="checkbox"
        [(ngModel)]="sbmodel.$formData[formName][formNameFieldMap[formName]]"
      />
      <span class="sbx-checkbox-box">
        <sbx-icon class="sbx-checkbox-icon" type="check"></sbx-icon>
      </span>
      <span class="sbx-checkbox-text">{{ getLabel(formName) }}&nbsp;</span>
      <sbx-popover class="sbx-icon-popover" [sbxPopoverContent]="getHelp(formName)">
        <sbx-icon type="help"></sbx-icon>
      </sbx-popover>
    </label>
  </ng-container>
</sbx-container>
