<div *ngIf="model.type" class="sbx-filled-out-document">
  <sbx-icon *ngIf="model.type === 'upload'" type="upload"></sbx-icon>
  <sbx-icon *ngIf="model.type === 'pickFrom'" type="dataRoom"></sbx-icon>
  <sbx-icon *ngIf="model.type === 'defer'" type="time"></sbx-icon>
  <sbx-icon *ngIf="model.type === 'waive'" type="ban"></sbx-icon>
  <strong *ngIf="model.type !== modelTypes.Waive">
    {{ model.documentTitle }}
  </strong>
  <strong *ngIf="model.type === modelTypes.Waive">Waive Document</strong>
  <sbx-icon type="delete" (click)="clearModel()"></sbx-icon>
</div>

<sbx-dropdown
  *ngIf="!model.type"
  [dropDownTemplate]="actionsTemplate"
  [dropDownWidth]="'auto'"
>
  <div class="btn btn-int">
    <i class="far fa-file-alt"></i>Document Options
    <span class="caret"></span>
  </div>
</sbx-dropdown>

<ng-content></ng-content>
<ng-template #actionsTemplate>
  <div class="sbx-dropdown-content">
    <sbx-document-pick-from
      class="sbx-dropdown-content-row"
      *ngIf="config.allowPickFrom"
      [(model)]="model"
      [config]="config"
      [pickFromModalTitle]="pickFromModalTitle"
      [excludeFn]="excludeFn"
    >
    </sbx-document-pick-from>

    <sbx-document-upload
      class="sbx-dropdown-content-row"
      *ngIf="config.allowUpload"
      [(model)]="model"
      [apiResource]="apiResource"
      [defaultTitle]="config.defaultTitle"
      [collectTitleInUpload]="config.collectTitleInUpload"
    >
    </sbx-document-upload>

    <sbx-document-defer
      class="sbx-dropdown-content-row"
      *ngIf="config.allowDefer"
      [(model)]="model"
      [defaultTitle]="config.defaultTitle"
      [shouldCollectTitle]="true"
    >
    </sbx-document-defer>

    <sbx-document-waive
      class="sbx-dropdown-content-row"
      *ngIf="config.allowWaive"
      [(model)]="model"
    >
    </sbx-document-waive>
  </div>
</ng-template>
