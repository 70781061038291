import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxIconComponent } from './sbx-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxIconComponent],
  exports: [SbxIconComponent],
})
export class SbxIconModule {
  static entryComponents = [SbxIconComponent];

  static forRoot(): ModuleWithProviders<SbxIconModule> {
    return {
      ngModule: SbxIconModule,
    };
  }
}
