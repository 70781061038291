const GRANT_HIERARCHY = {
  view: 0,
  modify: 1,
  share: 2,
};

/**
 * @ngdoc component
 * @name sb.lib.sharing.component:sbSharingGrant
 *
 * @description
 * This displays a sharing grant
 *
 *   @property {list of objects} options, [{label: xxx, value: yyy}...]
 *   @property {string} shareableId
 *   @property {expression} remove
 *   @grants {list} grants
 */
export const sbSharingGrant = {
  controllerAs: 'vm',
  template: require('./templates/sharing-grant.html'),
  bindings: {
    options: '<',
    shareableId: '<',
    remove: '&',
    grants: '<',
  },
  controller: [
    '$scope',
    function ($scope) {
      function removeItem(item) {
        this.remove({ $item: item });
      }
      function computeHighestGrant() {
        this.highestGrant = this.grants.reduce((max, cur) => {
          return max && GRANT_HIERARCHY[max.level] > GRANT_HIERARCHY[cur.level]
            ? max.level
            : cur.level;
        }, null);
        this.highestGrantOption = this.options.filter(
          (option) => option.value === this.highestGrant,
        )[0];
      }

      this.$onInit = () => {
        this.removeItem = removeItem.bind(this);
        this.computeHighestGrant = computeHighestGrant.bind(this);
        this.highestGrant = null;
        $scope.$watchCollection('vm.grants', () => {
          this.computeHighestGrant();
        });
      };
    },
  ],
};

/**
 * @ngdoc component
 * @name sb.lib.sharing.component:sbSharingBtn
 *
 * @description
 * This displays a button to open the sharing interface.
 *
 *   @property {string} shareableLocation url location of the resource
 *   @property {string} shareable the shareable json object
 *   @property {template} shareableType 'document' or 'folder'
 *   @property {template} buttonStyle 'icon' (default) or 'button'
 */
export const sbSharingBtn = {
  controllerAs: 'vm',
  template: require('./templates/sharing-btn.html'),
  bindings: {
    location: '<shareableLocation',
    shareable: '<shareable',
    shareableType: '@shareableType',
    style: '@buttonStyle',
  },
  controller: [
    '$scope',
    '$sbModal',
    'SharingService',
    'PromiseErrorCatcher',
    function ($scope, $sbModal, SharingService, PromiseErrorCatcher) {
      function openModal() {
        $sbModal
          .open({
            size: 'lg',
            keyboard: false,
            backdrop: 'static',
            template: require('./templates/sharing-modal.html'),
            controllerAs: 'vm',
            bindToController: true,
            resolve: {
              type: () => this.shareableType,
              shareable: () => this.shareable,
              location: () => this.location,
              grants: () => SharingService.getGrants(this.location),
              form: () => SharingService.getForm(this.location),
            },
            controller: SharingModalCtrl,
          })
          .result.catch(PromiseErrorCatcher);
      }
      this.$onInit = () => {
        this.openModal = openModal.bind(this);
      };
    },
  ],
};

export const SharingModalCtrl = [
  'grants',
  'form',
  'type',
  'shareable',
  'location',
  'SharingService',
  function (grants, form, type, shareable, location, SharingService) {
    this.notifyPeople = false;
    this.grants = grants || {};
    this.form = form.form;
    this.type = type;
    this.groupField = form.groups;
    this.stakeholderField = form.stakeholders;
    this.show = false;
    this.shareable = shareable;
    this.location = location;
    this.model = { newItems: [] };
    this.stakeholders = this.grants.stakeholderGrants || {};
    this.groups = this.grants.groupGrants || {};
    this.stakeholderIds = Object.getOwnPropertyNames(this.stakeholders);
    this.groupIds = Object.getOwnPropertyNames(this.groups);
    this.principalsWithGrants = this.groupIds
      .filter((key) => {
        return this.groups[key].filter((item) => item.context.id === this.shareableId)
          .length;
      })
      .concat(
        this.stakeholderIds.filter((key) => {
          return this.stakeholders[key].filter(
            (item) => item.context.id === this.shareable.id,
          ).length;
        }),
      );

    function remove(grant) {
      if (grant.context.id !== this.shareable.id) {
        return;
      }
      const grantId = grant.stakeholderId || grant.groupId;
      let typeGrants, keys;
      if (grant.stakeholderId) {
        typeGrants = this.stakeholders;
        keys = this.stakeholderIds;
      } else {
        typeGrants = this.groups;
        keys = this.groupIds;
      }

      this.principalsWithGrants = this.principalsWithGrants.filter(
        (item) => item !== grantId,
      );

      typeGrants[grantId] = typeGrants[grantId].filter((item) => {
        const itemId = item.stakeholderId || item.groupId;
        return item.context.id !== grant.context.id || itemId !== grantId;
      });

      if (typeGrants[grantId].length === 0) {
        delete typeGrants[grantId];
        keys.splice(keys.indexOf(grantId), 1);
      }
    }

    function saveGrants() {
      let grGrants = [];
      let shGrants = [];

      this.groupIds.forEach((id) => {
        grGrants = [].concat.apply(
          grGrants,
          this.groups[id].filter((item) => item.context.id === this.shareable.id),
        );
      });

      grGrants = grGrants.map((item) => {
        return {
          group: item.groupId,
          level: item.level,
          expires: item.expires,
        };
      });

      this.stakeholderIds.forEach((id) => {
        shGrants = [].concat.apply(
          shGrants,
          this.stakeholders[id].filter((item) => item.context.id === this.shareable.id),
        );
      });

      shGrants = shGrants.map((item) => {
        return {
          stakeholder: {
            sh: { id: item.stakeholderId },
          },
          level: item.level,
          expires: item.expires,
        };
      });
      let items = [].concat.apply(shGrants, grGrants);

      const newItems = this.model.newItems.map((item) => {
        const { expires, level } = item;
        return item.type
          ? { stakeholder: { sh: { id: item.id } }, level, expires }
          : { group: item.value, level, expires };
      });
      const newItemIds = newItems.map((item) => item.group || item.stakeholder.sh.id);
      items = items.filter((grant) => {
        if (grant.stakeholder) {
          return newItemIds.indexOf(grant.stakeholder.sh.id) === -1;
        }
        return newItemIds.indexOf(grant.group) === -1;
      });

      SharingService.saveGrants(
        this.location,
        [...items, ...newItems],
        undefined,
        this.notifyPeople,
        newItems,
      ).then(() => this.$dismiss());
    }
    this.remove = remove.bind(this);
    this.saveGrants = saveGrants.bind(this);
  },
];

/**
 * @ngdoc component
 * @name sb.lib.sharing.component:sbSharingList
 *
 * @description
 * This displays a list of principals that are being fed by the principal chooser
 *
 *   @property {list of enums} levelOptions
 *   @property {list of enums} groupOptions list of options
 *   @property {object} stakeholderOptions stakeholder chooser options
 *   @property {list} ignoreKeys stakeholder chooser options
 *   @property {expression} optionsIcon the icon to be used for groupOptions.
 */
export const sbSharingList = {
  controllerAs: 'vm',
  template: require('./templates/sharing-list.html'),
  bindings: {
    model: '=ngModel',
    shareable: '<',
    levelOptions: '<?',
    groupOptions: '<?',
    stakeholderOptions: '<?',
    ignoreKeys: '=?',
    optionsIcon: '@?',
  },
  controller: [
    function () {
      function change(newItem) {
        if (newItem) {
          this.newItem = newItem;
        }
        this.model = this.model.filter((item) => item.id !== this.newItem.id);
        this.model.push(this.newItem);
        this.ignoreKeys.push(this.newItem.id);
        this.newItem = null;
      }

      function remove(value) {
        this.ignoreKeys = this.ignoreKeys.filter((key) => key !== value.id);
        this.model = this.model.filter((item) => item.id !== value.id);
      }

      this.$onInit = () => {
        this.ignoreKeys = this.ignoreKeys || [];
        this.model = this.model || [];
        if (this.optionsIcon) {
          this.groupOptions = this.groupOptions.map((item) => {
            item.icon = this.optionsIcon;
            return item;
          });
        }
        this.newItem = null;
        this.remove = remove.bind(this);
        this.change = change.bind(this);
      };
    },
  ],
};

/**
 * @ngdoc objects
 * @name sb.lib.stakeholder:SharingService
 */
export const SharingService = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    return {
      /**
       * @ngdoc method
       * @name saveGrants
       * @methodOf sb.lib.stakeholder.SharingService
       *
       * @description
       * Finds sharable form
       */
      getForm(location) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${location}/form`,
          },
          'Failed to get forms',
        );
      },

      /**
       * @ngdoc method
       * @name getGrants
       * @methodOf sb.lib.stakeholder.SharingService
       *
       * @description
       * Finds all sharing Grants.
       *
       * @param {string} sharable location
       *
       * @returns {promise} Promise that resolves with the list of grants
       */
      getGrants(location) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: `${location}/sharing`,
          },
          'Failed to get sharing info',
        );
      },

      /**
       * @ngdoc method
       * @name saveGrants
       * @methodOf sb.lib.stakeholder.SharingService
       *
       * @description
       * Saves the sharing grants for the given shareable
       *
       * @param {string} sharable location
       * @param {list of objects} items
       * @param {string} stakeholderId optional
       *
       * @returns {promise} Promise that resolves with a list of grants
       */
      saveBulkGrants(grants, stakeholderId) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: BackendLocation.entity(1) + 'folders/shareable_folders',
            data: { grants: grants, stakeholderId: stakeholderId },
          },
          'Failed to save Note',
        );
      },

      /**
       * @ngdoc method
       * @name saveAllGrants
       * @methodOf sb.lib.stakeholder.SharingService
       *
       * @description
       * Saves the sharing grants for the given shareable
       *
       * @param {list of objects} items
       * @param {string} stakeholderId optional
       * @param {boolean} notifyPeople
       * @param {list of objects} newItems
       *
       * @returns {promise} Promise that resolves with a list of grants
       */
      saveGrants(location, items, stakeholderId, notifyPeople, newItems) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${location}/sharing`,
            data: {
              grants: items,
              stakeholderId: stakeholderId,
              notifyPeople: notifyPeople,
              newGrants: newItems,
            },
          },
          'Failed to save Note',
        );
      },

      /**
       * @ngdoc method
       * @name getVisiblePermissionOptions
       * @methodOf sb.lib.stakeholder.SharingService
       *
       * @description
       * Gets Permission options for a stakeholder
       *
       * @param {string} stakeholderId
       *
       * @returns {promise} Promise that resolves with an object containing
       *   current granted permissions, folders, and sharingForm
       */
      getVisiblePermissionOptions(stakeholderId) {
        return SimpleHTTPWrapper(
          {
            method: 'GET',
            url: BackendLocation.entity(1) + 'folders/shareable_folders',
            params: { stakeholderId: stakeholderId },
          },
          'Failed to get sharing info',
        );
      },
    };
  },
];
