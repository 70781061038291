<sbx-modal-header> Link Exhibit </sbx-modal-header>
<sbx-modal-content>
  <div>
    <strong *ngIf="this.listing.length"
      >Select the exhibit document for this reference.</strong
    >
    <sbx-table [columns]="this.tableColumns" [data]="this.listing"></sbx-table>
  </div>
  <sbx-button
    id="sbx-attach-exhibit"
    theme="default"
    icon="plus"
    title="Attach Exhibit"
    (click)="attachExhibit()"
  ></sbx-button>
</sbx-modal-content>

<ng-template #setDownload let-item let-row="row">
  <sbx-button
    theme="default"
    icon="share"
    title="{{ row.target ? ' Select' : ' Insert' }}"
    (click)="assignExhibit(row)"
  ></sbx-button>
</ng-template>
