/**
 * @ngdoc component
 * @name sb.workitem.fillAcroForm.component:sbFillAcroForm
 *
 * @description
 * This compomnent displays a PDF and interfaces filling and submitting
 * AcroForm field data with process events.
 *
 * @param {string} pdfUrl of the downloadable resource.
 * @param {string} dataUrl to which the data is sent.
 * @param {object} formData to prepopulate with.
 *
 */
export const sbFillAcroForm = {
  controllerAs: 'vm',
  template: require('./templates/fill-acroform.html'),
  bindings: {
    pdfurl: '@',
    dataurl: '@',
    formData: '<?',
  },
  controller: [
    '$q',
    'ProcessButtonModel',
    'SimpleHTTPWrapper',
    function ($q, ProcessButtonModel, SimpleHTTPWrapper) {
      function $onInit() {
        this.formData = Object.entries(this.formData).reduce((acc, [key, value]) => {
          key = key.replaceAll('_', '');

          if (value === undefined || value === null) {
            return acc;
          }

          // Special treatment for checkboxes group field. Backend sends partial key and
          // value as array of ids, but SbxPdfViewerComponent expects full key and
          // boolean value.
          if (Array.isArray(value)) {
            value.forEach((id) => {
              acc[`${key}.${id}`] = true;

              return acc;
            });
          }

          acc[key] = value;

          return acc;
        }, {});
        this.messages = {};
      }

      function handleValueChange(field) {
        this.formData[field.key] = field.value;
      }

      function onContinue() {
        const data = Object.entries(this.formData).reduce((acc, [fieldName, value]) => {
          const item = {
            fieldName,
            value,
          };

          return [item, ...acc];
        }, []);

        return SimpleHTTPWrapper({
          url: this.dataurl,
          method: 'PUT',
          data,
        }).then((errors) => {
          this.messages = Object.entries(errors).reduce((acc, [k, v]) => {
            acc[k.replaceAll('_', '')] = v;
            return acc;
          }, {});

          if (Object.keys(this.messages).length === 0) {
            return $q.resolve();
          }

          return $q.reject('Please fix the invalid fields');
        });
      }

      this.$onInit = $onInit.bind(this);
      this.handleValueChange = handleValueChange.bind(this);
      this.onContinue = onContinue.bind(this);

      ProcessButtonModel.$addSubmitCondition('continue', this.onContinue);
    },
  ],
}; // end sbConstraintToken
