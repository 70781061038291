import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

type ISbxProcessStatusType = 'success' | 'info' | 'warning' | 'danger';

@Downgrade.Component('ngShoobx', 'sbx-process-status')
@Component({
  selector: 'sbx-process-status',
  templateUrl: './sbx-process-status.component.html',
  styleUrls: ['./sbx-process-status.component.scss'],
})
export class SbxProcessStatusComponent {
  @Input({ required: true }) type: ISbxProcessStatusType;
  @Input({ required: true }) content: string;
  @Input() confirmButtonText: string = '';
  @Input() cancelButtonText: string = '';
  @Input() showCloseButton: boolean = false;
  @Input() showActionButtons: boolean = false;
  @Output() closeClick = new EventEmitter<void>();
  @Output() confirmClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  @HostBinding('class') get class() {
    return `sbx-${this.type}`;
  }
}
