<sbx-container>
  <sbx-page-header [title]="header"></sbx-page-header>

  <sbx-page #pageRef>
    <sbx-row>
      <sbx-col [lg]="12">
        <sbx-form
          #formRef
          *ngIf="state"
          [model]="state.model"
          [form]="form"
          [formFields]="state.description.fields"
          [serverErrors]="formErrors"
          class="sbx-form"
        ></sbx-form>

        <div class="sbx-button-container">
          <sbx-button
            theme="primary"
            title="Save"
            [disabled]="pageRef.isLoading()"
            (click)="handleSave()"
          ></sbx-button>
        </div>
      </sbx-col>
    </sbx-row>
  </sbx-page>
</sbx-container>
