export const SPAN_STYLES = [
  {
    type: 'span.Link',
    label: 'Link',
    class: 'Link',
  },
  {
    type: 'span.TermDef',
    label: 'Term Definition',
    class: 'TermDef',
  },
  {
    type: 'span.TermDefAlt',
    label: 'Alternative Term Definition',
    class: 'TermDefAlt',
  },
  {
    type: 'span.ExhibitRef',
    label: 'Exhibit Reference',
    class: 'ExhibitRef',
  },
  {
    type: 'span.SectionRef',
    label: 'Section Reference',
    class: 'SectionRef',
  },
  {
    type: 'span.InlineSectionTitle0',
    label: 'Section Title - Level 1',
    class: 'InlineSectionTitle0',
  },
  {
    type: 'span.InlineSectionTitle1',
    label: 'Section Title - Level 2',
    class: 'InlineSectionTitle1',
  },
  {
    type: 'span.InlineSectionTitle2',
    label: 'Section Title - Level 3',
    class: 'InlineSectionTitle2',
  },
  {
    type: 'span.InlineSectionTitle3',
    label: 'Section Title - Level 4',
    class: 'InlineSectionTitle3',
  },
  {
    type: 'span.InlineSectionTitle4',
    label: 'Section Title - Level 5',
    class: 'InlineSectionTitle4',
  },
];
