import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxRelationshipSetupComponent } from './relationships/sbx-relationship-setup/sbx-relationship-setup.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import {
  $similarEntitiesModal,
  similarEntitiesModalHelper,
} from '../../js/lib/stakeholder/modal';
import { Stakeholders } from '../../js/lib/stakeholder/service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxRelationshipSetupComponent],
  providers: [
    {
      provide: $similarEntitiesModal,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useFactory: ($injector: any) => $injector.get('$similarEntitiesModal'),
      deps: ['$injector'],
    },
    {
      provide: Stakeholders,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useFactory: ($injector: any) => $injector.get('Stakeholders'),
      deps: ['$injector'],
    },
    {
      provide: similarEntitiesModalHelper,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useFactory: ($injector: any) => $injector.get('similarEntitiesModalHelper'),
      deps: ['$injector'],
    },
  ],
})
export class SbxWorkflowsModule {
  static entryComponents = [SbxRelationshipSetupComponent];

  static forRoot(): ModuleWithProviders<SbxWorkflowsModule> {
    return {
      ngModule: SbxWorkflowsModule,
    };
  }
}
