import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxDateBaseComponent } from './sbx-date-base.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'sbx-date',
  templateUrl: './sbx-date.component.html',
  styleUrls: ['./sbx-date.component.scss'],
})
export class SbxDateComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  @ViewChild('dateBaseRef', { static: true }) dateBaseRef: SbxDateBaseComponent;
  private destroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }
  
  ngOnInit() {
    if (!this.to.postfix) {
      this.to.postfix = {};
    }

    this.to.postfix.icon = 'date';
    this.to.postfix.click = () => {
      this.dateBaseRef.toggleCalendar();
    };

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleChange(value: string) {
    this.formControl.setValue(value);

    if (this.formControl.touched) {
      return;
    }

    this.formControl.markAsTouched();
  }

  handleFocus(event) {
    if (this.to.focus) {
      this.to.focus(this.field, event);
    }
  }

  handleBlur() {
    this.formControl.markAsTouched();
  }
}
