import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxPagingModule } from '@/shared/sbx-paging/sbx-paging.module';
import { SbxGridModule } from '@/shared/sbx-grid/sbx-grid.module';
import { SbxPageModule } from '@/shared/sbx-page/sbx-page.module';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxPageHeaderModule } from '@/shared/sbx-page-header/sbx-page-header.module';
import { SbxAlertBannerModule } from '@/shared/sbx-alert-banner/sbx-alert-banner.module';
import { SbxListingSortModule } from '@/shared/sbx-listing/sbx-listing-sort/sbx-listing-sort.module';
import { SbxListingFilterModule } from '@/shared/sbx-listing/sbx-listing-filter/sbx-listing-filter.module';
import { SbxListingService } from './sbx-listing.service';
import { SbxListingComponent } from './sbx-listing.component';

@NgModule({
  declarations: [SbxListingComponent],
  providers: [SbxListingService],
  imports: [
    CommonModule,
    SbxPagingModule,
    SbxButtonModule,
    SbxIconModule,
    SbxGridModule,
    SbxPageModule,
    SbxPageHeaderModule,
    SbxAlertBannerModule,
    SbxListingSortModule,
    SbxListingFilterModule,
  ],
  exports: [SbxListingComponent],
})
export class SbxListingModule {}
