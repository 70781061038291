import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import Modal from '../../lib/modal/modal';
import BSCollapse from 'angular-ui-bootstrap/src/collapse';
import BSAccordion from 'angular-ui-bootstrap/src/accordion';

import Tools from '../../lib/tools';
import Document from '../../lib/document/document';

import {
  ReviewAndEditDocumentsModel,
  ShareDocumentsModalCtrl,
  sbReviewAndEditDocuments,
  sbRefPopovers,
  sbReviewDocumentMetadata,
} from './documents';

import { EditTermsModel, $editTermsModal, viewTextDiff } from './terms';

/**
 * @ngdoc overview
 * @name sb.workitem.reviewAndEditDocuments
 *
 * @description
 * This is the module for the review and edit documents workitem.
 */
export default angular
  .module('sb.workitem.reviewAndEditDocuments', [
    Modal,
    BSAccordion,
    BSCollapse,
    ngSanitize,
    Tools,
    Document,
  ])

  .component('sbReviewDocumentMetadata', sbReviewDocumentMetadata)
  .controller('ShareDocumentsModalCtrl', ShareDocumentsModalCtrl)
  .directive('sbReviewAndEditDocuments', sbReviewAndEditDocuments)
  .directive('sbRefPopovers', sbRefPopovers)
  .factory('ReviewAndEditDocumentsModel', ReviewAndEditDocumentsModel)
  .factory('EditTermsModel', EditTermsModel)
  .factory('$editTermsModal', $editTermsModal)
  .factory('viewTextDiff', viewTextDiff).name;
