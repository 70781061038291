import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  forwardRef,
} from '@angular/core';
import { $pickFromDataroomModal } from '../../../js/lib/document/pick-from';
import {
  ISbxDocumentReferenceModel,
  SbxDocumentReferenceModelType,
} from './sbx-document-reference.component';
import { BackendLocation } from '@/core/http';

export interface ISbxDocumentPickFromModalConfig {
  // Show managed documents in pick from modal
  allowPickManaged?: boolean;
  // ID of root/init folder.
  rootFolderId: string;
  // Required when `allowUpload`. URL location of upload API.
  managedTagFilter?: string[];
  // Should show un-finalized doc in Data Room.
  allowUnfinalized?: boolean;
  // Should show pending docs in Data Room.
  allowPending?: boolean;
  // Should show documents from the process regardless of visibility
  allowAllFromProc?: boolean;
}

export class SbxDocumentPickFromModel implements ISbxDocumentReferenceModel {
  type = SbxDocumentReferenceModelType.PickFrom;
  documentTitle: string;
  documentId?: string;
  pdfURL?: string;
}

@Component({
  selector: 'sbx-document-pick-from',
  templateUrl: './sbx-document-pick-from.component.html',
  styleUrls: ['./sbx-document-pick-from.component.scss'],
})
export class SbxDocumentPickFromComponent {
  @Input() config: ISbxDocumentPickFromModalConfig;
  @Input() pickFromModalTitle = 'Pick From Data Room';
  @Input() excludeFn: () => boolean;

  @Input()
  get model() {
    return this.pickFromModel;
  }
  set model(val) {
    this.pickFromModel = val;
    this.modelChange.emit(this.pickFromModel);
  }
  @Output() modelChange = new EventEmitter();
  private pickFromModel = new SbxDocumentPickFromModel();

  constructor(
    @Inject($pickFromDataroomModal) private modalService,
    @Inject(forwardRef(() => BackendLocation)) private backendLocation: BackendLocation,
  ) {}

  openPickFromModal() {
    const { config, pickFromModalTitle, excludeFn } = this;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;
    this.modalService({
      title: pickFromModalTitle,
      showManaged: config.allowPickManaged,
      rootFolderId: config.rootFolderId,
      managedTagFilter: config.managedTagFilter,
      allowUnfinalized: config.allowUnfinalized,
      allowPending: config.allowPending,
      allowAllFromProc: config.allowAllFromProc,
      excludeFn: excludeFn,
    })
      .then(({ id, title }) => {
        comp.model = {
          type: SbxDocumentReferenceModelType.PickFrom,
          documentId: id,
          documentTitle: title,
          pdfURL: this.backendLocation.entity('1') + `documents/${id}/preview_content`,
        };
      })
      .catch((e) => {
        throw new Error(e);
      });
  }
}
