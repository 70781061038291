import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxDropdownModule } from '../sbx-dropdown/sbx-dropdown.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxActionMenuComponent } from './sbx-action-menu.component';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxDropdownModule,
    SbxPopoverModule,
    SbxButtonModule,
  ],
  declarations: [SbxActionMenuComponent],
  exports: [SbxActionMenuComponent],
})
export class SbxActionModule {}
