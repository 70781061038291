import { InjectionToken } from '@angular/core';

export interface ISbxProcessUrlInfo {
  setBaseUrl: (baseUrl: string) => void;
  processUrl: () => string;
  dashboardUrl: () => string;
  currentWiUrl: () => string;
  subProcessWiId: () => string;
  wiId: () => string;
  processId: () => string;
  urlByWorkItemId: (id: string) => string;
  activityId: () => string;
  currentProcessInfoUrl: () => string;
  currentProcessGotoUrl: () => string;
  currentProcessInfoUrlTask: (taskId?: string) => string;
  abortUrl: () => string;
  doneUrl: () => string;
  isParentProcess: () => string;
}

export const SbxProcessUrlInfo = new InjectionToken<ISbxProcessUrlInfo>(
  'SbxProcessUrlInfo',
);

export function ProcessUrlInfoServiceFactory(i: any) {
  return i.get('ProcessUrlInfo');
}

export const ProcessUrlInfoServiceProvider = {
  provide: SbxProcessUrlInfo,
  useFactory: ProcessUrlInfoServiceFactory,
  deps: ['$injector'],
};
