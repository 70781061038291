/**
 * @ngdoc component
 * @name sb.workitem.waitPages.component:sbDefaultWaitWorkitemInfo
 *
 * @description
 * Use this component to (statelessly) show information about a wait worktiem.
 * This is the deafault view for these kinds of workitems and should work for
 * most cases.
 *
 * @param {wiWaitInfoRecord} info See `sb.process.model.$workitemRecord` for
 *   expected properties.
 */
export const sbDefaultWaitWorkitemInfo = {
  controllerAs: 'vm',
  template: require('./templates/default-wait-info.html'),
  bindings: {
    info: '<',
  },
}; // end sbDefaultWaitWorkitemInfo

/**
 * @ngdoc component
 * @name sb.workitem.waitPages.component:sbWaitWorkitemInfo
 *
 * @description
 * This component displays a workitem wait detials given a workitem type.
 *
 * @param {wiRecord} info See `sb.process.model.$workitemRecord` for
 *   expected properties.
 */
export const sbWaitWorkitemInfo = {
  controllerAs: 'vm',
  template: require('./templates/info.html'),
  bindings: {
    wi: '<workitem',
  },
}; // end sbWaitWorkitemInfo
