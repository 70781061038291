import { SbxUrlService } from '@/core/url/url.service';
import { Component, Inject, Input } from '@angular/core';
import { Downgrade } from '../downgrade';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { SbxVideoModalComponent } from './sbx-video-modal.component';

export enum SbxVideoMode {
  video = 'video',
  modal = 'modal',
}

export enum SbxVideoProportion {
  '16:9' = 'sbx-proportion-16-9',
  '4:3' = 'sbx-proportion-4-3',
}

const SELECTOR = 'sbx-video';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-video.component.html',
  styleUrl: './sbx-video.component.scss',
})
export class SbxVideoComponent {
  @Input()
  public videoSrc: string;
  @Input()
  public mode: SbxVideoMode = SbxVideoMode.video;
  @Input()
  public modalSize: 'sm' | 'md' | 'lg' = 'lg';
  @Input()
  public set proportion(value: SbxVideoProportion) {
    this.videoProportion =
      SbxVideoProportion[value] || value || SbxVideoProportion['16:9'];
  }
  @Input()
  public coverTitle: string;

  public videoProportion: SbxVideoProportion = SbxVideoProportion['16:9'];
  public videoMode: typeof SbxVideoMode = SbxVideoMode;

  public constructor(
    private readonly modalService: SbxModalService,
    @Inject(SbxUrlService) public readonly urlService: SbxUrlService,
  ) {}

  public open(): void {
    this.modalService.open(SbxVideoModalComponent, {
      size: this.modalSize,
      data: {
        videoSrc: this.videoSrc,
        proportion: this.videoProportion,
        title: this.coverTitle,
      },
    });
  }
}
