import { ComponentRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SbxHttpClient } from '@/core/http';
import { Downgrade } from '../downgrade';

@Downgrade.Component('ngShoobx', 'sbx-workitem-content')
@Component({
  selector: 'sbx-workitem-content',
  templateUrl: './sbx-workitem-content.component.html',
  styleUrls: ['./sbx-workitem-content.component.scss'],
})
export class SbxWorkitemContentComponent implements OnInit {
  @Input() path: string;
  @Input() wi: string;
  html: SafeHtml;
  htmlWrapperComponent: ComponentRef<any>;
  loading = true;

  constructor(private http: SbxHttpClient, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.http
      .entity('1')
      .get(this.path)
      .subscribe((res: any) => {
        this.html = this.domSanitizer.bypassSecurityTrustHtml(res.content)[
          // eslint-disable-next-line dot-notation
          'changingThisBreaksApplicationSecurity'
        ];
        this.loading = false;
      });
  }
}
