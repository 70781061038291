import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-clipboard',
  templateUrl: './sbx-clipboard.component.html',
  styleUrls: ['./sbx-clipboard.component.scss'],
})
export class SbxClipboardComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  @ViewChild('textAreaEl') textAreaEl: ElementRef<any>;

  ngOnInit() {
    // XXX: Should be removed when we migrate to Angular 8.
    // This line fixes tests (ExpressionChangedAfterItHasBeenCheckedError)
    setTimeout(() => {
      if (!this.to.postfix) {
        this.to.postfix = {};
      }

      this.to.postfix.icon = 'paste';
      this.to.postfix.click = this.copyToClipboard.bind(this);
      // Make postfix clickable when modal is readOnly
      this.to.readOnly = false;
    });
  }

  copyToClipboard() {
    // We copy text from hidden textarea
    this.textAreaEl.nativeElement.focus();
    this.textAreaEl.nativeElement.select();
    document.execCommand('copy');
  }
}
