import { Downgrade } from '@/shared/downgrade';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { formatNumber } from '@angular/common';

const NUMBER_REGEX = /[^\d]/g;
const FLOAT_REGEX = /[^\d.-]/g;
const ZEROS_REGEX = /^[^1-9]*$/;

@Downgrade.Component('ngShoobx', 'sbx-number-textline-base')
@Component({
  selector: 'sbx-number-textline-base',
  templateUrl: './sbx-number-textline-base.component.html',
  styleUrls: ['./sbx-number-textline-base.component.scss'],
})
export class SbxNumberTextlineBaseComponent implements OnInit {
  @ViewChild('inputField', { static: true }) input;
  @Input() public model: string | number;
  @Input() public id: string;
  @Input() public min: number;
  @Input() public max: number;
  @Input() public precision = 0;
  @Input() public errorsMin = false;
  @Input() public errorsMax = false;
  @Input() public showError = false;
  @Input() public readOnly = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public to: any;
  @Output() public changeValue: EventEmitter<number> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public change: EventEmitter<SbxNumberTextlineBaseComponent> =
    new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  public formattedValue = '';

  private userTyping: boolean = false;

  public constructor(private elRef: ElementRef) {}

  public ngOnInit(): void {
    if (!this.precision) {
      this.precision = 0;
    }
    this.formatValue(`${this.model}`);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.model?.firstChange ||
      changes.model?.previousValue === changes.model?.currentValue
    ) {
      return;
    }

    let value = `${this.model}`;
    if (Object.is(this.model, -0)) {
      // special case for negative zero where stringification removes the negative
      value = '-' + value;
    }
    if (this.formatModelValue(this.formattedValue) !== this.model) {
      this.formatValue(value, false);
    }
  }

  public handleFocus(event): void {
    this.focus.emit(event);
  }

  public handleBlur(event): void {
    const eventValue = event.target.value;

    if (eventValue.indexOf('.') > 0 && eventValue.at(eventValue.length - 1) === '0') {
      this.blur.emit(event);
      return;
    }

    this.formatValue(`${this.model}`);
    this.blur.emit(event);
  }

  public onInput(): void {
    this.userTyping = true;
  }

  public formatValue(value, suppressChangeValue = false): void {
    const formattedValue: string = this.formatDisplayValue(value);
    const modelValue: number = this.formatModelValue(formattedValue);
    this.formattedValue = formattedValue;
    this.input.nativeElement.value = formattedValue;
    this.model = modelValue;
    if (!suppressChangeValue) {
      this.changeValue.emit(modelValue);
      this.change.emit(this.elRef.nativeElement);
    }
  }

  private formatDisplayValue(value: string): string {
    const firstOccurence = value.indexOf('.');
    let currentVal = value.replace(NUMBER_REGEX, (...args) => {
      if (args[0] === '.' && args[1] === firstOccurence) {
        return args[0];
      }
      if (args[0] === '-' && args[1] === 0) {
        return args[0];
      }
      return '';
    });

    let minDecimalDigit = currentVal.split('.')[1]?.length || 0;
    if (this.userTyping && minDecimalDigit > this.precision) {
      currentVal = currentVal.slice(0, -(minDecimalDigit - this.precision));
      this.userTyping = false;
    }
    minDecimalDigit = Math.min(minDecimalDigit, this.precision);

    let displayValue: string;
    if (isNaN(parseFloat(currentVal))) {
      displayValue = currentVal;
    } else {
      displayValue = formatNumber(
        Number(currentVal),
        'en',
        `1.${minDecimalDigit}-${this.precision}`,
      );
      if (currentVal.charAt(currentVal.length - 1) === '.') {
        displayValue = displayValue + '.';
      }
      if (currentVal.charAt(0) === '-' && displayValue.charAt(0) !== '-') {
        displayValue = '-' + displayValue;
      }
    }
    return displayValue;
  }

  private formatModelValue(value: string): number {
    const currentVal = parseFloat(value.replace(FLOAT_REGEX, ''));
    if (currentVal === 0) {
      return ZEROS_REGEX.test(value) || currentVal === 0 ? 0 : null;
    }
    let newVal;

    if (Number.isNaN(currentVal)) {
      return null;
    }

    newVal = currentVal.toFixed(this.precision);
    newVal = parseFloat(newVal);
    return newVal;
  }
}
