import angular from 'angular';
import sbBootstrap from '../../lib/bootstrap/bootstrap';
import sbOrgCharts from './orgcharts';
import sbOrgChart from './orgchart';
import Promise from '../../lib/promise';
import Employee from '../../lib/employee';
import BackendLocation from '../../lib/url';
import Modal from '../modal/modal';

export default angular
  .module('sb.lib.orgchart', [sbBootstrap, Modal, Promise, Employee, BackendLocation])

  .component('sbOrgCharts', sbOrgCharts)
  .component('sbOrgChart', sbOrgChart).name;
