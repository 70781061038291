import { Map } from 'immutable';

/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbMdAnnotations
 *
 * @description
 * Add popovers to metadata annotations within a document's HTML content.
 * It adds these popover annotations to elements with class `.metadata-placeholder`.
 * It looks for the `data-field` attribute to use on the lookup.
 *
 * @element ANY
 * @param {Map} sbMdAnnotations Content/title lookup. This is expected
 *    to be an immutable map in the form of `{'set.field':
 *    {title: 'Title', value: 'Value'} }`.
 *
 * @example
   <!--
     $scope.annotations = Map({
       'testset.testfield': Map({ title: 'Test Field', value: 'testvalue' }),
     });
   -->
   <div data-sb-md-annotations="annotations">
     <span class=metadata-placeholder data-field=testset.testfield>
       Here is a placeholder
     </span>
     inside a bigger html doc
   </div>

   You can also use data-ng-bind-html for the contents.
 */
export function sbMdAnnotations() {
  return {
    restrict: 'A',
    scope: {
      data: '=sbMdAnnotations',
    },
    link(scope, element) {
      function lookup(fieldAttr, missing) {
        return function () {
          const data = scope.data || Map();
          const fieldLookup = this.getAttribute('data-field');
          return data.get(fieldLookup, Map()).get(fieldAttr) || missing;
        };
      }
      element.popover({
        title: lookup('title'),
        content: lookup('value', 'No value yet'),
        selector: '.metadata-placeholder',
        html: true,
        placement: 'auto',
        trigger: 'hover',
        container: 'body',
      });
    },
  };
} // end sbMdAnnotations

export const sbStaticContent = [
  '$sanitize',
  function ($sanitize) {
    return {
      restrict: 'A',
      link(scope, element) {
        element.popover({
          title: function () {
            const title = this.getAttribute('data-title');
            if (title && title.length > 0) {
              return $sanitize(title);
            }

            return 'Dynamic Content';
          },
          content: function () {
            return $sanitize(this.textContent);
          },
          selector: '.dynamic-content',
          html: true,
          placement: 'auto',
          trigger: 'hover',
          container: 'body',
        });
      },
    };
  },
]; // end sbStaticContent

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.document.object:$editDocumentPropertiesModal
 *
 * @description
 * This is a high level function for opening a modal for editing a documents properites.
 * This will enable user to edit things like title and effective date.
 *
 * @param {object} document The document object of the editing document. At least:
 *   @property {string} id
 *   @property {string} title
 *
 * @returns {Promise} Resolves when the document was edited and rejects otherwise.
 */
export const $editDocumentPropertiesModal = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  '$formModal',
  function (SimpleHTTPWrapper, BackendLocation, $formModal) {
    return (doc) => {
      const url = `${BackendLocation.entity(1)}documents/${doc.id}/editProperties`;
      let loading = true; // We are loading on init with `onOpenPromise`.
      return $formModal(
        {
          title: `Edit ${doc.title}`,
          htmlContent: require('./templates/edit-properties-modal.html'),
          loading: () => loading,
          onOpenPromise() {
            return SimpleHTTPWrapper({ url }, 'Failed to fetch document info.')
              .then(({ data, description }) => ({
                forms: { editPropsForm: description },
                formData: { editPropsForm: data },
              }))
              .finally(() => {
                loading = false;
              });
          },
          onConfirmPromise({ $formData }) {
            loading = true;
            const opts = { method: 'POST', url, data: $formData.editPropsForm };
            return SimpleHTTPWrapper(opts, 'Failed to update document.').finally(() => {
              loading = false;
            });
          },
        },
        'lg',
        'edit-document-props-modal',
      );
    };
  },
]; // end $editDocumentPropertiesModal
