import { Component, OnInit, Inject } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http';
import { Subject } from 'rxjs';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { SbxUrlService } from '@/core/url/url.service';

const SELECTOR = 'sbx-counsel-info';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-counsel-info.component.html',
  styleUrls: ['./sbx-counsel-info.component.scss'],
})
export class SbxCounselInfoComponent implements OnInit {
  counselData$: Subject<any>;
  deleted = false;
  hasError = false;
  bannerMsg: string = null;

  constructor(
    private sbxHttp: SbxHttpClient,
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(SbxConfirmationModalService)
    private sbxConfirmationService: SbxConfirmationModalService,
  ) {
    this.counselData$ = new Subject();
  }

  ngOnInit() {
    this.getCounselInfo();
    this.deleted = false;
  }

  onDelete() {
    this.sbxConfirmationService
      .open({
        data: {
          title: 'Removing Counsel',
          body: "You are about to remove your counsel and counsel's access to your company. Are you sure?",
          cancelButtonTitle: 'Cancel',
          okButtonTitle: 'Remove',
        },
      })
      .result.then(() => this.removeCounsel());
  }

  getCounselInfo() {
    this.sbxHttp
      .entity('2')
      .get('counsel/info')
      .subscribe((res: any) => {
        res.deleted = false;
        this.counselData$.next(res);
      });
  }

  removeCounsel() {
    this.sbxHttp
      .entity('2')
      .delete('counsel/remove')
      .subscribe(
        (res: any) => {
          // response also can be an error!
          this.bannerMsg = res.status;
          res.deleted = true;
          this.counselData$.next(res);

          if (res.redirectUrl) {
            window.location.href = res.redirectUrl;
          }
        },
        (errors) => {
          this.hasError = true;
          this.bannerMsg =
            errors.status === 500 ? 'Unable to remove counsel.' : errors.error.status;
        },
      );
  }
}
