<sbx-row>
  <sbx-col [lg]="8" [sm]="10">
    <sbx-title type="page">{{ title }}</sbx-title>
    <span *ngIf="beta" class="sbx-beta"
      ><img id="beta-image" [src]="sbxUrlService.assetUrl('img/beta.png')"
    /></span>
    <div class="sbx-header-actions" *ngIf="actionItems?.length">
      <sbx-action-menu [actions]="actionItems"></sbx-action-menu>
    </div>
  </sbx-col>

  <sbx-col [lg]="4" [sm]="2" class="sbx-back-url-wrapper">
    <ng-container
      *ngIf="isSpaPage && sbxUrlService.spaUrl(this.backUrl); else regularLink"
    >
      <a
        [routerLink]="sbxUrlService.spaUrl(this.backUrl).url"
        [queryParams]="sbxUrlService.spaUrl(this.backUrl).queryParams"
      >
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
      </a>
    </ng-container>
    <ng-template #regularLink>
      <a [href]="this.backUrl" class="sbx-back-url">
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
      </a>
    </ng-template>
  </sbx-col>

  <sbx-col [lg]="12" *ngIf="description">
    <div class="sbx-description">
      {{ description }}
    </div>
  </sbx-col>
</sbx-row>

<ng-template #linkContent>
  <span class="sbx-page-header-backlink">
    <sbx-icon class="sbx-back-url-icon" type="chevronLeft"></sbx-icon>
    <span class="sbx-back-url-title">{{ getBackButtonText() }}</span>
  </span>
</ng-template>
