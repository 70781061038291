import angular from 'angular';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.relationships.Relationship
 *
 * @param {string} withName ID name of the entity the relation is with.
 * @param {string} type Relationship type.
 * @param {string} title Relationship title.
 *
 * @returns {object} A new Relationship object for use as a model. You should
 *    use this as a base prototype for other relationships and implement
 *    a `$$write` method to update a model given server data.
 */
export const Relationship = [
  '$q',
  'SimpleHTTPWrapper',
  function ($q, SimpleHTTPWrapper) {
    function Relationship(withName, type, title) {
      /**
       * @ngdoc property
       * @name $with
       * @propertyOf sb.lib.relationships.Relationship
       *
       * @description
       * String name of who this relationship is with.
       */
      this.$with = withName;

      /**
       * @ngdoc property
       * @name $relationType
       * @propertyOf sb.lib.relationships.Relationship
       *
       * @description
       * String type of this Relationship.
       */
      this.$relationType = type;

      /**
       * @ngdoc property
       * @name $relationTitle
       * @propertyOf sb.lib.relationships.Relationship
       *
       * @description
       * String title of this Relationship.
       */
      this.$relationTitle = title;

      /**
       * @ngdoc property
       * @name $loading
       * @propertyOf sb.lib.relationships.Relationship
       *
       * @description
       * Boolean if this model is currently loading.
       */
      this.$loading = false;
    }

    /**
     * @ngdoc method
     * @name $$flattenTreeArrays
     * @methodOf sb.lib.relationships.Relationship
     *
     * @description
     * An internally available method to compute/flatten tree structures.
     *
     * @param {array} tree Array of trees.
     * @param {function} mapFunction Applicative to each "important" item.
     *
     * @returns {array} Returns an array of "topmost", selected items in the tree.
     */
    Relationship.prototype.$$flattenTreeArrays = function (tree, mapFunction) {
      const obj = this;
      if (!tree || !tree.length) {
        return [];
      }
      return tree
        .map((tItem) => {
          if (tItem.selected) {
            return [mapFunction(tItem)];
          }
          return obj.$$flattenTreeArrays(tItem.children, mapFunction);
        })
        .reduce((a, b) => a.concat(b), []);
    };

    /**
     * @ngdoc method
     * @name $$update
     * @methodOf sb.lib.relationships.Relationship
     *
     * @description
     * An internally available method to update the model POSTing to `this.$$url`.
     *
     * @param {object} data The data that will be sent to the server.
     * @param {string} defaultMsg The default rejection reason.
     *
     * @returns {promise} Returns a promise that resolves and calls `$$write` or
     *    rejects with failure reason.
     */
    Relationship.prototype.$$update = function (data, defaultMsg) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url,
          data: data,
        },
        defaultMsg,
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
          this.$triggerModelUpdate();
        });
    };

    Relationship.prototype.$editFund = function (fund) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/edit-fund',
          data: fund,
        },
        'Cannot add fund',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    Relationship.prototype.$removeFund = function (fundId) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/remove-fund',
          data: { fundId },
        },
        'Cannot remove fund',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    /**
     * @ngdoc method
     * @name $init
     * @methodOf sb.lib.relationships.Relationship
     *
     * @description
     * A method that inits the model with a GET to `this.$$url`.
     *
     * @returns {promise} Returns a promise that resolves with server data or
     *    rejects with failure reason.
     */
    Relationship.prototype.$init = function () {
      this.$loading = true;
      return $q
        .all([
          SimpleHTTPWrapper(
            {
              method: 'GET',
              url: this.$$url,
            },
            'Failed to fetch relationship information.',
          ).then(
            (data) => {
              return this.$$write(data);
            },
            (reason) => {
              return $q.reject(reason);
            },
          ),
          SimpleHTTPWrapper(
            {
              method: 'GET',
              url: this.$$diligenceUrl + '/status',
            },
            'Failed to fetch diligence status',
          ).then(
            (data) => {
              return this.$$setDiligence(data);
            },
            (reason) => {
              return $q.reject(reason);
            },
          ),
        ])
        .finally(() => {
          this.$loading = false;
          this.$triggerModelUpdate();
        });
    };

    return function (withName, type, title) {
      return new Relationship(withName, type, title);
    };
  },
]; // end Relationship

function getRelationshipUrl(BackendLocation, withName, type) {
  const currentLocation = BackendLocation.context(1)
    .replace(BackendLocation.entity(1), '')
    .split('/')
    .filter((a) => a);

  if (currentLocation[0] === 'relationships' && currentLocation.length === 3) {
    return BackendLocation.context(1);
  }

  return BackendLocation.entity(1) + 'relationships/' + withName + '/' + type + '/';
}

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.relationships.TargetRelationship
 *
 * @description
 * Makes a model for target relationships.
 *
 * @param {string} withName ID name of the entity the relation is with.
 * @param {string} type Relationship type.
 * @param {string} title Relationship title.
 *
 * @returns {object} A new target relationship object for use as a model.
 */
export const TargetRelationship = [
  'Relationship',
  'BackendLocation',
  'SimpleHTTPWrapper',
  'SourceRelationship',
  function (Relationship, BackendLocation, SimpleHTTPWrapper, SourceRelationship) {
    const base = Relationship();
    function TargetRelationship(withName, type, title) {
      this.$with = withName;
      this.$relationType = type;
      this.$relationTitle = title;
      this.$loading = false;
      this.$isTarget = true;

      const url = getRelationshipUrl(BackendLocation, withName, type);
      this.$$url = url + 'permissions';
      this.$$diligenceUrl = url + 'target-diligence';

      // This model does not use this. There are no stakeholder options here.
      this.$settings = {};

      this.$diligenceMode = undefined;

      /**
       * @ngdoc property
       * @name $relationshipStatus
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * String: status of the relationship.
       */
      this.$relationshipStatus = undefined;

      /**
       * @ngdoc property
       * @name $permissions
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * Tree of permissions.
       */
      this.$permissions = [];

      /**
       * @ngdoc property
       * @name $clientAccess
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * Flattened "topmost" permissions.
       */
      this.$clientAccess = [];

      /**
       * @ngdoc property
       * @name $folders
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * List of folders the stakeholder has access to
       */
      this.$folders = [];

      /**
       * @ngdoc property
       * @name $sourceTeam
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * Model of team of source part of the relationship. Consists of list
       * of members
       */
      this.$sourceTeam = SourceRelationship();
      this.$$modelUpdateCallbacks = [];
    }
    TargetRelationship.prototype = base;
    TargetRelationship.prototype.constructor = TargetRelationship;

    TargetRelationship.prototype.$$flatten = function (tree, empty) {
      const flatten = base.$$flattenTreeArrays(tree, (tItem) => {
        return { title: tItem.title, name: tItem.name };
      });
      return flatten.length ? flatten : empty;
    };

    TargetRelationship.prototype.$$write = function (data) {
      if (data.sourceTeam && data.sourceTeam.relationshipStatus) {
        this.$relationshipStatus = data.sourceTeam.relationshipStatus;
      }
      this.$clientAccess = this.$$flatten(data.permissions, [{ title: 'No Access' }]);
      this.$folders = data.folders;
      this.$permissions = data.permissions;
      this.$sourceTeam.$$write(data.sourceTeam || {});
    };

    TargetRelationship.prototype.$$setDiligence = function (data) {
      this.$diligenceMode = data.status;
      this.$diligenceEnabledDate = data.enabledDate;
    };

    TargetRelationship.prototype.$onModelUpdate = function (callBack) {
      this.$$modelUpdateCallbacks.push(callBack);
    };

    TargetRelationship.prototype.$triggerModelUpdate = function () {
      this.$$modelUpdateCallbacks.forEach((callBack) => callBack(this));
    };

    /**
     * @ngdoc method
     * @name $edit
     * @methodOf sb.lib.relationships.TargetRelationship
     *
     * @description
     * A method that edits permissions.
     *
     * @param {object} permissions The data that will be sent to the server.
     *
     * @returns {promise} Returns a promise that will call `$$write` or
     *    rejects with failure reason.
     */
    TargetRelationship.prototype.$edit = function (permissions) {
      return this.$$update(angular.copy(permissions), 'Failed to edit permissions.');
    };

    /**
     * @ngdoc method
     * @name $requestSourceMember
     * @methodOf sb.lib.relationships.TargetRelationship
     *
     * @description
     * Request new team member from source entity
     *
     * @param {object} member Member data to request
     *
     * @returns {promise} Return a promise that will update model upon
     * successful request
     */
    TargetRelationship.prototype.$requestSourceMember = function (member) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/source-member-request',
          data: member,
        },
        'Cannot request team member',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    /**
     * @ngdoc method
     * @name $updateSourceMember
     * @methodOf sb.lib.relationships.TargetRelationship
     *
     * @description
     * Update existing team member in source entity
     *
     * @param {object} member Member data to update
     *
     * @returns {promise} Return a promise that will update model upon
     * successful request
     */
    TargetRelationship.prototype.$updateSourceMember = function (member) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/source-member-update',
          data: member,
        },
        'Cannot update team member',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
          this.$triggerModelUpdate();
        });
    };

    /**
     * @ngdoc method
     * @name $remove
     * @methodOf sb.lib.relationships.TargetRelationship
     *
     * @description
     * Remove a member from the model and persist.
     * If the member is pending, we additionally remove them from the
     * relationship request via endpoint call from $removePendingSourceMember
     *
     * @param {object} removeMember Member to remove.
     *
     * @returns {promise} Returns a promise that resolves when the operation
     *    completes successfully or rejects with reason.
     */
    TargetRelationship.prototype.$remove = function (member) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/source-member-remove',
          data: member,
        },
        'Cannot remove team member',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$triggerModelUpdate();
          this.$loading = false;
        });
    };

    TargetRelationship.prototype.$addFund = function (fundName) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/add-fund',
          data: { fundName },
        },
        'Cannot edit fund',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    TargetRelationship.prototype.$editFund = function (fund) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/edit-fund',
          data: fund,
        },
        'Cannot add fund',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    TargetRelationship.prototype.$removeFund = function (fundId) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + '/remove-fund',
          data: { fundId },
        },
        'Cannot remove fund',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    return function (withName, type, title) {
      return new TargetRelationship(withName, type, title);
    };
  },
]; // end TargetRelationship

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.relationships.SourceRelationship
 *
 * @param {string} withName ID name of the entity the relation is with.
 * @param {string} type Relationship type.
 * @param {string} title Relationship title.
 *
 * @returns {object} A new source relationship object for use as a model.
 */
export const SourceRelationship = [
  '$q',
  'Relationship',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, Relationship, SimpleHTTPWrapper, BackendLocation) {
    function memberSortKey(member) {
      let prefix = '1_'; // Primary
      if (member.manager && !member.primary) {
        // Manager
        prefix = '2_';
      } else if (!member.manager && !member.primary) {
        // Member
        prefix = '3_';
      }
      return prefix + member.stakeholder.sh.lastName;
    }

    const base = Relationship(),
      baseInit = base.$init;
    function SourceRelationship(withName, type, title) {
      this.$with = withName;
      this.$relationType = type;
      this.$relationTitle = title;
      this.$loading = false;
      this.$isTarget = false;

      const url = getRelationshipUrl(BackendLocation, withName, type);
      this.$$url = url;
      this.$$diligenceUrl = url + 'source-diligence';

      this.$diligenceMode = undefined;

      /**
       * @ngdoc property
       * @name $settings
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description
       * Object of settings.
       */
      this.$settings = {};

      /**
       * @ngdoc property
       * @name $relationshipStatus
       * @propertyOf sb.lib.relationships.TargetRelationship
       *
       * @description
       * String: status of the relationship.
       */
      this.$relationshipStatus = undefined;

      /**
       * @ngdoc property
       * @name $members
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description
       * Array of members (objects) in the team.
       */
      this.$members = [];

      /**
       * @ngdoc property
       * @name $members
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description
       * Array of funds (objects) related to the team.
       */
      this.$funds = [];

      /**
       * @ngdoc property
       * @name $pendingMembers
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description
       * Array of pending members (objects) for the team.
       */
      this.$pendingMembers = [];

      /**
       * @ngdoc property
       * @name $canModifyPrimary
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description can the current user modify the primary stakeholder
       * Boolean
       */
      this.$canModifyPrimary = false;

      /**
       * @ngdoc property
       * @name $canEditSigners
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description
       * Can edit the signers of the team.
       */
      this.$fixedSigners = false;

      /**
       * @ngdoc property
       * @name $canGiveCreate
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description can the current user give "Create User" permissions
       */
      this.$canGiveCreate = false;

      /**
       * @ngdoc property
       * @name $invited
       * @propertyOf sb.lib.relationships.SourceRelationship
       *
       * @description is the current team stakeholder invited or not
       */
      this.invited = undefined;

      this.$$modelUpdateCallbacks = [];

      this.$allowedFolderTree = [];

      this.$allowedGroups = [];

      this.$relationshipType = undefined;

      this.$hasVerifiedDomain = false;
    }
    SourceRelationship.prototype = base;
    SourceRelationship.prototype.constructor = SourceRelationship;

    SourceRelationship.prototype.$$flatten = function (tree) {
      return base.$$flattenTreeArrays(tree, (tItem) => {
        return tItem.title;
      });
    };

    SourceRelationship.prototype.$$write = function (data) {
      const obj = this;
      obj.$relationshipStatus = data.relationshipStatus;
      obj.$relationshipType = data.relationshipType;
      obj.$canModifyPrimary = data.canModifyPrimary;
      obj.$fixedSigners = data.fixedSigners;
      obj.$hasVerifiedDomain = data.hasVerifiedDomain;
      obj.$canGiveCreate = data.canGiveCreate;
      obj.$targetEntityTitle = data.targetEntityTitle;
      obj.$teamTitle = data.teamTitle;
      obj.$allowedFolderTree = data.allowedFolderTree || [];
      obj.$allowedGroups = data.allowedGroups || [];
      obj.$allowedFolderGrants = data.allowedFolderGrants || [];
      obj.$invited = data.invited;
      obj.$funds = data.funds;

      function listForDisplay(list) {
        let text = '<ul class=relationships-permissions>';
        list.forEach((item) => {
          text += `<li>${item}</li>`;
        });
        text += '</ul>';
        return text;
      }

      function memberDataToModel(member) {
        const permissions = obj.$$flatten(member.permissions),
          restrictedFolderTitles = member.restrictedFolderTitles || [];
        member.$clientAccess = permissions.length > 0 ? 'Limited' : 'None';
        member.$clientAccessData = listForDisplay(permissions) || '';
        member.$dataRoomTags = restrictedFolderTitles.length > 0 ? 'Partial' : 'None';
        member.$dataRoomTagsData =
          listForDisplay(restrictedFolderTitles) || 'Nothing Shared';
        member.$isPrimary = member.primary;
        member.$isFullManager = member.fullManager;
        member.$isOnlySigner = member.onlySigner;
        member.$onlySignerFor = member.onlySignerFor;
        member.$isSigner = member.isSigner;
        member.$signingFor = member.signingFor;
        member.$isPermanentManager = member.isPermanentManager;
        return member;
      }

      obj.$members = (data.members || [])
        .sort((a, b) => {
          const aKey = memberSortKey(a);
          const bKey = memberSortKey(b);
          if (aKey < bKey) {
            return -1;
          } else if (aKey > bKey) {
            return 1;
          }
          return 0;
        })
        .map(memberDataToModel);
      obj.$pendingMembers = (data.pendingMembers || []).map(memberDataToModel);
    };

    SourceRelationship.prototype.$$setDiligence = function (data) {
      this.$diligenceMode = data.status;
      this.$diligenceEnabledDate = data.enabledDate;
    };

    SourceRelationship.prototype.$init = function () {
      const obj = this,
        membersPromise = baseInit.call(obj).finally(() => {
          obj.$loading = true; // Turn this back on.
        }),
        settingsPromise = SimpleHTTPWrapper({
          method: 'GET',
          url: obj.$$url + '/forms',
        }).then(
          (data) => {
            obj.$settings = data;
          },
          (reason) => {
            return $q.reject(reason);
          },
        );
      return $q.all([membersPromise, settingsPromise]).then(
        () => {
          obj.$loading = false; // only stop loading in success.
          obj.$triggerModelUpdate();
        },
        () => {
          return $q.reject('There was an error in fetching membership information.');
        },
      );
    };

    /**
     * @ngdoc method
     * @name $remove
     * @methodOf sb.lib.relationships.SourceRelationship
     *
     * @description
     * Remove a member from the model and persist.
     * If the member is pending, we additionally remove them from the
     * relationship request via endpoint call from $removePendingSourceMember
     *
     * @param {object} removeMember Member to remove.
     *
     * @returns {promise} Returns a promise that resolves when the operation
     *    completes successfully or rejects with reason.
     */
    SourceRelationship.prototype.$remove = function (removeMember) {
      if (!removeMember.stakeholder.sh.id) {
        this.$removePendingSourceMember(removeMember);
      }
      const id = removeMember.stakeholder.sh.id;
      const email = removeMember.stakeholder.sh.email;

      return this.$$update(
        {
          members: (this.$members || []).filter(
            ({ stakeholder }) => stakeholder.sh.id !== id,
          ),
          pendingMembers: (this.$pendingMembers || []).filter(
            ({ stakeholder }) => stakeholder.sh.email !== email,
          ),
        },
        'Failed to remove member or pending member.',
      ).then(() => this.$triggerModelUpdate());
    };

    /**
     * @ngdoc method
     * @name $removePendingSourceMember
     * @methodOf sb.lib.relationships.SourceRelationship
     *
     * @description
     * Remove a pending team member from source entity
     *
     * @param {object} member Member to be removed
     *
     * @returns {promise} Return a promise that will update model upon
     * successful request
     */
    SourceRelationship.prototype.$removePendingSourceMember = function (member) {
      this.$loading = true;
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: this.$$url + 'remove-pending-source-member',
          data: member,
        },
        'Cannot remove pending team member',
      )
        .then(this.$$write.bind(this))
        .finally(() => {
          this.$loading = false;
        });
    };

    SourceRelationship.prototype.$$newMembers = function (members, newMember) {
      const id = newMember.stakeholder.sh.id;
      return (members || []).map((member) => {
        if (member.stakeholder.sh.id === id) {
          return newMember;
        } else if (newMember.primary && member.primary) {
          member = angular.copy(member);
          member.primary = false;
        }
        return member;
      });
    };
    /**
     * @ngdoc method
     * @name $add
     * @methodOf sb.lib.relationships.SourceRelationship
     *
     * @description
     * Add a member from the model and persist.
     *
     * @param {object} newMember Member to add.
     *
     * @returns {promise} Returns a promise that resolves when the operation
     *    completes successfully or rejects with reason.
     */
    SourceRelationship.prototype.$add = function (newMember) {
      let members = this.$members.concat([newMember]);
      members = this.$$newMembers(members, newMember);
      return this.$$update({ members: members }, 'Failed to add member.').then(() =>
        this.$triggerModelUpdate(),
      );
    };

    /**
     * @ngdoc method
     * @name $edit
     * @methodOf sb.lib.relationships.SourceRelationship
     *
     * @description
     * Edit a member of the model and persist.
     *
     * @param {object} editMember Member object to replace
     *
     * @returns {promise} Returns a promise that resolves when the operation
     *    completes successfully or rejects with reason.
     */
    SourceRelationship.prototype.$edit = function (editMember) {
      const members = this.$$newMembers(this.$members, editMember);
      return this.$$update({ members: members }, 'Failed to edit member.');
    };

    /**
     * @ngdoc method
     * @name $onUpdate
     * @methodOf sb.lib.relationships.SourceRelationship
     *
     * @description
     * Callback function when model changes
     *
     * @ param function that takes SourceRelationship as an input
     *
     */
    SourceRelationship.prototype.$onModelUpdate = function (callBack) {
      this.$$modelUpdateCallbacks.push(callBack);
    };

    SourceRelationship.prototype.$triggerModelUpdate = function () {
      this.$$modelUpdateCallbacks.forEach((callBack) => callBack(this));
    };

    return function (withName, type, title) {
      return new SourceRelationship(withName, type, title);
    };
  },
]; // end SourceRelationship
