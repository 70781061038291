import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxTitleComponent } from './sbx-title.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxTitleComponent],
  exports: [SbxTitleComponent],
})
export class SbxTitleModule {}
