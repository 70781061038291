import { Component, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

/**
 *  Shoobx Image Upload Component with webcam and cropping
 *
 *  Example:
Component Template:
<div class="sbx-image-upload" *ngIf="imageSrc">
  <img [src]="imageSrc" />
</div>

<div class="sbx-image-upload-button">
  <sbx-button icon="user" title="Update Photo" (click)="updatePhoto()"></sbx-button>
</div>

Component:
import { SbxImageUploadModalService } from '@/shared//sbx-image-upload-modal/sbx-image-upload-modal.service';

  constructor(
    @Inject(SbxImageUploadModalService)
    public sbxImageUploadModalService: SbxImageUploadModalService,
  ) {}
  async updatePhoto() {
    let imageModal;
    try {
      imageModal = await this.sbxImageUploadModalService.open({
        modalHeader: 'Update Profile Picture',
        disableWebcam: true,
      }).result;
      this.imageSrc = imageModal?.result?.imageSrc;
    } catch (e) {
      if (e?.userCancelled) {
        return;
      }
      this.error = e.message;
    }
  }
 */

export interface IImageModalData {
  modalHeader?: string;
  disableWebcam?: boolean;
}

export enum SnapStatuses {
  allowed = 'allowed',
  notAllowed = 'notAllowed',
  done = 'done',
}

@Component({
  selector: 'sbx-image-upload-modal',
  templateUrl: './sbx-image-upload-modal.component.html',
  styleUrls: ['sbx-image-upload-modal.component.scss'],
})
export class SbxImageUploadModalComponent {
  @ViewChild('container', { read: ViewContainerRef }) containerRef;
  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<any>;
  @ViewChild('webcamTemplate', { static: false }) webcamTemplate;

  data: IImageModalData = {
    modalHeader: 'Upload Image',
    disableWebcam: false,
  };

  errorMsg: string;
  imageChangedEvent: any = '';
  imageBase64;
  croppedImage;

  imageUploadShow = false;
  snapStatuses = SnapStatuses;
  snapStatus = SnapStatuses.notAllowed;

  constructor(
    public sbxModalService: SbxModalService,
    private sanitizer: DomSanitizer,
  ) {}

  imageUpload() {
    this.imageUploadShow = !this.imageUploadShow;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.snapStatus = SnapStatuses.done;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.snapStatus = SnapStatuses.notAllowed;
    this.sbxModalService.dismiss({
      userCancelled: false,
      message: 'Image Capture Failed',
    });
  }

  webcamLinkClicked() {
    if (this.webcamTemplate.showWebcam) {
      this.snapStatus = SnapStatuses.allowed;
    } else {
      this.snapStatus = SnapStatuses.notAllowed;
    }
  }

  onCapture(event) {
    this.imageBase64 = event;
    this.snapStatus = SnapStatuses.done;
  }

  snapPhoto() {
    this.webcamTemplate.capture();
  }

  modalBack() {
    this.snapStatus = SnapStatuses.notAllowed;
    this.imageUploadShow = !this.imageUploadShow;
  }

  modalConfirm() {
    this.snapStatus = SnapStatuses.notAllowed;
    this.sbxModalService.close({
      imageSrc: this.croppedImage,
    });
  }
}
