/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFpvBool
 * @restrict A
 *
 * @description
 * This adds a $parser/$validator to a `ngModelController`. Values `'true'`
 * (string), `true` (boolean), and `1` (number) are all parsed as true. Values
 * are formatted by their `.toString()` evaluations.
 *
 * @element ANY
 * @param {template} sbFpvBool If truthy string, this is active. This is only
 *    checked on init.
 */
export function sbFpvBool() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      if (!attrs.sbFpvBool) {
        return;
      }
      modelCtrl.$parsers.push((value) => {
        if (value === 'true' || value === true || value === 1) {
          return true;
        }
        return false;
      });
      modelCtrl.$formatters.push((value) => {
        if (modelCtrl.$isEmpty(value)) {
          return '';
        }
        return value.toString();
      });
    },
  };
} // end sbFpvBool

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbRadio
 * @restrict A
 *
 * @description
 * This directive paints a native angular radio with an `<input>` element.
 *
 * @element ANY
 * @param {expression} sbRadio Expression that this radio represents
 * @param {string} sbRadioName Name of the radio underneath. Should be the
 *    same for all radios in the same "model group."
 * @param {boolean} [ngDisabled=false] When truthy, radio is disabled.
 * @param {expression} sbRadioBoolParser Any expression that results in a
 *    truthy string will enable the model to also use `sbFpvBool`.
 * @param {expression} sbRadioModel Two-way data binding expression.
 *
 * @example
   <span
     data-sb-radio="false"
     data-sb-radio-name="::id"
     data-ng-disabled="false"
     data-sb-radio-bool-parser="true"
     data-sb-radio-model="model.value">No</span>
 */
export function sbRadio() {
  return {
    restrict: 'A',
    transclude: true,
    template: require('./templates/widgets/sb-radio.html'),
    scope: {
      value: '&sbRadio',
      name: '&sbRadioName',
      disabled: '<?ngDisabled',
      boolParser: '&sbRadioBoolParser',
      model: '=sbRadioModel',
    },
  };
} // end sbRadio

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbNoInputRadio
 *
 * @description
 * This component paints a native angular radio with no `<input>` element.
 * Its just for visual representation and is completely stateless. You
 * may transclude content as a clickable label too.
 *
 * @param {boolean} selected When truthy, radio will appear selected.
 * @param {boolean} [disabled=false] When truthy, radio will become disabled.
 * @param {expression} [onSelect=undefined] Expression to evalutate when this radio
 *   is selected (clicked). It will not fire if element is disabled or is already
 *   selected.
 *
 * @example
   <sb-no-input-radio
     disabled="item.unselectable"
     on-select="vm.selectItem(item)"
     selected="item.isSelected">
     Item Label
   </sb-no-input-radio>
 */
export const sbNoInputRadio = {
  restrict: 'EA',
  controllerAs: 'vm',
  transclude: true,
  template: `
    <div class="checkradios-radio"
      ng-class="{
        'checkradios-selected': vm.selected,
        'checkradios-disabled': vm.disabled,
      }"
      ng-click="vm.click()">
      <div class="checkradios-circle"></div>
    </div>
    <ng-transclude ng-click="vm.click()"></ng-transclude>
  `,
  bindings: {
    selected: '<',
    disabled: '<?',
    onSelect: '&?',
  },
  controller: function () {
    function click() {
      if (this.onSelect && !this.disabled && !this.selected) {
        this.onSelect();
      }
    }
    this.$onInit = () => {
      this.click = click.bind(this);
    };
  },
}; // end sbNoInputRadio

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbCheckbox
 *
 * @description
 * This directive is for displaying a checkbox. **Note:** ngChange will work
 * as normal.
 *
 * @element ANY
 * @param {expression} ngModel Model of the checkbox.
 * @param {boolean} [checkboxDisabled=false] If truthy, input will be disabled.
 * @param {boolean} [checkboxIndirect=false] If truthy, input will look disabled,
 *    but not be disabled.
 * @param {template} [name=undefined] Name of the input element underneath.
 *    It will be whitespace sanitized with `'**'`.
 *
 * @example
   <sb-checkbox name="{{ ::prefix }}-name"
     ng-change="onChange()"
     ng-model="model.value"></sb-checkbox>
 */
export const sbCheckbox = {
  controllerAs: 'vm',
  template: require('./templates/widgets/checkbox.html'),
  require: {
    formCtrl: '^?form',
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    disabled: '<?checkboxDisabled',
    indirect: '<?checkboxIndirect',
    indeterminate: '<',
    label: '<',
  },
  controller: [
    '$element',
    '$attrs',
    function ($element, $attrs) {
      this.$onInit = () => {
        this.userChange = (event, value) => {
          event.preventDefault();
          if (this.disabled) {
            return;
          }
          this.ngModelCtrl.$setViewValue(value);
        };
      };

      this.$postLink = () => {
        const { name } = $attrs;
        this.name = name && name.replace(/\s/g, '**');

        // Remove this controller from the form. The lower level input
        // will attach itself.
        if (this.formCtrl) {
          this.formCtrl.$removeControl(this.ngModelCtrl);
        }
      };
    },
  ],
}; // end sbCheckbox

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbSwitch
 *
 * @description
 * This directive is for displaying a switch
 *
 * @param {expression} ngModel Model of the switch.
 * @param {template} switchLabel the Label for the switch
 *
 * @example
   <sb-switch ng-model="model.value"></sb-switch>
 */
class SwitchCtrl {
  userToggle() {
    this.ngModelCtrl.$setViewValue(!this.model);
  }
}

export const sbSwitch = {
  controllerAs: 'vm',
  template: require('./templates/widgets/switch.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
  },
  controller: SwitchCtrl,
}; // end sbSwitch
