<ng-container *ngIf="listTitle">
  <ng-container
    *ngTemplateOutlet="
      isSubList ? heading5 : heading4;
      context: { $implicit: listTitle }
    "
  ></ng-container>
</ng-container>

<ul class="sbx-{{ layout }}">
  <li *ngFor="let item of items" class="sbx-{{ itemStyle }}">
    <sbx-popover
      [sbxPopoverTitle]="item.popoverTitle"
      [sbxPopoverContent]="item.popoverContent"
      *ngIf="item.popoverTitle || item.popoverContent"
    >
      <ng-template *ngTemplateOutlet="list"></ng-template>
    </sbx-popover>

    <ng-container *ngIf="!(item.popoverTitle || item.popoverContent)">
      <ng-template *ngTemplateOutlet="list"></ng-template>
    </ng-container>

    <ng-template #list>
      <div class="sbx-key-value">
        <span class="sbx-key subfield-{{ item.subfield }}">{{
          item.key + (itemStyle == 'lite' ? ': ' : '')
        }}</span>
        <span class="sbx-value">
          <ng-container *ngIf="item.template">
            <ng-container
              *ngTemplateOutlet="item.template; context: { $implicit: item.value }"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="!item.template">{{ item.value }}</ng-container>
        </span>
      </div>
    </ng-template>
  </li>
</ul>

<ng-template #heading4 let-title>
  <h5>
    <b>{{ title }}</b>
  </h5>
</ng-template>
<ng-template #heading5 let-title>
  <h5 class="sbx-sub-title">{{ title }}</h5>
</ng-template>
