import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const LOGOUT_REDIRECT_COOKIE = 'shoobx_app_auth_logoutRedirect';

@Injectable()
export class SbxLogoutRedirectService {
  constructor(
    @Inject(CookieService) private cookieService: CookieService,
    @Inject(Window) private window,
  ) {}

  get logoutRedirectUrl() {
    const rawUrl = this.cookieService.get(LOGOUT_REDIRECT_COOKIE);
    return rawUrl ? rawUrl.replace(/["']/g, '') : null;
  }

  handlePossibleLogoutRedirect() {
    if (this.logoutRedirectUrl) {
      this.window.location.replace(this.logoutRedirectUrl);
      return true;
    }
    return false;
  }
}
