import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxInviteStakeholderComponent } from './sbx-invite-stakeholder.component';
import { SbxEditStakeholderModule } from '../sbx-edit-stakeholder/sbx-edit-stakeholder.module';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';

@NgModule({
  imports: [
    CommonModule,
    SbxModalModule,
    SbxButtonModule,
    SbxEditStakeholderModule,
    SbxPopoverModule,
  ],
  declarations: [SbxInviteStakeholderComponent],
  exports: [SbxInviteStakeholderComponent],
})
export class SbxInviteStakeholderModule {
  static entryComponents = [SbxInviteStakeholderComponent];

  static forRoot(): ModuleWithProviders<SbxInviteStakeholderModule> {
    return {
      ngModule: SbxInviteStakeholderModule,
    };
  }
}
