import { Injectable } from '@angular/core';
import { Observable, filter, BehaviorSubject, map } from 'rxjs';
import { ISbxWsAdapter, IWSMessage, IWSTopics } from './sbx-ws.interface';

export interface IAutobahnMessageModel {
  readonly topic: string;
  readonly message: IWSMessage;
}

@Injectable({
  providedIn: 'root',
})
export class SbxWsManualAdapterService implements ISbxWsAdapter {
  private readonly topicsSubject = new BehaviorSubject<IWSTopics>({});

  public constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const testingEnvironment = window.shoobxTesting;

    if (!testingEnvironment) {
      return;
    }

    testingEnvironment.messageHandler = (args, kwargs, metadata) => {
      this.topicsSubject.next({
        ...this.topicsSubject.value,
        [metadata.topic]: {
          args,
          kwargs,
          metadata,
        },
      });
    };
  }

  public listenTopic$<TopicData>(topic: string): Observable<TopicData> {
    return this.topicsSubject.pipe(
      filter((topics: IWSTopics) => topic in topics),
      map((topics: IWSTopics) => <TopicData>topics[topic].args[0]),
    );
  }

  // eslint-disable-next-line no-empty-function
  public unregisterTopic(): void {}
}
