import { Component, ViewChild } from '@angular/core';
import { SbxAlertBannerComponent } from '../sbx-alert-banner/sbx-alert-banner.component';

interface ILoadingFlags {
  [key: string]: boolean;
}
interface IErrorFlags {
  [key: string]: string;
}

@Component({
  selector: 'sbx-page',
  templateUrl: './sbx-page.component.html',
  styleUrls: ['sbx-page.component.scss'],
})
export class SbxPageComponent {
  @ViewChild('successBanner', { static: true }) successBanner: SbxAlertBannerComponent;

  private loadingFlags: ILoadingFlags = {};
  private warningFlags: IErrorFlags = {};
  private errorFlags: IErrorFlags = {};

  setLoadingFlag(type: string) {
    this.loadingFlags[type] = true;
  }

  clearLoadingFlag(type: string) {
    this.loadingFlags[type] = false;
  }

  isLoading(type?: string) {
    if (!type) {
      return Object.values(this.loadingFlags).includes(true);
    }

    return this.loadingFlags[type];
  }

  setWarningFlag(type: string, value: string) {
    this.warningFlags[type] = value;
  }

  clearWarningFlag(type: string) {
    this.warningFlags[type] = null;
  }

  setErrorFlag(type: string, value: string) {
    this.errorFlags[type] = value;
  }

  clearErrorFlag(type: string) {
    this.errorFlags[type] = null;
  }

  getWarnings() {
    return this.warningFlags;
  }

  getWarning(type: string) {
    return this.warningFlags[type];
  }

  getErrors() {
    return this.errorFlags;
  }

  getError(type: string) {
    return this.errorFlags[type];
  }

  showSuccessBanner(text: string) {
    this.successBanner.show({ customText: text });
  }

  hasWarnings(type?: string) {
    if (!type) {
      return Object.values(this.warningFlags).some((e) => e !== null);
    }

    return this.warningFlags[type] !== null;
  }

  hasErrors(type?: string) {
    if (!type) {
      return Object.values(this.errorFlags).some((e) => e !== null);
    }

    return this.errorFlags[type] !== null;
  }
}
