/**
 * @ngdoc component
 * @name sb.lib.boostrap.component:sbCollapsedActions
 *
 * @description
 * Standard widget to have a "dropdown of options" style button. Input is given
 * via transclusion slots of `<items>`, `<button-content>`, and `<custom-trigger>`. You may add the attribute
 * `sb-collapse-action` to make the listing automatically close on click of one of the
 * items. See the example:
 *
 * @param {boolean} [open=false] Open state of the drop down (two way data-binding).
 *
 * @example
   <sb-collapsed-actions>
     <button-content>
       <i class="fa fa-edit"></i>More Edit Options
     </button-content>

     <items>
       <a href="#" sb-collapse-action>One</a>
       <a href="#">Two</a>
     </items>
   </sb-collapsed-actions>
 */
export const sbCollapsedActions = {
  template: require('./templates/collapsed-actions.html'),
  controllerAs: 'vm',
  bindings: {
    disabled: '<?',
    open: '=?',
    buttonClass: '@?',
    disabledClickOutside: '<?',
  },
  transclude: {
    // Only use one of these! If both are used then only custom trigger will show.
    customTrigger: '?customTrigger',
    buttonContent: '?buttonContent',
    items: 'items',
  },
  controller: [
    '$scope',
    '$element',
    '$document',
    '$transclude',
    function ($scope, $element, $document, $transclude) {
      function toggle() {
        this.open = !this.open;
        if (this.open) {
          this.observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
          });
        }
      }

      function closeByClickOutside() {
        if (!this.disabledClickOutside) {
          this.open = false;
        }
      }

      this.setupObserver = () => {
        this.observer = new MutationObserver((records) => {
          const hasDropDown = records
            /* eslint-disable-next-line no-undef */
            .flatMap((n) => $(n.target))
            .some((n) => n.hasClass('collapsed-actions-dropdown'));

          if (!hasDropDown) {
            return this.observer.disconnect();
          }

          const dropDownMenu = $document
            .find('.dropdown.collapsed-actions-dropdown.open')
            .find('.dropdown-menu')[0];
          if (dropDownMenu) {
            return this.checkMenuPlacement(dropDownMenu);
          }
        });
      };

      this.checkMenuPlacement = (dropDownMenu) => {
        const horizontalPosition =
          Math.round(dropDownMenu.getBoundingClientRect().left) +
          dropDownMenu.offsetWidth;
        const verticalPosition =
          Math.round(dropDownMenu.getBoundingClientRect().top) +
          dropDownMenu.offsetHeight;

        if (horizontalPosition >= window.innerWidth) {
          dropDownMenu.style.right = '0';
          dropDownMenu.style.left = 'auto';
        }

        if (verticalPosition >= window.innerHeight) {
          // Places drop-down menu above hamburger icon if menu display will overflow screen height.
          dropDownMenu.style.top = `-${dropDownMenu.offsetHeight}px`;
        }

        this.observer.disconnect();
      };

      // Function to check if transclusion slot has been filled.
      this.isSlotFilled = $transclude.isSlotFilled;
      this.$onInit = () => {
        this.toggle = toggle.bind(this);
        this.closeByClickOutside = closeByClickOutside.bind(this);
        this.disabled = this.disabled || false; // So a watcher ends
        this.buttonClass = this.buttonClass || 'btn btn-int';
        this.setupObserver();
      };
      this.$postLink = () => {
        $element.find('items').on('click', ({ target }) => {
          if (target.hasAttribute('sb-collapse-action')) {
            $scope.$apply(() => {
              this.open = false;
            });
          }
        });
      };
    },
  ],
}; // end sbCollapsedActions
