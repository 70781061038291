<div *ngIf="counselData$ | async as data" class="tab-content">
  <sbx-alert-banner
    *ngIf="data.deleted || hasError"
    [customText]="bannerMsg"
    [error]="hasError"
    [fadeTime]="5000"
  >
  </sbx-alert-banner>
  <div class="col-md-12 viewspace">
    <div class="viewspace">
      <div class="pane-title">
        <h3 i18n:translate="">Registered Legal Counsel</h3>
      </div>
      <!-- if entity has a counsel -->
      <div *ngIf="data.hasCounsel">
        <div class="row">
          <div class="col-sm-12">
            <a href="workflow-admin.html" i18n:translate="">Workflow Administration</a>
          </div>
        </div>
        <!-- if counsel is team -->
        <div class="row" *ngIf="data.isTeam">
          <div class="col-sm-2">
            <h4>{{ data.team.firmName }}</h4>
            <!-- logo -->
            <img src="{{ data.team.logo }}" class="logo" *ngIf="data.team.logo" />
          </div>
          <!-- address -->
          <div
            class="address"
            *ngIf="data.team.address"
            [innerHtml]="data.team.address"
          ></div>
          <div class="col-sm-4">
            <h4>Counsels</h4>
            <div *ngFor="let sh of data.team.stakeholders">
              <h5>{{ sh.fullName }}</h5>
              <div *ngIf="sh.email">{{ sh.email }}</div>
              <div *ngIf="sh.contact">
                <div *ngIf="sh.contact.work_phone">{{ sh.contact.work_phone }}</div>
                <div *ngIf="sh.contact.work_email">{{ sh.contact.work_email }}</div>
              </div>
              <div [innerHtml]="sh.address"></div>
            </div>
          </div>
        </div>
        <!-- if counsel is ind -->
        <div class="row" *ngIf="!data.isTeam">
          <div class="col-sm-4">
            <h4>Counsel</h4>
            <h5>{{ data.individual.fullName }}</h5>
            <div *ngIf="data.individual.email">{{ data.individual.email }}</div>
            <div *ngIf="data.individual.contact">
              <div *ngIf="data.individual.contact.work_phone">
                {{ data.individual.contact.work_phone }}
              </div>
              <div *ngIf="data.individual.contact.work_email">
                {{ data.individual.contact.work_email }}
              </div>
            </div>
            <div [innerHtml]="data.individual.address"></div>
          </div>
        </div>
        <sbx-modal-footer>
          <span class="divider">
            <sbx-button title="Remove Counsel" theme="danger" (click)="onDelete()">
            </sbx-button>
            <sbx-button
              title="Change Legal Counsel"
              theme="secondary"
              href="{{ data.changeCounselLink }}"
            >
            </sbx-button>
          </span>
        </sbx-modal-footer>
      </div>
      <!-- if entity does not have a counsel -->
      <div *ngIf="!data.hasCounsel">
        <div class="no-counsel-text">
          <i>Legal counsel has not yet been identified for your entity.</i>
        </div>
        <div
          id="add-counsel"
          class="btn btn-int"
          data-target=""
          tal:condition="python: checkActionPermission(view, 'add_counsel')"
        >
          <a
            class="icon-link"
            [href]="
              sbxUrlService.canonicalUrl(['start-process'], {
                queryParams: { name: 'add_counsel' }
              })
            "
          >
            <i class="fa fa-plus"></i>
            Register Legal Counsel
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
