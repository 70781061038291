/**
 * @ngdoc component
 * @name sb.lib.document.component:sbGenerateAndDownload
 *
 * @description
 * A button that allows user to generate a document and download it after
 * generation is complete. Genearation is started by spawning a background task.
 * Component will wait until the background task finishes and redirects
 * user to document URL on success.
 *
 * @param {string} processId The id of the document to request.
 *
 * @example
   <sb-generate-customization-report
      process-id="{{ ::process.id }}">

      Download Customization Report

   </sb-generate-customization-report>
 */
export const sbGenerateCustomizationReport = {
  controllerAs: 'vm',
  template: require('./templates/generate-customization-report.html'),
  bindings: {
    processId: '<processId',
  },
  transclude: true,
  controller: [
    '$scope',
    '$window',
    'BackendLocation',
    function ($scope, $window, BackendLocation) {
      function click() {
        const { taskStatus } = this;
        if (!taskStatus) {
          const endpoint = `${BackendLocation.entity(1)}processes/${
            this.processId
          }/generate-customization-report`;
          this.launchTask(endpoint, this.data || {});
          $scope.$emit('preparingDocument');
        } else if (taskStatus.state === 'DONE' || taskStatus.state === 'SUCCESS') {
          $window.location = taskStatus.result;
        }
      }
      $scope.$watch('vm.taskStatus', () => {
        if (
          this.taskStatus &&
          (this.taskStatus.state === 'DONE' || this.taskStatus.state === 'SUCCESS')
        ) {
          $scope.$emit('documentReady', this.taskStatus.result);
          $window.location = this.taskStatus.result;
        }
      });
      this.click = click.bind(this);
    },
  ],
}; // end sbGenerateAndDownload
