import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform, Inject } from '@angular/core';
/**
 * By pass the sanitization of the html value string.
 * @param value An html string.
 * Usage:
 *  value | html
 * Example:
 *  <h1 [innerHTML]="someHTML | html">
 */
@Pipe({ name: 'html' })
export class HtmlPipe implements PipeTransform {
  constructor(@Inject(DomSanitizer) private sanitized: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
