import angular from 'angular';
/**
 * @ngdoc directive
 * @name sb.workitem.setOwnershipAndControl.directive:sbCowMaxTotalsize
 * @restrict A
 *
 * @description
 * Validator to ensure totalSize is strictly less than or equal to
 * preMoneyValuation. Expects `dealParam.preMoneyValuation` to be used on
 * `$scope`.
 */
export function sbCowMaxTotalsize() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$validators.cowMaxTotalSize = function (value) {
        if (
          angular.isNumber(value) &&
          angular.isNumber(scope.dealParam.preMoneyValuation) &&
          value > scope.dealParam.preMoneyValuation
        ) {
          return false;
        }
        return true;
      };
    },
  };
} // end sbCowMaxTotalsize
