import angular from 'angular';
import { contactFields } from './form';

const SELECT_SIMILAR_ENTITY_ERROR = 'Please select an option.';
const STAKEHOLDER_MODAL_FORM_NOTYPE_ERROR = 'Please select a type.';
const STAKEHOLDER_MODAL_FORM_ERROR = 'Please fix the errors in the form below.';
const STAKEHOLDER_CREATION_ERROR = 'There was an error creating this user.';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.stakeholder.object:$createStakeholderModal
 *
 * @description
 * This funciton will open a stakeholder creation modal.
 *
 * @param {object} options Standard stakeholder widget options object.
 * @param {object} [seedStakeholder=undefined] Initial seed stakeholder for modal.
 *
 * @returns {Promise} Resolves with the newly created stakeholder and rejects
 *    when user dismisses.
 */
const DEF_CREATE_TITLE = 'Add User to Your Fidelity Private Shares Account';
const DEF_BUTTON_TEXT = 'Create';
export const $createStakeholderModal = [
  '$sbModal',
  function ($sbModal) {
    return (options, seedStakeholder) =>
      $sbModal.open({
        template: require('./templates/create-new-modal.html'),
        windowClass: 'stakeholder-creation-modal',
        bindToController: true,
        keyboard: false,
        backdrop: 'static',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          StakeholderOptions: () => options,
          SeedStakeholder: () => seedStakeholder || {},
        },
        controller: CreateStakeholderModalCtrl,
      }).result;
  },
]; // end $createStakeholderModal

/**
 * @ngdoc controller
 * @name sb.lib.stakeholder.controller:CreateStakeholderModalCtrl
 *
 * @description
 * This cannot be injected directly. It is the controller of
 * `$createStakeholderModal`.
 */
const CreateStakeholderModalCtrl = [
  '$scope',
  '$q',
  'Stakeholders',
  '$similarEntitiesModal',
  'StakeholderOptions',
  'SeedStakeholder',
  'PromiseErrorCatcher',
  'similarEntitiesModalHelper',
  function (
    $scope,
    $q,
    Stakeholders,
    $similarEntitiesModal,
    StakeholderOptions,
    SeedStakeholder,
    PromiseErrorCatcher,
    similarEntitiesModalHelper,
  ) {
    function exportStakeholder() {
      const { form, model } = this;
      const { type, sh } = model;
      $scope.$broadcast('StakeholderCreationForm::setSubmitted');
      this.formError = null;
      if (!type) {
        this.formError = STAKEHOLDER_MODAL_FORM_NOTYPE_ERROR;
        return;
      } else if (form.creationField.$invalid) {
        this.formError = STAKEHOLDER_MODAL_FORM_ERROR;
        return;
      }
      const entityOptions = StakeholderOptions.entityOptions || {};
      const creatorIsContact = StakeholderOptions.creatorIsContact || false;
      const forceEphemeral = StakeholderOptions.forceEphemeral || false;
      const createGuest = StakeholderOptions.createGuest || false;
      const relationshipType = entityOptions.type;
      const isTeam = type === 'team';
      const initEntityTitle = SeedStakeholder.parentTitle || SeedStakeholder.title;
      const initAffTitle = SeedStakeholder.parentTitle && SeedStakeholder.title;
      const hasAff = entityOptions.affiliates && sh.addAffiliate;
      const affName = hasAff ? sh.affiliateTitle : undefined;
      const titleChanges =
        initEntityTitle !== sh.entityTitle || (hasAff && initAffTitle !== affName);
      const noChanges =
        isTeam &&
        !titleChanges &&
        (creatorIsContact ||
          (sh.firstName === SeedStakeholder.firstName &&
            sh.lastName === SeedStakeholder.lastName &&
            sh.email === SeedStakeholder.email));
      let prom;
      // There are really two cases here.
      // 1. We have an initialized team and are adding an affiliate.
      // 2. We have a new team and/or team + affiliate
      if (isTeam && (titleChanges || !SeedStakeholder.id)) {
        const shId = SeedStakeholder.id;
        prom = $similarEntitiesModal(
          sh.entityTitle,
          affName,
          entityOptions.affiliates,
          shId,
          relationshipType,
        ).then((semData) => {
          const createData = { type, description: sh, asId: SeedStakeholder.id };
          if (!initAffTitle && hasAff && SeedStakeholder.id) {
            // Creating an affiliate stakeholder by modifying existing (entity) team stakeholder.
            createData.parentId = SeedStakeholder.foreignEntityId;
            createData.parentShId = SeedStakeholder.id;
          }

          return similarEntitiesModalHelper(semData, createData);
        });
      } else if (noChanges) {
        // If there was no change to any of the information and we've already gone through
        // similarity checks.
        this.$close(SeedStakeholder);
        return;
      } else {
        // Person or Team with no title changes
        prom = $q.when({ description: sh, type, asId: SeedStakeholder.id });
      }
      const createFn = angular.isFunction(StakeholderOptions.mockCreate)
        ? (...args) => $q.when(StakeholderOptions.mockCreate(...args))
        : Stakeholders.create.bind(Stakeholders);
      const createSh = (createData) =>
        createFn(
          createData,
          relationshipType,
          createData.asId,
          creatorIsContact,
          forceEphemeral,
          createGuest,
        ).catch((e) => {
          this.formError = e.message || STAKEHOLDER_CREATION_ERROR;
          return $q.reject();
        });
      prom
        .then(createSh)
        .then((sh) => {
          this.$close(sh);
        })
        .catch(PromiseErrorCatcher);
      return prom;
    }

    this.model = {
      type: SeedStakeholder.type,
    };

    this.teamSeed = {
      addAffiliate: Boolean(SeedStakeholder.parentTitle),
      entityTitle: SeedStakeholder.parentTitle || SeedStakeholder.title,
      affiliateTitle: SeedStakeholder.parentTitle && SeedStakeholder.title,
      firstName: SeedStakeholder.contactFirstName,
      lastName: SeedStakeholder.contactLastName,
      email: SeedStakeholder.contactEmail,
      email2: SeedStakeholder.contactEmail,
      readOnly: !SeedStakeholder.ephemeral && SeedStakeholder.type === 'team',
      shId: SeedStakeholder.id,
    };
    this.personSeed = {
      firstName: SeedStakeholder.firstName,
      lastName: SeedStakeholder.lastName,
      email: SeedStakeholder.email,
      email2: SeedStakeholder.email,
      shId: SeedStakeholder.id,
    };
    this.stakeholders = Stakeholders;
    this.options = StakeholderOptions;
    this.title = StakeholderOptions.creationModalTitleText || DEF_CREATE_TITLE;
    this.buttonText = StakeholderOptions.creationModalButtonText || DEF_BUTTON_TEXT;
    this.exportStakeholder = exportStakeholder.bind(this);
  },
]; // end CreateStakeholderModalCtrl

export const similarEntitiesModalHelper = [
  function () {
    return (data, createData) => {
      const {
        selectedEntity,
        selectedAffiliate,
        rejectedEntitySuggestions,
        rejectedAffiliateSuggestions,
      } = data;
      const sh = createData.description;
      if (selectedAffiliate) {
        createData.entityId = selectedAffiliate.id;
        createData.parentId = selectedEntity.id;
        if (selectedAffiliate.shId) {
          createData.sh = { id: selectedAffiliate.shId };
        }
        createData.entityShId = selectedAffiliate.shId;
        sh.addAffiliate = true;
        sh.entityTitle = selectedEntity.title;
        sh.affiliateTitle = selectedAffiliate.title;
      } else if (selectedEntity) {
        if (sh.addAffiliate) {
          createData.parentId = selectedEntity.id;
          createData.parentShId = selectedEntity.shId;
          // Selected to create a new affiliate
          createData.asId = null;
        } else {
          createData.entityId = selectedEntity.id;
          if (selectedEntity.shId) {
            createData.sh = { id: selectedEntity.shId };
          }
        }
        sh.entityTitle = selectedEntity.title;
      }

      const rejEntityIds = rejectedEntitySuggestions.map((ob) => {
        return ob.id ? ob.id : null;
      });
      const rejAffIds = rejectedAffiliateSuggestions.map((ob) => {
        return ob.id ? ob.id : null;
      });
      createData.similarityWhitelist = rejEntityIds
        .concat(rejAffIds)
        .filter((ob) => ob !== null);
      // The implicit else for all of these conditions is that
      // `createData.description` will be what makes the stakeholder.
      return createData;
    };
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.stakeholder.object:$similarEntitiesModal
 *
 * @description
 * Calling this function will return a high level async promise for
 * prompting the user to select a similar entities to a given input.
 *
 * @param {string} entityTitle The title of the entity sought.
 * @param {string} [affiliateTitle=undefined] An optional affiliate argument to
 *    also provide similarity match for.
 * @param {bool} [includeAffiliates=undefined] Boolean indicating whether to
 *    include affiliates in similar entities suggestions.
 *
 * @returns {Promise} Resolves with an object that has either an array of
 *    `rejectedSelections` indicating the user did not pick an existing (might be
 *    empty) or a `selectedEntity` object of their selection. If `selectedEntity`
 *    is a `selectedAffiliate` might also be present. This promise will reject if the
 *    user cancels.
 */
export const $similarEntitiesModal = [
  '$q',
  '$sbModal',
  'Stakeholders',
  function ($q, $sbModal, Stakeholders) {
    return (
      entityTitle,
      affiliateTitle,
      includeAffiliates,
      shId,
      rtype,
      securityTicketID,
    ) => {
      return Stakeholders.findSimilarEntities(
        entityTitle,
        affiliateTitle,
        includeAffiliates,
        rtype,
        securityTicketID,
      ).then((similar) => {
        let sims;
        if (shId) {
          // Remove the stakeholder from the list to make sure it isn't the only one.
          sims = similar.filter((x) => x.shId !== shId || x.id);
        } else {
          sims = similar;
        }
        if (!sims || !sims.length) {
          return $q.resolve({
            rejectedEntitySuggestions: [],
            rejectedAffiliateSuggestions: [],
          });
        }
        const modal = $sbModal.open({
          windowClass: 'similar-team-sh-modal',
          bindToController: true,
          controllerAs: 'vm',
          controller: SimilarEntitiesModalCtrl,
          template: require('./templates/similar-entities-modal.html'),
          resolve: {
            SearchedNames: () => ({ affiliateTitle, entityTitle }),
            SimilarEntities: () => similar,
          },
        });
        return modal.result.then(
          ({ entityId, affiliateId }) => {
            const selectedEntity = entityId
              ? similar.find((sh) => sh.key === entityId)
              : undefined;
            const { similarAffiliates } = selectedEntity || {};
            const selectedAffiliate =
              affiliateId && similarAffiliates
                ? similarAffiliates.find((sh) => sh.key === affiliateId)
                : undefined;
            const simAffs = similarAffiliates ? similarAffiliates : [];
            const rejectedEntitySuggestions = entityId
              ? similar.filter((sh) => sh.key !== entityId)
              : similar;
            const rejectedAffiliateSuggestions = affiliateId
              ? simAffs.filter((sh) => sh.key !== affiliateId)
              : simAffs;
            return {
              selectedEntity,
              selectedAffiliate,
              rejectedEntitySuggestions,
              rejectedAffiliateSuggestions,
            };
          },
          () => $q.reject(),
        ); // Null out any dismissal reason.
      });
    };
  },
]; // end $similarEntitiesModal

/**
 * @ngdoc controller
 * @name sb.lib.stakeholder.controller:SimilarEntitiesModalCtrl
 *
 * @description
 * This cannot be injected directly. It is the controller of
 * `$similarEntitiesModal`.
 */
const SimilarEntitiesModalCtrl = [
  '$scope',
  'SimilarEntities',
  'SearchedNames',
  function ($scope, SimilarEntities, SearchedNames) {
    function submit() {
      this.errorText = null;
      const { entityId, affiliateId } = this.model;
      const invalidAffiliate =
        SearchedNames.affiliateTitle && !angular.isString(affiliateId);
      if (!angular.isString(entityId) || invalidAffiliate) {
        this.errorText = SELECT_SIMILAR_ENTITY_ERROR;
        return;
      }
      this.$close({ entityId, affiliateId });
    }

    this.searchedAffiliateName = SearchedNames.affiliateTitle;
    this.searchedEntityName = SearchedNames.entityTitle;
    this.similarEntities = SimilarEntities;

    this.similarAffiliatesForEntity = SimilarEntities.reduce((accum, ent) => {
      accum[ent.key] = ent.similarAffiliates;
      return accum;
    }, {});
    this.model = {};
    this.submit = submit.bind(this);

    $scope.$watch('vm.model.entityId', (nv) => {
      // Every time we change entity model, we want to recompute if there are
      // affiliates or not and make an easy lookup for the currently selected entity.
      const similarAffs = this.similarAffiliatesForEntity[nv];
      this.hasAffiliates = Boolean(similarAffs && similarAffs.length);
      this.selectedEntity = this.similarEntities.find((sh) => sh.key === nv);
      // If this change of entity model makes it so we have affiliates, we have to
      // unset the model so the user can "reselect." Otherwise, if there are
      // no affiliates, we can just make it '' (meaning new).
      this.model.affiliateId = this.hasAffiliates ? undefined : '';
    });
  },
]; // end SimilarEntitiesModalCtrl

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.stakeholder.object:$addEmailModal
 *
 * @description
 * This funciton will open a stakeholder-email modal.
 *
 * @param {object} options Standard stakeholder widget options object.
 * @param {object} [seedStakeholder=undefined] Initial seed stakeholder for modal.
 *
 * @returns {function} Resolves with the newly created stakeholder and rejects
 *    when user dismisses.
 */
export const $addEmailModal = [
  '$formModal',
  'Stakeholders',
  function ($formModal, Stakeholders) {
    return (sh) =>
      $formModal({
        title: 'Provide User Email Address',
        htmlContent: require('./templates/add-email.html'),
        windowClass: 'add-email',
        controllerAs: 'vm',
        forms: { addEmail: { fields: contactFields(sh.teamStakeholder, true, false) } },
        formData: {
          addEmail: {
            isTeamSh: sh.teamStakeholder,
            email: sh.teamStakeholder ? sh.contactEmail : sh.email,
            email2: sh.teamStakeholder ? sh.contactEmail : sh.email,
            firstName: sh.teamStakeholder ? sh.contactFirstName : sh.firstName,
            lastName: sh.teamStakeholder ? sh.contactLastName : sh.lastName,
          },
        },
        onConfirmPromise: ({ $formData }) => {
          return Stakeholders.addContactInfo(sh.id, $formData.addEmail).then(() => {
            if (sh.teamStakeholder) {
              sh.contactEmail = $formData.addEmail.email;
              sh.contactFirstName = $formData.addEmail.firstName;
              sh.contactLastName = $formData.addEmail.lastName;
            } else {
              sh.email = $formData.addEmail.email;
              sh.firstName = $formData.addEmail.firstName;
              sh.lastName = $formData.addEmail.lastName;
              sh.fullName = `${sh.firstName} ${sh.lastName}`;
            }
          });
        },
      });
  },
]; // end $addEmailModal
