import angular from 'angular';
import { fromEvent, of, animationFrameScheduler, Observable } from 'rxjs';
import {
  observeOn,
  pairwise,
  takeUntil,
  switchMap,
  map,
  share,
  mergeWith,
  startWith,
  tap,
} from 'rxjs/operators';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.events.directive:$scrollProps
 *
 * @description
 * This is a injectable function for easy dimension computations. It is primarily to
 * make the `sbScrollable` directive completely testable.
 *
 * @param {DOM} nativeElement The element to compute properties on (native, not jq
 *   element).
 * @param {string} dimensionSuffix The dimension of concern (eg `Height` or `Width`).
 * @param {string} positionSuffix The position of councern (eg `Left` or `Top`).
 *
 * @returns {object} Browser computed properties of:
 *   @property {number} current The current (viewable) dimension size.
 *   @property {number} total The total dimension of the element.
 *   @property {number} scroll The total dimension of the element.
 */
export const $scrollProps = [
  function () {
    return (nativeElement, dimensionSuffix, positionSuffix) => ({
      current: nativeElement[`client${dimensionSuffix}`],
      total: nativeElement[`scroll${dimensionSuffix}`],
      scroll: nativeElement[`scroll${positionSuffix}`],
    });
  },
]; // end $scrollProps

/**
 * @ngdoc directive
 * @name sb.lib.events.directive:sbScrollable
 * @restrict A
 *
 * @description
 * Add this directive to any container to make it have shoobx scrollbars.
 * Must have one child element container (due to CSS).
 *
 * @param {expression} [sbScrollable=undefined] You may optionally define an
 *   expression that will be `$watch`ed by this directive. The scrollbars will be
 *   updated when this expression is changed. Since the browser does not have a
 *   "reflow" event, use this expression to "hint" that the scrollbars need be
 *   to be updated. For instance, if a list of items, the items change. This
 *   is yet another reason to use immutable datastrutures.
 * @param {any} [horizontal=undefined] If this property exists on the element,
 *   an X (honrizontal) scrollbar will be available to the element too.
 * @param {any} [windowResizeComputed=undefined] If this property exists on the
 *   element, scrollbar will also be recomputed when window resizes (use this on
 *   elements where the height is dynamic to the windows size).
 *
 * @example
   <section sb-scrollable="vm.items" height="300px;">
     <ul>
       <li ng-repeat="item in vm.items track by item.id">
         {{ item.name }}
       </li>
     </ul>
   </section>
*/
export const sbScrollable = [
  '$window',
  '$animate',
  '$scrollProps',
  function ($window, $animate, $scrollProps) {
    function computeScrollBarDimensions(
      scrolledElement,
      scrollBarElement,
      dimensionSuffix,
      positionSuffix,
    ) {
      const { current, total, scroll } = $scrollProps(
        scrolledElement,
        dimensionSuffix,
        positionSuffix,
      );
      const scrollRatio = current / total;
      if (scrollRatio >= 1) {
        scrollBarElement.classList.remove('visible');
      } else {
        const dim = scrollRatio * 100;
        const pos = (scroll / total) * 100;
        scrollBarElement.style.cssText = `
        ${dimensionSuffix.toLowerCase()}: ${dim}%;
        ${positionSuffix.toLowerCase()}: ${pos}%;
      `;
        scrollBarElement.classList.add('visible');
      }
    }
    function wireScrollBar(
      elementDestroy$,
      extraTriggers,
      scrolledElement,
      yScrollBarElement,
      xScrollBarElement,
    ) {
      fromEvent(scrolledElement, 'scroll')
        .pipe(
          mergeWith(extraTriggers),
          takeUntil(elementDestroy$),
          observeOn(animationFrameScheduler),
        )
        .subscribe(() => {
          computeScrollBarDimensions(
            scrolledElement,
            yScrollBarElement,
            'Height',
            'Top',
          );
          if (xScrollBarElement) {
            computeScrollBarDimensions(
              scrolledElement,
              xScrollBarElement,
              'Width',
              'Left',
            );
          }
        });
    }
    function dragBar(
      elementDestroy$,
      scrolledElement,
      scrollBarElement,
      mouseSuffix,
      dimensionSuffix,
      positionSuffix,
    ) {
      // On mouseDown on the scrollbar, we listen to all the mousemove events on
      // window until windo reports a mouse up event, emitting deltas as we go.
      fromEvent(scrollBarElement, 'mousedown')
        .pipe(
          tap(() => {
            // Add the no selection class so the user doesn't highlight the whole page.
            // Also nuke any selection they might have so they don't drag things
            $window.getSelection().removeAllRanges();
            $window.document.body.classList.add('no-selection');
          }),
          switchMap((startingMouseDownEvt) => {
            const mouseUpEvents = fromEvent($window.document, 'mouseup').pipe(
              tap(() => {
                $window.document.body.classList.remove('no-selection');
              }),
            );
            return fromEvent($window.document, 'mousemove').pipe(
              startWith(startingMouseDownEvt),
              pairwise(),
              map(
                ([prv, cur]) => cur[`page${mouseSuffix}`] - prv[`page${mouseSuffix}`],
              ),
              takeUntil(mouseUpEvents),
            );
          }),
          takeUntil(elementDestroy$),
          observeOn(animationFrameScheduler),
        )
        .subscribe((delta) => {
          const { current, total } = $scrollProps(
            scrolledElement,
            dimensionSuffix,
            positionSuffix,
          );
          scrolledElement[`scroll${positionSuffix}`] += delta / (current / total);
        });
    }
    function wireDragging(
      elementDestroy$,
      scrolledElement,
      yScrollBarElement,
      xScrollBarElement,
    ) {
      dragBar(
        elementDestroy$,
        scrolledElement,
        yScrollBarElement,
        'Y',
        'Height',
        'Top',
      );
      if (xScrollBarElement) {
        dragBar(
          elementDestroy$,
          scrolledElement,
          xScrollBarElement,
          'X',
          'Width',
          'Left',
        );
      }
    }

    // When we instantiate the first sbScrollable, create a div and compute the
    // width of the browser's native scrollbar.
    const testDiv = $window.document.createElement('div');
    testDiv.style.cssText = `
      overflow: scroll; height: 100px; width: 100px;
      top: -999px; position: absolute;
  `;
    $window.document.body.appendChild(testDiv);
    const nativeScrollbarWidth = testDiv.offsetWidth - testDiv.clientWidth;
    $window.document.body.removeChild(testDiv);

    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const scrolledElement = element.children()[0];
        const yScrollBarElement = angular.element(
          '<div class="scrollbar scrollbar-y"></div>',
        )[0];
        const negativeNativeOffset = -nativeScrollbarWidth + 'px';
        if (nativeScrollbarWidth <= 0) {
          // Some macs have no native scrollbar width (they overlay the
          // scrollbar on the content).
          scrolledElement.style.right = '-15px';
          scrolledElement.style.paddingRight = '15px';
        } else {
          scrolledElement.style.right = negativeNativeOffset;
        }
        element.append(yScrollBarElement);

        let xScrollBarElement;
        if (angular.isDefined(attrs.horizontal)) {
          [xScrollBarElement] = angular.element(
            '<div class="scrollbar scrollbar-x"></div>',
          );
          if (nativeScrollbarWidth <= 0) {
            // Mac
            scrolledElement.style.bottom = '-15px';
            scrolledElement.style.paddingBottom = '15px';
          } else {
            scrolledElement.style.bottom = negativeNativeOffset;
          }
          element.append(xScrollBarElement);
        }

        const elementDestroy$ = new Observable((ob) => {
          element.on('$destroy', () => {
            ob.next();
            ob.complete();
          });
        }).pipe(share());

        wireDragging(
          elementDestroy$,
          scrolledElement,
          yScrollBarElement,
          xScrollBarElement,
        );

        // Since there is no "redraw"/reflow event, we can't know excatly when the
        // contents will resize. Angular probably is changing DOM all the time (likely
        // we are the scrollbar on an Angular populated list for instance).
        // This is where we utilize our "hint" parameter. Everytime the `$watch` on
        // that expression fires, we will trigger a custom made observable that
        // is animation aware. If we are not given a hint, we just fire a once
        // and done observable (for init).
        let extraTriggers = of(true);
        if (attrs.sbScrollable) {
          extraTriggers = new Observable((ob) =>
            scope.$watch(attrs.sbScrollable, () => ob.next()),
          ).pipe(
            switchMap(
              () =>
                new Observable((ob) => {
                  const callback = () => ob.next();
                  // I'm a nervous person and I feel like this will not always fire,
                  // so let's callback at least once without the `$animate` service.
                  // (Even in my testing, the service did make the callback even
                  // when, say, the element did not have a transition CSS property).
                  callback();
                  $animate.on('leave', element, callback);
                  $animate.on('enter', element, callback);
                  return () => {
                    $animate.off('leave', element, callback);
                    $animate.off('enter', element, callback);
                  };
                }),
            ),
          );
        }
        if (angular.isDefined(attrs.windowResizeComputed)) {
          extraTriggers = extraTriggers.pipe(mergeWith(fromEvent($window, 'resize')));
        }
        wireScrollBar(
          elementDestroy$,
          extraTriggers,
          scrolledElement,
          yScrollBarElement,
          xScrollBarElement,
        );
      },
    };
  },
]; // end sbScrollable

/**
 * @ngdoc directive
 * @name sb.lib.events.directive:sbScrollTo
 * @restrict A
 *
 * @description
 * Add this directive to an anchor tag to make the click event
 * scroll to a particular element on the page.
 *
 * @param {template} sbScrollTo ID name of the element to scroll to.
 * @param {number} [sbScrollToOffset=0] Number of pixels to offset the scroll
 *    by *NOTE:* considered one time binding.
 *
 * @example
   <a href="#" data-sb-scroll-to="SomeDiv" data-sb-scroll-to-offset="-200">
     Clicking this link will scroll to 200px above #SomeDiv.
   </a>

   <div id="SomeDiv">
     Some Div!!
   </div>
 */
export const sbScrollTo = [
  '$parse',
  function ($parse) {
    return {
      link(scope, element, attrs) {
        const $body = angular.element('html, body');
        const offset = $parse(attrs.sbScrollToOffset)(scope) || 0;
        element.click((evt) => {
          const $target = angular.element('#' + attrs.sbScrollTo);
          let scrollTo = $target.offset().top + offset;
          scrollTo = scrollTo >= 0 ? scrollTo : 0;
          $body.stop().animate({ scrollTop: scrollTo + 'px' }, 250, 'swing');
          evt.preventDefault();
        });
      },
    };
  },
]; // end sbScrollTo

/**
 * @ngdoc directive
 * @name sb.lib.events.directive:sbScrollNotAtBottom
 * @restrict A
 *
 * @description
 * Add this directive on to the page to make some content show up when the user
 * is *not* at the bottom of the screen.
 *
 * @example
   <div data-sb-scroll-not-at-bottom>
    This content will only show up if the user is not at the bottom of the
    scroll.
   </div>
 */
export const sbScrollNotAtBottom = [
  '$window',
  '$document',
  'DebounceMaker',
  'sbxZoneService',
  function ($window, $document, DebounceMaker, sbxZoneService) {
    const EVTS = 'scroll resize webkitTransitionEnd msTransitionEnd transitionend';
    const DEBOUNCE_WAIT = 100;
    const BOTTOM_EPSILON = 3;
    return {
      restrict: 'A',
      link(scope, element) {
        /**
         * For performance reasons, this directive does not use an $apply.
         * It also debounces the callback at DEBOUNCE_WAIT rate so that it
         * only does the height computations every so often.
         */
        const $jWindow = angular.element($window);
        const debounce = DebounceMaker(false); // No $apply.

        function hideShowFade() {
          const scrollFromTop = $jWindow.scrollTop();
          const windowPortSize = $jWindow.height();
          const docHeight = $document.height();
          const distanceFromBottom = docHeight - (scrollFromTop + windowPortSize);
          if (distanceFromBottom < BOTTOM_EPSILON) {
            element.hide();
          } else {
            element.show();
          }
        }
        function callback() {
          // Run debouncer outside angular 7 to avoid infinite cycles
          // in hybrid angular 1 + 7 pages.
          //
          // This callback is plugged into $digest cycle directly via
          // "scope.$watch(() => callback())".
          // setTimeout is a macro task in angular 7 zone,
          // and when all macro tasks are done, angular 7's checkStable
          // fires a global event to inform of that.
          // The event triggers another angular 1 digest, that schedules
          // a new debouncer, thus entering an infinite cycle.
          sbxZoneService
            .getZone()
            .runOutsideAngular(() => debounce(hideShowFade, DEBOUNCE_WAIT));
        }

        $jWindow.on(EVTS, callback);
        // Plug into the $digest cycle since height might change dynamically.
        scope.$watch(() => callback());
        scope.$on('$destroy', () => {
          $jWindow.off(EVTS, callback);
        });
        hideShowFade();
      },
    };
  },
]; // end sbScrollNotAtBottom
