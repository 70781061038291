/**
 * @ngdoc component
 * @name sb.userAccessLog.sbUserAccessLog
 *
 * @description
 * This element will display the activity logs table with load more button.
 *
 */
export const sbPaylocityLog = {
  controllerAs: 'vm',
  template: require('./templates/paylocity-log.html'),
  controller: [
    'BackendLocation',
    'LoadMoreModel',
    'EmployeeChanges',
    class {
      constructor(BackendLocation, LoadMoreModel, EmployeeChanges) {
        // Make this model not shared between components
        this.model = Object.assign({}, LoadMoreModel);

        this.model.initialise(BackendLocation.entity(1) + 'paylocity/log');

        this.model.loadMore();

        EmployeeChanges.subscribe(() => {
          this.model.refresh();
        });
      }
    },
  ],
};
