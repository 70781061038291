import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 *
 *   <sbx-paging [page]="currentPage" [size]="itemsPerPage" [totalItems]="total"
 *               (change)="onPage($event)"></sbx-paging>
 *
 */
@Component({
  selector: 'sbx-paging',
  templateUrl: './sbx-paging.component.html',
  styleUrls: ['./sbx-paging.component.scss'],
})
export class SbxPagingComponent {
  @Input() totalItems: number;
  @Input() size: number;
  @Input() page: number;
  @Output() change = new EventEmitter<number>();

  /* Total number of pages */
  get pages(): number {
    if (!this.totalItems) {
      return 0;
    }
    let pages = Math.floor(this.totalItems / this.size);
    if (this.totalItems % this.size) {
      pages++;
    }
    return pages;
  }

  get inputWidth(): number {
    return Math.ceil(Math.log10(this.pages)) + 1;
  }

  previous(): boolean {
    return this.page > 0;
  }

  next(): boolean {
    return this.page < this.pages - 1;
  }

  flipPage(newpage: number): void {
    this.change.emit(newpage);
  }

  handleInput(evt: Event): void {
    evt.stopPropagation();
    evt.preventDefault();

    // 1-based ordinal
    const target = evt.target as HTMLInputElement;
    const newpage = parseInt(target.value);
    if (newpage >= 1 && newpage <= this.pages) {
      this.flipPage(newpage - 1);
    } else {
      target.value = (this.page + 1).toString();
    }
  }
}
