import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import {
  handleRTValidationBlur,
  handleRTValidationFocus,
} from '../utils/real-time-validation-handler.util';

@Component({
  selector: 'sbx-form-field',
  templateUrl: './sbx-form-field.component.html',
  styleUrls: ['./sbx-form-field.component.scss'],
})
export class SbxFormFieldComponent extends FieldWrapper implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (this.to.prefix && !(this.to.prefix instanceof Object)) {
      this.to.prefix = { text: this.to.prefix };
    }
    
    if (this.to.postfix && !(this.to.postfix instanceof Object)) {
      this.to.postfix = { text: this.to.postfix };
    }

    if (this.to.readOnly) {
      this.formControl.disable();
    }

    const f = this.to.focus;
    this.to.focus = (field, event) => handleRTValidationFocus(field, event, f);

    const b = this.to.blur;
    this.to.blur = (field, event) => handleRTValidationBlur(field, event, b);
  }
}
