import { Component, Inject, Input } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxCaptableService } from '@/sbx-captable/sbx-captable.service';
import { CaptableOptions } from '@shoobx/types/webapi-v2';
import { MessagesService } from '@/shared/sbx-message/messages.service';
import { MessageSeverity } from '@/shared/sbx-message/message-severity';

const SELECTOR = 'sbx-captable-download';

/**
 * Use it with SbxMessagesComponent to show error messages.
 * These components were designed to work together in,
 * thanks to that you can display errors in the appropriate place in the template.
 *
 * Example:
 * <sbx-messages></sbx-messages>
 * <div>
 *     ...
 * </div>
 * <sbx-captable-download></sbx-captable-download>
 */

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-captable-download.component.html',
})
export class SbxCaptableDownloadComponent {
  @Input() options: CaptableOptions = {};

  loading = false;
  error = false;

  errorMessage = {
    text: 'There was an error generating the cap table for download.',
    severity: MessageSeverity.ERROR,
    closable: true,
  };

  constructor(
    @Inject(SbxCaptableService) private captableService: SbxCaptableService,
    private readonly messagesService: MessagesService,
  ) {}

  async downloadCaptable() {
    this.messagesService.removeMessage(this.errorMessage);
    this.loading = true;

    try {
      const downloadUrl = await this.captableService.export(this.options);
      window.location.assign(downloadUrl);
    } catch {
      this.messagesService.addMessage(this.errorMessage);
    } finally {
      this.loading = false;
    }
  }
}
