import angular from 'angular';

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbDictionary
 *
 * @description
 * This directive is intended for models of dictionary type. The keys are
 * generated from enums (dropdowns), while values can be arbitrary.
 *
 * @param {expression} ngModel Model expression. Models are arrays of objects with
 *   property pairs of `key` and `value`.
 * @param {template} name Name of the widget use for select name attributes.
 * @param {array} errors Array of error messages.
 * @param {object} valueType This is the field desciption of the value property.
 * @param {array<Object>} [keyOptions=undefined] Array of possible options for keys.
 *   Comes in the standard form of `{ label: 'Label', value: '123' }`.
 * @param {object} [keyType=undefined] A field description for the keys. When its readonly,
 *   keys are treated as "frozen"
 * @param {template} [valueLabel=undefined] Label for value column.
 * @param {template} [keyLabel=undefined] Label for key column.
 * @param {boolean} [sbDictionaryFrozenKeys=false] When truthy, hides the remove
 *    and add buttons as well as makes the keys readonly.
 */
export const sbDictionary = {
  controllerAs: 'vm',
  template: require('./templates/widgets/dictionary.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    name: '@',
    errors: '<',
    valueType: '<',
    keyOptions: '<?',
    keyType: '<?',
    frozenKeys: '<?',
    valueLabel: '@?',
    keyLabel: '@?',
    context: '<',
  },
  controller: [
    function () {
      function usedKeysLookup(items) {
        return (items || []).reduce((accum, item) => {
          accum[item.key] = true;
          return accum;
        }, {});
      }
      function createItemViewValue(key, value, index, usedKeys) {
        const id = `${this.name}-${index}-value`;
        return {
          key,
          value,
          valueOptions: Object.assign({}, this.valueType, { key: 'value', id }),
          filteredKeys: this.keyOptions.filter(
            ({ value }) => value === key || !usedKeys[value],
          ),
        };
      }
      function parseViewValue(viewValue) {
        return viewValue.map((item) => ({
          key: item.key,
          value: item.value,
        }));
      }
      function formatModelValue(modelValue) {
        modelValue = modelValue || [];
        const usedKeys = usedKeysLookup(modelValue);
        return modelValue.map(({ key, value }, index) =>
          createItemViewValue.call(this, key, value, index, usedKeys),
        );
      }
      function removeInput(index) {
        const filtered = this.ngModelCtrl.$viewValue.filter((item, i) => i !== index);
        const usedKeys = usedKeysLookup(filtered);
        const newVal = filtered.map(({ key, value }, index) =>
          createItemViewValue.call(this, key, value, index, usedKeys),
        );
        this.ngModelCtrl.$setViewValue(newVal);
      }
      function addInput() {
        const viewValue = this.ngModelCtrl.$viewValue;
        let usedKeys = usedKeysLookup(viewValue);
        const newItemKey = this.keyOptions.find(({ value }) => !usedKeys[value]).value;
        usedKeys = Object.assign({}, usedKeys, { [newItemKey]: true });
        const newVal = viewValue
          .concat([{ key: newItemKey, value: this.valueType.defaultValue }])
          .map(({ key, value }, index) =>
            createItemViewValue.call(this, key, value, index, usedKeys),
          );
        this.ngModelCtrl.$setViewValue(newVal);
      }
      function change() {
        // We slice/copy here so that angular will not skip the formatting.
        this.ngModelCtrl.$setViewValue(this.ngModelCtrl.$viewValue.slice());
      }

      this.addInput = addInput.bind(this);
      this.removeInput = removeInput.bind(this);
      this.change = change.bind(this);
      this.$onInit = () => {
        this.valueType.templateOptions.onChange = this.change;
        this.viewItemsModel = {};
        this.frozenKeys = Boolean(
          this.keyType && this.keyType.templateOptions.readOnly,
        );
        this.keyOptions = this.keyOptions || [];
      };
      this.$postLink = () => {
        this.ngModelCtrl.$parsers.push(parseViewValue.bind(this));
        this.ngModelCtrl.$formatters.push(formatModelValue.bind(this));
        if (this.ngModelCtrl.$validators.required) {
          this.ngModelCtrl.$validators.required = (modelValue) => {
            if (!modelValue || !modelValue.length) {
              return false;
            }
            return modelValue.every((item) => item.value);
          };
        }
      };
    },
  ],
}; // end sbDictionary

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbCheckList
 * @restrict EA
 * @requires https://docs.angularjs.org/api/ng/type/ngModel.NgModelController
 *
 * @description
 * This directive is intended for models of array type. It is similar to {@link
 * sb.lib.form.directive:sbList sbList} but has a more "set" feel. Items of
 * `sbCheckListValueOptions` are displayed as checkboxes and checking an item
 * adds the item to the model's array.
 *
 * @element ANY
 * @param {expression} sbCheckListErrors Array of field error messages.
 * @param {template} sbCheckListName Name of the widget.
 * @param {bool} sbSingleSelect True will make max of one element selectable
 * @param {expression} sbCheckListValueOptions Array of possible options for values.
 *    Comes in the standard form of `{ label: 'Label', value: '123' }`.
 * @param {expression} [sbCheckListRequired=false] Bool if this list is required.
 * @param {expression} sbCheckListShowSelectAll Bool if select all button should be shown
 *
 * @example
   <div
      data-sb-check-list
      data-ng-model="model"
      data-sb-check-list-required="true"
      data-sb-check-list-errors="fieldErrors()"
      data-sb-check-list-value-options="[{ label: 'one', value: '1'}]">
    </div>
 */
export function sbCheckList() {
  return {
    restrict: 'EA',
    template: require('./templates/widgets/checklist.html'),
    require: 'ngModel',
    scope: {
      model: '=ngModel',
      type: '@?sbOptionsType',
      errors: '&sbCheckListErrors',
      name: '@sbCheckListName',
      singleSelect: '<?sbSingleSelect',
      valueOptions: '&sbCheckListValueOptions',
      required: '&sbCheckListRequired',
      showSelectAll: '&sbCheckListShowSelectAll',
    },
    link: function (scope, element, attrs, ngModelCtrl) {
      scope.type = scope.type || 'check';

      ngModelCtrl.$render = angular.noop;
      ngModelCtrl.$validators.required = (modelValue) => {
        if (
          scope.required() &&
          (!angular.isArray(modelValue) || modelValue.length === 0)
        ) {
          return false;
        }
        return true;
      };
      ngModelCtrl.$parsers.push((value) => {
        const modelValue = [];
        angular.forEach(value, (vItem) => {
          modelValue.push(vItem.value);
        });
        return modelValue;
      });
      ngModelCtrl.$formatters.push((value) => {
        const viewValue = [];
        scope.internalModel = {};
        angular.forEach(value, (mItem, i) => {
          scope.internalModel[mItem] = true;
          viewValue.push({ value: mItem, id: i });
        });

        return viewValue;
      });

      scope.internalModel = {};
      scope.selectAll = {
        model: false,
        indeterminate: false,
      };

      scope.userChange = (itemValue) => {
        const viewValue = [];
        let i = 0;
        angular.forEach(scope.internalModel, (value, key) => {
          if (value && (!scope.singleSelect || key === itemValue)) {
            viewValue.push({ value: key, id: i++ });
          }
        });
        scope.triggerBulkSelect();
        ngModelCtrl.$setViewValue(viewValue);
      };

      scope.triggerBulkSelect = () => {
        const allOptionsLength = scope.valueOptions().length;
        const checkedOptionsLength = Object.values(scope.internalModel).filter(
          (option) => option,
        ).length;
        const noneOptionChecked = !checkedOptionsLength;
        const allOptionsChecked = checkedOptionsLength === allOptionsLength;
        const isIndeterminate = !noneOptionChecked && !allOptionsChecked;

        scope.selectAll = {
          model: allOptionsChecked,
          indeterminate: isIndeterminate,
        };
      };

      scope.triggerAll = () => {
        const viewValue = [];
        let i = 0;

        angular.forEach(scope.valueOptions(), (item) => {
          scope.internalModel[item.value] = scope.selectAll.model;

          if (scope.selectAll.model) {
            viewValue.push({ value: item.value, id: i++ });
          }
        });

        scope.selectAll.indeterminate = false;

        ngModelCtrl.$setViewValue(viewValue);
      };

      if (!scope.model) {
        ngModelCtrl.$setViewValue([]);
      }
      angular.forEach(scope.model, (key) => {
        scope.internalModel[key] = true;
      });
      scope.triggerBulkSelect();
    },
  };
} // end sbCheckList

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbCheckboxGrid
 * @restrict E
 *
 * @description
 * Directive to display counsel settings grid.
 *
 * @param {expression} ngModel Model expression. The model is an object of `{
 *    <row>-<col>: <boolean> }`.
 * @param {object} sbCheckboxGridPresentation An object of "options" describing
 *    how this checkbox grid looks. Comes in the form of `{ groupLabel: <string>,
 *    groupName: <string>, cols: [<colObject>], rows: [<rowObject>] }`.
 */
export function sbCheckboxGrid() {
  function updateHeadersAndEmailColumn(scope) {
    // If Counsel Review is required, set email to true
    Object.keys(scope.data).forEach((procId) => {
      if (scope.data[procId].counsel_review_required) {
        // eslint-disable-next-line camelcase
        scope.data[procId].counsel_email = true;
      }
    });

    const allRequired = scope.presentation().rows.every((row) => {
      return scope.data[row.name].counsel_review_required === true;
    });
    const allOptional = scope.presentation().rows.every((row) => {
      return scope.data[row.name].counsel_review_required === false;
    });
    if (!allRequired && !allOptional) {
      scope.headers.allCounselRequired = undefined;
    } else if (allRequired) {
      scope.headers.allCounselRequired = true;
    } else if (allOptional) {
      scope.headers.allCounselRequired = false;
    }

    const allEmail = scope
      .presentation()
      .rows.every((row) => scope.data[row.name].counsel_email === true);
    scope.headers.allEmail = allEmail;
  }

  const HELP_TEXT = [
    {
      name: 'counsel_required',
      label: 'Review Required',
      value: 'email_notification',
      group: 'group-1',
      helpText:
        'Shoobx will email Counsel a request to review the ' +
        "workflow's documents prior to execution. Counsel's review must " +
        'be completed before the workflow can proceed. Counsel will also ' +
        'be notified via email at the end of the workflow.',
    },
    {
      name: 'counsel_optional',
      label: 'Review Optional',
      value: 'counsel_approval_required',
      group: 'group-1',
      helpText:
        'The option to request legal review will be available ' +
        'during the workflow. If selected, Shoobx will email Counsel a request' +
        " to review the documents. Counsel's review must be completed before " +
        'the workflow can proceed.',
    },
    {
      name: 'counsel_email',
      label: 'Email Notification',
      value: 'counsel_approval_optional',
      group: 'group-1',
      helpText:
        'Fidelity Private Shares will notify Counsel of workflow activity via email.',
    },
  ];
  return {
    restrict: 'E',
    require: 'ngModel',
    scope: {
      data: '=ngModel',
      presentation: '&sbCheckboxGridPresentation',
    },
    link(scope, element) {
      element.change((evt) => {
        scope.$apply(() => {
          // if event is on top row Review Required or Review Optional
          // update the data model according
          const { headerType } = evt.originalEvent;
          switch (headerType) {
            case 'allCounselRequired':
              scope.updateRows('counsel_review_required', true);
              break;
            case 'allCounselOptional':
              scope.updateRows('counsel_review_required', false);
              break;
            case 'allEmail':
              scope.updateRows('counsel_email', evt.target.checked);
              break;
          }
          updateHeadersAndEmailColumn(scope);
        });
      });
    },
    template: require('./templates/widgets/checkbox-grid.html'),
    controller: [
      '$scope',
      function ($scope) {
        $scope.headers = {};

        $scope.HELP_TEXT = HELP_TEXT;

        $scope.changeMarker = (id) => ({
          change: `$event.originalEvent.headerType = '${id}';`,
        });

        $scope.updateRows = function (attribute, value) {
          $scope
            .presentation()
            .rows.filter((row) => row.editable)
            .forEach((row) => {
              $scope.data[row.name][attribute] = value;
            });
        };

        $scope.$watch('data', () => {
          // only fires when data model is fully replaced with a new object
          // ie: Page Load and on Reset
          updateHeadersAndEmailColumn($scope);
        }); // end data $watch

        $scope.toggleGroup = function () {
          $scope.closed = !$scope.closed;
        };
      },
    ], // end controller
  };
} // end sbCheckboxGrid
