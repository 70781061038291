import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  finalize,
  map,
  takeUntil,
} from 'rxjs';
import { SbxHttpClient } from '@/core/http';
import {
  GenericStatusResponse,
  LiquidationStackDataGet,
  LiquidationStackDataPost,
  Stack,
  StackName,
} from '@shoobx/types/webapi-v2';
import { SbxPageComponent } from '@/shared/sbx-page/sbx-page.component';

const GET_ERROR_MESSAGE = 'An error occurred while fetching Liquidation Stack data.';
const SAVE_ERROR_MESSAGE = 'An error occurred while saving Liquidation Stack data.';

@Downgrade.Component('ngShoobx', 'sbx-update-liquidation-stack-workitem')
@Component({
  selector: 'sbx-update-liquidation-stack-workitem',
  templateUrl: './sbx-update-liquidation-stack-workitem.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxUpdateLiquidationStackWorkitemComponent
  implements AfterViewInit, OnDestroy
{
  @Input() public workitemId!: string;
  @ViewChild('pageRef') public readonly pageRef: SbxPageComponent;

  public readonly list$!: Observable<Stack[][]>;

  private readonly listSubject = new BehaviorSubject<Stack[][]>(undefined);
  private readonly unsubscribe$ = new Subject();

  public constructor(
    private readonly http: SbxHttpClient,
    private readonly ref: ChangeDetectorRef,
  ) {
    this.list$ = this.listSubject.asObservable();
  }

  public ngAfterViewInit(): void {
    this.setUpStack(this.workitemId);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public handleChange(list: Stack[][]): void {
    this.updateList(list);
  }

  private setUpStack(workitemId: string): void {
    this.pageRef.setLoadingFlag('global');
    this.pageRef.clearErrorFlag('global');
    this.ref.detectChanges();

    this.http
      .entity('2')
      .get<LiquidationStackDataGet>(`workitems/${workitemId}/liquidation-stack`)
      .pipe(
        map((data: LiquidationStackDataGet): Stack[][] => [
          ...data.stack,
          ...[data.common],
        ]),
        catchError((error) => {
          this.pageRef.setErrorFlag(
            'global',
            error.error?.message || GET_ERROR_MESSAGE,
          );

          return EMPTY;
        }),
        finalize(() => {
          this.pageRef.clearLoadingFlag('global');
          this.ref.detectChanges();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((list) => {
        this.listSubject.next(list);
      });
  }

  private updateList(list: Stack[][]): void {
    const groups: StackName[][] = this.mapStackToStackNames(list.slice(0, -1));
    const common: StackName[] = this.mapStackToStackNames(list.slice(-1))[0];

    this.pageRef.setLoadingFlag('global');
    this.pageRef.clearErrorFlag('global');

    this.http
      .entity('2')
      .post<GenericStatusResponse>(`workitems/${this.workitemId}/liquidation-stack`, {
        params: <LiquidationStackDataPost>{
          stack: groups,
          common,
        },
      })
      .pipe(
        catchError((error) => {
          this.pageRef.setErrorFlag(
            'global',
            error.error?.message || SAVE_ERROR_MESSAGE,
          );

          return EMPTY;
        }),
        finalize(() => {
          this.pageRef.clearLoadingFlag('global');
          this.ref.detectChanges();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private mapStackToStackNames(list: Stack[][]): StackName[][] {
    return list.map((group: Stack[]) =>
      group.map((element: Stack) => {
        const { name } = element;

        return { name };
      }),
    );
  }
}
