import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxMessageModule } from '../sbx-message/sbx-message.module';
import { SbxDocumentReferencesComponent } from './tabs/references/sbx-document-references.component';
import { SbxDocumentReferenceModule } from '../sbx-document-reference/sbx-document-reference.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxUploadMissingDocumentModule } from '../sbx-upload-missing-document/sbx-upload-missing-document.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxDocumentReferenceGroupComponent } from './fragments/sbx-document-reference-group.component';
import { SbxDocumentInfoComponent } from './fragments/sbx-document-info.component';
import { SbxDocumentCardModule } from '../sbx-document-card/sbx-document-card.module';
import { SbxButtonGroupModule } from '../sbx-button-group/sbx-button-group.module';

@NgModule({
  imports: [
    CommonModule,
    SbxMessageModule,
    SbxDocumentReferenceModule,
    SbxButtonModule,
    SbxUploadMissingDocumentModule,
    SbxIconModule,
    SbxDocumentCardModule,
    SbxButtonGroupModule,
  ],
  declarations: [
    SbxDocumentReferencesComponent,
    SbxDocumentReferenceGroupComponent,
    SbxDocumentInfoComponent,
  ],
  exports: [SbxDocumentReferencesComponent],
})
export class SbxDocumentModule {
  static entryComponents = [SbxDocumentReferencesComponent];

  static forRoot(): ModuleWithProviders<SbxDocumentModule> {
    return {
      ngModule: SbxDocumentModule,
    };
  }
}
