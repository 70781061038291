<div class="sbx-tree-edit">
  <div class="sbx-tree-edit-children">
    <sbx-tree-node-edit
      [root]="root"
      [last]="false"
      (edit)="edit.emit($event)"
      (remove)="remove.emit($event)"
      (createSubgroup)="createSubgroup.emit($event)"
    >
    </sbx-tree-node-edit>
  </div>
</div>
