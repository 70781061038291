import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxStakeholderCardModule } from '../sbx-stakeholder-card/sbx-stakeholder-card.module';
import { SbxStakeholderCardListComponent } from './sbx-stakeholder-card-list.component';
import { Stakeholders } from '../../../js/lib/stakeholder/service';

@NgModule({
  imports: [CommonModule, SbxStakeholderCardModule],
  declarations: [SbxStakeholderCardListComponent],
  exports: [SbxStakeholderCardListComponent],
  providers: [
    {
      provide: Stakeholders,
      useFactory: ($injector) => $injector.get('Stakeholders'),
      deps: ['$injector'],
    },
  ],
})
export class SbxStakeholderCardListModule {
  static entryComponents = [SbxStakeholderCardListComponent];

  static forRoot(): ModuleWithProviders<SbxStakeholderCardListModule> {
    return {
      ngModule: SbxStakeholderCardListModule,
    };
  }
}
