import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sbx-radio-group',
  templateUrl: './sbx-radio-group.component.html',
  styleUrls: ['sbx-radio-group.component.scss'],
})
export class SbxRadioGroupComponent {
  @Input() name = null;
  @Input() items = [];
  @Input() model = null;
  @Input() layout: 'horizontal' | 'vertical' = 'horizontal';
  @Input() disabled = false;
  @Output() focus = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  handleFocus(event) {
    this.focus.emit(event);
  }

  handleChange(value) {
    this.modelChange.emit(value);
  }
}
