import { Component } from '@angular/core';

/**
 *  Shoobx Themed Modal Footer
 *
 *  Include in the input template to [sbxModalTemplate]
 *  to supply a standard footer layout.
 *
 *  Example:
 *
 *  @Component({
 *    selector: 'get-help',
 *    template: `
 *      <sbx-button type="help"
 *        sbxModal
 *        [sbxModalTemplate]="content"
 *        >
 *        Get Help Now!
 *      </sbx-button>
 *      <ng-template #content>
 *        <sbx-modal-content>
 *          This is some helpful content!
 *        </sbx-modal-content>
 *        <sbx-modal-footer>
 *          Some footer content.
 *        </sbx-modal-footer>
 *      </ng-template>
 *    `,
 *  })
 *  class GetHelpComponent {}
 */
@Component({
  selector: 'sbx-modal-footer',
  templateUrl: './sbx-modal-footer.component.html',
  styleUrls: ['./sbx-modal-footer.component.scss'],
})
export class SbxModalFooterComponent {}
