import {
  Component,
  Input,
  ViewEncapsulation,
  TemplateRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { Downgrade } from '../downgrade';

/**
 * A standard Shoobx popover (tooltip).
 * Wrapper component for the NgBootstrap popover widget.
 * See https://ng-bootstrap.github.io/#/components/popover/examples
 * @example Component
 *  <sbx-popover [sbxPopoverContent]="someContent"
 *    sbxPopoverTitle="someTitle" sbxPopoverPlacement="top | bottom | left | right">
 *  </sbx-popover>
 * @example Directive:
 *  <div sbx-popover [sbxPopoverContent]="someContent"
 *    sbxPopoverTitle="someTitle" sbxPopoverPlacement="top | bottom | left | right">
 *  </div>
 * @example Array-types
 *  <sbx-popover [sbxPopoverContent]='some content'
 *    sbxPopoverTitle='someTitle' sbxPopoverPlacement="['top', 'left']">
 *    <sbx-icon type='help'></sbx-icon>
 *  </sbx-popover>
 */

@Downgrade.Component('ngShoobx', 'sbx-popover')
@Component({
  selector: 'sbx-popover, [sbxPopover]',
  templateUrl: './sbx-popover.component.html',
  styleUrls: ['sbx-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SbxPopoverComponent implements OnChanges {
  /**
   * Title of the popover.
   */
  @Input() public sbxPopoverTitle: string = null;
  /**
   * Content of the popover.
   */
  @Input({ transform: sbxPopoverContentTransform })
  public sbxPopoverContent: TemplateRef<unknown> | string = null;

  @Input() public sbxPopoverTemplateContext = null;
  @Input() public sbxPopoverPlacement: Placement[] | Placement = [
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'right',
    'auto',
  ];
  /**
   * Container of popover to append to.
   */
  @Input() public sbxPopoverContainer: string = 'body';
  /**
   * Event Triggers for when to toggle the Popover
   */
  @Input() public sbxPopoverTriggers: string = 'mouseenter:mouseleave';

  /**
   * Indicator whether popover should be shown or not.
   */
  @Input() public sbxPopoverDisabled: boolean = false;

  /**
   * Trigger popover close on click.  true = any click, false = no clicks
   * 'inside' or 'outside' will specify clicks in or out of the popover content
   */
  @Input() public sbxPopoverAutoClose: boolean | string = 'outside';

  @Input() public sbxPopoverClass: string = '';

  @Input() public sbxPopoverAutoWidth: boolean = false;

  public isPopoverContentTemplate: boolean;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.sbxPopoverContent) {
      this.isPopoverContentTemplate = this.sbxPopoverContent instanceof TemplateRef;
    }
  }
}

function sbxPopoverContentTransform(
  value: TemplateRef<unknown> | string,
): TemplateRef<unknown> | string {
  return typeof value === 'string' ? String(value).trim() : value;
}
