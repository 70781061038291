import angular from 'angular';

/**
 * @ngdoc service
 * @kind function
 * @name sb.lib.promise.DebounceMaker
 * @requires $timeout
 * @requires $q
 *
 * @description
 * This factory produces `debounce` functions so that one may invoke a function
 * after a given amount of time. If there is a currently outstanding function
 * application, debounce will cancel that and restart the wait.
 *
 * @param {boolean} [applyScope=true] If $scope should be $appled after resolution.
 *
 * @returns {function} This function can be invoked with the following parameters:
 *    | @param {function} fn A function to apply async
 *    | @param {number} [wait=0] Time in ms to wait.
 *    | @param {array} [args=null] An array of arguments to be passed to the
 *       function application.
 *
 *    | @returns {promise} The promise returned from `$timeout`.
 *
 */
export const DebounceMaker = [
  '$timeout',
  function ($timeout) {
    return function (applyScope) {
      let prom;
      applyScope = angular.isUndefined(applyScope) ? true : applyScope;
      return (fn, wait, args = []) => {
        $timeout.cancel(prom);
        prom = $timeout(fn, wait || 0, applyScope, ...args);
        return prom;
      };
    };
  },
];

/**
 * @ngdoc service
 * @kind function
 * @name sb.lib.promise.SimpleHTTPWrapper
 * @requires $http
 * @requires $q
 *
 * @param {object} config Object to be passed to `$http`.
 * @param {string} [defaultMessage='There was no response'] Default error message
 *   for promise rejection.
 *
 * @description
 * The `SimpleHTTPWrapper` allows you to quickly use the `$http` service
 * without any boilerplate to handle missing `response.data` or
 * `response.data.error`.
 *
 * # General usage
 * The following two examples are basically equivalent:
 *
 * ```js
 * $http(httpOptions).then(function(response) {
 *  if ( response.data && response.data.error )
 *    return $q.reject(response.data.error);
 *  return operation(response.data);
 * }, function(response) {
 *  if ( response.data && response.data.error )
 *    return $q.reject(response.data.error);
 *  return $q.reject('Error!');
 * });```
 *
 * ```js
 * SimpleHTTPWrapper(httpOptions, 'Error!').then(function(data) {
 *  return operation(data);
 * }, function(reason) {
 *  return $q.reject(reason);
 * });```
 */
export const SimpleHTTPWrapper = [
  '$http',
  '$q',
  'BackendLocation',
  function ($http, $q, BackendLocation) {
    return function (httpOptions, defaultError) {
      if (httpOptions.url && httpOptions.url.indexOf(BackendLocation.root(2)) !== -1) {
        httpOptions = angular.copy(httpOptions);
        // Add onbehalf headers for webapi v2, they're no longer part of URL
        if (!httpOptions.headers) {
          httpOptions.headers = {};
        }
        if (!httpOptions.headers['X-SB-Onbehalf']) {
          httpOptions.headers['X-SB-Onbehalf'] = BackendLocation.onBehalf(2);
        }
      }
      // Authentication monitor does not pass the POST requests without content
      // type header. Setting up the data to empty object forces angular to add
      // the header to the request.
      if (httpOptions.method === 'POST') {
        if (httpOptions.data === undefined || httpOptions.data === null) {
          httpOptions = angular.copy(httpOptions);
          httpOptions.data = {};
        }
      }
      return $http(httpOptions).then(
        ({ data }) => {
          if (httpOptions.method === 'DELETE') {
            return data;
          }
          if (data === undefined || data === null || data === '') {
            return $q.reject('There was no response data.');
          }
          if (data.error) {
            return $q.reject(data.error);
          }
          return data;
        },
        ({ data }) => {
          // webapi 1 errors
          if (data && data.error) {
            return $q.reject(data.error);
          }
          // webapi 2 errors
          if (data && data.message) {
            return $q.reject(data.message);
          }
          return $q.reject(defaultError || 'There was a response error.');
        },
      );
    };
  },
];
