import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SbxListingFilterComponent } from './sbx-listing-filter.component';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxGridModule } from '@/shared/sbx-grid/sbx-grid.module';
import { SbxRadioModule } from '@/shared/sbx-radio/sbx-radio.module';
import { SbxCheckboxModule } from '@/shared/sbx-checkbox/sbx-checkbox.module';
import { SbxScrollableModule } from '@/shared/sbx-scrollable/sbx-scrollable.module';

@NgModule({
  declarations: [SbxListingFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    SbxIconModule,
    SbxButtonModule,
    SbxGridModule,
    SbxRadioModule,
    SbxCheckboxModule,
    SbxScrollableModule,
  ],
  exports: [SbxListingFilterComponent],
})
export class SbxListingFilterModule {}
