<div class="sbx-dropzone" [ngClass]="{ 'sbx-active-drop': !readOnly }">
  <form
    enctype="multipart/form-data"
    *ngIf="!readOnly && (multiple || (!multiple && files.length === 0))"
  >
    <label>
      <input
        type="file"
        [name]="key"
        [multiple]="multiple"
        (change)="handleFileChange($any($event.target).files)"
      />
      <div class="dnd-info">
        <sbx-icon class="sbx-left-icon" type="upload"></sbx-icon>
        <span class="dnd-label">Drag & Drop</span> your files here,
        <strong>or browse</strong>
      </div>
    </label>
  </form>

  <ul *ngIf="files.length">
    <li *ngFor="let file of files">
      <a [href]="file.url" class="sbx-list-item-content" *ngIf="file.url">
        <ng-container
          *ngTemplateOutlet="
            listItemContentTemplate;
            context: { $implicit: file.title }
          "
        ></ng-container>
      </a>
      <span class="sbx-list-item-content" *ngIf="!file.url">
        <ng-container
          *ngTemplateOutlet="
            listItemContentTemplate;
            context: { $implicit: file.title }
          "
        ></ng-container>
      </span>
      <div class="sbx-list-item-actions" *ngIf="!readOnly">
        <sbx-popover [sbxPopoverContent]="getDataFromStatus(file.status).popoverText">
          <sbx-icon
            [type]="getDataFromStatus(file.status).icon"
            class="sbx-icon-status {{ getDataFromStatus(file.status).class }}"
          ></sbx-icon>
        </sbx-popover>
        <sbx-action-menu
          *ngIf="file.status === 'uploaded'"
          [actions]="getFileActions(file.id)"
        ></sbx-action-menu>
      </div>
    </li>
  </ul>
</div>

<ng-template #listItemContentTemplate let-title>
  <sbx-icon class="sbx-left-icon" type="documentFilled"></sbx-icon>
  <span>{{ title }}</span>
</ng-template>
