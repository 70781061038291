<div class="sbx-tree-node-edit" [ngClass]="{ root: !depth }">
  <span class="sbx-line-container">
    <div class="sbx-tree-edit-line" [ngClass]="['sbx-tree-depth-' + depth]">
      <div
        *ngIf="root.leaves.length > 0 && collapseTreeToggle"
        class="sbx-tree-toggle"
        [ngSwitch]="root.expanded"
        (click)="root.expanded = !root.expanded"
      >
        <sbx-icon *ngSwitchCase="true" type="minus"></sbx-icon>
        <sbx-icon *ngSwitchCase="false" type="plus"></sbx-icon>
      </div>

      <div class="sbx-tree-name" [ngClass]="{ root: !depth }">
        <span>{{ root.name }}</span>
      </div>

      <sbx-action-menu [actions]="actions"></sbx-action-menu>
    </div>
  </span>

  <ng-container *ngIf="root.expanded">
    <div *ngFor="let node of root.leaves; let i = index" class="sbx-tree-edit-children">
      <sbx-tree-node-edit
        [root]="node"
        [depth]="depth + 1"
        [last]="last && root.leaves.length - i == 1"
        (edit)="edit.emit($event)"
        (remove)="remove.emit($event)"
        (createSubgroup)="createSubgroup.emit($event)"
      ></sbx-tree-node-edit>
    </div>
  </ng-container>
</div>
