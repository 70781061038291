/**
 * @ngdoc component
 * @name sb.lib.document.component:sbGenerateAndDownload
 *
 * @description
 * A button that allows user to generate a document and download it after
 * generation is complete. Genearation is started by spawning a background task.
 * Component will wait until the background task finishes and redirects
 * user to document URL on success.
 *
 * @param {string} documentId The id of the document to request.
 * @param {string} endpointName Endpoint name that will spawn the task to
 *                              generate the document. This endpoint is
 *                              appended to a document webapi URL.
 *
 * @example
   <sb-generate-and-download
      document-id="{{ ::doc.id }}"
      endpoint-name="watermark-doc">

      Download PDF

   </sb-generate-and-download>
 */
export const sbGenerateAndDownload = {
  controllerAs: 'vm',
  template: require('./templates/generate-and-download.html'),
  bindings: {
    docId: '<documentId',
    endpointName: '@',
    data: '<?',
  },
  transclude: true,
  controller: [
    '$scope',
    '$window',
    'BackendLocation',
    function ($scope, $window, BackendLocation) {
      function click() {
        const endpoint = `${BackendLocation.entity(1)}documents/${this.docId}/${
          this.endpointName
        }`;
        this.launchTask(endpoint, this.data || {});
        $scope.$emit('preparingDocument');
      }
      $scope.$watch('vm.taskStatus', () => {
        if (
          this.taskStatus &&
          (this.taskStatus.state === 'DONE' || this.taskStatus.state === 'SUCCESS')
        ) {
          $scope.$emit('documentReady', this.taskStatus.result);
          $window.location = this.taskStatus.result;
        }
      });
      this.click = click.bind(this);
    },
  ],
}; // end sbGenerateAndDownload
