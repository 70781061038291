import angular from 'angular';
import { DebounceMaker, SimpleHTTPWrapper } from './promise';
import { PromiseErrorCatcher } from './error';

/**
 * @ngdoc overview
 * @name sb.lib.promise
 *
 * @description
 * The sb.lib.promise module houses helpers for promise code and boilerplate.
 */
export default angular
  .module('sb.lib.promise', [])

  .factory('DebounceMaker', DebounceMaker)
  .factory('SimpleHTTPWrapper', SimpleHTTPWrapper)
  .factory('PromiseErrorCatcher', PromiseErrorCatcher).name;
