import angular from 'angular';
import Tools from '../lib/tools';
import RX from '../lib/rx';
import Form from '../lib/form/form';
import Messages from '../lib/messages/messages';

import {
  ListPageModel,
  sbFilteredList,
  sbFilteredListPaging,
  ListLocation,
} from './page';
import { sbFilteredListFilter } from './filters';
import { sbFilteredListSort } from './sorts';
import { sbFilteredListColumnSort } from './columnSort';

import {
  sbDataRoomPage,
  DataRoomPageModel,
  $dataroomIndexModal,
  $exportDataRoomIndexModal,
  sbDataroomFolderTree,
  sbDataroomFolderLevel,
} from './dataroom';
/**
 * @ngdoc overview
 * @name sb.lib.administration
 *
 * @description
 * This module houses administration helpers
 */
export default angular
  .module('sb.listpage', [Messages, Tools, Form, RX])

  .component('sbDataroomFolderLevel', sbDataroomFolderLevel)
  .component('sbDataroomFolderTree', sbDataroomFolderTree)
  .directive('sbDataRoomPage', sbDataRoomPage)
  .directive('sbFilteredList', sbFilteredList)
  .directive('sbFilteredListFilter', sbFilteredListFilter)
  .directive('sbFilteredListSort', sbFilteredListSort)
  .directive('sbFilteredListColumnSort', sbFilteredListColumnSort)
  .directive('sbFilteredListPaging', sbFilteredListPaging)
  .factory('ListPageModel', ListPageModel)
  .factory('$dataroomIndexModal', $dataroomIndexModal)
  .factory('DataRoomPageModel', DataRoomPageModel)
  .factory('ListLocation', ListLocation)
  .factory('$exportDataRoomIndexModal', $exportDataRoomIndexModal).name;
