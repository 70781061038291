import { AppConfig } from '@/core/config/app.config';
import { Downgrade } from '@/shared/downgrade';
import { Component, Inject } from '@angular/core';

@Downgrade.Component('ngShoobx', 'sbx-footer')
@Component({
  selector: 'sbx-footer',
  templateUrl: './sbx-footer.component.html',
  styleUrls: ['./sbx-footer.component.scss'],
})
export class SbxFooterComponent {
  version: string = null;

  constructor(@Inject(AppConfig) appConfig: AppConfig) {
    this.version = appConfig.config?.appVersion;
  }
}
