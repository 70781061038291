import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  TemplateRef,
  Input,
} from '@angular/core';
import {
  ISbxTableColumns,
  ISbxTableData,
  ISbxTableGroups,
  ISbxTableRow,
} from '@/shared/sbx-table/sbx-table.component';
import { SbxLiquidationStackModalService } from '../sbx-liquidation-stack-modal/sbx-liquidation-stack-modal.service';
import { SbxNextRoundPlannerService } from './sbx-next-round-planner.service';
import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import {
  StockClassAndAmountAndShares,
  SingleConversion,
  OwnershipEntry,
} from '@shoobx/types/plannerapi-v1';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { ISbxPropertiesList } from '@/shared/sbx-properties-list/sbx-properties-list.component';
import { SbxInvestorModalService } from '../sbx-investor-modal/sbx-investor-modal.service';
import {
  COLORS,
  formatTooltipContents,
  ISbxChartConfig,
  ISbxChartConfigDataColors,
  ISbxChartConfigDataColumns,
  SbxChartComponent,
} from '@/shared/sbx-chart/sbx-chart.component';
import { SbxPageComponent } from '@/shared/sbx-page/sbx-page.component';
import {
  INextRoundStateResponse,
  getEmployeeOptionColor,
} from './sbx-next-round-planner.service';
import { Subject } from 'rxjs';
import { pie } from 'billboard.js';

@Component({
  selector: 'sbx-next-round-planner',
  templateUrl: './sbx-next-round-planner.component.html',
  styleUrls: ['sbx-next-round-planner.component.scss'],
})
export class SbxNextRoundPlannerComponent implements OnInit {
  @ViewChild('pageRef', { static: true }) pageRef: SbxPageComponent;
  @ViewChild('stakeholdersActionsTemplate')
  stakeholdersActionsTemplate: TemplateRef<any>;
  @ViewChild('stockClassTitleTemplate', { static: true })
  stockClassTitleTemplate: TemplateRef<any>;
  @ViewChild('optionsTitleTemplate', { static: true })
  optionsTitleTemplate: TemplateRef<any>;
  @ViewChild('stockClassActionsTemplate') stockClassActionsTemplate: TemplateRef<any>;
  @ViewChild('investmentTemplate') investmentTemplate: TemplateRef<any>;
  @ViewChild('investmentReturnTemplate') investmentReturnTemplate: TemplateRef<any>;
  @ViewChild('investmentActionsTemplate') investmentActionsTemplate: TemplateRef<any>;
  @ViewChild('convertedSharesTemplate') convertedSharesTemplate: TemplateRef<any>;
  @ViewChild('debtActionsTemplate') debtActionsTemplate: TemplateRef<any>;
  @ViewChild('investmentTopMessageTemplate')
  investmentTopMessageTemplate: TemplateRef<any>;
  @ViewChild('stockClassTopMessageTemplate')
  stockClassTopMessageTemplate: TemplateRef<any>;
  @ViewChild('debtTitleTemplate') debtTitleTemplate: TemplateRef<any>;
  @ViewChild('conditionalBoldTemplate') conditionalBoldTemplate: TemplateRef<any>;
  @ViewChild('checkTemplate') checkTemplate: TemplateRef<any>;
  @ViewChild('prePieChartRef') prePieChartRef: SbxChartComponent;
  @ViewChild('postPieChartRef') postPieChartRef: SbxChartComponent;
  @ViewChild('checkboxHeaderTpl') private readonly checkboxHeaderTpl: TemplateRef<any>;
  @ViewChild('checkboxTpl') private readonly checkboxTpl: TemplateRef<any>;

  @Input() apiVersion = null;
  @Input() baseUrl = null;

  state: INextRoundStateResponse = {};
  stateChanges$ = new Subject<INextRoundStateResponse>();
  stakeholdersColumns: ISbxTableColumns = [];
  stockClassesColumns: ISbxTableColumns = [];
  yourInvestmentsColumns: ISbxTableColumns = [];
  yourInvestmentsGroups: ISbxTableGroups = [
    {
      key: 'preMoney',
      title: '',
      highlightColor: 'gray',
    },
    {
      key: 'postMoney',
      title: '',
      highlightColor: 'green',
    },
  ];

  employeeOptionsColumns: ISbxTableColumns = [];
  employeeOptionsGroups: ISbxTableGroups = [
    {
      key: 'preMoney',
      title: 'Pre-money',
      highlightColor: 'gray',
    },
    {
      key: 'postMoney',
      title: 'Post-money',
      highlightColor: 'green',
    },
  ];
  debtsAndAlternativesColumns: ISbxTableColumns = [];
  debtsAndAlternativesData: ISbxTableData = [];
  public isDebtsConvertSelectAllChecked = false;
  public isDebtsConvertSelectAllIndeterminate = false;

  prePieChartConfig: ISbxChartConfig = {
    data: {
      type: pie(),
      columns: [],
    },
    tooltip: {
      contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
        const { id } = d[0];
        const preMoney = this.state.preMoneyOwnership.find((o) => o.title === id);
        const postMoney = this.state.postMoneyOwnership.find((o) => o.title === id);

        return formatTooltipContents([
          {
            color: color(d[0].name),
            title: d[0].name,
            value: `
            Pre-money ${preMoney?.percent.toFixed(2) ?? 0}%
            <br>
            Post-money ${postMoney?.percent.toFixed(2) ?? 0}%
          `,
          },
        ]);
      },
    },
    size: {
      height: 200,
    },
    padding: {
      left: 0,
      right: 0,
      bottom: -5,
    },
    pie: {
      label: {
        show: false,
      },
    },
  };

  // XXX: We should use library for deep copy
  postPieChartConfig: ISbxChartConfig = {
    ...this.prePieChartConfig,
    data: {
      ...this.prePieChartConfig.data,
      columns: {
        ...this.prePieChartConfig.data.columns,
      },
    },
  };

  exportLoading = false;

  constructor(
    @Inject(SbxConfirmationModalService)
    public sbxConfirmationModalService: SbxConfirmationModalService,
    @Inject(SbxFormModalService)
    public sbxFormModalService: SbxFormModalService,
    @Inject(SbxNextRoundPlannerService)
    public sbxNextRoundPlannerService: SbxNextRoundPlannerService,
    @Inject(SbxLiquidationStackModalService)
    public sbxLiquidationStackModalService: SbxLiquidationStackModalService,
    @Inject(SbxInvestorModalService)
    public sbxInvestorModalService: SbxInvestorModalService,
  ) {}

  async ngOnInit() {
    this.sbxNextRoundPlannerService.initialize(this.apiVersion, this.baseUrl);

    try {
      await this.updateState();
    } catch (e) {}
  }

  async updateState() {
    this.pageRef.clearErrorFlag('global');
    this.pageRef.setLoadingFlag('global');
    try {
      this.state = await this.sbxNextRoundPlannerService.getState().toPromise();
      this.stateChanges$.next(this.state);
      this.setupChart(
        this.prePieChartConfig,
        this.prePieChartRef,
        this.state.preMoneyOwnership,
      );
      this.setupChart(
        this.postPieChartConfig,
        this.postPieChartRef,
        this.state.postMoneyOwnership,
      );
      this.setStakeholdersColumns();
      this.setStockClassesColumns();
      this.setEmployeeOptionsColumns();
      this.setInvestmentsColumns();
      this.setDebtsColumns();
      this.mapDebtsData();
      this.updateBulkSelectValue();
    } catch (e) {
      this.pageRef.setErrorFlag('global', 'An error occurred trying to fetch data');
    } finally {
      this.pageRef.clearLoadingFlag('global');
    }
  }

  async handleAddStakeholderButtonClick() {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.addStakeholderFormUrl(),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  async handleExportButtonClick() {
    this.exportLoading = true;
    try {
      const downloadUrl = await this.sbxNextRoundPlannerService.export();
      window.location.href = downloadUrl;
    } finally {
      this.exportLoading = false;
    }
  }

  async handleReset() {
    try {
      await this.sbxNextRoundPlannerService.reset();
      await this.updateState();
    } catch (e) {}
  }

  async handleAddStockClassButtonClick() {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getAddStockClassFormUrl(),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  async handleAddInvestorButtonClick() {
    try {
      const {
        result: { id },
      } = await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getAddInvestorFormUrl(),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      const state = await this.sbxNextRoundPlannerService.getState().toPromise();
      this.state.investors = state.investors;
      this.openEditInvestorModal(id);
      this.setInvestmentsColumns();
    } catch {}
  }

  async handleModifyLiquidationStackButtonClick() {
    try {
      const { stack } = await this.sbxNextRoundPlannerService
        .getLiquidationStack()
        .toPromise();
      const { result } = await this.sbxLiquidationStackModalService.open({
        stack,
        canEdit: this.state.canEdit,
      }).result;
      await this.sbxNextRoundPlannerService.saveLiquidationStack(result).toPromise();
      await this.updateState();
    } catch {}
  }

  async handleSettingsButtonClick() {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getSettingsEditFormUrl(),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  async handleAddConvertiblesButtonClick() {
    try {
      const {
        result: { id },
      } = await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getAddConvertibleFormUrl(),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  private async handleSingleConversion(id, converts = false): Promise<void> {
    this.pageRef.clearErrorFlag('global');
    this.pageRef.setLoadingFlag('global');

    try {
      await this.sbxNextRoundPlannerService.convertSingle(id, converts);
      await this.updateState();
    } catch (e) {
      this.pageRef.setErrorFlag('global', e.error.message);
    } finally {
      this.pageRef.clearLoadingFlag('global');
    }
  }

  async handleMassConversion(convert = false) {
    this.pageRef.clearErrorFlag('global');
    this.pageRef.setLoadingFlag('global');

    try {
      await this.sbxNextRoundPlannerService.convertEverything(convert);
      await this.updateState();
    } catch (e) {
      this.pageRef.setErrorFlag('global', e.error.message);
    } finally {
      this.pageRef.clearLoadingFlag('global');
    }
  }

  setStakeholdersColumns() {
    const columns: ISbxTableColumns = [
      {
        key: 'stakeholder',
        title: 'Stakeholder',
      },
      {
        key: 'preMoneyStr',
        title: 'Pre-money ownership',
        width: '30%',
      },
      {
        key: 'postMoneyStr',
        title: 'Post-money ownership',
        width: '30%',
      },
    ];

    if (this.state.dilutionImpact.some((sh) => sh.canDelete)) {
      columns.push({
        key: 'actions',
        title: '',
        template: this.stakeholdersActionsTemplate,
      });
    }

    this.stakeholdersColumns = columns;
  }

  setStockClassesColumns() {
    const columns: ISbxTableColumns = [
      {
        key: 'title',
        title: 'Name',
        template: this.stockClassTitleTemplate,
      },
      {
        key: 'amountRaisedStr',
        title: 'Amount Raised',
        align: 'right',
      },
      {
        key: 'preMoneyStr',
        title: 'Pre-Money Valuation',
        align: 'right',
      },
      {
        key: 'liquidationPref',
        title: 'Liquidation Preference',
      },
    ];

    if (this.state.stockClasses.some((sc) => sc.canEdit || sc.canDelete)) {
      columns.push({
        key: 'actions',
        title: '',
        template: this.stockClassActionsTemplate,
        width: '1px',
      });
    }

    this.stockClassesColumns = columns;
  }

  setEmployeeOptionsColumns() {
    const columns: ISbxTableColumns = [
      {
        key: 'name',
        title: '',
        width: '40%',
        template: this.optionsTitleTemplate,
      },
      {
        key: 'preMoneySharesStr',
        group: 'preMoney',
        title: 'Pre-Money Shares',
        width: '15%',
        align: 'right',
      },
      {
        key: 'preMoneyPercentageStr',
        group: 'preMoney',
        title: '% of total equity',
        width: '15%',
        align: 'right',
      },
      {
        key: 'postMoneySharesStr',
        group: 'postMoney',
        title: 'Post-Money Shares',
        width: '15%',
        align: 'right',
      },
      {
        key: 'postMoneyPercentageStr',
        group: 'postMoney',
        title: '% of total equity',
        width: '15%',
        align: 'right',
      },
    ];

    this.employeeOptionsColumns = columns;
  }

  setInvestmentsColumns() {
    const columns: ISbxTableColumns = [
      {
        key: 'title',
        title: 'Name',
        width: '20%',
        template: this.conditionalBoldTemplate,
      },
      {
        key: 'preMoneyStr',
        group: 'preMoney',
        title: 'Pre-money ownership',
        width: '20%',
        align: 'right',
      },
      {
        key: 'newInvestment',
        group: 'postMoney',
        title: 'New Investment',
        template: this.investmentTemplate,
        width: '30%',
      },
      {
        key: 'postMoneyStr',
        group: 'postMoney',
        title: 'Post-money ownership',
        align: 'right',
      },
    ];

    if (this.state.canEdit) {
      columns.push({
        key: 'actions',
        group: 'postMoney',
        title: '',
        template: this.investmentActionsTemplate,
        width: '1px',
      });
    }

    this.yourInvestmentsColumns = columns;
  }

  setDebtsColumns() {
    const columns: ISbxTableColumns = [
      {
        key: 'title',
        title: 'Name',
        template: this.debtTitleTemplate,
      },
      {
        key: 'amountStr',
        title: 'Amount',
      },
      {
        key: 'converts',
        title: 'Converts',
        template: this.checkboxTpl,
        headerTemplate: this.checkboxHeaderTpl,
        width: '60px',
      },
      {
        key: 'customConversion',
        title: 'Custom',
        template: this.checkTemplate,
      },
      {
        key: 'conversion',
        title: 'Converted Shares',
        template: this.convertedSharesTemplate,
      },
      {
        key: 'interestStr',
        title: 'Plus interest amount',
      },
    ];

    if (this.state.canEdit) {
      columns.push({
        key: 'actions',
        title: '',
        template: this.debtActionsTemplate,
        width: '1px',
      });
    }

    this.debtsAndAlternativesColumns = columns;
  }

  public handleDebtsConvertSelectAllChange(value: boolean): void {
    this.updateBulkSelectValue();
    this.handleMassConversion(value);
  }

  public handleDebtsConvertChange(id: number, selected: boolean): void {
    const index = this.debtsAndAlternativesData.findIndex(
      (row: ISbxTableRow) => row.id === id,
    );
    this.debtsAndAlternativesData[index].converts = selected;

    this.updateBulkSelectValue();
    this.handleSingleConversion(id, selected);
  }

  private updateBulkSelectValue(): void {
    const allOptionsLength = this.debtsAndAlternativesData.length;
    const checkedOptionsLength = this.debtsAndAlternativesDataConvertCheckedLength;
    const noneOptionChecked = !checkedOptionsLength;
    const allOptionsChecked = checkedOptionsLength === allOptionsLength;
    const isIndeterminate = !noneOptionChecked && !allOptionsChecked;

    this.isDebtsConvertSelectAllChecked = allOptionsChecked;
    this.isDebtsConvertSelectAllIndeterminate = isIndeterminate;
  }

  private get debtsAndAlternativesDataConvertCheckedLength(): number {
    return this.debtsAndAlternativesData.filter((element) => element.converts).length;
  }

  mapDebtsData() {
    const npasWithNotes = this.state.npas.reduce((acc, npa) => {
      return [...acc, npa, ...this.state.notes.filter((note) => note.npaId === npa.id)];
    }, []);
    this.debtsAndAlternativesData = npasWithNotes.concat(this.state.safes);
  }

  investmentPropertiesList(items: StockClassAndAmountAndShares[]) {
    return <ISbxPropertiesList>items.map((item) => ({
      key: this.state.stockClasses.find(({ name }) => name === item.classId).title,
      value: `${item.amountStr} | ${item.sharesStr} shares`,
    }));
  }

  setupChart(
    config: ISbxChartConfig,
    ref: SbxChartComponent,
    ownership: OwnershipEntry[],
  ) {
    const columns = ownership.map((o) => [
      o.title,
      o.percent,
    ]) as ISbxChartConfigDataColumns;

    const colors = ownership.reduce((acc, o, i) => {
      const color = getEmployeeOptionColor(o.title) ?? COLORS[i];
      acc[o.title] = color;
      return acc;
    }, {}) as ISbxChartConfigDataColors;

    if (ref) {
      ref.update({ columns, colors });
    } else {
      config.data.columns = columns;
      config.data.colors = colors;
    }
  }

  debtsConvertedSharesPropertiesList(items: SingleConversion[] = []) {
    return <ISbxPropertiesList>items.map((item) => ({
      key: this.state.stockClasses.find(({ name }) => name === item.stockClass).title,
      value: item.sharesStr,
    }));
  }

  debtsAdditionalInfoPropertiesList(item) {
    const npaTitle =
      item.type === 'npa' && this.state.notes.find((n) => n.npaId === item.id).title;
    const title = npaTitle ? `${npaTitle} ${item.title}` : item.title;

    const properties: ISbxPropertiesList = [
      {
        key: 'Title',
        value: title,
      },
      {
        key: 'Type',
        value: item.type.toUpperCase(),
      },
      {
        key: 'Valuation cap',
        value: item.valuationCapStr
          ? item.valuationCapStr +
            (item.postMoneyCap ? ' (Post-Money)' : ' (Pre-Money)')
          : 'None',
      },
      {
        key: 'Discount',
        value: item.discountStr,
      },
    ];

    if (item.type === 'npa') {
      properties.push({
        key: 'Interest',
        value: item.interestRateStr,
      });
    }

    if (item.converts) {
      properties.push({
        key: 'Conversion price',
        value: item.conversionPriceText ?? item.conversionPriceStr,
      });
    }

    return properties;
  }

  stockClassAdditionalInfoPropertiesList(item) {
    const properties: ISbxPropertiesList = [
      {
        key: 'Issue Price',
        value: item.originalIssuePriceStr,
      },
      {
        key: 'Authorized Shares',
        value: item.authorizedSharesStr,
      },
      {
        key: 'Anti-Dilution',
        value: item.antiDilution || 'N/A',
      },
    ];
    return properties;
  }

  stakeholdersActions(row): ISbxActionsMenu {
    return [
      {
        title: 'Delete',
        icon: 'delete',
        click: async () => {
          await this.sbxNextRoundPlannerService.removeStakeholder(row.id);
          await this.updateState();
        },
        collapsed: false,
        hidden: !row.canDelete,
      },
    ];
  }

  stockClassActions(row): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        click: async () => {
          try {
            await this.sbxFormModalService.open({
              data: {
                url: this.sbxNextRoundPlannerService.getEditStockClassFormUrl(row.name),
                urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
                topMessageTemplate: this.stockClassTopMessageTemplate,
              },
            }).result;
            await this.updateState();
          } catch {}
        },
        collapsed: false,
        hidden: !row.canEdit,
      },
      {
        title: 'Delete',
        icon: 'delete',
        click: async () => {
          try {
            await this.sbxConfirmationModalService.open({
              data: {
                title: 'Delete Stock Class',
                body:
                  'Deletion cannot be undone and all data will be lost. ' +
                  'Are you sure you want to delete?',
                okButtonTitle: 'Delete',
              },
            }).result;
            await this.sbxNextRoundPlannerService.removeStockClass(row.name);
            await this.updateState();
          } catch {}
        },
        collapsed: false,
        hidden: !row.canDelete,
      },
    ];
  }

  async openEditInvestorModal(id) {
    try {
      await this.sbxInvestorModalService.open({
        investorId: id,
        stockClasses: this.state.stockClasses,
        apiVersion: this.apiVersion,
        baseUrl: this.baseUrl,
      }).result;
      await this.updateState();
    } catch {}
  }

  investmentActions(row): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        click: () => {
          this.openEditInvestorModal(row.id);
        },
        collapsed: false,
        hidden: !row.canEdit,
      },
      {
        title: 'Delete',
        icon: 'delete',
        click: async () => {
          try {
            await this.sbxConfirmationModalService.open({
              data: {
                title: 'Delete Investor',
                body:
                  'Deletion cannot be undone and all data will be lost. ' +
                  'Are you sure you want to delete?',
                okButtonTitle: 'Delete',
              },
            }).result;
            await this.sbxNextRoundPlannerService.removeInvestor(row.id);
            await this.updateState();
          } catch {}
        },
        collapsed: false,
        hidden: !row.canDelete,
      },
    ];
  }

  async openHypotheticalConversionModal(id: string) {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getHypotheticalDebtEditFormUrl(id),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getDebtEditFormUrl(id),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  async openConversionModal(id: string) {
    try {
      await this.sbxFormModalService.open({
        data: {
          url: this.sbxNextRoundPlannerService.getDebtEditFormUrl(id),
          urlVersion: this.sbxNextRoundPlannerService.API_VERSION,
        },
      }).result;
      await this.updateState();
    } catch {}
  }

  debtActions(row): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        click: () => {
          if (row.hypothetical) {
            this.openHypotheticalConversionModal(row.id);
          } else {
            this.openConversionModal(row.id);
          }
        },
        collapsed: false,
        hidden: !this.canEdit(row),
      },
      {
        title: 'Delete',
        icon: 'delete',
        click: async () => {
          try {
            await this.sbxConfirmationModalService.open({
              data: {
                title: 'Delete Convertible',
                body:
                  'Deletion cannot be undone and all data will be lost. ' +
                  'Are you sure you want to delete?',
                okButtonTitle: 'Delete',
              },
            }).result;
            await this.sbxNextRoundPlannerService.removeConvertible(row.id);
            await this.updateState();
          } catch {}
        },
        collapsed: false,
        hidden: !this.canDelete(row),
      },
    ];
  }

  shouldBold(title: string) {
    switch (title) {
      case 'Total Pool':
      case 'Unallocated Investment':
      case 'Overallocated Investment':
        return true;
      default:
        return false;
    }
  }

  hasNewRound() {
    const preMoneyClasses = this.state.preMoneyOwnership.filter(
      ({ classId }) => classId,
    );
    const postMoneyClasses = this.state.postMoneyOwnership.filter(
      ({ classId }) => classId,
    );

    return postMoneyClasses.length > preMoneyClasses.length;
  }

  private canEdit(row): boolean {
    return (
      row.type === 'npa' ||
      (row.type === 'note' &&
        this.state.npas.find((npa) => npa.id === row.npaId).converts) ||
      (row.type === 'note' && row.hypothetical) ||
      row.type === 'safe'
    );
  }

  private canDelete(row): boolean {
    return row.hypothetical && (row.type === 'npa' || row.type === 'safe');
  }
}
