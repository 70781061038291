import angular from 'angular';
import sbBootstrap from './../bootstrap/bootstrap';
import Form from './../form/form';
import Messages from './../messages/messages';
import Tools from '../tools';

import { sbEmployeeProfilePage, EmployeeDetails } from './employee-profile';
import {
  sbDepartmentChooser,
  sbDepartmentsAdmin,
  sbDepartmentsListManager,
  DepartmentsAdminModel,
} from './department';

export default angular
  .module('sb.lib.employee', [Form, sbBootstrap, Tools, Messages])

  .component('sbDepartmentChooser', sbDepartmentChooser)
  .component('sbDepartmentsListManager', sbDepartmentsListManager)
  .component('sbDepartmentsAdmin', sbDepartmentsAdmin)
  .directive('sbEmployeeProfilePage', sbEmployeeProfilePage)
  .factory('EmployeeDetails', EmployeeDetails)
  .factory('DepartmentsAdminModel', DepartmentsAdminModel).name;
