<div class="sbx-icon-container">
  <sbx-icon *ngIf="type === 'warning' || type === 'danger'" type="error" />
  <sbx-icon *ngIf="type === 'info'" type="information" />
  <sbx-icon *ngIf="type === 'success'" type="check" />
</div>

<span class="sbx-content" [innerHTML]="content"></span>

<sbx-button-group>
  <sbx-button
    *ngIf="showActionButtons && cancelButtonText"
    theme="primary"
    class="sbx-cancel-button"
    [title]="cancelButtonText"
    (click)="cancelClick.emit()"
  />
  <sbx-button
    *ngIf="showActionButtons"
    theme="secondary"
    class="sbx-confirm-button"
    [title]="confirmButtonText"
    (click)="confirmClick.emit()"
  />
</sbx-button-group>

<sbx-icon
  *ngIf="showCloseButton"
  class="sbx-close"
  type="delete"
  (click)="closeClick.emit()"
/>
