export class PubSub {
  constructor() {
    this.callbacks = [];

    this.emit = this.emit.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  emit(id) {
    this.callbacks.forEach((cb) => cb(id));
  }

  subscribe(callback) {
    this.callbacks = [...this.callbacks, callback];
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
  }
}
