<sbx-search-bar
  [placeholderText]="'Search Companies'"
  [searchResults]="searchResults"
  [rowTemplate]="searchRowTemplate"
  (select)="navigateToDashboard($event)"
>
</sbx-search-bar>

<ng-template #searchRowTemplate let-item>
  <div class="sbx-box">
    <div class="sbx-search-result">
      <img
        *ngIf="item.entityHasLogo; else emptyLogo"
        class="sbx-search-logo"
        [src]="item.entityLogoUrl"
      />
      <ng-template #emptyLogo>
        <sbx-icon type="building" class="sbx-no-logo sbx-small"> </sbx-icon>
      </ng-template>
    </div>
    <span>{{ item.entity.title }}</span>
  </div>
</ng-template>
