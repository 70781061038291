import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxTaxAdjustEventModalComponent } from './sbx-tax-adjust-event-modal.component';
import { SbxTaxAdjustEventModalModalDataModel } from './sbx-tax-adjust-event-modal-data.model';

@Injectable()
export class SbxTaxAdjustEventModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: SbxTaxAdjustEventModalModalDataModel) {
    const modal = this.sbxModalService.open(SbxTaxAdjustEventModalComponent, {
      size: 'lg',
      data,
    });

    return modal;
  }
}
