<a class="btn btn-link" (click)="openModal()" *ngIf="!this.modalOpen">
  <sbx-icon type="envelope"></sbx-icon>
  Activity Log
</a>

<ng-template #modalContent>
  <sbx-modal-header>User Activity Log for {{ displayName }}</sbx-modal-header>
  <sbx-alert-banner
    *ngIf="hasError"
    customText="There was an issue accessing this user's activity log."
    [error]="true"
  >
  </sbx-alert-banner>
  <div *ngIf="!hasError">
    <sbx-paging
      [page]="currentPage"
      [size]="pageSize"
      [totalItems]="totalItems"
      (change)="getPage($event)"
    ></sbx-paging>
    <sbx-table
      *sbxLoader="loading"
      [data]="tableData"
      [columns]="tableColumns"
      itemPlaceholderName="activities"
    >
    </sbx-table>
  </div>
</ng-template>

<ng-container *ngIf="this.modalOpen">
  <ng-template *ngTemplateOutlet="modalContent"></ng-template>
</ng-container>
