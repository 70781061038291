<sbx-alert-banner
  *ngIf="error$ | async"
  [error]="true"
  [customText]="error$ | async"
></sbx-alert-banner>
<div *ngIf="field.key === fields.email.key" class="sbx-verification-email">
  <div *ngIf="field.model[fields.email.key]" class="sbx-verification-email-value">
    <span>
      {{ field.model[fields.email.key] }}
    </span>
    <sbx-button icon="delete" title="Reset" (click)="resetEmail()"></sbx-button>
  </div>
  <div *ngIf="!field.model[fields.email.key]" class="sbx-verification-email-fields">
    <div class="sbx-email-recovery">
      <span class="sbx-verification-row">
        <input
          type="email"
          [name]="fields.email.key"
          [placeholder]="fields.email.placeholder"
          [ngModel]="fields.email.value"
          (ngModelChange)="handleEmailChange($event)"
          [ngClass]="{ 'has-errors': showError }"
        />

        <sbx-button
          icon="envelope"
          [title]="getSendButtonTitle()"
          [disabled]="fields.email.disabled || availableIn > 0"
          (click)="sendEmail()"
        ></sbx-button>
        <span class="sbx-send-text" *ngIf="availableIn !== null">Sent</span>
      </span>
      <span class="sbx-verification-row">
        <input
          type="number"
          [name]="fields.code.key"
          [placeholder]="fields.code.placeholder"
          [ngModel]="fields.code.value"
          (ngModelChange)="handleVerificationChange($event)"
          [ngClass]="{ 'has-errors': showError }"
        />
        <sbx-button
          icon="key"
          title="Verify Code"
          [loading]="loading$ | async"
          [disabled]="fields.code.disabled"
          (click)="sendCode()"
        ></sbx-button>
      </span>
    </div>
  </div>
</div>

<div *ngIf="field.key === fields.phone.key" class="sbx-verification-phone">
  <div *ngIf="field.model[fields.phone.key]" class="sbx-verification-phone-value">
    <span>
      {{ field.model[fields.phone.key] }}
    </span>

    <sbx-button icon="delete" title="Reset" (click)="resetPhone()"></sbx-button>
  </div>
  <div *ngIf="!field.model[fields.phone.key]" class="sbx-verification-phone-fields">
    <div class="sbx-phone-recovery">
      <span class="sbx-verification-row">
        <span class="sbx-verification-number">+1</span>
        <input
          #inputField
          type="number"
          [name]="fields.phone.key"
          [placeholder]="fields.phone.placeholder"
          [ngModel]="fields.phone.value"
          (ngModelChange)="handlePhoneChange($event)"
          [ngClass]="{ 'has-errors': showError }"
        />
        <sbx-button
          icon="comment"
          [title]="getSendButtonTitle()"
          [disabled]="fields.phone.disabled || availableIn > 0"
          (click)="sendPhone()"
        ></sbx-button>
        <span class="sbx-send-text" *ngIf="availableIn !== null">Sent</span>
      </span>
      <span class="sbx-verification-row">
        <input
          type="number"
          [name]="fields.code.key"
          [placeholder]="fields.code.placeholder"
          [ngModel]="fields.code.value"
          (ngModelChange)="handleVerificationChange($event)"
          [ngClass]="{ 'has-errors': showError }"
        />
        <sbx-button
          icon="key"
          title="Verify Code"
          [loading]="loading$ | async"
          [disabled]="fields.code.disabled"
          (click)="sendCode()"
        ></sbx-button>
      </span>
    </div>
  </div>
</div>
