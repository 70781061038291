import { ISbxIconType } from '@/core/constants/icons';
import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface ISbxWiPanelItem {
  buttons: {
    id: string;
    icon: ISbxIconType;
    title: string;
  }[];
  title: string;
  data: object;
}

export interface ISbxWiPanelClickEvent {
  buttonId: string;
  data: object;
}

@Component({
  selector: 'sbx-wi-panel-group',
  templateUrl: './sbx-wi-panel-group.component.html',
  styleUrls: ['./sbx-wi-panel-group.component.scss'],
})
export class SbxWiPanelGroupComponent {
  @Input() items: ISbxWiPanelItem[];
  @Output() buttonClick = new EventEmitter<ISbxWiPanelClickEvent>();
}
