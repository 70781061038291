import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxPeerCompanyAdminComponent } from './sbx-peer-company-admin.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxPeerCompanyAdminComponent],
  exports: [SbxPeerCompanyAdminComponent],
})
export class SbxPeerCompanyAdminModule {
  static entryComponents = [SbxPeerCompanyAdminComponent];

  static forRoot(): ModuleWithProviders<SbxPeerCompanyAdminModule> {
    return {
      ngModule: SbxPeerCompanyAdminModule,
    };
  }
}
