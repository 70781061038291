import angular from 'angular';
import URL from '../../lib/url';

import { sbRequestSupportProgress } from './progress';

/**
 * @ngdoc overview
 * @name sb.workitem.requestSupport
 *
 * @description
 * This is the main module for the request support workitem.
 */
export default angular
  .module('sb.workitem.requestSupport', [URL])

  .directive('sbRequestSupportProgress', sbRequestSupportProgress).name;
