import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { SbxIconTypesProvider } from './constants/icons';
import { HttpClientModule } from '@angular/common/http';
import { BackendLocation, SbxHttpClient } from './http';
import { processStatusModalProvider, pickFromDataroomModalProvider } from './upgraded';
import { AppConfig } from './config/app.config';
import { RavenErrorHandler } from './raven/handler';
import { SbxZoneService } from './zone';
import { APP_BASE_HREF } from '@angular/common';
import { SbxZendeskWidget } from './zendesk/zendesk.service';
import { SbxUserNotifications } from './user-notifications/user-notifications.service';
import { SbxNextLocationTrackerService } from './next-location-tracker';
import { AppcuesService } from './appcues/appcues.service';
import { SbxUrlService } from './url/url.service';
import { SbxExportFormProvider } from '@/sbx-export-form/sbx-export-form.provider';
import { SbxAutobahnAdapterService } from '@/core/ws/sbx-autobahn-adapter.service';
import { WS_ADAPTER_TOKEN } from '@/core/ws/ws-adapter.token';
import { SbxWsManualAdapterService } from './ws/sbx-ws-manual-adapter.service';
import { SbxWsService } from './ws/sbx-ws.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppConfig,
    BackendLocation,
    processStatusModalProvider,
    pickFromDataroomModalProvider,
    SbxHttpClient,
    SbxIconTypesProvider,
    SbxZoneService,
    SbxZendeskWidget,
    SbxUserNotifications,
    SbxNextLocationTrackerService,
    SbxUrlService,
    AppcuesService,
    SbxExportFormProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.initialize(),
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/spa/',
    },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: Window, useValue: window },
    SbxWsService,
    {
      provide: WS_ADAPTER_TOKEN,
      useFactory: (appConfig: AppConfig) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.shoobxTesting) {
          return new SbxWsManualAdapterService();
        }

        return new SbxAutobahnAdapterService(appConfig);
      },
      deps: [AppConfig],
    },
  ],
})
export class CoreModule {}
