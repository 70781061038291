import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

@Downgrade.Component('ngShoobx', 'sbx-radio')
@Component({
  selector: 'sbx-radio',
  templateUrl: './sbx-radio.component.html',
  styleUrls: ['sbx-radio.component.scss'],
})
export class SbxRadioComponent {
  @Input() name: string;
  @Input() label: string;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() value: any;
  @Input() model: any;
  @Input() disabled: boolean;
  // Optional custom label class, such as for process intro page radios.
  @Input() labelClass? = '';
  @Output() focus = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  handleFocus(event) {
    this.focus.emit(event);
  }

  handleClick(event) {
    this.handleFocus(event);
    this.emitChange();
  }

  emitChange() {
    if (this.model !== this.value) {
      this.modelChange.emit(this.value);
    }
  }

  handleKeydown(event) {
    // Enter key
    if (event.which === 13) {
      this.model = this.value;
      this.handleFocus(event);
      this.emitChange();
    }
  }
}
