import { Component, Inject, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SettingsProfileService } from '../../sbx-tree/service/settings-profile.service';

@Component({
  selector: 'sbx-profile-list',
  templateUrl: './sbx-profile-list.component.html',
  styleUrls: ['./sbx-profile-list.component.scss'],
})
export class SbxProfileListComponent extends FieldType<FieldTypeConfig> {
  root;
  @Input() set modelValue(value: any) {
    (this.model as any) = value;
  }
  @Input() modelKey;
  @Input() settingsType;

  constructor(@Inject(SettingsProfileService) private service: SettingsProfileService) {
    super();
  }

  handleChange(value) {
    this.formControl.setValue(value);
  }
}
