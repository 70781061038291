/**
 * @ngdoc component
 * @name sb.lib.form.component:sbReadOnlyValue
 *
 * @description
 * This directive is intended displaying unchanging values in a nicely formatted way
 * for forms.
 *
 * @param {any} value The value of the field.
 * @param {object} fieldDefinition The field's standard description object.
 */
export const sbReadOnlyValue = {
  controllerAs: 'vm',
  template: require('./templates/widgets/read-only.html'),
  bindings: {
    value: '<',
    options: '<fieldDefinition',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.to = this.options.templateOptions;
      };
    },
  ],
}; // end sbReadOnlyValue
