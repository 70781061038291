import angular from 'angular';
import { Map, List } from 'immutable';

export const sbTargetDiligenceMode = [
  function () {
    const FAILED = 'Failed to save diligence settings';

    function onChangeSort(table) {
      return (evt, col) => {
        if (col === table.sortCol) {
          table.sortCol.reverse = !table.sortCol.reverse;
        } else {
          table.sortCol.active = false;
          col.active = true;
          col.reverse = false;
          table.sortCol = col;
        }
        evt.preventDefault();
      };
    }

    const mapEventsToItems = (events, users) => {
      const profiles = Map(users);

      return List(
        events.reduce((items, event) => {
          // build the item
          const item = angular.extend(
            {
              $expandable: Boolean(event.attached && event.attached.length),
            },
            event,
          );
          [item].concat(item.attached || []).map((event) => {
            if (event.userId) {
              event.$user = profiles.get(event.userId);
            }
          });
          items.push(item);
          return items;
        }, []),
      );
    };

    function expandItem(item) {
      if (!item.$expanded) {
        return [item];
      }
      return List([item]).concat(item.attached);
    }

    function toggleCollapse(table, item) {
      if (item.$expandable) {
        item.$expanded = !item.$expanded;
      }
    }

    return {
      restrict: 'EA',
      template: require('./templates/target-diligence-mode.html'),
      scope: {
        sourceTitle: '@sbTargetDiligenceModeSourceTitle',
        model: '=sbTargetDiligenceModeModel',
        canEdit: '&sbTargetDiligenceModeCanEdit',
      },
      controller: [
        '$scope',
        '$formModal',
        'SimpleHTTPWrapper',
        '$sbModal',
        'PromiseErrorCatcher',
        'SbxUrlService',
        function (
          $scope,
          $formModal,
          SimpleHTTPWrapper,
          $sbModal,
          PromiseErrorCatcher,
          SbxUrlService,
        ) {
          function openModal() {
            let isLoading = true;
            $formModal(
              {
                title: 'Diligence Mode for ' + $scope.sourceTitle,
                htmlContent: require('./templates/target-diligence-modal.html'),
                loading: () => isLoading,
                extraInfo: {
                  sourceTitle: $scope.sourceTitle,
                },
                onConfirmPromise: ({ $formData }) => {
                  return SimpleHTTPWrapper(
                    {
                      method: 'POST',
                      url: $scope.model.$$diligenceUrl,
                      data: $formData.targetDiligenceForm,
                    },
                    FAILED,
                  ).then(() => {
                    $scope.model.$diligenceMode = $formData.targetDiligenceForm.enabled;
                  });
                },
                onOpenPromise: () =>
                  SimpleHTTPWrapper({
                    url: $scope.model.$$diligenceUrl,
                  }).then(($formData) => {
                    isLoading = false;
                    return {
                      forms: { targetDiligenceForm: $formData.description },
                      formData: { targetDiligenceForm: $formData.model },
                    };
                  }),
              },
              'lg',
            ).catch(PromiseErrorCatcher);
          }
          $scope.model.$popoverMsg = `Diligence mode enables bilateral Data Room transparency.
When enabled, Shoobx will track ${$scope.model.$relationTitle}'s activity in your
Data Room and can notify ${$scope.model.$relationTitle} when files are added to or modified
in your Data Room.`;
          $scope.editDiligence = openModal;
          $scope.viewLog = () => {
            $sbModal
              .open({
                template: require('./templates/target-diligence-report.html'),
                backdrop: 'static',
                size: 'lg',
                windowClass: 'diligence-report-modal',
                bindToController: true,
                controllerAs: 'vm',
                resolve: {
                  endpoint: () => $scope.model.$$diligenceUrl + '/report',
                  enabledDate: () => $scope.model.$diligenceEnabledDate,
                  relationTitle: () => $scope.model.$relationTitle,
                },
                controller: [
                  '$scope',
                  'endpoint',
                  'enabledDate',
                  'relationTitle',
                  function ($scope, endpoint, enabledDate, relationTitle) {
                    $scope.loading = true;
                    $scope.events = [];
                    $scope.tables = null;
                    $scope.diligenceEnabledDate = enabledDate;
                    $scope.relationTitle = relationTitle;

                    $scope.columns = [
                      {
                        title: 'Date Of Access',
                        name: 'date',
                        func: ($i) => {
                          return $i.timestamp;
                        },
                        reverse: true,
                      },
                      {
                        title: 'User',
                        name: 'user',
                        func: ($i) => {
                          if (!$i.userId) {
                            return null;
                          }
                          return $i.$user.fullName;
                        },
                      },
                      { title: 'File', name: 'file' },
                      { title: 'Action', name: 'action' },
                    ];
                    SimpleHTTPWrapper({
                      url: endpoint,
                    }).then(($data) => {
                      $scope.loading = false;
                      $scope.table = {
                        $items: mapEventsToItems($data.events, $data.users),
                        sortCol: $scope.columns[0],
                      };
                      $scope.table.changeSort = onChangeSort($scope.table);
                      $scope.table.expandItem = expandItem;
                      $scope.table.toggleCollapse = (item) => {
                        return toggleCollapse($scope.table, item);
                      };
                      $scope.table.sortCol.active = true;
                    });
                  },
                ],
              })
              .result.catch(PromiseErrorCatcher);
          };

          $scope.assetUrl = SbxUrlService.assetUrl;
        },
      ],
    };
  },
];

export const sbSourceDiligenceMode = [
  function () {
    const FAILED = 'Failed to save diligence settings';

    function onChangeSort(table) {
      return (evt, col) => {
        if (col === table.sortCol) {
          table.sortCol.reverse = !table.sortCol.reverse;
        } else {
          table.sortCol.active = false;
          col.active = true;
          col.reverse = false;
          table.sortCol = col;
        }
        evt.preventDefault();
      };
    }

    return {
      restrict: 'EA',
      template: require('./templates/source-diligence-mode.html'),
      scope: {
        model: '=sbSourceDiligenceModeModel',
        sourceTitle: '@sbSourceDiligenceModeSourceTitle',
        canEdit: '&sbSourceDiligenceModeCanEdit',
      },
      controller: [
        '$scope',
        '$formModal',
        'PromiseErrorCatcher',
        'SimpleHTTPWrapper',
        '$sbModal',
        'SbxUrlService',
        function (
          $scope,
          $formModal,
          PromiseErrorCatcher,
          SimpleHTTPWrapper,
          $sbModal,
          SbxUrlService,
        ) {
          function openModal() {
            let isLoading = true;
            $formModal({
              title: 'Diligence Mode for ' + $scope.sourceTitle,
              htmlContent: require('./templates/source-diligence-modal.html'),
              loading: () => isLoading,
              size: 'lg',
              extraInfo: {
                sourceTitle: $scope.sourceTitle,
              },
              onConfirmPromise: ({ $formData }) => {
                return SimpleHTTPWrapper(
                  {
                    method: 'POST',
                    url: $scope.model.$$diligenceUrl,
                    data: $formData.sourceDiligenceForm,
                  },
                  FAILED,
                );
              },
              onOpenPromise: () =>
                SimpleHTTPWrapper({
                  url: $scope.model.$$diligenceUrl,
                }).then(($formData) => {
                  isLoading = false;
                  return {
                    forms: { sourceDiligenceForm: $formData.description },
                    formData: { sourceDiligenceForm: $formData.model },
                  };
                }),
            }).catch(PromiseErrorCatcher);
          }
          $scope.editDiligence = openModal;

          $scope.viewLog = function () {
            $sbModal
              .open({
                template: require('./templates/source-diligence-report.html'),
                backdrop: 'static',
                size: 'lg',
                windowClass: 'diligence-report-modal',
                bindToController: true,
                controllerAs: 'vm',
                resolve: {
                  endpoint: () => $scope.model.$$diligenceUrl + '/report',
                  enabledDate: () => $scope.model.$diligenceEnabledDate,
                },
                controller: [
                  '$scope',
                  'endpoint',
                  'enabledDate',
                  function ($scope, endpoint, enabledDate) {
                    $scope.loading = true;
                    $scope.events = [];
                    $scope.tables = null;
                    $scope.diligenceEnabledDate = enabledDate;
                    SimpleHTTPWrapper({
                      url: endpoint,
                    }).then(($data) => {
                      $scope.loading = false;
                      $scope.tables = $data.tables.map((tableData) => {
                        const table = {
                          $items: List(tableData.events),
                          name: tableData.name,
                          title: tableData.title,
                          columns: [
                            {
                              title: 'Date Of Activity',
                              name: 'date',
                              func: ($i) => {
                                return $i.timestamp;
                              },
                              reverse: true,
                            },
                            {
                              title: 'File',
                              name: 'file',
                              func: ($i) => {
                                return $i.documentTitle;
                              },
                            },
                            {
                              title: 'Location',
                              name: 'location',
                              func: ($i) =>
                                $i.folderPath.map(($p) => $p.title).join(','),
                            },
                          ],
                        };
                        table.changeSort = onChangeSort(table);
                        table.sortCol = table.columns[0];
                        table.sortCol.active = true;
                        return table;
                      });
                    });
                  },
                ],
              })
              .result.catch(PromiseErrorCatcher);
          };

          $scope.assetUrl = SbxUrlService.assetUrl;
        },
      ],
    };
  },
];

export const DataRoomDiligenceCtrl = [
  '$scope',
  '$sbModal',
  'TargetRelationship',
  'SimpleHTTPWrapper',
  function ($scope, $sbModal, TargetRelationship, SimpleHTTPWrapper) {
    const FAILED = 'Failed to save diligence settings';
    function isError() {
      const { diligenceStakeholder } = $scope.model.$formData.pickDiligenceRelationship;
      return !(diligenceStakeholder && diligenceStakeholder.sh);
    }

    function onSelectStakeholder() {
      const { sh } =
        $scope.model.$formData.pickDiligenceRelationship.diligenceStakeholder;
      $scope.model = TargetRelationship(sh.id, sh.relationType, sh.title);
      $scope.model.$init().catch((reason) => {
        $scope.error = reason;
      });
      $scope.model.$forms = { pickDiligenceRelationship: formDesc.description };
      $scope.model.$formData = { pickDiligenceRelationship: formDesc.model };
      SimpleHTTPWrapper({
        url: $scope.model.$$diligenceUrl,
      }).then((data) => {
        $scope.model.$forms.targetDiligenceForm = data.description;
        $scope.model.$formData.targetDiligenceForm = data.model;
        $scope.extraInfo = { sourceTitle: sh.title };
      });
    }

    function onComplete() {
      return SimpleHTTPWrapper(
        {
          method: 'POST',
          url: $scope.model.$$diligenceUrl,
          data: $scope.model.$formData.targetDiligenceForm,
        },
        FAILED,
      ).then(
        () => {
          $scope.$close();
        },
        (error) => {
          $scope.$error = error;
        },
      );
    }

    // Hard code initial description
    const formDesc = {
      model: {
        diligenceStakeholder: null,
      },
      description: {
        fields: [
          {
            type: 'stakeholder',
            templateOptions: {
              stakeholderButtonTitle: 'External Party',
              stakeholderOptions: {
                creationModalTitleText: null,
                creationModalIndividualLabel: null,
                allowCreation: false,
                includeStates: null,
                requireEmail: true,
                format: 'full',
                allowStakes: false,
                allowPerson: false,
                affiliatePlaceholder: '',
                allowEntity: true,
                affiliateCheckboxLabel: '',
                creationModalEntityNameLabel: null,
                placeholderText: null,
                onlyActive: false,
                creationModalTeamLabel: null,
                entityOptions: {
                  allowSelfTeam: false,
                  type: '',
                  affiliates: false,
                },
                affiliateLabel: '',
                allowExisting: true,
                alwaysDisplayAffiliate: false,
                creationModalEntityNamePlaceholder: null,
              },
              subfield: 0,
              required: true,
              label: 'External Party',
            },
            data: {},
            key: 'diligenceStakeholder',
          },
        ],
      },
    };
    $scope.model = {};
    $scope.model.$forms = { pickDiligenceRelationship: formDesc.description };
    $scope.model.$formData = { pickDiligenceRelationship: formDesc.model };
    $scope.onSelectStakeholder = onSelectStakeholder;
    $scope.onComplete = onComplete;
    $scope.isError = isError;
  },
];

export const DataRoomReportsCtrl = [
  '$scope',
  '$sbModal',
  '$q',
  'TargetRelationship',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function (
    $scope,
    $sbModal,
    $q,
    TargetRelationship,
    SimpleHTTPWrapper,
    BackendLocation,
  ) {
    function onChangeSort(table) {
      return (evt, col) => {
        if (col === table.sortCol) {
          table.sortCol.reverse = !table.sortCol.reverse;
        } else {
          table.sortCol.active = false;
          col.active = true;
          col.reverse = false;
          table.sortCol = col;
        }
        evt.preventDefault();
      };
    }

    const mapEventsToItems = (events, users) => {
      const profiles = Map(users);

      return List(
        events.reduce((items, event) => {
          // build the item
          const item = angular.extend(
            {
              $expandable: Boolean(event.attached && event.attached.length),
            },
            event,
          );
          [item].concat(item.attached || []).map((event) => {
            if (event.userId) {
              event.$user = profiles.get(event.userId);
            }
          });
          items.push(item);
          return items;
        }, []),
      );
    };

    function expandItem(item) {
      if (!item.$expanded) {
        return [item];
      }
      return List([item]).concat(item.attached);
    }

    function toggleCollapse(table, item) {
      if (item.$expandable) {
        item.$expanded = !item.$expanded;
      }
    }

    function getRelationships() {
      const url = BackendLocation.entity(1) + 'diligence-reports';
      return SimpleHTTPWrapper({
        method: 'GET',
        url: url,
      }).then((data) => {
        $scope.model.relationships = data;
      });
    }

    function updateDiligenceReport() {
      $scope.model.relationship = null;
      for (const item of $scope.model.relationships) {
        if (item.id === $scope.model.selectedItem) {
          $scope.model.relationship = TargetRelationship(item.id, item.type, item.name);
        }
      }
      if (!$scope.model.relationship) {
        return;
      }
      // Don't call init because we can't
      SimpleHTTPWrapper({
        method: 'GET',
        url: $scope.model.relationship.$$diligenceUrl + '/status',
      }).then((data) => {
        $scope.diligenceEnabledDate = data.enabledDate;
      });
      $scope.loading = true;
      $scope.events = [];
      $scope.tables = null;

      $scope.columns = [
        {
          title: 'Date Of Access',
          name: 'date',
          func: ($i) => {
            return $i.timestamp;
          },
          reverse: true,
        },
        {
          title: 'User',
          name: 'user',
          func: ($i) => {
            if (!$i.userId) {
              return null;
            }
            return $i.$user.fullName;
          },
        },
        { title: 'File', name: 'file' },
        { title: 'Action', name: 'action' },
      ];
      $q.all([
        SimpleHTTPWrapper({
          method: 'GET',
          url: $scope.model.relationship.$$diligenceUrl + '/status',
        }).then((data) => {
          $scope.diligenceEnabledDate = data.enabledDate;
        }),
        SimpleHTTPWrapper({
          url: $scope.model.relationship.$$diligenceUrl + '/report',
          method: 'GET',
        }).then(($data) => {
          $scope.table = {
            $items: mapEventsToItems($data.events, $data.users),
            sortCol: $scope.columns[0],
          };
          $scope.table.changeSort = onChangeSort($scope.table);
          $scope.table.expandItem = expandItem;
          $scope.table.toggleCollapse = (item) => {
            return toggleCollapse($scope.table, item);
          };
          $scope.table.sortCol.active = true;
        }),
      ]).finally(() => {
        $scope.loading = false;
      });
    }

    $scope.model = { relationships: $scope.relationships };
    $scope.getRelationships = getRelationships;
    $scope.updateDiligenceReport = updateDiligenceReport;
    getRelationships();
  },
];
