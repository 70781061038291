import angular from 'angular';

/**
 * @ngdoc component
 * @name sb.lib.administration.component:sbPreviewDocumentsWorkitem
 *
 * @description
 * This is the main component for the Preview Documents Workitem.
 *
 * @param {string} documentId The doc ID of the document being previewed.
 */
const sbPreviewDocumentsWorkitem = {
  controllerAs: 'vm',
  template: require('./templates/workitem.html'),
  bindings: {
    documentId: '<',
  },
  controller: [
    'ProcessUrlInfo',
    function (ProcessUrlInfo) {
      function $onInit() {
        // On parent we have much more "user chrome"/headers etc so we need more offset.
        const isParentProcess = ProcessUrlInfo.isParentProcess();
        this.sizeOffset = isParentProcess ? 450 : 150;
      }
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbPreviewDocumentsWorkitem

export default angular
  .module('sb.workitem.previewDocuments', [])

  .component('sbPreviewDocumentsWorkitem', sbPreviewDocumentsWorkitem).name;
