import angular from 'angular';
import Stakeholder from './../stakeholder';

import { sbApprovalList } from './list';
import { sbStakeholderApprovalUI, sbStakeholderApprovalItem } from './approvalui';

/**
 * @ngdoc overview
 * @name sb.lib.shapprovals
 *
 * @description
 * UI for stakeholder approvals
 */
export default angular
  .module('sb.lib.shapprovals', [Stakeholder])

  .component('sbApprovalList', sbApprovalList)
  .directive('sbStakeholderApprovalUi', sbStakeholderApprovalUI)
  .directive('sbStakeholderApprovalItem', sbStakeholderApprovalItem).name;
