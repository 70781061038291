import { Injectable, Inject, TemplateRef } from '@angular/core';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import {
  GenericDeleteResponse,
  GenericStatusResponse,
  HoldingValidationResult,
  InformationRightsFieldsSearchResults,
  InvestmentVehiclesResponse,
  PickupFile,
  PieLiquidationStack,
  PiePortfolioStatus,
  PiePortfolioSummary,
  PiePortfolioSummaryCompany,
  PieUiConfig,
  PortfolioCompaniesLabels,
  PortfolioCompanyEditForm,
  PortfolioCompanyProfile,
  PortfolioCompanyProfileData,
  PortfolioCompanyProfileDocuments,
  PortfolioCompanySearchResults,
  PortfolioInformationRightsMetrics,
  ProcessInfo,
  Session,
  TransactionsSearchResults,
} from '@shoobx/types/pieapi-v1';
import { Observable, of } from 'rxjs';
import { ISbxChartConfigDataColors } from '@/shared/sbx-chart/sbx-chart.component';

const GROUP_DEFAULT_POSTFIX = '-group_default';

export enum ProviderType {
  Profile = 'profile',
  Documents = 'documents',
  Personnel = 'personnel',
  Holdings = 'holdings',
  Ownership = 'ownership',
  StockClasses = 'stockClasses',
  Valuations = 'valuations',
  Financials = 'financials',
  Liabilities = 'liabilities',
  Events = 'events',
  Notes = 'notes',
  Timeline = 'timeline',
  Termsheets = 'termsheets',
  Inforights = 'inforights',
}

export type PortfolioCompanySearchResultsSortBy =
  | 'title'
  | 'investment'
  | 'last-inv-date'
  | 'realized'
  | 'unrealized';
export type PortfolioCompanySearchResultsSortDir = 'asc' | 'desc';

export const generateChartColors = (
  companies: PiePortfolioSummaryCompany[],
): ISbxChartConfigDataColors => {
  const colors = Object.values(companies).reduce(
    (acc: ISbxChartConfigDataColors, c) => {
      acc[c.displayName] = c.color;
      return acc;
    },
    {},
  );

  return colors;
};

@Injectable()
export class SbxInvestmentService {
  API_VERSION = 'pie_1';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
  ) {}

  getVehicles() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<InvestmentVehiclesResponse>('vehicles');
  }

  getLabels() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioCompaniesLabels>('companies/labels');
  }

  getCompanyProfile(companyId: string, providers?: ProviderType[]) {
    const searchParams = new URLSearchParams();
    if (providers) {
      searchParams.append('providers', providers.join(','));
    }

    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioCompanyProfileData>(
        `companies/${companyId}/profile-data?${searchParams}`,
      );
  }

  getSummary(investor?: string, label?: string): Observable<PiePortfolioSummary> {
    const params: { investor?: string; label?: string } = {};
    if (investor) {
      params.investor = investor;
    }
    if (label) {
      params.label = label;
    }

    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PiePortfolioSummary>('portfolio/summary', { params });
  }

  searchCompanies(params?: {
    investor?: string;
    label?: string;
    title?: string;
    start?: number;
    limit?: number;
    sortBy?: PortfolioCompanySearchResultsSortBy;
    sortDir?: PortfolioCompanySearchResultsSortDir;
    withEquity?: boolean;
    withExit?: boolean;
  }): Observable<PortfolioCompanySearchResults> {
    const reqParams = {};

    if (params?.start) {
      // eslint-disable-next-line dot-notation
      reqParams['start'] = params.start;
    }

    if (params?.limit) {
      // eslint-disable-next-line dot-notation
      reqParams['limit'] = params.limit;
    }

    if (params?.investor) {
      // eslint-disable-next-line dot-notation
      reqParams['investor'] = params.investor;
    }

    if (params?.label) {
      // eslint-disable-next-line dot-notation
      reqParams['label'] = params.label;
    }

    if (params?.title) {
      // eslint-disable-next-line dot-notation
      reqParams['title'] = params.title;
    }

    if (params?.sortBy) {
      reqParams['sort-by'] = params.sortBy;
    }

    if (params?.sortDir) {
      reqParams['sort-dir'] = params.sortDir;
    }

    if (params?.withEquity) {
      reqParams['with-equity'] = true;
    }
    if (params?.withExit) {
      reqParams['with-exit'] = true;
    }

    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioCompanySearchResults>('companies/search', { params: reqParams });
  }

  getCompany(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioCompanyProfile>(`companies/${companyId}/profile`);
  }

  removeCompany(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}`)
      .toPromise();
  }

  async getCompanyForm(
    companyId: string,
    topMessageTemplate: TemplateRef<any>,
  ): Promise<PortfolioCompanyEditForm> {
    function getGroupDefaultField(mdl, key) {
      const flds = Object.keys(mdl);
      const fldKey = flds.find((k) => key.indexOf(k) === 0);

      return {
        key: fldKey,
        value: mdl[`${fldKey}${GROUP_DEFAULT_POSTFIX}`],
      };
    }

    function setGroupDefaultField(mdl, key, value) {
      const fieldKey = getGroupDefaultField(mdl, key).key;
      mdl[`${fieldKey}${GROUP_DEFAULT_POSTFIX}`] = value;
    }

    const { description, model, overridden, overrideable, fromExternal, shoobxData } =
      await this.sbxHttpClient
        .entity(this.API_VERSION)
        .get<PortfolioCompanyEditForm>(`companies/${companyId}/edit`)
        .toPromise();

    const formFields = fromExternal
      ? of(
          (<Record<string, any>>description).fields.map((field, i) => {
            const isOverrideable = overrideable.indexOf(field.key) > -1;
            const renderShoobxTemplate = (key) => {
              const data = shoobxData[field.key];
              if (!data) {
                return '---';
              }

              switch (key) {
                case 'url':
                  return `<a href="${data}" target="_blank">${data}</a>`;
                case 'logo':
                  return `<img src="${data}" width="100%">`;
                default:
                  return data;
              }
            };

            const shoobxTemplate = `
        <span>
          ${isOverrideable ? renderShoobxTemplate(field.key) : ''}
        </span>
      `;

            return {
              fieldGroup: [
                isOverrideable
                  ? {
                      key: `${field.key}${GROUP_DEFAULT_POSTFIX}`,
                      type: 'bool-checkbox',
                      defaultValue: overridden.includes(field.key),
                      templateOptions: {
                        className: 'sbx-align-checkbox',
                        grid: {
                          lg: 1,
                        },
                      },
                    }
                  : {
                      template: '<span></span>',
                      templateOptions: {
                        grid: {
                          lg: 1,
                        },
                      },
                    },
                {
                  ...field,
                  templateOptions: {
                    focus: () => {
                      setGroupDefaultField(model, field.parentKey || field.key, true);
                    },
                    ...field.templateOptions,
                    grid: {
                      lg: 9,
                    },
                  },
                  expressionProperties: {
                    'templateOptions.className': () => {
                      return (
                        overrideable.includes(field.key) &&
                        !getGroupDefaultField(model, field.parentKey || field.key)
                          .value &&
                        'show-opacity'
                      );
                    },
                  },
                },
                {
                  template: shoobxTemplate,
                  templateOptions: {
                    grid: {
                      lg: 2,
                    },
                  },
                },
              ],
              hideExpression: field.hideExpression,
              templateOptions: {},
            };
          }),
        )
      : of((<Record<string, any>>description).fields);

    return new Promise((resolve) =>
      this.sbxFormModalService
        .open({
          data: {
            topMessageTemplate: fromExternal ? topMessageTemplate : null,
            title: 'Edit Corporate Information',
            formFields,
            model,
            saveUrl: `companies/${companyId}/edit`,
            urlVersion: this.API_VERSION,
          },
          size: fromExternal ? 'lg' : 'md',
        })
        .result.then(resolve)
        .catch(() => null),
    );
  }

  getPersonnelCreateFormUrl(companyId: string) {
    return `companies/${companyId}/personnel/create`;
  }

  getPersonnelEditFormUrl(companyId: string, personId: string) {
    return `companies/${companyId}/personnel/${personId}/edit`;
  }

  removePersonnel(companyId: string, personId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/personnel/${personId}`)
      .toPromise();
  }

  getBackgroundEditFormUrl(companyId: string) {
    return `companies/${companyId}/background`;
  }

  getDocuments(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioCompanyProfileDocuments>(`companies/${companyId}/documents`);
  }

  getDocumentsFormUrl(companyId: string) {
    return `companies/${companyId}/documents/upload`;
  }

  getValuationCreateFormUrl(companyId: string) {
    return `companies/${companyId}/valuations/create`;
  }

  getValuationEditFormUrl(companyId: string, valuationId: string) {
    return `companies/${companyId}/valuations/${valuationId}/edit`;
  }

  removeValuation(companyId: string, valuationId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/valuations/${valuationId}`)
      .toPromise();
  }

  getTimelineCreateFormUrl(companyId: string) {
    return `companies/${companyId}/events/create`;
  }

  getTimelineEditFormUrl(companyId: string, valuationId: string) {
    return `companies/${companyId}/events/${valuationId}/edit`;
  }

  removeTimeline(companyId: string, valuationId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/events/${valuationId}`)
      .toPromise();
  }

  getFinancialCreateFormUrl(companyId: string) {
    return `companies/${companyId}/financials/create`;
  }

  getFinancialEditFormUrl(companyId: string, financialId: string) {
    return `companies/${companyId}/financials/${financialId}/edit`;
  }

  removeFinancial(companyId: string, financialId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/financials/${financialId}`)
      .toPromise();
  }

  getStockClassCreateFormUrl(companyId: string) {
    return `companies/${companyId}/stockClasses/create`;
  }

  getStockClassEditFormUrl(companyId: string, stockClassId: string) {
    return `companies/${companyId}/stockClasses/${stockClassId}/edit`;
  }

  removeStockClass(companyId: string, stockClassId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(
        `companies/${companyId}/stockClasses/${stockClassId}`,
      )
      .toPromise();
  }

  getLiquidationStack(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PieLiquidationStack>(`companies/${companyId}/liquidationStack`);
  }

  saveLiquidationStack(companyId: string, stack: PieLiquidationStack) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`companies/${companyId}/liquidationStack`, {
        params: {
          stack,
        },
      });
  }

  setArchived(companyId: string, archived: boolean) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`companies/${companyId}/archived`, {
        params: {
          archived,
        },
      });
  }

  setWithGeneratedValuation(companyId: string, state: boolean) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`companies/${companyId}/valuations/with-generated`, {
        params: {
          state,
        },
      })
      .toPromise();
  }

  getInvestmentCreateFormUrl(companyId: string) {
    return `companies/${companyId}/simpleholdings/create`;
  }

  getInvestmentEditFormUrl(companyId: string, investmentId: string) {
    return `companies/${companyId}/simpleholdings/${investmentId}/edit`;
  }

  removeInvestment(companyId: string, investmentId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/holdings/${investmentId}`)
      .toPromise();
  }

  getLiabilityCreateFormUrl(companyId: string) {
    return `companies/${companyId}/liabilities/create`;
  }

  getLiabilityEditFormUrl(companyId: string, liabilityId: string) {
    return `companies/${companyId}/liabilities/${liabilityId}/edit`;
  }

  removeLiability(companyId: string, liabilityId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(
        `companies/${companyId}/liabilities/${liabilityId}`,
      )
      .toPromise();
  }

  getScenarioTitleEditUrl(scenarioKey: string) {
    return `scenarioTitle?scenario=${scenarioKey}`;
  }

  getNoteCreateFormUrl(companyId: string): string {
    return `companies/${companyId}/notes/create`;
  }

  getNoteEditFormUrl(companyId: string, noteId: string) {
    return `companies/${companyId}/notes/${noteId}/edit`;
  }

  removeNote(companyId: string, noteId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/notes/${noteId}`)
      .toPromise();
  }

  getTermsheetCreateFormUrl(companyId: string): string {
    return `companies/${companyId}/termsheets/create`;
  }

  getTermsheetEditFormUrl(companyId: string, termsheetId: string) {
    return `companies/${companyId}/termsheets/${termsheetId}/edit`;
  }

  removeTermsheet(companyId: string, termsheetId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/termsheets/${termsheetId}`)
      .toPromise();
  }

  getTermsheetShareFormUrl(companyId: string, termsheetId: string) {
    return `companies/${companyId}/termsheets/${termsheetId}/share`;
  }

  getPortfolioExcelExportUrl(investor?: string, label?: string) {
    let url = 'export';
    if (investor) {
      url = url + `?investor=${investor}&label=${label}`;
    }
    return url;
  }

  getPortfolioPdfExportUrl(investor?: string, label?: string) {
    let url = 'pdf';
    if (investor) {
      url = url + `?investor=${investor}&label=${label}`;
    }
    return url;
  }

  getCompanyPdfExportUrl(companyId: string) {
    return `companies/${companyId}/pdf`;
  }

  getOwnershipXlsx(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PickupFile>(`companies/${companyId}/ownership.xlsx`);
  }

  shareTermsheet(companyId: string, termsheetId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post(`companies/${companyId}/termsheets/${termsheetId}/share`);
  }

  duplicateTermsheet(companyId: string, termsheetId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post(`companies/${companyId}/termsheets/${termsheetId}/duplicate`);
  }

  getTermsheetPdf(companyId: string, termsheetId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PickupFile>(
        `companies/${companyId}/termsheets/${termsheetId}/termsheet.pdf`,
      );
  }

  executeTermsheet(companyId: string, termsheetId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<ProcessInfo>(`companies/${companyId}/termsheets/${termsheetId}/execute`);
  }

  getPortfolioStatus({ start, limit }) {
    const params = { start, limit };
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PiePortfolioStatus>('portfolio/status', { params });
  }

  getPlannerExitProjections(companyId: string) {
    return `companies/${companyId}/planner`;
  }

  getHoldingForm(companyId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<Session>(`companies/${companyId}/holdings/create`);
  }

  getHolding(companyId: string, holdingId: string, sessionId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TransactionsSearchResults>(
        `companies/${companyId}/holdings/${holdingId}/sessions/${sessionId}/search`,
      );
  }

  validateHolding(companyId: string, holdingId: string, sessionId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<HoldingValidationResult>(
        `companies/${companyId}/holdings/${holdingId}/sessions/${sessionId}/validate`,
      );
  }

  resetHolding(companyId: string, sessionId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericStatusResponse>(
        `companies/${companyId}/holdings/sessions/${sessionId}/reset`,
      );
  }

  getInitialInvestmentCreateFormUrl(companyId: string, sessionId: string) {
    return `companies/${companyId}/holdings/sessions/${sessionId}/create`;
  }

  getTransactionCreateFormUrl(companyId: string, holdingId: string, sessionId: string) {
    return `companies/${companyId}/holdings/${holdingId}/sessions/${sessionId}/create`;
  }

  getTransactionEditFormUrl(
    companyId: string,
    holdingId: string,
    eventId: string,
    sessionId: string,
  ) {
    return `companies/${companyId}/holdings/${holdingId}/${eventId}/sessions/${sessionId}/edit`;
  }

  removeTransaction(
    companyId: string,
    holdingId: string,
    eventId: string,
    sessionId: string,
  ) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(
        `companies/${companyId}/holdings/${holdingId}/${eventId}/sessions/${sessionId}`,
      )
      .toPromise();
  }

  getCompanyCreateFormUrl() {
    return 'companies';
  }

  searchInfoRights(params?: {
    investor?: string;
    label?: string;
    title?: string;
    start?: number;
    limit?: number;
  }): Observable<PortfolioInformationRightsMetrics> {
    const reqParams = {};

    if (params?.start) {
      // eslint-disable-next-line dot-notation
      reqParams['start'] = params.start;
    }

    if (params?.limit) {
      // eslint-disable-next-line dot-notation
      reqParams['limit'] = params.limit;
    }

    if (params?.investor) {
      // eslint-disable-next-line dot-notation
      reqParams['investor'] = params.investor;
    }

    if (params?.label) {
      // eslint-disable-next-line dot-notation
      reqParams['label'] = params.label;
    }

    if (params?.title) {
      // eslint-disable-next-line dot-notation
      reqParams['title'] = params.title;
    }

    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PortfolioInformationRightsMetrics>('companies/inforights', { params: reqParams });
  }

  // XXX: Bad name
  getMetrics(): Observable<InformationRightsFieldsSearchResults> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<InformationRightsFieldsSearchResults>('inforights/search');
  }

  getMetricCreateFormUrl() {
    return 'inforights/create';
  }

  getMetricEditFormUrl(id: string) {
    return `inforights/${id}/edit`;
  }

  removeMetric(id: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`inforights/${id}`)
      .toPromise();
  }

  getMetricEntryCreateFormUrl(companyId, metricId) {
    return `companies/${companyId}/inforights/${metricId}/create`;
  }

  getMetricEntryEditFormUrl(companyId, id) {
    return `companies/${companyId}/inforights/${id}/edit`;
  }

  removeMetricEntry(companyId, id) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`companies/${companyId}/inforights/${id}`)
      .toPromise();
  }
}
