export const nestedValueByPath = <Input, Output>(
  object: Input,
  path: string,
  delimiter = '.',
): Output => {
  const pathFragments = path.split(delimiter);

  return pathFragments.reduce(
    (previousValue: Input | unknown, currentValue: string) =>
      previousValue[currentValue],
    object,
  );
};
