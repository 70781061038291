<input
  type="text"
  ngbDatepicker
  #datePicker="ngbDatepicker"
  datepickerClass="sbx-datepicker-window"
  [firstDayOfWeek]="7"
  [attr.id]="id"
  [attr.name]="id"
  [ngModel]="formattedValue"
  (ngModelChange)="handleChange($event)"
  [ngClass]="{ 'has-errors': showError }"
  [minDate]="minDate"
  [disabled]="readOnly"
  [container]="container"
  (click)="handleFocus($event)"
  (blur)="handleBlur($event)"
  (closed)="closed.emit()"
/>
