import angular from 'angular';
/**
 * @ngdoc component
 * @name sb.lib.approval.component:sbApprovalList
 *
 * @description
 * This displays a list of approval items with an approve or reject
 * button. Rejection allows a rejection reason. The inner html of this
 * element will be the content of the listing `$item` is in the template
 * namespace, as well as any other `ngRepeat` vars.
 *
 * @param {expression} ngModel Model expression. Should be an array of at least:
 *   @property {'approved'|'rejected'} status
 *   @property {string} reason Info about rejection.
 */
export const sbApprovalList = {
  template: [
    '$element',
    function ($element) {
      const $baseTemplate = angular.element(require('./templates/list.html'));
      $baseTemplate.find('item-content').replaceWith($element.html());
      return $baseTemplate;
    },
  ],
  controllerAs: 'vm',
  require: 'ngModel',
  bindings: {
    model: '=ngModel',
  },
}; // end sbApprovalList
