import { Component, Input } from '@angular/core';
import { Downgrade } from '../downgrade';

const MIN_FULL_SIZE_HEIGHT = 23;

@Downgrade.Component('ngShoobx', 'sbx-progress-bar')
@Component({
  selector: 'sbx-progress-bar',
  templateUrl: './sbx-progress-bar.component.html',
  styleUrls: ['./sbx-progress-bar.component.scss'],
})
export class SbxProgressBarComponent {
  @Input() pending = 0;
  @Input() completed = 0;
  @Input() total = 100;
  @Input() height = MIN_FULL_SIZE_HEIGHT;
  @Input() threshold = 0;

  isFullSize() {
    return this.height >= MIN_FULL_SIZE_HEIGHT;
  }

  remainingPercent() {
    if (!this.isFullSize()) {
      return this.total - this.completed - this.pending;
    } else {
      return 0;
    }
  }
}
