import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxConfirmationModalComponent } from './sbx-confirmation-modal.component';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxConfirmationModalService } from './sbx-confirmation-modal.service';

@NgModule({
  imports: [CommonModule, SbxButtonModule, SbxModalModule],
  declarations: [SbxConfirmationModalComponent],
  providers: [SbxConfirmationModalService],
  exports: [SbxConfirmationModalComponent],
})
export class SbxConfirmationModalModule {}
