import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxInvestorModalComponent } from './sbx-investor-modal.component';
import { SbxInvestorModalDataModel } from '@/sbx-investment/sbx-investor-modal/sbx-investor-modal-data.model';

@Injectable()
export class SbxInvestorModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: SbxInvestorModalDataModel) {
    const modal = this.sbxModalService.open(SbxInvestorModalComponent, {
      size: 'md',
      data,
    });

    return modal;
  }
}
