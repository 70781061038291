import { Map, List } from 'immutable';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.document.object:PickFromModel
 *
 * @description
 * This factory function will create an instance of a model for managing the state
 * of the pick from modal.
 *
 * @param {string} rootFolderId The ID of the root/initial folder for this modal.
 * @param {boolean} [showManaged=false] Truthy means show managed documents
 *   for selection.
 * @param {array<string>} [managedTagFilter=undefined] The array of tags to filter
 *   managed on.
 * @param {function} [excludeFn=() => true] given a document object, returns true if
 *   it should be included in documents
 */
export const PickFromModel = [
  '$rootScope',
  '$observable',
  'BackendLocation',
  'ProcessUrlInfo',
  function ($rootScope, $observable, BackendLocation, ProcessUrlInfo) {
    class Model {
      constructor(
        rootFolderId,
        showManaged,
        managedTagFilter,
        excludeFn,
        allowUnfinalized,
        allowPending,
        allowAllFromProc = false,
      ) {
        this._url = BackendLocation.entity(1) + 'documents';
        this._defaultParams = Object.freeze({
          anyTags:
            managedTagFilter && managedTagFilter.length
              ? managedTagFilter.concat(['Status : Unmanaged'])
              : undefined,
          managed: Boolean(showManaged) || allowAllFromProc,
          filterEmptyFolders: true,
          // "pending" is a filterspec. If set to true or false it'll only select documents
          // with those tags; if left undefined it will select all documents. Thus if we are
          // to allowPending then we want BOTH pending docs and other docs.
          pending: allowPending || allowAllFromProc ? undefined : false,
          visible: !allowAllFromProc,
          finalized: (!allowUnfinalized && !allowAllFromProc) || undefined,
          includeDocsFromProcFamily: ProcessUrlInfo.processId(),
          allFromProc: allowAllFromProc,
        });
        this._exclude = excludeFn || (() => true);
        this._rootFolderId = rootFolderId;
        this._requests = new Subject();
        this._fullCleanBeforeLoad();

        /**
         * @ngdoc property
         * @name documents
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Immutable list of (sorted) documents within the current folder.
         */
        this.documents = List();

        /**
         * @ngdoc property
         * @name folders
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Immutable list of "subfolders" within the current folder.
         */
        this.folders = List();

        /**
         * @ngdoc property
         * @name showManaged
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Boolean for whether or not we want to show managed documents
         */
        this.showManaged = showManaged;

        /**
         * @ngdoc property
         * @name searchQuery
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * String search query (should be used with ngModel).
         */
        this.searchQuery = '';

        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Boolean if requests are outstanding.
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name folderPath
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Array folder path of the current folder.
         */
        this.folderPath = [];

        /**
         * @ngdoc property
         * @name currentFolder
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Currently displayed folder object.
         */
        this.currentFolder = undefined;
      }

      _startLoad() {
        this.loading = true;

        /**
         * @ngdoc property
         * @name error
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * String error of last failed request.
         */
        this.error = null;
      }

      _fullCleanBeforeLoad() {
        this._startLoad();
        this._docDetailsOpen = Map();

        /**
         * @ngdoc property
         * @name currentDocument
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * Currently selected document object.
         */
        this.currentDocument = undefined;

        /**
         * @ngdoc property
         * @name sortKey
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * The current sort columnname. Either `'name'` or `'date'`.
         */
        this.sortKey = 'name';

        /**
         * @ngdoc property
         * @name sortOrder
         * @propertyOf sb.lib.document.PickFromModel
         *
         * @description
         * The current order. Either `'dsc'` or `'asc'`.
         */
        this.sortOrder = 'dsc';
      }

      _handleResponse({ status, response }) {
        $rootScope.$applyAsync(() => {
          this.loading = false;
          if (status === 200) {
            const { folder, folderPath, subfolders, items } = response;
            this.currentFolder = folder;
            this.folderPath = folderPath;
            this.folders = List(
              (subfolders || []).map((folder) =>
                Object.assign(folder, {
                  breadcrumbs:
                    folder.path && folder.path.length > 2
                      ? folder.path.slice(1, -1)
                      : null,
                }),
              ),
            );
            this.documents = List(items).filter(this._exclude);
            return;
          }
          this.error = 'Error loading documents.';
        });
      }

      _processParams(overrides) {
        const { currentFolder, searchQuery } = this;
        const query = searchQuery || undefined;
        const folder =
          (overrides && overrides.folder) ||
          (currentFolder && currentFolder.id) ||
          this._rootFolderId;
        const globalSearch = query ? folder === this._rootFolderId : undefined;
        const nameSort = this.sortKey === 'name';
        const ascending = this.sortOrder === 'asc';
        let sort = 'title_a_z';
        if (ascending && nameSort) {
          sort = 'title_z_a';
        } else if (ascending) {
          sort = 'newest_ed';
        } else if (!nameSort) {
          sort = 'oldest_ed';
        }
        return Object.assign(
          { sort, folder, query, globalSearch },
          this._defaultParams,
          overrides || {},
        );
      }

      _makeRequest(fullParams) {
        return new Observable((observer) => {
          const ajax$ = $observable.sbAjax({
            method: 'POST',
            url: this._url,
            body: fullParams,
          });
          const cb = (xhr) => {
            observer.next(xhr);
            observer.complete();
          };
          const ajaxSub = ajax$.subscribe(cb, cb);
          return ajaxSub.unsubscribe.bind(ajaxSub);
        });
      }

      /**
       * @ngdoc method
       * @name reload
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Reload the state with the currently set params/query.
       */
      reload() {
        this._startLoad();
        this._requests.next();
      }

      /**
       * @ngdoc method
       * @name gotoRoot
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Navigate state to the configured root folders.
       */
      gotoRoot() {
        this._fullCleanBeforeLoad();
        this._requests.next({ folder: this._rootFolderId });
      }

      /**
       * @ngdoc method
       * @name gotoFolder
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Navigate state to the requested new folder.
       *
       * @param {folder} folder The requested folder object with at least `.id` property.
       */
      gotoFolder({ id: folder }) {
        this._fullCleanBeforeLoad();
        this._requests.next({ folder });
      }

      /**
       * @ngdoc method
       * @name setSort
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Set the sort of this listing.
       *
       * @param {string} colName The column name to change the sort to. If the same name,
       *   it will just reverse the order.
       */
      setSort(colName) {
        const isSameCol = colName === this.sortKey;
        if (isSameCol && this.sortOrder === 'asc') {
          this.sortOrder = 'dsc';
        } else if (isSameCol) {
          this.sortOrder = 'asc';
        } else {
          this.sortOrder = 'dsc';
          this.sortKey = colName;
        }
        this.reload();
      }

      /**
       * @ngdoc method
       * @name selectDocument
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Make document object the currently selected document of this listing
       *
       * @param {document} document Newly selected document object.
       */
      selectDocument(document) {
        this.currentDocument = document;
      }

      /**
       * @ngdoc method
       * @name isDocumentOpen
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Check if the document details are open for a document.
       *
       * @param {document} document Requested document object with at least `.id`.
       *
       * @returns {boolean} Truthy means the details are open.
       */
      isDocumentOpen({ id }) {
        return this._docDetailsOpen.get(id, false);
      }

      /**
       * @ngdoc method
       * @name toggleDocumentOpen
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Invert if the detials are open.
       *
       * @param {document} document Requested document object with at least `.id`.
       */
      toggleDocumentOpen({ id }) {
        this._docDetailsOpen = this._docDetailsOpen.update(id, (oldValue) => !oldValue);
      }

      /**
       * @ngdoc method
       * @name isAtRoot
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Check if the listing is at the configured root folder.
       *
       * @returns {boolean} Truthy if listing is at root.
       */
      isAtRoot() {
        const { currentFolder, _rootFolderId } = this;
        return currentFolder && currentFolder.id === _rootFolderId;
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.lib.document.PickFromModel
       *
       * @description
       * Call this method to begin using the listing state.
       *
       * @returns {function} This function should be called when the model
       *   is no longer needed so that the model has a chance to clean up.
       */
      init() {
        const subscription = this._requests
          .pipe(
            map(this._processParams.bind(this)),
            switchMap(this._makeRequest.bind(this)),
          )
          .subscribe(this._handleResponse.bind(this));
        this.gotoRoot();
        return () => {
          subscription.unsubscribe();
        };
      }
    }

    return (
      rootFolderId,
      showManaged,
      managedTagFilter,
      excludeFn,
      allowUnfinalized,
      allowPending,
      allowAllFromProc,
    ) =>
      new Model(
        rootFolderId,
        showManaged,
        managedTagFilter,
        excludeFn,
        allowUnfinalized,
        allowPending,
        allowAllFromProc,
      );
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.document.object:$pickFromDataroomModal
 *
 * @description
 * Call this function to open a pick from Data Room modal.
 *
 * @param {object} options Configuration options.
 *   @property {string} rootFolderId The ID of the root/initial folder for this modal.
 *   @property {boolean} [showManaged=false] Truthy means show managed documents
 *     for selection.
 *   @property {array<string>} [managedTagFilter=undefined] The array of tags to filter
 *     managed on.
 *   @property {string} [title='Select Document from Data Room'] The title of the modal.
 *   @property {function} [excludeFn=undefined] given a document object, returns true if the
 *     document should be included in search results.
 * @returns {Promise} This promise with resolve with selected document or will reject on
 *   dismissal or user cancelation.
 */
export const $pickFromDataroomModal = [
  '$sbModal',
  function ($sbModal) {
    return (options) =>
      $sbModal.open({
        controllerAs: 'vm',
        bindToController: true,
        windowClass: 'pick-from-dataroom-modal',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        template: require('./templates/pick-from-dataroom-modal.html'),
        controller: pickFromModalCtrl,
        resolve: {
          Options: () => options,
        },
      }).result;
  },
]; // end $pickFromDataroomModal

const pickFromModalCtrl = [
  '$scope',
  'PickFromModel',
  'Options',
  function ($scope, PickFromModel, Options) {
    function clearQuery() {
      this.model.searchQuery = '';
      this.model.reload();
    }
    const {
      rootFolderId,
      showManaged,
      managedTagFilter,
      title,
      excludeFn,
      allowUnfinalized,
      allowPending,
      allowAllFromProc,
    } = Options;
    this.clearQuery = clearQuery.bind(this);
    this.title = title || 'Select Document from Data Room';
    this.model = PickFromModel(
      rootFolderId,
      showManaged,
      managedTagFilter,
      excludeFn,
      allowUnfinalized,
      allowPending,
      allowAllFromProc,
    );
    this.isDocumentOpen = this.model.isDocumentOpen.bind(this.model);
    const cleanup = this.model.init();
    $scope.$on('$destroy', cleanup);
  },
];
