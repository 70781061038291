<sbx-pdf-upload
  [(file)]="field.model.file"
  [uploadId]="field.formControl.value"
  (uploadIdChange)="updateUploadId($event)"
  [apiResource]="field.templateOptions.apiResource"
  (click)="to.focus ? to.focus($event) : null; formControl.markAsTouched()"
  (focus)="to.focus ? to.focus(field, $event) : null"
  [deletePreviousUpload]="!field.templateOptions.persistUpload"
  [enablePreview]="field.templateOptions.enablePreview"
>
</sbx-pdf-upload>
