import angular from 'angular';
import Tools from '../tools';
import Messages from '../messages/messages';

import { sbMetadataEndpointForm, MetadataFormsModel } from './form';
import { sbMetdataChange } from './change';

/**
 * @ngdoc overview
 * @name sb.lib.metadata
 *
 * @description
 * Generic tools for working with metadata.
 */
export default angular
  .module('sb.lib.metadata', [Tools, Messages])

  .component('sbMetadataChange', sbMetdataChange)
  .component('sbMetadataEndpointForm', sbMetadataEndpointForm)
  .factory('MetadataFormsModel', MetadataFormsModel).name;
