import { Downgrade } from '@/shared/downgrade';
import {
  Component,
  Inject,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
} from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { SbxDocumentApiService } from '../../sbx-document-api.service';
import { MessagesService } from '@/shared/sbx-message/messages.service';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';
import { MessageSeverity } from '@/shared/sbx-message/message-severity';
import { $pickFromDataroomModal } from '@/core/upgraded';
import { DocumentReferences, GenericMessageResponse } from '@shoobx/types/webapi-v2';
import { SbxDocumentStoreService } from '../../sbx-document.store.service';
import { HttpErrorResponse } from '@angular/common/http';

const SELECTOR = 'sbx-document-references';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-document-references.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbxDocumentReferencesComponent implements OnChanges {
  @Input({ required: true }) public documentId: string;
  @Input({ required: true }) public dataRoomReferenceConfig: object;
  @Input({ required: true }) public dataRoomBackReferenceConfig: object;

  public readonly documentReferences$: Observable<DocumentReferences>;

  public constructor(
    @Inject($pickFromDataroomModal) private readonly modalService,
    private readonly documentApiService: SbxDocumentApiService,
    private readonly messagesService: MessagesService,
    private readonly confirmationService: SbxConfirmationModalService,
    private readonly documentStoreService: SbxDocumentStoreService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZone: NgZone,
  ) {
    this.documentReferences$ = this.documentStoreService.documentReferences$;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.documentId.firstChange ||
      changes.documentId.previousValue !== changes.documentId.currentValue
    ) {
      this.setUpDocument(changes.documentId.currentValue);
    }
  }

  public handleRemoveReference(sourceId: string, referenceId: string): void {
    // Manually coming back to ngZone
    this.ngZone.run(() => {
      this.confirmationService
        .open({
          data: {
            title: 'Confirm Deletion',
            body: 'Do you really want to delete this reference?',
          },
        })
        .result.then(() => {
          this.messagesService.clearMessages();

          return firstValueFrom(
            this.documentApiService.removeReference$(sourceId, referenceId),
          );
        })
        .then(() => {
          this.messagesService.setMessages([
            {
              text: 'Reference was deleted successfully',
              severity: MessageSeverity.SUCCESS,
              closable: true,
            },
          ]);

          this.setUpDocument(this.documentId);
        })
        .catch(({ error }) => this.showErrorMessage(error));
    });
  }

  public handleAddReference(): void {
    const modalConfig = this.dataRoomReferenceConfig;

    this.modalService(modalConfig)
      .then(({ id }) => {
        const referenceId = id;
        const sourceId = this.documentId;

        this.messagesService.clearMessages();

        return firstValueFrom(
          this.documentApiService.saveReference$(sourceId, referenceId),
        );
      })
      .then((response: GenericMessageResponse) => {
        this.messagesService.setMessages([
          {
            text: response.message,
            severity: MessageSeverity.SUCCESS,
            closable: true,
          },
        ]);

        this.setUpDocument(this.documentId);
      })
      .catch(({ error }) => this.showErrorMessage(error));
  }

  public handleAddBackReference(): void {
    const modalConfig = this.dataRoomBackReferenceConfig;

    this.modalService(modalConfig)
      .then(({ id }) => {
        const referenceId = this.documentId;
        const sourceId = id;

        this.messagesService.clearMessages();

        return firstValueFrom(
          this.documentApiService.saveReference$(sourceId, referenceId),
        );
      })
      .then((response: GenericMessageResponse) => {
        this.messagesService.setMessages([
          {
            text: response.message,
            severity: MessageSeverity.SUCCESS,
            closable: true,
          },
        ]);

        this.setUpDocument(this.documentId);
      })
      .catch(({ error }) => this.showErrorMessage(error));
  }

  private setUpDocument(documentId: string): void {
    firstValueFrom(this.documentApiService.getDocumentReferences$(documentId))
      .then((documentReferences) =>
        this.documentStoreService.setDocumentReferences(documentReferences),
      )
      .then(() => this.changeDetectorRef.detectChanges())
      .catch(({ error }) => this.showErrorMessage(error));
  }

  private showErrorMessage(error: HttpErrorResponse): void {
    if (!error?.message) {
      return;
    }

    this.messagesService.setMessages([
      {
        text: error.message,
        severity: MessageSeverity.ERROR,
        closable: true,
      },
    ]);
  }
}
