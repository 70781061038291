import angular from 'angular';
import Modal from '../modal/modal';
import Tasks from './../tasks';
import PDF from './../pdf/pdf';
import Tools from './../tools';
import Messages from './../messages/messages';
import Promise from './../promise';
import Rx from '../rx';

import {
  sbDocumentTemplateReviewer,
  DocumentTemplateModel,
  sbDocumentTemplateViewerPage,
} from './template';
import { sbDocumentPreview } from './preview';
import { EntityDocumentsService } from './service';
import {
  sbMdAnnotations,
  sbStaticContent,
  $editDocumentPropertiesModal,
} from './metadata';
import { sbDocumentDownload, sbDocumentDownloadDropdown } from './download';
import { sbDocumentReference } from './reference';
import { sbGenerateAndDownload } from './generateanddownload';
import { sbGenerateCustomizationReport } from './generatecustomizationreport';
import { $pickFromDataroomModal, PickFromModel } from './pick-from';

/**
 * @ngdoc controller
 * @name sb.lib.document.controller:EditDocumentModalCtrl
 *
 * @description
 * Controller for the behavior of the edit document title modal.
 */
const EditDocumentModalCtrl = [
  '$scope',
  '$q',
  '$window',
  'SimpleHTTPWrapper',
  'UploadApi',
  'UpdateUrl',
  'APIUrl',
  'Fields',
  function (
    $scope,
    $q,
    $window,
    SimpleHTTPWrapper,
    UploadApi,
    UpdateUrl,
    APIUrl,
    Fields,
  ) {
    function save() {
      const promises = [];
      if (APIUrl && Fields) {
        promises.push(
          SimpleHTTPWrapper({
            url: APIUrl,
            method: 'POST',
            data: $scope.model,
          }).catch((errors) => {
            if (angular.isObject(errors)) {
              $scope.errors = errors;
            }
            return $q.reject(errors);
          }),
        );
      }
      if (UploadApi && UpdateUrl) {
        $scope.uploadError = undefined;
        promises.push(
          SimpleHTTPWrapper({
            url: UpdateUrl,
            method: 'POST',
          }).catch((errors) => {
            if (angular.isObject(errors)) {
              $scope.uploadError = errors.message;
            }
            return $q.reject(errors);
          }),
        );
      }
      outstanding = true;
      $q.all(promises).then(
        () => {
          $window.location.reload();
        },
        () => {
          outstanding = false;
        },
      );
    }
    function canSave() {
      if (outstanding) {
        return false;
      }
      if (!UploadApi) {
        return true;
      }
      const file = $scope.model.uploadedFile;
      return file && file.ready && !file.failed;
    }
    let outstanding = false;
    $scope.model = { uploadedFile: {} };
    $scope.errors = {};
    $scope.fields = Fields;
    $scope.uploadApi = UploadApi;
    $scope.canSave = canSave;
    $scope.save = save;
  },
]; // end EditDocumentModalCtrl

/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbEditDocument
 * @restrict E
 *
 * @description
 * This element wraps a button that will open a modal on click to edit
 * a document.
 *
 * @param {template} [sbEditDocumentUploadApi=undefined] Name of webapi
 *    resource that provides pdf file upload functionality. If `undefined`,
 *    no upload form will be added.
 * @param {template} [sbEditDocumentPostUploadUrl=undefined] Url of endpoint to
 *    POST to after upload/on save.
 * @param {template} [sbEditDocumentUrl=undefined] Url of endpoint to post to
 *    with form data.
 * @param {object} [sbEditDocumentFormDescription=undefined] A form description
 *    object from a user io form. If `undefined`, will not add a form.
 *
 * @example
   <sb-edit-document
    data-sb-edit-document-url="/endpoint/url"
    data-sb-edit-document-form-description="::someObject">
     <i class="fa fa-edit"></i>
   </sb-edit-document>
 */
function sbEditDocument() {
  return {
    restrict: 'E',
    scope: {
      apiUrl: '@sbEditDocumentUrl',
      uploadApi: '@sbEditDocumentUploadApi',
      postUploadUrl: '@sbEditDocumentPostUploadUrl',
      formDesc: '&sbEditDocumentFormDescription',
    },
    controller: [
      '$scope',
      '$sbModal',
      'PromiseErrorCatcher',
      function ($scope, $sbModal, PromiseErrorCatcher) {
        function openEditModal() {
          $sbModal
            .open({
              windowClass: 'edit-document-modal',
              template: require('./templates/edit-modal.html'),
              controller: 'EditDocumentModalCtrl',
              resolve: {
                UploadApi: function () {
                  return $scope.uploadApi;
                },
                UpdateUrl: function () {
                  return $scope.postUploadUrl;
                },
                APIUrl: function () {
                  return $scope.apiUrl;
                },
                Fields: function () {
                  return ($scope.formDesc() || {}).fields;
                },
              },
            })
            .result.catch(PromiseErrorCatcher);
        }
        $scope.openEditModal = openEditModal;
      },
    ],
    link: function (scope, element) {
      element.click(() => {
        scope.$apply(scope.openEditModal);
      });
    },
  };
} // end sbEditDocument

/**
 * @ngdoc overview
 * @name sb.lib.document
 * @requires lib/sb.lib.bootstrap
 *
 * @description
 * Set of generic, reusable document-related UI components
 */
export default angular
  .module('sb.lib.document', [Modal, Tasks, Promise, PDF, Messages, Tools, Rx])

  .controller('EditDocumentModalCtrl', EditDocumentModalCtrl)
  .component('sbDocumentReference', sbDocumentReference)
  .component('sbDocumentDownloadDropdown', sbDocumentDownloadDropdown)
  .component('sbGenerateAndDownload', sbGenerateAndDownload)
  .component('sbGenerateCustomizationReport', sbGenerateCustomizationReport)
  .factory('PickFromModel', PickFromModel)
  .factory('$pickFromDataroomModal', $pickFromDataroomModal)
  .factory('EntityDocumentsService', EntityDocumentsService)
  .factory('DocumentTemplateModel', DocumentTemplateModel)
  .factory('$editDocumentPropertiesModal', $editDocumentPropertiesModal)
  .directive('sbDocumentTemplateViewerPage', sbDocumentTemplateViewerPage)
  .directive('sbDocumentTemplateReviewer', sbDocumentTemplateReviewer)
  .directive('sbDocumentPreview', sbDocumentPreview)
  .directive('sbMdAnnotations', sbMdAnnotations)
  .directive('sbStaticContent', sbStaticContent)
  .directive('sbDocumentDownload', sbDocumentDownload)
  .directive('sbEditDocument', sbEditDocument).name;
