<sbx-button
  *ngIf="type === 'button'; else icon"
  theme="default"
  icon="upload"
  [title]="title"
  (click)="openModal()"
></sbx-button>

<ng-template #icon>
  <sbx-action-menu
    [actions]="actions"
    [appearance]="{ type: 'icon', aligment: 'right' }"
  ></sbx-action-menu>
</ng-template>
