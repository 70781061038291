<sbx-number-textline-base
  [model]="formControl.value"
  [id]="$any(field.key)"
  [min]="field.templateOptions.min"
  [max]="field.templateOptions.max"
  [errorsMin]="formControl.errors?.min"
  [errorsMax]="formControl.errors?.max"
  [showError]="showError"
  [to]="to"
  [precision]="field.templateOptions.precision"
  (focus)="handleFocus($event)"
  (blur)="handleBlur()"
  (changeValue)="handleChange($event)"
></sbx-number-textline-base>
