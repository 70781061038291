import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxDocumentHeaderComponent } from './sbx-document-header.component';
import { RouterModule } from '@angular/router';
import { SbxGridModule } from '@/shared/sbx-grid/sbx-grid.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxTitleModule } from '@/shared/sbx-title/sbx-title.module';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxUploadMissingDocumentModule } from '@/shared/sbx-upload-missing-document/sbx-upload-missing-document.module';
import { UpgradedModule } from '@/shared/upgraded/upgraded.module';
import { SbxDocumentHeaderService } from './sbx-document-header.service';

@NgModule({
  imports: [
    CommonModule,
    UpgradedModule,
    RouterModule,
    SbxGridModule,
    SbxIconModule,
    SbxTitleModule,
    SbxButtonModule,
    SbxPopoverModule,
    SbxUploadMissingDocumentModule,
  ],
  declarations: [SbxDocumentHeaderComponent],
  exports: [SbxDocumentHeaderComponent],
  providers: [SbxDocumentHeaderService],
})
export class SbxDocumentHeaderModule {
  static entryComponents = [SbxDocumentHeaderComponent];

  static forRoot(): ModuleWithProviders<SbxDocumentHeaderModule> {
    return {
      ngModule: SbxDocumentHeaderModule,
    };
  }
}
