<ng-container *ngIf="!this.modalOpen">
  <a class="btn btn-link" (click)="populateForm()" *ngIf="buttonType === 'dropdown'">
    <i class="fa fa-edit"></i>Edit Info
  </a>
  <sbx-button
    title="Edit Name or Email"
    (click)="populateForm()"
    theme="secondary"
    [disabled]="!canEdit"
    *ngIf="buttonType === 'modal'"
  >
  </sbx-button>
  <sbx-popover
    *ngIf="buttonType === 'icon' && canEdit"
    sbxPopoverContent="Edit Name or Email"
    sbxPopoverPlacement="top"
    sbxPopoverContainer="body"
  >
    <a (click)="populateForm()">
      <i class="fa fa-edit"></i>
    </a>
  </sbx-popover>
</ng-container>
<ng-template #warningfooter>
  <ng-container
    *ngIf="
      templateData.initialModel && model.sh_type !== templateData.initialModel.sh_type
    "
  >
    <p>
      NOTE: Changing this entity type will affect where you find this contact. In the
      future, you'll be able to find this user in your
      <b *ngIf="model.sh_type === 'individual'">User Directory</b>
      <b *ngIf="model.sh_type === 'entity'">Companies and Firms Directory</b>
      instead of your
      <b *ngIf="model.sh_type !== 'individual'">User Directory.</b>
      <b *ngIf="model.sh_type !== 'entity'">Companies and Firms Directory.</b>
    </p>

    <ng-container *ngIf="templateData.funds.length > 0">
      <p>
        NOTE: This edit will remove all associated funds. Information and investments
        related to these funds will be associated with the entity above.
      </p>
      <p>Affected Fund(s):</p>
      <ul>
        <li *ngFor="let fund of templateData.funds">
          <strong>{{ fund }}</strong>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngIf="this.modalOpen">
  <ng-template *ngTemplateOutlet="warningfooter"></ng-template>
</ng-container>
