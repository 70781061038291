import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FeatureTypes } from '../../sbx-editor/sbx-editor.component';

@Component({
  selector: 'sbx-richtext',
  templateUrl: './sbx-richtext.component.html',
  styleUrls: ['./sbx-richtext.component.scss'],
})
export class SbxRichtextComponent extends FieldType<FieldTypeConfig> {
  editorFeatures = {
    [FeatureTypes.FontStyles]: true,
    [FeatureTypes.ListStyles]: true,
  };
}
