<div
  ngbDropdown
  [placement]="placement"
  [container]="container"
  [popperOptions]="popperOptions"
  #dropdownRef="ngbDropdown"
  class="sbx-dropdown"
>
  <span ngbDropdownAnchor (click)="handleClick()">
    <ng-content></ng-content>
  </span>
  <span ngbDropdownMenu [style.width]="sanitizedWidth" class="sbx-bordered">
    <ng-container *ngTemplateOutlet="dropDownTemplate"></ng-container>
  </span>
</div>
