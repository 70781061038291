import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxContainerComponent } from './sbx-container.component';
import { SbxRowComponent } from './sbx-row.component';
import { SbxColComponent } from './sbx-col.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SbxContainerComponent, SbxRowComponent, SbxColComponent],
  exports: [SbxContainerComponent, SbxRowComponent, SbxColComponent],
})
export class SbxGridModule {
  static entryComponents = [SbxContainerComponent, SbxRowComponent, SbxColComponent];

  static forRoot(): ModuleWithProviders<SbxGridModule> {
    return {
      ngModule: SbxGridModule,
    };
  }
}
