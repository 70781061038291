import angular from 'angular';
import { takeWhile } from 'rxjs/operators';

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.resolution.object:ResolutionWIModel
 *
 * @description
 * This factory function will create an instance of a model for managing the state
 * of a resolution workitem.
 */
export const ResolutionWIModel = [
  'BackendLocation',
  'SimpleHTTPWrapper',
  'ProcessUrlInfo',
  'EntityDocumentsService',
  'AppConfig',
  'PromiseErrorCatcher',
  function (
    BackendLocation,
    SimpleHTTPWrapper,
    ProcessUrlInfo,
    EntityDocumentsService,
    AppConfig,
    PromiseErrorCatcher,
  ) {
    class Model {
      constructor(defaultIntroDoc, docId, isWorkitem = true) {
        /**
         * @ngdoc property
         * @name url
         * @propertyOf sb.workitem.resolution.ResolutionWIModel
         *
         * @description
         * String url of base backend endpoint.
         */
        this.url = isWorkitem
          ? BackendLocation.context('1') + 'groups'
          : `${BackendLocation.entity(
              1,
            )}processes/${ProcessUrlInfo.processId()}/select_and_edit_resolution/${docId}`;

        /**
         * @ngdoc property
         * @name initialized
         * @propertyOf sb.workitem.resolution.ResolutionWIModel
         *
         * @description
         * When truthy, the model has been loaded at least once.
         */
        this.initialized = false;

        this.defaultIntroDoc = defaultIntroDoc;
        this.docId = docId;
        this.isWorkitem = isWorkitem;
      }

      _load(prom) {
        this.loading = true;
        return prom
          .then((data) => {
            angular.extend(this, data);
          })
          .finally(() => {
            this.loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name deleteGroup
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description
       * Delete a group.
       *
       * @param {string} groupId ID of the group to be marked.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      deleteGroup(groupId) {
        const data = { groupId };
        const prom = SimpleHTTPWrapper(
          { method: 'DELETE', url: this.url, data },
          'Could not mark resolution.',
        );
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name markGroup
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description
       * Mark a group as approving/not approving.
       *
       * @param {string} groupId ID of the group to be marked.
       * @param {boolean} approved `true` for approving, `false` for not approving.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      markGroup(groupId, approved) {
        const data = { groupId, approved };
        const prom = SimpleHTTPWrapper(
          { method: 'POST', url: this.url + '/mark', data },
          'Could not mark resolution.',
        );
        return this._load(prom);
      }

      resetGroup(groupId) {
        const data = { groupId };
        const prom = SimpleHTTPWrapper(
          { method: 'POST', url: this.url + '/reset', data },
          'Could not reset group.',
        );
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name move
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description
       * Move an item (group or resolution item) to a new container at a position.
       *
       * @param {string} itemId ID of the item being moved.
       * @param {string} containerId ID of the contianer moved to or within.
       * @param {number} toPosition index of where the item should be moved to.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      move(itemId, containerId, toPosition) {
        const data = { itemId, containerId, toPosition };
        const prom = SimpleHTTPWrapper(
          { method: 'PUT', url: this.url, data },
          'Could not move item.',
        );
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name save
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description
       * Save or edit a group.
       *
       * @param {string} content The HTML content of the group.
       * @param {string} [title=undefined] The title of the group.
       * @param {string} [groupId=undefined] The ID of the group to edit. A new
       *   group will be added when left as the default `undefined`.
       * @param {array<string>} [exhibitsToDelete=undefined] List of exhibit IDs
       *   to delete with this transaction.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      save(whereases = null, resolutions = null, title, groupId, exhibitsToDelete) {
        const data = { whereases, resolutions, title, groupId, exhibitsToDelete };
        const prom = SimpleHTTPWrapper(
          { method: 'POST', url: this.url, data },
          'Could not save content.',
        );
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name recordVoting
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description
       * Save some voting information.
       *
       * @param {string} sectionId ID of the section.
       * @param {array<string>} abstentions Array of SH IDs.
       * @param {array<string>} dissensions Array of SH IDs.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      recordVoting(sectionId, abstentions, dissensions) {
        const data = { sectionId, abstentions, dissensions };
        const prom = SimpleHTTPWrapper(
          { method: 'POST', url: this.url + '/votes', data },
          'Could not save votes.',
        );
        return this._load(prom);
      }

      /**
       * @ngdoc method
       * @name addExhibits
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description Add new (non-persisted) exhibits.
       *
       * @param {array} newExhibits Array of exhibits to add.
       */
      addExhibits(newExhibits) {
        this.exhibits = (this.exhibits || []).concat(newExhibits);
      }

      /**
       * @ngdoc method
       * @name checkBound
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description Check against bound resolutions.
       *
       * @returns {promise} Resolves with bound resolution lists or rejects
       *   with failure.
       */
      checkBound() {
        this.loading = true;
        return SimpleHTTPWrapper(
          { url: this.url + '/boundResolutions' },
          'Could not check bound resolutions.',
        )
          .then(({ bound }) => bound || [])
          .finally(() => {
            this.loading = false;
          });
      }

      /**
       * @ngdoc method
       * @name clearBound
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description Clear all bound resolutions and update state.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      clearBound() {
        const prom = SimpleHTTPWrapper(
          { method: 'DELETE', url: this.url + '/boundResolutions' },
          'Could remove bound resolutions.',
        );
        return this._load(prom);
      }

      fetchResolution() {
        const prom = SimpleHTTPWrapper(
          { url: this.url },
          'Could not fetch resolutions.',
        );
        return this._load(prom).finally(() => {
          this.initialized = true;
        });
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.workitem.resolution.ResolutionWIModel
       *
       * @description Initialize the model.
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      init() {
        if (this.defaultIntroDoc) {
          EntityDocumentsService.$getDocument(
            this.defaultIntroDoc,
            AppConfig.currentEntity.name,
          )
            .$toPromise()
            .then((doc) => {
              return EntityDocumentsService.$waitForDocumentToRender(doc).$toPromise();
            })
            .then((done) => {
              if (done) {
                return this.fetchResolution();
              }
            })
            .catch(PromiseErrorCatcher);
        } else {
          return this.fetchResolution();
        }
      }
    }
    return (defaultIntroDoc, docId, isWorkitem) =>
      new Model(defaultIntroDoc, docId, isWorkitem);
  },
]; // end ResolutionWIModel

/**
 * @ngdoc component
 * @name sb.workitem.resolution.component:sbSelectResolutions
 *
 * @description
 * This component is meant to be the entirety of the select resolutions workitem.
 */
export const sbSelectResolutions = {
  bindings: {
    config: '=?',
    doc: '@',
  },
  template: require('./templates/select.html'),
  controllerAs: 'vm',
  controller: [
    '$q',
    '$scope',
    'ProcessStatus',
    'ProcessButtonModel',
    'PromiseErrorCatcher',
    '$confirm',
    '$dropTransaction',
    '$resolutionEditModal',
    'ResolutionWIModel',
    function (
      $q,
      $scope,
      ProcessStatus,
      ProcessButtonModel,
      PromiseErrorCatcher,
      $confirm,
      $dropTransaction,
      $resolutionEditModal,
      ResolutionWIModel,
    ) {
      function showError(err) {
        ProcessStatus.$setStatus(
          err || 'There was a problem saving resolutions.',
          'danger',
        );
      }
      function resolutionModal(type, group, sectionId = 0) {
        $resolutionEditModal(this.model, type, group, sectionId).catch(
          PromiseErrorCatcher,
        );
      }
      function resetGroup(group, sectionId = 0) {
        this.model.resetGroup(group.sections[sectionId].id).catch(showError);
      }
      function markGroup(group, approved) {
        this.model.markGroup(group.id, approved).catch(showError);
      }
      function deleteGroup(group) {
        this.model.deleteGroup(group.id).catch(showError);
      }
      function resolutionDrop(section, toIndex, resolution) {
        this.itemDropped$.next({
          dropList: section.resolutions,
          dropListId: section.id,
          item: resolution,
          toIndex,
        });
        return true;
      }
      function groupDrop(dropListId, dropList, toIndex, group) {
        this.itemDropped$.next({
          item: group,
          dropList,
          dropListId,
          toIndex,
        });
        return true;
      }
      function handleDrop({ startArgs, finishArgs }) {
        // No need to $apply. We expect this all already in $digest.

        const { dropList, dropListId, toIndex, item } = startArgs;
        dropList.splice(toIndex, 0, item);

        const { fromList, fromIndex } = finishArgs;
        // If user is moving the item within the same list and they are moving upwards
        // (removeIndex is bigger than at index) then the index will be off by one.
        if (dropList === fromList && fromIndex > toIndex) {
          fromList.splice(fromIndex + 1, 1);
        } else {
          fromList.splice(fromIndex, 1);
        }

        // After all manipulation is done, find the final index and persist.
        const itemId = item.id;
        const moveToIndex = dropList.findIndex((i) => i.id === itemId);
        this.model.move(itemId, dropListId, moveToIndex).catch(showError);
      }
      function warnBound() {
        return this.model.checkBound().then((boundResolutions) => {
          if (!boundResolutions.length) {
            return $q.resolve();
          }
          const boundList = boundResolutions.map((doc) => doc.title).join('</li><li>');
          return $confirm({
            alertType: 'warning',
            confirmPromise: () => this.model.clearBound(),
            body: `
            <p>
              Shoobx has detected that the following resolutions have already been
              associated with another approval workflow:
            </p>
            <ul><li>${boundList}</li></ul>
            <p>
              Shoobx will automatically remove from this approval.
            </p>
          `,
          });
        });
      }

      this.$onInit = () => {
        this.resolutionModal = resolutionModal.bind(this);
        this.markGroup = markGroup.bind(this);
        this.resetGroup = resetGroup.bind(this);
        this.deleteGroup = deleteGroup.bind(this);
        const transactions$ = $dropTransaction.call(this, 'itemDropped$', 'itemMoved$');
        transactions$
          .pipe(takeWhile(() => !this.isDestroyed))
          .subscribe(handleDrop.bind(this));
        this.resolutionDrop = resolutionDrop.bind(this);
        this.groupDrop = groupDrop.bind(this);
        this.model = this.config
          ? ResolutionWIModel(
              this.doc,
              this.config.doc.get('id'),
              this.config.isWorkitem,
            )
          : ResolutionWIModel(this.doc);
        if (this.config) {
          this.config.warnBound = warnBound;
          this.config.model = this.model;
        }
        this.model.init();
        $scope.$watch('vm.model.loading', ProcessButtonModel.$disableWatch('continue'));
        ProcessButtonModel.$addSubmitCondition('continue', warnBound.bind(this));
      };

      this.$onDestroy = () => {
        this.isDestroyed = true;
      };
    },
  ],
}; // end sbSelectResolutions
