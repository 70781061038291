<div class="sbx-container">
  <span class="sbx-icon-container">
    <sbx-icon type="error"></sbx-icon>
  </span>
  <span class="sbx-main-content">
    <div>
      We found a user already assigned to this email address: <b>{{ shTitle }}</b
      >. Would you like to select the existing user or cancel and enter another email
      address?
    </div>
    <div class="modal-footer">
      <sbx-button
        title="Use {{ shTitle }}"
        theme="primary"
        (click)="save()"
      ></sbx-button>
      <sbx-button title="Edit email" theme="secondary" (click)="edit()"></sbx-button>
    </div>
  </span>
</div>
