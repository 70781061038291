import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxShareholderWorkitemComponent } from './sbx-shareholder.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxShareholderWorkitemComponent],
  exports: [SbxShareholderWorkitemComponent],
})
export class SbxShareholderWorkitemModule {
  static entryComponents = [SbxShareholderWorkitemComponent];

  static forRoot(): ModuleWithProviders<SbxShareholderWorkitemModule> {
    return {
      ngModule: SbxShareholderWorkitemModule,
    };
  }
}
