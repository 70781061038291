import { Component, ViewChild, TemplateRef } from '@angular/core';
import { IColumnTemplate } from './column-templates.model';

@Component({
  selector: 'sbx-profile-groups',
  templateUrl: './profile-groups.component.html',
  styleUrls: ['./profile-groups.component.scss'],
})
export class ProfileGroupsComponent implements IColumnTemplate {
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
}
