import { ISbxActionsMenu } from '@/shared/sbx-action-menu/sbx-action-menu.component';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import {
  ISbxTableColumns,
  ISbxTableData,
} from '@/shared/sbx-table/sbx-table.component';
import { nestedValueByPath } from '@/shared/utils/nested-value-by-path.util';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldArrayTypeConfig, FieldType } from '@ngx-formly/core';
import { firstValueFrom, tap } from 'rxjs';

@Component({
  selector: 'sbx-document-list',
  templateUrl: './sbx-document-list.component.html',
  styleUrls: ['./sbx-document-list.component.scss'],
})
export class SbxDocumentListComponent
  extends FieldType<FieldArrayTypeConfig>
  implements OnInit, AfterViewInit
{
  @ViewChild('nameTemplate')
  private readonly nameTemplate: TemplateRef<string>;
  @ViewChild('rowActionsTemplate')
  private readonly rowActionsTemplate: TemplateRef<object>;

  public columns: ISbxTableColumns;
  public data: ISbxTableData;

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formModalService: SbxFormModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.configureTableData();
  }

  public ngAfterViewInit(): void {
    this.configureTableColumns();

    this.changeDetectorRef.detectChanges();
  }

  public getRowActions(documentId: string): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        collapsed: false,
        disabled: false,
        click: () => this.handleEditDocumentEvent(documentId),
      },
      {
        title: 'Remove',
        icon: 'delete',
        collapsed: false,
        disabled: false,
        click: () => this.handleDeleteDocumentEvent(documentId),
      },
    ];
  }

  public async handleAddDocumentEvent(): Promise<void> {
    const documentForm = this.field.templateOptions;
    const documentsField = {
      data: {},
      templateOptions: {
        label: 'Documents',
        required: true,
        canEdit: true,
        canDelete: true,
        uploadURL: this.field.templateOptions.uploadURL,
        multiple: true,
        apiVersion: '2',
      },
      key: 'files',
      type: 'dropzone',
    };
    const defaultDateField = {
      key: 'effectiveDate',
      type: 'date',
      templateOptions: {
        label: 'Effective date',
        required: true,
        hidden: true,
      },
    };

    await firstValueFrom(
      this.formModalService
        .open({
          size: 'md',

          data: {
            title: documentForm.label,
            formFields: [documentsField, defaultDateField],
          },
        })
        .closed.pipe(
          tap((result) => {
            const { effectiveDate, files } = result.result;
            const uploadedDocuments = files.map((file) => {
              const { id, status, title } = file;
              return {
                documentId: id,
                documentTitle: title,
                type: status,
                effectiveDate,
              };
            });

            this.data = [...this.data, ...uploadedDocuments];
            this.changeDetectorRef.detectChanges();
            this.formControl.setValue([...this.data]);
          }),
        ),
    ).catch(() => undefined);
  }

  public handleClickEvent(event: MouseEvent): void {
    this.to.focus(this.field, event);
    this.to.blur(this.field, event);
  }

  private handleEditDocumentEvent(id: string): void {
    const index = this.data.findIndex((item) => item.documentId === id);
    const { documentId, type, documentTitle } = this.data[index];
    const model = {
      ...this.data[index],
      files: [{ id: documentId, status: type, title: documentTitle }],
    };
    const fileField = {
      data: {},
      templateOptions: {
        label: 'Document',
        required: true,
        canEdit: true,
        canDelete: true,
        uploadURL: this.field.templateOptions.uploadURL,
        multiple: false,
        apiVersion: '2',
      },
      key: 'files',
      type: 'dropzone',
    };
    const dateField = {
      data: {},
      key: 'effectiveDate',
      templateOptions: {
        isCalculated: false,
        label: 'Effective date',
        providesFormContext: false,
        required: true,
        subfield: 0,
      },
      type: 'date',
    };
    const documentForm = this.field.templateOptions;

    firstValueFrom(
      this.formModalService
        .open({
          size: 'md',
          data: {
            title: documentForm.label,
            formFields: [fileField, dateField],
            model,
          },
        })
        .closed.pipe(
          tap((result) => {
            const { effectiveDate, files } = result.result;
            const { id, status, title } = files[0];
            const uploadedDocument = {
              documentId: id,
              documentTitle: title,
              type: status,
              effectiveDate,
            };

            this.data = [
              ...this.data.slice(0, index),
              uploadedDocument,
              ...this.data.slice(index + 1),
            ];
            this.changeDetectorRef.detectChanges();
            this.formControl.setValue([...this.data]);
          }),
        ),
    ).catch(() => undefined);
  }

  public handleDeleteDocumentEvent(documentId: string): void {
    const index = this.data.findIndex((item) => item.documentId === documentId);
    this.data = [...this.data.slice(0, index), ...this.data.slice(index + 1)];

    this.changeDetectorRef.detectChanges();

    this.formControl.setValue([...this.data]);
  }

  private configureTableColumns(): void {
    this.columns = [
      {
        key: 'documentTitle',
        template: this.nameTemplate,
      },
      {
        key: 'effectiveDate',
      },
      {
        key: 'documentId',
        template: this.rowActionsTemplate,
      },
    ];
  }

  private configureTableData(): void {
    const path = String(this.field.key);
    const model = this.model;

    this.data = nestedValueByPath(model, path);
  }
}
