import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

export interface IStep {
  title: string;
  id: string;
  completed: boolean;
  current: boolean;
  linkId: string | null;
}

export interface IGroup {
  title: string;
  name: string;
  pos: number;
  active?: boolean;
}

const GROUP_WIDTH = 300;
const STEP_WIDTH = 200;

@Downgrade.Component('ngShoobx', 'sbx-process-wizard')
@Component({
  selector: 'sbx-process-wizard',
  templateUrl: './sbx-process-wizard.component.html',
  styleUrls: ['./sbx-process-wizard.component.scss'],
})
export class SbxProcessWizardComponent implements OnInit, OnChanges {
  @Input({ required: true }) steps: IStep[] | undefined;
  @Input({ required: true }) currentStepIndex: number | undefined;
  @Input() groups?: IGroup[];
  @Input() currentGroup?: IGroup;
  @Output() stepChange = new EventEmitter<IStep>();

  private containerWidth = 0;
  public containerOffset = 0;
  public scrollOffset = 0;

  constructor(@Inject(ElementRef) private elementRef: ElementRef) {}

  ngOnInit() {
    this.containerWidth = this.elementRef.nativeElement.clientWidth;
    this.updateScrollPosition();
  }

  ngOnChanges({ steps, currentStepIndex, groups }: SimpleChanges) {
    if (
      steps.previousValue !== steps.currentValue ||
      currentStepIndex.previousValue !== currentStepIndex.currentValue ||
      groups?.previousValue !== groups?.currentValue
    ) {
      this.updateScrollPosition();
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    const width = this.elementRef.nativeElement.clientWidth;

    if (this.containerWidth !== width) {
      this.containerWidth = width;
      this.updateScrollPosition();
    }
  }

  private updateScrollPosition(): void {
    if (!this.steps) {
      return;
    }

    this.containerOffset = this.groups ? GROUP_WIDTH : 0;

    // Scroll until new workitem is the second item, paying attention to bounds.
    let scrollTo = (this.currentStepIndex - 1) * STEP_WIDTH * -1 + this.containerOffset;
    const right = this.ribbonFromRight();
    const diff = (right || 0) - (this.scrollOffset - scrollTo);

    if (diff < 0) {
      scrollTo -= diff;
    }

    if (scrollTo >= this.containerOffset) {
      scrollTo = this.containerOffset;
    }

    this.scrollOffset = scrollTo;
  }

  // Compute wizard distance from right edge of view. Positive numbers indicate
  // the ribbon is scrolled off to the right (a good thing).
  private ribbonFromRight(): number {
    return this.steps.length * STEP_WIDTH - this.containerWidth + this.scrollOffset;
  }

  public scrollLeft(): void {
    let scrollTo = this.scrollOffset + 2 * STEP_WIDTH;

    if (scrollTo > this.containerOffset) {
      scrollTo = this.containerOffset;
    }

    this.scrollOffset = scrollTo;
  }

  public scrollRight(): void {
    let scrollTo = this.scrollOffset - 2 * STEP_WIDTH;
    const right = this.ribbonFromRight();
    const diff = right - 2 * STEP_WIDTH;

    if (diff < 0) {
      scrollTo -= diff;
    }

    this.scrollOffset = scrollTo;
  }

  public canScrollLeft(): boolean {
    return this.scrollOffset !== this.containerOffset;
  }

  public canScrollRight(): boolean {
    return this.ribbonFromRight() > 0;
  }
}
