import { Component, Input, OnInit, Inject } from '@angular/core';
import { SbxProcessButtonModel } from '@/shared/upgraded/process-button-model.service';
import {
  $similarEntitiesModal,
  similarEntitiesModalHelper,
} from '../../../../js/lib/stakeholder/modal';
import { Stakeholders } from '../../../../js/lib/stakeholder/service';

// $similarEntitiesModal
import { Downgrade } from '@/shared/downgrade';

const SELECTOR = 'sbx-relationship-setup';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-relationship-setup.component.html',
  styleUrls: ['sbx-relationship-setup.component.scss'],
})
export class SbxRelationshipSetupComponent implements OnInit {
  @Input() form;
  @Input() formFields;
  @Input() suggestedStakeholder;
  @Input() formKey = 'relationships_welcome';

  constructor(
    @Inject(SbxProcessButtonModel) private processButtonModel,
    @Inject($similarEntitiesModal) private $modal,
    @Inject(Stakeholders) private stakeholderService,
    @Inject(similarEntitiesModalHelper) private modalHelper,
  ) {}

  ngOnInit() {
    this.processButtonModel.$addSubmitCondition(
      /continue$/,
      () => {
        const form = this.form[this.formKey];
        const formData = form.suggested_stakeholder;

        // Skip submit condition if formData is empty to trigger form submit in
        // next step to show validation errors
        if (!formData) {
          return Promise.resolve();
        }

        if (
          form.stakeholder_definition === 'suggested' &&
          form.is_coordinator === 'yes' &&
          formData.type === 'team' &&
          !formData.sh.foreignEntityId &&
          this.suggestedStakeholder &&
          this.suggestedStakeholder.sh.id === formData.sh.id
        ) {
          formData.sh.entityTitle = formData.sh.title || formData.sh.fullName;
          const affiliates = Boolean(formData.sh.parentTitle);
          if (formData.sh.parentTitle) {
            formData.sh.hasAffiliate = true;
            formData.sh.addAffiliate = true;
            formData.sh.affiliateTitle = formData.sh.title || formData.sh.fullName;
            formData.sh.entityTitle = formData.sh.parentTitle;
          }
          const createData = {
            type: formData.type,
            description: formData.sh,
          };
          return this.$modal(
            formData.sh.entityTitle,
            formData.sh.affiliateTitle,
            affiliates,
            formData.sh.id,
            formData.sh.relationType,
            this.extractSecurityID(),
          ).then((data) => {
            if (
              data.selectedEntity ||
              data.selectedAffiliate ||
              data.rejectedEntitySuggestions ||
              data.rejectedAffiliateSuggestions
            ) {
              const changed = data.selectedEntity || data.selectedAffiliate;
              const modalSh = this.modalHelper(data, createData);
              let relationType = formData.sh.relationType;
              if (changed) {
                modalSh.description.id = undefined;
                if (formData.sh.relationType.includes('investment')) {
                  relationType = 'investment';
                }
              } else {
                modalSh.sh = modalSh.description;
              }
              return this.stakeholderService
                .create(
                  modalSh,
                  relationType,
                  formData.sh.id,
                  true,
                  undefined,
                  undefined,
                  this.extractSecurityID(),
                )
                .then((createdSh) => {
                  formData.sh = createdSh;
                  formData.description = undefined;
                  return formData.sh;
                });
            }
            return formData.sh;
          });
        }
        return Promise.resolve();
      },
      1,
    );
  }

  private extractSecurityID() {
    const formFields = this.formFields[this.formKey].fields;
    const formField = formFields.find((f) => {
      return f.type === 'stakeholder' && f.templateOptions.securityTicketID;
    });
    return formField?.templateOptions?.securityTicketID;
  }
}
