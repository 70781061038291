import {
  Component,
  HostListener,
  Inject,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGB_CONTAINER_TOKEN } from '../sbx-form/fields/ngb-container.token';
import { SbxModalService } from './sbx-modal.service';
import { NGB_BODY_ATTACHED_CONTAINER_CONFIG } from '../sbx-form/fields/ngb-container.config';

/**
 *  Shoobx Themed Modal Container
 *
 *  Dynamically generated by SbxModalService. Not for direct use.
 */
@Component({
  selector: 'sbx-modal',
  templateUrl: './sbx-modal.component.html',
  styleUrls: ['./sbx-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NGB_CONTAINER_TOKEN,
      useValue: NGB_BODY_ATTACHED_CONTAINER_CONFIG,
    },
  ],
})
export class SbxModalComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true }) containerRef;

  constructor(
    public currentModal: NgbActiveModal,
    @Inject(forwardRef(() => SbxModalService)) private sbxModalService: SbxModalService,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    event.stopImmediatePropagation();
    this.sbxModalService.dismiss();
  }
}
