import { Component, Output, EventEmitter } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ISearchGroup } from '../../sbx-search-bar/sbx-search-bar.component';
import { SbxHttpClient } from '@/core/http';

@Component({
  selector: 'sbx-stock-ticker',
  templateUrl: './sbx-stock-ticker.component.html',
  styleUrls: ['./sbx-stock-ticker.component.scss'],
})
export class SbxStockTickerComponent extends FieldType<FieldTypeConfig> {
  @Output() focus = new EventEmitter();
  constructor(private http: SbxHttpClient) {
    super();
    this.getTicker = this.getTicker.bind(this);
  }

  selectTicker(item) {
    if (item.token) {
      this.formControl.setValue(item);
    }
  }

  removeTicker() {
    this.formControl.setValue(null);
  }

  getTicker(query): Observable<ISearchGroup[]> {
    if (query.length < 2) {
      return of([]);
    }
    return this.http
      .entity('1')
      .get('asc', { params: { query } })
      .pipe(
        map((res: any[]) => {
          const searchGroup: ISearchGroup = {
            header: null,
            headerIcon: null,
            noResultsText: 'No companies found.',
            results: res || [],
            footer: null,
            footerLink: null,
          };
          return [searchGroup];
        }),
      );
  }
}
