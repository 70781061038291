import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SbxSelectComponent } from './sbx-select.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbTypeaheadModule],
  declarations: [SbxSelectComponent],
  exports: [SbxSelectComponent],
})
export class SbxSelectModule {
  static forRoot(): ModuleWithProviders<SbxSelectModule> {
    return {
      ngModule: SbxSelectModule,
    };
  }
}
