import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';

import { SbxAsc718GrantsComponent } from './sbx-asc718-grants/sbx-asc718-grants.component';

// SbxAsc718AdminModule is split into two modules - one is lazy and loaded via
// router, other one is included into main bundle and used in non spa pages
// like workflows

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxAsc718GrantsComponent],
  exports: [SbxAsc718GrantsComponent],
})
export class SbxAsc718AdminModule {
  static entryComponents = [SbxAsc718GrantsComponent];

  static forRoot(): ModuleWithProviders<SbxAsc718AdminModule> {
    return {
      ngModule: SbxAsc718AdminModule,
    };
  }
}
