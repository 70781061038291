import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import {
  TaxReport,
  TaxesSummary,
  PickupFile,
  GenericCreateForm,
  GenericStatusResponse,
  TaxAdjustmentOptions,
  TaxEventAdjustments,
  TaxAdjustment,
} from '@shoobx/types/webapi-v2';

export interface IReportOptions {
  offset: number;
  limit: number;
  sortKey: string;
  sortOrder: string;
  eventTypes: Array<string>;
  startDate: string;
  endDate: string;
}

@Injectable({ providedIn: 'root' })
export class SbxTaxService {
  API_VERSION = '2';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
  ) {}

  getTaxReport(params: IReportOptions): Observable<TaxReport> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TaxReport>('tax/reports/taxes', { params: params });
  }

  export(): Observable<PickupFile> {
    return this.sbxHttpClient.entity('2').get<PickupFile>('tax/reports/taxes.xlsx');
  }

  getEventsSummary(
    documentId: string,
    eventIds: Array<string>,
  ): Observable<TaxesSummary> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TaxesSummary>(`tax/reports/${documentId}/summary`, {
        params: { events: eventIds },
      });
  }

  getReportSettingsForm(): Observable<GenericCreateForm> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericCreateForm>(`tax/reports/taxes/settings-form`);
  }

  getEventAdjustmentUrl(documentId: string, eventId: string): string {
    return `tax/events/${documentId}/${eventId}/adjustments`;
  }

  getWorkitemEventAdjustmentUrl(
    workitemId: string,
    documentId: string,
    eventId: string,
  ): string {
    return `workitems/${workitemId}/tax/events/${documentId}/${eventId}/adjustments`;
  }

  getEventAdjustmentOptions(url: string): Observable<TaxAdjustmentOptions[]> {
    return this.sbxHttpClient.entity(this.API_VERSION).get<TaxAdjustmentOptions[]>(url);
  }

  postEventAdjustments(
    url: string,
    taxes: TaxAdjustment[],
    reason: string,
    taxesCollected: boolean,
  ): Observable<GenericStatusResponse> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(url, {
        params: <TaxEventAdjustments>{ taxes, reason, taxesCollected },
      });
  }
}
