import angular from 'angular';
import { List, Map, fromJS } from 'immutable';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';

const EDIT_LINK_CLASS_NAME = 'sub-section-edit-link';

const CUSTOM_SECTION_FIELDS = (defaultSections, hasSubsections, showArticle) => [
  {
    key: 'anchor',
    data: {},
    type: 'enum-dropdown',
    templateOptions: {
      required: true,
      label: 'Section Anchor',
      enumVocab: defaultSections,
    },
  },
  {
    type: 'enum-dropdown',
    templateOptions: {
      subfield: 1,
      required: true,
      label: 'Location',
      enumVocab: [
        { label: 'Before Anchor Section', value: 'before' },
        { label: 'After Anchor Section', value: 'after' },
        { label: 'In section', value: 'child' },
      ],
    },
    data: {},
    key: 'position-1',
    defaultValue: 'after',
    hideExpression: ($viewValue, $modelValue, scope) =>
      hasSubsections(scope.model.anchor),
  },
  {
    type: 'enum-dropdown',
    templateOptions: {
      subfield: 1,
      required: true,
      label: 'Location',
      enumVocab: [
        { label: 'Before Anchor Section', value: 'before' },
        { label: 'After Anchor Section', value: 'after' },
      ],
    },
    data: {},
    key: 'position-2',
    defaultValue: 'after',
    hideExpression: ($viewValue, $modelValue, scope) =>
      !hasSubsections(scope.model.anchor),
  },
  {
    type: 'string-textline',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'Section Title',
    },
    data: {},
    key: 'title',
    defaultValue: '',
  },
  {
    type: 'bool-checkbox',
    templateOptions: {
      subfield: 0,
      required: true,
      label: 'This is a numbered section.',
    },
    data: {},
    key: 'count',
    defaultValue: true,
    hideExpression: () => showArticle(),
  },
  {
    type: 'enum-radios',
    key: 'section_type',
    data: {},
    templateOptions: {
      required: true,
      label: 'Section Type',
      enumVocab: [
        { label: 'Article', value: 'article' },
        { label: 'Numbered Section', value: 'numbered' },
        { label: 'Unnumbered Section', value: 'unnumbered' },
      ],
    },
    hideExpression: () => !showArticle(),
  },
];

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.reviewAndEditDocuments.EditTermsModel
 * @requires https://docs.angularjs.org/api/ng/service/$sce
 * @requires lib/sb.lib.promise.SimpleHTTPWrapper
 *
 * @description
 * This service is in charge of model data for edit terms in document modal.
 *
 * @param {object} doc Document to save info for.
 * @param {object} docModel must have a $saveTerms.
 * @param {string} baseUrlString api string for review and edit docs.
 *
 * @returns {object} Returns a sections management object. Methods and
 *    properties described below are on this object.
 */
export const EditTermsModel = [
  '$q',
  '$sce',
  'SimpleHTTPWrapper',
  'PromiseErrorCatcher',
  '$confirm',
  function ($q, $sce, SimpleHTTPWrapper, PromiseErrorCatcher, $confirm) {
    const ERROR_DIFFERENT_SECTION = (name) =>
      `Section ${name} did not preview properly.`;
    const ERROR_FORM = 'Please correct the errors below.';
    const ERROR_SAVE_DOCUMENT = 'Could not save the document.';
    const ERROR_SAVE_DOCUMENT_SECTIONS_MSG_PREFIX = 'The following section';

    function processSection(section) {
      const form = section.get('form') || Map();
      const fields = form.get('fields', List());
      const lbls = fields.reduce((accum, field) => {
        const to = field.get('templateOptions', Map());
        const lbl = to.get('label');
        if (lbl && to.get('subfield') === 0 && !to.get('readOnly', false)) {
          return accum.push(lbl);
        }
        return accum;
      }, List());
      const jsForm = fields.size ? form.toJS() : null;
      return section
        .set(
          '$formFieldLabels',
          lbls.size > 0 && !section.get('hasSubSections') && lbls.join(', '),
        )
        .set('form', jsForm)
        .set('$trustedContent', $sce.trustAsHtml(section.get('content')));
    }

    function mdAnnotations(docMd, termOverrides) {
      const viewData = docMd
        .get('view')
        .get('fields')
        .reduce((acc, field, key) => {
          acc = acc.set(`${key}`, field);

          field.get('subfields', Map()).map((f, k) => {
            acc = acc.set(`${key.split('.')[0]}.${k}`, f);
          });

          return acc;
        }, Map());

      const fmtData = docMd.get('data');
      return fmtData.reduce((accum, s, sn) => {
        const fields = s.reduce((faccum, f, fn) => {
          const name = `${sn}.${fn}`;
          const terms = termOverrides.get(name);
          const defaultFormat =
            (terms || List()).find((f) => f.get('format') === 'default') || Map();
          const value = Map({
            title: viewData.get(name, Map()).get('title') || f.get('title'),
            value: defaultFormat.get('value') || f.get('fmtValue', 'N/A'),
            formats: terms || f.get('formats'),
            hasRelatedField: Boolean(viewData.get(name)),
          });
          return faccum.set(name, value);
        }, Map());
        return accum.merge(fields);
      }, Map());
    }

    class TermsModel {
      constructor(doc, docModel, baseUrlString) {
        const template = doc.get('$template');
        const md = template.get('formData', Map());
        // The texts often have extra spaces which willl be removed off by the
        // rich text editor, so to make hasChanges work, we trim().
        const customSections = template.get('customSections', Map());
        this.$$originalFormData = md.toJS();
        this.$$originalCustomSections = Object.freeze(customSections.toJS());
        this.$$docMd = doc.get('$metadata');
        this.$$docMdAvailable = doc.get('$availableMd');
        this.$$sectionToFields = doc.get('$sectionToFields');
        this.$$docId = doc.get('id');
        this.$$documentTitle = doc.get('title');
        this.$$docOrigTitle = doc.get('origTitle');
        this.$$formattedTitle = doc.get('formattedTitle');
        this.$$editableDocTitle = doc.get('$editableDocTitle');
        this.$$baseName = doc.get('$template').get('baseName');
        this.$$docModel = docModel;
        this.$$currentPreviewId = null;

        /**
         * @ngdoc property
         * @name $previewing
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Boolean describing if previewing is outstanding
         */
        this.$previewing = false;

        /**
         * @ngdoc property
         * @name $error
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * String error (if any) state.
         */
        this.$error = undefined;

        /**
         * @ngdoc property
         * @name $currentSection
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Referance to the currently open section (if any).
         */
        this.$currentSection = undefined;

        /**
         * @ngdoc property
         * @name $formData
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Current two-way data bound form data.
         */
        this.$formData = md.toJS();

        /**
         * @ngdoc property
         * @name $customSections
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Current two-way data bound custom section texts.
         */
        this.$customSections = customSections.toJS();

        /**
         * @ngdoc property
         * @name $formErrors
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Current form errors from server.
         */
        this.$formErrors = {};

        /**
         * @ngdoc property
         * @name $sections
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * Immutable data of the sections that the document template has.
         */
        this.$sections = doc.get('$sectionToFields').map((sec) => processSection(sec));

        /**
         * @ngdoc property
         * @name $mdAnnotations
         * @propertyOf sb.workitem.reviewAndEditDocuments.EditTermsModel
         *
         * @description
         * The metadata annotation immutable structure for the current document.
         * Includes the overrides from current term preview.
         */
        this.$mdAnnotations = mdAnnotations(
          this.$$docMd,
          doc.get('$template').get('formattedTerms', Map()),
        );

        this.$latestRevisionNumber = doc.get('latestRevisionNumber');

        /**
         * @ngdoc property
         * @name _baseUrlString
         * @propertyOf sb.workitem.reviewAndEditDocuments.ReviewAndEditDocumentsModel
         *
         * @description
         * The full api url string that will be hit.
         */
        this._baseUrlString = baseUrlString;
      }

      $$setSection(newSection, atIndex) {
        this.$sections = this.$sections.set(atIndex, newSection);
      }

      /**
       * @ngdoc method
       * @name $toggleSectionOpen
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Toggle the open/closed state of a particular section.
       *
       * @param {number} index ID index of the section to toggle.
       */
      $toggleSectionOpen(index) {
        const sectionsLength = this.$sections.size;
        let currentSection = this.$sections.get(index);
        const direction = !currentSection.get('isOpen');
        const clickedSectionLevel = currentSection.get('level');
        const oneGreaterLevel = clickedSectionLevel + 1;

        this.$$setSection(currentSection.set('isOpen', direction), index);
        index += 1;
        while (index < sectionsLength) {
          currentSection = this.$sections.get(index);
          if (currentSection.get('level') === clickedSectionLevel) {
            // We've reached a section that has the same level as the clicked one.
            break;
          } else if (!direction) {
            // If direction is close, hide all sections
            this.$$setSection(
              currentSection.set('isOpen', false).set('isShown', false),
              index,
            );
          } else if (currentSection.get('level') === oneGreaterLevel) {
            // If direction is open (implicitly) and its one higher level, show it.
            this.$$setSection(currentSection.set('isShown', true), index);
          }
          index += 1;
        }
      }

      /**
       * @ngdoc method
       * @name $setCurrentSection
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Set the state of the current section.
       *
       * @param {object} section The section that will become the new current section.
       */
      $setCurrentSection(section) {
        this.$currentSection = section;
        this.$error = undefined;
        this.$formErrors = {};
      }

      /**
       * @ngdoc method
       * @name $changeCurrentSectionBy
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Set the state of the current section by moving in a direction.
       *
       * @param {number} direction -1 for previous, 1 for next.
       *
       * @returns {boolean} Passes `false` when no current section is set to change
       *    from.
       */
      $changeCurrentSectionBy(direction) {
        const currentIndex = this.$sections.indexOf(this.$currentSection);
        if (currentIndex === -1) {
          return false;
        }

        const newIndex = currentIndex + direction;
        if (newIndex < 0) {
          this.$currentSection = this.$sections.last();
        } else if (newIndex >= this.$sections.size) {
          this.$currentSection = this.$sections.first();
        } else {
          this.$currentSection = this.$sections.get(newIndex);
        }

        if (this.$currentSection.get('hidden')) {
          return this.$changeCurrentSectionBy(direction);
        }

        this.$error = undefined;
        this.$formErrors = {};
        return true;
      }

      /**
       * @ngdoc method
       * @name $insertCustomSection
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Insert a custom section into the document.
       *
       * @param {object} section The attributes of the new section.
       *
       * @returns {null}
       */
      $insertCustomSection(section) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'templates/' + this.$$docId + '/insert-section',
            data: { sections: this.$customSections, section: section },
          },
          'Could not insert section.',
        ).then((data) => {
          this.$customSections = data.sections;
          this.$previewWorkingValues().catch(PromiseErrorCatcher);
        });
      }

      /**
       * @ngdoc method
       * @name $updateCustomSection
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Update a custom section's info, including anchor and title.
       *
       * @param {string} name The name of the section being edited.
       * @param {object} section The new attributes of the section.
       *
       * @returns {null}
       */
      $updateCustomSection(name, section) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'templates/' + this.$$docId + '/update-section',
            data: {
              sections: this.$customSections,
              name: name,
              section: section,
            },
          },
          'Could not update section.',
        ).then((data) => {
          this.$customSections = data.sections;
          this.$previewWorkingValues().catch(PromiseErrorCatcher);
        });
      }

      /**
       * @ngdoc method
       * @name $removeSection
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Remove a section from the document. Custom sections will be completely
       * deleted, whereby template sections will be marked removed.
       *
       * @param {string} name The name of the section to be removed.
       *
       * @returns {null}
       */
      $removeSection(name) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'templates/' + this.$$docId + '/remove-section',
            data: {
              sections: this.$customSections,
              name: name,
            },
          },
          'Could not remove section.',
        ).then((data) => {
          this.$customSections = data.sections;
          this.$previewWorkingValues().catch(PromiseErrorCatcher);
        });
      }

      /**
       * @ngdoc method
       * @name $reviveSection
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Revive a removed template section.
       *
       * @param {string} name The name of the section to be revived.
       *
       * @returns {null}
       */
      $reviveSection(name) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'templates/' + this.$$docId + '/revive-section',
            data: { sections: this.$customSections, name: name },
          },
          'Could not revive section.',
        ).then((data) => {
          this.$customSections = data.sections;
          this.$previewWorkingValues().catch(PromiseErrorCatcher);
        });
      }

      /**
       * @ngdoc method
       * @name $currentSectionCustomText
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * This is a getter/setter for the current section's custom text.
       *
       * @param {null|string} [setterValue=undefined] If null or a string, this section's
       *   custom text will become this value.
       *
       * @returns {null|string} The value of the current section. Null means this section
       *   has no custom text.
       */
      $currentSectionCustomText(setterValue) {
        const currentSectionName = this.$currentSection.get('name');
        const isSetter = angular.isDefined(setterValue);
        if (isSetter && setterValue === null) {
          delete this.$customSections[currentSectionName];
        } else if (isSetter) {
          if (angular.isUndefined(this.$customSections[currentSectionName])) {
            this.$customSections[currentSectionName] = {};
          }
          this.$customSections[currentSectionName].text = setterValue;
        }
        // We never want to return undefined (null or string only).
        const customSection = this.$customSections[currentSectionName];
        const currentValue = angular.isUndefined(customSection)
          ? null
          : customSection.text;
        return angular.isUndefined(currentValue) ? null : currentValue;
      }

      /**
       * @ngdoc method
       * @name $documentTitle
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * This is a getter/setter for the document's title.
       *
       * @param {null|string} [setterValue=undefined] If null or a string, this document's
       *   title will become this string.
       *
       * @returns {null|string} The document's title.
       */
      $documentTitle(setterValue) {
        const isSetter = angular.isDefined(setterValue);

        if (isSetter) {
          this.$$documentTitle = setterValue;
        }

        return this.$$documentTitle;
      }

      /**
       * @ngdoc method
       * @name $formattedTitle
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * This is a getter/setter for the document's formatted title.
       *
       * @param {null|string} [setterValue=undefined] If null or a string, this document's
       *   formatted title will become this string.
       *
       * @returns {null|string} The document's formatted title.
       */
      $formattedTitle(setterValue) {
        const isSetter = angular.isDefined(setterValue);

        if (isSetter) {
          this.$$formattedTitle = setterValue;
        }

        return this.$$formattedTitle;
      }

      $resetTitleToTemplate() {
        this.$$formattedTitle = this.$$docOrigTitle;
      }

      /**
       * @ngdoc method
       * @name $toggleCustomSectionText
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * This will toggle if the current section's custom text. If its set, it will
       * be unset (to null); if it is unset, it will become the html of the non-custom
       * text.
       */
      $toggleCustomSectionText() {
        const oldValue = this.$currentSectionCustomText();
        if (oldValue !== null) {
          this.$currentSectionCustomText(null);
          this.$previewWorkingValues().catch(PromiseErrorCatcher);
          return;
        }
        if (this.$currentSection.get('editInfo')) {
          $confirm({
            title: 'Are you sure?',
            body: this.$currentSection.get('editInfo'),
            confirmButtonText: 'Customize Section Text',
            dismissButtonText: 'Cancel',
            alertType: 'warning',
          })
            .then(() => {
              this.$currentSectionCustomText(this.$currentSection.get('content'));
            })
            .catch(PromiseErrorCatcher);
        } else {
          this.$currentSectionCustomText(this.$currentSection.get('content'));
        }
      }

      /**
       * @ngdoc method
       * @name $hasChanges
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Report if the term form data has undergone any changes.
       *
       * @returns {boolean} A truthy value indicates the data has changed.
       */
      $hasChanges() {
        return (
          !angular.equals(this.$$originalFormData, this.$formData) ||
          !angular.equals(this.$$originalCustomSections, this.$customSections)
        );
      }

      /**
       * @ngdoc method
       * @name $saveWorkingValuesToDocument
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Save the current form data to the document.
       *
       * @returns {promise} Returns a promise that resolves on success and rejects
       *    on failure.
       */
      $saveWorkingValuesToDocument() {
        return this.$$docModel
          .$saveTerms(
            this.$$docId,
            this.$formData,
            this.$customSections,
            this.$$editableDocTitle ? this.$$formattedTitle : null,
          )
          .catch((err) => {
            if (angular.isObject(err)) {
              if (
                err.length === 3 &&
                typeof err[2] === 'string' &&
                err[2].startsWith(ERROR_SAVE_DOCUMENT_SECTIONS_MSG_PREFIX)
              ) {
                this.$error = ERROR_SAVE_DOCUMENT + ' ' + err[2];
              } else {
                this.$error = ERROR_SAVE_DOCUMENT;
              }
              this.$formErrors = err;
            } else if (err) {
              this.$error = err;
            }
            return $q.reject();
          });
      }

      /**
       * @ngdoc method
       * @name $previewWorkingValues
       * @methodOf sb.workitem.reviewAndEditDocuments.EditTermsModel
       *
       * @description
       * Attempt a preview of the lastest formdata.
       *
       * @returns {promise} Returns a promise that resolves on success and rejects
       *    on failure.
       */
      $previewWorkingValues() {
        const termForm = this.$termForm;
        if (termForm && termForm.$invalid) {
          return $q.reject();
        }
        const startingSec = this.$currentSection;
        const previewId = this.$$currentPreviewId ? this.$$currentPreviewId + 1 : 1;
        this.$error = undefined;
        this.$previewing = true;
        this.$$currentPreviewId = previewId;

        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: this._baseUrlString + 'templates/' + this.$$docId,
            data: {
              previewSection: startingSec && startingSec.get('name'),
              terms: this.$formData,
              customSections: this.$customSections,
              formattedTitle: this.$formattedTitle(),
            },
          },
          'Could not preview section.',
        )
          .then(
            (data) => {
              if (this.$$currentPreviewId !== previewId || data === false) {
                return;
              }
              const newSections = fromJS(data.sections);
              this.$sections = newSections.map((newSec) => {
                // if section is removed, we won't get that section in the newSections.
                const oldSec = this.$sections.find(
                  (s) => s.get('name') === newSec.get('name'),
                );
                // if the section is inserted.
                if (!oldSec) {
                  return processSection(newSec);
                }
                return processSection(
                  fromJS(Object.assign(oldSec.toJS(), newSec.toJS())),
                );
              });
              this.$mdAnnotations = mdAnnotations(
                this.$$docMd,
                fromJS(data.formattedTerms || {}),
              );
              if (!startingSec) {
                // If we don't have a starting section (because user left to the outline
                // to make a preview), we do nothing.
                return;
              }
              const realSec = this.$currentSection;
              const startingName = startingSec.get('name');
              /*
               * We only want to update the current section if we have one and its
               * the same one we were looking at when we started. We also keep the
               * same form so that the user doesn't lose focus on any of the elements
               */
              if (!realSec || realSec.get('name') !== startingName) {
                return;
              }
              const index = this.$sections.findIndex(
                (sec) => sec.get('name') === startingName,
              );
              if (index === -1) {
                // This means the section we are editing disappeared by user's edits.
                // This is not supposed to happen, but if it does, lets handle it
                // with some grace.
                this.$currentSection = null;
                return;
              }
              const oldForm = startingSec.get('form');
              const newCurrent = this.$sections.get(index).set('form', oldForm);
              this.$sections = this.$sections.set(index, newCurrent);
              this.$currentSection = newCurrent;
            },
            (err) => {
              const realSec = this.$currentSection;
              if (this.$$currentPreviewId !== previewId) {
                // Not latest preview, do nothing with this error.
              } else if (!realSec || realSec.get('name') !== startingSec.get('name')) {
                // Not on same section user started preview on
                this.$error = ERROR_DIFFERENT_SECTION(startingSec.get('title'));
              } else if (angular.isObject(err)) {
                // Form errors
                this.$error = ERROR_FORM;
                this.$formErrors = err;
              } else if (err) {
                // String error:
                this.$error = err;
              }
              return $q.reject();
            },
          )
          .finally(() => {
            if (this.$$currentPreviewId === previewId) {
              this.$previewing = false;
              this.$$currentPreviewId = null;
            }
          });
      }

      $revertToRevisionForSection(section, revision) {
        this.$previewing = true;
        const data = {
          revision: revision.get('revisionNumber'),
          section: section.get('name'),
        };
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url:
              this._baseUrlString +
              'documents/' +
              this.$$docId +
              '/restore-custom-section',
            data,
          },
          'Failed to revert to this revision.',
        )
          .then(
            (resp) => {
              this.$$setNewSectionRevisionInfo(revision, resp);
            },
            (error) => {
              this.$error = error;
              return $q.reject();
            },
          )
          .finally(() => {
            this.$previewing = false;
          });
      }

      $$setNewSectionRevisionInfo(revision, resp) {
        let sec = this.$currentSection;
        const secIdx = this.$sections.indexOf(sec);
        const customSections = fromJS(resp.$metadata.data.custom_sections);
        if (!customSections || !customSections.get(sec.get('name'))) {
          return this.$toggleCustomSectionText();
        }
        const newSec = customSections.get(sec.get('name'));
        const secText = newSec.get('fmtValue');
        // when reverting the revision, the reverted revision
        // always becomes the newest revision.
        if (sec.get('hasChanges') === 'current') {
          // if changes are current, we'll just modify the virtual rev info.
          revision = revision.set('revisionNumber', null);
          sec = sec.set('revisions', sec.get('revisions').set(0, revision));
        } else {
          // if changes are stale / none, we'll append new virtual revision.
          revision = revision.set('revisionNumber', null);
          sec = sec.set('revisions', sec.get('revisions').unshift(revision));
        }
        this.$currentSection = sec;
        this.$sections = this.$sections.set(secIdx, sec);
        // set custom section text in the editor to reflect changes.
        this.$currentSectionCustomText(secText);
      }

      $revertToRevisionForTerm(nameSegments, revision) {
        const revisionNumber = revision.get('revisionNumber');
        this.$previewing = true;
        const data = {
          revision: revisionNumber,
          schema: nameSegments[0],
          field: nameSegments[1],
        };
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url:
              this._baseUrlString + 'documents/' + this.$$docId + '/restore-md-field',
            data,
          },
          'Failed to revert to this revision.',
        )
          .then(
            (resp) => {
              const revisions = resp.$revisions;
              const lastRevisions = revisions.find(
                (rev) => rev.revision_no === revisionNumber,
              );
              const secIdx = this.$sections.indexOf(this.$currentSection);
              this.$$setNewFormData(lastRevisions, nameSegments, secIdx);

              return lastRevisions;
            },
            (error) => {
              this.$error = error;
              return $q.reject();
            },
          )
          .finally(() => {
            this.$previewing = false;
          });
      }

      $$setNewFormData(lastRevision, nameSegments, secIdx) {
        const newData = lastRevision.changes.find(
          (change) =>
            change.schema === nameSegments[0] && change.field === nameSegments[1],
        );
        if (!newData) {
          return;
        }
        // 1. set revision history modal.
        const name = `${newData.schema}-${newData.field}`;
        const lookup = name.replace('-', '.');
        let fld = this.$currentSection
          .get('fields')
          .find((fld) => fld.get('name') === lookup);
        const fldIdx = this.$currentSection.get('fields').indexOf(fld);
        if (fld.get('hasChanges') === 'current') {
          // if changes are current, we'll just modify the virtual rev info.
          const rev = fld.get('revisions').get(0);
          fld = fld.set(
            'revisions',
            fld.get('revisions').set(0, rev.set('value', newData.fmt_new_value)),
          );
        } else {
          // if changes are stale / none, we'll append new virtual revision.
          fld = fld.set(
            'revisions',
            fld.get('revisions').unshift(
              Map({
                revisionNumber: lastRevision.revision_no,
                value: newData.fmt_new_value,
                creatorName: lastRevision.creator_name,
                createdDate: lastRevision.created_date,
              }),
            ),
          );
        }
        this.$currentSection = this.$currentSection.set(
          'fields',
          this.$currentSection.get('fields').set(fldIdx, fld),
        );
        this.$sections = this.$sections.set(secIdx, this.$currentSection);

        // 2. set form data.
        this.$formData[name] = newData.new_value;
      }

      $undoCurrentSectionChanges() {
        const sec = this.$currentSection;
        const fieldNames = sec.get('fields').map((fld) => fld.get('name'));
        const revisionNumber =
          sec.get('hasChanges') === 'current'
            ? this.$latestRevisionNumber
            : this.$latestRevisionNumber - 1;
        const data = {
          // if we have current changes, then revert to non-virtual revision;
          // else revert to previous revision.
          revision: revisionNumber,
          section: sec.get('name'),
          // formatted like set.name
          fieldKeys: fieldNames,
        };
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url:
              this._baseUrlString +
              'documents/' +
              this.$$docId +
              '/restore-section-revision',
            data,
          },
          'Failed to revert to this revision.',
        ).then(
          (resp) => {
            const revisions = resp.$revisions;
            const lastRevisions = revisions[revisions.length - 1];
            const secIdx = this.$sections.indexOf(this.$currentSection);
            fieldNames.map((name) => {
              const segs = name.split('.');
              this.$$setNewFormData(lastRevisions, segs, secIdx);
            });
            const secRev = sec
              .get('revisions')
              .find((rev) => rev.get('revisionNumber') === revisionNumber);
            return this.$$setNewSectionRevisionInfo(secRev, resp);
          },
          (error) => {
            this.$error = error;
            return $q.reject();
          },
        );
      }
    }

    return (doc, docModel, baseUrlString) =>
      new TermsModel(doc, docModel, baseUrlString);
  },
]; // end EditTermsModel

/**
 * @ngdoc object
 * @name sb.workitem.reviewAndEditDocuments.controller:EditTermsModalCtrl
 *
 * @description
 * Controller for the review documents MD form modal. Requires a document
 * and a model object for that document with an `$updateDocument` method.
 */
const EditTermsModalCtrl = [
  '$scope',
  '$timeout',
  '$q',
  '$confirm',
  '$observable',
  'EditTermsModel',
  'Document',
  'DocumentModel',
  'OpenSectionName',
  'BaseUrlString',
  'PromiseErrorCatcher',
  '$sbModal',
  '$formModal',
  'viewTextDiff',
  'RefreshOnSave',
  function (
    $scope,
    $timeout,
    $q,
    $confirm,
    $observable,
    EditTermsModel,
    Document,
    DocumentModel,
    OpenSectionName,
    BaseUrlString,
    PromiseErrorCatcher,
    $sbModal,
    $formModal,
    viewTextDiff,
    RefreshOnSave,
  ) {
    const persist = EditTermsModel(Document, DocumentModel, BaseUrlString);

    function save() {
      persist
        .$saveWorkingValuesToDocument()
        .then((newDocs) => {
          $scope.$close(newDocs);
          if (RefreshOnSave === true) {
            location.reload();
          }
        })
        .catch(PromiseErrorCatcher);
    }
    function cancelSave() {
      let confirmation;
      if (persist.$hasChanges()) {
        confirmation = $confirm({
          title: 'Unsaved Changes',
          body: `You have unsaved changes to the document's terms. Are you sure
               you'd like to discard these?`,
          confirmButtonText: 'Discard Changes',
          dismissButtonText: 'Go Back to Editing',
        });
      }
      $q.when(confirmation)
        .then(() => {
          $scope.$dismiss();
        })
        .catch(PromiseErrorCatcher);
    }
    function toggleSubSections(index) {
      persist.$toggleSectionOpen(index);
    }

    function validateTermForm() {
      if (persist.$termForm && Object.keys(persist.$termForm.$error).length !== 0) {
        persist.$error = persist.ERROR_FORM;
        return false;
      }
      return true;
    }
    function gotoSection(evt, section) {
      if (!validateTermForm()) {
        return;
      }
      const form = section ? section.get('form') : false;
      if (form && form.fields) {
        const fields = section.get('fields');
        const formFields = form.fields.map((formField) => {
          const name = formField.key.replace('-', '.');
          const field = fields.find((field) => field.get('name') === name);
          formField.templateOptions.field = field;
          formField.templateOptions.viewLargeDiff = viewLargeDiff;
          return formField;
        });
        form.fields = formFields;
        form.editableFields = angular.copy(
          formFields.filter((field) => !field.templateOptions.readOnly),
        );
        section = section.set('form', form);
      }
      persist.$setCurrentSection(section);
      $scope.shouldCollapseSectionsList = Boolean(section);
      if (evt) {
        evt.preventDefault();
      }
    }

    function previousSection() {
      if (!validateTermForm()) {
        return;
      }
      persist.$changeCurrentSectionBy(-1);
      gotoSection(null, persist.$currentSection);
    }

    function nextSection() {
      if (!validateTermForm()) {
        return;
      }
      persist.$changeCurrentSectionBy(1);
      gotoSection(null, persist.$currentSection);
    }

    function contentClick({ target }) {
      if (target.classList.contains(EDIT_LINK_CLASS_NAME)) {
        const sectionLinkId = target.parentNode.id.replace('shoobx-section-', '');
        const section = persist.$sections.find(
          (section) => section.get('name') === sectionLinkId,
        );
        persist.$setCurrentSection(section);
        $scope.shouldCollapseSectionsList = Boolean(section);
      }
    }

    function buildSectionsTree(withRefsOnly = false, withoutCurrentSection = false) {
      return persist.$sections
        .filter((section) => !withRefsOnly || section.get('ref'))
        .filter((section) => {
          return (
            !withoutCurrentSection ||
            (persist.$currentSection &&
              persist.$currentSection.get('name') !== section.get('name'))
          );
        })
        .map((section) => {
          const ref = section.get('ref') === null ? '' : `${section.get('ref')} - `;
          const indent = Array(section.get('level') + 1).join('-- ');

          return {
            label: `${indent}${ref}${section.get('title')}`,
            value: section.get('name'),
            ref: section.get('ref'),
            singleRef: section.get('singleRef'),
            // This title is used in tooltip
            title: `${section.get('ref')} - ${section.get('title')}`,
          };
        })
        .toArray();
    }

    function hasSubsections(anchor) {
      if (!anchor) {
        return false;
      }

      const section = persist.$sections.find(
        (section) => section.get('name') === anchor,
      );
      const hasSubsections = section.get('hasSubSections');

      // Edit custom section
      if (persist.$currentSection) {
        const currentSectionName = persist.$currentSection.get('name');
        const customSection = persist.$customSections[currentSectionName];

        return customSection.position !== 'child' && hasSubsections;
      }

      return hasSubsections;
    }

    function showArticle() {
      return persist.$$baseName === 'preferred-financing-charter';
    }

    function addCustomSection() {
      $formModal(
        {
          title: 'Add custom section',
          primaryButtonText: 'Add',
          forms: {
            form: {
              fields: CUSTOM_SECTION_FIELDS(
                buildSectionsTree(),
                (anchor) => hasSubsections(anchor),
                showArticle,
              ),
            },
          },
          formData: { form: {} },
          htmlContent: require('./templates/custom-section-form-modal.html'),
          onConfirmPromise({ $formData }) {
            const form = $formData.form;
            const position = hasSubsections(form.anchor)
              ? form['position-2']
              : form['position-1'];

            $scope.persist.$insertCustomSection({
              text: '<p>Text for section goes here</p>',
              title: form.title,
              anchor: form.anchor,
              position,
              count: form.section_type === 'unnumbered' ? false : form.count,
              type: form.section_type === 'article' ? 'article' : 'section',
            });
            $scope.persist.$updatedSection = form.title;
          },
        },
        'md',
      ).catch(PromiseErrorCatcher);
    }

    function editCustomSection() {
      const section = $scope.persist.$currentSection;
      const position = $scope.persist.$customSections[section.get('name')].position;
      const sectionData = Object.assign(
        {},
        $scope.persist.$customSections[section.get('name')],
        {
          'position-1': position,
          'position-2': position,
        },
      );

      $formModal(
        {
          title: 'Edit Custom Section',
          primaryButtonText: 'Save',
          forms: {
            form: {
              fields: CUSTOM_SECTION_FIELDS(
                buildSectionsTree(false, true),
                (anchor) => hasSubsections(anchor),
                showArticle,
              ),
            },
          },
          formData: { form: sectionData },
          htmlContent: require('./templates/custom-section-form-modal.html'),
          onConfirmPromise({ $formData }) {
            const form = $formData.form;
            const position = hasSubsections(form.anchor)
              ? form['position-2']
              : form['position-1'];

            $scope.persist.$updateCustomSection(section.get('name'), {
              title: form.title,
              anchor: form.anchor,
              position,
              count: form.count,
            });
            $scope.persist.$updatedSection = form.title;
          },
        },
        'md',
      ).catch(PromiseErrorCatcher);
    }

    function editDocumentTitle() {
      const $outerScope = $scope;
      $sbModal
        .open({
          windowClass: 'document-title-modal',
          template: require('./templates/edit-document-title-modal.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.title = $outerScope.persist.$formattedTitle();
              $scope.update = (event) => {
                $scope.title = event;
              };
              $scope.save = () => {
                $outerScope.persist.$formattedTitle($scope.title);
                $outerScope.persist.$previewWorkingValues();
                $scope.$dismiss();
              };
              $scope.resetTitleToTemplate = () => {
                $outerScope.persist.$resetTitleToTemplate();
                $outerScope.persist.$previewWorkingValues();
                $scope.$dismiss();
              };
              $scope.editorFeatures = {
                termPills: termPillsData$,
                refPills: refPillsData$,
                enableFontStyle: true,
                softBreak: true,
              };
            },
          ],
        })
        .result.catch(PromiseErrorCatcher);
    }

    function removeSection() {
      const section = $scope.persist.$currentSection;
      $confirm({
        body: `Are you sure you want to remove ${section.get('title')} section?`,
        alertType: 'warning',
        confirmButtonText: 'Yes',
        dismissButtonText: 'No',
      })
        .then(() => {
          $scope.persist.$removeSection(section.get('name'));
          $scope.persist.$setCurrentSection(null);
          $scope.shouldCollapseSectionsList = false;
          $scope.persist.$updatedSection = section.get('name');
        })
        .catch(PromiseErrorCatcher);
    }

    function reviveSection() {
      const section = $scope.persist.$currentSection;
      $confirm({
        body: `Are you sure you want to revive ${section.get('title')} section?`,
        alertType: 'warning',
        confirmButtonText: 'Yes',
        dismissButtonText: 'No',
      })
        .then(() => {
          $scope.persist.$reviveSection(section.get('name'));
          $scope.persist.$setCurrentSection(null);
          $scope.shouldCollapseSectionsList = false;
          $scope.persist.$updatedSection = section.get('name');
        })
        .catch(PromiseErrorCatcher);
    }

    function generateTermsPillData(mdAnnotations) {
      const annotations = mdAnnotations.toJS();
      const fields = persist.$$docMdAvailable.reduce((arr, label, value) => {
        arr.push({ value, label });

        return arr;
      }, []);
      const fieldsFormats = {};
      fields.forEach((field) => {
        fieldsFormats[field.value] = [];
        mdAnnotations
          .get(field.value)
          .get('formats')
          .forEach((format) => {
            fieldsFormats[field.value].push({
              value: format.get('format'),
              label: format.get('title'),
            });
          });
      });

      return {
        form: {
          fields,
          fieldsFormats,
        },
        values: annotations,
      };
    }

    const termPillsData$ = new BehaviorSubject(
      generateTermsPillData(persist.$mdAnnotations),
    );
    $scope.$watch(
      () => persist.$mdAnnotations && persist.$mdAnnotations.hashCode(),
      (p, n) => {
        if (p === n) {
          return;
        }

        const data = generateTermsPillData(persist.$mdAnnotations);
        termPillsData$.next(data);
      },
    );

    const refPillsData$ = new BehaviorSubject({
      references: buildSectionsTree(true),
      currentReference: this.$currentSection && this.$currentSection.get('name'),
    });

    function viewLargeDiff(field) {
      const nameSegments = field.get('name').split('.');
      $sbModal
        .open({
          size: 'lg',
          windowClass: 'large-diff-modal',
          template: require('./templates/large-diff-modal.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.field = field;
              $scope.latestRevision = field.get('revisions').get(0);
              $scope.revertToRevision = (revision) => {
                $scope.loading = true;
                persist
                  .$revertToRevisionForTerm(nameSegments, revision)
                  .then(() => {
                    $scope.$dismiss();
                    persist.$previewWorkingValues();
                    // refresh field history button stuff.
                    gotoSection(null, persist.$currentSection);
                  })
                  .catch(PromiseErrorCatcher);
              };
            },
          ],
        })
        .result.catch(PromiseErrorCatcher);
    }

    function numberOfChangedSections() {
      const changedSections = $scope.doc.get('$changedSections');
      return changedSections ? changedSections.size : 0;
    }

    function nextChangedSection() {
      const section = persist.$currentSection;
      const changedSections = $scope.doc.get('$changedSections');
      const sectionMd = changedSections.find(
        (sec) => sec.get('name') === section.get('name'),
      );
      const currentIndex = changedSections.indexOf(sectionMd);
      const nextChangedSection =
        currentIndex + 1 === changedSections.size
          ? changedSections.get(0)
          : changedSections.get(currentIndex + 1);
      const nextSection = persist.$sections.find(
        (sec) => sec.get('name') === nextChangedSection.get('name'),
      );
      gotoSection(null, nextSection);
    }

    function previousChangedSection() {
      const section = persist.$currentSection;
      const changedSections = $scope.doc.get('$changedSections');
      const sectionMd = changedSections.find(
        (sec) => sec.get('name') === section.get('name'),
      );
      const currentIndex = changedSections.indexOf(sectionMd);
      const prevChangedSection =
        currentIndex - 1 < 0
          ? changedSections.get(changedSections.size - 1)
          : changedSections.get(currentIndex - 1);
      const previousSection = persist.$sections.find(
        (sec) => sec.get('name') === prevChangedSection.get('name'),
      );
      gotoSection(null, previousSection);
    }

    function undoCurrentSectionChanges() {
      const sec = persist.$currentSection;
      // if the section is inserted section and only has one revision, then undoing the changes
      // means removing the section.
      if (sec.get('inserted') && sec.get('revisions').size === 1) {
        return removeSection();
      }

      persist.$revertingChanges = true;
      persist
        .$undoCurrentSectionChanges()
        .then(() => {
          persist.$revertingChanges = false;
        })
        .catch(PromiseErrorCatcher);
    }

    const template = ($scope.template = Document.get('$template'));
    $scope.doc = Document;
    $scope.persist = persist;
    $scope.docPersist = DocumentModel;
    $scope.save = save;
    $scope.contentClick = contentClick;
    $scope.cancelSave = cancelSave;
    $scope.toggleSubSections = toggleSubSections;
    $scope.gotoSection = gotoSection;
    $scope.numberOfChangedSections = numberOfChangedSections;
    $scope.previousSection = previousSection;
    $scope.nextSection = nextSection;
    $scope.undoCurrentSectionChanges = undoCurrentSectionChanges;
    $scope.addCustomSection = addCustomSection;
    $scope.editCustomSection = editCustomSection;
    $scope.editDocumentTitle = editDocumentTitle;
    $scope.removeSection = removeSection;
    $scope.reviveSection = reviveSection;
    const templateEditable =
      template.get('hasTerms') || template.get('hasEditableCustomSections');
    $scope.editableDoc = templateEditable && Document.get('$editable');
    $scope.isDocumentCustomizationDisabled =
      DocumentModel.$workitemData &&
      DocumentModel.$workitemData.get('isDocumentCustomizationDisabled');
    $scope.editableDocTitle =
      !$scope.isDocumentCustomizationDisabled && Document.get('$editableDocTitle');
    $scope.docTitle = Document.get('title');
    $scope.shouldCollapseSectionsList = false;
    $scope.termPillsData$ = termPillsData$;
    $scope.refPillsData$ = refPillsData$;
    $scope.viewLargeDiff = viewLargeDiff;
    $scope.viewTextDiff = viewTextDiff;
    $scope.previousChangedSection = previousChangedSection;
    $scope.nextChangedSection = nextChangedSection;
    $scope.editorFeatures = {
      enableParaStyle: true,
      enableSpanStyle: true,
      enableFontStyle: true,
      enableListStyle: true,
      customSubElement: true,
      customTableElement: true,
      anchorPills: true,
      staticPills: true,
      termPills: termPillsData$,
      refPills: refPillsData$,
    };
    const formData$ = $observable.fromWatcher($scope, 'persist.$formData', true);
    const updatedSection$ = $observable.fromWatcher(
      $scope,
      'persist.$updatedSection',
      true,
    );
    combineLatest([formData$, updatedSection$], () => true)
      .pipe(skip(1)) // Don't need to do this on render/init.
      .takeUntilScopeDestroy($scope)
      .subscribe(() => {
        // We run in an immediate $timeout because we need termForm to catch up to formData's
        // changes and adjust $invalid correctly.
        $timeout(() => {
          persist
            .$previewWorkingValues()
            .then(() => {
              // After something changes, we need to update our references
              $scope.refPillsData$.next({
                references: buildSectionsTree(true),
                currentReference:
                  persist.$currentSection && persist.$currentSection.get('name'),
              });
            })
            .catch(PromiseErrorCatcher);
          // We need to wait for the section to get added to rebuild.
          // So add a bit of a delay.
        }, 1000);
      });

    if (OpenSectionName) {
      const openSection = persist.$sections.find(
        (sec) => sec.get('name') === OpenSectionName,
      );
      gotoSection(null, openSection);
      $scope.shouldCollapseSectionsList = Boolean(openSection);
    }
  },
]; // end EditTermsModalCtrl

/**
 * @ngdoc object
 * @kind function
 * @name sb.workitem.reviewAndEditDocuments.object:$editTermsModal
 *
 * @description
 * Call this function to open a review and edit modal for a given doc.
 *
 * @param {object} doc The document object to review and eidt.
 * @param {object} persist An object with the following properties and methods:
 *  $previewWorkingValues(), $sections, $setCurrentSection(openSection), toggleSectionOpen(index),
 *  $changeCurrentSectionBy(number), $saveWorkingValuesToDocument(), hasChanges().
 * @param {string} baseUrlString the FULL api url which the editTermsModal will hit.
 * @param {object} [openSection] Which section to open.
 * @param {boolean} refreshOnSave should the entire page refresh when saving?
 *
 * @returns {promise} This promise will be resolved when the modal is closed.
 */
export const $editTermsModal = [
  '$sbModal',
  function ($sbModal) {
    return (doc, persist, baseUrlString, openSection, refreshOnSave) =>
      $sbModal.open({
        template: require('./templates/edit-md-modal.html'),
        controller: EditTermsModalCtrl,
        keyboard: false,
        backdrop: 'static',
        windowClass: 'edit-terms-modal',
        size: 'lg',
        resolve: {
          Document: () => doc,
          DocumentModel: () => persist,
          OpenSectionName: () => openSection && openSection.get('name'),
          BaseUrlString: () => baseUrlString,
          RefreshOnSave: () => refreshOnSave || false,
        },
      }).result;
  },
]; // end $editTermsModal

export const viewTextDiff = [
  '$sbModal',
  'PromiseErrorCatcher',
  function ($sbModal, PromiseErrorCatcher) {
    return (section, persist) => {
      $sbModal
        .open({
          size: 'lg',
          windowClass: 'large-diff-modal',
          template: require('./templates/text-diff-modal.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.section = section;
              $scope.latestRevision = section.get('revisions')
                ? section.get('revisions').get(0)
                : null;
              $scope.revertToRevision = (rev) => {
                $scope.loading = true;
                persist
                  .$revertToRevisionForSection(section, rev)
                  .then(() => {
                    $scope.$dismiss();
                  })
                  .catch(PromiseErrorCatcher);
              };
              $scope.loading = false;
            },
          ],
        })
        .result.catch(PromiseErrorCatcher);
    };
  },
];
