<div cdkDropList class="sbx-draggable-list" (cdkDropListDropped)="drop($event)">
  <div class="sbx-draggable-box" *ngFor="let item of items" cdkDrag>
    <div class="sbx-draggable-placeholder" *cdkDragPlaceholder></div>
    <div class="sbx-draggable-indicator"></div>
    <ng-container
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    >
    </ng-container>
  </div>
</div>
