<ul class="sbx-adjust-taxes">
  <li *ngFor="let row of rows">
    <div [class]="'sbx-event-row' + (row?.adjustment?.canEdit ? '' : ' sbx-tall-row')">
      <div class="sbx-event-name subfield-{{ row.subfield }}">
        {{ row.adjustment !== null ? row.adjustment.title : row.group }}
      </div>
      <div class="sbx-event-old-value sbx-bold">
        <span *ngIf="row.adjustment !== null && row?.adjustment.tax !== null">{{
          row.adjustment?.tax | currency
        }}</span>
      </div>
      <div class="sbx-event-value">
        <div class="sbx-form-field" *ngIf="row.adjustment !== null">
          <div class="sbx-form-field-input-addon sbx-prefix">$</div>
          <sbx-number-textline-base
            [model]="row.adjustment.override"
            [to]="{ readOnly: !row.adjustment.canEdit }"
            [precision]="2"
            [showError]="row.showError"
            (focus)="handleFocus(row)"
            (blur)="handleBlur(row)"
            (changeValue)="handleChange(row, $event)"
          ></sbx-number-textline-base>
        </div>
        <div *ngIf="row.showError" class="sbx-form-field-error">
          Value must be at least 0.
        </div>
      </div>
      <div class="sbx-event-remove" *ngIf="row.canRemove" (click)="removeRow(row)">
        <sbx-icon type="remove"></sbx-icon>
      </div>
    </div>
  </li>

  <li *ngIf="canAdd">
    <div class="sbx-event-row">
      <div class="sbx-event-value sbx-action">
        <sbx-button
          title="Add Custom Entry"
          icon="plus"
          (click)="addCustomEntry()"
        ></sbx-button>
      </div>
    </div>
  </li>

  <li>
    <div class="sbx-event-row">
      <div class="sbx-event-name sbx-bold">Total Taxes</div>
      <div class="sbx-event-old-value"></div>
      <div class="sbx-event-value sbx-bold">
        <span *ngIf="totalTaxes !== null">{{ totalTaxes | currency }}</span>
      </div>
    </div>
  </li>
</ul>
