import {
  Component,
  Inject,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Downgrade } from '../downgrade';
import { StakeholderCard } from '@shoobx/types/webapi-v2';
import { Stakeholders } from '../../../js/lib/stakeholder/service';

@Downgrade.Component('ngShoobx', 'sbx-stakeholder-card')
@Component({
  selector: 'sbx-stakeholder-card',
  templateUrl: './sbx-stakeholder-card.component.html',
  styleUrls: ['./sbx-stakeholder-card.component.scss'],
})
export class SbxStakeholderCardComponent implements OnInit, OnChanges {
  @Input() stakeholderId: string;

  @Input() format = 'medium';
  @Input() popoverTitle: string;
  @Input() showApproval = false;
  @Input() highlightTerm: string;
  @Input() affiliates = false;
  @Input() showLoader = false;

  @Input() public stakeholderObject: StakeholderCard & {
    archived?: boolean;
    representative?: any;
    url?: string;
    position?: string;
    roleTitles?: string[];
    approvalStatus?: string;
  };

  public initialized = false;

  constructor(@Inject(Stakeholders) private stakeholderService) {}

  async ngOnInit() {
    if (this.stakeholderId && !this.stakeholderObject && !this.initialized) {
      this.stakeholderObject = { fullName: '', id: '' };
      await this.getStakeholderById(this.stakeholderId);
    }
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.stakeholder || changes.stakeholderId || changes.highlightTerm) &&
      this.initialized
    ) {
      const newValue =
        changes.stakeholderId?.currentValue || changes.stakeholder?.currentValue;
      this.setStakeholderDetails(newValue);
    }
  }

  setStakeholderDetails(stakeholderDetails): void {
    if (typeof stakeholderDetails === 'string') {
      this.getStakeholderById(stakeholderDetails);
    } else {
      this.stakeholderObject = stakeholderDetails;
      if (!this.stakeholderObject) {
        this.stakeholderObject = { fullName: '', id: '' };
      }
    }
  }

  loadStakeholder(id: string): void {
    return this.stakeholderService
      .getStakeholder(id, false)
      .then((stakeHolder: StakeholderCard) => {
        this.stakeholderObject = stakeHolder;
      });
  }

  getStakeholderById(id: string): void {
    // only allow numbers, letters, and underscore
    id = id.toString().replace(/[^0-9a-z_]/gi, '');
    return this.loadStakeholder(id);
  }
}
