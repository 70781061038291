import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-enum-dropdown',
  templateUrl: './sbx-enum-dropdown.component.html',
  styleUrls: ['./sbx-enum-dropdown.component.scss'],
})
export class SbxEnumDropdownComponent extends FieldType<FieldTypeConfig> {
  selectItem(value) {
    this.formControl.setValue(value);
  }
}
