import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { SbxTableWithStakeholderFilterWorkitemComponent } from './sbx-table-with-stakeholder-filter.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxTableWithStakeholderFilterWorkitemComponent],
  exports: [SbxTableWithStakeholderFilterWorkitemComponent],
})
export class SbxTableWithStakeholderFilterWorkitemModule {
  static entryComponents = [SbxTableWithStakeholderFilterWorkitemComponent];

  static forRoot(): ModuleWithProviders<SbxTableWithStakeholderFilterWorkitemModule> {
    return {
      ngModule: SbxTableWithStakeholderFilterWorkitemModule,
    };
  }
}
