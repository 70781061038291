import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxHttpClient } from '@/core/http';

/**
 *  Address Component
 *
 *  By default the address label will be shown before the fields in the address schema.
 *  To hide the address label, set the field hideAddressLabel to true in templateOptions.
 *  Note, how this field differs from hideExpression which will hide the entire address field.
 *  Use classes sbx-address-label and sbx-address-field to style the address fields.

 */

@Component({
  selector: 'sbx-address',
  templateUrl: './sbx-address.component.html',
  styleUrls: ['./sbx-address.component.scss'],
})
export class SbxAddressComponent extends FieldType<FieldTypeConfig> implements OnInit {
  urlVersion = '2';
  url = 'addressSchema/';
  country = 'US';
  fieldKey: any;

  constructor(private sbxHttp: SbxHttpClient) {
    super();
  }

  ngOnInit() {
    this.fieldKey = this.field.key;
    // Take current value of country, if that's null then take default country, if that's also null then take US
    const country =
      this.field.model?.country ||
      this.getValue(this.fieldKey, this.field.model) ||
      this.field?.defaultValue?.country;

    this.getAddressSchema(country);
  }

  getObj(key, model) {
    const obj = key.split('.').reduce((acc, cur) => acc[cur], model);
    return obj;
  }

  getValue(key, model) {
    return this.getObj(key, model)?.country;
  }

  getAddressSchema(country: string) {
    this.sbxHttp
      .entity(this.urlVersion)
      .get(this.url + country)
      .subscribe(
        (res: any) => {
          const formFields = res.fields.map((field) => {
            if (field.key === 'country') {
              field.hooks = {
                onInit: (field) => {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.getAddressSchema(value);
                  });
                },
              };
            }

            // SbxFormComponent.prepareFields should take care of this, but it doesn't
            // because field is already moved to fieldGroup.
            field.hideExpression = this.field.hideExpression;

            return {
              ...field,
              type: field.type,
              key: `${this.fieldKey}.${field.key}`,
              templateOptions: {
                ...field.templateOptions,
                subfield: this.field.templateOptions.subfield + 1,
              },
            };
          });
          this.field.templateOptions.fields = formFields;
        },
        ({ error }) => {
          this.field.form.setErrors(error);
        },
        () => {
          this.field.formControl.enable();
        },
      );
  }
}
