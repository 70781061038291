import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import { sbPermissionLink } from './permission';
import { ProcessUrlInfo } from './processurlinfo';
import { BackendLocation, URLAvailability } from './url';

/**
 * @ngdoc overview
 * @name sb.lib.url
 *
 * @description
 * This module houses code for working with and getting information about URLs.
 */
export default angular
  .module('sb.lib.url', [ngSanitize])

  .factory('BackendLocation', BackendLocation)
  .factory('URLAvailability', URLAvailability)
  .factory('ProcessUrlInfo', ProcessUrlInfo)
  .directive('sbPermissionLink', sbPermissionLink).name;
