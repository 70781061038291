import { Component, Input } from '@angular/core';
import { SbxBoolRadiosComponent } from './sbx-bool-radios.component';

@Component({
  selector: 'sbx-bool-radios-vertical',
  templateUrl: './sbx-bool-radios.component.html',
  styleUrls: ['./sbx-bool-radios.component.scss'],
})
export class SbxBoolRadiosVerticalComponent extends SbxBoolRadiosComponent {
  @Input() layout: 'horizontal' | 'vertical' = 'vertical';
}
