import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxPipesModule } from '../pipes/pipes.module';
import { SbxModalModule } from '../sbx-modal/sbx-modal.module';
import { SbxVideoModalComponent } from './sbx-video-modal.component';
import { SbxVideoComponent } from './sbx-video.component';

@NgModule({
  imports: [CommonModule, SbxModalModule, SbxPipesModule],
  declarations: [SbxVideoComponent, SbxVideoModalComponent],
  exports: [SbxVideoComponent],
})
export class SbxVideoModule {
  static entryComponents = [SbxVideoComponent];

  static forRoot(): ModuleWithProviders<SbxVideoModule> {
    return {
      ngModule: SbxVideoModule,
    };
  }
}
