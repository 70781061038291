import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxWebcamComponent } from './sbx-webcam.component';

@NgModule({
  declarations: [SbxWebcamComponent],
  imports: [CommonModule, SbxIconModule],
  exports: [SbxWebcamComponent],
})
export class SbxWebcamModule {}
