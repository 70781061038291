import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
})
export class SbxHrAdminModule {
  static forRoot(): ModuleWithProviders<SbxHrAdminModule> {
    return {
      ngModule: SbxHrAdminModule,
    };
  }
}
