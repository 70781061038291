import angular from 'angular';
/**
 * @ngdoc object
 * @name sb.listpage.sbFilteredListFilter
 *
 **/
export const sbFilteredListFilter = [
  'TextModelOptions',
  function (TextModelOptions) {
    return {
      restrict: 'E',
      require: 'ngModel',
      template: (elem, attrs) => {
        if (attrs.onTop) {
          return require('./templates/filter-top.html');
        }
        return require('./templates/filter.html');
      },
      scope: {
        model: '=ngModel',
        description: '<description',
        totalSize: '<totalSize',
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.fieldLabels = {};
          $scope.searchOptions = TextModelOptions;

          this.$onInit = () => {
            if ($scope.description) {
              // Applying default filters if provided.
              this.defaultsExists = false;

              $scope.description.fields.forEach((field) => {
                if (!(field.key in $scope.model.filter) && field.defaultValue) {
                  $scope.model.filter[field.key] = field.defaultValue;
                  this.defaultsExists = true;
                }
              });
              if (this.defaultsExists) {
                $scope.model.setFilter();
              }
            }
          };

          $scope.hasFilters = () => {
            let filters = false;
            angular.forEach($scope.model.filter, (value) => {
              filters = filters || Boolean(value);
            });
            return filters;
          };

          $scope.clearFilter = (field) => {
            if (field) {
              $scope.model.filter[field] = null;
            } else {
              $scope.model.filter = {};
            }
            $scope.model.setFilter();
          };
        },
      ],
    };
  },
];
