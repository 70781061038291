import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SbxFormModalService } from './sbx-form-modal.service';
import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';

@Directive({
  selector: '[sbxFormModal]',
})
export class SbxFormModalDirective {
  @Input() sbxFormModalUrl;
  @Input() sbxFormModalUrlVersion;
  @Input() sbxFormModalTitle;
  @Input() sbxFormModalCancelButtonTitle;
  @Input() sbxFormModalCloseButtonTitle;
  @Input() sbxFormModalOkButtonTitle;
  @Input() sbxFormModalTopMessageTemplate;
  @Input() sbxFormModalTopMessageText;
  @Input() sbxFormModalTopMessageTemplateContext;
  @Input() sbxFormModalTopInfoMessageText;
  @Input() sbxFormModalBottomMessageTemplate;
  @Input() sbxFormModalBottomMessageText;
  @Input() sbxFormModalBottomMessageTemplateContext;
  @Input() sbxFormModalConfig;
  @Input() sbxFormModalReadOnly;

  @Output() sbxFormModalOnDismiss = new EventEmitter();
  @Output() sbxFormModalOnClose = new EventEmitter();

  constructor(
    private sbxFormModalService: SbxFormModalService,
    public currentModal: NgbActiveModal,
  ) {}

  @HostListener('click', ['$event'])
  onClick($event) {
    const data: any = {};

    if (this.sbxFormModalUrl !== undefined) {
      data.url = this.sbxFormModalUrl;
    }

    if (this.sbxFormModalUrlVersion !== undefined) {
      data.urlVersion = this.sbxFormModalUrlVersion;
    }

    if (this.sbxFormModalTitle !== undefined) {
      data.title = this.sbxFormModalTitle;
    }

    if (this.sbxFormModalCancelButtonTitle !== undefined) {
      data.cancelButtonTitle = this.sbxFormModalCancelButtonTitle;
    }

    if (this.sbxFormModalOkButtonTitle !== undefined) {
      data.okButtonTitle = this.sbxFormModalOkButtonTitle;
    }

    if (this.sbxFormModalTopMessageText !== undefined) {
      data.topMessageText = this.sbxFormModalTopMessageText;
    }

    if (this.sbxFormModalTopMessageTemplateContext !== undefined) {
      data.topMessageTemplateContext = this.sbxFormModalTopMessageTemplateContext;
    }

    if (this.sbxFormModalTopInfoMessageText !== undefined) {
      data.topInfoMessageText = this.sbxFormModalTopInfoMessageText;
    }

    if (this.sbxFormModalTopMessageTemplate !== undefined) {
      data.topMessageTemplate = this.sbxFormModalTopMessageTemplate;
    }

    if (this.sbxFormModalBottomMessageText !== undefined) {
      data.bottomMessageText = this.sbxFormModalBottomMessageText;
    }

    if (this.sbxFormModalBottomMessageTemplateContext !== undefined) {
      data.bottomMessageTemplateContext = this.sbxFormModalBottomMessageTemplateContext;
    }

    if (this.sbxFormModalBottomMessageTemplate !== undefined) {
      data.bottomMessageTemplate = this.sbxFormModalBottomMessageTemplate;
    }

    if (this.sbxFormModalConfig !== undefined) {
      data.config = this.sbxFormModalConfig;
    }

    if (this.sbxFormModalReadOnly !== undefined) {
      data.readOnly = this.sbxFormModalReadOnly;
    }

    const sbxFormModalConfig: ISbxFormModalConfig = {
      data,
    };

    this.sbxFormModalService
      .open(sbxFormModalConfig)
      .result.then((result) => {
        this.sbxFormModalOnClose.emit(result);
      })
      .catch((reason) => this.sbxFormModalOnDismiss.emit(reason));
  }
}
