import { NgModule } from '@angular/core';
import { SbxDraggableListComponent } from './sbx-draggable-list.component';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [CommonModule, SbxIconModule, DragDropModule],
  declarations: [SbxDraggableListComponent],
  exports: [SbxDraggableListComponent],
})
export class SbxDraggableListModule {}
