import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

const allowAttribute = (directiveFactory) => [
  '$injector',
  ($injector: angular.auto.IInjectorService) =>
    Object.assign($injector.invoke(directiveFactory), { restrict: 'EA' }),
];

export const Downgrade = {
  // It converts component class name to AngularJS component tag
  Component(
    moduleName: string,
    selector: string,
    selectorAsAttribute?: boolean,
  ): ClassDecorator {
    return function (Component: any) {
      const directiveName = selector.replace(/-(.)/g, (match, group) =>
        group.toUpperCase(),
      );
      const downgradedComponent = downgradeComponent({
        component: Component,
      }) as angular.IDirectiveFactory;
      const decoratedComponent = selectorAsAttribute
        ? allowAttribute(downgradedComponent)
        : downgradedComponent;

      angular.module(moduleName).directive(directiveName, decoratedComponent);
    };
  },
  // Expose injectable to AngularJS
  Injectable(moduleName: string, selector: string): ClassDecorator {
    return function (Injectable: any) {
      const injectableName = selector.replace(/-(.)/g, (match, group) =>
        group.toUpperCase(),
      );
      angular
        .module(moduleName)
        .factory(injectableName, downgradeInjectable(Injectable));
    };
  },
};
