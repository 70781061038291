import angular from 'angular';
/**
 * @ngdoc object
 * @kind function
 * @name sb.lib.form.ErrorCodeStrings
 *
 * @description
 * A quick translation service for error code to human readable strings.
 *
 * @param {string} code Code to translate.
 *
 * @param {string}
 *
 * @returns {string} Returns human readable string.
 */
export function ErrorCodeStrings() {
  const STRINGS_FROM_CODE = {
    email: 'That is not a valid email address.',
    required: 'This is required.',
    pattern: 'That value does not look like the expected value.',
    min: 'That value is too low.',
    max: 'That value is too high.',
    maxlength: 'That is too long.',
    minlength: 'That is too short.',
    date: 'Please enter this date in the form MM/DD/YYYY.',
    timeFormat: 'Please enter this time in the form HH:mm.',
    confirm: 'That value does not match.',
    requiredMonthValidator: 'Expiration month is required',
    requiredYearValidator: 'Expiration year is required',
  };
  return function (code, overrides = {}) {
    return overrides[code] || STRINGS_FROM_CODE[code] || 'That value is incorrect.';
  };
} // end ErrorCodeStrings

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbErrorTooltip
 * @restrict A
 * @requires lib/sb.lib.form.ErrorCodeStrings
 *
 * @description
 * This small directive adds a tooltip to an element that displays error
 * messages. It depends on the $parent scope having a property. The tooltip
 * will show when the element is hovered, but you can also programatically
 * show the tooltip with the expression `sbErrorTooltipTrigger`.
 *
 * @element ANY
 * @param {expression} sbErrorTooltip Either an array of strings to show.
 *    or a object of `{ errorCode: {ANY} }`. Error codes will automatically
 *    be translated.
 * @param {expression} [sbErrorTooltipTrigger=undefined] Bool, when set to
 *    true, shows the toolitp.
 *
 * @example
   <i class="fas fa-exclamation-triangle"
     data-sb-error-tooltip="errors"
     data-sb-error-tooltip-trigger="focused"></i>
 */
export const sbErrorTooltip = [
  'ErrorCodeStrings',
  function (ErrorCodeStrings) {
    return {
      restrict: 'A',
      scope: {
        errors: '&sbErrorTooltip',
        manualTrigger: '&?sbErrorTooltipTrigger',
        overrides: '<?sbErrorTooltipOverrides',
      },
      link: function (scope, element) {
        let manualTrigger, hovered;
        let listing = [];
        const showTool = () => {
          if ((manualTrigger || hovered) && listing.length > 0) {
            element.tooltip('show');
          } else {
            element.tooltip('hide');
          }
        };
        element
          .tooltip({
            placement: 'top',
            trigger: 'manual',
            // We have to disable anumation here, because focusing into the
            // invalid field in sbBackendFeedbackController.focusFirstInvalid
            // closes the tooltop otherwise.
            animation: false,
            html: true,
            title: function () {
              if (listing.length === 1) {
                return listing[0];
              }
              return '<ul><li>' + listing.join('</li><li>') + '</li></ul>';
            },
          })
          .on('mouseenter', () => {
            hovered = true;
            if (manualTrigger) {
              return;
            }
            showTool();
          })
          .on('mouseleave', () => {
            hovered = false;
            if (manualTrigger) {
              return;
            }
            showTool();
          })
          .on('$destroy', () => {
            element.tooltip('destroy');
          });
        scope.$watch(
          'errors()',
          (nv) => {
            listing = [];
            if (angular.isArray(nv)) {
              listing = nv;
            } else if (angular.isObject(nv)) {
              angular.forEach(nv, (value, key) => {
                listing.push(ErrorCodeStrings(key, scope.overrides));
              });
            }
            showTool();
          },
          true,
        );
        scope.$watch('manualTrigger()', (nv) => {
          manualTrigger = Boolean(nv);
          if (hovered) {
            return;
          }
          showTool();
        });
      }, // end link
    };
  },
]; // end sbErrorTooltip
