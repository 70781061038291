import angular from 'angular';
import { List, Record } from 'immutable';

/**
 * @ngdoc object
 * @name sb.lib.metadata:MetadataFormsModel
 *
 * @description
 * Use this to create a model for managing a generic MD form tied to an endpoint.
 *
 * @param {string} endpoint The endpoint of the generic MD forms data.
 */
export const MetadataFormsModel = [
  '$q',
  'SimpleHTTPWrapper',
  function ($q, SimpleHTTPWrapper) {
    const Button = new Record({
      name: undefined,
      text: undefined,
      class: undefined,
    });

    class Model {
      constructor(endpoint) {
        this._endpoint = endpoint;

        /**
         * @ngdoc property
         * @name form
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * The IO form description.
         */
        this.form = {};

        /**
         * @ngdoc property
         * @name data
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * The IO data model object.
         */
        this.data = {};

        /**
         * @ngdoc property
         * @name errors
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * The IO server errors object.
         */
        this.errors = {};

        /**
         * @ngdoc property
         * @name extraActions
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * An immutable list of extra actions to show.
         *   @property {string} name Unique action name of the button.
         *     This should be passed to `.save()`
         *   @property {string} class Button CSS class.
         *   @property {string} text Text content of the button.
         */
        this.extraActions = List();

        /**
         * @ngdoc property
         * @name loading
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * A boolean describing if model is outstanding.
         */
        this.loading = false;

        /**
         * @ngdoc property
         * @name initialized
         * @propertyOf sb.lib.metadata.MetadataFormsModel
         *
         * @description
         * A boolean describing if model has been initialized.
         */
        this.initialized = false;
      }

      _http(isSave, actionName) {
        this.loading = true;
        const config = Object.assign(
          { url: this._endpoint },
          isSave ? { method: 'POST', data: { formData: this.data, actionName } } : {},
        );
        return SimpleHTTPWrapper(config).finally(() => {
          this.loading = false;
        });
      }

      /**
       * @ngdoc method
       * @name init
       * @methodOf sb.lib.metadata.MetadataFormsModel
       *
       * @description
       * Initilze the model and hydrate the model `form` and `data`.
       */
      init() {
        this.initialized = false;
        return this._http()
          .then(
            ({ description, data, actions }) => {
              this.extraActions = List(actions.map((action) => Button(action)));
              this.data = data;
              this.form = description;
            },
            () => {
              this.extraActions = List();
              this.data = {};
              this.form = {};
              return $q.reject();
            },
          )
          .finally(() => {
            this.initialized = true;
          });
      }

      /**
       * @ngdoc method
       * @name serialize
       * @methodOf sb.lib.metadata.MetadataFormsModel
       *
       * @description
       * Take an action on the server.
       *
       * @param {string} [actionName=undefined] Action name to save with.
       */
      serialize(actionName) {
        return this._http(true, actionName).then(
          ({ message, data }) => {
            this.data = data;
            this.errors = {};
            return message;
          },
          (data) => {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty('errors')) {
              this.errors = data.errors;
              return $q.reject(data.message);
            }
            return $q.reject(data);
          },
        );
      }
    }
    return (endpoint) => new Model(endpoint);
  },
]; // end MetadataFormsModel

/**
 * @ngdoc component
 * @name sb.lib.metadata.component:sbMetadataEndpointForm
 *
 * @description
 * Displays metadata fields configured by an endpoint.
 *
 * @param {template} endpoint The endpoint with which to read/write data.
 *   This is passed to `MetadataFormsModel`.
 */
export const sbMetadataEndpointForm = {
  template: require('./templates/set-form.html'),
  transclude: true,
  controllerAs: 'vm',
  bindings: {
    endpoint: '@',
  },
  controller: [
    '$q',
    '$window',
    '$scope',
    'MetadataFormsModel',
    function ($q, $window, $scope, MetadataFormsModel) {
      function handleClick(actionName) {
        const { model } = this;
        let prom;
        if (actionName) {
          // On custom actions we don't mark the form as submited or check validity.
          prom = $q.when({ resolve: angular.noop });
        } else {
          this.mainForm.formly_mdForm.$setSubmitted();
          prom = $scope.mainFormFeedback.submittingForm();
        }
        prom
          .then((done) =>
            model.serialize(actionName).catch((error) => {
              done.resolve();
              return $q.reject(error);
            }),
          )
          .then(
            (message) => {
              this.bannerType = 'success';
              this.bannerMessage = message || 'Saved successfully.';
              $window.scrollTo(0, 0);
            },
            (message) => {
              this.bannerType = 'danger';
              this.bannerMessage = message || 'There was an error saving.';
            },
          );
      }

      this.$onInit = () => {
        this.model = MetadataFormsModel(this.endpoint, this.setConfigurations);
        this.handleClick = handleClick.bind(this);

        this.model.init().catch(() => {
          this.bannerType = 'danger';
          this.bannerMessage = 'There was an error fetching data.';
        });
      };
    },
  ],
}; // end sbMetadataEndpointForm
