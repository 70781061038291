export function sbRecord() {
  return {
    restrict: 'E',
    template: require('./templates/widgets/record.html'),
    require: 'ngModel',
    scope: {
      model: '=ngModel',
      schema: '<sbRecordSchema',
      parentForm: '<',
      context: '<',
    },
    controller: [
      '$scope',
      '$attrs',
      function ($scope, $attrs) {
        $scope.model = $scope.model || {};
        $scope.formName = `${$attrs.name}-innerRecordForm`;
        const done = $scope.$watch(
          'parentForm[formName] && parentForm.$submitted',
          (nv) => {
            if (nv) {
              $scope.parentForm[$scope.formName].$setSubmitted();
              done();
            }
          },
        );
      },
    ],
  };
}
