<sbx-table
  *ngIf="items.length > 0"
  [data]="displayItems(items)"
  [columns]="columns"
></sbx-table>

<sbx-button
  *ngIf="canAdd && !to.readOnly"
  [title]="addItemButtonTitle"
  icon="plus"
  (click)="handleAdd()"
></sbx-button>

<ng-template #actionsTemplate let-item let-row="row">
  <sbx-action-menu [actions]="getActionList(row)"></sbx-action-menu>
</ng-template>

<ng-template #dateDisplay let-item>
  {{ item | date: 'shortDate' }}
</ng-template>

<ng-template #numDisplay let-item>
  <ng-container *ngIf="item; else emptyTpl">
    {{ item | number }}
  </ng-container>
</ng-template>

<ng-template #dollarDisplay let-item>
  <ng-container *ngIf="item; else emptyTpl"> ${{ item | number }} </ng-container>
</ng-template>

<ng-template #enumDropdownDisplay let-item let-column="column">
  <ng-container *ngIf="item; else emptyTpl">
    {{ formatEnumDropdownValue(item, column) }}
  </ng-container>
</ng-template>

<ng-template #documentReferenceDisplay let-item let-column>
  <sbx-icon *ngIf="item" type="dataRoom"></sbx-icon>
</ng-template>

<ng-template #emptyTpl> -- </ng-template>
