import {
  Component,
  Input,
  OnInit,
  Inject,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { of } from 'rxjs';
import {
  ISbxWiPanelItem,
  ISbxWiPanelClickEvent,
} from '../sbx-wi-panel-group.component';
import { SbxHttpClient } from '@/core/http';
import { SbxFormModalService } from '../../sbx-form-modal/sbx-form-modal.service';
import { Downgrade } from '../../downgrade';
import {
  SbxProcessUrlInfo,
  ISbxProcessUrlInfo,
} from '../../upgraded/process-url-info.service';

interface EmailFieldsData {
  recipients: string[];
}

@Downgrade.Component('ngShoobx', 'sbx-workflow-emails')
@Component({
  selector: 'sbx-workflow-emails',
  templateUrl: './sbx-workflow-emails.component.html',
  styleUrls: ['./sbx-workflow-emails.component.scss'],
})
export class SbxWorkflowEmailsComponent implements OnInit {
  @Input() emails: object[];
  // Reminder: when setting emailNames in an angularJs template,
  // set via <sbx-workflow-emails [email=names]="['name']"
  @Input() emailNames: string[] = [];

  emailItems: ISbxWiPanelItem[] = [];

  @ViewChild('headerTemplate')
  headerTemplate: TemplateRef<any>;

  @ViewChild('recipientTemplate')
  recipientTemplate: TemplateRef<any>;

  maxRecipients = 20; // defined by #product
  popoverTitle = '';

  constructor(
    @Inject(SbxFormModalService) private sbxFormModalService: SbxFormModalService,
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    private http: SbxHttpClient,
  ) {}

  ngOnInit() {
    // eslint-disable-next-line no-negated-condition
    if (!this.emails) {
      this.http
        .entity('1')
        .get(this.getUrlContext() + 'emails', {
          params: {
            limitToEmailNames: this.emailNames,
          },
        })
        .subscribe((emails) => {
          this.emails = <object[]>emails || [];
          this.setEmailItems();
        });
    } else {
      this.setEmailItems();
    }
  }

  setEmailItems() {
    const items = [];
    if (this.emails) {
      if (typeof this.emails === 'string') {
        this.emails = JSON.parse(this.emails);
      }
      this.emails.forEach((email) => {
        items.push({
          // eslint-disable-next-line dot-notation
          title: email['title'],
          buttons: [
            {
              id: 'edit',
              icon: 'edit',
              title: 'Edit',
            },
          ],
          data: email,
        });
      });

      this.emailItems = items;
    }
  }

  emailFields(data: EmailFieldsData) {
    let ccPrefix =
      'Copying yourself on this email will CC you on all emails' +
      ' sent as part of this workflow.';
    if (data.recipients?.length) {
      ccPrefix +=
        `There are ${data.recipients?.length}` +
        ' recipients that will receive emails.';
    }
    return [
      {
        type: 'display-template',
        templateOptions: {
          label: 'Email Will Be Sent To',
          template: this.recipientTemplate,
        },
        key: 'recipientTemplate',
        defaultValue: {},
      },
      {
        key: 'message',
        fieldGroup: [
          {
            type: 'string-textline',
            templateOptions: {
              subfield: 0,
              required: true,
              label: 'Email Subject',
            },
            data: {},
            key: 'subject',
            defaultValue: '',
          },
          {
            type: 'text',
            templateOptions: {
              subfield: 0,
              required: true,
              label: 'Email Body',
              className: 'sbx-individual-group',
            },
            data: {},
            key: 'body',
            defaultValue: '',
          },
          {
            type: 'bool-radios',
            templateOptions: {
              subfield: 0,
              required: true,
              label: 'Would you like to be copied on the email?',
              className: 'sbx-individual-group',
              preText: ccPrefix,
            },
            data: {},
            key: 'cc',
            defaultValue: false,
          },
        ],
      },
    ];
  }

  getUrlContext() {
    return `workitems/${this.processUrlInfo.wiId()}/`;
  }

  handleButtonClick(event: ISbxWiPanelClickEvent) {
    const data = event.data as EmailFieldsData;
    this.popoverTitle = `An email will be sent to ${data.recipients?.length} recipients including:`;
    this.sbxFormModalService.open({
      size: 'lg',
      data: {
        title: 'Compose Email',
        model: data,
        topMessageTemplate: this.headerTemplate,
        formFields: of(this.emailFields(event.data as EmailFieldsData)),
        saveUrl: this.getUrlContext() + 'emails/update-email-message',
        urlVersion: 1,
      },
    });
  }
}
