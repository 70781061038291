<div class="sbx-stakeholder-card sbx-stakeholder-card-{{ format }}">
  <sbx-popover
    [sbxPopoverDisabled]="format !== 'micro'"
    [sbxPopoverContent]="popoverContent"
  >
    <div
      class="sbx-avatar"
      [ngClass]="{
        'sbx-small': format !== 'directory',
        'sbx-large': format === 'directory'
      }"
    >
      <img
        *ngIf="stakeholderObject?.pictureUrl && !stakeholderObject?.archived"
        [ngClass]="{
          'sbx-img-circle':
            !stakeholderObject?.teamStakeholder || stakeholderObject?.representative
        }"
        [src]="stakeholderObject?.pictureUrl"
      />

      <div
        class="sbx-avatar-initials sbx-img-circle"
        *ngIf="
          stakeholderObject &&
          !stakeholderObject?.pictureUrl &&
          !stakeholderObject?.teamStakeholder
        "
      >
        {{ stakeholderObject.fullName | initials }}
      </div>

      <sbx-icon
        *ngIf="!stakeholderObject"
        class="sbx-avatar-initials sbx-img-circle"
        type="user"
      ></sbx-icon>

      <sbx-icon
        *ngIf="!stakeholderObject?.pictureUrl && stakeholderObject?.teamStakeholder"
        class="sbx-avatar-initials sbx-img-circle"
        type="building"
      ></sbx-icon>
    </div>
  </sbx-popover>
  <ng-template #popoverContent>
    <h5 class="sbx-popover-title" *ngIf="popoverTitle">{{ popoverTitle }}</h5>
    <sbx-stakeholder-card
      id="popoverCard"
      *ngIf="format === 'micro'"
      [stakeholderObject]="stakeholderObject"
    >
    </sbx-stakeholder-card>
  </ng-template>

  <div class="sbx-break-text" *ngIf="showLoader && !initialized">
    <sbx-icon type="spinner"></sbx-icon>
  </div>

  <div class="sbx-break-text" *ngIf="format === 'directory'">
    <div *ngIf="stakeholderObject">
      <a [href]="stakeholderObject?.url">
        <ng-container *ngTemplateOutlet="highlightName"></ng-container>
      </a>
    </div>
    <div
      class="sbx-position"
      *ngIf="stakeholderObject?.position && !stakeholderObject?.archived"
    >
      {{ stakeholderObject?.position }}
    </div>
    <div
      class="sbx-roles"
      *ngIf="stakeholderObject?.roleTitles && !stakeholderObject?.archived"
    >
      <span *ngFor="let title of stakeholderObject?.roleTitles">
        {{ title }}
      </span>
    </div>
  </div>

  <div class="sbx-break-text" *ngIf="format !== 'micro' && format !== 'directory'">
    <span *ngIf="stakeholderObject">
      <ng-container *ngTemplateOutlet="highlightName"></ng-container>
    </span>

    <span
      class="sbx-stakeholder-position"
      *ngIf="
        format !== 'short' &&
        format !== 'directory' &&
        (stakeholderObject?.url || stakeholderObject?.email)
      "
    >
      <span class="sbx-off-color" *ngIf="stakeholderObject?.position">
        &nbsp;({{ stakeholderObject?.position }})
      </span>
      <span>&nbsp;|</span>
      <span class="sbx-off-color">
        {{ stakeholderObject?.email }}
      </span>
    </span>

    <div *ngIf="format !== 'micro' && stakeholderObject?.parentTitle">
      <em>Fund:</em> {{ stakeholderObject?.fullName }}
    </div>

    <div
      *ngIf="
        format !== 'micro' &&
        !stakeholderObject?.parentTitle &&
        affiliates &&
        stakeholderObject.teamStakeholder
      "
    >
      <em>Fund:</em> No fund specified.
    </div>

    <div
      *ngIf="
        format === 'full' &&
        stakeholderObject?.teamStakeholder &&
        stakeholderObject?.contactEmail
      "
    >
      <em>Contact:</em> {{ stakeholderObject?.contactFirstName }}
      {{ stakeholderObject?.contactLastName }} | {{ stakeholderObject?.contactEmail }}
    </div>
  </div>

  <ng-template #highlightName>
    <span class="sbx-full-name" *ngIf="stakeholderObject?.representative">
      {{ stakeholderObject?.parentTitle || stakeholderObject?.fullName }}
      <sbx-icon type="arrowRight"></sbx-icon
      >{{ stakeholderObject?.representative.fullName }}
    </span>
    <span
      class="sbx-full-name"
      *ngIf="
        !stakeholderObject?.representative &&
        (stakeholderObject?.parentTitle || stakeholderObject?.fullName) &&
        !highlightTerm
      "
      >{{ stakeholderObject.parentTitle || stakeholderObject.fullName }}</span
    >
    <span
      class="sbx-full-name"
      *ngIf="
        !stakeholderObject?.representative &&
        (stakeholderObject?.parentTitle || stakeholderObject?.fullName) &&
        highlightTerm
      "
      [innerHTML]="
        stakeholderObject.parentTitle || stakeholderObject.fullName
          | hightlightTerm : highlightTerm
      "
    >
    </span>
  </ng-template>

  <div *ngIf="showApproval">
    <span
      class="sbx-approvals-pending"
      *ngIf="stakeholderObject?.approvalStatus === 'pending'"
    >
      &mdash; Pending approvals
    </span>
    <span
      class="sbx-approvals-rejected"
      *ngIf="stakeholderObject?.approvalStatus === 'rejected'"
    >
      &mdash; Rejected
    </span>
  </div>
</div>
