import moment from 'moment';

function addSubWatchers(scope, subCtrl, parentCtrl) {
  scope.$watch(
    () => subCtrl.$touched,
    (nv) => {
      if (nv) {
        parentCtrl.$setTouched();
      } else {
        parentCtrl.$setUntouched();
      }
    },
  );
  scope.$watch(
    () => subCtrl.$dirty,
    (nv) => {
      if (nv) {
        parentCtrl.$setDirty();
      } else {
        parentCtrl.$setPristine();
      }
    },
  );
  scope.$watch(
    () => subCtrl.$error.date,
    (nv) => {
      parentCtrl.$setValidity('date', !nv);
    },
  );
  scope.$watch(
    () => subCtrl.$error.parse,
    (nv) => {
      parentCtrl.$setValidity('parse', !nv);
    },
  );
  if (scope.required) {
    scope.$watch(
      () => subCtrl.$error.required,
      (nv) => {
        parentCtrl.$setValidity('required', !nv);
      },
    );
  }
}

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbTimePicker
 * @restrict E
 *
 * @description
 * This is a time picker wrapper. Models are presented as strings (naive) in
 * the form `HH:mm A`,
 *
 * @param {expression} [ngModel=undefined] Model expression.
 * @param {template} [initModel=undefined] A string to init the model as. This
 *    should only be needed for backwards zope forms compatiblity.
 *
 * @example
   <sb-time-picker ng-model="model.time"></sb-time-picker>
 */
export function sbTimePicker() {
  const FORMAT = 'h:mm A';
  return {
    restrict: 'E',
    template: require('./templates/widgets/time-picker.html'),
    scope: {
      initModel: '@?',
      ngModel: '=?',
    },
    controller: [
      '$scope',
      function ($scope) {
        function dateFromString(str) {
          return moment(str, FORMAT).toDate();
        }
        function stringFromDate(date) {
          return moment(date).format(FORMAT);
        }
        if ($scope.initModel) {
          $scope.dateModel = dateFromString($scope.initModel);
        }
        $scope.$watch('dateModel', (nv) => {
          if (nv) {
            $scope.ngModel = stringFromDate(nv);
          }
        });
        $scope.$watch('ngModel', (nv) => {
          const currentString = stringFromDate($scope.dateModel);
          if (nv && nv !== currentString) {
            $scope.dateModel = dateFromString(nv);
          }
        });
      },
    ],
  };
} // end sbTimePicker

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbDatePicker
 * @restrict E
 *
 * @description
 * This is the date widget. It publishes naive date strings as the model.
 *
 * @param {expression} [ngModel=undefined] Model expression.
 * @param {boolean} [required=false] If field is required.
 * @param {template} [initModel=undefined] A string to init the model as. This
 *    should only be needed for backwards zope forms compatiblity.
 * @param {template} [inputName=undefined] A string to use as the inputs name
 *    should only be needed for backwards zope forms compatiblity.
 *
 * @example
 */
export function sbDatePicker() {
  const EXTERN_FORMAT = 'YYYY-MM-DD';
  const MOMENT_FORMATS = [
    EXTERN_FORMAT,
    'MM/D/YY',
    'M/DD/YY',
    'MM/DD/YY',
    'M/D/YY',
    'M/D/YYYY',
  ];
  const UIB_DATEPICKER_OPTIONS = {
    showWeeks: false,
    formatDay: 'd',
    formatMonth: 'MMMM',
  };
  const UIB_FORMATS = {
    inputFormat: 'M/d/yyyy',
    altFormats: [
      'yyyy-MM-dd',
      'M/d/yy',
      'MM/d/yy',
      'M/dd/yy',
      'MM/dd/yy',
      'MM/dd/yyyy',
      'M/d/yyyy',
    ],
  };

  const StringFromDate = (date) => {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format(EXTERN_FORMAT) : null;
  };

  const DateFromString = (str) => {
    const newDate = moment(str, MOMENT_FORMATS);
    return newDate.isValid() ? newDate.toDate() : undefined;
  };

  return {
    restrict: 'E',
    template: require('./templates/widgets/date-picker.html'),
    require: ['sbDatePicker', '?ngModel'],
    scope: {
      required: '<?',
      ngModel: '=?',
      inputName: '@',
      initModel: '@?initModel',
      closed: '&?',
      open: '&?',
    },
    controller: [
      '$scope',
      function ($scope) {
        this.$registerSubController = (ctrl) => {
          this.$$subCtrl = ctrl;
        };
        $scope.datepickerOptions = UIB_DATEPICKER_OPTIONS;
        $scope.formats = UIB_FORMATS;
        $scope.state = {};
      },
    ],
    link(scope, element, attrs, ctrls) {
      const [sbDatePickerCtrl, ngModelCtrl] = ctrls;
      const subCtrl = sbDatePickerCtrl.$$subCtrl;

      if (ngModelCtrl) {
        scope.ngModelCtrl = ngModelCtrl;
        ngModelCtrl.$parsers.push(StringFromDate);
        ngModelCtrl.$formatters.push(DateFromString);
        addSubWatchers(scope, subCtrl, ngModelCtrl);
      } else {
        scope.ngModelCtrl = {};
      }

      scope.changeDate = (dateString) => {
        const date = DateFromString(dateString);

        scope.ngModelCtrl.$viewValue = date;
        if (ngModelCtrl) {
          ngModelCtrl.$setViewValue(date);
        }
      };
      scope.getDate = () => {
        const value = scope.ngModelCtrl.$viewValue;

        if (!value) {
          return null;
        }

        return StringFromDate(value);
      };
      scope.toggleDatePicker = () => {
        const sbxDateBaseComponent = element.find('sbx-date-base')[0];

        setTimeout(() => sbxDateBaseComponent.click());
      };
      scope.datePickerClosed = () => {
        if (typeof scope.closed === 'function') {
          scope.closed();
        }
      };
      scope.datePickerOpen = () => {
        if (typeof scope.open === 'function') {
          scope.open();
        }
      };

      if (scope.initModel) {
        const attempt = moment(scope.initModel, MOMENT_FORMATS);
        if (attempt.isValid()) {
          scope.ngModelCtrl.$viewValue = attempt.toDate();
        }
      }
    },
  };
} // end sbDatePicker

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbDatePickerInput
 * @restrict A
 *
 * @description
 * This is a private directive for use with datepicker.
 */
export function sbDatePickerInput() {
  return {
    restrict: 'A',
    require: ['ngModel', '^^sbDatePicker'],
    link(scope, element, attrs, ctrls) {
      const [ngModelCtrl, sbDatePickerCtrl] = ctrls;
      sbDatePickerCtrl.$registerSubController(ngModelCtrl);
    },
  };
} // end sbDatePickerInput

class expirationDateCtrl {
  $onInit() {
    this.disabled = this.disabled || false; // Make sure watcher ends
  }

  clearExpiration() {
    this.ngModel = null;
    this.open = false;
  }
}
/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbExpDateDropDown
 *
 * @description
 * Provides a drop down widget where a user can choose an expiration date or no
 * expiration at all.
 *
 * @param {expression} ngModel Model expression.
 *
 * @example
 * <sb-exp-date-drop-down ng-model="test"></sb-exp-date-drop-down>
 */
export const sbExpDateDropDown = {
  restrict: 'E',
  controllerAs: 'vm',
  template: require('./templates/widgets/expiration-date.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    ngModel: '=',
    disabled: '<?',
  },
  controller: expirationDateCtrl,
};
