import {
  Component,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  OnInit,
  Inject,
} from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { SbxHttpClient } from '@/core/http';
import { SbxUrlService } from '@/core/url/url.service';
import { AppConfig } from '@/core/config/app.config';

/**
 *  Shoobx Signature Upload Component
 *
 *  Example:
Component Template:
<div class="sbx-signature" *ngIf="imageSrc">
  <img [src]="imageSrc" />
</div>

<div class="sbx-signature-button">
  <sbx-button icon="sign" title="Update Signature" (click)="updateSignature()"></sbx-button>
</div>

Component:
import { SbxSignatureModalService } from '@/shared/sbx-signature-modal/sbx-signature-modal.service';

  constructor(
    @Inject(SbxSignatureModalService)
    public sbxSignatureModalService: SbxSignatureModalService,
  ) {}
  async updateSignature() {
    let signatureModal;
    try {
      const data = {
        modalHeader: 'Update Your Sinature',
      }
      signatureModal = await this.sbxSignatureModalService.open(data).result;
      this.imageSrc = signatureModal?.result?.image;
    } catch (e) {
      if (e?.userCancelled) {
        return;
      }
      this.error = e.message;
    }
  }
 */

export interface ISignatureModalData {
  modalHeader?: string;
}

@Component({
  selector: 'sbx-signature-modal',
  templateUrl: './sbx-signature-modal.component.html',
  styleUrls: ['sbx-signature-modal.component.scss'],
})
export class SbxSignatureModalComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) containerRef;
  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<any>;
  @ViewChild('signatureTemplate', { static: false }) signatureTemplate;

  dataDefault: ISignatureModalData = {
    modalHeader: 'Update Signature',
  };

  data: ISignatureModalData;
  fontImages = [];
  errorMsg: string;
  imageBase64;
  valid = false;
  model = 'draw';
  modelSignatureFont: string;

  constructor(
    public sbxModalService: SbxModalService,
    private http: SbxHttpClient,
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(AppConfig) public appConfig: AppConfig,
  ) {}

  async ngOnInit() {
    this.data = { ...this.dataDefault, ...this.data };
    this.fontImages = await this.getFontImages();
  }

  async getFontImages() {
    const res = await this.http.entity('2').get<any>(`getSignatureFonts`).toPromise();
    const name = this.appConfig.userProfile.fullName;
    const fontImages = [];
    await res.fonts.forEach((font) => {
      this.http
        .entity('2')
        .request('GET', `getSignaturePicture?font=${font}&text=${name}`, {
          responseType: 'text',
        })
        .subscribe((res) => {
          const image = 'data:image/png;base64, ' + res;
          fontImages.push({
            value: font,
            image: image,
          });
        });
    });
    return fontImages;
  }

  fileChangeEvent(event: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.imageBase64 = reader.result;
    };
    this.valid = true;
  }

  onCapture(event) {
    this.imageBase64 = event;
    this.valid = true;
  }

  onClear() {
    this.imageBase64 = '';
    this.valid = false;
  }

  modalCancel() {
    this.sbxModalService.close();
  }

  modalConfirm() {
    this.sbxModalService.close({
      image: this.imageBase64,
    });
  }

  hangleSignatureTypeChange($event) {
    if (typeof $event === 'string') {
      this.model = $event;
      if (this.model === 'type') {
        const selectedFont = this.fontImages[0];
        this.modelSignatureFont = selectedFont?.value;
        this.imageBase64 = selectedFont?.image;
        this.valid = true;
      } else {
        this.imageBase64 = '';
        this.valid = false;
      }
    }
  }

  hangleSignatureFontImageChange($event) {
    if (typeof $event === 'string') {
      this.modelSignatureFont = $event;
      this.imageBase64 = this.fontImages.find(
        (fontImage) => fontImage.value === $event,
      ).image;
      this.valid = true;
    }
  }
}
