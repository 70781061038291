import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module';
import { SbxErrorPageComponent } from './sbx-error-page.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule, SharedModule, CommonModule, ReactiveFormsModule],
  declarations: [SbxErrorPageComponent],
})
export class SbxErrorPageModule {}
