<div (click)="to.focus ? to.focus(field, $event) : null">
  <sbx-tree-picker
    [model]="field.model"
    [modelKey]="$any(field.key)"
    [settingsType]="to.settingsType"
    [useWhitelist]="to.useWhitelist"
    [whitelist]="to.whitelist"
    (treeSelect)="handleChange($event)"
  ></sbx-tree-picker>
</div>
