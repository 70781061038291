import {
  Component,
  ViewChild,
  OnInit,
  SimpleChanges,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  INTERNAL_FORMAT,
  SbxDateBaseAdapter,
} from './sbx-date-base.component.formatters';
import moment from 'moment';
import { Downgrade } from '@/shared/downgrade';
import { NGB_CONTAINER_TOKEN } from './ngb-container.token';
import { NgbContainerConfig } from './ngb-container.config';

const SELECTOR = 'sbx-date-base';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-date-base.component.html',
  styleUrls: ['./sbx-date-base.component.scss'],
  providers: [
    {
      provide: NgbInputDatepicker,
      useFactory: (ngbContainer: NgbContainerConfig) => {
        const datepicker = new NgbInputDatepicker();

        if (!ngbContainer) {
          return datepicker;
        }

        datepicker.container = ngbContainer.container;
        datepicker.popperOptions = ngbContainer.popperOptions;

        return datepicker;
      },
      deps: [NGB_CONTAINER_TOKEN],
    },
  ],
})
export class SbxDateBaseComponent implements OnInit, OnChanges {
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;

  @Input() public id: string = undefined;
  @Input() public model = '';
  @Input() public min = '';
  @Input() public showError = false;
  @Input() public readOnly = false;
  @Output() public readonly modelChange = new EventEmitter<string>();
  @Output() public readonly focus = new EventEmitter<FocusEvent>();
  @Output() public readonly blur = new EventEmitter<FocusEvent>();
  @Output() public readonly closed = new EventEmitter<void>();
  @Output() public readonly datePickerValueChange = new EventEmitter<void>();

  formattedValue = '';
  minDate: NgbDateStruct = null;

  public readonly container: undefined | 'body';

  public constructor(private readonly inputDatepicker: NgbInputDatepicker) {
    this.container = inputDatepicker.container;
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  public ngOnInit() {
    this.formattedValue = this.model || '';

    if (this.min) {
      const adapter = new SbxDateBaseAdapter();
      this.minDate = adapter.fromModel(this.min);
    } else {
      this.minDate = { year: 1919, month: 1, day: 1 };
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.model?.firstChange ||
      changes.model?.previousValue === changes.model?.currentValue
    ) {
      return;
    }

    const formattedDate = moment(this.formattedValue, INTERNAL_FORMAT, true);
    // Prevent updating formattedValue in midflight
    if (this.formattedValue && !formattedDate.isValid()) {
      return;
    }

    this.formattedValue = changes.model.currentValue;
  }

  @HostListener('click', ['$event'])
  public onClick(event): void {
    if (this.readOnly) {
      return;
    }

    // ngb datepicker invoked manually
    if (event.target.tagName === SELECTOR.toUpperCase()) {
      this.toggleCalendar();
    }
  }

  public toggleCalendar(): void {
    this.datePicker.toggle();
  }

  public handleChange(value: string): void {
    this.formattedValue = value || '';

    const date = moment(value, INTERNAL_FORMAT, true);
    this.modelChange.emit(date.isValid() ? date.format(INTERNAL_FORMAT) : null);
  }

  public handleFocus(event): void {
    this.datePicker.open();
    this.focus.emit(event);
  }

  public handleBlur(event): void {
    if (this.datePicker.isOpen()) {
      return;
    }

    // Cleanup formattedValue if date is invalid on blur
    this.formattedValue = this.model || '';

    this.blur.emit(event);
  }
}
