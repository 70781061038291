import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@/shared/shared.module';
import { SbxPieSettingsComponent } from './sbx-pie-settings.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SbxPieSettingsComponent],
})
export class SbxPieSettingsModule {
  static entryComponents = [SbxPieSettingsComponent];

  static forRoot(): ModuleWithProviders<SbxPieSettingsModule> {
    return {
      ngModule: SbxPieSettingsModule,
    };
  }
}
