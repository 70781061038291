import { Component, Input, ViewChild } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import { SbxHttpClient } from '@/core/http/client.service';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { ActivityLogResponse } from '@shoobx/types/webapi-v2';
import { ISbxTableColumns } from '@/shared/sbx-table/sbx-table.component';

const SELECTOR = 'sbx-activity-log-modal';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-activity-log-modal.component.html',
  styleUrls: ['./sbx-activity-log-modal.component.scss'],
})
export class SbxActivityLogModalComponent {
  @Input() stakeholderId: string;
  @Input() displayName: string;
  @Input() modalOpen = false;

  @ViewChild('modalContent') modalContent;
  loading = false;
  tableColumns: ISbxTableColumns;
  tableData: ActivityLogResponse['data'] = [];
  pageSize = 15;
  currentPage = 0;
  totalItems: number;
  hasError = false;

  constructor(
    private sbxModalService: SbxModalService,
    private http: SbxHttpClient,
  ) {}

  ngOnInit() {
    this.tableColumns = [
      {
        key: 'timestamp',
        title: 'TimeStamp',
        width: '150px',
      },
      {
        key: 'text',
        title: 'Activity',
      },
    ];

    if (this.modalOpen) {
      this.getModalData();
    }
  }

  getPage(pageNum) {
    this.currentPage = pageNum;
    this.getModalData();
  }

  getModalData() {
    this.loading = true;
    this.http
      .entity('2')
      .get(`stakeholder/${this.stakeholderId}/activityLog`, {
        params: {
          page: this.currentPage,
          size: this.pageSize,
        },
      })
      .subscribe(
        (res: ActivityLogResponse) => {
          this.tableData = res.data;
          this.totalItems = res.totalItems;
          this.loading = false;
        },
        (errors) => {
          this.hasError = true;
          this.loading = false;
        },
      );
  }

  async openModal() {
    this.getModalData();
    const modal = this.sbxModalService.open(this.modalContent, {
      size: 'lg',
    });

    try {
      await modal.result;
    } finally {
      this.currentPage = 0;
      this.tableData = [];
      this.hasError = false;
    }
  }
}
