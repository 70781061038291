import angular from 'angular';
import ngTouch from 'angular-touch';
import { sbSlideMenu } from './menu';

/**
 * @ngdoc overview
 * @name sb.lib.mobile
 *
 * @description
 * This module houses code aimed at mobile web users.
 */
export default angular
  .module('sb.lib.mobile', [ngTouch])
  .component('sbSlideMenu', sbSlideMenu).name;
