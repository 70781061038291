import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SbxPageHeaderComponent } from './sbx-page-header.component';
import { SbxPageHeaderTemplateService } from './sbx-page-header.service';
import { SbxTitleModule } from '../sbx-title/sbx-title.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxActionModule } from '@/shared/sbx-action-menu/sbx-action-menu.module';

@NgModule({
  imports: [
    CommonModule,
    SbxTitleModule,
    SbxIconModule,
    SbxGridModule,
    SbxActionModule,
    RouterModule,
  ],
  declarations: [SbxPageHeaderComponent],
  exports: [SbxPageHeaderComponent],
  providers: [SbxPageHeaderTemplateService],
})
export class SbxPageHeaderModule {
  static entryComponents = [SbxPageHeaderComponent];

  static forRoot(): ModuleWithProviders<SbxPageHeaderModule> {
    return {
      ngModule: SbxPageHeaderModule,
    };
  }
}
