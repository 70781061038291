import angular from 'angular';
import Modal from '../../lib/modal/modal';
import Promise from 'lib/promise';
import Rx from 'lib/rx';
import Tools from 'lib/tools';
import {
  sbConstraintToken,
  sbCombinedIsoVestConstraint,
  sbCombinedRule701Constraint,
  sbConstraint,
  sbConstraintSet,
  sbConstraintsOverview,
} from './constraints';
import { ConstraintService } from './service';

export default angular
  .module('sb.workitem.grantConstraintsWorkitem', [Modal, Promise, Tools, Rx])

  .component('sbConstraintToken', sbConstraintToken)
  .component('sbCombinedIsoVestConstraint', sbCombinedIsoVestConstraint)
  .component('sbCombinedRule701Constraint', sbCombinedRule701Constraint)
  .component('sbConstraint', sbConstraint)
  .component('sbConstraintSet', sbConstraintSet)
  .component('sbConstraintsOverview', sbConstraintsOverview)
  .factory('ConstraintService', ConstraintService).name;
