import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxDisabledModule } from '../sbx-disabled/sbx-disabled.module';
import { SbxCaptableDownloadComponent } from '@/shared/sbx-captable-download/sbx-captable-download.component';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxAlertBannerModule } from '@/shared/sbx-alert-banner/sbx-alert-banner.module';
import { SbxMessageModule } from '@/shared/sbx-message/sbx-message.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxDisabledModule,
    SbxButtonModule,
    SbxAlertBannerModule,
  ],
  declarations: [SbxCaptableDownloadComponent],
  exports: [SbxCaptableDownloadComponent],
  providers: [SbxMessageModule],
})
export class SbxCaptableDownloadModule {
  static entryComponents = [SbxCaptableDownloadComponent];
}
