import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Downgrade } from '@/shared/downgrade';
import {
  ISbxProcessUrlInfo,
  SbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import {
  ISbxProcessButtonModel,
  SbxProcessButtonModel,
} from '@/shared/upgraded/process-button-model.service';
import { SbxNextRoundPlannerComponent } from '../sbx-next-round-planner/sbx-next-round-planner.component';
import { SbxConfirmationModalService } from '@/shared/sbx-confirmation-modal/sbx-confirmation-modal.service';

const SELECTOR = 'sbx-process-next-round-planner';
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-process-next-round-planner.component.html',
  styleUrls: ['sbx-process-next-round-planner.component.scss'],
})
@Downgrade.Component('ngShoobx', SELECTOR)
export class SbxProcessNextRoundPlannerComponent implements OnInit, AfterViewInit {
  @ViewChild('nextRoundPlannerRef') nextRoundPlannerRef: SbxNextRoundPlannerComponent;

  baseUrl: string = null;
  submitConditionId: number = null;
  resetConditionId: number = null;

  constructor(
    @Inject(SbxProcessUrlInfo) private processUrlInfo: ISbxProcessUrlInfo,
    @Inject(SbxProcessButtonModel) private processButtonModel: ISbxProcessButtonModel,
    @Inject(SbxConfirmationModalService)
    public sbxConfirmationModalService: SbxConfirmationModalService,
  ) {
    this.baseUrl = `workitems/${processUrlInfo.wiId()}/nextround`;
  }

  ngOnInit() {
    this.submitConditionId = this.processButtonModel.$addSubmitCondition(
      /continue$/,
      () => {
        this.nextRoundPlannerRef.pageRef.clearErrorFlag('processButton');
        const { state } = this.nextRoundPlannerRef;
        const preMoneyClasses = state.preMoneyOwnership.filter(
          ({ classId }) => classId,
        );
        const postMoneyClasses = state.postMoneyOwnership.filter(
          ({ classId }) => classId,
        );

        if (preMoneyClasses.length >= postMoneyClasses.length) {
          this.nextRoundPlannerRef.pageRef.setErrorFlag(
            'processButton',
            'You need to add a new stock class before you can continue the process.',
          );
          return Promise.reject();
        }

        return Promise.resolve();
      },
    );

    this.resetConditionId = this.processButtonModel.$addSubmitCondition(
      'reset',
      async () => {
        await this.sbxConfirmationModalService.open({
          data: {
            title: 'Reset',
            body: 'This will erase all hypothetical data from the planner.',
            okButtonTitle: 'Reset',
          },
        }).result;
        await this.nextRoundPlannerRef.handleReset();
      },
    );
  }

  ngAfterViewInit() {
    this.nextRoundPlannerRef.stateChanges$.subscribe((state) => {
      if (state.postMoneyOwnership.length > state.preMoneyOwnership.length) {
        this.nextRoundPlannerRef.pageRef.clearErrorFlag('processButton');
      }
    });
  }

  ngOnDestroy() {
    this.processButtonModel.$removeSubmitCondition(this.submitConditionId);
    this.submitConditionId = null;

    this.processButtonModel.$removeSubmitCondition(this.resetConditionId);
    this.resetConditionId = null;
  }
}
