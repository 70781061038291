import {
  Component,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

const SELECTOR = 'sbx-alert-banner';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-alert-banner.component.html',
  styleUrls: ['./sbx-alert-banner.component.scss'],
})
export class SbxAlertBannerComponent implements OnChanges {
  @ViewChild('alertBanner', { static: true }) alertBanner;

  @Input() error = false;
  @Input() warning = false;
  @Input() customText = '';
  @Input() fadeTime = 1000;
  @Input() disableClose = false;
  @Input() details = '';

  @Output() close = new EventEmitter();

  hidden = true;
  fadeTimeout;
  hideTimeout;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customText && changes.customText.currentValue) {
      this.show({
        error: this.error,
        warning: this.warning,
        customText: this.customText,
      });
    }
  }

  public show({
    error = false,
    warning = false,
    customText = '',
    details = '',
    fadeTimeOverride = null,
  }) {
    this.error = error;
    this.warning = warning;
    this.hidden = false;
    this.customText = customText;
    this.details = details;
    clearTimeout(this.fadeTimeout);
    clearTimeout(this.hideTimeout);

    const fadeTime = fadeTimeOverride || this.fadeTime;

    this.alertBanner.nativeElement.style.opacity = '1';
    if (!this.error && !this.warning && fadeTime) {
      this.fadeTimeout = setTimeout(() => {
        this.alertBanner.nativeElement.style.opacity = '0';
        this.hideTimeout = setTimeout(() => {
          this.hide();
        }, fadeTime);
      }, fadeTime);
    }
  }

  public hide() {
    this.hidden = true;

    this.close.emit();
  }
}
