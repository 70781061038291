import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ISearchGroup } from '../sbx-search-bar/sbx-search-bar.component';
import { StakeholderSearchService } from './stakeholder-search.service';

@Component({
  selector: 'sbx-stakeholder-search',
  templateUrl: './sbx-stakeholder-search.component.html',
  styleUrls: ['./sbx-stakeholder-search.component.scss'],
})
export class SbxStakeholderSearchComponent implements OnInit {
  @Input() placeholderText = '';
  @Input() parseResultsCallback: (
    text: string,
    results: [],
    searchGroups: ISearchGroup[],
  ) => void = null;
  @Input() showSearchIcon = false;
  @Input() options: {
    affiliateLabel?: string;
    affiliatePlaceholder?: string;
    affiliateCheckboxLabel?: string;
    allowEntity?: boolean;
    allowRelationship?: boolean;
    allowExisting?: boolean;
    restrictToCurrentUser?: boolean;
    allowPerson?: boolean;
    allowStakes?: boolean;
    format?: string;
    alwaysDisplayAffiliate?: boolean;
    creationModalTitleText?: string;
    creationModalIndividualLabel?: string;
    createModalTeamLabel?: string;
    createModalEntityNameLabel?: string;
    creationModalEntityNamePlaceholder?: string;
    creatorIsContact?: boolean;
    entityOptions?: Record<string, unknown>;
    excludeStates?: string[];
    forceEphemeral?: boolean;
    includeStates?: string[];
    includeGroups?: string[];
    onlyActive?: boolean;
    placeholderText?: string;
    pickRepresentative?: boolean;
    pickRepresentativeOnly?: boolean;
    withUsersOnly?: boolean;
  };
  @Input() securityTicketId: string = null;
  @Input() noClearButton = false;
  @Input() excludeFn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() focus = new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() blur = new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() selectStakeholder = new EventEmitter<any>();

  searchResults: Observable<ISearchGroup[]>;

  constructor(private service: StakeholderSearchService) {
    this.search = this.search.bind(this);
  }

  handleFocus(event) {
    this.focus.emit(event);
  }

  handleBlur(event) {
    this.blur.emit(event);
  }

  ngOnInit() {
    if (!this.options) {
      this.options = {};
    }

    if (this.options.placeholderText) {
      this.placeholderText = this.options.placeholderText;
    }
  }

  search(text: string) {
    const {
      entityOptions,
      allowPerson,
      allowStakes,
      restrictToCurrentUser,
      includeGroups,
      allowEntity,
      allowRelationship,
      includeStates,
      excludeStates,
      onlyActive,
      withUsersOnly,
      pickRepresentative,
      pickRepresentativeOnly,
    } = this.options;

    let allowAffiliates, type, excludeSelf;
    if (entityOptions) {
      allowAffiliates = Boolean(entityOptions.affiliates);
      type = entityOptions.type;
      excludeSelf = entityOptions.allowSelfTeam === false;
    }
    const searchParams = {
      allowEntity,
      allowPerson,
      allowRelationship,
      allowAffiliates,
      allowStakes,
      restrictToCurrentUser,
      type,
      includeStates,
      excludeStates,
      onlyActive,
      withUsersOnly,
      pickRepresentative,
      pickRepresentativeOnly,
      includeGroups,
      excludeSelf,
      securityTicketID: this.securityTicketId,
    };

    return this.service.search(text, searchParams).pipe(
      map((results) => {
        const {
          individuals,
          teams: teamsResp,
          relationships: relationshipsResp,
          stakes,
        } = results;
        // Service is going to return individual stakeholders and team stakeholders
        // or individual stakeholders and relationships, so we assign one of them
        // to teams variable
        const teams = relationshipsResp?.total ? relationshipsResp : teamsResp;
        const searchGroups = [];

        // backend will only return individuals if user has access
        if (individuals !== undefined) {
          const userResults: ISearchGroup = {
            header: teams ? 'Users' : '',
            headerIcon: 'user',
            noResultsText: 'No users found',
            results: individuals.results.filter(this.excludeFn || (() => true)),
            footer: null,
            footerLink: null,
            error: individuals.error,
          };

          individuals.searchGroup = userResults;
          searchGroups.push(userResults);
        }

        if (teams !== undefined) {
          // backend will only return teams if user has access
          let teamResults = [];
          if (teams !== undefined) {
            if (searchParams.pickRepresentative) {
              // List individual representatives of the team as separate
              // items underneath the parent team item.
              teams.results.filter(this.excludeFn || (() => true)).forEach((result) => {
                if (searchParams.pickRepresentativeOnly) {
                  result.disabled = true;
                }
                teamResults.push(result);
                const representatives = (result.representatives || []).filter(
                  this.excludeFn || (() => true),
                );
                representatives.forEach((representative) => {
                  teamResults.push({
                    isChild: true,
                    ...representative,
                  });
                });
              });
            } else {
              teamResults = teams.results.filter(this.excludeFn || (() => true));
            }
          }

          const companyResults: ISearchGroup = {
            header: individuals ? 'Companies' : '',
            headerIcon: 'building',
            noResultsText: 'No companies found',
            results: teamResults,
            footer: null,
            footerLink: null,
            error: teams.error,
          };

          teams.searchGroup = companyResults;
          searchGroups.push(companyResults);
        }

        if (stakes !== undefined) {
          const stakeResults: ISearchGroup = {
            header: individuals || teams ? 'Your Companies' : '',
            headerIcon: 'building',
            noResultsText: 'No companies found',
            results: stakes.results.filter(this.excludeFn || (() => true)),
            footer: null,
            footerLink: null,
            error: stakes.error,
          };
          stakes.searchGroup = stakes;
          searchGroups.push(stakeResults);
        }

        if (this.parseResultsCallback) {
          this.parseResultsCallback(text, results, searchGroups);
        }

        return searchGroups;
      }),
    );
  }
}
