import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { Router } from '@angular/router';
import { LensInfo } from '@shoobx/types/webapi-v2';
import { Downgrade } from '@/shared/downgrade';
import { isHrefValid } from '@/shared/utils/url-validator.util';
import { Params as QueryParams } from '@angular/router';

export interface Params {
  withSpaPrefix?: boolean;
  lens?: LensInfo;
  queryParams?: QueryParams;
}

@Downgrade.Injectable('ngShoobx', 'SbxUrlService')
@Injectable({
  providedIn: 'root',
})
export class SbxUrlService {
  constructor(
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(Router) private router: Router,
    @Inject('Window') private readonly window: Window,
  ) {}

  parseCamefromUrl(url: string) {
    // All camefrom urls should be relative. To check if url is relative we try to
    // construct new URL - if it fails, that means url is relative, otherwise url is
    // absolute (which leads to open external redirects).
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return '/';
    } catch {
      return url;
    }
  }

  spaUrl(url: string) {
    if (!url) {
      return null;
    }

    const index = url.indexOf(this.baseHref);
    if (index === -1) {
      return null;
    }

    const link = url.substr(index + this.baseHref.length - 1);
    const spaUrl = this.router.parseUrl(link).toString().split('?')[0];
    // Decode url to avoid double encoding after url is passed to [routerLink]
    const decodedSpaUrl = decodeURIComponent(spaUrl);
    const queryParams = this.router.parseUrl(link).queryParams;
    return { url: decodedSpaUrl, queryParams };
  }

  canonicalUrl(commands: string[], params?: Params) {
    const { currentEntity, currentLens } = this.appConfig;
    const lens = params?.lens || currentLens;
    const baseUrl = params?.withSpaPrefix ? this.baseHref : '/';
    const onbehalf = lens?.onbehalf ? `onbehalf-${lens.onbehalf}` : null;
    const entity = currentEntity?.name;
    const path = commands.filter((i) => i).join('/');
    const url = [onbehalf, entity, path].filter((i) => i).join('/');
    const queryParams = params?.queryParams
      ? `?${new URLSearchParams(params?.queryParams).toString()}`
      : '';
    return baseUrl + url + queryParams;
  }

  dashboardUrl(params?: Params) {
    const lens = params?.lens?.id || this.appConfig.currentLens?.id;
    if (!lens) {
      return null;
    }

    return this.canonicalUrl(['dashboard', lens], params);
  }

  assetUrl(asset: string) {
    return `${process.env.PUBLIC_PATH}${asset}`;
  }

  public mapToUrl(href: string): URL {
    const currentHref = this.window.location.href;

    if (!isHrefValid(href)) {
      throw new Error(`'${href}' is not a valid href`);
    }

    // currentHref will be skipped if href is fullUrl
    return new URL(href, currentHref);
  }
}
