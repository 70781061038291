/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbExpDate
 * @restrict EA
 *
 * @description
 * Credit Card Expiration Date Directive
 *
 * @element ANY
 *
 * @param {model} Parent form model.
 */
export const sbExpDate = [
  '$window',
  function ($window) {
    return {
      restrict: 'EA',
      template: require('./templates/widgets/exp-date.html'),
      require: 'ngModel',
      scope: {
        model: '=ngModel',
      },
      link: (scope, el, attrs, ctrl) => {
        const date = new $window.Date();
        const currentYear = date.getFullYear();
        const currentYearStr = currentYear.toString();
        const currentMonth = date.getMonth();
        const range = (scope.yearRange = []);
        for (let i = 0; i < 20; i++) {
          range.push(i + currentYear);
        }
        // Don't let user select a month that's already happened
        scope.model = scope.model || {};
        scope.$watch('model.exp_year', (year) => {
          // date.getMonth() returns a month val, 0-11 0 is jan, 1 is feb etc.
          const month = year === currentYearStr ? currentMonth : 0;
          const { exp_month: userMonth } = scope.model;
          if (userMonth && month > parseInt(userMonth)) {
            scope.model.exp_month = null; // eslint-disable-line camelcase
          }
          scope.monthRange = [];
          for (let i = month + 1; i <= 12; i++) {
            scope.monthRange.push(`${(i < 10 ? '0' : '') + i}`);
          }
          scope.monthRange.push(undefined); // XXX dirty chosen hack
        });
        if (attrs.required) {
          // eslint-disable-next-line camelcase
          scope.$watchCollection('model', ({ exp_month, exp_year }) => {
            ctrl.$setValidity('requiredMonthValidator', Boolean(exp_month)); // eslint-disable-line camelcase
            ctrl.$setValidity('requiredYearValidator', Boolean(exp_year)); // eslint-disable-line camelcase
          });
        }
      },
    };
  },
];
