import angular from 'angular';
/**
 * @ngdoc service
 * @name sb.lib.form.DelawareCCorpService
 *
 * @description
 * Use this service to lookup Delaware info.
 */
export const DelawareCCorpService = [
  '$http',
  '$q',
  'BackendLocation',
  function ($http, $q, BackendLocation) {
    return {
      /**
       * @ngdoc method
       * @name namereservation
       * @methodOf sb.lib.form.DelawareCCorpService
       *
       * @param {object} data Data to send to the server.
       *
       * @returns {promise} Promise which resolves with positive response and
       *    rejects with negative response.
       */
      namereservation(data) {
        return $http({
          url: BackendLocation.root(1) + 'deccorp/name-reservation',
          method: 'POST',
          data,
        }).then(
          (resp) => resp.data,
          (resp) => $q.reject(resp.data),
        );
      },

      /**
       * @ngdoc method
       * @name entitysearch
       * @methodOf sb.lib.form.DelawareCCorpService
       *
       * @param {object} data Data to send to the server.
       *
       * @returns {promise} Promise which resolves with positive response and
       *    rejects with negative response.
       */
      entitysearch(data) {
        return $http({
          url: BackendLocation.root(1) + 'deccorp/entity-search',
          method: 'POST',
          data: data,
        }).then(
          (resp) => {
            return resp.data;
          },
          (resp) => {
            return $q.reject(resp.data);
          },
        );
      },

      /**
       * @ngdoc method
       * @name getentitysearchform
       * @methodOf sb.lib.form.DelawareCCorpService
       *
       * @returns {promise} Promise which resolves with positive response and
       *    rejects with negative response.
       */
      getentitysearchform() {
        return $http({
          url: BackendLocation.root(1) + 'deccorp/entity-search-form',
          method: 'GET',
        }).then(
          (resp) => {
            return resp.data;
          },
          (resp) => {
            return $q.reject(resp.data);
          },
        );
      },
    };
  },
]; // end DelawareCCorpService

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbUrlAvailabilityChecker
 * @restrict E
 *
 * @description
 * This directive inserts a url availability checker form.
 *
 * @param {expression} ngModel Model for the searchbox.
 */
export function sbUrlAvailabilityChecker() {
  return {
    require: 'ngModel',
    restrict: 'E',
    template: require('./templates/widgets/url-availability-checker.html'),
    scope: {
      model: '=ngModel',
    },
    controller: [
      '$scope',
      'URLAvailability',
      function ($scope, URLAvailability) {
        const TLDS = ['com', 'net', 'us'];
        $scope.urlcheck = function () {
          $scope.results = [];
          if (!$scope.model) {
            $scope.urlSearchFail = false;
            $scope.invalidDomain = true;
            return;
          }
          if ($scope.lastUrlSearch === $scope.model) {
            // Don't search for something you just searched for
            return;
          }
          $scope.lastUrlSearch = $scope.model = URLAvailability.urlformat($scope.model);
          $scope.invalidDomain = false;
          $scope.urlSearchFail = false;
          $scope.urlcheckLoading = true;
          URLAvailability.urlcheck({
            name: $scope.model,
            tlds: TLDS,
          })
            .then(
              (data) => {
                const availableLookup = {};
                let result;
                angular.forEach(data.available, (tld) => {
                  availableLookup[tld] = true;
                });
                $scope.results = TLDS.map((tld) => {
                  result = { url: $scope.lastUrlSearch + '.' + tld };
                  if (availableLookup[tld]) {
                    result.available = true;
                  }
                  return result;
                });
              },
              () => {
                $scope.urlSearchFail = true;
              },
            )
            .finally(() => {
              $scope.urlcheckLoading = false;
            });
        }; // end urlcheck()
      },
    ], // end controller
  };
} // end sbUrlAvailabilityChecker

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbCcorpNamecheckPage
 * @restrict A
 *
 * @description
 * This directive inserts a CCorp name check form
 *
 * @TODO This diretive assumes a zope, web 1.0 form. Its useless for angular
 *    forms. Make it have ngModel and consider moving this namecheck validation
 *    to a aync validator and just use modelCtrl for most of this state..
 */
export function sbCcorpNamecheckPage() {
  return {
    requires: 'ngModel',
    restrict: 'A',
    template: require('./templates/widgets/ccorp-name-check.html'),
    scope: {
      suffixes: '=sbCcorpNameSuffixes',
      initialName: '@sbCcorpInitialName',
      initialSuffix: '@sbCcorpInitialSuffix',
      reCaptchaAPIKey: '@sbCcorpRecaptchaApiKey',
      ngModel: '=ngModel',
    },
    controller: [
      '$scope',
      '$sbModal',
      'ProcessButtonModel',
      'URLAvailability',
      'DebounceMaker',
      'NameCheckModel',
      'PromiseErrorCatcher',
      function (
        $scope,
        $sbModal,
        ProcessButtonModel,
        URLAvailability,
        DebounceMaker,
        NameCheckModel,
        PromiseErrorCatcher,
      ) {
        const debounce = DebounceMaker();

        // Constants:
        $scope.namecheckModel = NameCheckModel;
        $scope.CCORP_NAME_CHECK_FORM_DEF = [
          {
            key: 'name',
            id: 'deccorp_name_check-ccorp_name',
            type: 'string-textline',
            data: {},
            templateOptions: {
              label: 'Name',
              help:
                'Enter the name of your company, excluding the suffix (e.g. ' +
                'Inc. or Co.), as you would like it to appear on your Charter.',
            },
            modelOptions: {},
          },
          {
            key: 'suffix',
            id: 'deccorp_name_check-ccorp_suffix',
            type: 'enum-dropdown',
            data: {},
            templateOptions: {
              label: 'Suffix',
              help: 'Choose your Delaware C Corporation suffix.',
              enumVocab: ($scope.suffixes || []).map((s) => ({ value: s, label: s })),
            },
          },
        ];
        $scope.CCORP_NAME_CHECK_RESERVATION_NUMBER = [
          {
            key: 'reservationNumber',
            id: 'deccorp_name_check-reservation_number',
            type: 'string-textline',
            data: {},
            templateOptions: {
              label: 'Reservation Number',
              help:
                'Enter the Delaware Reservation Number for your ' +
                "name if you've reserved it",
            },
            modelOptions: {},
          },
        ];
        if ($scope.initialName.charAt($scope.initialName.length - 1) === ',') {
          $scope.initialName = $scope.initialName.substr(
            0,
            $scope.initialName.length - 1,
          );
          $scope.namecheckModel.comma = true;
        }
        $scope.namecheckModel.ccorpName = {
          name: $scope.initialName,
          suffix: $scope.initialSuffix,
        };
        $scope.reservationNumber = '';
        $scope.openSimilarNamesModal = function (evt) {
          evt.preventDefault();
          $sbModal
            .open({
              size: 'md',
              windowClass: 'captcha-modal',
              keyboard: true,
              resolve: {
                ccorpName: function () {
                  return $scope.namecheckModel.ccorpName;
                },
                reCaptchaAPIKey: function () {
                  return $scope.reCaptchaAPIKey;
                },
              },
              template: require('./templates/widgets/ccorp-similar-names-modal.html'),
              controller: 'SimilarNamesController',
            })
            .result.catch(PromiseErrorCatcher);
        };

        $scope.urlChecker = {};
        $scope.ccorpNameFormErrors = {};
        $scope.$watch('namecheckModel.comma', (value) => {
          $scope.ngModel.comma = value;
        });
        $scope.$watch('namecheckModel.reservationNumber', (value) => {
          // eslint-disable-next-line camelcase
          $scope.ngModel.reservation_number = value;
        });
        $scope.$watchCollection('namecheckModel.ccorpName', (ccorpName) => {
          if (angular.isDefined(ccorpName.name)) {
            $scope.urlChecker.url = URLAvailability.urlformat(ccorpName.name);
            // eslint-disable-next-line camelcase
            $scope.ngModel.ccorp_name = ccorpName.name;
          }
          if (angular.isDefined(ccorpName.suffix)) {
            // eslint-disable-next-line camelcase
            $scope.ngModel.ccorp_suffix = ccorpName.suffix;
          }
          $scope.namecheckModel.showResults = false;
          $scope.namecheckModel.namecheckLoading = false;
          if ($scope.namecheckModel.titleHasValues()) {
            debounce(() => {
              $scope.namecheckModel.extraCheckbox = false;
              $scope.namecheckModel.namecheck();
            }, 1000);
          } else {
            $scope.namecheckModel.namecheckLoading = false;
          }
        });
        $scope.$watch('namecheckModel.canContinue()', (nv) => {
          if (nv) {
            ProcessButtonModel.requestEnable('continue');
          } else {
            ProcessButtonModel.disable('continue');
          }
        });
      },
    ], // end controller
  };
} // end sbCcorpNamecheckPage

/* Backward compat for processes pinned to Show Form */
export function sbCcorpNamecheckForm() {
  return {
    requires: 'ngModel',
    restrict: 'A',
    template: require('./templates/widgets/ccorp-name-check-form.html'),
    scope: {
      suffixes: '=sbCcorpNameSuffixes',
      initialName: '@sbCcorpInitialName',
      initialSuffix: '@sbCcorpInitialSuffix',
      reCaptchaAPIKey: '@sbCcorpRecaptchaApiKey',
      ngModel: '=ngModel',
    },
    controller: [
      '$scope',
      '$sbModal',
      'ProcessButtonModel',
      'URLAvailability',
      'DebounceMaker',
      'NameCheckModel',
      'PromiseErrorCatcher',
      function (
        $scope,
        $sbModal,
        ProcessButtonModel,
        URLAvailability,
        DebounceMaker,
        NameCheckModel,
        PromiseErrorCatcher,
      ) {
        const debounce = DebounceMaker();

        // Constants:
        $scope.namecheckModel = NameCheckModel;
        $scope.CCORP_NAME_CHECK_FORM_DEF = [
          {
            key: 'name',
            id: 'form.widgets.ccorp_name',
            type: 'string-textline',
            data: {},
            templateOptions: {
              label: 'Name',
              help:
                'Enter the name of your company, excluding the suffix (e.g. ' +
                'Inc. or Co.), as you would like it to appear on your Charter.',
            },
            modelOptions: {},
          },
          {
            key: 'suffix',
            id: 'form.widgets.ccorp_suffix',
            type: 'enum-dropdown',
            data: {},
            templateOptions: {
              label: 'Suffix',
              help: 'Choose your Delaware C Corporation suffix.',
              enumVocab: ($scope.suffixes || []).map((s) => ({ value: s, label: s })),
            },
          },
        ];
        $scope.CCORP_NAME_CHECK_RESERVATION_NUMBER = [
          {
            key: 'reservationNumber',
            id: 'form.widgets.reservation_number',
            type: 'string-textline',
            data: {},
            templateOptions: {
              label: 'Reservation Number',
              help:
                'Enter the Delaware Reservation Number for your ' +
                "name if you've reserved it",
            },
            modelOptions: {},
          },
        ];
        if ($scope.initialName.charAt($scope.initialName.length - 1) === ',') {
          $scope.initialName = $scope.initialName.substr(
            0,
            $scope.initialName.length - 1,
          );
          $scope.namecheckModel.comma = true;
        }
        $scope.ngModel = $scope.namecheckModel.ccorpName = {
          name: $scope.initialName,
          suffix: $scope.initialSuffix,
        };
        $scope.reservationNumber = '';
        $scope.openSimilarNamesModal = function (evt) {
          evt.preventDefault();
          $sbModal
            .open({
              size: 'md',
              windowClass: 'captcha-modal',
              keyboard: true,
              resolve: {
                ccorpName: function () {
                  return $scope.namecheckModel.ccorpName;
                },
                reCaptchaAPIKey: function () {
                  return $scope.reCaptchaAPIKey;
                },
              },
              template: require('./templates/widgets/ccorp-similar-names-modal.html'),
              controller: 'SimilarNamesController',
            })
            .result.catch(PromiseErrorCatcher);
        };

        $scope.urlChecker = {};
        $scope.ccorpNameFormErrors = {};

        $scope.$watchCollection('namecheckModel.ccorpName', (ccorpName) => {
          if (angular.isDefined(ccorpName.name)) {
            $scope.urlChecker.url = URLAvailability.urlformat(ccorpName.name);
          }
          $scope.namecheckModel.showResults = false;
          $scope.namecheckModel.namecheckLoading = false;
          if ($scope.namecheckModel.titleHasValues()) {
            debounce(() => {
              $scope.namecheckModel.extraCheckbox = false;
              $scope.namecheckModel.namecheck();
            }, 1000);
          } else {
            $scope.namecheckModel.namecheckLoading = false;
          }
        });
        $scope.$watch('namecheckModel.canContinue()', (nv) => {
          if (nv) {
            ProcessButtonModel.requestEnable('continue');
          } else {
            ProcessButtonModel.disable('continue');
          }
        });
      },
    ], // end controller
  };
} // end sbCcorpNamecheckForm

export const NameCheckModel = [
  'DelawareCCorpService',
  function (DelawareCCorpService) {
    const self = {};
    self.deUrl = 'https://icis.corp.delaware.gov/Ecorp/EntitySearch/NameSearch.aspx';
    self.deDisclaimer = 'https://icis.corp.delaware.gov/Ecorp/Disclaimer.aspx';
    self.extraCheckbox = false;
    self.canContinue = function () {
      const resNumber = Boolean(self.reservationNumber),
        alwaysTrue =
          self.searchData.valid_suffix && self.titleHasValues() && self.showResults,
        available = !(self.searchData.exact_match || self.delawareError),
        exactMatch = self.searchData.exact_match && resNumber,
        delawareError = self.delawareError && (resNumber || self.extraCheckbox),
        canContinue = alwaysTrue && (available || exactMatch || delawareError);
      return Boolean(canContinue);
    };
    self.ccorpName = self.oldName = {};
    // eslint-disable-next-line camelcase
    self.searchData = { valid_suffix: false, exact_match: false };
    self.namecheckLoading = false;
    self.showResults = false;
    self.displayName = function () {
      const comma = self.comma ? ', ' : ' ';
      return angular.copy(self.ccorpName.name + comma + self.ccorpName.suffix);
    };

    self.titleHasValues = function () {
      return (
        angular.isString(self.ccorpName.name) &&
        self.ccorpName.name.length > 0 &&
        angular.isString(self.ccorpName.suffix) &&
        self.ccorpName.suffix.length > 0
      );
    };

    self.showDisplayName = function () {
      return (
        angular.isString(self.displayName()) &&
        self.displayName().length > 0 &&
        self.showResults
      );
    };

    self.namecheck = function () {
      self.reservationNumber = '';
      self.showResults = false;
      self.namecheckLoading = false;
      if (angular.equals(self.oldName, self.ccorpName) || !self.titleHasValues()) {
        self.namecheckLoading = false;
        self.showResults = true;
        return;
      }
      self.delawareError = false;
      self.namecheckLoading = true;
      // save last name searched per call
      const namecheckLastSearched = angular.copy(self.ccorpName);
      DelawareCCorpService.namereservation(namecheckLastSearched)
        .then(
          (data) => {
            if (!angular.equals(namecheckLastSearched, self.ccorpName)) {
              return;
            }
            self.searchData = data;
            self.namecheckLastSearched = {};
            self.namecheckLoading = false;
            self.showResults = true;
          },
          () => {
            if (!angular.equals(namecheckLastSearched, self.ccorpName)) {
              return;
            }
            self.delawareError = true;
            self.namecheckLoading = false;
            self.showResults = true;
            // eslint-disable-next-line camelcase
            self.searchData.valid_suffix = true;
          },
        )
        .finally(() => {
          self.oldName = angular.copy(self.ccorpName);
        });
    };
    return self;
  },
]; // end NameCheckModel

export const SimilarNamesController = [
  '$scope',
  'DelawareCCorpService',
  'ccorpName',
  'reCaptchaAPIKey',
  function ($scope, DelawareCCorpService, ccorpName, reCaptchaAPIKey) {
    $scope.reCaptchaAPIKey = reCaptchaAPIKey;
    $scope.results = {};
    $scope.gettingPage = true;
    $scope.searching = false;
    $scope.captchaLoaded = false;
    $scope.allowSubmit = false;
    $scope.$on('sbRecaptcha::captchaLoadingError', () => {
      $scope.loadingError = true;
    });
    $scope.loaded = function ($event) {
      $scope.captchaLoaded = $event;
    };
    $scope.response = function () {
      $scope.allowSubmit = true;
    };
    $scope.expired = function () {
      $scope.allowSubmit = false;
    };
    $scope.captchaEntered = function (text) {
      $scope.captchaText = text;
    };
    self.getDelawarePage = function () {
      DelawareCCorpService.getentitysearchform().then(
        (data) => {
          $scope.gettingPage = false;
          $scope.formData = data;
          if ($scope.formData.hasCaptcha) {
            // allow submission of captcha text
            $scope.captchaLoaded = true;
            $scope.allowSubmit = true;
          }
        },
        () => {
          $scope.$emit('sbRecaptcha::captchaLoadingError');
        },
      );
    };
    // Get the delaware page first to check if a captcha is required or not
    self.getDelawarePage();
    $scope.getSimilarResults = function (e) {
      e.preventDefault();
      $scope.results = {};
      $scope.searching = true;
      DelawareCCorpService.entitysearch({
        name: ccorpName.name,
        suffix: ccorpName.suffix,
        formData: $scope.formData,
        captchaText: $scope.captchaText || null,
      }).then(
        (data) => {
          $scope.searching = false;
          $scope.results = data;
          if ($scope.formData.hasCaptcha && $scope.results.captcha_error) {
            self.getDelawarePage();
          } else {
            // either the captcha is correct or our own captcha was already completed
            $scope.allowSubmit = false;
          }
          if ($scope.results.similar_results.length > 0) {
            $scope.leftResults = $scope.results.similar_results.slice(
              0,
              Math.ceil($scope.results.similar_results.length / 2),
            );
            $scope.rightResults = $scope.results.similar_results.slice(
              Math.ceil($scope.results.similar_results.length / 2),
            );
          }
        },
        () => {
          $scope.$emit('sbRecaptcha::captchaLoadingError');
        },
      );
    };
    $scope.enter = function (e) {
      if (e.which === 13) {
        $scope.getSimilarResults(e);
      }
    };
  },
]; // end SimilarNamesController
