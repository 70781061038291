import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxActionModule } from '../sbx-action-menu/sbx-action-menu.module';
import { SbxDropdownModule } from '../sbx-dropdown/sbx-dropdown.module';
import { SbxCheckboxModule } from '../sbx-checkbox/sbx-checkbox.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import {
  SbxTreeEditComponent,
  SbxTreeSingleSelectComponent,
  SbxTreeSelectComponent,
  SbxTreeNodeEditComponent,
  SbxTreeNodeSelectComponent,
  SbxTreeViewComponent,
  SbxTreeNodeViewComponent,
} from './sbx-tree.component';

import { SbxTreeHoverComponent } from './sbx-tree-hover.component';
import { SbxTreePickerComponent } from './sbx-tree-picker.component';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxActionModule,
    SbxDropdownModule,
    SbxCheckboxModule,
    SbxPopoverModule,
  ],
  declarations: [
    SbxTreeEditComponent,
    SbxTreeNodeEditComponent,
    SbxTreeSelectComponent,
    SbxTreeSingleSelectComponent,
    SbxTreeNodeSelectComponent,
    SbxTreeViewComponent,
    SbxTreeNodeViewComponent,
    SbxTreeHoverComponent,
    SbxTreePickerComponent,
  ],
  exports: [
    SbxTreeEditComponent,
    SbxTreeSelectComponent,
    SbxTreeSingleSelectComponent,
    SbxTreeNodeSelectComponent,
    SbxTreeViewComponent,
    SbxTreeHoverComponent,
    SbxTreePickerComponent,
  ],
})
export class SbxTreeModule {
  static entryComponents = [SbxTreePickerComponent];

  static forRoot(): ModuleWithProviders<SbxTreeModule> {
    return {
      ngModule: SbxTreeModule,
    };
  }
}
