<sbx-modal-header>
  <h4 class="modal-title">Liquidation stack</h4>
</sbx-modal-header>

<sbx-modal-content>
  <p *ngIf="canEdit">Drag and drop to update the liquidation stack.</p>

  <sbx-liquidation-stack
    [stack]="stack"
    [canEdit]="canEdit"
    (change)="change($event)"
  ></sbx-liquidation-stack>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    *ngIf="canEdit"
    theme="primary"
    (click)="handleSave()"
    title="Save"
  ></sbx-button>
  <sbx-button
    theme="secondary"
    (click)="sbxModalService.dismiss()"
    title="{{ canEdit ? 'Cancel' : 'Close' }}"
  ></sbx-button>
</sbx-modal-footer>
