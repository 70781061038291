import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SbxFormModalService } from '../sbx-form-modal/sbx-form-modal.service';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import {
  ISbxDocumentReferenceModel,
  SbxDocumentReferenceModelType,
} from './sbx-document-reference.component';

export class SbxDocumentUploadFile {
  ready = true;
  pdfURL = '';
  name = '';
}

export class SbxDocumentUploadModel implements ISbxDocumentReferenceModel {
  type = SbxDocumentReferenceModelType.Upload;
  uploadId?: string;
  documentTitle: string;
  pdfURL?: string;
  fieldId?: string;
  processId?: string;
  documentId?: string;

  otherUploadData?: { title: string };
  file?: SbxDocumentUploadFile = new SbxDocumentUploadFile();
}

@Component({
  selector: 'sbx-document-upload',
  templateUrl: './sbx-document-upload.component.html',
  styleUrls: ['./sbx-document-upload.component.scss'],
})
export class SbxDocumentUploadComponent implements OnInit {
  @Input() apiResource: () => string;
  @Input() defaultTitle = '';
  @Input() collectTitleInUpload = false;
  @Input()
  get model() {
    return this.uploadModel;
  }
  set model(val) {
    this.uploadModel = val;
    this.modelChange.emit(this.uploadModel);
  }
  @Output() modelChange = new EventEmitter();

  private uploadModel: SbxDocumentUploadModel = new SbxDocumentUploadModel();

  constructor(private sbxFormModalService: SbxFormModalService) {}

  ngOnInit(): void {
    this.model.file = new SbxDocumentUploadFile();
    if (this.defaultTitle) {
      this.model.documentTitle = this.defaultTitle;
    }
    if (this.collectTitleInUpload) {
      this.model.otherUploadData = {
        title: '',
      };
    }
  }

  async open() {
    const formFields = [
      {
        key: 'uploadId',
        type: 'pdf-file',
        templateOptions: <FormlyTemplateOptions>{
          apiResource: this.apiResource,
          file: this.model.file,
          enablePreview: true,
          label: 'File',
          persistUpload: false,
          required: true,
        },
      },
    ];

    if (this.collectTitleInUpload) {
      formFields.push({
        key: 'documentTitle',
        type: 'string-textline',
        templateOptions: <FormlyTemplateOptions>{
          label: 'Document Title',
        },
      });
    }
    try {
      const modalDataAfterClose = await this.sbxFormModalService.open({
        data: {
          title: 'Upload Document',
          model: this.model,
          formFields: formFields,
        },
        windowClass: 'sbx-document-upload',
      }).result;
      this.model.uploadId = modalDataAfterClose.result.uploadId;
      this.model.file = modalDataAfterClose.result.file;
      this.model.pdfURL = modalDataAfterClose.result.file.pdfURL;
      this.model.type = SbxDocumentReferenceModelType.Upload;
      this.model.fieldId =
        this.apiResource().split('/')[this.apiResource().split('/').length - 1];
      if (!this.model.documentTitle) {
        this.model.documentTitle = this.model.file.name;
      }
      this.modelChange.emit(this.model);
    } catch {}
  }
}
