<sbx-modal-header>
  <h4 class="modal-title">{{ newInvestmentForm?.title }}</h4>
</sbx-modal-header>

<sbx-modal-content>
  <!-- XXX: Unhide when add investor functionality will be added -->
  <!-- <sbx-stakeholder-card
    class="sbx-mb-15"
    [stakeholderId]="investorId"
    [format]="'medium'"
  ></sbx-stakeholder-card> -->

  <sbx-title type="section">CURRENT HOLDINGS</sbx-title>
  <sbx-properties-list
    [items]="[
      { key: 'PRE-MONEY OWNERSHIP', value: preMoneyOwnership },
      { key: 'INVESTMENT', value: currentInvestmentAmountStr }
    ]"
  >
  </sbx-properties-list>

  <sbx-table
    [columns]="currentHoldingsColumns"
    [data]="currentHoldingsData"
  ></sbx-table>

  <sbx-title type="section">NEW INVESTMENT</sbx-title>
  <sbx-form
    #formRef
    *ngIf="newInvestmentForm"
    [model]="newInvestmentForm.model"
    [form]="form"
    [formFields]="newInvestmentForm.description.fields"
    [serverErrors]="formErrors"
  ></sbx-form>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    title="Save"
    theme="primary"
    (click)="save()"
    [disabled]="!form.valid"
  ></sbx-button>
  <sbx-button
    title="Cancel"
    theme="secondary"
    (click)="sbxModalService.dismiss()"
  ></sbx-button>
</sbx-modal-footer>

<ng-template #boldTpl let-item>
  <strong>{{ item }}</strong>
</ng-template>
